import React from 'react';
import PropTypes from 'prop-types';

import './well-programme-attributes-component.scss';

import { Attributes } from '../../models/result';
import { IN_DEV_MODE } from '../../../../shared/helpers/common';
import { StringLookupResult } from '../../../../common/models/string-lookup-result';
import PropertyGridComponent, { PropertyGridStringValue, PropertyGridSelectValue } from '../../../../shared/components/property-grid/property-grid-component';
import { LookupResult } from '../../../../common/models/lookup-result';

const IDS = {
  ID: 5,
  WELL_PROGRAMME: 10,
  COUNTRY_ID: 15,
  WESTWOOD_WELL_ID: 20
};
Object.freeze(IDS);

const getSections = (attributes, lookups, valueChanged, westwoodLookups) => {
  const result = [
    {
      label: 'Well Programme',
      rows: [
        { label: 'Well Programme', values: [new PropertyGridStringValue(IDS.WELL_PROGRAMME, attributes.wellProgramme, valueChanged )] },
        { label: 'Country', values: [new PropertyGridSelectValue(IDS.COUNTRY_ID, attributes.countryId, valueChanged, 'key', 'value', lookups.Country)] },
        { label: 'Westwood Well', values: [new PropertyGridSelectValue(IDS.WESTWOOD_WELL_ID, attributes.westwoodDataId, valueChanged, 'key', 'value', westwoodLookups.Well, true)] },
        //{ label: 'Decimal Latitude', values: [new PropertyGridNumberValue(IDS.DECIMAL_LATITUDE, INPUTTYPES.NUMBER, attributes.westwoodLegacyWellId, undefined, 2)] },
      ]
    },
  ];

  if (IN_DEV_MODE) result[0].rows.unshift({ label: 'Well Programme Id', values: [new PropertyGridStringValue(IDS.ID, attributes.id)] });
  return result;
};

const WellProgrammeAttributesComponent = (props) => {
  const {
    editing,
    lookups,
    attributes,
    updateAttributes,
    westwoodLookups
  } = props;

  const valueChanged = (value, id) => {
    switch (id) {
      case IDS.WELL_PROGRAMME: attributes.wellProgramme = value; break;
      case IDS.COUNTRY_ID: attributes.countryId = Number(value); break;
      case IDS.WESTWOOD_WELL_ID: attributes.westwoodDataId = value; break;

      default: throw new Error('Id ' + id + ' is not supported in LeftPanelComponent.valueChanged');
    }
    updateAttributes(attributes)
    return true;
  };

  const sections = getSections(attributes, lookups, valueChanged, westwoodLookups);

  return <PropertyGridComponent sections={sections} readOnly={!editing} />;
};

WellProgrammeAttributesComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  attributes: PropTypes.instanceOf(Attributes).isRequired,
  updateAttributes: PropTypes.func.isRequired,
  westwoodLookups: PropTypes.instanceOf(StringLookupResult).isRequired,
};

export default WellProgrammeAttributesComponent;
