import React from 'react';
import PropTypes from 'prop-types';

import './licences-component.scss';
import AttributeListComponent from '../../../../common/components/attribute-list/attribute-list-component';
import { CorporateLicence } from '../../models/corporate-result';
import SplitterPanelComponent from '../../../../shared/components/splitter-panel/splitter-panel-component';
import PropertyGridComponent, { PropertyGridStringValue, PropertyGridDateValue, SectionPropType } from '../../../../shared/components/property-grid/property-grid-component';

const IDS = {
  LICENCE_NUMBER: 0,
  LICENCE_ROUND: 10,
  LICENCE_TYPE: 20,
  ORIGINAL_LICENCE_TYPE: 30,
  START_DATE: 40,
  END_DATE: 50,
  SEGREGATION_DATE: 60
};
Object.freeze(IDS);

class LicencesComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { items: [], selectedIndex: 0};
  }

  componentDidMount() {
    this.update(this.state.selectedIndex);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.licences !== this.props.licences) {
      this.update(0);
    }

    //NB. Changed this to work like fields - needs revisiting and changing to shared component as comments is using!
    //if (prevProps.licences.entityId !== this.props.licences.entityId || prevState.selectedIndex !== this.state.selectedIndex) {
    //  if (this.props.licences.length > this.state.selectedIndex) {
    //    this.update(this.state.selectedIndex);
    //  }
    //  else {
    //    this.update(0);
    //  }
    //}
  }

  update(index) {
    const { licences } = this.props;
    const items = licences.map(item => {
      return {
        label: item.licenceNumber,
        data: item,
        sections: [{
          label: 'Licences',
          rows: [
            { label: 'Licence Number', values: [new PropertyGridStringValue(IDS.LICENCE_NUMBER, item.licenceNumber)] },
            { label: 'Licence Round', values: [new PropertyGridStringValue(IDS.LICENCE_ROUND, item.licenceRound)] },
            { label: 'Licence Type', values: [new PropertyGridStringValue(IDS.LICENCE_TYPE, item.licenceType)] },
            { label: 'Original Licence Type', values: [new PropertyGridStringValue(IDS.ORIGINAL_LICENCE_TYPE, item.originalLicenceType)] },
            { label: 'Start Date', values: [new PropertyGridDateValue(IDS.START_DATE, item.startDate)] },
            { label: 'End Date', values: [new PropertyGridDateValue(IDS.END_DATE, item.endDate)] },
            { label: 'Segregation Date', values: [new PropertyGridDateValue(IDS.SEGREGATION_DATE, item.segregationDate)] }
          ]
        }]
      }
    });

    this.setState({ items: items, selectedIndex: index });
  }

  get selected() {
    const { items, selectedIndex } = this.state;

    return items.length === 0 || selectedIndex < 0 || selectedIndex > items.length - 1
      ? null
      : items[selectedIndex]
  }

  setSelectedIndex = (value) => {
    const { selectedIndex } = this.state;
    if (typeof value !== 'number') {
      throw new Error('Invalid value param "' + value + '" supplied to AttributeListComponent.setSelectedIndex');
    }

    if (selectedIndex !== value) {
      this.setState({ selectedIndex: value });
    }
  }

  render() {
    const { items, selectedIndex } = this.state;
    const selected = this.selected;

    const className = ' can-jump';

    return (
      <SplitterPanelComponent vertical percentage={false} primaryIndex={1} secondarySize={125}>
        <ul className="labels">
          {items.map((item, index) => (
            <li
              key={index}
              className={(index === selectedIndex ? 'selected' : '') + className}
            >
              <div className={'label-name' + className} onClick={index === selectedIndex ? undefined : () => this.setSelectedIndex(index)}>{item.label}</div>

            </li>
          ))}
        </ul>
        <PropertyGridComponent readOnly={false} sections={selected === null ? [] : selected.sections} narrow={true} />
      </SplitterPanelComponent>
    );
  }
}

AttributeListComponent.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.instanceOf(CorporateLicence)),
  onJump: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired
};


export default LicencesComponent;
