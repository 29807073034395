export const RELATED_ITEM_TYPE = {
  COMMENT: 1,
  FIELD: 2,
  LICENCE: 3,
  BLOCK: 4,
  OWNERSHIP: 5,
  PROCESSING: 6,
  WELL: 7,
  LOCATION: 8,
  ALIAS: 9,
  HUB: 10,
  PROSPECT: 11,
  DISCOVERY: 12,
  RESOURCECOMMENT: 13,
  PRODUCTIONCOMMENT: 14,
  OFFICIALUID: 15,
  WESTWOOD_DATA_FIXED_PLATFORM: 16,
  WESTWOOD_DATA_FLOATING_PLATFORM: 17
};
Object.freeze(RELATED_ITEM_TYPE);
