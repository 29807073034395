import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { MessageBoxGlobal } from '../../../../../common/globals/globals';
import { LookupResult } from '../../../../../common/models/lookup-result';
import { ResourceProductionAttributes, unitConversions } from '../../../models/production-result';
import { valueTypes } from '../../../../../shared/helpers/value-handler';
import './resource-attributes-component.scss';
import PropertyGridComponent, { validateDate, PropertyGridStringValue, PropertyGridSelectValue, PropertyGridNumberValue, PropertyGridNumberValueDouble, PropertyGridPercentageValue, PropertyGridDateValue } from '../../../../../shared/components/property-grid/property-grid-component';
import { INPUTTYPES } from '../../../../../shared/components/number-format/number-formatter';

const IDS = {
  RESOURCE_ID: 5,

  RESOURCE_NAME: 10,
  RESOURCE_STATUS_ID: 15,
  TOTAL_RECOVERABLE_RESERVES_MMBOE: 20,
  TOTAL_RECOVERABLE_RESERVES_M3: 21,

  TOTAL_TECHNCAL_RESERVES_MMBOE: 25,
  TOTAL_TECHNCAL_RESERVES_M3: 26,

  DEVELOPMENT_WELLS: 30,
  TOTAL_ABEX_USDMM: 35,

  REPORTED_REMAINING_LIQUID_RESERVES_MMBOE: 40,
  REPORTED_REMAINING_LIQUID_RESERVES_M3: 41,

  REPORTED_LIQUID_RESERVES_SOURCE_ID: 45,
  REPORTED_LIQUID_RESERVES_EFFECTIVE_DATE: 50,

  REPORTED_REMAINING_GAS_RESERVES_BCF: 55,
  REPORTED_REMAINING_GAS_RESERVES_M3: 56,

  REPORTED_GAS_RESERVES_SOURCE_ID: 60,
  REPORTED_GAS_RESERVES_EFFECTIVE_DATE: 65,
  REPORTED_COP: 70,
  REPORTED_COP_SOURCE_ID: 75,
  REPORTED_COP_DATE: 80,

  EXPECTED_SANCTION_YEAR: 85,
  FIELD_APPROVAL_DATE: 90,
  FIELD_PRODUCTION_DATE: 95,
  PRODUCTION_CESSATION_DATE: 100,

  BREAK_EVEN_YEAR: 105,
  BREAK_EVEN_PRICE: 110,
  FULL_FIELD_NPV_USDMM: 115,
  FULL_FIELD_NPV10_USDMM: 120,
  FUL_INTERNAL_RATE_OF_RETURN_PERCENT: 125,
  FULL_FIELD_OPERATING_COST: 130,
  POINT_FORWARD_NPV_USDMM: 135,
  POINT_FORWARD_NPV10_USDMM: 140,
  POINT_FORWARD_OPERATING_COST_USDMM: 145
};
Object.freeze(IDS);

const getSectionsLeft = (attributes, lookups, valueChanged) => {
  return [
    {
      label: 'Resource',
      rows: [
        { label: 'Resource Name', values: [new PropertyGridStringValue(IDS.RESOURCE_NAME, attributes.resourceName)] },
        { label: 'Field Status', values: [new PropertyGridSelectValue(IDS.RESOURCE_STATUS_ID, attributes.resourceStatusId, valueChanged, 'key', 'value', lookups.ResourceStatus_Field)] },
        { label: 'Total Recoverable Reserves', values: [new PropertyGridNumberValueDouble(IDS.TOTAL_RECOVERABLE_RESERVES_MMBOE, IDS.TOTAL_RECOVERABLE_RESERVES_M3, attributes.initialCombinedMmboe, attributes.initialCombinedM3, valueTypes.UnitString(valueTypes.mmmboe), valueTypes.UnitString(valueTypes.msm3), INPUTTYPES.NUMBER, undefined, 2, true)] },
        { label: 'Total Technical Reserves', values: [new PropertyGridNumberValueDouble(IDS.TOTAL_TECHNCAL_RESERVES_MMBOE, IDS.TOTAL_TECHNCAL_RESERVES_M3, attributes.totalTechnicalReservesMmboe, attributes.totalTechnicalReservesM3, valueTypes.UnitString(valueTypes.mmmboe), valueTypes.UnitString(valueTypes.msm3), INPUTTYPES.NUMBER, undefined, 2, true)] },
        { label: 'Development Wells', values: [new PropertyGridNumberValue(IDS.DEVELOPMENT_WELLS, INPUTTYPES.NUMBER, attributes.developmentWells, undefined, 0)] },
        { label: 'Total Abex (' + valueTypes.UnitString(valueTypes.usdMM) + ')', values: [new PropertyGridNumberValue(IDS.TOTAL_ABEX_USDMM, INPUTTYPES.NUMBER, attributes.totalAbexUSDMM, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] }
      ]
    },
    {
      label: 'Reported Attributes',
      rows: [
        { label: 'Remaining Liquid Reserves', values: [new PropertyGridNumberValueDouble(IDS.REPORTED_REMAINING_LIQUID_RESERVES_MMBOE, IDS.REPORTED_REMAINING_LIQUID_RESERVES_M3, attributes.reportedRemainingLiquidMMB, attributes.reportedRemainingLiquidM3, valueTypes.UnitString(valueTypes.mmmboe), valueTypes.UnitString(valueTypes.msm3), INPUTTYPES.NUMBER, valueChanged, 2, true)] },
        { label: 'Remaining Liquid Reserves Source', values: [new PropertyGridSelectValue(IDS.REPORTED_LIQUID_RESERVES_SOURCE_ID, attributes.reportedRemainingLiquidSourceId, valueChanged, 'key', 'value', lookups.ReportedSource)] },
        { label: 'Remaining Liquid Reserves Effective Date', values: [new PropertyGridDateValue(IDS.REPORTED_LIQUID_RESERVES_EFFECTIVE_DATE, attributes.reportedRemainingLiquidEffectiveDate, valueChanged)] },
        { label: 'Remaining Gas Reserves', values: [new PropertyGridNumberValueDouble(IDS.REPORTED_REMAINING_GAS_RESERVES_BCF, IDS.REPORTED_REMAINING_GAS_RESERVES_M3, attributes.reportedRemainingGasBCF, attributes.reportedRemainingGasM3, valueTypes.UnitString(valueTypes.bcf), valueTypes.UnitString(valueTypes.bsm3), INPUTTYPES.NUMBER, valueChanged, 2, true)] },
        { label: 'Remaining Gas Reserves Source', values: [new PropertyGridSelectValue(IDS.REPORTED_GAS_RESERVES_SOURCE_ID, attributes.reportedRemainingGasSourceId, valueChanged, 'key', 'value', lookups.ReportedSource)] },
        { label: 'Remaining Gas Reserves Effective Date', values: [new PropertyGridDateValue(IDS.REPORTED_GAS_RESERVES_EFFECTIVE_DATE, attributes.reportedRemainingGasEffectiveDate, valueChanged)] },
        { label: 'Reported COP Year', values: [new PropertyGridNumberValue(IDS.REPORTED_COP, INPUTTYPES.YEAR, attributes.reportedCOPYear, valueChanged)] },
        { label: 'Reported COP Source', values: [new PropertyGridSelectValue(IDS.REPORTED_COP_SOURCE_ID, attributes.reportedCOPSourceId, valueChanged, 'key', 'value', lookups.ReportedSource)] },
        { label: 'Reported COP Date', values: [new PropertyGridDateValue(IDS.REPORTED_COP_DATE, attributes.reportedCOPDate, valueChanged)] },
      ]
    }
  ]
};

const getSectionsRight = (attributes, valueChanged) => {
  return [
    {
      label: 'Dates',
      rows: [
        { label: 'Expected Sanction Year', values: [new PropertyGridNumberValue(IDS.EXPECTED_SANCTION_YEAR, INPUTTYPES.YEAR, attributes.expectedSanctionYear, valueChanged)] },
        { label: 'Field Approval Date', values: [new PropertyGridDateValue(IDS.FIELD_APPROVAL_DATE, attributes.fieldApprovalDate, valueChanged)] },
        { label: 'Field Production Date', values: [new PropertyGridDateValue(IDS.FIELD_PRODUCTION_DATE, attributes.productionStartDate, valueChanged)] },
        { label: 'Production Cessation Date', values: [new PropertyGridDateValue(IDS.PRODUCTION_CESSATION_DATE, attributes.productionEndDate, valueChanged)] }
      ]
    },
    {
      label: 'Economic Attributes',
      rows: [
        { label: 'Break Even Year', values: [new PropertyGridNumberValue(IDS.BREAK_EVEN_YEAR, INPUTTYPES.YEAR, attributes.breakEvenYear, undefined)] },
        { label: 'Break Even Price', values: [new PropertyGridNumberValue(IDS.BREAK_EVEN_PRICE, INPUTTYPES.NUMBER, attributes.breakEvenPrice, undefined, valueTypes.GetPrecision(valueTypes.generic2, 0))] },
        { label: 'Full Field NPV (' + valueTypes.UnitString(valueTypes.usdMM) + ')', values: [new PropertyGridNumberValue(IDS.FULL_FIELD_NPV_USDMM, INPUTTYPES.NUMBER, attributes.fullFieldNPVUSDMM, undefined, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Full Field NPV10 (' + valueTypes.UnitString(valueTypes.usdMM) + ')', values: [new PropertyGridNumberValue(IDS.FULL_FIELD_NPV10_USDMM, INPUTTYPES.NUMBER, attributes.fullFieldNPV10USDMM, undefined, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Full Internal Rate of Return (' + valueTypes.UnitString(valueTypes.equity) + ')', values: [new PropertyGridPercentageValue(IDS.FUL_INTERNAL_RATE_OF_RETURN_PERCENT, INPUTTYPES.NUMBER, attributes.fullInternalRateOfReturn, undefined)] },
        { label: 'Full Field Operating Cost (' + valueTypes.UnitString(valueTypes.usdMM) + ')', values: [new PropertyGridNumberValue(IDS.FULL_FIELD_OPERATING_COST, INPUTTYPES.NUMBER, attributes.fullFieldOperatingCostUSDMM, undefined, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Point Forward NPV (' + valueTypes.UnitString(valueTypes.usdMM) + ')', values: [new PropertyGridNumberValue(IDS.POINT_FORWARD_NPV_USDMM, INPUTTYPES.NUMBER, attributes.pointForwardNPVUSDMM, undefined, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Point Forward NPV10 (' + valueTypes.UnitString(valueTypes.usdMM) + ')', values: [new PropertyGridNumberValue(IDS.POINT_FORWARD_NPV10_USDMM, INPUTTYPES.NUMBER, attributes.pointForwardNPV10USDMM, undefined, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Point Forward Operating Cost (' + valueTypes.UnitString(valueTypes.usdMM) + ')', values: [new PropertyGridNumberValue(IDS.POINT_FORWARD_OPERATING_COST_USDMM, INPUTTYPES.NUMBER, attributes.pointForwardOperatingCostUSDMM, undefined, valueTypes.GetPrecision(valueTypes.generic3, 0))] }
      ]
    }
  ]
};

const ResourceAttributesComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    currentResourceAttributes,
    currentResourceAttributeActions,
    lookups
  } = props;

  const valueChanged = (value, id) => {
    switch (id) {
      //RESOURCE
      case IDS.RESOURCE_STATUS_ID: currentResourceAttributes.resourceStatusId = value; break;
      //R/O - case IDS.TOTAL_RECOVERABLE_RESERVES: currentResourceAttributes.initialLiquidMMB = value; break;
      //R/O - case IDS.TOTAL_TECHNCAL_RESERVES: currentResourceAttributes.initialLiquidMMB = value; break;
      case IDS.TOTAL_ABEX_USDMM: currentResourceAttributes.totalAbexUSDMM = value; break;

      //REPORTEDATTRIBUTES
      case IDS.REPORTED_REMAINING_LIQUID_RESERVES_MMBOE:
        currentResourceAttributes.reportedRemainingLiquidMMB = value;
        currentResourceAttributes.reportedRemainingLiquidM3 = value === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, value); break;

      case IDS.REPORTED_REMAINING_LIQUID_RESERVES_M3:
        currentResourceAttributes.reportedRemainingLiquidM3 = value;
        currentResourceAttributes.reportedRemainingLiquidMMB = value === 0 ? 0 : unitConversions.Convert(unitConversions.cubicMeterToBOE, Number(value)); break;

      case IDS.REPORTED_LIQUID_RESERVES_SOURCE_ID: currentResourceAttributes.reportedRemainingLiquidSourceId = value; break;
      case IDS.REPORTED_LIQUID_RESERVES_EFFECTIVE_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid Reported Liquid Reserves Effective Date');
          return false;
        }
        currentResourceAttributes.reportedRemainingLiquidEffectiveDate = value === undefined ? currentResourceAttributes.reportedRemainingLiquidEffectiveDate = null : moment(value).format('YYYY-MM-DD[T]hh:mm:ss.sss'); break;

      case IDS.REPORTED_REMAINING_GAS_RESERVES_BCF:
        currentResourceAttributes.reportedRemainingGasBCF = value;
        currentResourceAttributes.reportedRemainingGasM3 = value === 0 ? 0 : unitConversions.Convert(unitConversions.cubicFeetToCubicMeter, value); break;

      case IDS.REPORTED_REMAINING_GAS_RESERVES_M3:
        currentResourceAttributes.reportedRemainingGasM3 = value;
        currentResourceAttributes.reportedRemainingGasBCF = value === 0 ? 0 : unitConversions.Convert(unitConversions.cubicMeterToCubicFeet, Number(value)); break;


      case IDS.REPORTED_GAS_RESERVES_SOURCE_ID: currentResourceAttributes.reportedRemainingGasSourceId = value; break;
      case IDS.REPORTED_GAS_RESERVES_EFFECTIVE_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid Reported Gas Reserves Effective Date');
          return false;
        }
        currentResourceAttributes.reportedRemainingGasEffectiveDate = value === undefined ? currentResourceAttributes.reportedRemainingGasEffectiveDate = null : moment(value).format('YYYY-MM-DD[T]hh:mm:ss.sss'); break;
      case IDS.REPORTED_COP: currentResourceAttributes.reportedCOPYear = value; break;
      case IDS.REPORTED_COP_SOURCE_ID: currentResourceAttributes.reportedCOPSourceId = value; break;
      case IDS.REPORTED_COP_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid Reported COP Date');
          return false;
        }
        currentResourceAttributes.reportedCOPDate = value === undefined ? currentResourceAttributes.reportedCOPDate = null : moment(value).format('YYYY-MM-DD[T]hh:mm:ss.sss'); break;

      //DATES
      case IDS.EXPECTED_SANCTION_YEAR: currentResourceAttributes.expectedSanctionYear = value; break;
      case IDS.FIELD_APPROVAL_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid Field Approval Date');
          return false;
        }
        currentResourceAttributes.fieldApprovalDate = value === undefined ? currentResourceAttributes.fieldApprovalDate = null : moment(value).format('YYYY-MM-DD[T]hh:mm:ss.sss'); break;
      case IDS.FIELD_PRODUCTION_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid Field Production Date');
          return false;
        }
        currentResourceAttributes.productionStartDate = value === undefined ? currentResourceAttributes.productionStartDate = null : moment(value).format('YYYY-MM-DD[T]hh:mm:ss.sss'); break;
      case IDS.PRODUCTION_CESSATION_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid Production Cessation Date');
          return false;
        }
        currentResourceAttributes.productionEndDate = value === undefined ? currentResourceAttributes.productionEndDate = null : moment(value).format('YYYY-MM-DD[T]hh:mm:ss.sss'); break;

      //ECONOMIC ATTRIBUTES
      //R/O - case IDS.BREAK_EVEN_YEAR: currentResourceAttributes.breakEvenYear = value; break;
      //R/O - case IDS.BREAK_EVEN_PRICE: currentResourceAttributes.breakEvenPrice = value; break;
      //R/O - case IDS.FULL_FIELD_NPV_USDMM: currentResourceAttributes.fullFieldNPVUSDMM = value; break;
      //R/O - case IDS.FULL_FIELD_NPV10_USDMM: currentResourceAttributes.fullFieldNPV10USDMM = value; break;
      //R/O - case IDS.FUL_INTERNAL_RATE_OF_RETURN_PERCENT: currentResourceAttributes.currentResourceAttributes = value; break;
      //R/O - case IDS.FULL_FIELD_OPERATING_COST: currentResourceAttributes.fullFieldOperatingCostUSDMM = value; break;
      //R/O - case IDS.POINT_FORWARD_NPV_USDMM: currentResourceAttributes.pointForwardNPVUSDMM = value; break;
      //R/O - case IDS.POINT_FORWARD_NPV10_USDMM: currentResourceAttributes.pointForwardNPV10USDMM = value; break;
      //R/O - case IDS.POINT_FORWARD_OPERATING_COST_USDMM: currentResourceAttributes.pointForwardOperatingCostUSDMM = value; break;

      default: throw new Error('Id ' + id + ' is not supported in AttributesComponent.valueChanged');
    }
    currentResourceAttributeActions.onEdit(currentResourceAttributes);
    return true;
  };

  const leftSections = getSectionsLeft(currentResourceAttributes, lookups, valueChanged);
  const rightSections = getSectionsRight(currentResourceAttributes, valueChanged);

  return (
    <div className="resource-attributes-component">
      <PropertyGridComponent className="left-grid" sections={leftSections} readOnly={!editing} />
      <PropertyGridComponent className="right-grid" sections={rightSections} readOnly={!editing} />
    </div>
  );
};

ResourceAttributesComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  currentResourceAttributes: PropTypes.instanceOf(ResourceProductionAttributes).isRequired,
  currentResourceAttributeActions: PropTypes.shape({ onEdit: PropTypes.func.isRequired }).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired
};

export default ResourceAttributesComponent;
