import React from 'react';
import PropTypes from 'prop-types';

import LeftPanelComponent from './left-panel-component';
import RightPanelComponent from './right-panel-component';

import { Attributes } from '../../models/result';
import { LookupResult } from '../../../../common/models/lookup-result';
import { LoadingGlobal, MessageBoxGlobal, PinLocationGlobal } from '../../../../common/globals/globals';

import SplitterPanelComponent from '../../../../shared/components/splitter-panel/splitter-panel-component';

const TopPanelComponent = (props) => {
  const {
    editing,
    loadingGlobal,
    messageBoxGlobal,
    pinLocationGlobal,
    lookups,
    attributes,
    updateAttributes,
    geoserverUrl,
    mapComponentName,
    contextLayerType,
    lookupsFiltered
  } = props;

  return (
    <SplitterPanelComponent vertical percentage secondarySize={50}>
      <LeftPanelComponent
        editing={editing}
        messageBoxGlobal={messageBoxGlobal}
        lookups={lookups}
        attributes={attributes}
        updateAttributes={updateAttributes}
        lookupsFiltered={lookupsFiltered}
      />
      <RightPanelComponent
        messageBoxGlobal={messageBoxGlobal}
        loadingGlobal={loadingGlobal}
        pinLocationGlobal={pinLocationGlobal}
        geoserverUrl={geoserverUrl}
        mapComponentName={mapComponentName}
        contextLayerType={contextLayerType}
        points={attributes.points}
      />
    </SplitterPanelComponent>
  );
};

TopPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  pinLocationGlobal: PropTypes.instanceOf(PinLocationGlobal).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  attributes: PropTypes.instanceOf(Attributes).isRequired,
  updateAttributes: PropTypes.func.isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  mapComponentName: PropTypes.string.isRequired,
  contextLayerType: PropTypes.number.isRequired,
  lookupsFiltered: PropTypes.instanceOf(LookupResult).isRequired,
};

export default TopPanelComponent;