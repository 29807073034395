class WestwoodDataFixedPlatform {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to WestwoodDataFixedPlatform.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'string') { throw new Error('Invalid obj.Id param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (typeof obj.name !== 'string') { throw new Error('Invalid obj.name param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.projectName !== undefined && obj.projectName !== null && typeof obj.projectName !== 'string') { throw new Error('Invalid obj.projectName param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.fieldName !== undefined && obj.fieldName !== null && typeof obj.fieldName !== 'string') { throw new Error('Invalid obj.fieldName param supplied to WestwoodDataFixedPlatform.ctor'); }

      if (obj.waterDepthMeters !== undefined && obj.waterDepthMeters !== null && typeof obj.waterDepthMeters !== 'number') { throw new Error('Invalid obj.waterDepthMeters param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.status !== undefined && obj.status !== null && typeof obj.status !== 'string') { throw new Error('Invalid obj.status param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.country !== undefined && obj.country !== null && typeof obj.country !== 'string') { throw new Error('Invalid obj.country param supplied to WestwoodDataFixedPlatform.ctor'); }

      if (obj.structureType !== undefined && obj.structureType !== null && typeof obj.structureType !== 'string') { throw new Error('Invalid obj.structureType param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.sizeCategory !== undefined && obj.sizeCategory !== null && typeof obj.sizeCategory !== 'string') { throw new Error('Invalid obj.sizeCategory param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.weightJacketTonnes !== undefined && obj.weightJacketTonnes !== null && typeof obj.weightJacketTonnes !== 'number') { throw new Error('Invalid obj.weightJacketTonnes param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.weightTopsidesTonnes !== undefined && obj.weightTopsidesTonnes !== null && typeof obj.weightTopsidesTonnes !== 'number') { throw new Error('Invalid obj.weightTopsidesTonnes param supplied to WestwoodDataFixedPlatform.ctor'); }

      if (obj.unmanned !== undefined && obj.unmanned !== null && typeof obj.unmanned !== 'boolean') { throw new Error('Invalid obj.unmanned param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.wellhead !== undefined && obj.wellhead !== null && typeof obj.wellhead !== 'boolean') { throw new Error('Invalid obj.wellhead param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.compression !== undefined && obj.compression !== null && typeof obj.compression !== 'boolean') { throw new Error('Invalid obj.compression param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.processing !== undefined && obj.processing !== null && typeof obj.processing !== 'boolean') { throw new Error('Invalid obj.processing param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.accommodation !== undefined && obj.accommodation !== null && typeof obj.accommodation !== 'boolean') { throw new Error('Invalid obj.accommodation param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.drilling !== undefined && obj.drilling !== null && typeof obj.drilling !== 'boolean') { throw new Error('Invalid obj.drilling param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.injection !== undefined && obj.injection !== null && typeof obj.injection !== 'boolean') { throw new Error('Invalid obj.injection param supplied to WestwoodDataFixedPlatform.ctor'); }

      if (obj.product !== undefined && obj.product !== null && typeof obj.product !== 'string') { throw new Error('Invalid obj.product param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.developmentType !== undefined && obj.developmentType !== null && typeof obj.developmentType !== 'string') { throw new Error('Invalid obj.developmentType param supplied to WestwoodDataFixedPlatform.ctor'); }
      if (obj.developmentStructure !== undefined && obj.developmentStructure !== null && typeof obj.developmentStructure !== 'string') { throw new Error('Invalid obj.licenceName param supplied to WestwoodDataFixedPlatform.ctor'); }
    }

    this.id = obj === null ? '' : obj.id;
    this.name = obj === null ? '' : obj.name ?? '';
    this.projectName = obj === null ? '' : obj.projectName ?? '';
    this.fieldName = obj === null ? '' : obj.fieldName ?? '';

    this.waterDepthMeters = obj === null ? 0 : obj.waterDepthMeters;
    this.status = obj === null ? '' : obj.status ?? '';
    this.country = obj === null ? '' : obj.country ?? '';

    this.structureType = obj === null ? '' : obj.structureType ?? '';
    this.sizeCategory = obj === null ? '' : obj.sizeCategory ?? '';
    this.weightJacketTonnes = obj === null ? 0 : obj.weightJacketTonnes;
    this.weightTopsidesTonnes = obj === null ? 0 : obj.weightTopsidesTonnes;

    this.unmanned = obj === null ? false : obj.unmanned ?? false;
    this.wellhead = obj === null ? false : obj.wellhead ?? false;
    this.compression = obj === null ? false : obj.compression ?? false;
    this.processing = obj === null ? false : obj.processing ?? false;
    this.accommodation = obj === null ? false : obj.accommodation ?? false;
    this.drilling = obj === null ? false : obj.drilling ?? false;
    this.injection = obj === null ? false : obj.injection ?? false;

    this.product = obj === null ? '' : obj.product ?? '';
    this.developmentType = obj === null ? '' : obj.developmentType ?? '';
    this.developmentStructure = obj === null ? '' : obj.developmentStructure ?? '';
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "WestwoodDataFixedPlatform.FromArray"');
    }

    const result = array.map(obj => new WestwoodDataFixedPlatform(obj));
    return result;
  }
}

export { WestwoodDataFixedPlatform };
