import React from 'react';
import PropTypes from 'prop-types';

import './right-panel-component.scss';

import MapComponent from '../../../../common/components/map/map-component';
import { MessageBoxGlobal, LoadingGlobal, PinLocationGlobal } from '../../../../common/globals/globals';

const RightPanelComponent = (props) => {
  const { messageBoxGlobal, loadingGlobal, pinLocationGlobal, geoserverUrl, mapComponentName, contextLayerType, points } = props;

  return (
    points === null || points === undefined ?
      <div className='no-map-data'>A valid location could not be found</div>
      :
      <MapComponent
        messageBoxGlobal={messageBoxGlobal}
        loadingGlobal={loadingGlobal}
        pinLocationGlobal={pinLocationGlobal}
        geoserverUrl={geoserverUrl}
        mapComponentName={mapComponentName}
        contextLayerType={contextLayerType}
        points={points} />
  );
};

RightPanelComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  pinLocationGlobal: PropTypes.instanceOf(PinLocationGlobal).isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  mapComponentName: PropTypes.string.isRequired,
  contextLayerType: PropTypes.number.isRequired,
};

export default RightPanelComponent;