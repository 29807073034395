import { Attributes } from '../models/result';
import { RECORD_STATUS } from '../../../common/models/record-status';

const editAttributes = (item) => {
  if (!(item instanceof Attributes)) {
    throw new Error('Invalid item param supplied to editAttributes');
  }

  item.status = item.status === RECORD_STATUS.added
    ? item.status
    : RECORD_STATUS.edited;
};

export { editAttributes };
