import React from 'react';
import './production-share-chart.scss';
import { appendClassNames } from '../../../shared/helpers/common';
import cloneDeep from 'lodash/cloneDeep';
import { WgeCorporateBlue, WgeLightGrey, WgeSectorsRed, WgeWildcatBlue, WgeAnalyticsGreen, WgeRigLogixYellow, WgeAtlasPurple, WgeEnergentBlue, WgeGlrGreen, WgeFarmOutsOrange, WgeRigEdgeBlue, WgeRigOutlookMaroon, WgeAdditional1, WgeAdditional2, WgeAdditional3, WgeAdditional4, WgeAdditional5, WgeAdditional6, WgeAdditional7, WgeAdditional8, WgeAdditional9, WgeAdditional10, WgeDarkGrey} from '../../../shared/scss/wge-colors';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
);

const ProductionShareChart = (props) => {
  const { className, resourceData, isStacked, chartType, startYear, endYear, xTitle, yTitle } = props;

  var entityData = cloneDeep(resourceData);

  entityData = entityData.filter(r => r.year >= startYear && r.year <= endYear);

  var actualChartData = entityData.map((item) => {
    return {
      year: item.year,
      resourceId: item.resourceId,
      hubProductionPercent: item.hubProductionPercent.toFixed(2),
      resourceName: item.resourceName
    }
  });

  actualChartData.sort((a, b) => a.year - b.year);

  var uniqueYears = [...new Set(actualChartData.map(item => item.year))];
  var uniqueResources = [...new Set(actualChartData.map(item => item.resourceId))];

  var chartData = {
    labels: uniqueYears, 
    datasets: []
  };

  const bgColorWW = [WgeCorporateBlue, WgeLightGrey, WgeSectorsRed, WgeWildcatBlue, WgeAnalyticsGreen, WgeRigLogixYellow, WgeAtlasPurple, WgeEnergentBlue, WgeGlrGreen, WgeFarmOutsOrange, WgeRigEdgeBlue, WgeRigOutlookMaroon, WgeAdditional1, WgeAdditional2, WgeAdditional3, WgeAdditional4, WgeAdditional5, WgeAdditional6, WgeAdditional7, WgeAdditional8, WgeAdditional9, WgeAdditional10, WgeDarkGrey];
  var startColourNumber = 0;

  uniqueResources.forEach(function (resource) { 
    var uniqueData = actualChartData.filter(r => r.resourceId === resource);
    var uniqueResourceName = uniqueData.length > 0 ? uniqueData[0].resourceName : "";

    //insert any missing years
    uniqueYears.forEach(function (year) {
      var yearDataRow = uniqueData.filter(y => y.year === year);
      if (yearDataRow === null || (yearDataRow !== null && yearDataRow.length === 0)) {
        uniqueData.push({ year: year, resourceId: resource, hubProductionPercent: 0, resourceName: uniqueResourceName});
      }
    });

    uniqueData.sort((a, b) => a.year - b.year); 

    chartData.datasets.push({
      label: uniqueResourceName,
      data: uniqueData.map(r => r.hubProductionPercent),
      backgroundColor: startColourNumber < 23 ? bgColorWW[startColourNumber] : bgColorWW[startColourNumber % 23],
      pointRadius: 0,
      fill: 'origin'
    });
    startColourNumber += 1;
  });

  var chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: isStacked === "true" ? true : false,
        ticks: {
          min: 0,
          max: 100
        },
        title: {
          text: yTitle,
          display: true
        },
      },
      x: {
        ticks: {
          minRotation: 45,
          autoSkip: true
        },
        title: {
          text: xTitle,
          display: true
        },
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      },
      filler: {
        propagate: true
      }
    }
  };

  let contentDiv = null;
  if (chartType === "Line") {
    contentDiv = (<Line data={chartData} options={chartOptions} />);
  }
  else if (chartType === "Bar") { contentDiv = (<Bar data={chartData} options={chartOptions} />); }

  return (
    <div className={appendClassNames('production-share-chart-component', className)}>
      {contentDiv}
    </div>
  );

};

export default ProductionShareChart;
