class PointModel {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid \'obj\' param supplied to \'PointModel.ctor\''); }
    if (typeof obj.x !== 'number') { throw new Error('Invalid \'obj.x\' param supplied to \'PointModel.ctor\''); }
    if (typeof obj.y !== 'number') { throw new Error('Invalid \'obj.y\' param supplied to \'PointModel.ctor\''); }

    this.x = obj.x;
    this.y = obj.y;

    this.compareShapes = PointModel.compareShapes;
  }

  destroy() {
    delete this.x;
    delete this.y;
    delete this;
  }

  get latLng() { return { lat: this.y, lng: this.x }; }

  static compareShapes = (a, b) => {
    if (typeof a !== 'object' || typeof b !== 'object') {
      throw new Error('Invalid "PointModel" param supplied to "PointModel.compareShapes"');
    }

    if (a.x !== b.x) return false;
    if (a.y !== b.y) return false;

    return true;
  }

}

export { PointModel };
