import React from 'react';
import PropTypes from 'prop-types';

import HubProductionChartComponent from '../shared/hub-production-chart-component';
import HubEmissionIntensityChartComponent from '../shared/hub-emission-intensity-chart-component';
import HubAttributesComponent from './hub-attributes-component';
import HubEmissionsOverridesComponent from './hub-emissions-overrides-component';
import DateTextGenericComponent from '../../../../../common/components/date-text-generic/date-text-generic-component';
import { HubText, HubAnnual, HubComment, HubProductionAttributes, ResourceAnnual, ResourceProductionAttributes } from '../../../models/production-result';
import { MessageBoxGlobal } from '../../../../../common/globals/globals';
import { LookupResult } from '../../../../../common/models/lookup-result';
import TabPanelComponent, { Tab } from '../../../../../shared/components/tab-panel/tab-panel-component';
import { ChartData } from '../../../../../shared/components/generic-chart/chart-data';
import { valueTypes } from '../../../../../shared/helpers/value-handler';

const HubPanelComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    allResourceAttributes,
    allResourceAnnuals,
    hubAnnuals,
    hubAttributes,
    hubEmissionOverrides,
    hubAttributeActions,
    hubMinYear,
    hubMaxYear,
    onChangeHubDescription,
    onChangeHubSnapshotComment,
    lookups,
    hubOverrideActions,
    selectedEmissionsOverrideRows,
    handleCalculatedValues,
    productionEmissions,
    emissionsIntensity
  } = props;

  const tabs = [
    new Tab('Hub Production', <HubProductionChartComponent allResourceAnnuals={allResourceAnnuals} hubAnnuals={hubAnnuals} allResourceAttributes={allResourceAttributes} hubMinYear={hubMinYear} hubMaxYear={hubMaxYear} />),
    new Tab('Hub Attributes', <HubAttributesComponent editing={editing} lookups={lookups} messageBoxGlobal={messageBoxGlobal} hubAttributes={hubAttributes} hubAttributeActions={hubAttributeActions} />),
    new Tab('Hub Client Description', <DateTextGenericComponent key="1" editing={editing} commentText={hubAttributes.clientChangeDescription} commentDate={hubAttributes.clientChangeDescriptionDate} onChange={onChangeHubDescription} />),  //TODO actual text and date from db
    new Tab('Hub Snapshot Comment', <DateTextGenericComponent key="2" editing={editing} commentText={hubAttributes.snapshopComment} commentDate={hubAttributes.snapshotCommentDate} onChange={onChangeHubSnapshotComment} />), //TODO actual text and date from db
    new Tab('Emissions Overrides', <HubEmissionsOverridesComponent lookups={lookups} editing={editing} hubEmissionOverrides={hubEmissionOverrides} selectedEmissionsOverrideRows={selectedEmissionsOverrideRows} messageBoxGlobal={messageBoxGlobal} hubOverrideActions={hubOverrideActions} handleCalculatedValues={handleCalculatedValues} />),
    new Tab('Production & ' + valueTypes.UnitString(valueTypes.CO2) + ' Emissions Intensity', <HubEmissionIntensityChartComponent chartData={productionEmissions} />),
    new Tab('Annual ' + valueTypes.UnitString(valueTypes.CO2) + ' Emissions & Intensity', <HubEmissionIntensityChartComponent chartData={emissionsIntensity} />)
  ];

  return <TabPanelComponent align="top" toolbarItems={tabs} selectedItemIndex={0} />;
};

HubPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  allResourceAttributes: PropTypes.arrayOf(PropTypes.instanceOf(ResourceProductionAttributes)).isRequired,
  allResourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  hubAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(HubAnnual)).isRequired,
  hubAttributes: PropTypes.instanceOf(HubProductionAttributes),
  hubAttributeActions: PropTypes.shape({ onEdit: PropTypes.func.isRequired }).isRequired,
  hubMinYear: PropTypes.number.isRequired,
  hubMaxYear: PropTypes.number.isRequired,
  hubComments: PropTypes.arrayOf(PropTypes.instanceOf(HubComment)).isRequired,
  hubCommentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  hubDescriptions: PropTypes.arrayOf(PropTypes.instanceOf(HubText)).isRequired,
  hubDescriptionActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  productionEmissions: PropTypes.instanceOf(ChartData),
  emissionsIntensity: PropTypes.instanceOf(ChartData)
};

HubPanelComponent.defaultProps = {
  productionEmissions: null,
  emissionsIntensity: null
};

export default HubPanelComponent;
