import React from 'react';
import PropTypes from 'prop-types';

import { CorporateAttributes } from '../../models/corporate-result';
import { IN_DEV_MODE } from '../../../../shared/helpers/common';
import { LookupResult } from '../../../../common/models/lookup-result';

import PropertyGridComponent, { PropertyGridStringValue, PropertyGridSelectValue, PropertyGridBooleanValue } from '../../../../shared/components/property-grid/property-grid-component';

const IDS = {
  CORPORATE_ID: 5,
  CORPORATE_NAME: 10,
  CORPORATE_SHORT_NAME: 15,
  COUNTRY_LOOKUP_ID: 20,
  CORPORATE_CATEGORY_ID: 26,
  ACTIVE: 35,
  CORE: 40
};
Object.freeze(IDS);

const getSections = (corporateAttributes, lookups, valueChanged) => {

  const result = [
    {
      label: 'Corporate',
      rows: [
        { label: 'Corporate Name', values: [new PropertyGridStringValue(IDS.CORPORATE_NAME, corporateAttributes.corporateName, valueChanged)] },
        //{ label: 'Corporate Short Name', values: [new PropertyGridStringValue(IDS.CORPORATE_SHORT_NAME, corporateAttributes.corporateShortName)] },
        { label: 'Country Lookup', values: [new PropertyGridSelectValue(IDS.COUNTRY_LOOKUP_ID, corporateAttributes.countryLookUpId, valueChanged, 'key', 'value', lookups.CorporateCountry)] },
        { label: 'Corporate Category', values: [new PropertyGridSelectValue(IDS.CORPORATE_CATEGORY_ID, corporateAttributes.corporateCategoryId, valueChanged, 'key', 'value', lookups.CorporateCategory)] },
        { label: 'Active: Yes/No', values: [new PropertyGridBooleanValue(IDS.ACTIVE, corporateAttributes.active)] },
        { label: 'Core/Non Core', values: [new PropertyGridSelectValue(IDS.CORE, corporateAttributes.coreLookUpID, valueChanged, 'key', 'value', lookups.Core)] }
      ]
    }
  ];

  if (IN_DEV_MODE) {
    result[0].rows.unshift({ label: 'Corporate Id', values: [new PropertyGridStringValue(IDS.CORPORATE_ID, corporateAttributes.id)] });
  }

  return result;
};

const TopPanelComponent = (props) => {
  const {
    editing,
    lookups,
    attributes,
    updateAttributes
  } = props;

  const valueChanged = (value, id) => {
    switch (id) {
      case IDS.CORPORATE_NAME:
        if (attributes.status === 1) {
          attributes.corporateName = value;
          attributes.corporateShortName = value;
        }
        break;
      case IDS.COUNTRY_LOOKUP_ID: attributes.countryLookUpId = Number(value); break;
      case IDS.CORPORATE_CATEGORY_ID: attributes.corporateCategoryId = Number(value); break;
      case IDS.CORE:
        attributes.core = Boolean(Number(value));
        attributes.coreLookUpID = Number(value);
        break;

      default: throw new Error('Id ' + id + ' is not supported in AttributesComponent.valueChanged');
    }
    updateAttributes(attributes)
  };

  const sections = getSections(attributes, lookups, valueChanged);

  return <PropertyGridComponent sections={sections} readOnly={!editing} />;
};

TopPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  attributes: PropTypes.instanceOf(CorporateAttributes).isRequired,
  updateAttributes: PropTypes.func.isRequired,
};

export default TopPanelComponent;
