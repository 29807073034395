import { PointModel } from "./point-model";

class PolygonModel {
  constructor(obj) {
    if (typeof obj !== 'object') { throw new Error('Invalid \'obj\' param supplied to \'PolygonModel.ctor\''); }
    if (!Array.isArray(obj.coordinates)) { throw new Error('Invalid \'obj.coordinates\' param supplied to \'PolygonModel.ctor\''); }

    this.coordinates = obj.coordinates.map(obj => new PointModel(obj));

    this.compareShapes = PolygonModel.CompareShape;
  }

  destroy() {

    Object.keys(this.coordinates).forEach(key => {
      this.coordinates[key].destroy();
    });

    delete this.coordinates;
  }

  get latLngs() { return this.coordinates.map(obj => obj.latLng); }

  static CompareShape = (a, b) => {
    if (typeof a !== 'object' || typeof b !== 'object') {
      throw new Error('Invalid "PolygonModel" param supplied to "PolygonModel.compareShapes"');
    }

    if (a.coordinates.length !== b.coordinates.length) return false;

    for (var i = 0; i < a.coordinates.length; i++) {
      if (a.coordinates[i].x !== b.coordinates[i].x) return false;
      if (a.coordinates[i].y !== b.coordinates[i].y) return false;
    }

    return true;
  }

}

const defaultOptions = {
  borderWidth: 2,
  borderColor: 'red',
  fillColor: 'red',
  popup: undefined
};

class PolygonsModel {
  constructor(polygons, options = {}) {
    if (!Array.isArray(polygons)) { throw new Error('Invalid \'polygons\' param supplied to \'PolygonsModel.ctor\''); }
    if (typeof options !== 'object') { throw new Error('Invalid \'options\' supplied to \'PolygonModel.ctor\''); }

    this.polygons = polygons.map(obj => new PolygonModel(obj));
    this.options = { ...defaultOptions, ...options };

    this.compareShapes = PolygonsModel.compareShapes;
  }

  destroy() {
    Object.keys(this.polygons).forEach(key => {
      this.polygons[key].destroy();
    });

    delete this.polygons;
    delete this.options;
  }

  get latLngs() { return this.polygons.map(obj => obj.latLngs); }

  static compareShapes = (a, b) => {
    if (typeof a !== 'object' || typeof b !== 'object') {
      throw new Error('Invalid "CircleModel" param supplied to "CircleModel.compareShapes"');
    }

    if (a.length !== b.length) return false;
    for (var i = 0; i < a.length; i++) {
      if (a[i].polygons.length !== b[i].polygons.length) return false;

      for (var j = 0; j < a[i].polygons.length; j++) {
        if (!PolygonModel.CompareShape(a[i].polygons[j], b[i].polygons[j])) return false;
      }
    }

    return true;
  }

}

export { PolygonModel, PolygonsModel };
