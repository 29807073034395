import PropTypes from 'prop-types';

import './integrity-grid-component.scss';
import { MessageBoxGlobal, ViewGlobal, Views } from '../../common/globals/globals';
import AGGridComponent, { GRIDTYPES } from '../../common/components/grid/ag-grid-component';
import GeneralFormatter from '../../common/components/grid/formatters/general-formatter';
import { IssueType } from '../../common/components/editable-view/issue-type';

const IntegrityGridComponent = (props) => {
  const { data } = props;

  const INTEGRITY_CHECK_ENTITY = {
    BLOCK: 1,
    PRODUCTION: 2,
    RESOURCE_3: 3,
    RESOURCE_4: 4,
    WELL: 5,
    CORPORATE: 6,
    LICENCE: 8,
    HUB: 9,
    MAP: 10
  };
  Object.freeze(INTEGRITY_CHECK_ENTITY);

  const ImageFormatter = (params) => {
    var errorCode = params.data.integrity_check_type === 1 ? 0 : params.data.integrity_check_type === 2 ? 1 : -1;
    //if (errorCode === -1 || (params.data.integrity_check_field !== 'resource_id' && params.data.integrity_check_field !== 'hub_id' && params.data.integrity_check_field !== 'block_id' && params.data.integrity_check_field !== 'corporate_id'))
    //  return "";

    var imageSrc = IssueType.image(errorCode);

    const result =
      <img
        style={{ height: '17px', width: '17px', display: 'block', position: 'relative', top: '2px', left: '3px', cursor: 'pointer' }}
        src={imageSrc}
        alt={''}
        onClick={() => params.data.integrity_check_entity !== 10 ? navigate(params.data.integrity_check_entity, params.data.integrity_check_entity_name, params.data.integrity_check_primary_key) : null}
      />
    return result;
  };

  const columnDefs = [];
  columnDefs.push({ headerName: 'Type', suppressHeaderMenuButton: true, resizable: true, field: 'integrity_type', sortable: false, filter: false, editable: false, width: 55, maxWidth: 55, cellRenderer: ImageFormatter, type: 'image' });
  columnDefs.push({ headerName: 'Category', suppressHeaderMenuButton: true, resizable: true, field: 'category_name', sortable: true, filter: false, editable: false, width: 100, maxWidth: 100, cellRenderer: GeneralFormatter, type: 'text' });
  columnDefs.push({ headerName: 'Description', suppressHeaderMenuButton: true, resizable: true, field: 'integrity_check_description', sortable: true, filter: false, editable: false, flex: 1, /*width: '10',*/ cellRenderer: GeneralFormatter, type: 'text' });
  columnDefs.push({ headerName: 'Country', suppressHeaderMenuButton: true, resizable: true, field: 'country_name', sortable: true, filter: false, editable: false, width: 100, maxWidth: 100, cellRenderer: GeneralFormatter, type: 'text' });

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {},
    image: {}
  };

  const navigate = (integrityCheckEntity, integrityCheckEntityName, integrityCheckPrimaryKey) => {
    switch (integrityCheckEntity) {

      case INTEGRITY_CHECK_ENTITY.BLOCK:
        props.viewGlobal.setAndId(Views.Block, integrityCheckPrimaryKey, integrityCheckEntityName); break;

      case INTEGRITY_CHECK_ENTITY.PRODUCTION:
        props.viewGlobal.setAndId(Views.Production, integrityCheckPrimaryKey, integrityCheckEntityName); break;

      case INTEGRITY_CHECK_ENTITY.RESOURCE_3:
      case INTEGRITY_CHECK_ENTITY.RESOURCE_4:
        props.viewGlobal.setAndId(Views.Resource, integrityCheckPrimaryKey, integrityCheckEntityName); break;

      case INTEGRITY_CHECK_ENTITY.WELL:
        props.viewGlobal.setAndId(Views.Well, integrityCheckPrimaryKey, integrityCheckEntityName); break;

      case INTEGRITY_CHECK_ENTITY.CORPORATE:
        props.viewGlobal.setAndId(Views.Corporate, integrityCheckPrimaryKey, integrityCheckEntityName); break;

      case INTEGRITY_CHECK_ENTITY.LICENCE:
        props.viewGlobal.setAndId(Views.License, integrityCheckPrimaryKey, integrityCheckEntityName); break;

      case INTEGRITY_CHECK_ENTITY.HUB:
        props.viewGlobal.setAndId(Views.Hub, integrityCheckPrimaryKey, integrityCheckEntityName); break;

      default:
        throw new Error('Unsupported integrityCheckEntity (' + integrityCheckEntity + ') supplied to HomeView.navigate()');
    }
  };

  return (
    <div className="integrity-grid-component">
      <AGGridComponent
        headerRowHeight={25}
        columnDefs={columnDefs}
        columnTypes={columnTypes}
        gridData={data}
        rowsCount={data.length}
        messageBoxGlobal={props.messageBoxGlobal}
        allowPaste={false}
        editYear={0}
        autoResize
        groupHeaderHeight={0}
        gridType={GRIDTYPES.INTEGRITY}
      />
    </div>
  );
};

IntegrityGridComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    country_name: PropTypes.string.isRequired,
    integrity_check_description: PropTypes.string.isRequired,
    integrity_check_entity: PropTypes.number.isRequired,
    integrity_check_entity_name: PropTypes.string.isRequired,
    integrity_check_field: PropTypes.string.isRequired,
    integrity_check_primary_key: PropTypes.number.isRequired,
    integrity_check_table: PropTypes.string.isRequired,
    integrity_check_type: PropTypes.number.isRequired
  })).isRequired,
  viewGlobal: PropTypes.instanceOf(ViewGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired
};

export default IntegrityGridComponent;
