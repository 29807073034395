import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';

import './app-component.scss';
import InvalidVersionComponent from './invalid-version-component';
import { IN_DEV_MODE } from '../../shared/helpers/common';
import LoadingComponent from '../../shared/components/loading/loading-component';
import SidebarComponent from './sidebar-component';
import MessageBoxComponent from '../../shared/components/message-box/message-box-component';
import SplitterPanelComponent from '../../shared/components/splitter-panel/splitter-panel-component';
import SliderPanelComponent from '../../shared/components/slider-panel/slider-panel-component';
import { apiPost, apiGet } from '../../common/helpers/api-helpers';
import { IdGlobal, SearchGlobal, EditingGlobal, PrintModeGlobal, ViewGlobal, Views, CountriesGlobal, MessageBoxGlobal, LoadingGlobal, LoadingImage, PinLocationGlobal } from '../../common/globals/globals';
// Views
import BlockView from '../../views/block/block-view';
import CorporateView from '../../views/corporate/corporate-view';
import HistoricProspectView from '../../views/historic-prospect/historic-prospect-view';
import HomeView from '../../views/home/home-view';
import HubView from '../../views/hub/hub-view';
import LicenseView from '../../views/license/license-view';
import LicenseRoundView from '../../views/license-round/license-round-view';
import ProductionView from '../../views/production/production-view';
import RelinquishmentsView from '../../views/relinquishments/relinquishments-view';
import ResourceView from '../../views/resource/resource-view';
import EmissionsUploaderView from '../../views/emissions-uploader/emissions-uploader-view';
import RigView from '../../views/rig/rig-view';
import RigContractsView from '../../views/rig-contracts/rig-contracts-view';
import VariablesView from '../../views/variables/variables-view';
import WellView from '../../views/well/well-view';
import WellProgrammeView from '../../views/well-programme/well-programme-view';
import TestView from '../../views/test/test-view';
//import QueriesView from '../../views/queries/queries-view';
import QueriesView from '../../views/queries/queries-view-new';
import FiscalView from '../../views/fiscal/fiscal-view';
import SpatialView from '../../views/spatial/spatial-view';
import PhilTestView from '../../views/test/phil-test-view';
import JimTestView from '../../views/test/jim-test-view';
import RobTestView from '../../views/test/rob-test-view';
import NSTALicenceCompareView from '../../views/nsta-licence-compare/nsta-licence-compare';
import NPDWellsCompareView from '../../views/npd-wells-compare/npd-wells-compare';
import HermesCompareView from '../../views/hermes/hermes-compare-view';
import HermesManagementView from '../../views/hermes-management/hermes-management-view';
import AutoUpdateView from '../../views/auto-update/auto-update-view';
import HubUpsideView from '../../views/hub-upside/hub-upside-view';

import { Groups } from '../../common/models/groups';

const SIDEBAR_COLLAPSED_WIDTH = 34;
const NEW_RECORD_ID = -999999;
const COPY_RECORD_ID = -999;
const SETTINGS_KEY = 'GME4_SETTINGS';

class AppComponent extends React.Component {
  constructor(props) {
    super(props);

    //localStorage.clear(); //use this to clear out local storage for testing
    this._loadingRef = React.createRef();

    this.lastVersionCheck = null;
    this.versionValid = null;
    this.userGroups = null;
    this.sidebarExpandedWidth = 300;
    this.currLoadingImage = LoadingImage.Loading;
    this.loadingCount = 0;
    this.loadingImage = this.currLoadingImage;
    this.loadingTimeout = null;
    this.yearStartChange = this.yearStartChange.bind(this);
    this.yearEndChange = this.yearEndChange.bind(this);

    //var storedSelectedStartYear = Number(localStorage.getItem('storedSelectedStartYear'));
    //var storedSelectedEndYear = Number(localStorage.getItem('storedSelectedEndYear'));
    //if (storedSelectedStartYear === null) storedSelectedStartYear = 0;
    //if (storedSelectedEndYear === null) storedSelectedEndYear = 0;

    var storedSelectedStartYear = localStorage.getItem('storedSelectedStartYear') === null ? 0 : localStorage.getItem('storedSelectedStartYear') === "Min" ? 0 : localStorage.getItem('storedSelectedStartYear') === "Max" ? 9999 : Number(localStorage.getItem('storedSelectedStartYear'));
    var storedSelectedEndYear = localStorage.getItem('storedSelectedEndYear') === null ? 9999 : localStorage.getItem('storedSelectedEndYear') === "Min" ? 0 : localStorage.getItem('storedSelectedEndYear') === "Max" ? 9999 : Number(localStorage.getItem('storedSelectedEndYear'));


    //var storedSelectedStartYear = localStorage.getItem('storedSelectedStartYear');
    //var storedSelectedEndYear = localStorage.getItem('storedSelectedEndYear');
    //if (storedSelectedStartYear === null) storedSelectedStartYear = 0;
    //if (storedSelectedEndYear === null) storedSelectedEndYear = 0;

    this.state = {
      printMode: false,
      sidebarWidth: this.sidebarExpandedWidth,
      id: null,
      view: IN_DEV_MODE ? Views.HubUpside : Views.Home,
      editing: false,
      countries: [1, 2], // 1, 2 = United Kingdom, Norway
      searchString: '',
      searchResults: [],
      message: null,
      loading: false,
      selectedStartYear: storedSelectedStartYear,
      selectedEndYear: storedSelectedEndYear,
      pinLocation: null,
      geoserverUrl: 'web/wms'
    };
  }

  componentDidMount() {
    this.checkVersion();
    this.getUserGroups();
    //this.testWestwoodData();
  }

  componentDidUpdate() {
    this.checkVersion();
    this.getUserGroups();
  }

  get settings() {
    const str = localStorage.getItem(SETTINGS_KEY);
    return typeof str === 'string' ? JSON.parse(str) : { searchHistory: [], navigationHistory: [] };
  }
  set settings(value) {
    localStorage.setItem(SETTINGS_KEY, JSON.stringify(value));
  }

  get searchHistory() { return this.settings.searchHistory; }
  set searchHistory(value) {
    if (!Array.isArray(value) || value.filter(obj => typeof obj !== 'string').length > 0) {
      throw new Error('Invalid value param supplied to AppComponent.searchHistory.set()');
    }
    this.settings = { ...this.settings, searchHistory: value };
  }

  get navigationHistory() { return this.settings.navigationHistory; }
  set navigationHistory(value) {
    //TODO: validate array values are nav history items
    if (!Array.isArray(value)) {
      throw new Error('Invalid value param supplied to AppComponent.navigationHistory.set()');
    }
    this.settings = { ...this.settings, navigationHistory: value };
  }

  get minutesSinceLastVersionCheck() {
    if (this.lastVersionCheck === null) {
      return null;
    }

    const now = new Date();
    const diffMs = (now - this.lastVersionCheck); // milliseconds
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffMins;
  }

  loader = (show) => {
    if (this._loadingRef !== undefined && this._loadingRef !== null && this._loadingRef.current !== undefined && this._loadingRef.current !== null) {
      this._loadingRef.current.show(show);
    }
  }

  showPrintMode = (callback = undefined) => {
    this.setPrintMode(true, callback);
  }

  hidePrintMode = (callback = undefined) => {
    this.setPrintMode(false, callback);
  }

  checkVersion = () => {
    const lastCheck = this.minutesSinceLastVersionCheck;
    if (lastCheck === null || lastCheck >= 5) {
      this.lastVersionCheck = new Date();
      const onSuccess = (result) => {
        this.versionValid = result.major === this.props.version.major && result.minor === this.props.version.minor && result.build === this.props.version.build;
        this.forceUpdate();
      };
      apiGet('api/app/version', this.showLoading, this.hideLoading, this.createMessageBoxGlobal(), onSuccess, undefined);
    }
  }

  getUserGroups = () => {
    const onSuccess = (result) => {
      this.userGroups = new Groups(result);
    };
    apiGet('api/app/userGroups', this.showLoading, this.hideLoading, this.createMessageBoxGlobal(), onSuccess, undefined);
  }

  testWestwoodData = () => {
    //const onSuccess = () => {
    //  console.log(' called westwoodData api');
    //};
    //apiGet('api/westwoodData/load', this.showLoading, this.hideLoading, this.createMessageBoxGlobal(), onSuccess, undefined);


    //const onSuccess = (result) => {
    //  console.log(' called westwoodData lookups api');
    //};
    //apiGet('api/well/westwoodlookups', this.showLoading, this.hideLoading, this.createMessageBoxGlobal(), onSuccess, undefined);
  }


  setId = (id, displayName, callback = undefined) => {
    if (id !== null && id !== NEW_RECORD_ID && id !== COPY_RECORD_ID) {
      if (id !== null && typeof id !== 'number') {
        throw new Error('Invalid id param supplied to AppComponent.setId');
      }
      if (typeof displayName !== 'string') {
        throw new Error('Invalid displayName param supplied to AppComponent.setId');
      }
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to AppComponent.setId');
    }

    this.setState(prevState => {
      return prevState.id === id
        ? null // Don't update
        : { id: id };
    }, () => this.addNavigationHistory(displayName, callback));
  }

  setView = (view, callback = undefined) => {
    if (!Views.isValid(view)) {
      throw new Error('Invalid view param supplied to AppComponent.setView');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to AppComponent.setView');
    }

    this.setState(prevState => {
      return prevState.view === view
        ? null // Don't update
        : { view: view, id: null, searchString: '', searchResults: [] };
    }, callback);
  }

  setViewAndId = (view, id, displayName, callback = undefined) => {
    if (!Views.values().includes(view)) {
      throw new Error('Invalid view param supplied to AppComponent.setViewAndId');
    }
    if (id !== null && typeof id !== 'number') {
      throw new Error('Invalid id param supplied to AppComponent.setViewAndId');
    }
    if (displayName !== null && typeof displayName !== 'string') {
      throw new Error('Invalid displayName param supplied to AppComponent.setViewAndId');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to AppComponent.setViewAndId');
    }

    this.setState(prevState => {
      return prevState.view === view && prevState.id === id
        ? null // Don't update
        : { view: view, id: id };
    }, () => this.addNavigationHistory(displayName, callback));
  }

  setEditing = (editing, callback = undefined) => {
    if (typeof editing !== 'boolean') {
      throw new Error('Invalid editing param supplied to AppComponent.setEditing');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to AppComponent.setEditing');
    }

    this.setState(prevState => {
      return prevState.editing === editing
        ? null // Don't update
        : { editing: editing };
    }, callback);
  }

  setPrintMode = (printMode, callback = undefined) => {
    if (typeof printMode !== 'boolean') {
      throw new Error('Invalid printMode param supplied to AppComponent.setPrintMode');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to AppComponent.setPrintMode');
    }

    this.setState(prevState => {
      return prevState.printMode === printMode
        ? null // Don't update
        : { printMode: printMode };
    }, callback);
  }

  setCountries = (countries, callback = undefined) => {
    if (!Array.isArray(countries) || countries.filter(obj => typeof obj !== 'number').length > 0) {
      throw new Error('Invalid countries param supplied to AppComponent.setCountries');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to AppComponent.setCountries');
    }

    this.setState(prevState => {
      return prevState.countries === countries
        ? null // Don't update
        : { countries: countries };
    }, callback);
  }

  setSearch = (searchString, searchResults, callback = undefined) => {
    if (typeof searchString !== 'string') {
      throw new Error('Invalid searchString param supplied to AppComponent.setSearch');
    }
    if (!Array.isArray(searchResults)) {
      throw new Error('Invalid searchResults param supplied to AppComponent.setSearch');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to AppComponent.setSearch');
    }

    this.setState(prevState => {
      let result = {};
      if (prevState.searchString !== searchString) {
        result.searchString = searchString;
      }
      if (prevState.searchResults !== searchResults) {
        result.searchResults = searchResults;
      }
      return Object.keys(result).length === 0
        ? null // Don't update
        : result;
    }, callback);
  }

  setSearchString = (searchString, callback = undefined) => {
    if (typeof searchString !== 'string') {
      throw new Error('Invalid searchString param supplied to AppComponent.setSearch');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to AppComponent.setSearch');
    }

    this.setState(prevState => {
      return prevState.searchString === searchString
        ? null // Don't update
        : { searchString: searchString };
    }, callback);
  }

  setSearchResults = (searchResults, callback = undefined) => {
    if (!Array.isArray(searchResults)) {
      throw new Error('Invalid searchResults param supplied to AppComponent.setSearch');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to AppComponent.setSearch');
    }

    this.setState(prevState => {
      return prevState.searchResults === searchResults
        ? null // Don't update
        : { searchResults: searchResults };
    }, callback);
  }

  setMessage = (message, callback = undefined) => {
    if (message !== null && message === undefined) {
      throw new Error('Invalid message param supplied to AppComponent.setMessage');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to AppComponent.setMessage');
    }

    this.setState(prevState => {
      return prevState.message === message
        ? null // Don't update
        : { message: message };
    }, callback);
  }

  setPinLocation = (pinLocation, callback = undefined) => {
    if (!pinLocation instanceof L.LatLng) {
      throw new Error('Invalid pinLocation param supplied to AppComponent.setPinLocation');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to AppComponent.setPinLocation');
    }

    this.setState(prevState => {
      return prevState.pinLocation === pinLocation
        ? null // Don't update
        : { pinLocation: pinLocation };
    }, callback);
  }

  showLoading = (image = null, delay = 750, callback = undefined) => {
    if (image !== null && !LoadingImage.values().includes(image)) {
      throw new Error('Invalid image param supplied to AppComponent.showLoading');
    }
    if (!Number.isInteger(delay) || delay < 0) {
      throw new Error('Invalid "delay" param supplied to "AppComponent.showLoading"');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "AppComponent.showLoading"');
    }

    if (image !== null) {
      this.currLoadingImage = image;
    }
    this.loadingCount++;
    this.loadingImage = this.currLoadingImage;

    const doShowLoading = () => {
      if (this.loadingCount > 0) {
        this.loader(true);
      }
    };

    if (this.loadingImage !== LoadingImage.Logo) {
      doShowLoading();
    } else if (this.loadingTimeout === null) {
      this.loadingTimeout = setTimeout(doShowLoading, delay);
    }
  }

  hideLoading = (callback = undefined) => {
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid "callback" param supplied to "AppComponent.hideLoading"');
    }

    this.loadingCount = Math.max(this.loadingCount - 1, 0)
    this.loadingImage = this.currLoadingImage;
    if (this.loadingCount === 0) {
      this.currLoadingImage = LoadingImage.Loading;
    }

    if (this.loadingCount <= 0) {
      this.loader(false);
    }
  }

  toggleSidebar = () => {
    this.setState(prevState => {
      const size = prevState.sidebarWidth > SIDEBAR_COLLAPSED_WIDTH
        ? SIDEBAR_COLLAPSED_WIDTH
        : this.sidebarExpandedWidth;
      const result = prevState.sidebarWidth === size
        ? null // Don't update
        : { sidebarWidth: size };
      return result;
    });
  }

  onSidebarSizeChange = (size, dragging) => {
    if (typeof size !== 'number') {
      throw new Error('Invalid size param \'' + size + '\' supplied to App.onSidebarSizeChange');
    }
    if (typeof dragging !== 'boolean') {
      throw new Error('Invalid dragging param \'' + dragging + '\' supplied to App.onSidebarSizeChange');
    }

    if (dragging === false && this.state.printMode === false) {
      if (size > SIDEBAR_COLLAPSED_WIDTH) {
        this.sidebarExpandedWidth = size;
      }
      this.setState(prevState => {
        const result = prevState.sidebarWidth === size
          ? null // Don't update
          : { sidebarWidth: size };
        return result;
      });
    }
  }

  search = (searchString, addToSearchHistory = true, callback = undefined) => {
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to AppComponent.search');
    }

    const { view, countries } = this.state;

    if (addToSearchHistory && searchString !== '') {
      let searchHistory = [...this.searchHistory];

      const idx = searchHistory.findIndex(obj => obj.toLowerCase() === searchString.toLowerCase());
      if (idx !== -1) {
        searchHistory.splice(idx, 1);
      }
      searchHistory.unshift(searchString);
      const MAX_COUNT = 10;
      if (searchHistory.length > MAX_COUNT) {
        searchHistory = searchHistory.slice(0, MAX_COUNT);
      }

      this.searchHistory = searchHistory;
    }

    const apiUrl = Views.apiUrl(view);
    const headerBody = { searchString: searchString, countryIds: countries };
    const onSuccess = (result) => this.setSearch(searchString, result, callback);
    apiPost(apiUrl, JSON.stringify(headerBody), this.showLoading, this.hideLoading, this.createMessageBoxGlobal(), onSuccess, undefined);
  }

  addNavigationHistory = (displayName, callback) => {
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to AppComponent.setId');
    }

    const { view, id } = this.state;
    if (id !== null && id !== NEW_RECORD_ID && id !== COPY_RECORD_ID && !Views.reportOnly(view)) {
      if (typeof displayName !== 'string') {
        throw new Error('Invalid displayName param supplied to AppComponent.addNavigationHistory');
      }

      let navigationHistory = [...this.navigationHistory];

      const idx = navigationHistory.findIndex(obj => obj.view === view && obj.id === id);
      if (idx !== -1) {
        navigationHistory.splice(idx, 1);
      }

      navigationHistory.unshift({ view: view, id: id, displayName: displayName });
      const MAX_COUNT = 10;
      if (navigationHistory.length > MAX_COUNT) {
        navigationHistory = navigationHistory.slice(0, MAX_COUNT);
      }

      this.navigationHistory = navigationHistory;
    }

    if (typeof callback === 'function') {
      callback();
    }
  }

  createIdGlobal() {
    return new IdGlobal(
      this.state.id,
      this.setId,
      (callback) => this.setId(NEW_RECORD_ID, null, callback),
      this.state.id !== null,
      this.state.id === NEW_RECORD_ID,
      (callback) => this.setId(null, null, callback),
      (callback) => this.setId(COPY_RECORD_ID, null, callback),
      this.state.id === COPY_RECORD_ID);
  }

  createViewGlobal() {
    return new ViewGlobal(this.state.view, this.setView, this.setViewAndId);
  }

  createEditingGlobal() {
    return new EditingGlobal(this.state.editing, this.setEditing);
  }

  createPrintModeGlobal() {
    const value = this.state.printMode;
    const set = (printMode, callback) => this.setPrintMode(printMode, callback);
    const open = (callback) => this.setPrintMode(true, callback);
    const close = (callback) => this.setPrintMode(false, callback);
    return new PrintModeGlobal(value, set, open, close);
  }

  createCountriesGlobal() {
    return new CountriesGlobal(this.state.countries, this.setCountries);
  }

  createSearchGlobal() {
    return new SearchGlobal(this.state.searchString, this.state.searchResults, this.setSearch, this.setSearchString, this.setSearchResults, this.search, () => this.searchHistory, () => this.searchHistory = []);
  }

  createMessageBoxGlobal() {
    return new MessageBoxGlobal(this.state.message, this.setMessage, this.state.message !== null, () => this.setMessage(null), this.props.logout);
  }

  createLoadingGlobal() {
    return new LoadingGlobal(this.showLoading, this.hideLoading);
  }

  createPinLocationGlobal() {
    return new PinLocationGlobal(this.state.pinLocation, this.setPinLocation);
  }

  yearStartChange(e) {
    this.setState({ selectedStartYear: Number(e) });
    localStorage.setItem('storedSelectedStartYear', e);
  }

  yearEndChange(e) {
    this.setState({ selectedEndYear: Number(e) });
    localStorage.setItem('storedSelectedEndYear', e);
  }

  getViewComponent = (view, printMode, idGlobal, viewGlobal, editingGlobal, printModeGlobal, countriesGlobal, searchGlobal, messageBoxGlobal, loadingGlobal, pinLocationGlobal, geoserverUrl) => {
    switch (view) {
      case Views.Home: return <HomeView loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} viewGlobal={viewGlobal} countriesGlobal={countriesGlobal} />;
      case Views.Production: return <ProductionView viewGlobal={viewGlobal} editingGlobal={editingGlobal} idGlobal={idGlobal} loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} countriesGlobal={countriesGlobal} searchGlobal={searchGlobal} yearStartChange={this.yearStartChange} yearEndChange={this.yearEndChange} selectedStartYear={this.state.selectedStartYear} selectedEndYear={this.state.selectedEndYear} />;
      case Views.Resource: return <ResourceView
        viewGlobal={viewGlobal}
        editingGlobal={editingGlobal}
        idGlobal={idGlobal}
        loadingGlobal={loadingGlobal}
        messageBoxGlobal={messageBoxGlobal}
        countriesGlobal={countriesGlobal}
        searchGlobal={searchGlobal}
        pinLocationGlobal={pinLocationGlobal}
        geoserverUrl={geoserverUrl}
      />;

      case Views.Fiscal: return <FiscalView printModeGlobal={printModeGlobal} idGlobal={idGlobal} loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} />;
      case Views.NSTACompare: return <NSTALicenceCompareView loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} editingGlobal={editingGlobal} />;
      case Views.NPDWellsCompare: return <NPDWellsCompareView loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} editingGlobal={editingGlobal} />;
      case Views.HermesCompareView: return <HermesCompareView loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} editingGlobal={editingGlobal} />;
      case Views.HermesManagementView: return <HermesManagementView loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} editingGlobal={editingGlobal} />;
      case Views.AutoUpdateView: return <AutoUpdateView loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} editingGlobal={editingGlobal} />;
      case Views.EmissionsUploader: return <EmissionsUploaderView loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} editingGlobal={editingGlobal} />;
      case Views.Block: return <BlockView
        viewGlobal={viewGlobal}
        idGlobal={idGlobal}
        loadingGlobal={loadingGlobal}
        messageBoxGlobal={messageBoxGlobal}
        countriesGlobal={countriesGlobal}
        searchGlobal={searchGlobal}
        editingGlobal={editingGlobal}
        pinLocationGlobal={pinLocationGlobal}
        geoserverUrl={geoserverUrl}
      />;
      case Views.Hub: return <HubView
        viewGlobal={viewGlobal}
        idGlobal={idGlobal}
        loadingGlobal={loadingGlobal}
        messageBoxGlobal={messageBoxGlobal}
        countriesGlobal={countriesGlobal}
        searchGlobal={searchGlobal}
        editingGlobal={editingGlobal}
        pinLocationGlobal={pinLocationGlobal}
        geoserverUrl={geoserverUrl}
      />;

      case Views.Well: return <WellView
        viewGlobal={viewGlobal}
        idGlobal={idGlobal}
        loadingGlobal={loadingGlobal}
        messageBoxGlobal={messageBoxGlobal}
        countriesGlobal={countriesGlobal}
        searchGlobal={searchGlobal}
        editingGlobal={editingGlobal}
        pinLocationGlobal={pinLocationGlobal}
        geoserverUrl={geoserverUrl}
        copyRecordId={COPY_RECORD_ID}
      />;
      case Views.WellProgramme: return <WellProgrammeView
        viewGlobal={viewGlobal}
        idGlobal={idGlobal}
        loadingGlobal={loadingGlobal}
        messageBoxGlobal={messageBoxGlobal}
        countriesGlobal={countriesGlobal}
        searchGlobal={searchGlobal}
        editingGlobal={editingGlobal}
        pinLocationGlobal={pinLocationGlobal}
        geoserverUrl={geoserverUrl}
      />;
      case Views.Corporate: return <CorporateView viewGlobal={viewGlobal} editingGlobal={editingGlobal} idGlobal={idGlobal} loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} countriesGlobal={countriesGlobal} searchGlobal={searchGlobal} />;
      case Views.License: return <LicenseView 
        viewGlobal={viewGlobal}
        idGlobal={idGlobal}
        loadingGlobal={loadingGlobal}
        messageBoxGlobal={messageBoxGlobal}
        countriesGlobal={countriesGlobal}
        searchGlobal={searchGlobal}
        editingGlobal={editingGlobal}
        pinLocationGlobal={pinLocationGlobal}
      />;
      case Views.LicenseRound: return <LicenseRoundView
        viewGlobal={viewGlobal}
        idGlobal={idGlobal}
        loadingGlobal={loadingGlobal}
        messageBoxGlobal={messageBoxGlobal}
        countriesGlobal={countriesGlobal}
        searchGlobal={searchGlobal}
        editingGlobal={editingGlobal}
        pinLocationGlobal={pinLocationGlobal}
      />;
      case Views.HistoricProspect: return <HistoricProspectView />;
      case Views.Relinquishment: return <RelinquishmentsView
        viewGlobal={viewGlobal}
        idGlobal={idGlobal}
        loadingGlobal={loadingGlobal}
        messageBoxGlobal={messageBoxGlobal}
        countriesGlobal={countriesGlobal}
        searchGlobal={searchGlobal}
        editingGlobal={editingGlobal}
        pinLocationGlobal={pinLocationGlobal}
      />;
      case Views.Rig: return <RigView
        viewGlobal={viewGlobal}
        idGlobal={idGlobal}
        loadingGlobal={loadingGlobal}
        messageBoxGlobal={messageBoxGlobal}
        countriesGlobal={countriesGlobal}
        searchGlobal={searchGlobal}
        editingGlobal={editingGlobal}
        pinLocationGlobal={pinLocationGlobal}
        geoserverUrl={geoserverUrl}
      />;
      case Views.RigContracts: return <RigContractsView />;
      case Views.Variables: return <VariablesView />;
      case Views.Queries: return <QueriesView idGlobal={idGlobal} loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} countriesGlobal={countriesGlobal} />;
      case Views.Spatial: return <SpatialView idGlobal={idGlobal} loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} countriesGlobal={countriesGlobal} />;
      case Views.HubUpside: return <HubUpsideView idGlobal={idGlobal} loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} countriesGlobal={countriesGlobal} />;
      
      case Views.PhilTest: return <PhilTestView editingGlobal={editingGlobal} idGlobal={idGlobal} />;
      case Views.JimTest: return <JimTestView />;
      case Views.RobTest: return <RobTestView />;
      case Views.Test: return <TestView printModeGlobal={printModeGlobal} editingGlobal={editingGlobal} loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} viewGlobal={viewGlobal} searchGlobal={searchGlobal} idGlobal={idGlobal} />;
default: throw new Error('Unsupported view (' + view + ') supplied to AppComponent.viewComponent()');
    }
  }

  render() {
    if (this.versionValid === null) {
      return null;
    }

    if (this.versionValid === false) {
      return <InvalidVersionComponent />;
    }

    const { view, sidebarWidth, printMode, geoserverUrl } = this.state;

    const idGlobal = this.createIdGlobal();
    const viewGlobal = this.createViewGlobal();
    const editingGlobal = this.createEditingGlobal();
    const printModeGlobal = this.createPrintModeGlobal();
    const countriesGlobal = this.createCountriesGlobal();
    const searchGlobal = this.createSearchGlobal();
    const messageBoxGlobal = this.createMessageBoxGlobal();
    const loadingGlobal = this.createLoadingGlobal();
    const pinLocationGlobal = this.createPinLocationGlobal();
    const viewComponent = this.getViewComponent(view, printModeGlobal, idGlobal, viewGlobal, editingGlobal, printModeGlobal, countriesGlobal, searchGlobal, messageBoxGlobal, loadingGlobal, pinLocationGlobal, geoserverUrl);

    if (printMode === true) {
      return viewComponent;
    }

    return (
      <div className="app-component">
        <LoadingComponent ref={this._loadingRef} visible={false} image={this.loadingImage} />
        <MessageBoxComponent message={messageBoxGlobal.value} clear={messageBoxGlobal.clear} />
        <SplitterPanelComponent
          animateSizeChange
          secondarySize={sidebarWidth}
          secondaryMinSize={SIDEBAR_COLLAPSED_WIDTH}
          primaryIndex={1}
          percentage={false}
          vertical
          onSecondaryPaneSizeChange={this.onSidebarSizeChange}
        >
          <SidebarComponent
            idGlobal={idGlobal}
            viewGlobal={viewGlobal}
            editing={editingGlobal.value}
            countriesGlobal={countriesGlobal}
            searchGlobal={searchGlobal}
            messageBoxGlobal={messageBoxGlobal}
            loadingGlobal={loadingGlobal}
            getNavigationHistory={() => this.navigationHistory}
            clearNavigationHistory={() => this.navigationHistory = []}
            expanded={sidebarWidth > SIDEBAR_COLLAPSED_WIDTH}
            toggleExpanded={this.toggleSidebar}
            logout={this.props.logout}
            userGroups={this.userGroups}
          />
          <SliderPanelComponent childKey={view} animation="slide" timeout={600}>{viewComponent}</SliderPanelComponent>
        </SplitterPanelComponent>
      </div>
    );
  }
}

AppComponent.propTypes = {
  version: PropTypes.shape({
    major: PropTypes.number.isRequired,
    minor: PropTypes.number.isRequired,
    build: PropTypes.number.isRequired
  }),
  logout: PropTypes.func.isRequired
};

export default AppComponent;
