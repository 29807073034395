import DoubleFormatter from '../components/grid/formatters/double-formatter';

class ColSettings {
  name = -1;
  displayName = '';
  type = '';
  width = 105;
  decimalPlaces = 0;
  excelOnly = false;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  typeToString() {
    switch (this.type) {
      case 'text':
        return 'text';
      case 'int':
      case 'double':
        return 'numeric';
      default: return 'text';
    }
  }

  static getAgGridColumns(colSettings) {
    const columnDefs = [];

    colSettings.forEach(x => {
      switch (x.type) {
        case 'double':
          columnDefs.push({ headerName: x.displayName, suppressHeaderMenuButton: true, resizable: true, field: x.name, sortable: true, filter: false, editable: false, width: x.width, type: x.typeToString(), cellRenderer: DoubleFormatter, cellRendererParams: { decimalPlaces: x.decimalPlaces } });
          break;
        case 'text':
        case 'int':
        default:
          columnDefs.push({ headerName: x.displayName, suppressHeaderMenuButton: true, resizable: true, field: x.name, sortable: true, filter: false, editable: false, width: x.width, type: x.typeToString() });
          break;
      }
    });

    return columnDefs;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "ColSettings.FromArray"');
    }

    const result = array.map(obj => new ColSettings(obj));
    return result;
  }
}

export { ColSettings };