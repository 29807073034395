import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap'

import './test-view.scss';
import { apiGet } from '../../common/helpers/api-helpers';
import { MessageBoxButton, MessageBoxButtons, MessageBoxIcon } from '../../shared/components/message-box/message-box-classes';
import ComingSoonComponent from '../../common/components/coming-soon/coming-soon-component';
import { PrintModeGlobal, IdGlobal, SearchGlobal, ViewGlobal, Views, EditingGlobal, LoadingGlobal, MessageBoxGlobal } from '../../common/globals/globals';

const TestView = (props) => {
  const { printModeGlobal, editingGlobal, loadingGlobal, messageBoxGlobal, viewGlobal, searchGlobal, idGlobal } = props;

  const showLoading = (showLoadingFunc) => {
    showLoadingFunc();
    setTimeout(loadingGlobal.hide, 8000);
  };

  const messageBoxCallback = (button) => {
    const str = button < 0
      ? 'Custom button clicked (' + button + ')'
      : MessageBoxButton.displayName(button) + ' button clicked';
    window.alert(str);
  }
  const showSimple = () => {
    // Minimum requirements to show a message
    messageBoxGlobal.show(
      // Message box body
      'Message box content content content content content',
      // Message box button click
      messageBoxCallback
    );
  }
  const showFull = () => {
    messageBoxGlobal.show(
      // Message box body
      'Message box content content content content content',
      // Message box button click
      messageBoxCallback,
      // Message box buttons
      [MessageBoxButton.Close],
      // Message box header
      'Message box header',
      // Message box icon
      MessageBoxIcon.Warning
    );
  }
  const showError = () => {
    messageBoxGlobal.showError(
      <h1>Oh <i>SHEEEEET!!!</i></h1>,
      messageBoxCallback,
      MessageBoxButtons.OKCancel,
      <span style={{ color: 'red' }}>!PANIC!</span>
    );
  }
  const showQuestion = () => {
    messageBoxGlobal.showQuestion(
      <ComingSoonComponent />,
      messageBoxCallback,
      MessageBoxButtons.YesNoCancel
    );
  }
  const showWarning = () => {
    messageBoxGlobal.showWarning(
      'Message box content content content content content',
      messageBoxCallback,
      MessageBoxButtons.AbortRetryIgnore
    );
  }
  const showInformation = () => {
    messageBoxGlobal.showInformation(
      'Message box content content content content content',
      messageBoxCallback,
      [MessageBoxButton.Close, MessageBoxButton.OK, MessageBoxButton.Cancel, MessageBoxButton.Abort, MessageBoxButton.Retry, MessageBoxButton.Ignore, MessageBoxButton.Yes, MessageBoxButton.No]
    );
  }
  const showCustom = () => {
    messageBoxGlobal.showInformation(
      'Mix of custom and regular buttons',
      messageBoxCallback,
      [MessageBoxButton.OK, '<First Custom>', '2nd Custom ', MessageBoxButton.Cancel, 'Click Me! (3rd Custom)']
    );
  }

  const callOk = () => {
    apiGet('api/test/ok', loadingGlobal.show, loadingGlobal.hide, messageBoxGlobal, (result) => messageBoxGlobal.showInformation(result.message), undefined);
  }
  const callUnhandledError = () => {
    apiGet('api/test/unhandled-error', loadingGlobal.show, loadingGlobal.hide, messageBoxGlobal, (result) => messageBoxGlobal.showInformation(result.message), undefined);
  }
  const callErrorWithMessage = () => {
    apiGet('api/test/error-with-message', loadingGlobal.show, loadingGlobal.hide, messageBoxGlobal, (result) => messageBoxGlobal.showInformation(result.message), undefined);
  }
  const callErrorWithoutMessage = () => {
    apiGet('api/test/error-without-message', loadingGlobal.show, loadingGlobal.hide, messageBoxGlobal, (result) => messageBoxGlobal.showInformation(result.message), undefined);
  }
  const callInvalid1 = () => {
    apiGet('api/test/invalid-parameter-1', loadingGlobal.show, loadingGlobal.hide, messageBoxGlobal, (result) => messageBoxGlobal.showInformation(result.message), undefined);
  }
  const callInvalid2 = () => {
    apiGet('api/test/invalid-parameter-2', loadingGlobal.show, loadingGlobal.hide, messageBoxGlobal, (result) => messageBoxGlobal.showInformation(result.message), undefined);
  }

  const paddingComponents = (count) => {
    const result = [];
    for (var loop = 1; loop <= count; loop++) {
      result.push(<h1>Test{loop}</h1>);
    }
    return result;
  }

  return (
    <div className="view-panel test-view">
      <div style={{ margin: '10px' }}>
        <h2>Editing</h2>
        <Button onClick={() => editingGlobal.set(!editingGlobal.value)}>{editingGlobal.value ? 'Cancel' : 'Edit'}</Button>
      </div>
      <div style={{ margin: '10px', borderTop: '1px solid black' }}>
        <h2>{'ID: ' + (idGlobal.isSet ? (idGlobal.isNewRecordId ? 'New' : idGlobal.value) : 'None')}</h2>
        <Button onClick={() => idGlobal.set(2, 'Alba')}>Alba</Button>
        <Button onClick={idGlobal.setNewRecordId}>New</Button>
        <Button onClick={idGlobal.clear}>Clear</Button>
      </div>
      <div style={{ margin: '10px', borderTop: '1px solid black' }}>
        <h2>Loading Component</h2>
        <Button onClick={() => showLoading(loadingGlobal.showLogo)}>Logo</Button>
        <Button onClick={() => showLoading(loadingGlobal.showLoading)}>Loading</Button>
        <Button onClick={() => showLoading(loadingGlobal.showSave)}>Save</Button>
      </div>
      <div style={{ margin: '10px', borderTop: '1px solid black' }}>
        <h2>Message Box Component</h2>
        <Button onClick={showSimple}>Simple</Button>
        <Button onClick={showFull}>Full</Button>
        <Button onClick={showError}>Error</Button>
        <Button onClick={showQuestion}>Question</Button>
        <Button onClick={showWarning}>Warning</Button>
        <Button onClick={showInformation}>Information</Button>
        <Button onClick={showCustom}>Custom</Button>
      </div>
      <div style={{ margin: '10px', borderTop: '1px solid black' }}>
        <h2>API Examples</h2>
        <Button onClick={callOk}>Ok</Button>
        <Button onClick={callUnhandledError}>Error 1</Button>
        <Button onClick={callErrorWithMessage}>Error 2</Button>
        <Button onClick={callErrorWithoutMessage}>Error 3</Button>
        <Button onClick={callInvalid1}>Invalid 1</Button>
        <Button onClick={callInvalid2}>Invalid 2</Button>
      </div>
      <div style={{ margin: '10px', borderTop: '1px solid black' }}>
        <h2>Goto</h2>
        <Button onClick={() => viewGlobal.set(Views.Production)}>Production</Button>
        <Button onClick={() => viewGlobal.setAndId(Views.Resource, 2, 'Alba')}>Resource - Alba</Button>
        <Button onClick={() => viewGlobal.setAndId(Views.Resource, 2, 'Alba', () => searchGlobal.search('Alba'))}>Resource - Search For Alba</Button>
        <Button onClick={() => viewGlobal.setAndId(Views.Resource, -99, 'Invalid')}>Resource - Invalid</Button>
        <Button onClick={() => viewGlobal.setAndId(Views.Production, -99, 'Invalid')}>Production - Invalid</Button>
      </div>
      <div style={{ margin: '10px', borderTop: '1px solid black' }}>
        <h2>Search</h2>
        <Button onClick={() => searchGlobal.search('Alba')}>Alba</Button>
        <Button onClick={() => searchGlobal.search('Vali')}>Vali</Button>
      </div>
      <div style={{ margin: '10px', borderTop: '1px solid black' }}>
        <h2>Print Mode</h2>
        <Button onClick={printModeGlobal.toggle}>Toggle</Button>
        <Button onClick={() => window.print()}>Print</Button>
        {paddingComponents(40)}
      </div>

    </div>
  );
};

TestView.propTypes = {
  printModeGlobal: PropTypes.instanceOf(PrintModeGlobal).isRequired,
  editingGlobal: PropTypes.instanceOf(EditingGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  viewGlobal: PropTypes.instanceOf(ViewGlobal).isRequired,
  searchGlobal: PropTypes.instanceOf(SearchGlobal).isRequired,
  idGlobal: PropTypes.instanceOf(IdGlobal).isRequired
}

export default TestView;