import { GenericExcelExportColumn, GenericExcelExport } from "./generic-excel-export";

class HubUpsideExcelExport {
  export(tables, workbookName) {

    const genericExcelExport = new GenericExcelExport();
    const workbook = genericExcelExport.createWorkbook();
    const tablesCollection = Object.values(tables);

    tablesCollection.forEach(x => {
      this._addSheet(genericExcelExport, workbook, x.data, x.columnSettings, x.sheetName);
    });


    genericExcelExport.writeWorkbook(workbook, workbookName + genericExcelExport.getWorkbookSuffix());
  }

  _addSheet(genericExcelExport, workbook, dataRows, colSettings, sheetName) {
    const colDefs = [];
    const colSettingsCollection = Object.values(colSettings);

    colSettingsCollection.forEach(x => {
      if (x.type === 'double' && x.decimalPlaces > 0) {
        var format = '0.' + '0'.repeat(x.decimalPlaces);
        colDefs.push(new GenericExcelExportColumn(x.displayName, x.name, format));
      } else {
        colDefs.push(new GenericExcelExportColumn(x.displayName, x.name));
      }
    });

    genericExcelExport.writeSheet(workbook, sheetName, dataRows, colDefs);
  }
}

export { HubUpsideExcelExport };
