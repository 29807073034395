import React from 'react';
import PropTypes from 'prop-types';

import './fields-component.scss';
import AttributeListComponent from '../../../../common/components/attribute-list/attribute-list-component';
import { HubField } from '../../models/result';
import SplitterPanelComponent from '../../../../shared/components/splitter-panel/splitter-panel-component';
import PropertyGridComponent, { PropertyGridStringValue, SectionPropType } from '../../../../shared/components/property-grid/property-grid-component';

const IDS = {
  NAME: 0,
  RESOURCE_STATUS: 10
};
Object.freeze(IDS);

class FieldsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { items: [], selectedIndex: 0};
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fields !== this.props.fields) {
      this.update();
    }
  }

  update() {
    const { fields } = this.props;
    const items = fields.map(item => {
      return {
        label: item.fieldName,
        data: item,
        sections: [{
          label: 'Fields',
          rows: [
            { label: 'Field Name', values: [new PropertyGridStringValue(IDS.NAME, item.fieldName)] },
            { label: 'Status', values: [new PropertyGridStringValue(IDS.RESOURCE_STATUS, item.resourceStatus)] }
          ]
        }]
      }
    });

    this.setState({ items: items, selectedIndex: 0 });
  }

  get selected() {
    const { items, selectedIndex } = this.state;

    return items.length === 0 || selectedIndex < 0 || selectedIndex > items.length - 1
      ? null
      : items[selectedIndex]
  }

  setSelectedIndex = (value) => {
    const { selectedIndex } = this.state;
    if (typeof value !== 'number') {
      throw new Error('Invalid value param "' + value + '" supplied to AttributeListComponent.setSelectedIndex');
    }

    if (selectedIndex !== value) {
      this.setState({ selectedIndex: value });
    }
  }

  render() {
    const { items, selectedIndex } = this.state;
    const selected = this.selected;

    return (
      <SplitterPanelComponent vertical percentage={false} primaryIndex={1} secondarySize={225}>
        <ul className="labels">
          {items.map((item, index) => (
            <li
              key={index}
              className={(index === selectedIndex ? 'selected' : '')}
            >
              <div className={'label-name'} onClick={index === selectedIndex ? undefined : () => this.setSelectedIndex(index)}>{item.label}</div>

            </li>
          ))}
        </ul>
        <PropertyGridComponent readOnly={false} sections={selected === null ? [] : selected.sections} narrow={true} />
      </SplitterPanelComponent>
    );
  }
}

AttributeListComponent.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.instanceOf(HubField))
};


export default FieldsComponent;
