import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';
import './popup-panel.scss';
import DateTextGenericComponent from '../../../../common/components/date-text-generic/date-text-generic-component';
import moment from 'moment';

const RelinquishBlockComponent = (props) => {
  const { blockNo, onClose } = props;

  const [errorPanel, setErrorPanel] = useState(null);
  const [relComment, setRelinquishmentComment] = useState('');
  const [relDate, setRelinquishmentDate] = useState(moment().utc(true));

  const validate = () => {
    if (relComment === '' && !relDate.isValid()) {
      setErrorPanel(<div className="error-panel">A Relinquishment Comment and a valid Relinquishment Date must be entered!</div>);
    }
    else if (relComment === '') {
      setErrorPanel(<div className="error-panel">A Relinquishment Comment must be entered!</div>);
    }
    else if (!relDate.isValid()) {
      setErrorPanel(<div className="error-panel">A valid Relinquishment Date must be entered!</div>);
    }
    else {
      props.onClose(false, relComment, relDate);
    }
  }

  const onChange = (text, date) => {
    setErrorPanel(null);
    setRelinquishmentDate(moment(date).utc(true));
    setRelinquishmentComment(text);
  }

  const okClick = () => {
    validate();
  }

  const onFocus = () => {
    setErrorPanel(null);
  }

  return (
    <div className="popup-panel-component">
      <div className="content relinquish-block">
        <div>To relinquish block {blockNo}, please enter a relinquishment comment.</div>
        {errorPanel}
        <div className={(errorPanel !== null ? 'small' : '')}>
          <DateTextGenericComponent key="1" editing commentText={relComment} commentDate={relDate} onChange={onChange} onFocus={onFocus} />
        </div>
        <div className="buttons">
          <Button className="ok-button" size="md" onClick={() => okClick()}>Ok</Button>
          <Button className="cancel-button" size="md" onClick={() => onClose(true)}>Cancel</Button>
        </div>
      </div>
    </div>
  );
};

RelinquishBlockComponent.propTypes = {
  blockNo: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default RelinquishBlockComponent;

