import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';

import { LeafletHelper, ContextLayerModel, latLng } from '../../../shared/helpers/leaflet/leaflet-helper';
import { zoomLevels } from '../../../shared/helpers/leaflet/helpers/zoom-levels';
import './map-component.scss';
import { apiGet, callApiDefaultOnFail } from '../../helpers/api-helpers';
import { MessageBoxGlobal, LoadingGlobal, PinLocationGlobal } from '../../../common/globals/globals';
import { MessageBoxIcon } from '../../../shared/components/message-box/message-box-classes';

class MapComponent extends React.Component {
  constructor(props) {
    super(props);

    this.ignoreMapMove = true; //HACK: ignore the first map move
    this.mapMoveTimer = null;
    this.pointsLayer = L.featureGroup();
    this.shapesLayer = L.featureGroup();
    this.leafletHelper = new LeafletHelper(this.props.geoserverUrl, this.props.mapComponentName, undefined, true);

    this.contextLayers = null;
    this.gettingContext = false;
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResize);
    document.addEventListener('keyup', this.onKeyUp, false);
    this.contextLayers = this.getContextLayers(this.props.contextLayerType);
  }

  componentWillUnmount() {
    clearTimeout(this.mapMoveTimer);
    window.removeEventListener('resize', this.windowResize);
    document.removeEventListener('keyup', this.onKeyUp);

    if (this.leafletHelper !== null && this.leafletHelper !== undefined) {
      this.leafletHelper.destroy();
      delete this.leafletHelper;
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.gettingContext && prevProps !== this.props) {
      let initialize = false;

      if (this.leafletHelper instanceof LeafletHelper) {
        if (!this.leafletHelper.initialized) {
          initialize = true;
        }

        this.update(initialize, prevProps);
      }
    }
    
  }

  getContextLayers = (contextLayerType) => {
    this.gettingContext = true;
    const onSuccess = (result) => {
      this.contextLayers = ContextLayerModel.FromArray(result);
      this.update(true);
      this.gettingContext = false;
    };
    const onFail = (result) => {
      this.gettingContext = false;
      callApiDefaultOnFail(result, this.props.messageBoxGlobal);
    }
    apiGet('api/app/load-context-layers?id=' + contextLayerType, this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, onFail);
  }

  update = (initialize, prevProps = undefined) => {
    const { points, pinLocationGlobal, shapes } = this.props;

    if (this.leafletHelper instanceof LeafletHelper) {
      if (initialize) {
        this.leafletHelper.initialize(this.contextLayers);
        this.leafletHelper.leafletMap.on('moveend', this.onMapMove, this);

        this.leafletHelper.controls.gmeButtons.pin.onComplete = this.onPin;
        this.leafletHelper.controls.pinCoordinates.visible = true;
      }

      // we dont want to move the map unless this is the initial load of an entity, or the location of the entity being edited has changed
      const places = 4;
      let redraw = false;
      if (prevProps === undefined || prevProps === null || prevProps.points === undefined || prevProps.points === null || prevProps.points.length === 0) {
        redraw = true;
      }
      else
      {
        if (points !== undefined && points !== null && points.length > 0) {
          let prevPoints = prevProps.points;
          points.forEach(x => {
            let prev = undefined;
            prev = prevPoints.find(p => p.lat.toFixed(places) === x.lat.toFixed(places) && p.lng.toFixed(places) === x.lng.toFixed(places));
            if (prev === undefined) redraw = true;
          });
        }
      }
      
      if (redraw) {
        if (points !== undefined && points !== null && points.length === 1) {
          let center = latLng(points[0].lat, points[0].lng);
          this.leafletHelper.setView(center, zoomLevels._5km);
        }
        else if (points !== undefined && points !== null && points.length > 0) {
          const bounds = L.latLngBounds([]);
          points.forEach(obj => bounds.extend(obj.latLng));
          this.leafletHelper.flyToBounds(bounds);
        }
      }

      this.pointsLayer.clearLayers();
      const layers = points.map(obj => this.leafletHelper.createLayer(obj)).filter(obj => obj !== null);
      layers.forEach(obj => this.pointsLayer.addLayer(obj));
      this.pointsLayerVisible = true;

      if (shapes != null && shapes != undefined) {
        this.shapesLayer.clearLayers();
        const shapesLayers = shapes.map(obj => this.leafletHelper.createLayer(obj)).filter(obj => obj !== null);
        shapesLayers.forEach(obj => this.shapesLayer.addLayer(obj));
        this.shapesLayerVisible = true;
      }

      if (pinLocationGlobal.value != undefined && pinLocationGlobal.value != null) this.updatePinLocation(pinLocationGlobal.value);
    }
  }

  onPin = (center) => {
    this.updatePinLocation()
    this.props.pinLocationGlobal.set(center);
  }

  updatePinLocation = (center) => {
    let lat = 0;
    let lng = 0;
    if (center !== undefined && center instanceof L.LatLng) {
      lat = center.lat;
      lng = center.lng;
    }
    const dp = 3;
    this.leafletHelper.controls.pinCoordinates._control._label.innerHTML = 'Latitude: ' + lat.toFixed(dp) + ' Longitude: ' + lng.toFixed(dp);
  }

  windowResize = () => {
    this.invalidateMap();
  }

  onKeyUp = (e) => {
    if (e.key === 'Escape') {
      this.leafletHelper.controls.searchButtons.cancelAll();
    }
  }

  get pointsLayerVisible() {
    return this.leafletHelper.leafletMap.hasLayer(this.pointsLayer);
  }
  set pointsLayerVisible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "BaseViewComponent.pointsLayerVisible.set"');
    }

    if (value !== this.pointsLayerVisible) {
      if (value) {
        this.leafletHelper.leafletMap.addLayer(this.pointsLayer);
      } else {
        this.leafletHelper.leafletMap.removeLayer(this.pointsLayer);
      }
    }
  }

  get shapesLayerVisible() {
    return this.leafletHelper.leafletMap.hasLayer(this.shapesLayer);
  }
  set shapesLayerVisible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "BaseViewComponent.shapesLayerVisible.set"');
    }

    if (value !== this.shapesLayerVisible) {
      if (value) {
        this.leafletHelper.leafletMap.addLayer(this.shapesLayer);
      } else {
        this.leafletHelper.leafletMap.removeLayer(this.shapesLayer);
      }
    }
  }

  invalidateMap = () => {
    const handler = () => {
      if (this.leafletHelper.initialized) {
        this.leafletHelper.leafletMap.invalidateSize();
      }
    };
    setTimeout(handler, 300);
  }

  onMapMove = () => {
    if (this.ignoreMapMove === true) {
      this.ignoreMapMove = false;
    }
  }

  reset() {
    const doReset = () => {
      this.leafletHelper.controls.searchResults.close();
      this.props.reset();
    };

    this.props.messageBoxGlobal.showYesNoPrompt('Are you sure you want to reset?', doReset, 'Reset', MessageBoxIcon.Question);
  }

  render() {
    return <div className="map-component" id={this.leafletHelper.id} />;
  }

}

MapComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  pinLocationGlobal: PropTypes.instanceOf(PinLocationGlobal).isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  mapComponentName: PropTypes.string.isRequired,
  contextLayerType: PropTypes.number.isRequired,
  points: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  shapes: PropTypes.arrayOf(PropTypes.object),
};

MapComponent.defaultProps = {
};

export default MapComponent;
export { LeafletHelper, ContextLayerModel };
