import React from "react";
import PropTypes from 'prop-types';

import { MessageBoxGlobal } from '../../common/globals/globals';

import AGGridComponent, { COLUMN_MEDIUM } from '../../common/components/grid/ag-grid-component';

const NPDCompareGridComponent = (props) => {
  const { messageBoxGlobal, data, type } = props;

  const columnDefs = [];
  columnDefs.push({ headerName: 'WellBore Name', suppressHeaderMenuButton: true, resizable: true, field: 'wlbWellboreName', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
  columnDefs.push({ headerName: 'Well Name', suppressHeaderMenuButton: true, resizable: true, field: 'wlbWell', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
  columnDefs.push({ headerName: 'Drilling Operator', suppressHeaderMenuButton: true, resizable: true, field: 'wlbDrillingOperator', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
  columnDefs.push({ headerName: 'Production Licence', suppressHeaderMenuButton: true, resizable: true, field: 'wlbProductionLicence', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
  columnDefs.push({ headerName: 'Purpose', suppressHeaderMenuButton: true, resizable: true, field: 'wlbPurpose', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
  columnDefs.push({ headerName: 'Status', suppressHeaderMenuButton: true, resizable: true, field: 'wlbStatus', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
  columnDefs.push({ headerName: 'Well Type', suppressHeaderMenuButton: true, resizable: true, field: 'wlbWellType', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
  columnDefs.push({ headerName: 'Npd Well id', suppressHeaderMenuButton: true, resizable: true, field: 'wlbNpdidWellbore', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
  columnDefs.push({ headerName: 'Npd Field id', suppressHeaderMenuButton: true, resizable: true, field: 'fldNpdidField', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
  columnDefs.push({ headerName: 'Npd Discovery id', suppressHeaderMenuButton: true, resizable: true, field: 'dscNpdidDiscovery', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
  columnDefs.push({ headerName: 'GME Well', suppressHeaderMenuButton: true, resizable: true, field: 'gmeWellName', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {},
    image: {}
  };

  return (
    <AGGridComponent
      messageBoxGlobal={messageBoxGlobal}
      columnDefs={columnDefs}
      rowsCount={data.length}
      columnTypes={columnTypes}
      gridData={data}
      allowPaste={false}
      editYear={0}
      autoResize
    />
  );
};

NPDCompareGridComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
};

export default NPDCompareGridComponent;
