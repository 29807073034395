import React from 'react';
import PropTypes from 'prop-types';
import './fiscal-view.scss';

import { apiGet, callApiDefaultOnFail } from '../../common/helpers/api-helpers';
import { LoadingGlobal, MessageBoxGlobal } from '../../common/globals/globals';
import { Button } from 'reactstrap'
import TabPanelComponent, { Tab } from '../../shared/components/tab-panel/tab-panel-component';
import FiscalViewGridComponent from '../fiscal/fiscal-view-grid';

class FiscalView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { bulkFiscal: null, bulkResourceAnnuals: null };
  };

  get apiPrefix() { return 'api/bulk-fiscal/' };

  componentDidMount() {
    //this.initCompareData();
  }

  //initCompareData() {
  //  const onSuccess = (result) => this.setState({ bulkFiscal: result });
  //  apiGet(this.apiPrefix + 'create-bulk-fiscal', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
  //}

  onAtlasVsGME() {
    let yesCallback = () => {
      let onSuccess = (result) => this.setState({ bulkFiscal: result, bulkResourceAnnuals: null });
      let onFail = (e) => this.props.idGlobal.clear(() => callApiDefaultOnFail(e, this.props.messageBoxGlobal));
      apiGet(this.apiPrefix + 'create-bulk-fiscal', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, onFail);
    }
    this.props.messageBoxGlobal.showYesNoPrompt('Are you sure you want to Compare Atlas vs GME?', yesCallback, 'Atlas vs GME');
  }

  onBulkResourceAnnuals() {
    let yesCallback = () => {
      let onSuccess = (result) => this.setState({ bulkResourceAnnuals: result, bulkFiscal: null });
      let onFail = (e) => this.props.idGlobal.clear(() => callApiDefaultOnFail(e, this.props.messageBoxGlobal));
      apiGet(this.apiPrefix + 'get-bulk-cashflow-annuals', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, onFail);
    }
    this.props.messageBoxGlobal.showYesNoPrompt('Are you sure you want to get all Resource Annuals?', yesCallback, 'Resource Annuals');
  }

  render() {
    const { bulkFiscal, bulkResourceAnnuals } = this.state;

    /* BULK FISCAL */
    let hasBulkFiscal = bulkFiscal !== null && bulkFiscal !== undefined;
    let content = null;

    if (hasBulkFiscal) {
      let hasMismatches = hasBulkFiscal && bulkFiscal.mismatches !== null;
      let mismatchesCount = hasMismatches ? bulkFiscal.mismatches.length : 0;

      let hasResourceAnnuals = hasBulkFiscal && bulkFiscal.resourceAnnuals !== null;
      let resourceAnnualCount = hasMismatches ? bulkFiscal.resourceAnnuals.length : 0;

      let hasHubAnnuals = hasBulkFiscal && bulkFiscal.hubAnnuals !== null;
      let hubAnnualsCount = hasMismatches ? bulkFiscal.hubAnnuals.length : 0;

      let hasHubEmissionAnnuals = hasBulkFiscal && bulkFiscal.hubEmissionAnnuals !== null;
      let hubEmissionAnnualsCount = hasMismatches ? bulkFiscal.hubEmissionAnnuals.length : 0;

      let tabs = [
        new Tab('Resource Annual vs Calculation Mismatches (' + mismatchesCount.toString() + ')', hasMismatches ? <FiscalViewGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={bulkFiscal.mismatches} type={1} /> : null),
        new Tab('Resource Annuals (' + resourceAnnualCount.toString() + ')', hasResourceAnnuals ? <FiscalViewGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={bulkFiscal.resourceAnnuals} type={2} /> : null),
        new Tab('Hub Cashflow Annuals (' + hubAnnualsCount.toString() + ')', hasHubAnnuals ? <FiscalViewGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={bulkFiscal.hubAnnuals} type={3} /> : null),
        new Tab('Hub Emission Annuals (' + hubEmissionAnnualsCount.toString() + ')', hasHubEmissionAnnuals ? <FiscalViewGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={bulkFiscal.hubEmissionAnnuals} type={4} /> : null),
      ];

      content = <TabPanelComponent toolbarItems={tabs} selectedItemIndex={0}/>;
    }

    /* RESOURCE ANNUAL */
    let hasBulkResourceAnnuals = bulkResourceAnnuals !== null && bulkResourceAnnuals !== undefined;
    if (hasBulkResourceAnnuals) {
      content = <FiscalViewGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={bulkResourceAnnuals} type={5} />;
    }
    return (
      <div className="fiscal-view">
        <h1>Fiscal Calculation Outputs</h1>
        <div className="import-tab-buttons">
          <Button id='1' className="btn-submit" size="sm" onClick={() => this.onBulkResourceAnnuals()}>Bulk Atlas Resource Annuals</Button>
          <Button id='2' className="btn-submit" size="sm" onClick={() => this.onAtlasVsGME()}>Atlas vs GME Calculation Mismatches</Button>
        </div>
        {content}
      </div>
    );
  }


}

FiscalView.propTypes = {
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
}

export default FiscalView;

//import React from 'react';
//import PropTypes from 'prop-types';
//import { Button } from 'reactstrap';

//import './fiscal-view.scss';
//import { apiGet, apiPost, callApiDefaultOnFail } from '../../common/helpers/api-helpers';
//import { PrintModeGlobal, IdGlobal, LoadingGlobal, MessageBoxGlobal } from '../../common/globals/globals';
//import { print } from '../../shared/helpers/common';
//import { Portfolio, PortfolioSelection } from '../../shared/components/fiscal/models/portfolio/portfolio';
//import { Variables } from '../../shared/components/fiscal/variables/variables';
//import { ResourceText } from '../../shared/components/fiscal/models/resource-text';
//import ToolbarComponent from '../../shared/components/toolbar-panel/toolbar-component';
//import VariablesModalComponent from '../../shared/components/fiscal/variables/variables-modal-component';
//import ResourceFiscalComponent, { defaultPageSize, pageSizes } from '../../shared/components/fiscal/resource-fiscal-component';
//import { ActivityModel } from '../../shared/helpers/leaflet/models/activity-model';
//import { ContextLayerModel } from '../../shared/helpers/leaflet/models/context-layer-model';

//const GEOSERVER_URL = 'https://wms.20190815.westwoodenergy.com/WEB/wms';

//class FiscalView extends React.Component {
//  constructor(props) {
//    super(props);

//    this.allVariables = null;
//    this.state = {
//      mapContextLayers: [],
//      mapActivities: [],
//      fiscalData: null,
//      variablesModalVisible: false
//    };
//  }

//  get apiPrefix() { return 'api/portfolio/'; };
//  get geoserverUrl() { return GEOSERVER_URL; };
//  get portfolioType() { return 10; };

//  get printModeGlobal() { return this.props.printModeGlobal; };
//  get idGlobal() { return this.props.idGlobal; };
//  get loadingGlobal() { return this.props.loadingGlobal; };
//  get messageBoxGlobal() { return this.props.messageBoxGlobal; };
//  get selectedVariables() { return Array.isArray(this.allVariables) ? this.allVariables[0] : null; }
//  set selectedVariables(value) {
//    if (Array.isArray(this.allVariables)) {
//      this.allVariables[0] = value;
//    }
//  }

//  componentDidMount() {
//    this.loadContextLayers()
//    //this.loadActivities();
//    this.initFiscalData();
//  }

//  componentDidUpdate(prevProps) {
//    if (prevProps.idGlobal.value !== this.props.idGlobal.value) {
//      this.initFiscalData();
//    }
//  }

//  initFiscalData() {
//    const performInitFiscalData = () => {
//      if (this.idGlobal.isSet) {
//        this.getPortfolioData();
//      } else {
//        this.setState(prevState => { return prevState.fiscalData !== null ? { fiscalData: null } : null });
//      }
//    };

//    if (this.allVariables === null) {
//      const onSuccess = (result) => {
//        const model = result.map(obj => new Variables(obj));
//        this.allVariables = model;
//        performInitFiscalData();
//      }
//      apiGet(this.apiPrefix + 'variables', this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
//    } else {
//      performInitFiscalData();
//    }
//  }

//  getPortfolioData = (callback = undefined) => {
//    if (callback !== undefined && typeof callback !== 'function') {
//      throw new Error('Invalid callback param supplied to getFiscalData');
//    }
//    if (this.idGlobal.isNewRecordId) {
//      throw new Error('Cannot call getFiscalData for a new record');
//    }

//    if (this.selectedVariables === null) {
//      return null;
//    }

//    const headerBody = { id: this.idGlobal.value, typeId: this.portfolioType, variables: this.selectedVariables };
//    const onSuccess = (result) => {
//      const model = {
//        portfolio: new Portfolio(result.portfolio),
//        portfolioSelections: result.selections.map(obj => new PortfolioSelection(obj)),
//        clientDescription: new ResourceText(result.clientDescription),
//        changeDescription: new ResourceText(result.changeDescription)
//      };
//      this.setState({ fiscalData: model }, callback)
//    }
//    const onFail = (e) => this.idGlobal.clear(() => callApiDefaultOnFail(e, this.messageBoxGlobal));
//    apiPost(this.apiPrefix + 'resource/load', JSON.stringify(headerBody), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, onFail);
//  };

//  loadContextLayers() {
//    const url = this.apiPrefix + 'context-layers';
//    const onSuccess = (result) => {
//      const mapContextLayers = result.map(obj => new ContextLayerModel(obj));
//      this.setState({ mapContextLayers: mapContextLayers });
//    };
//    apiGet(url, this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess);
//  }

//  //loadActivities(fromDate, toDate) {
//  //  const url = getActivitiesUrl(this.geoserverUrl, fromDate, toDate);
//  //  const onSuccess = (result) => {
//  //    const mapActivities = result.features.map(obj => new ActivityModel(obj));
//  //    this.setState({ mapActivities: mapActivities });
//  //  };
//  //  apiGet(url, this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess);
//  //}

//  printButtonClick = () => {
//    const doPrint = () => {
//      // Hack to try and wait for render to complete
//      setTimeout(() => {
//        print(this.state.fiscalData.resource.resourceName);
//        this.printModeGlobal.close();
//      }, 1500);
//    };
//    this.printModeGlobal.open(doPrint);
//  };

//  showVariablesModal = () => {
//    this.setState(prevState => { return prevState.variablesModalVisible === true ? null : { variablesModalVisible: true } });
//  }

//  hideVariablesModal = () => {
//    this.setState(prevState => { return prevState.variablesModalVisible === false ? null : { variablesModalVisible: false } });
//  }

//  variablesUpdate = (value, callback) => {
//    if (typeof callback !== 'function') {
//      throw new Error('Invalid callback param supplied to FiscalView.variablesUpdate');
//    }

//    const onSuccess = (result) => callback(new Variables(result));
//    apiPost(this.apiPrefix + 'variables/update', JSON.stringify(value), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
//  }

//  variablesSave = (value) => {
//    this.selectedVariables = value;

//    const { fiscalData } = this.state;

//    if (fiscalData === null) {
//      return;
//    }

//    const onSuccess = (result) => {
//      this.setState(prevState => {
//        prevState.fiscalData.portfolio = new Portfolio(result);
//        return { fiscalData: prevState.fiscalData };
//      }, this.hideVariablesModal);
//    };
//    apiPost(this.apiPrefix + 'resource/update', JSON.stringify({ variables: value, portfolioInputs: fiscalData.portfolioInputs }), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
//  };

//  getResource = (portfolio) => {
//    return portfolio.portfolioResources.find(({ resourceId }) => resourceId === portfolio.selections[0].id);
//  };

//  getResourceCashflow = (portfolio) => {
//    return portfolio.resourceCashflows.find(({ resourceId }) => resourceId === portfolio.selections[0].id).cashflow;
//  };

//  render() {
//    const { fiscalData, variablesModalVisible } = this.state;

//    if (this.selectedVariables === null) {
//      return null;
//    }

//    if (fiscalData === null) {
//      return <div className="view-panel no-data"><h1>Please select a resource.</h1></div>;
//    }

//    const className = 'view-panel fiscal-view' + (this.printModeGlobal.value === true ? ' print-mode' : '');
//    const pageSize = this.printModeGlobal.value ? defaultPageSize : pageSizes.None;
//    const toolbarItems = [
//      <Button size="sm" className="btn-image variables-button" onClick={this.showVariablesModal}>Variables</Button>,
//      <Button size="sm" className="btn-image print-button" onClick={this.printButtonClick}>Print</Button>
//    ];

//    return (
//      <div className={className}>
//        {variablesModalVisible && <VariablesModalComponent variables={this.selectedVariables} onOk={this.variablesSave} onCancel={this.hideVariablesModal} onUpdate={this.variablesUpdate} />}
//        <ToolbarComponent items={toolbarItems} />
//        <ResourceFiscalComponent
//          geoserverUrl={this.geoserverUrl}
//          contextLayers={this.state.mapContextLayers}
//          pageSize={pageSize}
//          portfolio={fiscalData.portfolio}
//          clientDescription={fiscalData.clientDescription}
//          changeDescription={fiscalData.changeDescription}
//        />
//      </div>
//    );
//  }
//}

//FiscalView.propTypes = {
//  printModeGlobal: PropTypes.instanceOf(PrintModeGlobal).isRequired,
//  idGlobal: PropTypes.instanceOf(IdGlobal).isRequired,
//  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
//  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired
//};

//export default FiscalView;