import React from 'react';
import PropTypes from 'prop-types';
import './spatial-view.scss';

import { apiGet, callApiDefaultOnFail } from '../../common/helpers/api-helpers';
import { LoadingGlobal, MessageBoxGlobal, CountriesGlobal } from '../../common/globals/globals';
import { Button } from 'reactstrap'
import TabPanelComponent, { Tab } from '../../shared/components/tab-panel/tab-panel-component';
import SpatialGridComponent from './spatial-grid-component';
import SpatialImportResults from './spatial-import-results';
import ViewPostGISSpatial from './view-postgis-spatial.js';
//import ViewStratSplitErrors from './view-strat-split.js';
import ViewStratSplitErrors from './view-postgis-spatial.js';

class SpatialView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { data: null, hasStratSplitRun: false };
  };

  get apiPrefix() { return 'api/app/spatial/' };

  componentDidMount() {
    this.initValidationResults();
  }

  initValidationResults() {
    const onSuccess = (result) => this.setState({ data: result });
    let onFail = (e) => this.props.idGlobal.clear(() => callApiDefaultOnFail(e, this.props.messageBoxGlobal));
    apiGet(this.apiPrefix + 'integrity-get-map', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, onFail);
  }

  onPostGisToSQLClick() {
    let yesCallback = () => {
      let onSuccess = (result) => this.setState({ data: result });
      let onFail = (e) => this.props.idGlobal.clear(() => callApiDefaultOnFail(e, this.props.messageBoxGlobal));
      apiGet(this.apiPrefix + 'postgis-to-sql', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, onFail);
    }
    this.props.messageBoxGlobal.showYesNoPrompt('Are you sure you want to update SQL Spatial from PostGIS?', yesCallback, 'PostGIS to SQL');
  }

  onSQLToPostGisClick() {
    let yesCallback = () => {
      let onSuccess = (result) => this.setState({ data: result });
      let onFail = (e) => this.props.idGlobal.clear(() => callApiDefaultOnFail(e, this.props.messageBoxGlobal));
      apiGet(this.apiPrefix + 'sql-to-postgis', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, onFail);
    }
    this.props.messageBoxGlobal.showYesNoPrompt('Are you sure you want to update PostGIS from SQL Spatial?', yesCallback, 'SQL to PostGIS');
  }

  onViewPostGisDataAndIssues() {
    let onSuccess = (result) => this.setState({ data: result });
    let onFail = (e) => this.props.idGlobal.clear(() => callApiDefaultOnFail(e, this.props.messageBoxGlobal));
    apiGet(this.apiPrefix + 'view-postgis-and-issues', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, onFail);
  }

  onViewStratSplitErrors() {
    let onSuccess = (result) => {
      this.setState({ data: result, hasStratSplitRun: true });
    }
    let onFail = (e) => {
      this.props.idGlobal.clear(() => callApiDefaultOnFail(e, this.props.messageBoxGlobal));
    }
    apiGet(this.apiPrefix + 'view-strat-split-errors', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, onFail);
  }

  render() {
    const { data, hasStratSplitRun } = this.state;

    let hasData = data !== null && data !== undefined;

    let hasStratSplitErrors = hasData && data.stratSplit !== undefined && data.stratSplit !== null && data.stratSplit.length !== 0;

    let hasValidationResults = hasData && data.validationResults !== null;
    let validationResultsCount = hasValidationResults ? data.validationResults.length : 0;

    let hasShapeIssues = hasData && data.shapeIssues !== null;
    let shapeIssuesCount = hasShapeIssues ? data.shapeIssues.length : 0;

    let hasPostGISToSQLResults = hasData && data.postGISToSQL && ((data.blocks !== undefined && data.blocks !== null) || (data.fields !== undefined && data.fields !== null));
    let hasSQLToPostGISResults = hasData && data.sqlToPostGIS && ((data.blocks !== undefined && data.blocks !== null) || (data.fields !== undefined && data.fields !== null));
    let hasPostGISDataAndIssues = hasData && data.postGISDataAndIssues && ((data.blocks !== undefined && data.blocks !== null) || (data.fields !== undefined && data.fields !== null));

    let postgistosql =
      <div className="import-tab">
        <div className="import-tab-buttons">
          <Button className="btn-submit" size="sm" onClick={() => this.onViewPostGisDataAndIssues()}>View PostGIS Data & Issues</Button>
          <Button className="btn-submit" size="sm" onClick={() => this.onPostGisToSQLClick()}>PostGIS to SQL</Button>
        </div>
        {hasPostGISToSQLResults ? <SpatialImportResults messageBoxGlobal={this.props.messageBoxGlobal} blocks={data.blocks} fields={data.fields} /> : null}
        {hasPostGISDataAndIssues ? <ViewPostGISSpatial messageBoxGlobal={this.props.messageBoxGlobal} blocks={data.blocks} fields={data.fields} /> : null}
      </div>;

    let sqltopostgis =
      <div className="import-tab">
        <Button className="btn-submit" size="sm" onClick={() => this.onSQLToPostGisClick()}>SQL to PostGIS</Button>
        {hasSQLToPostGISResults ? <SpatialImportResults messageBoxGlobal={this.props.messageBoxGlobal} blocks={data.blocks} fields={data.fields} /> : null}
      </div>;

    let stratSplitErrors =
      <div className="import-tab">
        <div className="import-tab-buttons">
          <Button className="btn-submit" size="sm" onClick={() => this.onViewStratSplitErrors()}>View Strat Split Errors</Button>
        </div>
        {hasStratSplitRun ?
          hasStratSplitErrors ?
            <SpatialGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={data.stratSplit} type={'stratsplit'} />
            : <div><span>No Results Found</span></div>
          : null
        }
      </div>;

    const tabs = [
      new Tab('Validation (' + validationResultsCount.toString() + ')', hasValidationResults ? <SpatialGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={data.validationResults} type={'validationResults'} /> : null),
      new Tab('Shape Issues (' + shapeIssuesCount.toString() + ')', hasShapeIssues ? <SpatialGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={data.shapeIssues} type={'shapeIssues'} /> : null),
      new Tab('PostGIS to SQL', postgistosql),
      new Tab('Strat Split', stratSplitErrors)
      //new Tab('SQL to PostGIS', sqltopostgis),

    ];
    return (
      <div className="spatial-view">
        <h1 className="header">Spatial</h1>
        <Button className="header-button" size="sm" onClick={() => this.initValidationResults()}>Refresh</Button>
        <TabPanelComponent toolbarItems={tabs} selectedItemIndex={0} />
      </div>
    );
  }

};

SpatialView.propTypes = {
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  countriesGlobal: PropTypes.instanceOf(CountriesGlobal).isRequired,
}

export default SpatialView;
