import React from 'react';
import PropTypes from 'prop-types';

import { Attributes } from '../../../models/result';
import { IN_DEV_MODE } from '../../../../../shared/helpers/common';
import { LookupResult } from '../../../../../common/models/lookup-result';
import PropertyGridComponent, { PropertyGridStringValue, PropertyGridSelectValue } from '../../../../../shared/components/property-grid/property-grid-component';

const IDS = {
  ID: 5,
  BLOCK_NO: 10,
  SUB_AREA: 15,
  BLOCK_SUFFIX: 20,
  SUB_AREA_ABBREV: 25,
  STATUS_ID: 30,
  BLOCK_STATUS: 35,
  LICENCE_ID: 40,
  LICENCE_ROUND: 45,
  STRAT_LEVEL_ID: 50
};
Object.freeze(IDS);

const getSections = (attributes, lookups, valueChanged, lookupsFiltered) => {

  const result = [
    {
      label: 'Block',
      rows: [
        { label: 'Block No', values: [new PropertyGridStringValue(IDS.BLOCK_NO, attributes.blockNo)] },
        { label: 'Status', values: [new PropertyGridStringValue(IDS.BLOCK_STATUS, attributes.blockStatus)] },
        { label: 'Block Suffix', values: [new PropertyGridStringValue(IDS.BLOCK_SUFFIX, attributes.blockSuffix, valueChanged)] },
        { label: 'Sub Area', values: [new PropertyGridStringValue(IDS.SUB_AREA, attributes.subArea, valueChanged)] },
        { label: 'Sub Area Abbreviation', values: [new PropertyGridStringValue(IDS.SUB_AREA_ABBREV, attributes.subAreaAbbrev, valueChanged)] },
        
      ]
    },
    {
      label: 'Stratigraphic Level',
      rows: [
        { label: 'Stratigraphic Level', values: [new PropertyGridSelectValue(IDS.STRAT_LEVEL_ID, attributes.stratLevelId, valueChanged, 'key', 'value', lookups.StratLevel, true)] }
      ]
    },
  ];

  if (attributes.isLicenced) {
    result[0].rows.push(
      { label: 'Licence', values: [new PropertyGridSelectValue(IDS.LICENCE_ID, attributes.licenceId, valueChanged, 'key', 'value', lookupsFiltered.Licence)] },
      { label: 'Licence Round', values: [new PropertyGridStringValue(IDS.LICENCE_ROUND, attributes.licenceRound)] }
    );
  }

  if (IN_DEV_MODE) {
    result[0].rows.unshift({ label: 'Block Id', values: [new PropertyGridStringValue(IDS.ID, attributes.id)] });
  }

  if (attributes.countryId === 2) {
    result[0].rows = result[0].rows.filter(x => x.label !== 'Block Suffix');
  }

  return result;
};

const LeftPanelComponent = (props) => {
  const {
    editing,
    lookups,
    attributes,
    updateAttributes,
    lookupsFiltered
  } = props;

  const valueChanged = (value, id) => {
    switch (id) {
      case IDS.BLOCK_SUFFIX: attributes.blockSuffix = value; break;
      case IDS.SUB_AREA: attributes.subArea = value; break;
      case IDS.SUB_AREA_ABBREV: attributes.subAreaAbbrev = value; break;
      case IDS.STRAT_LEVEL_ID: attributes.stratLevelId = Number(value); break;

      case IDS.LICENCE_ID: attributes.licenceId = Number(value); break;

      default: throw new Error('Id ' + id + ' is not supported in LeftPanelComponent.valueChanged');
    }
    updateAttributes(attributes)
    return true;
  };

  const sections = getSections(attributes, lookups, valueChanged, lookupsFiltered);

  return <PropertyGridComponent sections={sections} readOnly={!editing} />;
};

LeftPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  attributes: PropTypes.instanceOf(Attributes).isRequired,
  updateAttributes: PropTypes.func.isRequired,
  lookupsFiltered: PropTypes.instanceOf(LookupResult).isRequired
};

export default LeftPanelComponent;
