import React from 'react';
import PropTypes from 'prop-types';

import { MessageBoxGlobal } from '../../../../../common/globals/globals';
import { HubEmissionOverride } from '../../../../../common/models/hub-emission-override';
import HubEmissionsOverridesGridComponent from '../../../../../common/components/grid/hub-emissions-overrides-grid-component';

const HubEmissionsOverridesComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    hubEmissionOverrides,
    selectedEmissionsOverrideRows,
    handleCalculatedValues,
    lookups
  } = props;

  var preventScrollToTop = props.preventScrollToTop;

  return <HubEmissionsOverridesGridComponent
    editing={editing}
    readOnly={false}
    messageBoxGlobal={messageBoxGlobal}
    hubEmissionOverrides={hubEmissionOverrides}
    preventScrollToTop={preventScrollToTop}
    selectedRows={selectedEmissionsOverrideRows}
    onAdd={props.hubOverrideActions.onAdd}
    onDelete={props.hubOverrideActions.onDelete}
    selectedEmissionsOverrideRows={props.hubOverrideActions.onSelectEmissionOverrideRow}
    handleCalculatedValues={handleCalculatedValues}
    lookups={lookups}
  />;

};

HubEmissionsOverridesComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  hubEmissionOverrides: PropTypes.arrayOf(PropTypes.instanceOf(HubEmissionOverride)).isRequired,

};

export default HubEmissionsOverridesComponent;
