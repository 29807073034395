import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as ViewFunctions from '../view-common/view-functions';
import './corporate-view.scss';

// Models
import { CorporateResult } from './models/corporate-result';
import { PostbackInfo } from '../view-common/postback-info';
// Components
import TopPanelComponent from './components/top-panel/top-panel-component';
import BottomPanelComponent from './components/bottom-panel/bottom-panel-component';
import CorporateEditComponent from './components/corporate-edit-component';
// Common models
import { RECORD_STATUS } from './../../common/models/record-status';
import { Views, ViewGlobal, IdGlobal, LoadingGlobal, MessageBoxGlobal, CountriesGlobal, EditingGlobal, SearchGlobal} from '../../common/globals/globals';
// Common components
import { Button } from 'reactstrap';
import EditableViewComponent from '../../common/components/editable-view/editable-view-component';
import SplitterPanelComponent from '../../shared/components/splitter-panel/splitter-panel-component';
import { MessageBoxButton, MessageBoxButtons, MessageBoxIcon } from '../../shared/components/message-box/message-box-classes';
// Helpers
import { apiGet, apiPost, apiGetAsynch } from '../../common/helpers/api-helpers';
import { RELATED_ITEM_TYPE } from '../../common/models/related-item-type';
import { GMATRIX_ENTITY } from '../../common/models/gmatrix-entity';

const CORPORATE_EDIT_TYPES = {
  MERGE: 1,
  CORRECT: 2,
  REBRAND: 3,
  ACTIVATE: 4,
  DEACTIVATE: 5,
  ACQUISITION: 6
};
Object.freeze(CORPORATE_EDIT_TYPES);

class CorporateView extends React.Component {
  constructor(props) {
    super(props);

    this.beginEdit = ViewFunctions.beginEdit.bind(this);
    this.cancelEdit = ViewFunctions.cancelEdit.bind(this);
    this.canButtonsEnable = ViewFunctions.canButtonsEnable.bind(this)
    this.canPerformAction = ViewFunctions.canPerformAction.bind(this)
    this.deleteEntity = ViewFunctions.deleteEntity.bind(this);
    this.didMount = ViewFunctions.didMount.bind(this)
    this.getActions = ViewFunctions.getActions.bind(this);
    this.setSelected = ViewFunctions.setSelected.bind(this);
    this.getCurrentEntity = ViewFunctions.getCurrentEntity.bind(this)
    this.getData = ViewFunctions.getData.bind(this)
    this.initData = ViewFunctions.initData.bind(this)
    this.newData = ViewFunctions.newData.bind(this)
    this.populatePostbackInfo = ViewFunctions.populatePostbackInfo.bind(this)
    this.refresh = ViewFunctions.refresh.bind(this);
    this.resetEditTimeout = ViewFunctions.resetEditTimeout.bind(this);
    this.saveEdit = ViewFunctions.saveEdit.bind(this);
    this.setDataInState = ViewFunctions.setDataInState.bind(this)
    this.updateAttributes = ViewFunctions.updateAttributes.bind(this);
    this.updateData = ViewFunctions.updateData.bind(this)
    this.updateSearchResults = ViewFunctions.updateSearchResults.bind(this);

    this.lookups = null;
    this.state = { entityData: null, canEnable: true, selectedItems: null };
    this.reClickEditButton = false;
    this.corporateEditType = null;
  }

  get apiPrefix() { return 'api/corporate/' };
  get className() { return 'view-panel corporate-view' };
  get currentView() { return Views.Corporate };
  get currentViewName() { return Views.displayName(this.currentView) };
  
  get viewGlobal() { return this.props.viewGlobal; };
  get idGlobal() { return this.props.idGlobal; };
  get editingGlobal() { return this.props.editingGlobal; };
  get countriesGlobal() { return this.props.countriesGlobal; };
  get loadingGlobal() { return this.props.loadingGlobal; };
  get messageBoxGlobal() { return this.props.messageBoxGlobal; };
  get searchGlobal() { return this.props.searchGlobal; };
  get gmatrixEntity() { return GMATRIX_ENTITY.Corporate; }

  componentDidMount() {
    this.didMount();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idGlobal.value !== this.props.idGlobal.value) {
      this.initData();
    }
    if (this.reClickEditButton) {
      this.reClickEditButton = false;
      this.editCorporate(this.corporateEditType);
    }
  }

  getPostbackInfo(forEdit, autoClose) {
    const result = new PostbackInfo();
    this.populatePostbackInfo(result, forEdit, autoClose);
    return result;
  }

  setData(newData, callback = undefined) {
    let entity = newData === null ? null : new CorporateResult(newData);

    if (entity !== null && !(entity instanceof CorporateResult)) {
      throw new Error('Invalid newData param supplied to setData');
    }

    this.setDataInState(entity, callback);
  }

  /* START - VIEW SPECIFIC METHODS */

  acquire = () => {
    this.editCorporate(CORPORATE_EDIT_TYPES.ACQUISITION);
  }

  correct = () => {
    this.editCorporate(CORPORATE_EDIT_TYPES.CORRECT);
  }

  deactivate = () => {
    const entity = this.getCurrentEntity();
    if (entity !== null) {
      const editType = entity.attributes.active ? CORPORATE_EDIT_TYPES.DEACTIVATE : CORPORATE_EDIT_TYPES.ACTIVATE;
      this.editCorporate(editType);
    }
  }

  merge = () => {
    this.editCorporate(CORPORATE_EDIT_TYPES.MERGE);
  }

  onChangeCorporateDescription = (text, date) => {
    let entity = this.getCurrentEntity();

    if (entity !== null) {
      let attributes = entity.attributes;
      attributes.corporateDescriptionDate = moment(date).utc(true);
      attributes.corporateDescription = text;
    }
  }

  rebrand = () => {
    this.editCorporate(CORPORATE_EDIT_TYPES.REBRAND);
  }

  closeCorporateEdit = () => {
    this.messageBoxGlobal.clear();
    return;
  }

  submitCorporateEdit = (corporateId, corporateEditType, selections, newCorporateName, corporateActionDate, corporateActionMessage, createNewCorporate) => {
    let corporateSelections = '&selections=' + selections;
    for (var a = 0; a < selections.length; a++) {
      if (a === 0) corporateSelections = '&selections=' + selections[a];
      else
        corporateSelections = corporateSelections + '&selections=' + selections[a];
    }

    const url = this.apiPrefix + 'editCorporate?corporateId=' + corporateId
      + '&corporateName=' + newCorporateName + '&corporateEdittype=' + corporateEditType
      + corporateSelections
      + '&corporateActionDate=' + corporateActionDate
      + '&corporateActionMessage=' + corporateActionMessage
      + '&createNewCorporate=' + createNewCorporate;

    this.messageBoxGlobal.clear();

    const apiCallback = (result) => {
      this.setData(new CorporateResult(result), this.idGlobal.set(result.attributes.id, result.attributes.corporateName, this.updateSearchResults()));

      //this.idGlobal.set(result.attributes.id, result.attributes.corporateName);

      //this.updateSearchResults();
    }

    const postbackInfo = this.getPostbackInfo(false, false);
    apiPost(url, JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, apiCallback, undefined);

  }

  nameCheck = (corporateName, corporateEditType, callback) => {
    const urlNameCheck = this.apiPrefix + 'nameCheck?corporateName=' + corporateName + '&corporateEdittype=' + corporateEditType;

    apiGet(urlNameCheck, this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, callback, undefined);
  }

  editCorporate = (corporateEditType) => {
    const entity = this.getCurrentEntity();
    if (corporateEditType === CORPORATE_EDIT_TYPES.MERGE) entity.attributes.newCorporateName = '';
    else entity.attributes.newCorporateName = entity.attributes.corporateName;

    if (entity === null) { return; }

    const attributes = entity.attributes;

    let header = '';
    let msgContents = '';

    switch (corporateEditType) {
      case CORPORATE_EDIT_TYPES.MERGE: header = 'Merge'; break;
      case CORPORATE_EDIT_TYPES.CORRECT: header = 'Correct'; break;
      case CORPORATE_EDIT_TYPES.REBRAND: header = 'Rebrand'; break;
      case CORPORATE_EDIT_TYPES.ACQUISITION: header = 'Acquire'; break;
    }

    var corporateName = attributes.newCorporateName;
    var corporateList = attributes.corporateList;

    msgContents = <CorporateEditComponent
      corporateName={corporateName}
      corporateEditType={corporateEditType}
      corporates={corporateList}
      corporateActionDate={attributes.corporateActionDate}
      onClose={this.closeCorporateEdit}
      onSubmitCorporateEdit={this.submitCorporateEdit}
      corporateId={attributes.id}
      onNameCheck={this.nameCheck}
    />

    this.messageBoxGlobal.showPanel(msgContents, header, 'lg');
  }

  /* END - VIEW SPECIFIC METHODS */

  render() {
    const entity = this.getCurrentEntity();

    if (entity === null || this.lookups === null) {
      return <div className="view-panel no-data"><h1>Please select a corporate, or click<span className="new-button" onClick={() => this.idGlobal.setNewRecordId()} />for a new corporate.</h1></div>;
    }

    const { attributes, editLock, issues } = entity;
    const activateText = attributes.active ? 'De-Activate Corporate' : 'Activate Corporate';

    const allowDeactivate = attributes.active ? attributes.allowDeactivation : !attributes.active;

    const toolbarItems = [
      <Button key={0} className="btn-image first-button merge-button" size="sm" disabled={this.editingGlobal.value || !attributes.active} onClick={this.merge}>Merge Corporate</Button>,
      <Button key={1} className="btn-image first-button correct-button" size="sm" disabled={this.editingGlobal.value} onClick={this.correct}>Correct Corporate</Button>,
      <Button key={2} className="btn-image first-button rebrand-button" size="sm" disabled={this.editingGlobal.value} onClick={this.rebrand}>Rebrand Corporate</Button>,
      <Button key={3} className="btn-image first-button deactivate-button" size="sm" disabled={this.editingGlobal.value || !allowDeactivate} onClick={this.deactivate}>{activateText}</Button>,
      <Button key={4} className="btn-image first-button acquire-button" size="sm" disabled={this.editingGlobal.value || !attributes.active} onClick={this.acquire}>Acquire Corporate</Button>
    ];

    const { canEnable } = this.state;

    const comments = attributes.comments.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const editTimeout = this.editingGlobal.value && editLock !== undefined && editLock !== null ? moment(editLock.expiryTime).toDate() : undefined;

    return (
      <EditableViewComponent
        className={this.className}
        loadingGlobal={this.loadingGlobal}
        header={this.currentViewName + ': ' + attributes.corporateName}
        messageBoxGlobal={this.messageBoxGlobal}
        editingGlobal={this.editingGlobal}
        issues={issues}
        canRefresh={!(this.idGlobal.isNewRecordId | this.idGlobal.isCopyRecordId)}
        canEnable={canEnable}
        refresh={this.refresh}
        beginEdit={this.beginEdit}
        cancelEdit={this.cancelEdit}
        saveEdit={this.saveEdit}
        editTimeout={editTimeout}
        resetEditTimeout={this.resetEditTimeout}
        toolbarItems={toolbarItems}
        toolbarItemsPosition="after"
        startYear={0}
        endYear={0}
        selectedStartYear={0}
        selectedEndYear={0}
        yearStartChange={() => { }}
        yearEndChange={() => { }}
        isProduction={false}
        canDelete={false}
        onDelete={this.delete}
        viewGlobal={this.props.viewGlobal}
        searchGlobal={this.props.searchGlobal}
        currentView={this.gmatrixEntity}
        currentId={this.idGlobal.value}
        showCalc={false}
        recordLocked={false}
        updateSnapshotAudit={() => { }}
        edited={false}
        calculate={() => { }}
        shortcuts={[]}
        onDelete={() => { }}
        canCopyPin={false}
        onCopyPin={() => { }}
      >
        <SplitterPanelComponent vertical={false} percentage secondarySize={50}>
          <TopPanelComponent
            editing={this.editingGlobal.value}
            lookups={this.lookups}
            attributes={attributes}
            updateAttributes={this.updateAttributes}
          />
          <BottomPanelComponent
            editing={this.editingGlobal.value}
            messageBoxGlobal={this.messageBoxGlobal}
            comments={comments}
            commentActions={this.getActions(attributes.comments, RELATED_ITEM_TYPE.COMMENT)}
            fields={attributes.fields}
            licences={attributes.licences}
            corporateDescriptionDate={attributes.corporateDescriptionDate}
            corporateDescription={attributes.corporateDescription}
            onChangeCorporateDescription={this.onChangeCorporateDescription}
          />
        </SplitterPanelComponent>
      </EditableViewComponent>
    );
  }

};

CorporateView.propTypes = {
  viewGlobal: PropTypes.instanceOf(ViewGlobal).isRequired,
  idGlobal: PropTypes.instanceOf(IdGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  countriesGlobal: PropTypes.instanceOf(CountriesGlobal).isRequired,
  searchGlobal: PropTypes.instanceOf(SearchGlobal).isRequired,
  editingGlobal: PropTypes.instanceOf(EditingGlobal).isRequired,
}

export default CorporateView;
export { CORPORATE_EDIT_TYPES };
