import { RECORD_STATUS } from '../models/record-status';
import { HubComment } from '../models/hub-comment';

const addHubComment = (items, hubId, push = false) => {
  if (!Array.isArray(items) || items.filter(obj => !(obj instanceof HubComment)).length > 0) {
    throw new Error('Invalid items param supplied to addHubComment');
  }
  if (typeof hubId !== 'number') {
    throw new Error('Invalid hubId param supplied to addHubComment');
  }

  const ids = items.filter(obj => obj.hubCommentId < 0).map(obj => obj.hubCommentId).sort((a, b) => b - a);
  const result = new HubComment({
    hubCommentId: (ids.length > 0 ? ids[0] : 0) - 1,
    hubId: hubId,
    commentDate: new Date(),
    textValue: '',
    allowAdd: true,
    allowRemove: true,
    status: RECORD_STATUS.added
  });

  if (push === false) {
    items.unshift(result);
  } else {
    items.push(result);
  }

  return result;
};

const deleteHubComment = (items, item) => {
  if (!Array.isArray(items) || items.filter(obj => !(obj instanceof HubComment)).length > 0) {
    throw new Error('Invalid items param supplied to deleteHubComment');
  }
  if (!(item instanceof HubComment)) {
    throw new Error('Invalid item param supplied to deleteHubComment');
  }

  if (item.status === RECORD_STATUS.added) {
    items.splice(items.indexOf(item), 1)
  } else {
    item.status = RECORD_STATUS.deleted;
  }
};

const editHubComment = (item) => {
  if (!(item instanceof HubComment)) {
    throw new Error('Invalid item param supplied to editHubComment');
  }

  item.status = item.status === RECORD_STATUS.added
    ? item.status
    : RECORD_STATUS.edited;
};


export { addHubComment, deleteHubComment, editHubComment };