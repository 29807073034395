import React from 'react';
import PropTypes from 'prop-types';
//import { Input } from 'reactstrap';
import moment from 'moment';
//import MaskedInput from 'react-text-mask';

import './queries-view-new.scss';
// Query models
import { QueryParamType, QueryParams } from './models/query-params';
import { QueryResultsInput, QueryResultsOutput } from './models/query-results';
// Query components
import QueryParamsComponent from './components/query-params-component';
//import QueryResultsGridComponent, { getFilteredRows } from './components/query-results-grid-component-new';
import QueryResultsGridComponent from './components/query-results-grid-component-new';
// Common helpers
import { apiGet, apiPost } from '../../common/helpers/api-helpers';
// Common models
import { IdGlobal, LoadingGlobal, MessageBoxGlobal, CountriesGlobal } from '../../common/globals/globals';
// Shared components
import ToolbarPanelComponent from '../../shared/components/toolbar-panel/toolbar-panel-component';
//import { DateInputMaskComponentPlaceholder, DateInputMaskComponentMask } from '../../shared/components/date-picker/date-input-mask-component';

class QueriesViewNew extends React.Component {
  constructor(props) {
    super(props);
    this.getQueryParams = this.getQueryParams.bind(this);
    this.getQueryResults = this.getQueryResults.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.setParam1 = this.setParam1.bind(this);
    this.setParam2 = this.setParam2.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
    this.setFilters = this.setFilters.bind(this);

    //this.state = { queryParams: null, queryResults: null, param1: null, param2: null, showFilters: false, filters: {} };
    this.state = { queryParams: null, queryResults: null, param1: '', param2: '', showFilters: false, filters: {} };
  }

  get apiPrefix() { return 'api/queries/' };
  get idGlobal() { return this.props.idGlobal; };
  get loadingGlobal() { return this.props.loadingGlobal; };
  get messageBoxGlobal() { return this.props.messageBoxGlobal; };
  get countriesGlobal() { return this.props.countriesGlobal; };

  componentDidMount() {

    this.getQueryParams();
    if (this.idGlobal.value !== null && this.idGlobal.value !== undefined) this.getQueryResults();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.idGlobal.value === null || prevProps.idGlobal.value === undefined) && (this.props.idGlobal.value !== null && this.props.idGlobal.value !== undefined)) {
      this.getQueryParams();
      this.getQueryResults();
    }
    else if (prevProps.idGlobal.value !== this.props.idGlobal.value) {
      //this.setState({ queryParams: null, queryResults: null, param1: null, param2: null, showFilters: false, filters: {} }, this.getQueryParams, this.getQueryResults);
      //this.setState({ queryParams: null, queryResults: null, param1: null, param2: null, showFilters: false, filters: {} });
      this.setState({ queryParams: null, queryResults: null, param1: '', param2: '', showFilters: false, filters: {} });
      this.getQueryParams();
      this.getQueryResults();

    }
  }

  getQueryParams() {
    if (this.idGlobal.isSet) {
      const apiUrl = this.apiPrefix + 'params/' + this.idGlobal.value;
      const onSuccess = (result) => {
        const queryParams = new QueryParams(result);
        //let param1 = null;
        //let param2 = null;
        let param1 = '';
        let param2 = '';
        if (queryParams.param1IsSet) {
          switch (queryParams.param1Type) {
            case QueryParamType.string: param1 = ''; break;
            case QueryParamType.date: param1 = moment().format('DD/MM/YYYY'); break;
            default: break;
          }
        }
        if (queryParams.param2IsSet) {
          switch (queryParams.param2Type) {
            case QueryParamType.string: param2 = ''; break;
            case QueryParamType.date: param2 = moment().format('DD/MM/YYYY'); break;
            default: break;
          }
        }
        this.setState({ queryParams: queryParams, queryResults: null, param1: param1, param2: param2 });
      };
      apiGet(apiUrl, this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
    }
  }

  getQueryResults() {
    const { param1, param2 } = this.state;
    const apiUrl = this.apiPrefix + 'results';
    const headerBody = new QueryResultsInput(this.countriesGlobal.value, this.idGlobal.value, param1, param2);
    const onSuccess = (result) => this.setState({ queryResults: new QueryResultsOutput(result.columnNames, result.rows) });
    apiPost(apiUrl, JSON.stringify(headerBody), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
  }

  onKeyPress(event) {
    if (event.which === 13 || event.keyCode === 13) {
      this.getQueryResults();
    }
  }

  setParam1(value) {
    this.setState(prevState => prevState.param1 === value ? null : { param1: value });
  }

  setParam2(value) {
    this.setState(prevState => prevState.param2 === value ? null : { param2: value });
  }

  toggleFilters() {
    this.setState(prevState => { return { showFilters: !prevState.showFilters }; });
  }

  setFilters(value) {
    this.setState({ filters: value });
  }

  render() {
    const { queryParams, queryResults, showFilters } = this.state;

    if (queryParams === null) {
      return <div className="view-panel no-data"><h1>Please select a query.</h1></div>;
    }

    //let filteredRows = queryResults === null ? [] : getFilteredRows(queryResults.rows, filters);
    let filteredRows = queryResults === null ? [] : queryResults.rows;

    //const getInputControl = (displayName, type, value, onChange) => {
    //  const control = type === QueryParamType.date
    //    ? <MaskedInput className="form-control-sm form-control" placeholder={DateInputMaskComponentPlaceholder} mask={DateInputMaskComponentMask} value={value} spellCheck={false} onKeyPress={this.onKeyPress} onChange={event => onChange(event.target.value)} />
    //    : <Input bsSize="sm" value={value} spellCheck={false} onKeyPress={this.onKeyPress} onChange={event => onChange(event.target.value)} />;
    //  return <React.Fragment><span>{displayName}:</span>{control}</React.Fragment>;
    //};

    const toolbarItems = [];
    if (queryParams.param1IsSet) {
      //toolbarItems.push(getInputControl(queryParams.param1DisplayName, queryParams.param1Type, param1, this.setParam1));
    }
    if (queryParams.param2IsSet) {
      //toolbarItems.push(getInputControl(queryParams.param2DisplayName, queryParams.param2Type, param2, this.setParam2));
    }

    //toolbarItems.push(<Button className="btn-image refresh-button" size="sm" onClick={this.getQueryResults}>Refresh</Button>);
    //toolbarItems.push(<Button title="Filters" className="btn-image no-text filters-button" disabled={queryResults === null} size="sm" onClick={this.toggleFilters} />);
    toolbarItems.push(<span>({filteredRows.length.toString() + (queryResults !== null && filteredRows.length < queryResults.rows.length ? '/' + queryResults.rows.length.toString() : '')} rows)</span>);

    return (
      <ToolbarPanelComponent className="view-panel queries-view" header={queryParams.queryDisplayName} toolbarItems={toolbarItems} align={'top'}>
        {
          queryResults === null
            ? <QueryParamsComponent queryParams={queryParams} />
            : <QueryResultsGridComponent messageBoxGlobal={this.messageBoxGlobal} queryId={this.idGlobal.value} columnNames={queryResults.columnNames} rows={filteredRows} showFilters={showFilters} setFilters={this.setFilters} />
        }
      </ToolbarPanelComponent>
    );
  }
}

QueriesViewNew.propTypes = {
  idGlobal: PropTypes.instanceOf(IdGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  countriesGlobal: PropTypes.instanceOf(CountriesGlobal).isRequired
};

export default QueriesViewNew;
