import React from 'react';
import PropTypes from 'prop-types';

import AttributeStaticListComponent from '../../components/attribute-list/attribute-static-list-component';
import GenericLocation from '../../models/generic-location';

import { apiGet } from '../../helpers/api-helpers';
import { LookupResult } from '../../models/lookup-result';
import { MessageBoxGlobal, LoadingGlobal } from '../../globals/globals';
import { PropertyGridNumberValue, PropertyGridBooleanValue, PropertyGridSelectValue } from '../../../shared/components/property-grid/property-grid-component';

import { INPUTTYPES } from '../../../shared/components/number-format/number-formatter';

const IDS = {
  DECIMAL_LATITUDE: 0,
  DECIMAL_LONGITUDE: 10,
  DMS_LAT_DEGREES: 20,
  DMS_LAT_MINUTES: 30,
  DMS_LAT_SECONDS: 40,
  DMS_NORTHING: 60,
  DMS_LONG_DEGREES: 70,
  DMS_LONG_MINUTES: 80,
  DMS_LONG_SECONDS: 90,
  DMS_EASTING: 100,
  STRATIGRAPHIC_SPLIT: 110,
  INTENDED_COUNTRY_ID: 120,
  BOTTOM_HOLE: 130,
};
Object.freeze(IDS);

class LocationComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { lookups: null };

    this.loadLookups();
  }

  componentDidUpdate() {
    this.loadLookups();
  }

  loadLookups() {
    if (this.props.showIntendedCountry && this.props.editing && this.state.lookups === null) {
//      const apiUrl = 'api/resource/lookups/countries';
      const apiUrl = this.props.countryLookupURL;
      const onSuccess = (result) => this.setState({ lookups: new LookupResult(result) });
      apiGet(apiUrl, this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
    }
  }

  render() {
    const { lookups } = this.state;
    const { editing, messageBoxGlobal, locations, locationActions, allowAdd, allowRemove } = this.props;

    const onEdit = (id, value, item) => {
      switch (id) {
        // R/O case IDS.DECIMAL_LATITUDE: item.decimalLatitude = value; break;
        // R/O case IDS.DECIMAL_LONGITUDE: item.decimalLongitude = value; break;
        case IDS.DMS_LAT_DEGREES: item.dmsLatDegrees = value; break;
        case IDS.DMS_LAT_MINUTES: item.dmsLatMinutes = value; break;
        case IDS.DMS_LAT_SECONDS: item.dmsLatSeconds = value; break;
        case IDS.DMS_NORTHING: item.dmsNorthing = value; break;
        case IDS.DMS_LONG_DEGREES: item.dmsLongDegrees = value; break;
        case IDS.DMS_LONG_MINUTES: item.dmsLongMinutes = value; break;
        case IDS.DMS_LONG_SECONDS: item.dmsLongSeconds = value; break;
        case IDS.DMS_EASTING: item.dmsEasting = value; break;
        case IDS.STRATIGRAPHIC_SPLIT: item.stratigraphicSplit = value; break;
        case IDS.INTENDED_COUNTRY_ID:
          item.intendedCountryId = Number(value);
          item.intendedCountryName = lookups.Countries.find(obj => obj.key === item.intendedCountryId).value;
          break;
        case IDS.BOTTOM_HOLE: item.bottomHole = value; break;
        default: throw new Error('Id ' + id + ' is not supported in LocationComponent.onEdit');
      }
      locationActions.onEdit(item);
    };

    const getTitle = (location) => {
      if (this.props.showStratSplit) return location.stratigraphicSplit === false ? 'Primary' : 'Secondary';
      if (this.props.showBottomHole) return location.bottomHole === false ? 'Top Hole' : 'Bottom Hole';
      if (this.props.showStratSplit && this.props.showBottomHole) return 'UN-HANDLED LOCATION COMPONENT CONFIGURATION - CONTACT IT';
    };

    const getRows = (item) => {
      let rows = [];
      if (this.props.showIntendedCountry) rows.push({ label: 'Intended Country', values: [new PropertyGridSelectValue(IDS.INTENDED_COUNTRY_ID, item.intendedCountryId, (value, id) => onEdit(id, value, item), 'key', 'value', lookups === null ? [{ key: item.intendedCountryId, value: item.intendedCountryName }] : lookups.Countries)] });
      rows.push({ label: 'Latitude Degrees', values: [new PropertyGridNumberValue(IDS.DMS_LAT_DEGREES, INPUTTYPES.NUMBER, item.dmsLatDegrees, (value, id) => onEdit(id, value, item))] });
      rows.push({ label: 'Latitude Minutes', values: [new PropertyGridNumberValue(IDS.DMS_LAT_MINUTES, INPUTTYPES.NUMBER, item.dmsLatMinutes, (value, id) => onEdit(id, value, item))] });
      rows.push({ label: 'Latitude Seconds', values: [new PropertyGridNumberValue(IDS.DMS_LAT_SECONDS, INPUTTYPES.NUMBER, item.dmsLatSeconds, (value, id) => onEdit(id, value, item), 2)] });
      rows.push({ label: 'Latitude Northing/Southing', values: [new PropertyGridSelectValue(IDS.DMS_NORTHING, item.dmsNorthing, (value, id) => onEdit(id, value, item), 'key', 'value', [{ key: 'N', value: 'Northing' }, { key: 'S', value: 'Southing' }])] });
      rows.push({ label: 'Longitude Degrees', values: [new PropertyGridNumberValue(IDS.DMS_LONG_DEGREES, INPUTTYPES.NUMBER, item.dmsLongDegrees, (value, id) => onEdit(id, value, item))] });
      rows.push({ label: 'Longitude Minutes', values: [new PropertyGridNumberValue(IDS.DMS_LONG_MINUTES, INPUTTYPES.NUMBER, item.dmsLongMinutes, (value, id) => onEdit(id, value, item))] });
      rows.push({ label: 'Longitude Seconds', values: [new PropertyGridNumberValue(IDS.DMS_LONG_SECONDS, INPUTTYPES.NUMBER, item.dmsLongSeconds, (value, id) => onEdit(id, value, item), 2)] });
      rows.push({ label: 'Longitude Easting/Westing', values: [new PropertyGridSelectValue(IDS.DMS_EASTING, item.dmsEasting, (value, id) => onEdit(id, value, item), 'key', 'value', [{ key: 'E', value: 'Easting' }, { key: 'W', value: 'Westing' }])] });
      if (this.props.showStratSplit) rows.push({ label: 'Stratigraphic Split', values: [new PropertyGridBooleanValue(IDS.STRATIGRAPHIC_SPLIT, item.stratigraphicSplit, (value, id) => onEdit(id, value, item))] });
      if (this.props.showBottomHole) rows.push({ label: 'Bottom Hole', values: [new PropertyGridBooleanValue(IDS.BOTTOM_HOLE, item.bottomHole, (value, id) => onEdit(id, value, item))] });
      rows.push({ label: 'Decimal Latitude', values: [new PropertyGridNumberValue(IDS.DECIMAL_LATITUDE, INPUTTYPES.NUMBER, item.decimalLatitude, undefined, 6)] });
      rows.push({ label: 'Decimal Longitude', values: [new PropertyGridNumberValue(IDS.DECIMAL_LONGITUDE, INPUTTYPES.NUMBER, item.decimalLongitude, undefined, 6)] });

      return rows;
    }

    const items = locations.map((item) => {
      return {
        label: getTitle(item),
        data: item,
        sections: [{
          label: 'Location',
          rows: getRows(item)
        }]
      }
    });

    const add = () => {
      let newItem = new GenericLocation(null);
      newItem.getNew();

      locationActions.onAdd(newItem);
    }

    return (
      <AttributeStaticListComponent
        editing={editing}
        messageBoxGlobal={messageBoxGlobal}
        items={items}
        itemDescription="location"
        canAdd={allowAdd}
        canDelete={allowRemove}
        onAdd={add}
        onDelete={locationActions.onDelete}
        onSelected={locationActions.onSelected}
        onAddPosition="end"
      />
    );
  }
};

LocationComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  locations: PropTypes.arrayOf(PropTypes.instanceOf(GenericLocation)).isRequired,
  locationActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  showIntendedCountry: PropTypes.bool.isRequired,
  showStratSplit: PropTypes.bool.isRequired,
  showBottomHole: PropTypes.bool.isRequired,
  allowAdd: PropTypes.bool.isRequired,
  allowRemove: PropTypes.bool.isRequired,
};

export default LocationComponent;
