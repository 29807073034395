import React from 'react';
import PropTypes from 'prop-types';
import CommentsComponent from '../../../../common/components/comments/comments-component';
import { GMatrixGenericComment } from '../../../../common/models/generic-comment';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import TabPanelComponent, { Tab } from '../../../../shared/components/tab-panel/tab-panel-component';

const BottomPanelComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    comments,
    commentActions,
  } = props;

  let selectedTab = 0;
  const tabs = [];

  tabs.push(new Tab('Comments', <CommentsComponent editing={editing} messageBoxGlobal={messageBoxGlobal} comments={comments} commentActions={commentActions} />));
  return (
    <TabPanelComponent align="top" toolbarItems={tabs} selectedItemIndex={selectedTab} />
  );
};

BottomPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  comments: PropTypes.arrayOf(PropTypes.instanceOf(GMatrixGenericComment)).isRequired,
  commentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
};

export default BottomPanelComponent;
