import L from 'leaflet';
import "../extenders/tilelayer.wms.canvas";

import { ContextLayerModel } from '../models/context-layer-model';

const createContextLayer = (geoserverUrl, tileSize, contextLayerModel) => {
  if (typeof geoserverUrl !== 'string') {
    throw new Error('Invalid \'geoserverUrl\' supplied to \'createContextLayer\'');
  }
  if (typeof tileSize !== 'number') {
    throw new Error('Invalid \'tileSize\' param supplied to \'createContextLayer\'');
  }
  if (!(contextLayerModel instanceof ContextLayerModel)) {
    throw new Error('Invalid \'contextLayerModel\' supplied to \'createContextLayer\'');
  }

  const options = {
    tileSize: tileSize,
    layers: contextLayerModel.geoServerLayerNames,
    format: "image/png8",
    transparent: true,
    opacity: contextLayerModel.opacity,
    styles: contextLayerModel.getStyles(),
    minZoom: contextLayerModel.minZoom,
    maxZoom: contextLayerModel.maxZoom,
    name: contextLayerModel.layerDisplayName,
    noWrap: true,
    //crossOrigin: 'use-credentials'
  };

  const result = L.tileLayer.wms.canvas(geoserverUrl, options);
  result._contextLayerModel = contextLayerModel;
  return result;
};

export { createContextLayer };
