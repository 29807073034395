import React from 'react';
import PropTypes from 'prop-types';

import { ResourceReservesHistory } from '../../../../models/production-result';
import { MessageBoxGlobal } from '../../../../../../common/globals/globals';
import AGGridComponent, { COLUMN_SMALL, COLUMN_MEDIUM, LiquidFormatter, GasFormatter, ReadOnlyGeneralFormatter, GRIDTYPES } from '../../../../../../common/components/grid/ag-grid-component';
import { valueTypes } from '../../../../../../shared/helpers/value-handler';

const HistoryComponent = (props) => {
  const { reservesHistory, messageBoxGlobal } = props;
  const columnDefs = [];

  columnDefs.push({ headerName: 'Total Recoverable Liquid (' + valueTypes.UnitString(valueTypes.mmb) + ')', suppressHeaderMenuButton: true, field: 'initialLiquid', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: LiquidFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Remaining Liquid (' + valueTypes.UnitString(valueTypes.mmb) + ')', suppressHeaderMenuButton: true, field: 'remainingLiquid', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: LiquidFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Total Recoverable Gas Reserves (' + valueTypes.UnitString(valueTypes.bcf) + ')', suppressHeaderMenuButton: true, field: 'initialGas', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, cellRenderer: GasFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Remaining Gas (' + valueTypes.UnitString(valueTypes.bcf) + ')', suppressHeaderMenuButton: true, field: 'remainingGas', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: GasFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Date Added', suppressHeaderMenuButton: true, field: 'dateAdded', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyGeneralFormatter, type: 'numeric' });

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {}
  };

  return (
    <AGGridComponent
      messageBoxGlobal={messageBoxGlobal}
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      gridData={reservesHistory}
      rowsCount={reservesHistory.length}
      editing={false}
      allowPaste={false}
      gridType={GRIDTYPES.NONE}
    />
  );

};

HistoryComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  reservesHistory: PropTypes.arrayOf(PropTypes.instanceOf(ResourceReservesHistory)).isRequired
};

export default HistoryComponent;
