import React from 'react';
import PropTypes from 'prop-types';

import AliasComponent from './alias-component';
import BlockComponent from './block-component';
import OfficialUIDComponent from './official-uid-component';
import LocationComponent from '../../../../common/components/location/location-component';
import GenericLocation from '../../../../common/models/generic-location';
import OwnershipComponent from '../../../../common/components/ownership/ownership-component';
import ProcessingComponent from './processing-component';
import CommentsComponent from '../../../../common/components/comments/comments-component';
import { GMatrixGenericComment } from '../../../../common/models/generic-comment';
import { ResourceWell } from '../../../../common/models/resource-well';
import ResourceWellComponent from '../../../../common/components/resource-well/resource-well-component';
import WestwoodDataDPFFieldComponent from '../../../../common/components/westwood-data-dpf-field/westwood-data-dpf-field-component';
import FixedPlatformComponent from './fixed-platform-component';
import FloatingPlatformComponent from './floating-platform-component';
import { StringLookupResult } from '../../../../common/models/string-lookup-result';

import { AssociatedDataContents, ResourceAnnual, ResourceAlias, ResourceBlock, ResourceText, ResourceProcessing, OfficialUID, ResourceWestwoodDataFixedPlatform, ResourceWestwoodDataFloatingPlatform } from '../../models/result';
import Ownership from '../../../../common/models/ownership';

import { LookupResult } from '../../../../common/models/lookup-result';
import { MessageBoxGlobal, LoadingGlobal } from '../../../../common/globals/globals';

import TabPanelComponent, { Tab } from '../../../../shared/components/tab-panel/tab-panel-component';

const BottomPanelComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    loadingGlobal,
    lookups,
    countryId,
    associatedDataContents,
    resourceAliases,
    resourceAliasActions,
    resourceBlocks,
    resourceBlockActions,
    productionComments,
    productionCommentActions,
    resourceComments,
    resourceCommentActions,
    locations,
    locationActions,
    countryLookupURL,
    ownership,
    ownershipActions,
    resourceProcessing,
    resourceProcessingActions,
    resourceWells,
    resourceWellActions,
    ownershiplookupURL,
    entityId,
    lookupsFiltered,
    canEditOwnership,
    officialUIDs,
    officialUIDActions,
    dpfField,
    fixedPlatforms,
    fixedPlatformActions,
    floatingPlatforms,
    floatingPlatformActions,
    lookupsWestwood
  } = props;
  let selectedTab = 0;
  const tabs = [];

  let allowLocationAdd = locations !== null && locations.length < 1 ? true : false;
  let allowLocationRemove = true;

  selectedTab = 0;


  if (associatedDataContents.canHaveAlias) {
    tabs.push(new Tab('Alias', <AliasComponent editing={editing} messageBoxGlobal={messageBoxGlobal} lookups={lookups} resourceAliases={resourceAliases} resourceAliasActions={resourceAliasActions} id={entityId} />));
  }
  if (associatedDataContents.canHaveBlock) {
    tabs.push(new Tab('Block', <BlockComponent editing={editing} messageBoxGlobal={messageBoxGlobal} loadingGlobal={loadingGlobal} resourceBlocks={resourceBlocks} countryId={countryId} resourceBlockActions={resourceBlockActions} id={entityId} />));
  }

  tabs.push(new Tab('Official UID', <OfficialUIDComponent editing={editing} messageBoxGlobal={messageBoxGlobal} loadingGlobal={loadingGlobal} officialUIDs={officialUIDs} countryId={countryId} officialUIDActions={officialUIDActions} id={entityId} />));

  if (associatedDataContents.canHaveComments) {
    tabs.push(new Tab('Resource Comments', <CommentsComponent editing={editing} messageBoxGlobal={messageBoxGlobal} comments={resourceComments} commentActions={resourceCommentActions} />))
    tabs.push(new Tab('Production Comments', <CommentsComponent editing={editing} messageBoxGlobal={messageBoxGlobal} comments={productionComments} commentActions={productionCommentActions} />))
  }
  if (associatedDataContents.canHaveLocation) {
    tabs.push(new Tab('Location',
      <LocationComponent
        editing={editing}
        loadingGlobal={loadingGlobal}
        messageBoxGlobal={messageBoxGlobal}
        locations={locations}
        locationActions={locationActions}
        showIntendedCountry={true}
        showStratSplit={true}
        showBottomHole={false}
        allowAdd={allowLocationAdd}
        allowRemove={allowLocationRemove}
        countryLookupURL={countryLookupURL}
      />));
  }
  if (associatedDataContents.canHaveOwnership) {
    let canEdit = canEditOwnership ? editing  : false;
    tabs.push(new Tab('Ownership', <OwnershipComponent editing={canEdit} messageBoxGlobal={messageBoxGlobal} loadingGlobal={loadingGlobal} ownership={ownership} ownershipActions={ownershipActions} lookupURL={ownershiplookupURL} id={entityId} />));
  }
  if (associatedDataContents.canHaveProcessing) {
    tabs.push(new Tab('Processing', <ProcessingComponent editing={editing} messageBoxGlobal={messageBoxGlobal} loadingGlobal={loadingGlobal} resourceProcessing={resourceProcessing} resourceProcessingActions={resourceProcessingActions} id={entityId} />));
  }
  if (associatedDataContents.canHaveWell) {
    //tabs.push(new Tab('Well', <WellComponent editing={editing} messageBoxGlobal={messageBoxGlobal} loadingGlobal={loadingGlobal} resourceWells={resourceWells} countryId={countryId} resourceWellActions={resourceWellActions} />));
    tabs.push(new Tab('Well',
      <ResourceWellComponent
        editing={editing}
        label={'Wells'}
        messageBoxGlobal={messageBoxGlobal}
        wells={lookupsFiltered.Wells}
        loadingGlobal={loadingGlobal}
        resourceWell={resourceWells}
        resourceWellActions={resourceWellActions}
        countryId={countryId}
        fromWell={false}
        resourceId={entityId}
        wellId={-1}
      />));
  }
  if (associatedDataContents.canHaveWestwoodDataField && dpfField !== null) {
    tabs.push(new Tab('Westwood Data Field Information', <WestwoodDataDPFFieldComponent dpfField={dpfField} />));
    // ADD A SPLITTER IN HERE SO CAN ALSO DISPLAY RELATED FACILITIES & PERHAPS ALSO COMPONENTS?
  }

  if (associatedDataContents.canHaveWestwoodDataPlatform) {
    tabs.push(new Tab('Westwood Data Fixed Platforms', <FixedPlatformComponent editing={editing} messageBoxGlobal={messageBoxGlobal} lookupsWestwood={lookupsWestwood} fixedPlatforms={fixedPlatforms} fixedPlatformActions={fixedPlatformActions} id={entityId} />));
    tabs.push(new Tab('Westwood Data Floating Platforms', <FloatingPlatformComponent editing={editing} messageBoxGlobal={messageBoxGlobal} lookupsWestwood={lookupsWestwood} floatingPlatforms={floatingPlatforms} floatingPlatformActions={floatingPlatformActions} id={entityId} />));
  }

  return <TabPanelComponent toolbarItems={tabs} selectedItemIndex={selectedTab} />;
};

BottomPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  countryId: PropTypes.number.isRequired,
  associatedDataContents: PropTypes.instanceOf(AssociatedDataContents).isRequired,
  resourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  resourceAliases: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAlias)).isRequired,
  resourceAliasActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  resourceBlocks: PropTypes.arrayOf(PropTypes.instanceOf(ResourceBlock)).isRequired,
  resourceBlockActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  officialUIDs: PropTypes.arrayOf(PropTypes.instanceOf(OfficialUID)).isRequired,
  officialUIDActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  productionComments: PropTypes.arrayOf(PropTypes.instanceOf(GMatrixGenericComment)).isRequired,
  productionCommentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  resourceComments: PropTypes.arrayOf(PropTypes.instanceOf(GMatrixGenericComment)).isRequired,
  resourceCommentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  resourceDescriptions: PropTypes.arrayOf(PropTypes.instanceOf(ResourceText)).isRequired,
  resourceDescriptionActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  locations: PropTypes.arrayOf(PropTypes.instanceOf(GenericLocation)).isRequired,
  locationActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  countryLookupURL: PropTypes.string.isRequired,
  ownership: PropTypes.arrayOf(PropTypes.instanceOf(Ownership)),
  ownershipActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  resourceProcessing: PropTypes.arrayOf(PropTypes.instanceOf(ResourceProcessing)).isRequired,
  resourceProcessingActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  resourceWells: PropTypes.arrayOf(PropTypes.instanceOf(ResourceWell)).isRequired,
  resourceWellActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  ownershiplookupURL: PropTypes.string.isRequired,
  canEditOwnership: PropTypes.bool.isRequired,
  fixedPlatforms: PropTypes.arrayOf(PropTypes.instanceOf(ResourceWestwoodDataFixedPlatform)),
  fixedPlatformActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }),
  floatingPlatforms: PropTypes.arrayOf(PropTypes.instanceOf(ResourceWestwoodDataFloatingPlatform)),
  floatingPlatformActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }),
  lookupsWestwood: PropTypes.instanceOf(StringLookupResult).isRequired,
};

export default BottomPanelComponent;
