import React from 'react';
import { useState } from 'react'

const HermesToggleComponent = ({ label, toggled, onClick }) => {
  const [isToggled, toggle] = useState(toggled);

  const callback = () => {
    toggle(!isToggled)
    onClick(!isToggled)
  }

  return (
    <div className="hermes-toggle">
      <h4 className="toggle-label"><small>{label}</small></h4>
      <label className="switch">
        <input type="checkbox" defaultChecked={isToggled} onClick={callback} />
        <span className="slider round" />  
      </label>
    </div>

  );
};

export default HermesToggleComponent;
