import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap'
import { apiPostFile } from '../../common/helpers/api-helpers';
import './emissions-uploader-view.scss';
import { LoadingGlobal, MessageBoxGlobal } from '../../common/globals/globals';
import IssuesGridComponent, { IssueType, Issue } from '../../common/components/editable-view/issues-grid-component';

const FILETYPES = {
  EMISSION: 0,
  ALLOWANCE: 1
};
Object.freeze(FILETYPES);

class EmissionsUploaderView extends React.Component {
  constructor(props) {
    super(props);

    this.inputFileEmissionRef = React.createRef();
    this.inputFileAllowanceRef = React.createRef();

    this.onButtonEmissionClick = this.onButtonEmissionClick.bind(this);
    this.onButtonAllowanceClick = this.onButtonAllowanceClick.bind(this);
    this.onChangeEmissionFile = this.onChangeEmissionFile.bind(this);
    this.onChangeAllowanceFile = this.onChangeAllowanceFile.bind(this);
    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
    this.fileSelect = this.fileSelect.bind(this);
    this.fileSelectedChanged = this.fileSelectedChanged.bind(this);

    this.state = { fileOutput: null, file: null, fileName: '', validateButton: null, submitButton: null, issues: [], fileType: null };
  };

  onSubmitButtonClick = (validate) => {
    var file = new FormData();
    file.append(this.state.fileName, this.state.file);

    const onSuccess = (result) => {
      if (result.errormessage !== undefined && result.errormessage !== null)
        this.props.messageBoxGlobal.showInformation(result.errormessage);
      else if (result.issues !== undefined && result.issues !== null && result.issues.length > 0) {
        this.props.messageBoxGlobal.showInformation("Validation failed, see issues in grid!");
        this.setState({ issues: result.issues.map(item => new Issue(item)), fileOutput: null, file: null, fileName: '', fileType: null, validateButton: null, submitButton: null });
      }
      else
        this.props.messageBoxGlobal.showInformation("all good");
    }

    const onFail = (result) => {
      if (result !== null && result.toString() === 'TypeError: Failed to fetch') {
        if (this.inputFileEmissionRef !== undefined) this.inputFileEmissionRef.current.value = null;
        if (this.inputFileAllowanceRef !== undefined) this.inputFileAllowanceRef.current.value = null;
        this.props.messageBoxGlobal.showInformation('An error occured fetching the file, this is probably due to the file changing, please reselect file and try again!');
      }
      else
        this.props.messageBoxGlobal.showInformation('An error occured, please try again or contact IT');

      this.setState({ fileOutput: null, file: null, fileName: '', issues: [], fileType: null, validateButton: null, submitButton: null });
    }

    var isEmission = this.state.fileType === FILETYPES.EMISSION ? true : false;
    apiPostFile('api/fileupload/loadTheFile?validate=' + validate + '&isEmissions=' + isEmission, file, this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, onFail);
  };

  onButtonEmissionClick = () => {
    this.fileSelect(true);
  };

  onButtonAllowanceClick = () => {
    this.fileSelect(false);
  };

  fileSelect(isEmission) {
    if (!isEmission && this.inputFileAllowanceRef !== undefined) this.inputFileAllowanceRef.current.value = null;
    if (isEmission && this.inputFileEmissionRef !== undefined) this.inputFileEmissionRef.current.value = null;
    if (!isEmission) this.inputFileAllowanceRef.current.click();
    if (isEmission) this.inputFileEmissionRef.current.click();

    var fileType = isEmission ? FILETYPES.EMISSION : FILETYPES.ALLOWANCE;
    this.setState({ fileType: fileType, issues: [] });
  }

  onChangeEmissionFile = (event) => {
    if (this.inputFileEmissionRef.current !== null) {
      this.fileSelectedChanged(event.target.files[0]);
    }
  }

  onChangeAllowanceFile = (event) => {
    if (this.inputFileAllowanceRef.current !== null) {
      this.fileSelectedChanged(event.target.files[0]);
    }
  }

  fileSelectedChanged(file) {
    var iFileOutput =
      <div className="file-selection">
        <h4 className="file-selection-header">File selected - </h4>
        <span>{file.name}</span> 
      </div>

    var type = this.state.fileType === FILETYPES.EMISSION ? 'Emissions' : 'Allowances';
    var vButton = file !== null ? <Button className="btn-validate" size="sm" onClick={() => this.onSubmitButtonClick(true)}>Validate {type} file</Button> : null;
    var sButton = file !== null ? <Button className="btn-submit" size="sm" onClick={() => this.onSubmitButtonClick(false)}>Submit {type} file</Button> : null;

    this.setState({ fileOutput: iFileOutput, file: file, fileName: file.name, validateButton: vButton, submitButton: sButton, issues: [] });
  }


  render() {
    const { issues } = this.state;
    var issueGrid = null;
    var hide = 'hide';
    if (issues !== undefined && issues !== null && issues.length > 0) {
      issueGrid = <IssuesGridComponent messageBoxGlobal={this.props.messageBoxGlobal} editingGlobal={this.props.editingGlobal} issues={issues} />
      hide = '';
    }

    const ButtonSelectionHeader = this.state.validateButton !== null || this.state.submitButton !== null ? <h4 className="button-select-header">Please select an option</h4> : null;

    return (
      <div className="emissions-uploader-view">
        <h1>Emissions Uploader</h1>
        <div style={{ margin: '10px', borderTop: '1px solid black' }}>
          <h4 className="selection-header">Please Make a selection</h4>
          <input type="file" id="emissionfile" ref={this.inputFileEmissionRef} style={{ display: 'none' }} onChange={this.onChangeEmissionFile} />
          <input type="file" id="alowancefile" ref={this.inputFileAllowanceRef} style={{ display: 'none' }} onChange={this.onChangeAllowanceFile} />
          <Button className="btn-file-select-emissions" size="sm" onClick={this.onButtonEmissionClick}>Select Emissions File</Button>
          <Button className="btn-file-select-allowances" size="sm" onClick={this.onButtonAllowanceClick}>Select Allowance File</Button>

          {this.state.fileOutput}
          {ButtonSelectionHeader}
          {this.state.validateButton}
          {this.state.submitButton}

        </div>
        <div className={"issues-section " + hide}>
          {issueGrid}
        </div>
      </div>
    );
  }

};

EmissionsUploaderView.propTypes = {
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
}

export default EmissionsUploaderView;
