import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { zoomLevels } from './helpers/zoom-levels';
import { MapControls } from './helpers/map-controls';
import { ActivityModel } from './models/activity-model';
import { createTopographyLayer } from './layers/topography-layer';
import { createCircleLayer, CircleModel } from './layers/circle-layer';
import { createPolygonsLayer, PolygonsModel } from './layers/polygons-layer';
import { SearchResultModel, searchResultType } from './models/search-result-model';
import { createCircleMarkerLayer, CircleMarkerModel } from './layers/circle-marker-layer';
import { ContextLayerController, ContextLayerModel, getLayersForZoom } from './helpers/context-layer-controller';

//const DEFAULT_TILE_SIZE = 768;
const DEFAULT_TILE_SIZE = 800;
//const COMBINED_UK_AND_NORWAY_MAP_BOUNDS = [[48.1666670, -23.5866510], [74.4999999996003, 37.0006860000589]]; //JB - combined UK & Norway extents
// FORMAT: [[BOTTOM LEFT LATITUDE, BOTTOM LEFT LONGITUDE], [TOP RIGHT LATITUDE, TOP RIGHT LONGITUDE]]
const COMBINED_UK_AND_NORWAY_MAP_BOUNDS = [[45, -27], [77, 40]]; //JB - combined UK & Norway extents - updated 19042023
const FLY_TO_OPTIONS = { animate: false };

let _mapCount = 0;
function _getNextId() {
  _mapCount++;
  const result = 'map-component-' + _mapCount;
  return result;
};

function getDefaultMaxBounds() {
  const result = L.latLngBounds(COMBINED_UK_AND_NORWAY_MAP_BOUNDS).pad(0.05);
  return result;
};

class LeafletHelper {
  constructor(geoserverUrl, id = undefined, excelExport = undefined, isGME = undefined) {
    if (typeof geoserverUrl !== 'string') {
      throw new Error('Invalid \'geoserverUrl\' supplied to \'LeafletHelper.ctor\'');
    }

    this._geoserverUrl = geoserverUrl;
    this._id = typeof id === 'string' ? id : _getNextId();
    this._leafletMap = null;
    this._controls = null;
    this._contextLayerController = null;
    this._circleMarkers = null;
    this._polygons = null;
    this._excelExport = excelExport;
    this._isGME = isGME;
  }

  deletekeys(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] !== null && typeof obj[key] === 'object') {
        this.deletekeys(obj[key]);
      }
      else {
        delete obj[key];
      }
    });
  }

  destroy() {
    if (this._contextLayerController !== null && this._contextLayerController !== undefined) {
      this._contextLayerController.destroy();
    }

    delete this._contextLayerController;

    if (this._controls !== null && this._controls !== undefined) {
      this._controls.destroy();
    }

    delete this._controls;

    if (this._leafletMap !== null) this._leafletMap.remove();

    delete this._polygons;
    delete this._geoserverUrl;
    delete this._id;
    delete this._leafletMap;
    delete this._circleMarkers;
    delete this.createLayer;
    delete this;
  }

  get initialized() { return this._leafletMap !== null; }
  get id() { return this._id; }
  get leafletMap() { return this._leafletMap; }
  get controls() { return this._controls; }
  get contextLayerController() { return this._contextLayerController; }
  get circleMarkers() { return this._circleMarkers; }
  get polygons() { return this._polygons; }

  initialize(contextLayerModels = [], mapProps = {}) {
    if (this._leafletMap !== null) {
      throw new Error('\'LeafletHelper.initialize\' has already been called');
    }
    if (!Array.isArray(contextLayerModels) || contextLayerModels.filter(obj => !(obj instanceof ContextLayerModel)).length > 0) {
      throw new Error('Invalid \'contextLayerModels\' param supplied to \'LeafletHelper.initialize\'');
    }
    if (typeof mapProps !== 'object') {
      throw new Error('Invalid \'mapProps\' supplied to \'LeafletHelper.initialize\'');
    }

    mapProps.zoomControl = false;
    if (mapProps.maxBounds === undefined) { mapProps.maxBounds = getDefaultMaxBounds(); }
    if (mapProps.minZoom === undefined) { mapProps.minZoom = zoomLevels._500km; }
    if (mapProps.maxZoom === undefined) { mapProps.maxZoom = zoomLevels._500m; }
    if (mapProps.zoom === undefined) { mapProps.zoom = zoomLevels._500km; }
    if (mapProps.zoom < mapProps.minZoom) { mapProps.zoom = mapProps.minZoom; }
    if (mapProps.zoom > mapProps.maxZoom) { mapProps.zoom = mapProps.maxZoom; }
    if (mapProps.center === undefined) { mapProps.center = [54.02067955159995, -3.9990234375000004]; }
    if (mapProps.maxBoundsViscosity === undefined) { mapProps.maxBoundsViscosity = 1.0; }
    if (mapProps.layers === undefined) { mapProps.layers = []; }
    if (mapProps.preferCanvas === undefined) { mapProps.preferCanvas = true; }

    mapProps.layers = [
      ...mapProps.layers,
      createTopographyLayer(DEFAULT_TILE_SIZE),
      ...getLayersForZoom(this._geoserverUrl, DEFAULT_TILE_SIZE, contextLayerModels, mapProps.zoom)
    ];

    this._leafletMap = L.map(this.id, mapProps);

    this._contextLayerController = new ContextLayerController(this._leafletMap, this._geoserverUrl, DEFAULT_TILE_SIZE, contextLayerModels);
    this._controls = new MapControls(this._leafletMap, this._contextLayerController, this._excelExport, this._isGME);

    this._contextLayerController.consoleLogLayers();
  }

  getBounds(latLngs) {
    const result = L.latLngBounds(latLngs);
    return result;
  }

  fitBounds(bounds, zoom = null) {
    if (!bounds.isValid()) {
      return;
    }

    if (zoomLevels.isInvalid(zoom)) {
      this._leafletMap.fitBounds(bounds);
    } else {
      const center = bounds.getCenter();
      zoom = Math.min(zoom, this.getBoundsZoom(bounds));
      this.setView(center, zoom);
    }
  }

  setView(center, zoom) {
    this._leafletMap.setView(center, zoom);
  }

  flyToBounds(bounds, zoom = null) {
    if (!bounds.isValid()) {
      return;
    }

    if (zoomLevels.isInvalid(zoom)) {
      this._leafletMap.flyToBounds(bounds, FLY_TO_OPTIONS);
    } else {
      const center = bounds.getCenter();
      zoom = Math.min(zoom, this.getBoundsZoom(bounds));
      this.flyTo(center, zoom);
    }
  }

  flyTo(latlng, zoom) {
    this._leafletMap.flyTo(latlng, zoom, FLY_TO_OPTIONS);
  }

  getBoundsZoom(bounds) {
    return this._leafletMap.getBoundsZoom(bounds, 0);
  }

  createLayer(model) {
    if (model instanceof PolygonsModel) {
      return createPolygonsLayer(model);
    }

    if (model instanceof CircleMarkerModel) {
      return createCircleMarkerLayer(model);
    }

    if (model instanceof CircleModel) {
      return createCircleLayer(model);
    }

    return null;
  }

}

const compareShapes = (a, b) => {
  if (typeof a !== 'object' || typeof b !== 'object') {
    throw new Error('Invalid "object" param supplied to "LeafletHelper.compareShapes"');
  }

  if (a.lat !== b.lat) return false;
  if (a.lng !== b.lng) return false;

  return true;
};

const LatLngBounds = L.LatLngBounds;
const latLngBounds = L.latLngBounds;
const LatLng = L.LatLng;
const latLng = L.latLng;

export { LeafletHelper };
export { getDefaultMaxBounds, zoomLevels, ContextLayerModel, ActivityModel, CircleModel, CircleMarkerModel, PolygonsModel, SearchResultModel, searchResultType, LatLngBounds, latLngBounds, LatLng, latLng, compareShapes };
