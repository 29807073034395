import L from 'leaflet';

import { faMousePointer } from '@fortawesome/pro-regular-svg-icons';

import './gme-buttons-control.scss';
import { MeasureButtonControl } from '../common/measure-button-control';
import { PinLocationButtonControl } from './pin-location-button-control';

L.Control.GMEButtonsControl = L.Control.extend({
  initialize: function (options) { this.options = options; },
  onAdd: function () { return this.options.container; }
});

class GMEButtonsControl {
  constructor(leafletMap) {
    if (!(leafletMap instanceof L.Map)) {
      throw new Error('Invalid "leafletMap" param supplied to "GMEButtonsControl.ctor"');
    }

    this._ignoreMouseEvents = false;
    this._leafletMap = leafletMap;

    this._container = L.DomUtil.create('div', 'leaflet-bar gme-buttons-control');
    L.DomEvent.on(this._container, 'mouseenter', () => this._ignoreMouseEvents = true, this);
    L.DomEvent.on(this._container, 'mouseleave', () => this._ignoreMouseEvents = false, this);

    this.measure = new MeasureButtonControl(this);
    this.pin = new PinLocationButtonControl(this);

    this._leafletMap.addControl(new L.Control.GMEButtonsControl({ position: 'topleft', container: this._container }));
  }

  get ignoreMouseEvents() {
    return this._ignoreMouseEvents;
  }
  get leafletMap() {
    return this._leafletMap;
  }
  get container() {
    return this._container;
  }

  cancelAll = () => {
    this.measure.cancel();
    this.pin.cancel();
  }

  updateCancelButton() {
    //TODO: commented out until we revisit 181 - Retain Select Tool Function
    //const toggled = this.pointSelect.toggled ||
    //  this.boxSelect.toggled ||
    //  this.radiusSelect.toggled ||
    //  this.measure.toggled;

    //const disabled = hasClass(this._cancelButton, DISABLED_CLASS_NAME);
    //if (toggled === disabled) {
    //  toggleClass(this._cancelButton, DISABLED_CLASS_NAME);
    //}
  }
}

export { GMEButtonsControl };