import React from "react";
import PropTypes from 'prop-types';

import { MessageBoxGlobal } from '../../common/globals/globals';

import AGGridComponent, { COLUMN_MEDIUM, COLUMN_MEDIUM_MEDIUM, COLUMN_LARGE, COLUMN_EXTRA_LARGE, COLUMN_WIDE } from '../../common/components/grid/ag-grid-component';

const HermesCompareGridComponent = (props) => {
  const { messageBoxGlobal, data } = props;

  const reformatDateValue = (value) => {
    if (value === null || value === undefined) return null;
    let dateValue = value.toISOString();
    let formattedDate = new Date(dateValue).toLocaleString().split(',')[0];

    return formattedDate;
  }

  const dateCompare = (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue;

    if (dateAsString == null) {
      return 0;
    }

    let dateValue = dateAsString.toISOString();
    let cellDate = new Date(dateValue).toLocaleString().split(',')[0];

    let agDateValue = filterLocalDateAtMidnight.toISOString();
    let agDateFormatted = new Date(agDateValue).toLocaleString().split(',')[0];

    if (cellDate < agDateFormatted) {
      return -1;
    } else if (cellDate > agDateFormatted) {
      return 1;
    }
    return 0;
  }

  const columnDefs = [];
  columnDefs.push({ headerName: 'Name', suppressHeaderMenuButton: false, resizable: true, field: 'nameValue', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
  //columnDefs.push({ headerName: 'Type', suppressHeaderMenuButton: true, resizable: true, field: 'wlbWell', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
  columnDefs.push({ headerName: 'Attribute', suppressHeaderMenuButton: false, resizable: true, field: 'attributeName', sortable: true, filter: false, editable: false, width: COLUMN_LARGE, type: 'text' });
  columnDefs.push({ headerName: 'Previous Value', suppressHeaderMenuButton: false, resizable: true, field: 'oldValue', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'text' });
  columnDefs.push({ headerName: 'Current Value', suppressHeaderMenuButton: false, resizable: true, field: 'newValue', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'text' });
  //columnDefs.push({ headerName: 'Country', suppressHeaderMenuButton: true, resizable: true, field: 'wlbStatus', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
  columnDefs.push({
    headerName: 'Previous Date',
    suppressHeaderMenuButton: false,
    resizable: true,
    field: 'oldDate',
    sortable: true,
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        return dateCompare(filterLocalDateAtMidnight, cellValue);
      }
    },
    editable: false,
    width: COLUMN_MEDIUM_MEDIUM,
    type: 'text',
    valueFormatter: function (params) {
      return reformatDateValue(params.value);
    }
  });
  columnDefs.push({
    headerName: 'Date Updated',
    suppressHeaderMenuButton: false,
    resizable: true,
    field: 'newDate',
    sortable: true,
    filter: 'agDateColumnFilter',
    editable: false,
    width: COLUMN_MEDIUM_MEDIUM,
    type: 'text',
    valueFormatter: function (params) {
      return reformatDateValue(params.value);
    },
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        return dateCompare(filterLocalDateAtMidnight, cellValue);
      }
    }
  });
  columnDefs.push({ headerName: 'Status', suppressHeaderMenuButton: false, resizable: true, field: 'differenceType', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {},
    image: {}
  };

  return (
    <AGGridComponent
      messageBoxGlobal={messageBoxGlobal}
      columnDefs={columnDefs}
      rowsCount={data.length}
      columnTypes={columnTypes}
      gridData={data}
      allowPaste={false}
      editYear={0}
      autoResize
    />
  );
};

HermesCompareGridComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
};

export default HermesCompareGridComponent;
