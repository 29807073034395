import React from 'react';
import PropTypes from 'prop-types';
import './npd-wells-compare.scss';

import { apiGet } from '../../common/helpers/api-helpers';
import { LoadingGlobal, MessageBoxGlobal } from '../../common/globals/globals';
import TabPanelComponent, { Tab } from '../../shared/components/tab-panel/tab-panel-component';
import NPDCompareGridComponent from './npd-compare-grid-component';

class NPDWellsCompareView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { devwells: null, devwellsmissing: null, expwells: null, expwellsmissing: null };
  };

  get apiPrefix() { return 'api/npd-data/' };

  componentDidMount() {
    this.initCompareData();
  }

  initCompareData() {
    const onSuccess = (result) => {
      this.setState({ devwells: result.devwells, devwellsmissing: result.devwellsmissing, expwells: result.expwells, expwellsmissing: result.expwellsmissing });
    }
    apiGet(this.apiPrefix + 'npd-well-data', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
  }

  render() {
    const { devwells, devwellsmissing, expwells, expwellsmissing } = this.state;

    let hasDevWells = devwells !== null;
    let devWellsCount = devwells ? devwells.length : 0;
    let hasDevWellsMissing = devwellsmissing !== null;
    let devWellsMissingCount = devwellsmissing ? devwellsmissing.length : 0;
    let hasExpWells = expwells !== null;
    let expWellsCount = expwells ? expwells.length : 0;
    let hasExpWellsMissing = expwellsmissing !== null;
    let expWellsMissingCount = expwellsmissing ? expwellsmissing.length : 0;

    const tabs = [
      new Tab('NPD Dev Wells (' + devWellsCount.toString() + ')', hasDevWells ? <NPDCompareGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={devwells} type={1} /> : null),
      new Tab('NPD Dev Wells Not in GM (' + devWellsMissingCount.toString() + ')', hasDevWellsMissing ? <NPDCompareGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={devwellsmissing} type={2} /> : null),
      new Tab('NPD Exp Wells (' + expWellsCount.toString() + ')', hasExpWells ? <NPDCompareGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={expwells} type={3} /> : null),
      new Tab('NPD Exp Wells Not in GM (' + expWellsMissingCount.toString() + ')', hasExpWellsMissing ? <NPDCompareGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={expwellsmissing} type={4} /> : null)
    ];
    return (
      <div className="npd-wells-compare-view">
        <h1>NPD Wells Compare</h1>
        <TabPanelComponent toolbarItems={tabs} selectedItemIndex={0} />
      </div>
    );
  }

};

NPDWellsCompareView.propTypes = {
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
}

export default NPDWellsCompareView;
