import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

import './property-grid-table-component.scss';
import { LABEL_CLASS_NAME, PropertyGridBaseValue, PropertyGridStringValue, PropertyGridDateValueUTC, PropertyGridDateValue, PropertyGridNumberValue, PropertyGridNumberValueDouble, PropertyGridPercentageValue, PropertyGridSelectValue, PropertyGridBooleanValue, PropertyGridLargeStringValue } from './property-grid-values';
import { appendClassNames } from '../../helpers/common';

const getCell = (readOnly, rowIndex, colIndex, colStyle, value) => {
  const key = 'row-' + rowIndex + '-column-' + colIndex;
  const canEdit = !readOnly && value.canEdit;
  return (
    <td key={key} className={'column-value column-' + colIndex.toString()} style={colStyle}>
      {canEdit ? value.getControl() : value.getLabel()}
    </td>
  );
};

const getRow = (readOnly, row, rowIndex, colStyle) => {
  const key = 'row-' + rowIndex.toString();
  return (
    <tr key={key} className={key}>
      <td className="column-label" style={colStyle}>
        <div className={LABEL_CLASS_NAME}>{row.label}</div>
      </td>
      {row.values.map((obj, colIndex) => getCell(readOnly, rowIndex, colIndex, colStyle, obj))}
    </tr>
  );
};

const PropertyGridTableComponent = (props) => {
  let { rows, readOnly, narrow } = props;
  if (narrow === undefined || narrow === null) narrow = false;

  let className = appendClassNames('property-grid-table-component', props.className);
  let colStyle = undefined;
  if (rows.length > 0 && rows[0].values.length > 0) {
    if (rows[0].values.length === 1) {
      if (narrow) {
        className += ' single-column-narrow';
      } else {
        className += ' single-column';
      }
    } else {
      colStyle = { width: 'calc(100% / ' + (rows[0].values.length + 1).toString() + ')' };
    }
  }

  return (
    <div className={className}>
      <Table size="sm">
        <tbody>
          {rows.map((row, rowIndex) => getRow(readOnly, row, rowIndex, colStyle))}
        </tbody>
      </Table>
    </div >
  );
};

const RowPropType = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  values: PropTypes.arrayOf(PropTypes.instanceOf(PropertyGridBaseValue)).isRequired
});

PropertyGridTableComponent.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(RowPropType).isRequired,
  narrow: PropTypes.bool,
};

export default PropertyGridTableComponent;
export { RowPropType, PropertyGridBaseValue, PropertyGridStringValue, PropertyGridDateValueUTC, PropertyGridDateValue, PropertyGridNumberValue, PropertyGridNumberValueDouble, PropertyGridPercentageValue, PropertyGridSelectValue, PropertyGridBooleanValue, PropertyGridLargeStringValue };
