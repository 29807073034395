export default class TextEditor {
  init(params) {
    this.value = params.value;

    this.input = document.createElement('input');
    this.input.id = 'input';
    this.input.type = 'text';
    this.input.value = this.value;
    this.input.style = 'width: 100%';

    this.input.addEventListener('input', (event) => {
      this.value = event.target.value;
      event.stopPropagation();
    });

    this.input.addEventListener('keydown', this.onKeyDown);
    this.input.addEventListener('keypress', this.onKeyPress);
  }

  /* Component Editor Lifecycle methods */
  // gets called once when grid ready to insert the element
  getGui() {
    this.input.focus();
    return this.input;
  }

  // the final value to send to the grid, on completion of editing
  getValue() {
    this.input.focus();
    return this.value;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  }

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    return false;
  }

  // after this component has been created and inserted into the grid
  afterGuiAttached(e) {
    this.input.focus();
  }

  onKeyPress(event) {
  }

  onKeyDown(event) {
  }

}
