import React from 'react';
import PropTypes from 'prop-types';

import AttributeStaticListComponent from '../../components/attribute-list/attribute-static-list-component';
import Ownership from '../../models/ownership';
import { apiGet } from '../../helpers/api-helpers';
import { LookupResult } from '../../models/lookup-result';
import { RECORD_STATUS } from '../../models/record-status';
import { MessageBoxGlobal, LoadingGlobal } from '../../globals/globals';
import { PropertyGridSelectValue, PropertyGridNumberValue, PropertyGridBooleanValue, PropertyGridStringValue } from '../../../shared/components/property-grid/property-grid-component';
import { INPUTTYPES } from '../../../shared/components/number-format/number-formatter';

const IDS = {
  CORPORATE_ID: 10,
  EQUITY: 20,
  IS_OPERATOR: 30,
  IS_CORE: 40,
  IS_ASSUMED: 50,
  COMMERCIAL_COMMENT: 60,
  ADMIN_COMMENT: 70,
  IS_OPERATOR_ONLY: 80,
  TIER_ID: 90,
};
Object.freeze(IDS);

class OwnershipComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { lookups: null, id: this.props.id };

    this.loadLookups();
  }

  componentDidUpdate() {
    const { id } = this.props;
    this.setState(prevState => {
      return prevState.id === id ? null :
        {
          id: id,
          lookups: null
        };
    });

    this.loadLookups();
  }

  loadLookups() {
    if (this.state.lookups === null) {
      const apiUrl = this.props.lookupURL
      const onSuccess = (result) => {
        this.setState({ lookups: new LookupResult(result) });
      }
      apiGet(apiUrl, this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
    }
  }

  render() {
    const { lookups } = this.state;
    const { editing, messageBoxGlobal, ownership, ownershipActions, isWell } = this.props;

    const onEdit = (id, value, item) => {
      switch (id) {
        // R/O case IDS.BLOCK_NO: item.blockNo = value; break;
        case IDS.CORPORATE_ID:
          item.corporateId = Number(value);
          item.corporateName = lookups.Corporates.find(obj => obj.key === item.corporateId).value;
          break;
        case IDS.EQUITY: item.equity = value; break;
        case IDS.IS_OPERATOR: item.isOperator = value; break;
        case IDS.IS_CORE: item.isCore = value; break;
        case IDS.IS_ASSUMED: item.isAssumed = value; break;
        case IDS.COMMERCIAL_COMMENT: item.commercialComment = value; break;
        case IDS.ADMIN_COMMENT: item.adminComment = value; break;
        case IDS.IS_OPERATOR_ONLY: item.operatorOnly = value; break;
        case IDS.TIER_ID:
          item.tierId = Number(value);
          item.tierName = lookups.Tiers.find(obj => obj.key === item.tierId).value;
          break;
        default: throw new Error('Id ' + id + ' is not supported in OwnershipComponent.onEdit');
      }
      ownershipActions.onEdit(item);
    };

    const getTitle = (item) => {
      const value = <React.Fragment>{item.corporateName}<span style={{ float: 'right' }}>({item.equity}%)</span></React.Fragment>;
      
      return item.status === RECORD_STATUS.added && (value === undefined || value === null || value === '')
        ? '-New Ownership-'
        : value;
    };

    const items = ownership.map(item => {
      let setRows =
        [
          { label: 'Corporate Name', values: [new PropertyGridSelectValue(IDS.CORPORATE_ID, item.corporateId, (value, id) => onEdit(id, value, item), 'key', 'value', lookups === null ? [{ key: item.corporateId, value: item.corporateName }] : lookups.Corporates)] },
          { label: 'Equity', values: [new PropertyGridNumberValue(IDS.EQUITY, INPUTTYPES.NUMBER, item.equity, (value, id) => onEdit(id, value, item), 5)] },
          { label: 'Is Operator', values: [new PropertyGridBooleanValue(IDS.IS_OPERATOR, item.isOperator, (value, id) => onEdit(id, value, item))] }
        ];

      if (isWell) {
        setRows.push({ label: 'Is Operator Only', values: [new PropertyGridBooleanValue(IDS.IS_OPERATOR_ONLY, item.operatorOnly, (value, id) => onEdit(id, value, item))] });
        setRows.push({ label: 'Tier', values: [new PropertyGridSelectValue(IDS.TIER_ID, item.tierId, (value, id) => onEdit(id, value, item), 'key', 'value', lookups === null ? [{ key: item.tierId, value: item.tier_name }] : lookups.Tiers)] });
      }
      else {
        setRows.push({ label: 'Is Core', values: [new PropertyGridBooleanValue(IDS.IS_CORE, item.isCore, (value, id) => onEdit(id, value, item))] });
        setRows.push({ label: 'Is Assumed', values: [new PropertyGridBooleanValue(IDS.IS_ASSUMED, item.isAssumed, (value, id) => onEdit(id, value, item))] });
        setRows.push({ label: 'Commercial Comment', values: [new PropertyGridStringValue(IDS.COMMERCIAL_COMMENT, item.commercialComment, (value, id) => onEdit(id, value, item))] });
        setRows.push({ label: 'Admin Comment', values: [new PropertyGridStringValue(IDS.ADMIN_COMMENT, item.adminComment, (value, id) => onEdit(id, value, item))] });
      }
      return {
        label: getTitle(item),
        data: item,
        sections: [{
          label: 'Ownership',
          rows: setRows
        }]
      }
    });

    const add = () => {
      let newItem = new Ownership(null);
      newItem.getNew();

      ownershipActions.onAdd(newItem);
    }

    return (
      <AttributeStaticListComponent
        editing={editing}
        messageBoxGlobal={messageBoxGlobal}
        items={items}
        itemDescription="participant"
        canAdd
        canDelete
        onAdd={add}
        onDelete={ownershipActions.onDelete}
        onSelected={ownershipActions.onSelected}
        onAddPosition={"start"}
      />
    );
  }
};

OwnershipComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  ownership: PropTypes.arrayOf(PropTypes.instanceOf(Ownership)).isRequired,
  ownershipActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  isWell: PropTypes.bool.isRequired,
};

OwnershipComponent.defaultProps = {
  isWell: false
};

export default OwnershipComponent;
