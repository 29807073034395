import React from 'react';
import PropTypes from 'prop-types';

import { HubAnnual } from '../../models/hub-annual';
import { valueTypes } from '../../../shared/helpers/value-handler';

import AGGridComponent, {
  COLUMN_SMALL,
  COLUMN_MEDIUM,
  COLUMN_MEDIUM_MEDIUM,
  COLUMN_LARGE,
  COLUMN_WIDE,
  GeneralFormatter,
  DoubleFormatter,
  ReadOnlyNumberFormatter,
  ReadOnlyDoubleFormatter,
  numberParser,
  GRIDTYPES
} from './ag-grid-component';
import { MessageBoxGlobal } from '../../../common/globals/globals';

const OpexTypes = [
  { id: 1, title: 'Calculated' },
  { id: 2, title: 'Manual' },
  { id: 3, title: 'Percent' }
];
Object.freeze(OpexTypes);

const HubAnnualsGridComponent = (props) => {
  const { hubAnnuals, editing, readOnly, rowAdded, top, startYear, endYear, editYear, enableDeclineCurve, handleCalculatedValues } = props;

  const setCellClass = (params) => {
    return (params.data.isAutoGenerated) ? 'ag-grey-out-class' : 'ag-normal-class';
  }

  const columnDefs = [];
  columnDefs.push({ headerName: "Year", suppressHeaderMenuButton: true, field: "year", sortable: false, filter: 'agNumberColumnFilter', filterParams: { newRowsAction: 'keep' }, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyNumberFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Fields", suppressHeaderMenuButton: true, field: "fields", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyNumberFormatter, type: 'text' });
  columnDefs.push({ headerName: "Liquid (" + valueTypes.UnitString(valueTypes.mboed) + ")", suppressHeaderMenuButton: true, field: "liquidMbod", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Gas (" + valueTypes.UnitString(valueTypes.mmscfd) + ")", suppressHeaderMenuButton: true, field: "gasMmscfd", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Capex (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "capexUSDMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "OPEX Charge(" + valueTypes.UnitString(valueTypes.usdMM) + ")", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "opexChargeUsdMM", sortable: true, filter: false, editable: editing, width: COLUMN_MEDIUM, cellRenderer: readOnly ? ReadOnlyDoubleFormatter : DoubleFormatter, valueParser: numberParser, type: 'numeric' });/*field: "opexChargeUsdMM", , valueGetter: Number("opexChargeUsdMM")*/
  columnDefs.push({ headerName: "Hub and Field Specific OPEX Receipts (" + valueTypes.UnitString(valueTypes.usdMM) + ")", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "totalOpexReceiptsUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM_MEDIUM, cellRenderer: readOnly ? ReadOnlyDoubleFormatter : DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Total Tarriff Receipts (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "totalTariffUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "ETS Charge (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "etsChargeUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Carbon Charge (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "carbonChargeUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Total Emissions Charge (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "totalEmissionsChargeUSDMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Abex (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "abexUSDMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Revenue (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "revenueUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Pre Tax Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "preTaxCashFlowUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Cumulative Pre Tax Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "cumulativePreTaxCashflowUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM_MEDIUM, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Corporate Tax (" + valueTypes.UnitString(valueTypes.usdMM) + ")", field: "corporateTaxUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Special Tax (" + valueTypes.UnitString(valueTypes.usdMM) + ")", field: "specialTaxUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Undiscounted Post Tax Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", field: "undiscountedPostTaxCashflowUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM_MEDIUM, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Cumulative Undiscounted Post Tax Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", field: "cumulativeUndiscountedPostTaxCashflowUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_LARGE, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Discounted Post Tax Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", field: "discountedPostTaxCashflowUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM_MEDIUM, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Cumulative Discounted Post Tax Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", field: "cumulativeDiscountedPostTaxCashflowUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_LARGE, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Comment", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "comment", sortable: true, filter: false, editable: editing, width: COLUMN_WIDE, cellRenderer: GeneralFormatter, type: 'text' });
  columnDefs.push({ headerName: "falseColumn", hide: true, suppressColumnsToolPanel: true, suppressHeaderMenuButton: true, field: "falseColumn", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {}
  };

  const numColumns = ["opexChargeUsdMM", "opexReceiptsUsdMM"];
  const numPositiveColumns = ["noneyet"];
  const numPercentColumns = ["noneyet"];
  const selectColumns = ["noneyet"];

  return (
    <AGGridComponent
      messageBoxGlobal={props.messageBoxGlobal}
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      gridData={hubAnnuals}
      rowsCount={hubAnnuals.length}
      editing={editing}
      rowAdded={rowAdded}
      top={top}
      allowPaste
      id={props.id}
      numColumns={numColumns}
      numPercentColumns={numPercentColumns}
      numPositiveColumns={numPositiveColumns}
      selectColumns={selectColumns}
      canButtonsEnable={props.canButtonsEnable}
      startYear={startYear}
      endYear={endYear}
      gridType={GRIDTYPES.HUB}
      editYear={editYear}
      enableDeclineCurve={enableDeclineCurve}
      handleCalculatedValues={handleCalculatedValues}
      activeComponent={props.activeComponent}
    />
  );
};

HubAnnualsGridComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  editing: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  hubAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(HubAnnual)).isRequired,
  id: PropTypes.number,
  canButtonsEnable: PropTypes.func.isRequired,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  handleCalculatedValues: PropTypes.func.isRequired
};

HubAnnualsGridComponent.defaultProps = {
  readOnly: false,
  handleCalculatedValues: () => { }
};

export default HubAnnualsGridComponent;
export { HubAnnual };
