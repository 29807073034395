import { Views } from '../globals/globals';
import { GMATRIX_ENTITY } from './gmatrix-entity';

class RelatedItem {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to RelatedItem.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to RelatedItem.ctor'); }
      if (typeof obj.viewKey !== 'number') { throw new Error('Invalid obj.viewKey param supplied to RelatedItem.ctor'); }
      if (typeof obj.displayName !== 'string') { throw new Error('Invalid obj.displayName param supplied to RelatedItem.ctor'); }
      if (typeof obj.view !== 'string') { throw new Error('Invalid obj.view param supplied to RelatedItem.ctor'); }
      if (obj.items !== null && !Array.isArray(obj.items))
      {
        throw new Error('Invalid obj.items param supplied to RelatedItem.ctor');
      }
    }

    var view = GMATRIX_ENTITY.findView(obj.id);
    if (view !== null && view !== undefined) {
      this.id = obj === null ? -1 : obj.id;
      this.view = obj === null ? '' : obj.view;
      this.displayName = obj === null ? '' : obj.displayName;
      this.viewKey = obj === null ? -1 : obj.viewKey;
      this.items = obj === undefined || obj.items === null ? [new Item(null)] : obj.items.map(i => new Item(i));
    }
  }
}

class Item {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid obj param supplied to Item.ctor');
    }

    this.id = obj === null ? -1 : obj.id;
    this.name = obj === null ? '' : obj.name;
  }
}

class AddRelatedItem {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to AddRelatedItem.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to AddRelatedItem.ctor'); }
      if (typeof obj.view !== 'string') { throw new Error('Invalid obj.view param supplied to AddRelatedItem.ctor'); }
      if (typeof obj.canAdd !== 'boolean') { throw new Error('Invalid obj.canAdd param supplied to AddRelatedItem.ctor'); }
    }

    if (Views.values().includes(obj.id)) {
      this.id = obj === null ? 0 : obj.id;
      this.view = obj === null ? '' : obj.view;
      this.canAdd = obj === null ? false : obj.canAdd;
    }
  }
}

export { RelatedItem, AddRelatedItem };
