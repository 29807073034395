import React from 'react';
import PropTypes from 'prop-types';

import { WestwoodDataDPFField } from '../../models/westwood-data-dpf-field';
import PropertyGridComponent, { PropertyGridStringValue, PropertyGridBooleanValue, PropertyGridNumberValue } from '../../../shared/components/property-grid/property-grid-component';
import { PropertyGridLargeStringValue } from '../../../shared/components/property-grid/property-grid-table-component';

const IDS = {
  ID: 5,
  NAME: 10,
  PROJECT: 15,
  COUNTRY: 20,
  STATUS: 25,
  PRODUCT: 30,
  STRUCTURE: 35,
  TYPE: 40,
  DEVELOPMENT_SUMMARY: 45,
  BLOCK: 50,
};
Object.freeze(IDS);

const getSections = (dpfField) => {
  const result = [
    {
      label: 'Westwood Data DPF Field Information',
      rows: [
        { label: 'Westwood Data Field Id', values: [new PropertyGridStringValue(IDS.ID, dpfField.id)] },
        { label: 'Field', values: [new PropertyGridStringValue(IDS.NAME, dpfField.fieldName)] },
        { label: 'Project', values: [new PropertyGridStringValue(IDS.PROJECT, dpfField.projectName)] },
        { label: 'Country', values: [new PropertyGridStringValue(IDS.COUNTRY, dpfField.country)] },
        { label: 'Status', values: [new PropertyGridStringValue(IDS.STATUS, dpfField.status)] },
        { label: 'Product', values: [new PropertyGridStringValue(IDS.PRODUCT, dpfField.fieldProduct)] },
        { label: 'Development Structure', values: [new PropertyGridStringValue(IDS.STRUCTURE, dpfField.developmentStructure)] },
        { label: 'Development Type', values: [new PropertyGridStringValue(IDS.TYPE, dpfField.developmentType)] },
        { label: 'Development Summary', values: [new PropertyGridLargeStringValue(IDS.DEVELOPMENT_SUMMARY, dpfField.developmentSummary)] },
        { label: 'Block', values: [new PropertyGridStringValue(IDS.BLOCK, dpfField.blockNumber)] },
      ]
    },

  ];

  return result;
};

const WestwoodDataDPFFieldComponent = (props) => {
  const { dpfField } = props;

  const sections = getSections(dpfField);

  return <PropertyGridComponent sections={sections} readOnly={true} />;
};

WestwoodDataDPFFieldComponent.propTypes = {
  dpfField: PropTypes.instanceOf(WestwoodDataDPFField).isRequired,
};

export default WestwoodDataDPFFieldComponent;
