import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import HomeComponent from './home-component';
import { BrowserType, getBrowserType } from '../../shared/helpers/browser-detect';

const LoginComponent = (props) => {
  const content = getBrowserType() === BrowserType.IE
    ? (
      <React.Fragment>
        <h3>This application is not supported for use with Internet Explorer.</h3>
        <h3>Please use Chrome, Edge or Firefox.</h3>
      </React.Fragment>
    )
    : (
      <React.Fragment>
        <h3>Please click the login button to connect using your Microsoft credentials.</h3>
        <br />
        <Button size="lg" onClick={props.login}>Login</Button>
      </React.Fragment>
    );

  return (
    <HomeComponent>
      {content}
    </HomeComponent>
  );
};

LoginComponent.propTypes = {
  login: PropTypes.func.isRequired
}

export default LoginComponent;
