import React from 'react';
//import PropTypes from 'prop-types';

import './variables-view.scss';
import ComingSoonComponent from '../../common/components/coming-soon/coming-soon-component';

const VariablesView = (props) => {
  return (
    <div className="view-panel variables-view">
      <h1>VariablesView</h1>
      <ComingSoonComponent />
    </div>
  );
};

VariablesView.propTypes = {
}

export default VariablesView;