import React from 'react';
import './hub-chart.scss';
import { appendClassNames } from '../../../shared/helpers/common';
import { ResourceAnnual } from '../../models/resource-annual';
import cloneDeep from 'lodash/cloneDeep';
import Functions from './chart-functions';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
);

const HubChart = (props) => {
  const { className, resourceData, hubAnnual, isStacked, chartType, resourceAttributes, hubMinYear, hubMaxYear, xTitle, yTitle } = props;

  //need to add all the zero values in for years that dont exist in the entityData (resourcedata)
  var entityData = cloneDeep(resourceData);

  var resourceIds = resourceAttributes.map((e, i) => ({
    resourceIds: e.resourceId
  }));

  //round to 1 d.p.
  var entityDataLength = entityData.length;
  for (let val = 0; val < entityDataLength; val++) {
    entityData[val].combinedMboed = Functions.round(entityData[val].combinedMboed, 1);
  }

  const getFilteredResourceData = (resourceId) => {
    return resourceData.filter((r) => r.resourceId === resourceId);
  };

  for (let val = 0; val < resourceIds.length; val++) {
    var resId = resourceIds[val].resourceIds;
    const filteredResourceData = getFilteredResourceData(resId);

    if (filteredResourceData.length > 0) {
      for (let val2 = hubMinYear; val2 < filteredResourceData[0].year; val2++) {
        var newResBefore = [new ResourceAnnual()];
        newResBefore.year = val2;
        newResBefore.liquidMbod = 0;
        newResBefore.gasMboed = 0;
        newResBefore.resourceId = resId;
        newResBefore.combinedMboed = 0;
        entityData.push(newResBefore);
      }

      var resCount = filteredResourceData.length;
      var resMaxYear = filteredResourceData[resCount - 1].year;
      for (let val3 = resMaxYear + 1; val3 <= hubMaxYear; val3++) {
        var newResAfter = [new ResourceAnnual()];
        newResAfter.year = val3;
        newResAfter.liquidMbod = 0;
        newResAfter.gasMboed = 0;
        newResAfter.resourceId = resId;
        newResAfter.combinedMboed = 0;
        entityData.push(newResAfter);
      }
    }
  }

  entityData.sort((a, b) => a.year - b.year);

  //use the company colours - 22 of them
  const bgColorWW = ["rgb(27,54,93)", "rgb(168,168,168)", "rgb(163,0,70)", "rgb(18,17,154)", "rgb(82,174,50)", "rgb(243,146,0)", "rgb(149,27,129)", "rgb(0,159,227)", "rgb(141,176,151)", "rgb(214,53,21)", "rgb(0,105,150)", "rgb(86,0,37)", "rgb(142,135,195)", "rgb(40,102,56)", "rgb(209,89,201)", "rgb(7,78,102)", "rgb(251,151,146)", "rgb(44,47,43)", "rgb(42,27,144)", "rgb(4,158,51)", "rgb(183,88,24)", "rgb(45,23,43)"];

  var populateDatasets = resourceAttributes.map((e, i) => ({
    label: e.resourceName,
    backgroundColor: i < 22 ? bgColorWW[i] : bgColorWW[i % 22],
    data: entityData.filter((r) => r.resourceId === e.resourceId && r.status !== 3).map(r => r.combinedMboed),
    pointRadius: 0,
    fill: 'origin',
  }));

  var chartData = {
    labels: hubAnnual.map(h => h.year),
    datasets: populateDatasets
  };

  var chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: isStacked === "true" ? true : false,
        title: {
          text: yTitle,
          display: true
        },
      }
      ,
      x: {
        ticks: {
          minRotation: 45,
          autoSkip: true
        },
        title: {
          text: xTitle,
          display: true
        },
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      },
      filler: {
        propagate: true
      }
    }
  };

  let contentDiv = null;
  if (chartType === "Line") { contentDiv = (<Line data={chartData} options={chartOptions} />); }
  else if (chartType === "Bar") { contentDiv = (<Bar data={chartData} options={chartOptions} />); }

  return (
    <div className={appendClassNames('hub-chart-component', className)}>
      {contentDiv}
    </div>
  );

};

export default HubChart;