import { CircleModel } from '../../shared/helpers/leaflet/models/circle-model';
import { RECORD_STATUS } from './record-status';

class GenericLocation {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to GenericLocation.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to GenericLocation.ctor'); }
      if (typeof obj.entityId !== 'number') { throw new Error('Invalid obj.entityId param supplied to GenericLocation.ctor'); }
      if (typeof obj.dmsLatDegrees !== 'number') { throw new Error('Invalid obj.dmsLatDegrees param supplied to GenericLocation.ctor'); }
      if (typeof obj.dmsLatMinutes !== 'number') { throw new Error('Invalid obj.dmsLatMinutes param supplied to GenericLocation.ctor'); }
      if (typeof obj.dmsLatSeconds !== 'number') { throw new Error('Invalid obj.dmsLatSeconds param supplied to GenericLocation.ctor'); }
      if (typeof obj.dmsNorthing !== 'string') { throw new Error('Invalid obj.dmsNorthing param supplied to GenericLocation.ctor'); }
      if (typeof obj.dmsLongDegrees !== 'number') { throw new Error('Invalid obj.dmsLongDegrees param supplied to GenericLocation.ctor'); }
      if (typeof obj.dmsLongMinutes !== 'number') { throw new Error('Invalid obj.dmsLongMinutes param supplied to GenericLocation.ctor'); }
      if (typeof obj.dmsLongSeconds !== 'number') { throw new Error('Invalid obj.dmsLongSeconds param supplied to GenericLocation.ctor'); }
      if (typeof obj.dmsEasting !== 'string') { throw new Error('Invalid obj.dmsEasting param supplied to GenericLocation.ctor'); }
      if (typeof obj.epsgCode !== 'number') { throw new Error('Invalid obj.epsgCode param supplied to GenericLocation.ctor'); }
      if (typeof obj.decimalLatitude !== 'number') { throw new Error('Invalid obj.decimalLatitude param supplied to GenericLocation.ctor'); }
      if (typeof obj.decimalLongitude !== 'number') { throw new Error('Invalid obj.decimalLongitude param supplied to GenericLocation.ctor'); }
      if (obj.stratigraphicSplit !== null && typeof obj.stratigraphicSplit !== 'boolean') { throw new Error('Invalid obj.stratigraphicSplit param supplied to Attributes.ctor'); }
      if (obj.bottomHole !== undefined && obj.bottomHole !== null && typeof obj.bottomHole !== 'boolean') { throw new Error('Invalid obj.bottomHole param supplied to Attributes.ctor'); }
      if (obj.intendedCountryId !== null && typeof obj.intendedCountryId !== 'number') { throw new Error('Invalid obj.intendedCountryId param supplied to GenericLocation.ctor'); }
      if (obj.intendedCountryName !== null && typeof obj.intendedCountryName !== 'string') { throw new Error('Invalid obj.intendedCountryName param supplied to GenericLocation.ctor'); }
      if (obj.intendedEPSGCode !== undefined && obj.intendedEPSGCode !== null && typeof obj.intendedEPSGCode !== 'number') { throw new Error('Invalid obj.intendedEPSGCode param supplied to GenericLocation.ctor'); }
      if (typeof obj.selected !== 'boolean') { throw new Error('Invalid obj.selected param supplied to GenericLocation.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.entityId = obj === null ? 0 : obj.entityId;
    this.dmsLatDegrees = obj === null ? 0 : obj.dmsLatDegrees;
    this.dmsLatMinutes = obj === null ? 0 : obj.dmsLatMinutes;
    this.dmsLatSeconds = obj === null ? 0 : obj.dmsLatSeconds;
    this.dmsNorthing = obj === null ? '' : obj.dmsNorthing;
    this.dmsLongDegrees = obj === null ? 0 : obj.dmsLongDegrees;
    this.dmsLongMinutes = obj === null ? 0 : obj.dmsLongMinutes;
    this.dmsLongSeconds = obj === null ? 0 : obj.dmsLongSeconds;
    this.dmsEasting = obj === null ? '' : obj.dmsEasting;
    this.epsgCode = obj === null ? 0 : obj.epsgCode;
    this.decimalLatitude = obj === null ? 0 : obj.decimalLatitude;
    this.decimalLongitude = obj === null ? 0 : obj.decimalLongitude;

    this.stratigraphicSplit = obj === null ? false : obj.stratigraphicSplit;
    this.bottomHole = obj === null || obj.bottomHole === undefined ? false : obj.bottomHole;

    this.intendedCountryId = obj === null ? 0 : obj.intendedCountryId;
    this.intendedCountryName = obj === null ? '' : obj.intendedCountryName;
    this.intendedEPSGCode = obj === null || obj.intendedEPSGCode === undefined ? 0 : obj.intendedEPSGCode;

    this.status = obj === null ? '' : obj.status;
    this.selected = obj === null ? false : obj.selected;

    this.point = obj === null || obj.point === null ? null : new CircleModel(obj.point.y, obj.point.x);
  }

  getNew() {
    this.dmsNorthing = 'N';
    this.dmsEasting = 'E';
    this.status = RECORD_STATUS.added;
    this.selected = true;
    this.epsgCode = 0;
    this.intendedEPSGCode = 0;
  }
}

export default GenericLocation;
