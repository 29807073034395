class StringLookup {
  key = '';
  value = '';

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

class StringLookupResult {
  constructor(obj) {
    obj && Object.assign(this, obj);

    if (obj !== undefined) {
      Object.keys(obj).map(key => this[key] = obj[key].map(kvp => new StringLookup(kvp)));
    }
  }
}

export { StringLookup, StringLookupResult };