import { objectToEnum } from "../../common";

const DEFAULT_LAYER_OPACITY = 0.6;

const layerType = objectToEnum({
  Quadrants: 1,
  Blocks: 2,
  Fields: 3,
  EAndAWells: 4,
  Discoveries: 7,
  Hubs: 8,
  Prospects: 9,
  Pipelines: 10,
  WindProjects: 12,
  Clusters: 13,
  HydrogenProjects: 14,
  CarbonCaptureProjects: 15,
  CarbonStorageProjects: 16,
  CombinedProjects: 17,
  CarbonStorageLicenceRounds: 18,
  CarbonStorageLicence: 19
});

class ContextLayerModel {
  constructor(obj) {
    if (typeof obj !== 'object') {
      throw new Error('Invalid "obj" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.contextLayerID !== 'number') {
      throw new Error('Invalid "obj.contextLayerID" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.layerID !== 'number') {
      throw new Error('Invalid "obj.layerID" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.groupDisplayName !== 'string') {
      throw new Error('Invalid "obj.groupDisplayName" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.layerDisplayName !== 'string') {
      throw new Error('Invalid "obj.layerDisplayName" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.geoServerLayerNames !== 'string') {
      throw new Error('Invalid "obj.geoServerLayerNames" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.geoServerCQLFilters !== 'string') {
      throw new Error('Invalid "obj.geoServerCQLFilters" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.geoServerStyles !== 'string') {
      throw new Error('Invalid "obj.geoServerStyles" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.epsgCode !== 'number') {
      throw new Error('Invalid "obj.epsgCode" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.isQueryable !== 'boolean') {
      throw new Error('Invalid "obj.isQueryable" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.isDefaultOn !== 'boolean') {
      throw new Error('Invalid "obj.isDefaultOn" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.layerOrder !== 'number') {
      throw new Error('Invalid "obj.layerOrder" supplied to "ContextLayerModel.ctor"');
    }
    if (layerType.isInvalid(obj.layerType)) {
      throw new Error('Invalid "obj.layerType" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.isShowLabels !== 'boolean') {
      throw new Error('Invalid "obj.isShowLabels" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.minZoom !== 'number') {
      throw new Error('Invalid "obj.minZoom" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.maxZoom !== 'number') {
      throw new Error('Invalid "obj.maxZoom" supplied to "ContextLayerModel.ctor"');
    }
    if (obj.analyticBaseID !== null && typeof obj.analyticBaseID !== 'number') {
      throw new Error('Invalid "obj.analyticBaseID" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.visible !== 'boolean') {
      throw new Error('Invalid "obj.visible" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.noLabelsStyle !== 'string') {
      throw new Error('Invalid "obj.noLabelsStyle" supplied to "ContextLayerModel.ctor"');
    }
    if (typeof obj.labelsVisible !== 'boolean') {
      throw new Error('Invalid "obj.labelsVisible" supplied to "ContextLayerModel.ctor"');
    }

    this.contextLayerId = obj.contextLayerID;
    this.layerId = obj.layerID;
    this.groupDisplayName = obj.groupDisplayName;
    this.layerDisplayName = obj.layerDisplayName;
    this.geoServerLayerNames = obj.geoServerLayerNames;
    this.geoServerCqlFilters = obj.geoServerCQLFilters;
    this.geoServerStyles = obj.geoServerStyles;
    this.epsgCode = obj.epsgCode;
    this.isQueryable = obj.isQueryable;
    this.isDefaultOn = obj.isDefaultOn;
    this.layerOrder = obj.layerOrder;
    this.layerType = obj.layerType;
    this.opacity = typeof obj.opacity !== 'number' ? DEFAULT_LAYER_OPACITY : obj.opacity;
    this.isShowLabels = obj.isShowLabels;
    this.minZoom = obj.minZoom;
    this.maxZoom = obj.maxZoom;
    this.analyticBaseId = obj.analyticBaseID;
    this.visible = obj.visible;
    this.noLabelsStyle = obj.noLabelsStyle;
    this.labelsVisible = obj.labelsVisible;
  }

  destroy() {
    delete this.contextLayerId;
    delete this.layerId;
    delete this.groupDisplayName;
    delete this.layerDisplayName;
    delete this.geoServerLayerNames;
    delete this.geoServerCqlFilters;
    delete this.geoServerStyles;
    delete this.epsgCode;
    delete this.isQueryable;
    delete this.isDefaultOn;
    delete this.layerOrder;
    delete this.layerType;
    delete this.opacity;
    delete this.isShowLabels;
    delete this.minZoom;
    delete this.maxZoom;
    delete this.analyticBaseId;
    delete this.visible;
    delete this.noLabelsStyle;
    delete this.labelsVisible;

    delete this;
  }

  getStyles() {
    let result = this.geoServerStyles;
    if (!this.labelsVisible) {
      result += (' ' + this.noLabelsStyle);
    }
    result = result.trim();
    return result;
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "ContextLayerModel.FromArray"');
    }

    const result = array.map(obj => new ContextLayerModel(obj));
    return result;
  }
}

export { ContextLayerModel, layerType };
