import GenericLocation from '../../models/generic-location';
import { RECORD_STATUS } from '../../../common/models/record-status';

const addLocation = (items, push = false) => {
  if (!Array.isArray(items) || items.filter(obj => !(obj instanceof GenericLocation)).length > 0) {
    throw new Error('Invalid items param supplied to addLocation');
  }

  const ids = items.filter(obj => obj.id < 0).map(obj => obj.id).sort((a, b) => b - a);
  const result = new GenericLocation({
    id: (ids.length > 0 ? ids[0] : 0) - 1,
    entityId: -1,
    dmsLatDegrees: 0,
    dmsLatMinutes: 0,
    dmsLatSeconds: 0,
    dmsLongDegrees: 0,
    dmsLongMinutes: 0,
    dmsLongSeconds: 0,
    epsgCode: 0,
    decimalLatitude: 0,
    decimalLongitude: 0,
    stratigraphicSplit: false,
    bottomHole: false,
    intendedCountryId: 0,
    intendedCountryName: '',
    intendedEPSGCode: 0,
    dmsNorthing: 'N',
    dmsEasting: 'E',
    point: null,
    status: RECORD_STATUS.added,
    selected: true
  });

  if (push === false) {
    items.unshift(result);
  } else {
    items.push(result);
  }

  return result;
};

const deleteLocation = (items, item) => {
  if (!Array.isArray(items) || items.filter(obj => !(obj instanceof GenericLocation)).length > 0) {
    throw new Error('Invalid items param supplied to deleteLocation');
  }
  if (!(item instanceof GenericLocation)) {
    throw new Error('Invalid item param supplied to deleteLocation');
  }

  if (item.status === RECORD_STATUS.added) {
    items.splice(items.indexOf(item), 1)
  } else {
    item.status = RECORD_STATUS.deleted;
  }
};

const editLocation = (item) => {
  if (!(item instanceof GenericLocation)) {
    throw new Error('Invalid item param supplied to editLocation');
  }

  item.status = item.status === RECORD_STATUS.added
    ? item.status
    : RECORD_STATUS.edited;
};

export { addLocation, deleteLocation, editLocation };