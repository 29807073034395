import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ag-grid-component.scss';
import GasFormatter from './formatters/gas-formatter';
import ImageFormatter from './formatters/image-formatter';
import DoubleFormatter from './formatters/double-formatter';
import LiquidFormatter from './formatters/liquid-formatter';
import PercentFormatter from './formatters/percent-formatter';
import GeneralFormatter from './formatters/general-formatter';
import ReadOnlyDoubleFormatter from './formatters/readonly-double-formatter';
import ReadOnlyGeneralFormatter from './formatters/readonly-general-formatter';
import ReadOnlyNumberFormatter from './formatters/readonly-number-formatter';

import { OpexTypes } from '../grid/resource-annuals-grid-component';

import { AgGridReact } from 'ag-grid-react';
import { ACTIVECOMPONENT } from '../../globals/globals';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';

import { MessageBoxGlobal } from '../../../common/globals/globals';
import { MessageBoxButtons } from '../../../shared/components/message-box/message-box-classes';

import cloneDeep from 'lodash/cloneDeep';
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-061087}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Westwood_Global_Energy}_is_granted_a_{Multiple_Applications}_Developer_License_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{2_August_2025}____[v3]_[01]_MTc1NDA4OTIwMDAwMA==40c87bec8089f5fb38c3237cbe3cddcd");

var cellsValid = true;

var currentRow = null;
var currentColumn = null;

var updateValues = [];
var cellClicked;
var columnEditable;
var cellType;

const COLUMN_SMALL = 105;
const COLUMN_MEDIUM = 115;
const COLUMN_MEDIUM_MEDIUM = 140;
const COLUMN_LARGE = 165;
const COLUMN_EXTRA_LARGE = 265;
const COLUMN_WIDE = 415;
const COLUMN_EXTRA_WIDE = 815;
const COLUMN_EXTRA_EXTRA_WIDE = 880;

const GRIDTYPES = {
  RESOURCE: 0,
  HUB: 1,
  NONE: 2,
  SNAPSHOTHISTORY: 3,
  INTEGRITY: 4,
  HUBEMISSIONS: 5,
  HUBEMISSIONSOVERRIDE: 6
};
Object.freeze(GRIDTYPES);

const defaultSort = (rows, sortColumn, sortDirection) => {
  let result = rows;
  if (sortDirection !== "NONE") {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    result = result.sort(comparer);
  }
  return result
};

const handleFilterChange = filter => filters => {
  const newFilters = { ...filters };
  if (filter.filterTerm) {
    newFilters[filter.column.key] = filter;
  } else {
    delete newFilters[filter.column.key];
  }
  return newFilters;
};

function getFilteredValues(rows, columnId) {
  return rows
    .map(r => r[columnId])
    .filter((item, i, a) => {
      return i === a.indexOf(item);
    });
};

const numberParser = (params) => {
  var colType = params.colDef.type;
  var newValue = params.newValue;
  var oldValue = params.oldValue;

  if (allNumberParser(colType, newValue)) {
    cellsValid = true;
    return newValue;
  }
  else {
    cellsValid = false;
    return oldValue;
  }
};

const allNumberParser = (colType, value) => {
  if ((colType === 'numeric' || colType === 'percent') && isNaN(value)) {
    return false;
  }
  else {
    return true;
  }
};

class agGridComponent extends Component {
  constructor(props) {
    super(props);
    let defaultToolPanelOpen = localStorage.getItem('storedColumnToolPanelIsOpen') === "true" ? 'columns' : '';

    let columnsideBar = this.props.gridType === GRIDTYPES.SNAPSHOTHISTORY || this.props.gridType === GRIDTYPES.NONE || this.props.gridType === GRIDTYPES.INTEGRITY || this.props.gridType === GRIDTYPES.HUBEMISSIONSOVERRIDE
      ? null
      : {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: defaultToolPanelOpen,
      };

    this.state = {
      preventScrollToTop: true,
      sideBar: columnsideBar
    };

    this.grid = null;
    this.selectedRow = null;
    this.selectedCol = null;
    this.rangeSelection = null;

    this.onCellSelected = this.onCellSelected.bind(this);

    this.cellValidation = this.cellValidation.bind(this);
    this.pastecellValidation = this.pastecellValidation.bind(this);

    this.showHideToolPanel = this.showHideToolPanel.bind(this);

    this.onInitialGridCellsUpdated = this.onInitialGridCellsUpdated.bind(this);
    this.onProcessDataFromClipboard = this.onProcessDataFromClipboard.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.onCellFocused = this.onCellFocused.bind(this);
    this.onRangeSelectionChanged = this.onRangeSelectionChanged.bind(this);
    this.postProcessPopup = this.postProcessPopup.bind(this);
    this.cellEditingStarted = this.cellEditingStarted.bind(this);
    this.tabToNextCell = this.tabToNextCell.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowDataChanged = this.onRowDataChanged.bind(this);
  }

  filterGridRows() {
    //for list of filter type values - https://www.ag-grid.com/javascript-grid/filter-provided-simple/#simple-filter-options
    const startYear = this.props.startYear;
    const realStartYear = startYear - 1;
    const endYear = this.props.endYear;
    const realendYear = endYear + 1;

    //NB - the range years need to be one less than start year and 1 more than end year so as to include them in the grid
    if (this.grid !== null && (this.grid.api !== undefined || this.gridApi !== undefined)) {
      this.grid.api.setColumnFilterModel('year', {
        filterType: 'number',
        type: 'inRange',
        filter: realStartYear,
        filterTo: realendYear
      });

      this.grid.api.onFilterChanged();
      this.grid.api.redrawRows();
    }
  }

  componentDidMount() {
    this.setCanFilter();
    this.filterGridRows();

    if (this.props.autoResize) {
      var allColumnIds = [];
      if (this.grid !== null && this.grid.api !== undefined) {
        this.grid.api.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });

        if (this.grid !== undefined && (this.props.gridType === GRIDTYPES.INTEGRITY || this.props.gridType === GRIDTYPES.HUBEMISSIONS)) {
          this.grid.api.autoSizeColumns(allColumnIds);
          this.grid.api.sizeColumnsToFit();
        }
        else if (this.grid !== undefined && this.props.gridType !== GRIDTYPES.SNAPSHOTHISTORY && this.props.gridType !== GRIDTYPES.NONE) {
          this.grid.api.autoSizeColumns(allColumnIds);
        }
      }
    }

    if (this.props.gridType === GRIDTYPES.SNAPSHOTHISTORY) {
      if (this.grid !== undefined && this.grid !== null && this.grid.api !== undefined) {
        if (this.props.rowsCount > 0 && this.grid.api.getRowNode(this.props.selectedIndex) !== undefined) this.grid.api.getRowNode(this.props.selectedIndex).setSelected(true, true);
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.filterGridRows();
    if (prevProps !== null && prevProps !== undefined) {

      if (prevProps.editYear !== this.props.editYear) {
        this.grid.api.refreshCells();
      }

      if (prevProps.id !== null && prevProps.id !== undefined && this.props.id !== null && this.props.id !== undefined) {

        if (prevProps.id !== this.props.id) {

          if (this.gridApi !== undefined && this.grid.api.getRowNode(0) !== undefined) {
            this.gridApi.ensureIndexVisible(0, 'top');
          }
        }
      }

      //if (this.gridApi !== undefined && this.props.gridType !== GRIDTYPES.SNAPSHOTHISTORY && this.props.gridType !== GRIDTYPES.NONE) {
      //  this.gridApi.sizeColumnsToFit();
      //}

      //if (this.gridApi !== undefined && this.props.gridType === GRIDTYPES.INTEGRITY) {
      //  //this.grid.api.autoSizeColumns();
      //  this.gridApi.sizeColumnsToFit();
      //}
      //if (this.grid !== undefined && this.props.gridType === GRIDTYPES.INTEGRITY) {
      //  //this.grid.api.autoSizeColumns();
      //  this.grid.api.sizeColumnsToFit();
      //}
    }

    if (this.props.rowAdded) {
      var top = this.props.top;
      var gridCanvas = this.grid.getDataGridDOMNode().querySelector('.react-grid-Canvas');
      gridCanvas.scrollTop = top;
    }
    this.setCanFilter();

    if (this.props.gridType === GRIDTYPES.SNAPSHOTHISTORY) {
      if (this.grid !== undefined && this.grid !== null) {
        if (this.props.rowsCount > 0 && this.grid.api.getRowNode(this.props.selectedIndex) !== undefined) this.grid.api.getRowNode(this.props.selectedIndex).setSelected(true, true);
      }
    }

    if (this.grid !== undefined && (this.props.gridType === GRIDTYPES.INTEGRITY || this.props.gridType === GRIDTYPES.HUBEMISSIONS)) {
      //this.grid.api.autoSizeColumns(allColumnIds);
      if (this.grid.api !== undefined) {
        this.grid.api.sizeColumnsToFit();
      }
    }

  }

  setCanFilter() {
    if (this.grid !== null) {
      this.grid.setState(prevState => {
        if (prevState !== null) {
          return prevState.canFilter === this.props.showFilters ? null : { canFilter: this.props.showFilters }
        }
        else
          return null;
      }
      )
    }
  }

  cellValidation = (updated) => {
    if (this.props.numPositiveColumns.indexOf(Object.keys(updated)[0]) > -1) {
      var posValue = updated[Object.keys(updated)[0]];

      if (isNaN(posValue) || posValue < 0) {
        return false;
      }
    }
    else if (this.props.numColumns.indexOf(Object.keys(updated)[0]) > -1) {
      var value = updated[Object.keys(updated)[0]];

      if (isNaN(value)) {
        return false;
      }
    }
    else if (this.props.numPercentColumns.indexOf(Object.keys(updated)[0]) > -1) {
      var valuePC = updated[Object.keys(updated)[0]];

      if (isNaN(valuePC) || valuePC > 100) {
        return false;
      }
    }

    return true;
  };

  pastecellValidation = (colName, value, cellType) => {
    if (cellType === 'dropdown') {
      //check value exists in dropdown list - need to make a generic function in case of adding more drop down columns
      if (colName === 'opexTypeId') {
        if (isNaN(value)) {
          var exists1 = OpexTypes.find(o => o.title === value);
          if (exists1 === undefined) return false;
          else return true;
        }
        else {
          var exists2 = OpexTypes.find(o => o.id === Number(value));
          if (exists2 === undefined) return false;
          else return true;
        }

      }
    }

    if (this.props.numPositiveColumns === undefined) { return true; }
    if (this.props.numPositiveColumns.indexOf(colName) > -1) {
      var posValue = value;

      if (isNaN(posValue) || posValue < 0) {
        return false;
      }
    }
    else if (this.props.numColumns.indexOf(colName) > -1) {
      var val = value;

      if (isNaN(val)) {
        return false;
      }
    }
    else if (this.props.numPercentColumns.indexOf(colName) > -1) {
      var valuePC = value;

      if (isNaN(valuePC) || valuePC > 100) {
        return false;
      }
    }

    return true;
  };

  onCellSelected = ({ rowIdx, idx }) => {
    if (this.props.editing && this.props.allowPaste) {
      if (this.props.columns[idx].editable) {
        //document.removeEventListener('paste', this.handlePaste);
        //document.addEventListener('paste', this.handlePaste);
      }
    }

    if (this.grid !== null && (this.props.rowsCount - 1) === rowIdx) {
      this.grid.openCellEditor(rowIdx, idx);
    }

    this.selectedRow = rowIdx;
    this.selectedCol = idx;

  };

  onInitialGridCellsUpdated = (event) => {
    var rownum = event.rowIndex;
    if (this.props.gridType !== GRIDTYPES.NONE && this.props.gridType !== GRIDTYPES.HUBEMISSIONSOVERRIDE) {
      var updatedYear = event.data.year;
      rownum = this.props.gridData.findIndex(x => x.year === updatedYear);
    }

    if (!cellsValid) {
      this.props.messageBoxGlobal.showWarning(
        'value entered is not in correct format, please try again!',
        () => { },
        MessageBoxButtons.OK,
        'Invalid Data'
      );
      this.props.gridData[rownum][event.colDef.field] = cellType === 'text' ? event.oldValue.toString() : Number(event.oldValue);
      cellsValid = true;
      return;
    }

    updateValues[0] = event.newValue;
    const year = event.data.year;


    if (event.colDef.type == 'text') {
      this.props.gridData[rownum][event.colDef.field] = event.newValue.toString();
    }
    else {
      this.props.gridData[rownum][event.colDef.field] = cellType === 'text' ? event.newValue.toString() : Number(event.newValue);
    }

    this.props.gridData[rownum]["updated"] = true;

    if (this.props.gridType !== GRIDTYPES.NONE) {
      this.props.handleCalculatedValues(year);
    }

  }

  onProcessDataFromClipboard = (e) => {
    if (this.props.editing && this.props.allowPaste && columnEditable) {
      const pasteData = e.data;

      if (pasteData.length > 0) {
        if (pasteData[0].length > 1 || this.rangeSelection[0].columns.length > 1) {
          this.props.messageBoxGlobal.showWarning(
            'only allowed to paste one column of data, please try again!',
            () => { },
            MessageBoxButtons.OK,
            'Invalid Data'
          );
          return;
        }
        else {
          var columnName = cellClicked;
          var parsed = true;

          var updated = [];

          pasteData.forEach((row) => {
            if (row[0] !== "") {
              var datainsert = {};
              datainsert[columnName] = row;
              updated.push(datainsert);

              if (this.pastecellValidation(columnName, row, cellType) === false) {
                this.props.messageBoxGlobal.showWarning(
                  'values entered are not in correct format, please try again!',
                  () => { },
                  MessageBoxButtons.OK,
                  'Invalid Data'
                );
                parsed = false;
                return;
              }
            }
          });

          if (parsed === true) {
            this.setState({ preventScrollToTop: true });
            var a = 0

            //if we have filtered via the dropdown the this.selectedRow value is not correct for the gridData Array as rows are hidden need to recalculate it
            let actualGridrow = this.selectedRow;

            if (this.props.startYear !== null && this.props.startYear !== undefined && this.props.startYear !== '' && this.props.startYear !== 0 && this.props.startYear > this.props.gridData[0]["year"])
              actualGridrow = this.selectedRow + (this.props.startYear - this.props.gridData[0]["year"]); //if this.props.startyear is a number

            let allowPaste = this.props.gridType === GRIDTYPES.RESOURCE ? this.props.gridData[actualGridrow + a]["isAutoAbex"] === false && this.props.gridData[actualGridrow + a]["isAutoGenerated"] === false : this.props.gridData[actualGridrow + a]["isAutoGenerated"] === false;

            if (updated.length > 1) {
              pasteData.forEach((row) => {
                if (row[0] !== "") {
                  var pasteValue = cellType === 'text' ? pasteValue = row.toString() : pasteValue = Number(row);
                  if (allowPaste) {
                    this.props.gridData[actualGridrow + a][columnName] = pasteValue;
                    this.props.gridData[actualGridrow + a]["updated"] = true;
                  }

                  a += 1;
                }
              });
            }
            else if (updated.length === 1) {
              var rangSelectionSize = this.rangeSelection[0].endRow.rowIndex - this.rangeSelection[0].startRow.rowIndex + 1;

              pasteData.forEach((row) => {
                if (row[0] !== "") {
                  for (var b = 0; b < rangSelectionSize; b++) {
                    var pasteValue = cellType === 'text' ? pasteValue = row.toString() : pasteValue = Number(row);
                    if (allowPaste) {
                      this.props.gridData[actualGridrow + b][columnName] = pasteValue;
                      this.props.gridData[actualGridrow + b]["updated"] = true;
                    }
                  }
                }
              });

            }

            if (this.props.gridType !== GRIDTYPES.NONE) {
              this.props.handleCalculatedValues(this.props.gridData[actualGridrow]['year']);
            }
          }

        }
      }
    }
  }

  onCellClicked(params) {
    if (params.colDef.type === 'text') {
      params.api.stopEditing(true);
      params.api.startEditingCell({ rowIndex: params.node.rowIndex, colKey: params.column });
      params.api.setFocusedCell(params.node.rowIndex, params.column);
    }

    if (params !== null && params !== undefined) {

      if (params.column !== null) {
        if (!params.column.colDef["checkboxSelection"]) {
          currentRow = params.node.rowIndex;
          currentColumn = params.column;

          cellClicked = params.colDef.field;
          columnEditable = params.colDef.editable;
          cellType = params.colDef.type;

          if (cellType !== 'dropdown' && cellType !== 'text' && this.props.singleClickEdit) {
            this.gridApi.setFocusedCell(currentRow, currentColumn);
          }

        }
      }
    }
  }

  onCellFocused(params) {
    if (!params.column.colDef["checkboxSelection"] && params.column.colDef.type !== "dropdown" && this.props.singleClickEdit) {
      this.gridApi.clearRangeSelection();
      params.api.startEditingCell({ rowIndex: params.rowIndex, colKey: params.column });
    }

    if (params.column !== null) {
      cellType = params.column.colDef.type;
      if (!params.column.colDef["checkboxSelection"]) {
        cellClicked = params.column.colDef.field;
        columnEditable = params.column.colDef.editable;
        cellType = params.column.colDef.type;
        this.selectedRow = params.rowIndex;

        currentRow = params.rowIndex;
        currentColumn = params.column;
      }

      if (cellType === 'text') {
      }
    }
  }

  onRangeSelectionChanged(e) {
    //if (this.props.gridType !== GRIDTYPES.HUBEMISSIONSOVERRIDE) {
      this.rangeSelection = this.gridApi.getCellRanges();
    //}

  }

  postProcessPopup(params) {
    if (params.type !== 'columnMenu' || params.column.colDef.filter !== false) {
      return;
    }

    params.ePopup.onkeypress = (e) => {
      if (e.key === 'Enter') {
        const model = this.gridApi.getFilterInstance(params.column.colId);
        const filter = model.getMiniFilter();
        if (filter) {
          model.selectNothing();
          const valueCount = model.getUniqueValueCount();
          for (let i = 0; i < valueCount; i++) {
            const value = model.getUniqueValue(i);
            if ((value || '').toUpperCase().indexOf(filter.toUpperCase()) > -1) {
              model.selectValue(value);
            }
          }
        } else {
          model.selectEverything();
        }
        this.gridApi.onFilterChanged();
      }
    }

  }

  //RG - below enables up and down arrows to move you up and down out of edit cell
  //the commented bit is the beginnings of the lef/right arrow work, not working yet and also need to try to detect where the cursor is in the edit cell
  //call logged with aggrid
  defaultColDef = {
    
    //arrow moves up and down from an edit cell ok but cant get the left and right to work yet
    editable: this.props.editing,
    suppressKeyboardEvent: params => {
      if (this.props.gridType !== GRIDTYPES.HUBEMISSIONSOVERRIDE) {
        if (params.column.colDef.type !== "text") {
          const key = params.event.key;
          const deltaY = key === 'ArrowUp' ? -1 : (key === 'ArrowDown' || key === 'Enter') ? + 1 : null;

          if (key === 'Tab') {
            return false;
          }
          else if (this.props.editing && deltaY && this.props.singleClickEdit) { //gparams.editing
            params.api.setFocusedCell(params.node.rowIndex + deltaY, params.column);
            currentRow = params.node.rowIndex + deltaY;
            currentColumn = params.column;

            return false;
          }
          else if (this.props.editing && deltaY === null) {
            currentRow = params.node.rowIndex;
            currentColumn = params.column;
            return false;
          }

          return false;
        }
      }
    }
  }

  onSaveGridColumnState(e) {
    if (!this.grid.api) return;

    if (this.props.gridType !== GRIDTYPES.NONE) {
      let columnState = this.grid.api?.getColumnState();
      let columnGroupState = this.grid.api?.getColumnGroupState();

      let type = this.props.gridType === GRIDTYPES.RESOURCE ? 'resource' : 'hub';

      localStorage.setItem(type + 'storedcolumnState', JSON.stringify(columnState));
      localStorage.setItem(type + 'storedcolumnGroupState', JSON.stringify(columnGroupState));
    }
  }

  onFirstDataRendered(params) {
    if (!this.grid.api) return;
    if (this.props.gridType !== GRIDTYPES.NONE) {
      let type = this.props.gridType === GRIDTYPES.RESOURCE ? 'resource' : 'hub';

      let columnState = JSON.parse(localStorage.getItem(type + 'storedcolumnState'));
      let columnGroupState = JSON.parse(localStorage.getItem(type + 'storedcolumnGroupState'));

      if (columnState !== null && columnState !== "null") {
        if (this.compareColumnStates(columnState)) {
          this.grid.api.applyColumnState({ state: columnState });
          if (columnGroupState !== null && columnGroupState !== "null") {
            this.grid.api.setColumnGroupState(columnGroupState);
          }
        }
      }

    }

  }

  compareColumnStates(columnState) {
    var childCount = 0;
    var colReturn = false;

    this.props.columnDefs.forEach((row) => {
      var children = row.children;
      childCount += children === null || children === undefined ? 1 : children.length;
    });

    if (columnState.length !== childCount) {
      return false;
    }
    else {
      for (var i = 0; i < columnState.length; i++) {
        var col = columnState[i].colId;
        colReturn = false;

        for (var j = 0; j < this.props.columnDefs.length; j++) {
          var children = this.props.columnDefs[j].children;
          if (children !== null && children !== undefined) {
            for (let index = 0; index < children.length; index++) {
              if (children[index].field.toUpperCase() === col.toUpperCase()) {
                colReturn = true;
              }
            }
          }
          else if (this.props.columnDefs[j].field.toUpperCase() === col.toUpperCase()) {
            colReturn = true;
          }
        }
        if (!colReturn) {
          return false;
        }
      }
    }

    return true;
  };

  showHideToolPanel(e) {
    var showing = this.grid.api.isToolPanelShowing();
    localStorage.setItem('storedColumnToolPanelIsOpen', showing);
  }

  cellEditingStarted(e) {
    const { editYear, gridType, enableDeclineCurve } = this.props;

    if (gridType !== GRIDTYPES.NONE && gridType !== GRIDTYPES.HUBEMISSIONSOVERRIDE) {
      var rownum = e.rowIndex;
      var updatedYear = e.data.year;
      var isAutoAbex = e.data.isAutoAbex;
      var isAutoGenerated = e.data.isAutoGenerated;

      rownum = this.props.gridData.findIndex(x => x.year === updatedYear);

      if ((gridType === GRIDTYPES.HUB && isAutoGenerated) || (gridType === GRIDTYPES.RESOURCE && (isAutoAbex || (enableDeclineCurve && (editYear > 0 && updatedYear > editYear))))) {

        this.props.gridData[rownum][e.colDef.field] = e.value;
        this.grid.api.stopEditing(true);
      }
    }
  }

  tabToNextCell(params) {
    currentRow = params.nextCellPosition.rowIndex;
    currentColumn = params.nextCellPosition.column;

    var result = {
      rowIndex: params.nextCellPosition.rowIndex,
      column: params.nextCellPosition.column
    };

    return result;

  }

  onSelectionChanged() {
    if (this.props.gridType === GRIDTYPES.SNAPSHOTHISTORY) {
      let selectedRows = this.grid.api.getSelectedRows();

      if (selectedRows.length === 1) {
        let id = selectedRows[0].resourceSnapshotId;
        this.props.rowSelectChanged(id);
      }
    }
  }

  onRowDataChanged() {
    if (this.props.gridType === GRIDTYPES.SNAPSHOTHISTORY) {
      if (this.grid !== undefined && this.grid !== null) {
        if (this.grid.api !== undefined) {
          if (this.props.rowsCount > 0 && this.grid.api.getRowNode(this.props.selectedIndex) !== undefined) {
            this.grid.api.getRowNode(this.props.selectedIndex).setSelected(true, true);
            this.grid.api.ensureIndexVisible(0, 'top');
          }
        }
      }
    }
  }

  onGridReady = (params) => {
    //this.api = params.api;
    //this.columnApi = params.columnApi;
    //this.gridApi = params.gridApi;
    //this.gridColumnApi = params.gridApi;
    this.gridApi = params.api;
    this.filterGridRows();
    this.onRowDataChanged();
  }

  render() {
    ////console.log = console.warn = console.error = () => { }; //turn console warnigns off

    var gridData = cloneDeep(this.props.gridData);

    //change empty objects to "" for export/paste to excel
    if (gridData !== null && gridData !== undefined && gridData.length > 0) {
      for (var i = 0; i < gridData.length; i++) {
        for (var j = 0; j < gridData[i].length; j++) {
          if (gridData[i][j] !== null && (typeof gridData[i][j] === 'object') && Object.keys(gridData[i][j]).length === 0) {
            gridData[i][j] = null;
          }
        };
      };
    }

    if (currentRow !== null && currentRow !== undefined && currentColumn !== null && currentColumn !== undefined && this.gridApi) {
      //only do this if we are in the grid already or we have come from an update in the grid!
      if (this.props.activeComponent === ACTIVECOMPONENT.GRID) {
        //this.gridApi.setFocusedCell(currentRow, currentColumn); //CAUSE THE BAD SETSTATE ERROR - DOESNT APPEAR TO DO ANYTHING ANYMORE - IS IT IMPORTANT OR A NICETY? - INVESTIGATE OR WAIT FOR A PROBLEM?
      }
    }

    //if (this.props.gridType === GRIDTYPES.SNAPSHOTHISTORY) {
    //  if (this.grid !== undefined && this.grid !== null) {
    //    if (this.props.rowsCount > 0 && this.grid.api.getRowNode(this.props.selectedIndex) !== undefined) this.grid.api.getRowNode(this.props.selectedIndex).setSelected(true, true);
    //  }
    //}

    return (
      <div className="aggrid-component ag-theme-balham" >
        <AgGridReact
          ref={node => this.grid = node}
          enableRangeSelection
          columnDefs={this.props.columnDefs}
          columnTypes={this.props.columnTypes}
          defaultColDef={this.defaultColDef}
          rowData={gridData}
          headerHeight={this.props.headerRowHeight}
          rowHeight={25}
          onCellValueChanged={this.onInitialGridCellsUpdated}
          onPasteStart={this.onPasteStart}
          onPasteEnd={this.onPasteEnd}
          processDataFromClipboard={this.onProcessDataFromClipboard}
          onCellClicked={this.onCellClicked}
          onCellFocused={this.onCellFocused}
          onCellSelected={this.onCellSelected}
          suppressScrollOnNewData={this.state.preventScrollToTop}
           /*onGridReady={params => this.gridApi = params.api}*/
          onGridReady={this.onGridReady}
          onRangeSelectionChanged={this.onRangeSelectionChanged}
          postProcessPopup={this.postProcessPopup}
          sideBar={this.state.sideBar}
          onFirstDataRendered={this.onFirstDataRendered.bind(this)}
          onColumnVisible={this.onSaveGridColumnState.bind(this)}
          onColumnRowGroupChanged={this.onSaveGridColumnState.bind(this)}
          groupHeaderHeight={this.props.groupHeaderHeight}
          onToolPanelVisibleChanged={this.showHideToolPanel}
          onCellEditingStarted={this.cellEditingStarted}
          //onCellEditingStopped={this.cellEditingStopped}
          tabToNextCell={this.tabToNextCell}
          rowSelection={this.props.rowSelection}
          onSelectionChanged={this.onSelectionChanged}
          suppressRowClickSelection={!this.props.rowSelectionEnabled}
          /*suppressCellSelection={this.props.rowSelectionEnabled}*/
          suppressCellFocus={this.props.rowSelectionEnabled}
          onRowDataChanged={this.onRowDataChanged}
          onRowSelected={this.props.rowSelected}
          stopEditingWhenCellsLoseFocus
          singleClickEdit={this.props.singleClickEdit}
        />
      </div>
    );
  }
};

agGridComponent.propTypes = {
  className: PropTypes.string,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  enableCellSelect: PropTypes.bool,
  columnDefs: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      headerName: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(PropTypes.shape({
        headerName: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
        sortable: PropTypes.bool.isRequired,
        filter: PropTypes.string,
        editable: PropTypes.bool.isRequired,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        cellRenderer: PropTypes.func,
        pinned: PropTypes.string,
        type: PropTypes.string
      })).isRequired
    })).isRequired,
    PropTypes.arrayOf(PropTypes.shape({
      headerName: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      sortable: PropTypes.bool.isRequired,
      filter: PropTypes.string,
      editable: PropTypes.bool.isRequired,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
      cellRenderer: PropTypes.func,
      pinned: PropTypes.string,
      type: PropTypes.string
    })).isRequired
  ]),
  columnTypes: PropTypes.object,
  headerRowHeight: PropTypes.number.isRequired,
  onGridSort: PropTypes.func,
  getCellActions: PropTypes.func,
  rowsCount: PropTypes.number.isRequired,
  allowPaste: PropTypes.bool.isRequired,
  showFilters: PropTypes.bool.isRequired,
  onAddFilter: PropTypes.func,
  onClearFilters: PropTypes.func,
  getValidFilterValues: PropTypes.func,
  id: PropTypes.number.isRequired,
  autoResize: PropTypes.bool.isRequired,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  editYear: PropTypes.number.isRequired,
  enableDeclineCurve: PropTypes.bool.isRequired,
  gridType: PropTypes.number.isRequired,
  activeComponent: PropTypes.number.isRequired,
  rowSelectionEnabled: PropTypes.bool.isRequired,
  rowSelectChanged: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  groupHeaderHeight: PropTypes.number.isRequired,
  rowSelection: PropTypes.string.isRequired,
  rowSelected: PropTypes.func.isRequired
};

agGridComponent.defaultProps = {
  showFilters: false,
  headerRowHeight: 65,
  getCellActions: () => { },
  allowPaste: false,
  id: 1,
  autoResize: false,
  startYear: 0,
  endYear: 2050,
  enableDeclineCurve: true,
  gridType: GRIDTYPES.NONE,
  editYear: 0,
  activeComponent: ACTIVECOMPONENT.GRID,
  rowSelectionEnabled: false,
  rowSelectChanged: () => { },
  selectedIndex: 0,
  groupHeaderHeight: 25,
  rowSelection: "single",
  rowSelected: () => { },
  singleClickEdit: false
};

export default agGridComponent;
export {
  defaultSort,
  handleFilterChange,
  getFilteredValues,
  COLUMN_SMALL,
  COLUMN_MEDIUM,
  COLUMN_MEDIUM_MEDIUM,
  COLUMN_LARGE,
  COLUMN_EXTRA_LARGE,
  COLUMN_WIDE,
  COLUMN_EXTRA_WIDE,
  COLUMN_EXTRA_EXTRA_WIDE,
  GasFormatter,
  ImageFormatter,
  DoubleFormatter,
  LiquidFormatter,
  PercentFormatter,
  GeneralFormatter,
  ReadOnlyDoubleFormatter,
  ReadOnlyGeneralFormatter,
  ReadOnlyNumberFormatter,
  numberParser,
  GRIDTYPES
};
