import React from 'react';
import PropTypes from 'prop-types';

import ResourcePanelComponent from './resource-panel/resource-panel-component';
import HubPanelComponent from './hub-panel/hub-panel-component';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import { LookupResult } from '../../../../common/models/lookup-result';
import { ResourceSnapshotAnnual } from '../../../../common/models/resource-snapshot-annual';

import { ResourceText, ResourceAnnual, ResourceReservesHistory, ResourceProductionAttributes, HubText, HubAnnual, HubComment, HubProductionAttributes, ResourceSnapshotComment, ResourceSnapshotHistory } from '../../models/production-result';
import { GMatrixGenericComment } from '../../../../common/models/generic-comment';

const BottomPanelComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    allResourceAttributes,
    currentResourceAttributes,
    currentResourceAttributeActions,
    currentResourceReservesHistory,
    currentResourceComments,
    currentResourceCommentActions,
    currentResourceProductionComments,
    currentResourceProductionCommentActions,
    currentResourceDescriptions,
    currentResourceDescriptionActions,
    hubAttributes,
    hubEmissionOverrides,
    hubAttributeActions,
    hubOverrideActions,
    selectedEmissionsOverrideRows,
    hubComments,
    hubCommentActions,
    hubDescriptions,
    hubDescriptionActions,
    lookups,
    showResourceGrid,
    onChangeHubDescription,
    onChangeHubSnapshotComment,
    selectedStartYear,
    selectedEndYear,
    currentResourceSnapshotComments,
    currentResourceSnapshotHistory,
    resourceSnapshotAnnuals,
    onGoalSeek,
    handleCalculatedValues,
    productionEmissions,
    emissionsIntensity
  } = props;

  const hubAnnuals = props.hubAnnuals.filter(h => h.year >= selectedStartYear && h.year <= selectedEndYear);
  const allResourceAnnuals = props.allResourceAnnuals.filter(h => h.year >= selectedStartYear && h.year <= selectedEndYear);
  const currentResourceAnnuals = props.currentResourceAnnuals.filter(h => h.year >= selectedStartYear && h.year <= selectedEndYear);

  const hubMinYear = hubAnnuals.length > 0 ? hubAnnuals[0].year : 0;
  const hubMaxYear = hubAnnuals.length > 0 ? hubAnnuals[hubAnnuals.length - 1].year : 0;

  var maxCombinedValue1 = 0;
  var maxCombinedValue2 = 0;
  if (allResourceAnnuals.length > 0) {
    maxCombinedValue1 = allResourceAnnuals.reduce((max, p) => p.combinedMboed > max ? p.combinedMboed : max, allResourceAnnuals[0].combinedMboed);
    maxCombinedValue2 = maxCombinedValue1 / 100; //not sure about using 100 need to test this more to see if need to work a divider number out
    maxCombinedValue2 = Math.round(maxCombinedValue2);
    maxCombinedValue2 = maxCombinedValue2 * 100;

    if (maxCombinedValue2 < maxCombinedValue1) {
      maxCombinedValue2 = maxCombinedValue1;
      maxCombinedValue2 = maxCombinedValue2 / 10;
      maxCombinedValue2 = Math.round(maxCombinedValue2);
      maxCombinedValue2 = maxCombinedValue2 * 10;
    }

    if (maxCombinedValue2 < maxCombinedValue1) {
      maxCombinedValue2 = maxCombinedValue1;
      maxCombinedValue2 = maxCombinedValue2 + 5;
      maxCombinedValue2 = Math.round(maxCombinedValue2);
    }
  }
  return (
    showResourceGrid
      ? (<ResourcePanelComponent
        editing={editing}
        messageBoxGlobal={messageBoxGlobal}
        currentResourceAttributes={currentResourceAttributes}
        currentResourceAttributeActions={currentResourceAttributeActions}
        currentResourceAnnuals={currentResourceAnnuals}
        hubMinYear={hubMinYear}
        hubMaxYear={hubMaxYear}
        maxCombinedValue2={maxCombinedValue2}
        currentResourceComments={currentResourceComments}
        currentResourceCommentActions={currentResourceCommentActions}
        currentResourceProductionComments={currentResourceProductionComments}
        currentResourceProductionCommentActions={currentResourceProductionCommentActions}
        currentResourceDescriptions={currentResourceDescriptions}
        currentResourceDescriptionActions={currentResourceDescriptionActions}
        currentResourceReservesHistory={currentResourceReservesHistory}
        hubAttributes={hubAttributes}
        hubAttributeActions={hubAttributeActions}
        lookups={lookups}
        allResourceAnnuals={allResourceAnnuals}
        hubAnnuals={hubAnnuals}
        allResourceAttributes={allResourceAttributes}
        hubComments={hubComments}
        hubCommentActions={hubCommentActions}
        onChangeHubDescription={onChangeHubDescription}
        onChangeHubSnapshotComment={onChangeHubSnapshotComment}
        startYear={selectedStartYear}
        endYear={selectedEndYear}
        currentResourceSnapshotComments={currentResourceSnapshotComments}
        currentResourceSnapshotHistory={currentResourceSnapshotHistory}
        resourceSnapshotAnnuals={resourceSnapshotAnnuals}
        onGoalSeek={onGoalSeek}
      />)
      : (<HubPanelComponent
        editing={editing}
        messageBoxGlobal={messageBoxGlobal}
        allResourceAttributes={allResourceAttributes}
        allResourceAnnuals={allResourceAnnuals}
        hubAnnuals={hubAnnuals}
        hubAttributes={hubAttributes}
        hubEmissionOverrides={hubEmissionOverrides}
        hubAttributeActions={hubAttributeActions}
        hubOverrideActions={hubOverrideActions}
        selectedEmissionsOverrideRows={selectedEmissionsOverrideRows}
        hubMinYear={hubMinYear}
        hubMaxYear={hubMaxYear}
        hubComments={hubComments}
        hubCommentActions={hubCommentActions}
        hubDescriptions={hubDescriptions}
        hubDescriptionActions={hubDescriptionActions}
        lookups={lookups}
        onChangeHubDescription={onChangeHubDescription}
        onChangeHubSnapshotComment={onChangeHubSnapshotComment}
        handleCalculatedValues={handleCalculatedValues}
        productionEmissions={productionEmissions}
        emissionsIntensity={emissionsIntensity}
      />)
  );
};

BottomPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  showResourceGrid: PropTypes.bool.isRequired,
  allResourceAttributes: PropTypes.arrayOf(PropTypes.instanceOf(ResourceProductionAttributes)).isRequired,
  allResourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  currentResourceAttributes: PropTypes.instanceOf(ResourceProductionAttributes).isRequired,
  currentResourceAttributeActions: PropTypes.shape({ onEdit: PropTypes.func.isRequired }).isRequired,
  currentResourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  currentResourceReservesHistory: PropTypes.arrayOf(PropTypes.instanceOf(ResourceReservesHistory)).isRequired,
  currentResourceComments: PropTypes.arrayOf(PropTypes.instanceOf(GMatrixGenericComment)).isRequired,
  currentResourceCommentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  currentResourceProductionComments: PropTypes.arrayOf(PropTypes.instanceOf(GMatrixGenericComment)).isRequired,
  currentResourceProductionCommentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  currentResourceDescriptions: PropTypes.arrayOf(PropTypes.instanceOf(ResourceText)).isRequired,
  currentResourceDescriptionActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  hubAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(HubAnnual)).isRequired,
  hubAttributes: PropTypes.instanceOf(HubProductionAttributes),
  hubAttributeActions: PropTypes.shape({ onEdit: PropTypes.func.isRequired }).isRequired,
  hubComments: PropTypes.arrayOf(PropTypes.instanceOf(HubComment)).isRequired,
  hubCommentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  hubDescriptions: PropTypes.arrayOf(PropTypes.instanceOf(HubText)).isRequired,
  hubDescriptionActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  currentResourceSnapshotComments: PropTypes.arrayOf(PropTypes.instanceOf(ResourceSnapshotComment)).isRequired,
  currentResourceSnapshotHistory: PropTypes.arrayOf(PropTypes.instanceOf(ResourceSnapshotHistory)).isRequired,
  resourceSnapshotAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceSnapshotAnnual)).isRequired,
  onGoalSeek: PropTypes.func.isRequired,
};

export default BottomPanelComponent;
