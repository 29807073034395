import React from 'react';
import PropTypes from 'prop-types';

import { IssueType } from './issue-type';
import { Issue } from '../../../common/models/issue';
import AGGridComponent, { COLUMN_WIDE } from '../grid/ag-grid-component';
import GeneralFormatter from '../grid/formatters/general-formatter';
import { MessageBoxGlobal, EditingGlobal } from '../../../common/globals/globals';

const IssuesGridComponent = (props) => {
  const { issues } = props;

  const ImageFormatter = (params) => {
    var imageSrc = IssueType.image(params.data.issueTypeId);

    const result = <img style={{ height: '14px', width: '14px', display: 'block', position: 'relative', top: '2px', left: '-7px' }} src={imageSrc} />

    return result;
  };

  const columnDefs = [];
  columnDefs.push({ headerName: '', field: 'issueFile', sortable: false, filter: false, editable: false, width: 25, cellRenderer: ImageFormatter, type: 'image' });
  columnDefs.push({ headerName: 'Name', field: 'name', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, cellRenderer: GeneralFormatter, type: 'text' });
  columnDefs.push({ headerName: 'Description', field: 'description', sortable: true, filter: false, editable: false, flex: 1, cellRenderer: GeneralFormatter, type: 'text' });

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {},
    image: {}
  };

  return (
    <AGGridComponent
      messageBoxGlobal={props.messageBoxGlobal}
      editing={props.editingGlobal}
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      headerRowHeight={25}
      gridData={issues}
      rowsCount={issues.length}
      allowPaste={false}
      editYear={0}
    />
  );
};

IssuesGridComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  editingGlobal: PropTypes.instanceOf(EditingGlobal).isRequired,
  issues: PropTypes.arrayOf(PropTypes.instanceOf(Issue)).isRequired
};

export default IssuesGridComponent;
export { IssueType, Issue };
