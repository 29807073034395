class CountriesGlobal {
  constructor(value, set) {
    if (typeof set !== 'function') {
      throw new Error('Invalid set param supplied to CountriesGlobal.ctor is invalid');
    }

    this._value = value;
    this._set = set;
  }

  get value() { return this._value; };
  get set() { return this._set; };
}

export { CountriesGlobal };