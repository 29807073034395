import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import './text-box-wrapper-component.scss';

import NumberFormatter from '../number-format/number-formatter';

class TextBoxWrapperComponent extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.state = { value: this.props.value, modalVisible: false };
  }

  get modalVisible() { return this.value.modalVisible; }
  set modalVisible(value) { this.setState({ modalVisible: value }); }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  onChange(event) {
    this.setState({ value: event.target.value });
  }

  onBlur(event) {
    if (this.state.value !== this.props.value && typeof this.props.onChange === 'function') {
      this.props.onChange(this.state.value);
    }
  }

  render() {
    const { value, modalVisible } = this.state;
    const newProps = { ...this.props };
    newProps.onChange = this.onChange;
    newProps.onBlur = this.onBlur;
    newProps.value = value;
    newProps.title = value;
    newProps.prefix = "";
    newProps.precision = this.props.precision;

    if (this.props.type !== 'text') {
      return <NumberFormatter {...newProps} thousandSeparator={""} allowNegative={true} numberType={this.props.numberType} />
    }

    return (
      <div className="text-box-wrapper-component">
        <Modal isOpen={modalVisible} centered>
          <ModalHeader toggle={() => { this.modalVisible = false; }}>{this.props.title}</ModalHeader>
          <ModalBody><textarea style={{ width: '100%', minHeight: '100px' }} value={value} onChange={this.onChange} onBlur={this.onBlur} /></ModalBody>
          <ModalFooter><Button size="sm" onClick={() => { this.modalVisible = false; }}>Close</Button></ModalFooter>
        </Modal>
        <input title={value} {...newProps} />
        <button onClick={() => { this.modalVisible = true; }}>...</button>
      </div>
    );
  }
}

TextBoxWrapperComponent.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
  precision: PropTypes.number
};

TextBoxWrapperComponent.defaultPropTypes = {
  precision: 0
}

export default TextBoxWrapperComponent;
