import React from 'react';
import PropTypes from 'prop-types';

import AttributeStaticListComponent from '../attribute-list/attribute-static-list-component';
import { ResourceWell } from '../../models/resource-well';
import { RECORD_STATUS } from '../../models/record-status';
import { Lookup } from '../../models/lookup-result';
import { MessageBoxGlobal } from '../../globals/message-box-global';
import { PropertyGridSelectValue, PropertyGridBooleanValue } from '../../../shared/components/property-grid/property-grid-component';

const IDS = {
  WELL_ID: 0,
  RESOURCE_ID: 10,
  IS_DISCOVERY_WELL: 20,
};
Object.freeze(IDS);

class ResourceWellComponent extends React.Component {
  render() {
    const { editing, messageBoxGlobal, resources, wells, resourceWell, resourceWellActions, label, resourceStatusParentId, fromWell, resourceId, wellId } = this.props;

    const onEdit = (id, value, item) => {
      switch (id) {
        case IDS.WELL_ID:
          item.wellId = Number(value);
          item.wellName = wells.find(obj => obj.key === item.wellId).value;
          item.countryId = this.props.countryId;
          break;
        case IDS.RESOURCE_ID:
          item.resourceId = Number(value);
          item.resourceName = resources.find(obj => obj.key === item.resourceId).value;
          item.countryId = this.props.countryId;
          break;
        case IDS.IS_DISCOVERY_WELL: item.isDiscoveryWell = value; break;
        default: throw new Error('Id ' + id + ' is not supported in ResourceWellComponent.onEdit');
      }
      resourceWellActions.onEdit(item);
    };

    const getTitle = (item) => {
      const value = fromWell ? <React.Fragment>{item.resourceName}</React.Fragment> : <React.Fragment>{item.wellName}</React.Fragment>;
      
      return item.status === RECORD_STATUS.added && (value === undefined || value === null || value === '')
        ? '-New-'
        : value;
    };

    const items = resourceWell.map(item => {
      let setRows = [];
      if (fromWell) setRows.push({ label: 'Resource Name', values: [new PropertyGridSelectValue(IDS.RESOURCE_ID, item.resourceId, (value, id) => onEdit(id, value, item), 'key', 'value', resources === null ? [{ key: item.entityId, value: item.corporateName }] : resources)] });
      else setRows.push({ label: 'Well', values: [new PropertyGridSelectValue(IDS.WELL_ID, item.wellId, (value, id) => onEdit(id, value, item), 'key', 'value', wells === null ? [{ key: item.wellId, value: item.wellName }] : wells)] });

      setRows.push({ label: 'Is Discovery Well', values: [new PropertyGridBooleanValue(IDS.IS_DISCOVERY_WELL, item.isDiscoveryWell, (value, id) => onEdit(id, value, item))] });

      return {
        label: getTitle(item),
        data: item,
        sections: [{
          label: label,
          rows: setRows
        }]
      }
    });

    const add = () => {
      let newItem = new ResourceWell(null);
      newItem.getNew();
      newItem.resourceStatusParentId = resourceStatusParentId;
      newItem.wellId = wellId;
      newItem.resourceId = resourceId;
      resourceWellActions.onAdd(newItem);
    }

    return (
      <AttributeStaticListComponent
        editing={editing}
        messageBoxGlobal={messageBoxGlobal}
        items={items}
        itemDescription="Resource Well"
        canAdd
        canDelete
        onAdd={add}
        onDelete={resourceWellActions.onDelete}
        onSelected={resourceWellActions.onSelected}
        onAddPosition={"start"}
      />
    );
  }
};

ResourceWellComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  resourceStatusParentId: PropTypes.number.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  resources: PropTypes.arrayOf(PropTypes.instanceOf(Lookup)).isRequired,
  resourceWell: PropTypes.arrayOf(PropTypes.instanceOf(ResourceWell)).isRequired,
  resourceWellActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
};

ResourceWellComponent.defaultProps = {
  resourceStatusParentId: -1,
  resources: [],
  wells: []
};

export default ResourceWellComponent;
