import React from 'react';
import PropTypes from 'prop-types';
import { doubleHelper } from './double-helper';

// Custom Formatter component
class LiquidFormatter extends React.Component {
  static propTypes = { 
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };

  static defaultProps = {
    value: null
  };

  render() {
    const renderedVal = this.props.value === null ? '-' : doubleHelper(this.props.value, this.props.decimalPlaces);

    return (
      <div className="liquidColumn">{renderedVal}</div>);
  }
}

LiquidFormatter.propTypes = {
  decimalPlaces: PropTypes.number.isRequired
};

LiquidFormatter.defaultProps = {
  decimalPlaces: 3
};

export default LiquidFormatter;