import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import './attribute-static-list-component.scss';
import { MessageBoxGlobal } from '../../globals/globals';
import ToolbarPanelComponent from '../../../shared/components/toolbar-panel/toolbar-panel-component';
import SplitterPanelComponent from '../../../shared/components/splitter-panel/splitter-panel-component';
import PropertyGridComponent, { SectionPropType } from '../../../shared/components/property-grid/property-grid-component';

const AttributeStaticListComponent = (props) => {
  let { items, itemDescription, canAdd, canDelete, editing, messageBoxGlobal, secondarySize, className } = props;
  if (secondarySize === undefined || secondarySize === null) secondarySize = 300;
  if (className === undefined || className === null) className = "";

  var selectedItem = items.find(x => x.data.selected === true);
  var selectedId = selectedItem === null || selectedItem === undefined ? 0 : selectedItem.data.id;

  if (items !== null && items !== undefined && items.length !== 0) if (selectedId === 0) items[0].data.selected = true;

  const setSelectedIndex = (value) => {
    if (typeof value !== 'number') {
      throw new Error('Invalid value param "' + value + '" supplied to AttributeListComponent.setSelectedIndex');
    }

    props.onSelected(value);
  }

  const onAdd = () => {
    props.onAdd();
  };

  const onDelete = () => {
    const yesCallback = () => {
      props.onDelete(selectedId);
    }
    messageBoxGlobal.showYesNoPrompt('Are you sure you want to delete this ' + itemDescription + '?', yesCallback, 'Delete');
  };

  const toolbarItems = canAdd || canDelete ? [
    <Button title="Add" className="btn-add btn-image no-text first-button" size="sm" disabled={!editing || !canAdd} onClick={onAdd} />,
    <Button title="Delete" className="btn-delete btn-image no-text last-button" size="sm" disabled={!editing || !canDelete || selectedId === 0} onClick={onDelete} />
  ] : [];

  let content = items !== null && items !== undefined && items.length !== 0 ?
    <SplitterPanelComponent vertical percentage={false} primaryIndex={1} secondarySize={secondarySize}>
      <ul className="labels">
        {items.map((item) => (
          <li
            key={item.data.id}
            className={(item.data.selected ? 'selected' : '')}
          >
            <div className={'label-name'} onClick={item.data.selected ? undefined : () => setSelectedIndex(item.data.id)}>{item.label}</div>
          </li>
        ))}
      </ul>
      <PropertyGridComponent readOnly={!editing} sections={items.find(x => x.data.selected === true).sections} />
    </SplitterPanelComponent>
    :
    null

  return (
    <ToolbarPanelComponent className={"attribute-static-list-component " + className} align="left" toolbarColor="secondary" toolbarItems={toolbarItems}>

      {content}

    </ToolbarPanelComponent>
  );
}

AttributeStaticListComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      data: PropTypes.object.isRequired,
      sections: PropTypes.arrayOf(SectionPropType).isRequired
    })
  ).isRequired,
  itemDescription: PropTypes.string.isRequired,
  onAddPosition: PropTypes.oneOf(['start', 'end']).isRequired,
  canAdd: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default AttributeStaticListComponent;
