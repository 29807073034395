import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Label, Input, Button } from 'reactstrap';

const NewEntityComponent = (props) => {
  const { onClose } = props;

  const [entityName, setEntityName] = useState(props.entityName);
  const [errorMessage, setErrorMessage] = useState(null);

  const onChangeEntityName = (event) => {
    setErrorMessage(null);
    setEntityName(event.target.value);
  }

  const nameCheckCallback = (result) => {
    if (result) {
      setErrorMessage('The Entity Name you have entered already exists in the Database!');
    }
    else {
      props.addNewEntity(entityName);
    }
  }

  const validate = () => {
    props.onNameCheck(entityName, nameCheckCallback);
  }

  const submit = async () => {
    validate();
  }

  const setOKDisabled = () => {
    let disabled = false;

    if(entityName.trim() === '') disabled = true;

    return disabled;
  }

  let row1 = 
    <Row className="mt-3 pl-3" >
      <Col xs="4">
        <Label for="cbNtdStartYear">New Entity Name</Label>
      </Col>
      <Col xs="7">
        <Input style={{ 'height': '30px' }} type="textarea" name="text" id="exampleText" value={entityName} onChange={onChangeEntityName} />
      </Col>
    </Row>

  let row2 = errorMessage === null ? null :
    <div className="error-message">
      <span>{errorMessage}</span>
    </div>

  let row3 =
    <div className="buttons">
      <Button className="ok-button" disabled={setOKDisabled()} size="md" onClick={() => submit()}>Ok</Button>
      <Button className="cancel-button" size="md" onClick={() => onClose()}>Cancel</Button>
    </div>


  return (
    <Container className="corporate-edit-component" fluid style={{ paddingLeft: 0, paddingRight: 0, overflowX: "hidden", overflow: "hidden" }}>
      {row1}
      {row2}
      {row3}
    </Container>
  );
};

NewEntityComponent.propTypes = {
  entityName: PropTypes.string.isRequired
};

NewEntityComponent.defaultProps = {
  entityName: ''
};

export default NewEntityComponent;
