import React from 'react';
import PropTypes from 'prop-types';

import ResourceChartsComponent from './resource-charts-component';
import ResourceHistoryComponent from './resource-history-component';
import SnapshotAnnualComparisonChartComponent from './snapshot-annual-comparison-chart-component';
import ResourceAttributesComponent from './resource-attributes-component';
import ResourceHyperbolicControlComponent from './resource-hyperbolic-control-component';
import DateTextGenericComponent from '../../../../../common/components/date-text-generic/date-text-generic-component';
import { MessageBoxGlobal } from '../../../../../common/globals/globals';
import { LookupResult } from '../../../../../common/models/lookup-result';
import { ResourceText, ResourceAnnual, ResourceReservesHistory, ResourceProductionAttributes, ResourceSnapshotComment, ResourceSnapshotHistory } from '../../../models/production-result';
import { ResourceSnapshotAnnual } from '../../../../../common/models/resource-snapshot-annual';
import TabPanelComponent, { Tab } from '../../../../../shared/components/tab-panel/tab-panel-component';
import SplitterPanelComponent from '../../../../../shared/components/splitter-panel/splitter-panel-component';
import CommentsComponent from '../../../../../common/components/comments/comments-component';
import { GMatrixGenericComment } from '../../../../../common/models/generic-comment';

const ResourcePanelComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    currentResourceAttributes,
    currentResourceAttributeActions,
    currentResourceAnnuals,
    hubMinYear,
    hubMaxYear,
    maxCombinedValue2,
    currentResourceReservesHistory,
    currentResourceComments,
    currentResourceCommentActions,
    currentResourceProductionComments,
    currentResourceProductionCommentActions,
    hubAttributes,
    lookups,
    allResourceAnnuals,
    hubAnnuals,
    allResourceAttributes,
    onChangeHubDescription,
    onChangeHubSnapshotComment,
    startYear,
    endYear,
    currentResourceSnapshotComments,
    currentResourceSnapshotHistory,
    resourceSnapshotAnnuals,
    onGoalSeek
  } = props;

  const rightTabs = [
    new Tab('Charts', <ResourceChartsComponent currentResourceAnnuals={currentResourceAnnuals} hubMinYear={hubMinYear} hubMaxYear={hubMaxYear} maxCombinedValue2={maxCombinedValue2} allResourceAnnuals={allResourceAnnuals} hubAnnuals={hubAnnuals} allResourceAttributes={allResourceAttributes} />),
    new Tab('Snapshot v Annual', <SnapshotAnnualComparisonChartComponent currentResourceAnnuals={currentResourceAnnuals} startYear={startYear} endYear={endYear}/>),
    new Tab('Attributes', <ResourceAttributesComponent editing={editing} messageBoxGlobal={messageBoxGlobal} lookups={lookups} currentResourceAttributes={currentResourceAttributes} currentResourceAttributeActions={currentResourceAttributeActions} />),
    new Tab('Resource Comments', <CommentsComponent editing={editing} messageBoxGlobal={messageBoxGlobal} comments={currentResourceComments} commentActions={currentResourceCommentActions} />),
    new Tab('Production Comments', <CommentsComponent editing={editing} messageBoxGlobal={messageBoxGlobal} comments={currentResourceProductionComments} commentActions={currentResourceProductionCommentActions} />),
    new Tab('Hub Client Description', <DateTextGenericComponent key="1" editing={editing} commentText={hubAttributes.clientChangeDescription} commentDate={hubAttributes.clientChangeDescriptionDate} onChange={onChangeHubDescription} />),  
    new Tab('Hub Snapshot Comment', <DateTextGenericComponent key="2" editing={editing} commentText={hubAttributes.snapshopComment} commentDate={hubAttributes.snapshotCommentDate} onChange={onChangeHubSnapshotComment} />) ,
    new Tab('Resource History', <ResourceHistoryComponent reservesHistory={currentResourceReservesHistory} messageBoxGlobal={messageBoxGlobal} currentResourceSnapshotComments={currentResourceSnapshotComments} currentResourceSnapshotHistory={currentResourceSnapshotHistory} resourceSnapshotAnnuals={resourceSnapshotAnnuals} resourceId={currentResourceAttributes.resourceId} />)
  ];

  const leftTabs = [
    new Tab('Hyperbolic Controls', <ResourceHyperbolicControlComponent editing={editing} messageBoxGlobal={messageBoxGlobal} resourceAttributes={currentResourceAttributes} resourceAttributesActions={currentResourceAttributeActions} resourceAnnuals={currentResourceAnnuals} onGoalSeek={onGoalSeek}/>) 
  ];

  return (
    <SplitterPanelComponent vertical percentage secondarySize={60}>
      <TabPanelComponent align="top" toolbarItems={leftTabs} selectedItemIndex={0} />
      <TabPanelComponent align="top" toolbarItems={rightTabs} selectedItemIndex={0} />   
    </SplitterPanelComponent>
  );

};

ResourcePanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  currentResourceAttributes: PropTypes.instanceOf(ResourceProductionAttributes).isRequired,
  currentResourceAttributeActions: PropTypes.shape({ onEdit: PropTypes.func.isRequired }).isRequired,
  currentResourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  hubMinYear: PropTypes.number.isRequired,
  hubMaxYear: PropTypes.number.isRequired,
  maxCombinedValue2: PropTypes.number.isRequired,
  currentResourceReservesHistory: PropTypes.arrayOf(PropTypes.instanceOf(ResourceReservesHistory)).isRequired,
  currentResourceComments: PropTypes.arrayOf(PropTypes.instanceOf(GMatrixGenericComment)).isRequired,
  currentResourceCommentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  currentResourceProductionComments: PropTypes.arrayOf(PropTypes.instanceOf(GMatrixGenericComment)).isRequired,
  currentResourceProductionCommentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  currentResourceDescriptions: PropTypes.arrayOf(PropTypes.instanceOf(ResourceText)).isRequired,
  currentResourceDescriptionActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  currentResourceSnapshotComments: PropTypes.arrayOf(PropTypes.instanceOf(ResourceSnapshotComment)).isRequired,
  currentResourceSnapshotHistory: PropTypes.arrayOf(PropTypes.instanceOf(ResourceSnapshotHistory)).isRequired,
  //resourceSnapshotAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceSnapshotAnnual)).isRequired,
  onGoalSeek: PropTypes.func.isRequired,
};

export default ResourcePanelComponent;
