class KeyValue {
  constructor(obj) {
    if (obj !== null) {
      if (typeof obj !== 'object') {
        throw new Error('Invalid "obj" param supplied to "KeyValue.ctor"');
      }
      if (typeof obj.key !== 'number') {
        throw new Error('Invalid "obj.key" param supplied to "KeyValue.ctor"');
      }
      if (typeof obj.value !== 'string') {
        throw new Error('Invalid "obj.value" param supplied to "KeyValue.ctor"');
      }
    }

    this.key = obj === null ? -1 : obj.key;
    this.value = obj === null ? '' : obj.value;
  }
}

//class KeyValues {
//  constructor(obj) {
//    obj && Object.assign(this, obj);

//    if (obj !== undefined) {
//      Object.keys(obj).map(key => this[key] = obj[key].map(kvp => new KeyValue(kvp)));
//    }
//  }
//}

class KeyValues {
  constructor(obj) {
    if (obj !== null) {
      if (typeof obj !== 'object') {
        throw new Error('Invalid "obj" param supplied to "KeyValues.ctor"');
      }
      if (typeof obj.key !== 'number') {
        throw new Error('Invalid "obj.key" param supplied to "KeyValues.ctor"');
      }
      if (!Array.isArray(obj.value)) {
        throw new Error('Invalid "obj.value" param supplied to "KeyValues.ctor"');
      }
    }

    this.key = obj === null ? -1 : obj.key;
    this.value = obj === null ? '' : obj.value.map(x => new KeyValue(x));

  }
}

class NewPartBlockDataResult {
  constructor(obj) {
    if (obj !== null) {
      if (typeof obj !== 'object') {
        throw new Error('Invalid "obj" param supplied to "NewPartBlockDataResult.ctor"');
      }
      if (!Array.isArray(obj.countries)) {
        throw new Error('Invalid "obj.countries" param supplied to "NewPartBlockDataResult.ctor"');
      }
      if (typeof obj.parentBlocks !== 'object') {
        throw new Error('Invalid "obj.parentBlocks" param supplied to "NewPartBlockDataResult.ctor"');
      }
    }

    this.countries = obj === null ? null : obj.countries.map(x => new KeyValue(x));
    this.parentBlocks = obj === null ? null : obj.parentBlocks.map(x => new KeyValues(x));
  }
}

export { NewPartBlockDataResult, KeyValue, KeyValues };