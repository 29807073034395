import React from 'react';
import PropTypes from 'prop-types';

import { ResourceSnapshotAnnual } from '../../../../../../common/models/resource-snapshot-annual';
import { MessageBoxGlobal } from '../../../../../../common/globals/globals';
import AGGridComponent, { COLUMN_SMALL, COLUMN_MEDIUM, LiquidFormatter, GasFormatter, ReadOnlyGeneralFormatter, DoubleFormatter, GRIDTYPES } from '../../../../../../common/components/grid/ag-grid-component';

const SnapshotAnnualComponent = (props) => {
  const { resourceSnapshotAnnuals, messageBoxGlobal, resourceSnapshotId } = props;
  const columnDefs = [];

  columnDefs.push({ headerName: 'Year', suppressHeaderMenuButton: true, field: 'year', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyGeneralFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Liquid (mbod)', suppressHeaderMenuButton: true, field: 'liquidMbod', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: LiquidFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Gas (mmscfd)', suppressHeaderMenuButton: true, field: 'gasMmscfd', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: GasFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Capex ($mm)', suppressHeaderMenuButton: true, field: 'capexUSDMM', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Actual OPEX ($mm)', suppressHeaderMenuButton: true, field: 'opexActualUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Total Tariff ($mm)', suppressHeaderMenuButton: true, field: 'totalTariffUSDMM', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Abex ($mm)', suppressHeaderMenuButton: true, field: 'abexUSDMM', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Revenue ($mm)', suppressHeaderMenuButton: true, field: 'revenueUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Pre Tax Cashflow ($mm)', suppressHeaderMenuButton: true, field: 'preTaxCashFlowUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Cumulative Operating Cashflow ($mm)', suppressHeaderMenuButton: true, field: 'cumulativeOperatingCashFlowUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, cellRenderer: DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Corporate Tax ($mm)', suppressHeaderMenuButton: true, field: 'corparateTaxUSDMM', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Special Tax ($mm)', suppressHeaderMenuButton: true, field: 'specialTaxUSDMM', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Post Tax Discounted Cashflow ($mm)', suppressHeaderMenuButton: true, field: 'postTaxDiscountedCashflowUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, cellRenderer: DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Cumulative Discounted Cashflow ($mm)', suppressHeaderMenuButton: true, field: 'cumulativeDiscountedCashflowUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, cellRenderer: DoubleFormatter, type: 'numeric' });

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {}
  };

  return (
    <AGGridComponent
      messageBoxGlobal={messageBoxGlobal}
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      gridData={resourceSnapshotAnnuals}
      rowsCount={resourceSnapshotAnnuals.length}
      editing={false}
      allowPaste={false}
      gridType={GRIDTYPES.NONE}
      resourceSnapshotId={resourceSnapshotId}
    />
  );

};

SnapshotAnnualComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  resourceSnapshotAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceSnapshotAnnual)).isRequired
};

export default SnapshotAnnualComponent;
