import { Views } from '../globals/globals';

const gmatrixEntity = {
  Production: 10,
  Resource: 20,
  Hub: 30,
  Block: 50,
  Well: 60,
  Corporate: 80,
  Licence: 90,
  Relinquishment: 100,
  LicenceRound: 110,
  WellProgramme: 120,
  Rig: 130
};
Object.freeze(gmatrixEntity);

const GMATRIX_ENTITY = {
  ...gmatrixEntity,
  findView: (entity) => {
    switch (entity) {
      case GMATRIX_ENTITY.Production: return Views.Production;
      case GMATRIX_ENTITY.Resource: return Views.Resource;
      case GMATRIX_ENTITY.Hub: return Views.Hub;
      case GMATRIX_ENTITY.Block: return Views.Block;
      case GMATRIX_ENTITY.Well: return Views.Well;
      case GMATRIX_ENTITY.Corporate: return Views.Corporate;
      case GMATRIX_ENTITY.Licence: return Views.License;
      case GMATRIX_ENTITY.Relinquishment: return Views.Relinquishment;
      case GMATRIX_ENTITY.LicenceRound: return Views.LicenceRound;
      case GMATRIX_ENTITY.WellProgramme: return Views.WellProgramme;
      case GMATRIX_ENTITY.Rig: return Views.Rig;
    }
  }
}

export { GMATRIX_ENTITY };
