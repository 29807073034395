const invalidateResourceAnnualValues = (currentProductionData) => {
  currentProductionData.resourceAnnuals.filter(r => r.hasSnapshot === true && r.hasResourceAnnual === false).forEach((res) => {
    res['liquidMbod'] = null;
    res['opexActualUsdMM'] = null;
    res['gasMmscfd'] = null;
    res['hubProductionPercent'] = null;
    res['capexUSDMM'] = null;
    res['opexCostSharePercent'] = null;
    res['opexCalculatedUsdMM'] = null;
    res['opexActualUsdMM'] = null;
    res['opexManualUsdMM'] = null;
    res['liquidTariffUSDBOE'] = null;
    res['gasTariffUSDBOE'] = null;
    res['totalTariffUSDMM'] = null;
    res['abexUSDMM'] = null;
    res['revenueUsdMM'] = null;
    res['operatingCashFlowUsdMM'] = null;
    res['preTaxCashFlowUsdMM'] = null;
    res['cumulativePreTaxCashflowUsdMM'] = null;
    res['corparateTaxUsdMM'] = null;
    res['specialTaxUsdMM'] = null;
    res['undiscountedPostTaxCashFlowUsdMM'] = null;
    res['cumulativeUndiscountedPostTaxCashFlowUsdMM'] = null;
    res['discountedPostTaxCashFlowUsdMM'] = null;
    res['cumulativeDiscountedPostTaxCashFlowUsdMM'] = null;
    res['liquidDeclinePercent'] = null;
    res['gasDeclinePercent'] = null;
    res['priceDiscountPercent'] = null;
    res['abexUSDMM'] = null;
    res['combinedMboed'] = null;
    res['gasMboed'] = null;
    res['liquidPriceUSDBOE'] = null;
    res['gasPriceUSDMCF'] = null;
    res['fixedOpexUsdMm'] = null;
    res['cumulativeOperatingCashFlowUsdMM'] = null;
    res['cumulativeLiquidMMB'] = null;
    res['cumulativeGasBCF'] = null;
  });

  return currentProductionData;
}

const invalidateValues = (currentProductionData, editedYear, resouceID) => {
  const editYear = editedYear;

  currentProductionData.resourceAnnuals.filter(r => r.resourceId === resouceID && r.year >= editYear).forEach((res) => {
    res['opexActualUsdMM'] = null;
    res['totalTariffUSDMM'] = null;
    res['revenueUsdMM'] = null;
    res['preTaxCashFlowUsdMM'] = null;
    res['cumulativePreTaxCashflowUsdMM'] = null;
    res['corparateTaxUsdMM'] = null;
    res['specialTaxUsdMM'] = null;
    res['discountedPostTaxCashFlowUsdMM'] = null;
    res['cumulativeOperatingCashFlowUsdMM'] = null;
    res['undiscountedPostTaxCashFlowUsdMM'] = null;
    res['cumulativeDiscountedPostTaxCashFlowUsdMM'] = null;
  });

  currentProductionData.hubAnnuals.filter(h => h.year >= editYear).forEach((res) => {
    res['fields'] = null;
    res['liquidMbod'] = null;
    res['gasMmscfd'] = null;
    res['capexUSDMM'] = null;
    res['totalOpexReceiptsUsdMM'] = null;
    res['totalTariffUsdMM'] = null;
    res['abexUSDMM'] = null;
    res['revenueUsdMM'] = null;
    res['preTaxCashFlowUsdMM'] = null;
    res['cumulativePreTaxCashflowUsdMM'] = null;
  });

  return currentProductionData;
}

const resetHubAnnuals = (hubAnnuals) => {
  hubAnnuals.forEach((res) => {
    if (res['fields'] === null) res['fields'] = 0;
    if (res['liquidMbod'] === null) res['liquidMbod'] = 0;
    if (res['gasMmscfd'] === null) res['gasMmscfd'] = 0;
    if (res['capexUSDMM'] === null) res['capexUSDMM'] = 0;
    if (res['opexChargeUsdMM'] === null) res['opexChargeUsdMM'] = 0;
    if (res['totalOpexReceiptsUsdMM'] === null) res['totalOpexReceiptsUsdMM'] = 0;
    if (res['totalTariffUsdMM'] === null) res['totalTariffUsdMM'] = 0;
    if (res['abexUSDMM'] === null) res['abexUSDMM'] = 0;
    if (res['revenueUsdMM'] === null) res['revenueUsdMM'] = 0;
    if (res['operatingCashFlowUsdMM'] === null) res['operatingCashFlowUsdMM'] = 0;
    if (res['preTaxCashFlowUsdMM'] === null) res['preTaxCashFlowUsdMM'] = 0;
    if (res['cumulativePreTaxCashflowUsdMM'] === null) res['cumulativePreTaxCashflowUsdMM'] = 0;
    if (res['corporateTaxUsdMM'] === null) res['corporateTaxUsdMM'] = 0;
    if (res['specialTaxUsdMM'] === null) res['specialTaxUsdMM'] = 0;
    if (res['undiscountedPostTaxCashflowUsdMM'] === null) res['undiscountedPostTaxCashflowUsdMM'] = 0;
    if (res['cumulativeUndiscountedPostTaxCashflowUsdMM'] === null) res['cumulativeUndiscountedPostTaxCashflowUsdMM'] = 0;
    if (res['discountedPostTaxCashflowUsdMM'] === null) res['discountedPostTaxCashflowUsdMM'] = 0;
    if (res['cumulativeDiscountedPostTaxCashflowUsdMM'] === null) res['cumulativeDiscountedPostTaxCashflowUsdMM'] = 0;
    if (res['combinedMboed'] === null) res['combinedMboed'] = 0;
  });

  return hubAnnuals
}

const resetResourceAnnuals = (resourceAnnuals) => {
  resourceAnnuals.forEach((res) => {
    if (res['opexActualUsdMM'] === null) res['opexActualUsdMM'] = 0;
    if (res['totalTariffUSDMM'] === null) res['totalTariffUSDMM'] = 0;
    if (res['revenueUsdMM'] === null) res['revenueUsdMM'] = 0;
    if (res['preTaxCashFlowUsdMM'] === null) res['preTaxCashFlowUsdMM'] = 0;
    if (res['cumulativePreTaxCashflowUsdMM'] === null) res['cumulativePreTaxCashflowUsdMM'] = 0;
    if (res['corparateTaxUsdMM'] === null) res['corparateTaxUsdMM'] = 0;
    if (res['specialTaxUsdMM'] === null) res['specialTaxUsdMM'] = 0;
    if (res['discountedPostTaxCashFlowUsdMM'] === null) res['discountedPostTaxCashFlowUsdMM'] = 0;
    if (res['cumulativeOperatingCashFlowUsdMM'] === null) res['cumulativeOperatingCashFlowUsdMM'] = 0;
    if (res['undiscountedPostTaxCashFlowUsdMM'] === null) res['undiscountedPostTaxCashFlowUsdMM'] = 0;
    if (res['cumulativeDiscountedPostTaxCashFlowUsdMM'] === null) res['cumulativeDiscountedPostTaxCashFlowUsdMM'] = 0;

    //additional resets - as non calculated values may be set to null due to having a snapshot but no resource annual
    if (res['liquidMbod'] === null) res['liquidMbod'] = 0;
    if (res['opexActualUsdMM'] === null) res['opexActualUsdMM'] = 0;
    if (res['gasMmscfd'] === null) res['gasMmscfd'] = 0;
    if (res['hubProductionPercent'] === null) res['hubProductionPercent'] = 0;
    if (res['capexUSDMM'] === null) res['capexUSDMM'] = 0;
    if (res['opexCostSharePercent'] === null) res['opexCostSharePercent'] = 0;
    if (res['opexCalculatedUsdMM'] === null) res['opexCalculatedUsdMM'] = 0;
    if (res['opexManualUsdMM'] === null) res['opexManualUsdMM'] = 0;
    if (res['liquidTariffUSDBOE'] === null) res['liquidTariffUSDBOE'] = 0;
    if (res['gasTariffUSDBOE'] === null) res['gasTariffUSDBOE'] = 0;
    if (res['abexUSDMM'] === null) res['abexUSDMM'] = 0;
    if (res['operatingCashFlowUsdMM'] === null) res['operatingCashFlowUsdMM'] = 0;
    if (res['cumulativeUndiscountedPostTaxCashFlowUsdMM'] === null) res['cumulativeUndiscountedPostTaxCashFlowUsdMM'] = 0;
    if (res['liquidDeclinePercent'] === null) res['liquidDeclinePercent'] = 0;
    if (res['gasDeclinePercent'] === null) res['gasDeclinePercent'] = 0;
    if (res['priceDiscountPercent'] === null) res['priceDiscountPercent'] = 0;
    if (res['abexUSDMM'] === null) res['abexUSDMM'] = 0;
    if (res['combinedMboed'] === null) res['combinedMboed'] = 0;
    if (res['gasMboed'] === null) res['gasMboed'] = 0;
    if (res['liquidPriceUSDBOE'] === null) res['liquidPriceUSDBOE'] = 0;
    if (res['gasPriceUSDMCF'] === null) res['gasPriceUSDMCF'] = 0;
    if (res['fixedOpexUsdMm'] === null) res['fixedOpexUsdMm'] = 0;
    if (res['cumulativeLiquidMMB'] === null) res['cumulativeLiquidMMB'] = 0;
    if (res['cumulativeGasBCF'] === null) res['cumulativeGasBCF'] = 0;
  });

  return resourceAnnuals
}

const calculateDeclineValues = (currentResourceAnnuals) => {
  let previousLiquidMbod = 0;
  let previousGasMmscfd = 0;

  currentResourceAnnuals.forEach((res) => {
    let liquidDecline = previousLiquidMbod > 0 ? 100 - ((res['liquidMbod'] / previousLiquidMbod) * 100) : 0;
    res['liquidDeclinePercent'] = liquidDecline;
    previousLiquidMbod = res['liquidMbod'];

    let gasDecline = previousGasMmscfd > 0 ? 100 - ((res['gasMmscfd'] / previousGasMmscfd) * 100) : 0;
    res['gasDeclinePercent'] = gasDecline;
    previousGasMmscfd = res['gasMmscfd'];
  });

  return currentResourceAnnuals;
}

const calculateCumulativeValues = (currentResourceAnnuals) => {
  let previousLiquidVal = 0;
  let previousGasVal = 0;

  currentResourceAnnuals.forEach((res) => {
    let cumulateLiquid = (res['liquidMbod'] * 0.365) + previousLiquidVal;
    res['cumulativeLiquidMMB'] = cumulateLiquid;
    previousLiquidVal = res['cumulativeLiquidMMB'];

    let cumulateGas = (res['gasMmscfd'] * 0.365) + previousGasVal;
    res['cumulativeGasBCF'] = cumulateGas;
    previousGasVal = res['cumulativeGasBCF'];
  });

  return currentResourceAnnuals;
}

const changeFalseValueForYearCSS = (rows) => {

  rows.forEach((res) => {
    if (res['falseColumn'] === 'dontChange') res['falseColumn'] = 'doChange';
    else res['falseColumn'] = 'dontChange';
  });

  return rows;
}

export { invalidateResourceAnnualValues, invalidateValues, resetHubAnnuals, resetResourceAnnuals, calculateDeclineValues, calculateCumulativeValues, changeFalseValueForYearCSS };