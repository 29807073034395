import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import './attribute-list-component.scss';
import { MessageBoxGlobal } from '../../globals/globals';
import ToolbarPanelComponent from '../../../shared/components/toolbar-panel/toolbar-panel-component';
import SplitterPanelComponent from '../../../shared/components/splitter-panel/splitter-panel-component';
import PropertyGridComponent, { SectionPropType } from '../../../shared/components/property-grid/property-grid-component';

class AttributeListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.selectedIndex = 0;
  }

  get selected() {
    const { items } = this.props;

    return items.length === 0 || this.selectedIndex < 0 || this.selectedIndex > items.length - 1
      ? null
      : items[this.selectedIndex]
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      if (this.selected === null) {
        this.selectedIndex = 0;
      }
    }
  }

  setSelectedIndex(value) {
    if (typeof value !== 'number') {
      throw new Error('Invalid value param "' + value + '" supplied to AttributeListComponent.setSelectedIndex');
    }

    if (this.selectedIndex !== value) {
      this.selectedIndex = value;
      this.forceUpdate();
    }
  }

  render() {
    const { editing, messageBoxGlobal, items, itemDescription, canAdd, canDelete, canJump, secondarySize } = this.props;
    const selected = this.selected;

    const onAdd = () => {
      this.selectedIndex = this.props.onAddPosition === 'start' ? 0 : items.length;
      this.props.onAdd();
    };

    const onDelete = () => {
      const yesCallback = () => {
        if (this.selectedIndex === items.length - 1) {
          this.selectedIndex--;
        }
        this.props.onDelete(selected.data)
      }
      messageBoxGlobal.showYesNoPrompt('Are you sure you want to delete this ' + itemDescription + '?', yesCallback, 'Delete');
    };

    const toolbarItems = canAdd || canDelete ? [
      <Button title="Add" className="btn-add btn-image no-text first-button" size="sm" disabled={!editing || !canAdd} onClick={onAdd} />,
      <Button title="Delete" className="btn-delete btn-image no-text last-button" size="sm" disabled={!editing || !canDelete || this.selected === null} onClick={onDelete} />
    ] : [];

    const className = (canJump ? ' can-jump' : '');

    return (
      <ToolbarPanelComponent className="attribute-list-component" align="left" toolbarColor="secondary" toolbarItems={toolbarItems}>
        <SplitterPanelComponent vertical percentage={false} primaryIndex={1} secondarySize={secondarySize}>
          <ul className="labels">
            {items.map((item, index) => (
              <li
                key={index}
                className={(index === this.selectedIndex ? 'selected' : '') + className}
              >
                <div className={'label-name' + className} onClick={index === this.selectedIndex ? undefined : () => this.setSelectedIndex(index)}>{item.label}</div>
                {canJump ? <div className="jump-btn" onClick={() => this.props.onJump(item.data.id, item.label)} title="Open in Production View"/> : null}
              </li>
            ))}
          </ul>
          <PropertyGridComponent readOnly={!editing} sections={selected === null ? [] : selected.sections} />
        </SplitterPanelComponent>
      </ToolbarPanelComponent>
    );
  }
}

AttributeListComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      data: PropTypes.object.isRequired,
      sections: PropTypes.arrayOf(SectionPropType).isRequired
    })
  ).isRequired,
  itemDescription: PropTypes.string.isRequired,
  onAddPosition: PropTypes.oneOf(['start', 'end']).isRequired,
  canAdd: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canJump: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onJump: PropTypes.func.isRequired,
};

AttributeListComponent.defaultProps = {
  onAddPosition: 'start',
  secondarySize: 300,
  onAdd: () => { },
  onDelete: () => { },
  canJump: false,
  onJump: () => { },
};

export default AttributeListComponent;