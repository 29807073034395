class Entities {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Entities.ctor'); }
    if (obj !== null) {
      if (typeof obj.entityTypeId !== 'number') { throw new Error('Invalid obj.entityTypeId param supplied to Entities.ctor'); }
      if (typeof obj.entityType !== 'string') { throw new Error('Invalid obj.entityType param supplied to Entities.ctor'); }
    }

    this.entityTypeId = obj === null ? 0 : obj.entityTypeId;
    this.entityType = obj === null ? '' : obj.entityType;
  }
}

class EmailRecipientAttributes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to EmailRecipientAttributes.ctor'); }
    if (obj !== null) {
      if (typeof obj.recipientId !== 'number') { throw new Error('Invalid obj.recipientId param supplied to EmailRecipientAttributes.ctor'); }
      if (typeof obj.entityTypeId !== 'number') { throw new Error('Invalid obj.entityTypeId param supplied to EmailRecipientAttributes.ctor'); }
      if (typeof obj.emailAddress !== 'string') { throw new Error('Invalid obj.emailAddress param supplied to EmailRecipientAttributes.ctor'); }
    }

    this.recipientId = obj === null ? 0 : obj.recipientId;
    this.entityTypeId = obj === null ? 0 : obj.entityTypeId;
    this.emailAddress = obj === null ? '' : obj.emailAddress;
  }
}

class EndPointAttributes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to EndPointAttributes.ctor'); }
    if (obj !== null) {
      if (typeof obj.endPointId !== 'number') { throw new Error('Invalid obj.endPointId param supplied to EndPointAttributes.ctor'); }
      if (typeof obj.entityTypeId !== 'number') { throw new Error('Invalid obj.entityTypeId param supplied to EndPointAttributes.ctor'); }
      if (typeof obj.entityType !== 'string') { throw new Error('Invalid obj.entityType param supplied to EndPointAttributes.ctor'); }
      if (typeof obj.endPointTypeId !== 'number') { throw new Error('Invalid obj.endPointTypeId param supplied to EndPointAttributes.ctor'); }
      if (typeof obj.endPointType !== 'number') { throw new Error('Invalid obj.endPointType param supplied to EndPointAttributes.ctor'); }
      if (typeof obj.endPointURL !== 'string') { throw new Error('Invalid obj.endPointURL param supplied to EndPointAttributes.ctor'); }
      if (typeof obj.uidAttribute !== 'string') { throw new Error('Invalid obj.uidAttribute param supplied to EndPointAttributes.ctor'); }
      if (typeof obj.nameAttribute !== 'string') { throw new Error('Invalid obj.nameAttribute param supplied to EndPointAttributes.ctor'); }
      if (obj.jsonPath !== null && typeof obj.jsonPath !== 'string') { throw new Error('Invalid obj.jsonPath param supplied to EndPointAttributes.ctor'); }
      if (obj.attributeExcludeList !== null && typeof obj.attributeExcludeList !== 'string') { throw new Error('Invalid obj.attributeExcludeList param supplied to EndPointAttributes.ctor'); }
      if (typeof obj.exportPath !== 'string') { throw new Error('Invalid obj.exportPath param supplied to EndPointAttributes.ctor'); }
      if (typeof obj.exportFilename !== 'string') { throw new Error('Invalid obj.exportFilename param supplied to EndPointAttributes.ctor'); }
      if (typeof obj.active !== 'boolean') { throw new Error('Invalid obj.active param supplied to EndPointAttributes.ctor'); }
      if (obj.teamsWebhookURL !== null && typeof obj.teamsWebhookURL !== 'string') { throw new Error('Invalid obj.teamsWebhookURL param supplied to EndPointAttributes.ctor'); }
      if (typeof obj.allowEmptyJson !== 'boolean') { throw new Error('Invalid obj.allowEmptyJson param supplied to EndPointAttributes.ctor'); }
    }

    this.endPointId = obj.endPointId;
    this.entityTypeId = obj.entityTypeId;
    this.entityType = obj.entityType;
    this.endPointTypeId = obj.endPointTypeId;
    this.endPointType = obj.endPointType;
    this.endPointURL = obj.endPointURL;
    this.uidAttribute = obj.uidAttribute;
    this.nameAttribute = obj.nameAttribute;
    this.jsonPath = obj.jsonPath === null ? '' : obj.jsonPath;
    this.attributeExcludeList = obj.attributeExcludeList === null ? '' : obj.attributeExcludeList;
    this.exportPath = obj.exportPath;
    this.exportFilename = obj.exportFilename;
    this.active = obj.active;
    this.teamsWebhookURL = obj.teamsWebhookURL === null ? '' : obj.teamsWebhookURL;
    this.allowEmptyJson = obj.allowEmptyJson;
  }
}
export { EmailRecipientAttributes, Entities, EndPointAttributes };
