import React from 'react';
import PropTypes from 'prop-types';

import { LicenceBlock } from '../../models/result';
import SplitterPanelComponent from '../../../../shared/components/splitter-panel/splitter-panel-component';
import PropertyGridComponent, { PropertyGridStringValue } from '../../../../shared/components/property-grid/property-grid-component';

const IDS = {
  NAME: 0,
  SUB_AREA: 10,
};
Object.freeze(IDS);

class BlocksComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { items: [], selectedIndex: 0};
  }

  componentDidMount() {
    this.update(this.state.selectedIndex);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entityId !== this.props.entityId) {
      this.update(0);
    }
  }

  update(index) {
    const { blocks } = this.props;
    const items = blocks.map(item => {
      return {
        label: item.blockName,
        data: item,
        sections: [{
          label: 'Blocks',
          rows: [
            { label: 'Block Name', values: [new PropertyGridStringValue(IDS.NAME, item.blockName)] },
            { label: 'Sub Area', values: [new PropertyGridStringValue(IDS.SUB_AREA, item.subArea)] },
          ]
        }]
      }
    });

    this.setState({ items: items, selectedIndex: index });
  }

  get selected() {
    const { items, selectedIndex } = this.state;

    return items.length === 0 || selectedIndex < 0 || selectedIndex > items.length - 1
      ? null
      : items[selectedIndex]
  }

  setSelectedIndex = (value) => {
    const { selectedIndex } = this.state;
    if (typeof value !== 'number') {
      throw new Error('Invalid value param "' + value + '" supplied to AttributeListComponent.setSelectedIndex');
    }

    if (selectedIndex !== value) {
      this.setState({ selectedIndex: value });
    }
  }

  render() {
    const { items, selectedIndex } = this.state;
    const selected = this.selected;

    return (
      <SplitterPanelComponent vertical percentage={false} primaryIndex={1} secondarySize={225}>
        <ul className="labels">
          {items.map((item, index) => (
            <li
              key={index}
              className={(index === selectedIndex ? 'selected' : '')}
            >
              <div className={'label-name'} onClick={index === selectedIndex ? undefined : () => this.setSelectedIndex(index)}>{item.label}</div>

            </li>
          ))}
        </ul>
        <PropertyGridComponent readOnly={false} sections={selected === null ? [] : selected.sections} narrow={true} />
      </SplitterPanelComponent>
    );
  }
}

BlocksComponent.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.instanceOf(LicenceBlock)),
  editing: PropTypes.bool.isRequired,
  entityId: PropTypes.number.isRequired
};


export default BlocksComponent;
