import React from 'react';
import PropTypes from 'prop-types';

import AttributeStaticListComponent from '../../../../common/components/attribute-list/attribute-static-list-component';

import { ResourceWestwoodDataFloatingPlatform } from '../../models/result';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import { StringLookupResult } from '../../../../common/models/string-lookup-result';
import { RECORD_STATUS } from '../../../../common/models/record-status';
import { PropertyGridStringValue, PropertyGridSelectValue, PropertyGridNumberValue, PropertyGridBooleanValue } from '../../../../shared/components/property-grid/property-grid-component';
import { INPUTTYPES } from '../../../../shared/components/number-format/number-formatter';

const IDS = {
  FACILITY_ID: 5,
  NAME: 10,
  PROJECT_NAME: 15,
  FIELD_NAME: 16,
  STATUS: 20,
  FPS_TYPE: 25,
  WATER_DEPTH: 30,
  OWNERSHIP_TYPE: 35,
  IMO: 40,
  WEIGHT_TOTAL_TONNES: 45,
  MOORING_SYSTEM: 50,
  MOORING_TYPE: 55,
  CAPEX: 60,
  CAPEX_ESTIMATED: 65,
  PRODUCT: 85,
  DEVELOPMENT_TYPE: 90,
  DEVELOPMENT_STRUCTURE: 95
};
Object.freeze(IDS);

const FloatingPlatformComponent = (props) => {
  const { editing, messageBoxGlobal, lookupsWestwood, floatingPlatforms, floatingPlatformActions, id } = props;

  const onEdit = (id, value, item) => {
    switch (id) {
      case IDS.FACILITY_ID: item.facilityId = value; break;

      default: throw new Error('Id ' + id + ' is not supported in AliasComponent.onEdit');
    }

    floatingPlatformActions.onEdit(item);
    return true;
  };

  const getTitle = (item) => {
    let value = item.floatingPlatform === undefined || item.floatingPlatform === null ? '' : item.floatingPlatform.name;

    if (item.status === RECORD_STATUS.added && value === '') return '-New Floating Platform--';

    return value;
  };

  const getRows = (item) => {
    let rows = [];
    rows.push({ label: 'Platform', values: [new PropertyGridSelectValue(IDS.FACILITY_ID, item.facilityId, (value, id) => onEdit(id, value, item), 'key', 'value', lookupsWestwood.FloatingPlatform)] });
    if (item.floatingPlatform === undefined || item.floatingPlatform === null) return rows;

    let fp = item.floatingPlatform;
    rows.push({ label: 'Westwood Data Platform Id', values: [new PropertyGridStringValue(IDS.FACILITY_ID, fp.id)] });
    rows.push({ label: 'Project', values: [new PropertyGridStringValue(IDS.PROJECT_NAME, fp.projectName)] });
    rows.push({ label: 'Field', values: [new PropertyGridStringValue(IDS.FIELD_NAME, fp.fieldName)] });

    rows.push({ label: 'Status', values: [new PropertyGridStringValue(IDS.STATUS, fp.status)] });
    rows.push({ label: 'FPS Type', values: [new PropertyGridStringValue(IDS.FPS_TYPE, fp.fpsType)] });
    rows.push({ label: 'Water Depth (m)', values: [new PropertyGridNumberValue(IDS.WATER_DEPTH, INPUTTYPES.NUMBER, fp.waterDepthMeters, null, 2)] });
    rows.push({ label: 'Ownership Type', values: [new PropertyGridStringValue(IDS.OWNERSHIP_TYPE, fp.ownershipType)] });

    rows.push({ label: 'IMO', values: [new PropertyGridStringValue(IDS.IMO, fp.vesselIMO)] });
    rows.push({ label: 'Weight Total (tonnes)', values: [new PropertyGridNumberValue(IDS.WEIGHT_TOTAL_TONNES, INPUTTYPES.NUMBER, fp.weightTotalTonnes, null, 2)] });
    rows.push({ label: 'Mooring System', values: [new PropertyGridStringValue(IDS.MOORING_SYSTEM, fp.mooringSystem)] });
    rows.push({ label: 'Mooring Type', values: [new PropertyGridStringValue(IDS.MOORING_TYPE, fp.mooringType)] });

    rows.push({ label: 'CAPEX ($mm)', values: [new PropertyGridNumberValue(IDS.CAPEX, INPUTTYPES.NUMBER, fp.capexUSDMM, null, 2)] });
    rows.push({ label: 'CAPEX Estimated', values: [new PropertyGridBooleanValue(IDS.CAPEX_ESTIMATED, fp.capexEstimated)] });

    rows.push({ label: 'Product', values: [new PropertyGridStringValue(IDS.PRODUCT, fp.product)] });
    rows.push({ label: 'Development Type', values: [new PropertyGridStringValue(IDS.DEVELOPMENT_TYPE, fp.developmentType)] });
    rows.push({ label: 'Development Structure', values: [new PropertyGridStringValue(IDS.DEVELOPMENT_STRUCTURE, fp.developmentStructure)] });

    return rows;
  }

  const items = floatingPlatforms.map(item => {
    return {
      label: getTitle(item),
      data: item,
      sections: [{
        label: 'Floating Platform',
        rows: getRows(item)
      }]
    }
  });

  const add = () => {
    let newItem = new ResourceWestwoodDataFloatingPlatform(null);
    newItem.getNew(id);

    if (floatingPlatforms.length > 0) {
      floatingPlatforms[0].status = floatingPlatforms[0].status === RECORD_STATUS.added ? floatingPlatforms[0].status : RECORD_STATUS.edited;
    }

    floatingPlatformActions.onAdd(newItem);
  }

  return (
    <AttributeStaticListComponent
      editing={editing}
      messageBoxGlobal={messageBoxGlobal}
      items={items}
      itemDescription="Floating Platform"
      canAdd
      canDelete
      onAdd={add}
      onDelete={floatingPlatformActions.onDelete}
      onSelected={floatingPlatformActions.onSelected}
      onAddPosition={"start"}
    />
  );
};

FloatingPlatformComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  lookupsWestwood: PropTypes.instanceOf(StringLookupResult).isRequired,
  floatingPlatforms: PropTypes.arrayOf(PropTypes.instanceOf(ResourceWestwoodDataFloatingPlatform)).isRequired,
  floatingPlatformActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired
};

export default FloatingPlatformComponent;
