import React from 'react';
//import PropTypes from 'prop-types';

import './jim-test-view.scss';

const JimTestView = (props) => {

  return (
    <div className="view-panel jim-test-view">
      <h1>JimTestView</h1>
    </div>
  );
};

JimTestView.propTypes = {
}

export default JimTestView;