import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

class TimeoutComponent extends React.Component {
  constructor(props) {
    super(props);

    this.timer = null;
    this.onTimer = this.onTimer.bind(this);
  }

  componentDidMount() {
    this.initTimer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.timeout !== this.props.timeout) {
      this.initTimer();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  onTimer() {
    if (this.getTimeoutInMinutes() <= 0) {
      clearInterval(this.timer);
      this.props.onTimedOut();
    }
    this.forceUpdate();
  }

  initTimer() {
    clearInterval(this.timer);
    if (this.getTimeoutInMinutes() > 0) {
      this.timer = setInterval(this.onTimer, 60000); // Update timeout every minute
    }
  }

  getTimeoutInMinutes() {
    const { timeout } = this.props;

    if (!(timeout instanceof Date)) {
      return 0;
    }

    const timespan = timeout - new Date();
    if (timespan <= 0) {
      return 0;
    }

    const result = Math.round(timespan / 1000 / 60);
    return result;
  }

  render() {
    let timeout = this.getTimeoutInMinutes();
    if (timeout <= 0) {
      return null;
    }

    const textStyle = {};
    if (timeout <= 10) {
      textStyle.backgroundColor = 'orange';
    }
    if (timeout <= 5) {
      textStyle.backgroundColor = 'red';
    }

    let text = 'Expired';
    if (timeout > 0) {
      text = '';
      if (timeout > 60) {
        text += Math.floor(timeout / 60) + 'h ';
        timeout = timeout % 60;
      };
      text += Math.ceil(timeout) + 'm';
    }

    return [
      <h4 key={0}><small>Timeout:&nbsp;</small><span style={textStyle}>{text}</span></h4>,
      <Button id="reset-button" key={1} className="btn-image reset-timeout-button" disabled={!this.props.canEnable} size="sm" onClick={() => this.props.onReset()}>Reset</Button>
    ];
  }
}

TimeoutComponent.propTypes = {
  onReset: PropTypes.func.isRequired,
  onTimedOut: PropTypes.func.isRequired,
  timeout: PropTypes.instanceOf(Date)
};

export default TimeoutComponent;

