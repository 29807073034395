import { Issue } from '../../../common/models/issue';
import { EditLock } from '../../../common/models/edit-lock';
import { GMatrixGenericComment } from '../../../common/models/generic-comment';
import { PolygonsModel, PolygonModel } from '../../../shared/helpers/leaflet/models/polygons-model';
import { MapShapeBorder } from '../../../shared/scss/app-colors';
import { CircleModel } from '../../../shared/helpers/leaflet/models/circle-model';
import Ownership from '../../../common/models/ownership';

import { LookupResult } from '../../../common/models/lookup-result';

class Attributes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Attributes.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to Attributes.ctor'); }
      if (typeof obj.displayName !== 'string') { throw new Error('Invalid obj.displayName param supplied to Attributes.ctor'); }
      if (typeof obj.parentBlockId !== 'number') { throw new Error('Invalid obj.parentBlockId param supplied to Attributes.ctor'); }
      if (typeof obj.parentBlockNo !== 'string') { throw new Error('Invalid obj.parentBlockNo param supplied to Attributes.ctor'); }
      if (typeof obj.blockNo !== 'string') { throw new Error('Invalid obj.blockNo param supplied to Attributes.ctor'); }
      if (obj.subArea !== null && typeof obj.subArea !== 'string') { throw new Error('Invalid obj.subArea param supplied to Attributes.ctor'); }
      if (obj.blockSuffix !== null && typeof obj.blockSuffix !== 'string') { throw new Error('Invalid obj.blockSuffix param supplied to Attributes.ctor'); }
      if (obj.subAreaAbbrev !== null && typeof obj.subAreaAbbrev !== 'string') { throw new Error('Invalid obj.subAreaAbbrev param supplied to Attributes.ctor'); }
      if (typeof obj.statusId !== 'number') { throw new Error('Invalid obj.statusId param supplied to Attributes.ctor'); }
      if (typeof obj.blockStatus !== 'string') { throw new Error('Invalid obj.blockStatus param supplied to Attributes.ctor'); }
      if (obj.licenceId !== null && typeof obj.licenceId !== 'number') { throw new Error('Invalid obj.licenceId param supplied to Attributes.ctor'); }
      if (obj.licenceRound !== null && typeof obj.licenceRound !== 'string') { throw new Error('Invalid obj.licenceRound param supplied to Attributes.ctor'); }
      if (typeof obj.isLicenced !== 'boolean') { throw new Error('Invalid obj.isLicenced param supplied to Attributes.ctor'); }
      if (obj.stratLevelId !== null && typeof obj.stratLevelId !== 'number') { throw new Error('Invalid obj.stratLevelId param supplied to Attributes.ctor'); }
      if (obj.relinquishComment !== null && typeof obj.relinquishComment !== 'string') { throw new Error('Invalid obj.relinquishComment param supplied to Attributes.ctor'); }
      if (obj.shapeId !== null && typeof obj.shapeId !== 'number') { throw new Error('Invalid obj.shapeId param supplied to Attributes.ctor'); }

      if (typeof obj.lastModified !== 'string') { throw new Error('Invalid obj.lastModified param supplied to Attributes.ctor'); }
      if (typeof obj.countryId !== 'number') { throw new Error('Invalid obj.countryId param supplied to Attributes.ctor'); }

      if (obj.comments !== null && !Array.isArray(obj.comments)) { throw new Error('Invalid obj.comments param supplied to Attributes.ctor'); }
      if (obj.ownership !== null && !Array.isArray(obj.ownership)) { throw new Error('Invalid obj.ownership param supplied to Attributes.ctor'); }

    }

    this.id = obj === null ? 0 : obj.id;
    this.displayName = obj === null ? '' : obj.displayName;
    this.parentBlockId = obj === null ? 0 : obj.parentBlockId;
    this.parentBlockNo = obj === null ? '' : obj.parentBlockNo;
    this.blockNo = obj === null ? '' : obj.blockNo;
    this.subArea = obj === null ? '' : obj.subArea;
    this.blockSuffix = obj === null ? '' : obj.blockSuffix;
    this.subAreaAbbrev = obj === null ? '' : obj.subAreaAbbrev;
    this.statusId = obj === null ? -1 : obj.statusId;
    this.blockStatus = obj === null ? '' : obj.blockStatus;
    this.licenceId = obj === null || obj.licenceId  === null ? null : obj.licenceId;
    this.licenceRound = obj === null || obj.licenceRound === null ? '' : obj.licenceRound;
    this.isLicenced = obj === null ? false : obj.isLicenced;
    this.stratLevelId = obj === null || obj.stratLevelId === null ? undefined : obj.stratLevelId;
    this.relinquishComment = obj === null || obj.relinquishComment === null ? '' : obj.relinquishComment;
    this.shapeId = obj === null || obj.shapeId === null ? -1 : obj.shapeId;

    this.lastModified = obj === null || obj.lastModified === null ? Date.now() : obj.lastModified;
    this.countryId = obj === null || obj.countryId === null ? -1 : obj.countryId;

    this.shapes = undefined;
    if (obj !== null && obj !== undefined) {
      if (obj.shapes !== undefined && obj.shapes !== null) {
        this.shapes = new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });
      }
      else if (obj.points !== undefined && obj.points !== null && obj.points.length > 0) {
        let item = obj.points[0];
        this.shapes = new CircleModel(item.y, item.x, { borderColor: MapShapeBorder, fillColor: 'transparent', radius: 1000 });
      }
    }
    this.points = obj === null || obj === undefined || obj.points === undefined || obj.points === null ? undefined : obj.points.map(item => new CircleModel(item.y, item.x, { borderColor: MapShapeBorder, fillColor: 'transparent', radius: 400 }));

    this.comments = (obj === null || obj.comments === null) ? [new GMatrixGenericComment(null)] : obj.comments.map(item => new GMatrixGenericComment(item));
    this.ownership = obj === null || !obj.isLicenced ? null : obj.ownership === null ? [new Ownership(null)] : obj.ownership.map(item => new Ownership(item));

    this.status = obj === null ? '' : obj.status;
    this.originalStatus = obj === null ? '' : obj.originalStatus;
  }
}

export class Result {
  constructor(obj) {
    obj && Object.assign(this, obj);
    this.attributes = obj === null || obj === undefined ? new Attributes(null) : new Attributes(obj.attributes);
    this.issues = obj === null || obj === undefined ? [new Issue()] : obj.issues.map(item => new Issue(item));
    this.editLock = obj === null || obj === undefined ? new EditLock() : new EditLock(obj.editLock);
    this.lookupsFiltered = obj === null || obj === undefined || obj.lookupsFiltered === null || obj.lookupsFiltered === undefined ? new LookupResult() : new LookupResult(obj.lookupsFiltered);
  }
}

export { Issue, EditLock, Attributes };
