import React from "react";
import PropTypes from 'prop-types';

import { MessageBoxGlobal } from '../../common/globals/globals';
import { ColSettings } from '../../common/models/col-settings';
import AGGridComponent from '../../common/components/grid/ag-grid-component';

const HubUpsideGridComponent = (props) => {
  const { messageBoxGlobal, data, colSettings } = props;
  let columnDefs = [];

  columnDefs = ColSettings.getAgGridColumns(ColSettings.FromArray(colSettings));

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {},
    image: {}
  };

  return (
    <AGGridComponent
      messageBoxGlobal={messageBoxGlobal}
      columnDefs={columnDefs}
      rowsCount={data?.length ?? 0}
      columnTypes={columnTypes}
      gridData={data}
      allowPaste={false}
      editYear={0}
      autoResize
    />
  );
};

HubUpsideGridComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
};

export default HubUpsideGridComponent;
