import L from 'leaflet';
import { faThumbtack } from '@fortawesome/pro-solid-svg-icons';
import { getIcon } from '../helpers/control-helper';

L.Control.PinLocationCoordinatesControl = L.Control.extend({
  initialize: function (options) {
    this.options = options;
  },
  onAdd: function (map) {
    this._container = L.DomUtil.create('div', 'leaflet-bar');
    this._container.style.fontSize = '11px';
    this._container.style.backgroundColor = 'rgba(255, 255, 255, 1)';

    this._image = L.DomUtil.create('span', '', this._container);
    this._image.innerHTML = getIcon(faThumbtack);
    this._image.style.padding = '2px 0px 0px 4px';
    this._image.style.height = '17px';
    this._image.style.float = 'left';

    this._label = L.DomUtil.create('span', '', this._container);
    this._label.style.color = '#333';
    this._label.style.padding = '2px 5px 2px 5px';

    return this._container;
  }
});

L.control.pinLocationCoordinatesControl = (opts) => {
  return new L.Control.PinLocationCoordinatesControl(opts);
};

const addPinLocationCoordinatesControl = (leafletMap, position) => {
  const result = L.control.pinLocationCoordinatesControl({ position: position });
  leafletMap.addControl(result);
  return result;
};

export { addPinLocationCoordinatesControl };