import React from 'react';
import { Button } from 'reactstrap';

import HomeComponent from './home-component';

const InvalidVersionComponent = (props) => {
  return (
    <HomeComponent>
      <h3>A newer version of this application is available.</h3>
      <h3>Please click the reload button.</h3>
      <br />
      <Button size="lg" onClick={() => window.location.reload(true)}>Reload</Button>
    </HomeComponent>
  );
};

export default InvalidVersionComponent;