import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';

const CheckedDropdownComponent = (props) => {
  const { className, disabled, selectedItems, items, text, keyName, valueName, onChange, onSelectAll, onSelectNone } = props;

  let selectAllNone = [];
  if (typeof onSelectAll === 'function') {
    selectAllNone.push(<DropdownItem key={1} onClick={onSelectAll}>Select All</DropdownItem>);
  }
  if (typeof onSelectNone === 'function') {
    selectAllNone.push(<DropdownItem key={2} onClick={onSelectNone}>Select None</DropdownItem>);
  }
  if (selectAllNone.length > 0) {
    selectAllNone.unshift(<DropdownItem key={0} divider />);
  }

  return (
    <UncontrolledDropdown size="sm">
      <DropdownToggle disabled={disabled} caret className={className} style={{ width: '100%' }}>{text}</DropdownToggle>
      <DropdownMenu>
        {items.map(item => (
          <DropdownItem key={'item' + item[keyName]} onClick={() => onChange(item)}>
            <Input type="checkbox" defaultChecked={selectedItems.includes(item[keyName])} style={{ marginTop: '.2rem' }} />{item[valueName]}
          </DropdownItem>
        ))}
        {selectAllNone}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

CheckedDropdownComponent.propTypes = {
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  keyName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func,
  onSelectNone: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  text: PropTypes.string.isRequired,
  valueName: PropTypes.string.isRequired
};

export default CheckedDropdownComponent;
