import React from 'react';
import PropTypes from 'prop-types';
import './auto-update.scss';

import { apiGet } from '../../common/helpers/api-helpers';
import { LoadingGlobal, MessageBoxGlobal } from '../../common/globals/globals';
import AutoUpdateGridComponent from './auto-update-grid-component';
import AutoUpdateSelectComponent from './auto-update-select-component';
import { AutoUpdateLogs, AutoUpdateTypes } from './models/result';
import { Button } from 'reactstrap';

class AutoUpdateView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { autoUpdateLogs: null, autoUpdateType: null, autoUpdateTypes: null, id: null, showAll: false };
  };

  get apiPrefix() { return 'api/autoUpdate/' };

  componentDidMount() {
    this.getAutoUpdateTypes();
  }

  selectAutoUpdateType = (id) => {
    this.initAutoUpdateData(id);
  }

  autoUpdateRun = () => {
    const onSuccess = (result) => {
      this.props.messageBoxGlobal.showError(result);
      const id = this.state.id;
      this.selectAutoUpdateType(id);
    }

    apiGet(this.apiPrefix + 'auto-update-run', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
  }

  initAutoUpdateData(id) {
    const onSuccess = (result) => {
      this.setState({ id: id, autoUpdateLogs: result.logs.map(obj => new AutoUpdateLogs(obj)), autoUpdateType: result.autoUpdateType });

    }
    apiGet(this.apiPrefix + 'auto-update-logs?autoUpdateTypeId=' + id, this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
  }

  getAutoUpdateTypes() {
    const onSuccess = (result) => {
      this.setState({ autoUpdateTypes: result.Type, id: result.Type[0].key }, this.initAutoUpdateData(result.Type[0].key) );
    }
    apiGet(this.apiPrefix + 'update-types', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
  }

  render() {
    const { autoUpdateLogs, autoUpdateType, autoUpdateTypes, id, showAll } = this.state;

    if (autoUpdateTypes === null || id === null) return null;

    if (autoUpdateTypes !== null && autoUpdateTypes.length > 0 && autoUpdateLogs === null && id === null) this.initAutoUpdateData(autoUpdateTypes[0].key);

    let hasUpdates = autoUpdateLogs !== null && autoUpdateLogs.length > 0;
    let updateCount = hasUpdates ? autoUpdateLogs.length : 0;

    const title = autoUpdateType !== null && autoUpdateType !== '' ? autoUpdateType + ' Updates' : 'Auto Updates';

    const autoUpdateLogGrid = hasUpdates || updateCount > 0
      ? <AutoUpdateGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={autoUpdateLogs} />
      : <div className="no-results">No Results Found, Please select another update type</div>

    return (
      <div className="auto-update-view">
        <h1 className="title">{title}</h1>
        <AutoUpdateSelectComponent selectEntity={this.selectAutoUpdateType} entities={autoUpdateTypes} value={autoUpdateTypes[id - 1].key} />
        <div className="update"><Button onClick={this.autoUpdateRun} >Run Auto Update</Button></div>
        {autoUpdateLogGrid}
      </div>
    );
  }

};

AutoUpdateView.propTypes = {
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
}

export default AutoUpdateView;
