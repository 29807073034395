import {
  WgeCharcoalPlum,
  WgeIndigoBlue,
  WgeVibrantGreen,
  WgeLightGrey,
  WgeEnergentBlue,
  WgeWildcatBlue,
  WgeNewEnergies,
  WgeAtlasPurple,
  WgePlatformLogix,
  WgeRigLogix,
  WgeGlrGreen,
  WgeRigLogixYellow,
  WgeEnergentLight,
  WgeWildcatDark,
  WgeNewEnergiesLight,
  WgeAtlasDark,
  WgePlatformLogixAlt,
  WgeRigLogixDark,
  WgeGlrLight,
  WgeWindLogixDark,
  WgeEnergentDark,
  WgeWildcatLight,
  WgeNewEnergiesDark,
  WgeAtlasLight,
  WgePlatformLogixAlt2,
  WgeRigLogixLight,
  WgeGlrDark,
  WgeWindLogixLight,

  WgeCorporateBlue,
  WgeSectorsRed,
  WgeAnalyticsGreen,
  WgeFarmOutsOrange,
  WgeRigEdgeBlue,
  WgeRigOutlookMaroon,
  WgeAdditional1,
  WgeAdditional2,
  WgeAdditional3,
  WgeAdditional4,
  WgeAdditional5,
  WgeAdditional6,
  WgeAdditional7,
  WgeAdditional8,
  WgeAdditional9,
  WgeAdditional10,
  WgeDarkGrey
} from '../scss/wge-colors';

const assignColours = (theArray, colourColumn) => {
  const colors = [
    WgeCharcoalPlum,
    WgeIndigoBlue,
    WgeVibrantGreen,
    WgeLightGrey,
    WgeEnergentBlue,
    WgeWildcatBlue,
    WgeNewEnergies,
    WgeAtlasPurple,
    WgePlatformLogix,
    WgeRigLogix,
    WgeGlrGreen,
    WgeRigLogixYellow,
    WgeEnergentLight,
    WgeWildcatDark,
    WgeNewEnergiesLight,
    WgeAtlasDark,
    WgePlatformLogixAlt,
    WgeRigLogixDark,
    WgeGlrLight,
    WgeWindLogixDark,
    WgeEnergentDark,
    WgeWildcatLight,
    WgeNewEnergiesDark,
    WgeAtlasLight,
    WgePlatformLogixAlt2,
    WgeRigLogixLight,
    WgeGlrDark,
    WgeWindLogixLight,

    WgeCorporateBlue,
    WgeSectorsRed,
    WgeAnalyticsGreen,
    WgeFarmOutsOrange,
    WgeRigEdgeBlue,
    WgeRigOutlookMaroon,
    WgeAdditional1,
    WgeAdditional2,
    WgeAdditional3,
    WgeAdditional4,
    WgeAdditional5,
    WgeAdditional6,
    WgeAdditional7,
    WgeAdditional8,
    WgeAdditional9,
    WgeAdditional10,
    WgeDarkGrey
  ];
  for (var loop = 0; loop < theArray.length; loop++) {
    theArray[loop][colourColumn] = colors[loop % colors.length];
  }
};

export { assignColours };
