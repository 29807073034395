import React from 'react';
import PropTypes from 'prop-types';

const Min = "Min";
const Max = "Max";

class YearsFilterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEndYear: props.selectedEndYear,
      selectedStartYear: props.selectedStartYear
    };

    this.years = [Min];

    var startYear = props.startYear;
    var endYear = props.endYear;
    while (startYear <= endYear) {
      this.years.push(startYear++);
    }

    this.years.push(Max);
  }

  onChangeStartYear(e) {
    var startYear = 0;
    startYear = e.target.value === Min ? 0 : e.target.value === Max ? 9999 : e.target.value;
    this.setState({ selectedStartYear: startYear });
    this.props.onYearStartChange(startYear);

  }

  onChangeEndYear(e) {
    var endYear = 0;
    endYear = e.target.value === Min ? 0 : e.target.value === Max ? 9999 : e.target.value;
    this.setState({ selectedEndYear: endYear });
    this.props.onYearEndChange(endYear);
  }

  render() {
    return (
      <div className="years-filter">
        <h4 key={0}>Years:</h4>
        <h4 key={1}><small>From:</small></h4>
        <select key={2} value={this.state.selectedStartYear > this.props.endYear ? Max : this.state.selectedStartYear}
          onChange={(e) => this.onChangeStartYear(e)}>
          {this.years.map((year) => <option key={year} value={year}>{year}</option>)}
        </select>
        <h4 key={3}><small>To:</small></h4>
        <select key={4} value={this.state.selectedEndYear > this.props.endYear ? Max : this.state.selectedEndYear}
          onChange={(e) => this.onChangeEndYear(e)}>
          {this.years.map((year) => <option key={year} value={year}>{year}</option>)}
        </select>
      </div>
    );
  }

}

YearsFilterComponent.propTypes = {
  onYearStartChange: PropTypes.func.isRequired,
  onYearEndChange: PropTypes.func.isRequired,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  selectedStartYear: PropTypes.number.isRequired,
  selectedEndYear: PropTypes.number.isRequired
};

YearsFilterComponent.defaultProps = {
  startYear: 1995,
  endYear: 2050
};

export default YearsFilterComponent;
