import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse } from 'reactstrap';

import './sidebar-component.scss';
import RecordLocksComponent, { SelectedEditLock } from './record-locks-component';
import CountriesComponent from './countries-component';
import ViewsComponent from './views-component';
import SearchComponent from './search-component';
import SearchResultsComponent from './search-results-component';
import { apiGet, apiPost } from '../../common/helpers/api-helpers';
import { MessageBoxButton } from '../../shared/components/message-box/message-box-component';
import ToolbarPanelComponent from '../../shared/components/toolbar-panel/toolbar-panel-component';
import { IdGlobal, SearchGlobal, ViewGlobal, Views, LoadingGlobal, CountriesGlobal, MessageBoxGlobal } from '../../common/globals/globals';

class SidebarComponent extends Component {
  constructor(props) {
    super(props);

    this.toggleHistory = this.toggleHistory.bind(this);

    this.countries = [];

    this.state = { historyOpen: false };
  }

  componentDidMount() {
    const { loadingGlobal, messageBoxGlobal } = this.props;
    const onSuccess = (result) => { this.countries = result; this.forceUpdate(); };
    apiGet('api/app/countries', loadingGlobal.show, loadingGlobal.hide, messageBoxGlobal, onSuccess, undefined);
  }

  toggleHistory() {
    this.setState(prevState => { return { historyOpen: !prevState.historyOpen } })
  }

  render() {
    const { idGlobal, viewGlobal, editing, loadingGlobal, countriesGlobal, messageBoxGlobal, searchGlobal, getNavigationHistory, clearNavigationHistory, userGroups } = this.props;

    const recordLocks = () => {
      const onSuccess = (result) => {
        const userName = sessionStorage.getItem('userName');
        const editLocks = result.map(item => new SelectedEditLock(item, editing && item.userName === userName));
        showRecordLocks(editLocks);
      };
      apiGet('api/app/locks', loadingGlobal.show, loadingGlobal.hide, messageBoxGlobal, onSuccess, undefined);
    };

    const showRecordLocks = (editLocks) => {
      const buttons = editing || editLocks.length === 0 ? [MessageBoxButton.Close] : ['Clear', MessageBoxButton.Cancel];
      let callback = (button) => {
        if (button === -1) {
          editLocks = editLocks.filter(item => item.selected === true).map(item => item.editLock);
          if (editLocks.length > 0) {
            apiPost('api/app/locks/clear', JSON.stringify(editLocks), loadingGlobal.show, loadingGlobal.hide, messageBoxGlobal, undefined, undefined);
          }
        }
      };
      messageBoxGlobal.show(<RecordLocksComponent editLocks={editLocks} />, callback, buttons, 'Record Locks');
    };

    const toggleBtn = <div className={'btn-' + (this.props.expanded ? 'collapse' : 'expand')} onClick={this.props.toggleExpanded} />;
    const settingsBtn = (
      <UncontrolledDropdown size="sm" className="settings-dropdown">
        <DropdownToggle tag="div" caret><div className="btn-settings" /></DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={this.props.logout}>Logout</DropdownItem>
          <DropdownItem onClick={recordLocks}>Record Locks</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    let historyItems = [<DropdownItem key={0} disabled>No history</DropdownItem>];
    if (getNavigationHistory().length > 0) {
      historyItems = getNavigationHistory().filter(x => Views.isValid(x.view)).map((obj, index) => <DropdownItem key={index} disabled={editing} onClick={editing ? undefined : () => viewGlobal.setAndId(obj.view, obj.id, obj.displayName, () => searchGlobal.search(obj.displayName, false))}>{Views.displayName(obj.view) + ': ' + obj.displayName}</DropdownItem>);
      historyItems.push(<DropdownItem key={historyItems.length} divider />);
      historyItems.push(<DropdownItem key={historyItems.length} onClick={() => clearNavigationHistory()}>Clear</DropdownItem>);
    }
    const historyBtn = (
      <Dropdown size="sm" className="settings-dropdown" isOpen={this.state.historyOpen} toggle={this.toggleHistory}>
        <DropdownToggle tag="div" caret><div className="btn-history" /></DropdownToggle>
        <DropdownMenu>{historyItems}</DropdownMenu>
      </Dropdown>
    );

    return (
      <ToolbarPanelComponent className="sidebar-component" align="left" toolbarItems={[toggleBtn, settingsBtn, historyBtn]}>
        <div className="panel">
          <CountriesComponent editing={editing} countriesGlobal={countriesGlobal} countries={this.countries} />
          <ViewsComponent editing={editing} viewGlobal={viewGlobal} idGlobal={idGlobal} searchGlobal={searchGlobal} userGroups={userGroups} />
          <div className="search-wrapper">
            <Collapse className="search" isOpen={Views.showFilters(viewGlobal.value)}>
              <SearchComponent editing={editing} searchGlobal={searchGlobal} />
              <SearchResultsComponent editing={editing} idGlobal={idGlobal} viewGlobal={viewGlobal} searchGlobal={searchGlobal} />
            </Collapse>
          </div>
        </div>
      </ToolbarPanelComponent>
    );
  }
}

SidebarComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  idGlobal: PropTypes.instanceOf(IdGlobal).isRequired,
  viewGlobal: PropTypes.instanceOf(ViewGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  countriesGlobal: PropTypes.instanceOf(CountriesGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  searchGlobal: PropTypes.instanceOf(SearchGlobal).isRequired,
  getNavigationHistory: PropTypes.func.isRequired,
  clearNavigationHistory: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

export default SidebarComponent;
