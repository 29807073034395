import React from 'react';
import PropTypes from 'prop-types';

import { ResourceAnnual } from '../../../../models/production-result';
import ProductionShareChart from '../../../../../../common/components/chart/production-share-chart';

const HubProductionShareChartComponent = (props) => {
  const {
    hubMinYear,
    hubMaxYear,
    allResourceAnnuals
  } = props;

  return (
    <ProductionShareChart
      resourceData={allResourceAnnuals}
      isStacked="true"
      chartType="Line"
      startYear={hubMinYear}
      endYear={hubMaxYear}
      xTitle="Year"
      yTitle="%"
    />
  );
};

HubProductionShareChartComponent.propTypes = {
  hubMinYear: PropTypes.number.isRequired,
  hubMaxYear: PropTypes.number.isRequired,
  maxCombinedValue2: PropTypes.number.isRequired,
  allResourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired
};

export default HubProductionShareChartComponent;