import L from 'leaflet';
import { default as GeometryUtil } from 'leaflet-geometryutil';
import { faDrawCircle } from '@fortawesome/pro-solid-svg-icons';

import { BaseButtonControl, BORDER_OPTIONS, MARKER_OPTIONS, TOOLTIP_OPTIONS } from '../common/base-button-control';

class RadiusSelectButtonControl extends BaseButtonControl {
  constructor(searchButtonsControl) {
    super(searchButtonsControl);

    this._drawing = false;
  }

  get iconDef() { return faDrawCircle; }
  get title() { return 'Radius Select'; }

  cancel() {
    this.clearLayerGroup();
    this._drawing = false;
    this.toggled = false;
  }

  onToggledChanged(toggled) {
    if (toggled) {
      this.addEvent('mousedown', this._mapMouseDown);
      this.addEvent('mousemove', this._mapMouseMove);
      this.addEvent('mouseup', this._mapMouseUp);
    } else {
      this.removeEvent('mousedown', this._mapMouseDown);
      this.removeEvent('mousemove', this._mapMouseMove);
      this.removeEvent('mouseup', this._mapMouseUp);
    }
  }

  _getTooltip(radius) {
    return this.getDescriptionString('Radius', this.getDistanceString(radius));
  }

  _mapMouseDown(e) {
    if (this.ignoreMouseEvents) {
      return;
    }

    this._drawing = true;

    const layerGroup = this.layerGroup;
    // Add maker layer
    const marker = L.circleMarker(e.latlng, MARKER_OPTIONS);
    marker.bindTooltip(this._getTooltip(0), TOOLTIP_OPTIONS);
    layerGroup.addLayer(marker);
    // Add border layer
    const border = L.circle(e.latlng, { ...BORDER_OPTIONS, radius: 0 });
    layerGroup.addLayer(border);
  }

  _mapMouseMove(e) {
    if (this.ignoreMouseEvents || !this._drawing) {
      return;
    }

    const layers = this.layerGroup.getLayers();
    if (layers.length === 0) {
      return;
    }

    // Update border layer
    const border = layers[layers.length - 1];
    const center = border.getLatLng();
    const edge = L.latLng(center.lat, e.latlng.lng);
    const radius = GeometryUtil.length([center, edge]);
    border.setRadius(radius);
    // Update marker layer
    const marker = layers[layers.length - 2];
    marker.setLatLng(edge);
    marker.setTooltipContent(this._getTooltip(radius));
  }

  _mapMouseUp(e) {
    if (this.ignoreMouseEvents) {
      return;
    }

    this._drawing = false;

    const layerGroup = this.layerGroup;
    const layers = layerGroup.getLayers();
    if (layers.length > 0) {
      const border = layers[layers.length - 1];
      const latLng = border.getLatLng();
      const radius = border.getRadius();

      if (radius > 0) {
        for (var loop = 0; loop < layers.length - 1; loop++) {
          layerGroup.removeLayer(layers[loop]);
        }

        if (typeof this.onComplete === 'function') {
          this.onComplete(latLng, radius);
        }
      } else {
        this.clearLayerGroup();
      }
    }

    //TODO: added until we revisit 181 - Retain Select Tool Function
    this.toggled = false;
  }
}

export { RadiusSelectButtonControl };