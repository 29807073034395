import React from 'react';

const AutoUpdateSelectComponent = (props) => {
  const onChangeEntity = (e) => {
    props.selectEntity(Number(e.currentTarget.value));
  }

  return (
    <div className="entity-select">
      <h4 key={1}><small>Select Update Type:</small></h4>
      <select
        value={props.value === null ? '' : props.value}
        onChange={(e) => onChangeEntity(e)}>
        {props.entities.map((entity) => <option key={entity['key']} value={entity['key']}>{entity['value']}</option>)}
      </select>
    </div>

  );
};

export default AutoUpdateSelectComponent;

