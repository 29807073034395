import { getDate } from '../../../shared/helpers/date-helper';
import { objectToEnum } from '../../../shared/helpers/common';

const differenceTypes = objectToEnum({
  NewRecord: 1,
  NewAttribute: 2,
  Edit: 3,
  RemovedRecord: 4,
  RemovedAtribute: 5
}, false);

differenceTypes.getDisplayString = (value) => {
  switch (value) {
    case differenceTypes.NewRecord:
      return 'New Record';
    case differenceTypes.NewAttribute:
      return 'New Attribute';
    case differenceTypes.Edit:
      return 'Edit';
    case differenceTypes.RemovedRecord:
      return 'Removed Record';
    case differenceTypes.RemovedAtribute:
      return 'Removed Attribute';
    default:
      return 'Unknown';
  }
};

class Entities {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Entities.ctor'); }
    if (obj !== null) {
      if (typeof obj.entityTypeId !== 'number') { throw new Error('Invalid obj.entityTypeId param supplied to Entities.ctor'); }
      if (typeof obj.entityType !== 'string') { throw new Error('Invalid obj.entityType param supplied to Entities.ctor'); }
    }

    this.entityTypeId = obj === null ? 0 : obj.entityTypeId;
    this.entityType = obj === null ? '' : obj.entityType;
  }
}

class DifferenceAttributes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Differences.ctor'); }
    if (obj !== null) {
      if (typeof obj.differenceId !== 'number') { throw new Error('Invalid obj.differenceId param supplied to Differences.ctor'); }
      if (typeof obj.entityTypeId !== 'number') { throw new Error('Invalid obj.entityTypeId param supplied to Differences.ctor'); }
      if (typeof obj.differenceType !== 'number') { throw new Error('Invalid obj.differenceType param supplied to Differences.ctor'); }
      if (typeof obj.uidValue !== 'string') { throw new Error('Invalid obj.uIDValue param supplied to Differences.ctor'); }
      if (typeof obj.nameValue !== 'string') { throw new Error('Invalid obj.nameValue param supplied to Differences.ctor'); }
      if (typeof obj.attributeName !== 'string') { throw new Error('Invalid obj.attributeName param supplied to Differences.ctor'); }
      if (obj.oldValue !== null && typeof obj.oldValue !== 'string') { throw new Error('Invalid obj.oldValue param supplied to Differences.ctor'); }
      if (obj.newValue !== null && typeof obj.newValue !== 'string') { throw new Error('Invalid obj.newValue param supplied to Differences.ctor'); }
      if (obj.newDate !== null && typeof obj.newDate !== 'string') { throw new Error('Invalid obj.newDate param supplied to Differences.ctor'); }
      if (obj.oldDate !== null && typeof obj.oldDate !== 'string') { throw new Error('Invalid obj.oldDate param supplied to Differences.ctor'); }
    }

    this.differenceId = obj === null ? 0 : obj.differenceId;
    this.entityTypeId = obj === null ? 0 : obj.entityTypeId;
    this.differenceType = obj === null ? 'Unknown' : differenceTypes.getDisplayString(obj.differenceType);
    this.uidValue = obj === null ? '' : obj.uIDValue;
    this.nameValue = obj === null ? '' : obj.nameValue;
    this.attributeName = obj === null ? '' : obj.attributeName;
    this.oldValue = obj === null ? '' : obj.oldValue;
    this.newValue = obj === null ? '' : obj.newValue;
    this.newDate = obj === null ? '' : getDate(obj.newDate);
    this.oldDate = obj === null ? '' : getDate(obj.oldDate);
  }
}

export { DifferenceAttributes, Entities };
