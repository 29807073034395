import React from 'react';
import PropTypes from 'prop-types';
import CommentsComponent from '../../../../common/components/comments/comments-component';
import { GMatrixGenericComment } from '../../../../common/models/generic-comment';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import TabPanelComponent, { Tab } from '../../../../shared/components/tab-panel/tab-panel-component';
import SplitterPanelComponent from '../../../../shared/components/splitter-panel/splitter-panel-component';
import BlocksComponent from './blocks-component';
import { LicenceBlock } from '../../models/result';

const BottomPanelComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    comments,
    commentActions,
    blocks,
    entityId
  } = props;

  let selectedTab = 0;
  const leftTab = [];
  const rightTab = [];

  leftTab.push(new Tab('Blocks', <BlocksComponent editing={editing} blocks={blocks} entityId={entityId} />));

  rightTab.push(new Tab('Comments', <CommentsComponent editing={editing} messageBoxGlobal={messageBoxGlobal} comments={comments} commentActions={commentActions} />));

  return (
    <SplitterPanelComponent vertical percentage secondarySize={50}>
      <TabPanelComponent align="top" toolbarItems={leftTab} selectedItemIndex={selectedTab} />
      <TabPanelComponent align="top" toolbarItems={rightTab} selectedItemIndex={selectedTab} />
    </SplitterPanelComponent>
  );
};

BottomPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  comments: PropTypes.arrayOf(PropTypes.instanceOf(GMatrixGenericComment)).isRequired,
  commentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  blocks: PropTypes.arrayOf(PropTypes.instanceOf(LicenceBlock)).isRequired,
  entityId: PropTypes.number.isRequired
};

export default BottomPanelComponent;
