import React from 'react';
import PropTypes from 'prop-types';
//import moment from 'moment';
import { getDate, isDateValid, formatDate } from '../../helpers/date-helper';

import './property-grid-component.scss';
import PropertyGridTableComponent, { RowPropType, PropertyGridBaseValue, PropertyGridStringValue, PropertyGridDateValueUTC, PropertyGridDateValue, PropertyGridNumberValue, PropertyGridNumberValueDouble, PropertyGridPercentageValue, PropertyGridSelectValue, PropertyGridBooleanValue } from './property-grid-table-component';
import { appendClassNames } from '../../helpers/common';
import TreeViewComponent, { TreeNode } from '../tree-view/tree-view-component';

const validateDate = (theDate) => {
  //return (moment(theDate, 'YYYY-MM-DD').isValid() && theDate.indexOf('_') === -1) || theDate === undefined;
  return (isDateValid(theDate) && theDate.indexOf('_') === -1) || theDate === undefined;
};

const PropertyGridComponent = (props) => {
  let { readOnly, narrow } = props;

  if (narrow === undefined || narrow === null) narrow = false;
  const toNode = (section) => {
    const result = new TreeNode(section.label);
    result.isParent = true;
    const child = typeof section.customRenderRows === 'function'
      ? section.customRenderRows()
      : <PropertyGridTableComponent readOnly={readOnly} className={section.className} rows={section.rows} narrow={narrow} />;
    result.addChild(child);
    return result;
  }

  const nodes = props.sections.map(obj => toNode(obj));

  const nodeClick = (node) => {
    if (node.isParent && nodes.length > 1) {
      node.collapsed = !node.collapsed;
    }
    return false;
  };

  return (
    <TreeViewComponent
      className={appendClassNames('property-grid-component', props.className)}
      nodes={nodes}
      labelClick={nodeClick}
      toggleClick={nodeClick}
    />
  );
};

const SectionPropType = PropTypes.shape({
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(RowPropType).isRequired,
  customRenderRows: PropTypes.func,
});

PropertyGridComponent.propTypes = {
  sections: PropTypes.arrayOf(SectionPropType).isRequired,
  readOnly: PropTypes.bool.isRequired,
  narrow: PropTypes.bool,
};

export default PropertyGridComponent;
export { validateDate, SectionPropType, PropertyGridBaseValue, PropertyGridStringValue, PropertyGridDateValueUTC, PropertyGridDateValue, PropertyGridNumberValue, PropertyGridNumberValueDouble, PropertyGridPercentageValue, PropertyGridSelectValue, PropertyGridBooleanValue, getDate, formatDate };
