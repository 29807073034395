import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import DateInputMaskComponent, { moment } from '../../../shared/components/date-picker/date-input-mask-component';
import TextareaAutosize from 'react-textarea-autosize';

import './date-text-generic-component.scss';

class DateTextGenericComponent extends React.Component {
  constructor(props) {
    super(props);

    const { editing, commentDate, commentText } = this.props;

    this.txtOnChange = this.txtOnChange.bind(this);
    this.onChangeDateToday = this.onChangeDateToday.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.fireOnChange = this.fireOnChange.bind(this);
    this.id = 1;

    this.state = {
      commentText: commentText,
      canEdit: editing,
      commentDate: commentDate
    };

  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        commentText: this.props.commentText,
        canEdit: this.props.editing,
        commentDate: this.props.commentDate
      });
    }
  }

  fireOnChange(newValue) {
    if (!this.props.editing) {
      return;
    }

    if (newValue === undefined) newValue = '____/__/__';

    if (typeof this.props.onChange === 'function' && this.props.value !== newValue) {
      this.setState({ commentDate: newValue });
      this.props.onChange(this.state.commentText, newValue);
    }
  }

  onChangeDateToday() {
    const now = new Date();
    this.setState({
      commentDate: now
    });
    this.props.onChange(this.state.commentText, now);
  }

  onEdit() {
    if (!this.props.editing) {
      return;
    }

    const { commentText, commentDate } = this.state;
    if (this.props.commentText !== commentText) {
      this.props.onChange(commentText, commentDate);
    }

  }

  txtOnChange(e) {
    this.setState({ commentText: e.target.value });
  }

  render() {
    const { commentText, canEdit, commentDate } = this.state;
    const theDate = commentDate === null ? '' : moment(commentDate).format("DD/MM/YYYY");
    const theCommentText = commentText === null ? '' : commentText;

    const showToday = this.props.readOnly ? " hide" : "";

    return (

      <div className="date-text-generic-comments-component">
        <div className="date-selector">
          <span>Date</span>
          <DateInputMaskComponent value={theDate} onChange={e => this.fireOnChange(e)} keepCharPositions disabled={!canEdit} onFocus={this.props.onFocus} />
          <Button className={"no-text first-button today-button" + showToday} size="sm" disabled={!canEdit} onClick={this.onChangeDateToday}>Today</Button>
        </div>
        <div className="text-area">
          <span>Comment</span>
          <TextareaAutosize className="comments-text" readOnly={!canEdit} value={theCommentText} onChange={this.txtOnChange} onBlur={this.onEdit} disabled={!canEdit} onFocus={this.props.onFocus} />
        </div>
      </div>

    );
  }
}

DateTextGenericComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  commentText: PropTypes.string,
  commentDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onFocus: PropTypes.func.isRequired
};

DateTextGenericComponent.defaultProps = {
  editing: false,
  commentText: '',
  readOnly: false,
  commentDate: '',
  onFocus: () => { }
};

export default DateTextGenericComponent;
