import React from 'react';
import PropTypes from 'prop-types';

import { HubEmissionOverride } from '../../models/hub-emission-override';
import ToolbarPanelComponent from '../../../shared/components/toolbar-panel/toolbar-panel-component';
import { Button } from 'reactstrap';
import TextEditor from './editors/text-editor';

import AGGridComponent, {
  COLUMN_SMALL,
  COLUMN_LARGE,
  COLUMN_WIDE,
  GeneralFormatter,
  DoubleFormatter,
  ReadOnlyNumberFormatter,
  ReadOnlyDoubleFormatter,
  GRIDTYPES
} from './ag-grid-component';
import { MessageBoxGlobal } from '../../../common/globals/globals';

const HubEmissionsOverridesGridComponent = (props) => {
  const { hubEmissionOverrides, editing, readOnly, canAdd, canDelete, onAdd, onDelete, selectedRows, lookups } = props;

  //var ForecastTypes = { "1": "Westwood Forecast", "2": "Constant CO2 Intensity", "3": "Constant CO2 Emissions" }; //TODO REPLACE WITH A LOOKUP PASSED FROM THE CONTROLLER
  //var OverrideTypes = { "1": "CO2", "2": "CH4" };  //TODO REPLACE WITH A LOOKUP PASSED FROM THE CONTROLLER

  const ForecastTypes = { };
  lookups.FutureEmissions.forEach(x => { ForecastTypes[x.key] = x.value; });

  const OverrideTypes = {};
  lookups.EmissionsOverrideType.forEach(x => { OverrideTypes[x.key] = x.value; });
  const columnDefs = [];
  columnDefs.push({ headerName: "", checkboxSelection: true, suppressHeaderMenuButton: true, field: "falseColumn", sortable: false, hide: !editing, editable: false, width: 43 });
  columnDefs.push({ headerName: "Year", suppressHeaderMenuButton: true, field: "year", sortable: false, editable: editing, width: COLUMN_SMALL, cellRenderer: readOnly ? ReadOnlyNumberFormatter : GeneralFormatter, type: 'numeric' });

  columnDefs.push({
    headerName: "Emissions Override Type", suppressHeaderMenuButton: true, cellEditorPopup: true, field: "hubEmissionsOverrideTypeId", sortable: false, filter: false, type: 'dropdown',
    suppressSizeToFit: true, singleClickEdit: true,
    editable: editing, width: COLUMN_SMALL, cellEditor: "agRichSelectCellEditor", cellEditorParams: {
      values: extractValues(OverrideTypes)
    },
    valueFormatter: function (params) {
      return lookupValue(OverrideTypes, params.value);
    },
    valueParser: function (params) {
      return lookupKey(OverrideTypes, params.newValue);
    }
  });

  columnDefs.push({
    headerName: "Future Emissions Method", suppressHeaderMenuButton: true, cellEditorPopup: true, field: "emissionsMethodId", sortable: false, type: 'dropdown',
    suppressSizeToFit: true, singleClickEdit: true,
    editable: editing, width: COLUMN_LARGE, cellEditor: "agRichSelectCellEditor", cellEditorParams: {
      values: extractValues(ForecastTypes)
    }
    ,
    valueFormatter: function (params) {
      return lookupValue(ForecastTypes, params.value);
    }
    ,
    valueParser: function (params) {
      return lookupKey(ForecastTypes, params.newValue);
    }
  });

  columnDefs.push({ headerName: "Override Value", suppressHeaderMenuButton: true, field: "overrideValue", sortable: true, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: readOnly ? ReadOnlyDoubleFormatter : DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Comment", cellEditor: TextEditor, suppressHeaderMenuButton: true, field: "emissionsOverrideComment", sortable: true, editable: editing, width: COLUMN_WIDE, /*cellRenderer: GeneralFormatter,*/ type: 'text' });

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {}
  };

  const numColumns = ["noneyet"];
  const numPositiveColumns = ["noneyet"];
  const numPercentColumns = ["noneyet"];
  const selectColumns = ["noneyet"];

  function extractValues(mappings) {
    return Object.keys(mappings);
  }

  function lookupValue(mappings, key) {
    return mappings[key];
  }

  function lookupKey(mappings, name) {
    for (var key in mappings) {
      if (mappings.hasOwnProperty(key)) {
        if (name === mappings[key]) {
          return key;
        }
      }
    }
  }

  const toolbarItems = [
    <Button title="Add Year" className="btn-add btn-image no-text first-button" size="sm" disabled={!editing || !canAdd} onClick={onAdd} />,
    <Button title="Delete Selected Year(s)" className="btn-delete btn-image no-text last-button" size="sm" disabled={!editing || !canDelete || selectedRows.length === 0} onClick={() => onDelete(selectedRows)} />
  ];

  const onRowSelected = e => {
    let selected = e.node.selected;
    props.selectedEmissionsOverrideRows(e.node.rowIndex, selected);
  }

  return (
    <ToolbarPanelComponent className="attribute-list-component" align="left" toolbarColor="secondary" toolbarItems={toolbarItems}>
      <AGGridComponent
        messageBoxGlobal={props.messageBoxGlobal}
        columnDefs={columnDefs}
        columnTypes={columnTypes}
        gridData={hubEmissionOverrides}
        rowsCount={hubEmissionOverrides.length}
        editing={editing}
        allowPaste
        numColumns={numColumns}
        numPercentColumns={numPercentColumns}
        numPositiveColumns={numPositiveColumns}
        selectColumns={selectColumns}
        rowSelected={onRowSelected}
        rowSelection="multiple"
        rowSelectionEnabled={false}
        gridType={GRIDTYPES.HUBEMISSIONSOVERRIDE}
        handleCalculatedValues={props.handleCalculatedValues}
        singleClickEdit={true}
      />
    </ToolbarPanelComponent>
  );

};

HubEmissionsOverridesGridComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  editing: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  hubEmissionOverrides: PropTypes.arrayOf(PropTypes.instanceOf(HubEmissionOverride)).isRequired,
  canAdd: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

HubEmissionsOverridesGridComponent.defaultProps = {
  readOnly: false,
  canAdd: true,
  canDelete: true,
  onAdd: () => { },
  onDelete: () => { },
};

export default HubEmissionsOverridesGridComponent;
export { HubEmissionOverride };

