class PrintModeGlobal {
  constructor(value, set, open, close) {
    if (typeof set !== 'function') {
      throw new Error('Invalid set param supplied to PrintModeGlobal.ctor');
    }
    if (typeof open !== 'function') {
      throw new Error('Invalid open param supplied to PrintModeGlobal.ctor');
    }
    if (typeof close !== 'function') {
      throw new Error('Invalid close param supplied to PrintModeGlobal.ctor');
    }

    this._value = value;
    this._set = set;
    this._open = open;
    this._close = close;
  }

  get value() { return this._value; };
  get set() { return this._set; };
  get open() { return this._open; };
  get close() { return this._close; };
}

export { PrintModeGlobal };