import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './production-view.scss';
// Production models
import { editHubAttributes } from './helpers/hub-attributes-helper';
import { editResourceAttributes } from './helpers/resource-attributes-helper';
import { ProductionUpdateType } from './models/production-update-type';
import { ProductionResult, EditLock } from './models/production-result';
import { ProductionPostbackInfo } from './models/production-postback-info';
// Production components
import BottomPanelComponent from './components/bottom-panel/bottom-panel-component';
import TopPanelComponent, { EditGridsAction } from './components/top-panel/top-panel-component';
// Common helpers
import { apiGet, apiPost, callApiDefaultOnFail } from '../../common/helpers/api-helpers';
import { addHubText, deleteHubText, editHubText } from '../../common/helpers/hub-text-helpers';
import { addHubComment, deleteHubComment, editHubComment } from '../../common/helpers/hub-comment-helpers';
import { addResourceText, deleteResourceText, editResourceText } from '../../common/helpers/resource-text-helpers';
// Common models
import { LookupResult } from '../../common/models/lookup-result';
import { RECORD_STATUS } from '../../common/models/record-status';
import { Views, ViewGlobal, IdGlobal, LoadingGlobal, MessageBoxGlobal, CountriesGlobal, EditingGlobal, SearchGlobal } from '../../common/globals/globals';
// Common components
import EditableViewComponent, { IssueType } from '../../common/components/editable-view/editable-view-component';
// Shared components
import { MessageBoxIcon } from '../../shared/components/message-box/message-box-classes';
import TabComponent from '../../shared/components/tab-panel/tab-component';
import SplitterPanelComponent from '../../shared/components/splitter-panel/splitter-panel-component';
import { ACTIVECOMPONENT } from '../../common/globals/globals';
import { HubEmissionOverride } from '../../common/models/hub-emission-override';

import { invalidateResourceAnnualValues, invalidateValues, resetHubAnnuals, resetResourceAnnuals, calculateDeclineValues, calculateCumulativeValues, changeFalseValueForYearCSS } from './production-view-helper-functions';
import { GMATRIX_ENTITY } from '../../common/models/gmatrix-entity';
import { RELATED_ITEM_TYPE } from '../../common/models/related-item-type';

import * as ViewFunctions from '../view-common/view-functions';

class ProductionView extends React.Component {
  constructor(props) {
    super(props);

    this.refresh = this.refresh.bind(this);
    this.beginEdit = this.beginEdit.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.resetEditTimeout = this.resetEditTimeout.bind(this);
    this.canHandleGridAction = this.canHandleGridAction.bind(this);
    this.handleGridAction = this.handleGridAction.bind(this);
    this.setProductionData = this.setProductionData.bind(this);
    this.canButtonsEnable = this.canButtonsEnable.bind(this);
    this.onChangeHubDescription = this.onChangeHubDescription.bind(this);
    this.onChangeHubSnapshotComment = this.onChangeHubSnapshotComment.bind(this);

    this.calculate = this.calculate.bind(this);
    this.updateSnapshotAudit = this.updateSnapshotAudit.bind(this);
    this.handleCalculatedValues = this.handleCalculatedValues.bind(this);
    this.resetPostBackAnnualValues = this.resetPostBackAnnualValues.bind(this);
    this.lookups = null;
    this.onGoalSeek = this.onGoalSeek.bind(this);

    //first move to generic view-functions - comments only firstly
    this.getActions = ViewFunctions.getActions.bind(this);
    this.setSelected = ViewFunctions.setSelected.bind(this);

    this.updateData = ViewFunctions.updateData.bind(this) //cant use this yet
    this.setDataInState = ViewFunctions.setDataInState.bind(this)

    this.state = {
      activeComponent: ACTIVECOMPONENT.GRID,
      productionData: null,
      showResourceGrid: true,
      showEmissionsGrid: false,
      canEnable: true,
      recordLocked: false,
      selectedStartYear: props.selectedStartYear,
      selectedEndYear: props.selectedEndYear,
      startYear: 0,
      endYear: 0,
      edited: false,
      selectedEmissionsOverrideRows: [],
      selectedItems: null
    };
  }

  get apiPrefix() { return 'api/production/' };
  get viewGlobal() { return this.props.viewGlobal; };
  get idGlobal() { return this.props.idGlobal; };
  get editingGlobal() { return this.props.editingGlobal; };
  get countriesGlobal() { return this.props.countriesGlobal; };
  get loadingGlobal() { return this.props.loadingGlobal; };
  get messageBoxGlobal() { return this.props.messageBoxGlobal; };
  get searchGlobal() { return this.props.searchGlobal; };
  get gmatrixEntity() { return GMATRIX_ENTITY.Production; }

  get currentResource() {
    const { productionData } = this.state;
    const result = productionData === null || productionData === undefined || !this.idGlobal.isSet
      ? undefined
      : productionData.resourceAttributes.find(r => r.resourceId === this.idGlobal.value);
    return result === undefined ? null : result;
  }
  get currentHub() {
    const { productionData } = this.state;
    const result = productionData === null || productionData === undefined
      ? undefined
      : productionData.hubAttributes;
    return result === undefined ? null : result;
  }

  componentDidMount() {
    const onSuccess = (result) => {
      this.lookups = new LookupResult(result);
    };
    apiGet(this.apiPrefix + 'lookups', this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
    this.initProductionData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idGlobal.value !== this.props.idGlobal.value) {
      this.initProductionData();
    }
    if (prevProps !== null && (prevProps.selectedStartYear !== this.props.selectedStartYear || prevProps.selectedEndYear !== this.props.selectedEndYear)) {
      this.setState({ selectedStartYear: this.props.selectedStartYear, selectedEndYear: this.props.selectedEndYear });
    }
  }

  canButtonsEnable(enable) {
    this.setState({ canEnable: enable });
  }

  initProductionData() {
    if (this.idGlobal.isSet) {
      if (this.currentResource === null) {
        this.getProductionData(false);
      }
    } else if (this.state.data !== null) {
      this.setProductionData(null);
    }
  }

  getPostbackInfo(forEdit, autoClose) {
    if (typeof forEdit !== 'boolean') {
      throw new Error('Invalid forEdit param supplied to getPostbackInfo');
    }
    if (typeof autoClose !== 'boolean') {
      throw new Error('Invalid autoClose param supplied to getPostbackInfo');
    }

    const result = new ProductionPostbackInfo();
    result.productionPayload = this.state.productionData;
    result.countryIds = this.countriesGlobal.value;
    result.resourceId = this.idGlobal.value;
    result.forEdit = forEdit;
    result.autoClose = autoClose;
    return result;
  }

  setData(newData, callback = undefined) {
    let entity = newData === null ? null : new ProductionResult(newData);

    if (entity !== null && !(entity instanceof ProductionResult)) {
      throw new Error('Invalid newData param supplied to setData');
    }

    this.setDataInState(entity, callback);
  }

  setProductionData(newProductionData, callback = undefined, edited = false) {
    if (newProductionData !== null && !(newProductionData instanceof ProductionResult)) {
      throw new Error('Invalid newProductionData param supplied to setProductionData');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to setProductionData');
    }

    var recordLocked = false;
    if (newProductionData !== null && newProductionData.editLock !== null) {
      if (newProductionData.editLock.recordOtherUserLock === true) {
        var alert = "Record locked by " + newProductionData.editLock.userName + " - you cannot edit until the record is unlocked!";
        this.messageBoxGlobal.showMessage(alert, null, MessageBoxIcon.Information);
        recordLocked = true;
      }
    }

    let startYear = newProductionData !== null ? newProductionData.startYear : 0;
    let endYear = newProductionData !== null ? newProductionData.endYear : 0;

    if (newProductionData !== null)
      newProductionData = invalidateResourceAnnualValues(newProductionData);

    this.setState(prevState => {
      return prevState.productionData === newProductionData
        ? null
        : { productionData: newProductionData, canEnable: true, recordLocked: recordLocked, startYear: startYear, endYear: endYear, edited: edited }
    }, callback);
  }

  getProductionData(forEdit, callback = undefined) {
    if (typeof forEdit !== 'boolean') {
      throw new Error('Invalid forEdit param supplied to getProductionData');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to getProductionData');
    }

    const alertCallback = () => {
      if (typeof callback === 'function') {
        callback();
      }
      const { alert } = this.state.productionData;
      if (typeof alert === 'string' && alert.length > 0) {
        this.messageBoxGlobal.showMessage(alert, null, MessageBoxIcon.Information);
      }
    };

    let postbackInfo = this.getPostbackInfo(forEdit, false);
    if (postbackInfo.productionPayload !== null) {
      postbackInfo = this.resetPostBackAnnualValues(postbackInfo);
    }

    const onSuccess = (result) => this.setProductionData(new ProductionResult(result), alertCallback);
    const onFail = (e) => this.idGlobal.clear(() => callApiDefaultOnFail(e, this.messageBoxGlobal));
    apiPost(this.apiPrefix + 'load', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, onFail);
  }

  calculate(callback = undefined) {
    let postbackInfo = this.getPostbackInfo(false, false);

    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to handleGridAction');
    }

    const alertCallback = () => {
      if (typeof callback === 'function') {
        callback();
      }
      const { alert } = this.state.productionData;
      if (typeof alert === 'string' && alert.length > 0) {
        this.messageBoxGlobal.showMessage(alert, null, MessageBoxIcon.Information);
      }
    };

    if (postbackInfo.productionPayload !== null) {
      postbackInfo = this.resetPostBackAnnualValues(postbackInfo);
    }

    const onSuccess = (result) => this.setProductionData(new ProductionResult(result), alertCallback);
    apiPost(this.apiPrefix + 'new-calculate', JSON.stringify(postbackInfo), () => this.loadingGlobal.showLoading(0), this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
  }

  updateSnapshotAudit(callback = undefined) {
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to handleGridAction');
    }

    const alertCallback = () => {
      if (typeof callback === 'function') {
        callback();
      }
      const { alert } = this.state.productionData;
      if (typeof alert === 'string' && alert.length > 0) {
        this.messageBoxGlobal.showMessage(alert, null, MessageBoxIcon.Information);
      }
    };
    let postbackInfo = this.getPostbackInfo(false, false);

    if (postbackInfo.productionPayload !== null) {
      postbackInfo = this.resetPostBackAnnualValues(postbackInfo);
    }

    const onSuccess = (result) => this.setProductionData(new ProductionResult(result), alertCallback);
    apiPost(this.apiPrefix + 'snapshotaudit', JSON.stringify(postbackInfo), () => this.loadingGlobal.showLoading(0), this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
  }

  resetPostBackAnnualValues(postbackInfo) {
    let resourceAnnuals = postbackInfo.productionPayload.resourceAnnuals;
    let hubAnnuals = postbackInfo.productionPayload.hubAnnuals;
    resourceAnnuals = resetResourceAnnuals(resourceAnnuals);
    hubAnnuals = resetHubAnnuals(hubAnnuals);
    postbackInfo.productionPayload.resourceAnnuals = resourceAnnuals;
    postbackInfo.productionPayload.hubAnnuals = hubAnnuals;
    postbackInfo.productionPayload.alert = '';
    return postbackInfo;
  }

  handleCalculatedValues(editedYear) {
    //need to set all calculated values to null and then make them '-' in the cell renderer of the coldefs as currently a null or a dash wont be allowed due to validation and all the renderers used
    let currentProductionData = this.state.productionData;

    currentProductionData = invalidateValues(currentProductionData, editedYear, this.idGlobal.value);
    this.setState({ productionData: currentProductionData, edited: true, activeComponent: ACTIVECOMPONENT.GRID });
  }

  refresh() {
    // Get a fresh copy of the data
    this.getProductionData(this.editingGlobal.value);
  }

  beginEdit() {
    // Get a fresh copy of the data, and put us in edit mode
    this.getProductionData(true, () => this.editingGlobal.set(true));
  }

  saveEdit() {
    if (!this.canPerformAction('save')) {
      return;
    }

    let postbackInfo = this.getPostbackInfo(false, false);

    if (postbackInfo.productionPayload !== null) {
      postbackInfo = this.resetPostBackAnnualValues(postbackInfo);
    }

    const onSuccess = (result) => this.setProductionData(new ProductionResult(result), () => this.editingGlobal.set(false));
    apiPost(this.apiPrefix + 'save', JSON.stringify(postbackInfo), this.loadingGlobal.showSave, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
    document.removeEventListener('paste', this.handlePaste);
  }

  cancelEdit(autoClose = false) {
    let postbackInfo = this.getPostbackInfo(false, autoClose);
    if (postbackInfo.productionPayload !== null) {
      postbackInfo = this.resetPostBackAnnualValues(postbackInfo);
    }

    const onSuccess = (result) => this.setProductionData(new ProductionResult(result), () => this.editingGlobal.set(false));
    apiPost(this.apiPrefix + 'cancel', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
    document.removeEventListener('paste', this.handlePaste);
  }

  resetEditTimeout() {
    let postbackInfo = this.getPostbackInfo(false, false);
    if (postbackInfo.productionPayload !== null) {
      postbackInfo = this.resetPostBackAnnualValues(postbackInfo);
    }

    const onSuccess = (result) => {
      const newProductionData = new ProductionResult(this.state.productionData);
      newProductionData.editLock = new EditLock(result);
      this.setProductionData(newProductionData);
    };
    apiPost(this.apiPrefix + 'reset', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
  }

  canHandleGridAction(action) {
    if (!this.editingGlobal.value) {
      return false;
    }

    const buttons = this.state.showResourceGrid ? this.currentResource.buttons : this.currentHub.buttons;
    switch (action) {
      case EditGridsAction.addStart: return buttons.allowInsertStart;
      case EditGridsAction.deleteStart: return buttons.allowDeleteStart;
      case EditGridsAction.moveStart: return buttons.allowMoveStart;
      //case EditGridsAction.moveEnd: return buttons.allowMoveEnd; //if hub and the resource.enableDeclineCurve = true then true
      //case EditGridsAction.deleteEnd: return buttons.allowDeleteEnd; //if hub and the resource.enableDeclineCurve = true then true
      //case EditGridsAction.addEnd: return buttons.allowInsertEnd; //if hub and the resource.enableDeclineCurve = true then true
      case EditGridsAction.moveEnd: return !this.state.showResourceGrid && this.currentResource.enableDeclineCurve ? true : buttons.allowMoveEnd;
      case EditGridsAction.deleteEnd: return !this.state.showResourceGrid && this.currentResource.enableDeclineCurve ? true : buttons.allowDeleteEnd;
      case EditGridsAction.addEnd: return !this.state.showResourceGrid && this.currentResource.enableDeclineCurve ? true : buttons.allowInsertEnd;
      case EditGridsAction.refresh: return true;
      default: throw new Error('Unsupported action (' + action + ') supplied to canHandleGridAction.canHandleAction()');
    }
  }

  handleGridAction(updateType, action, numYears, callback = undefined) {
    if (!Object.values(ProductionUpdateType).includes(updateType)) {
      throw new Error('Invalid updateType param supplied to handleGridAction');
    }
    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to handleGridAction');
    }

    const url = this.apiPrefix + 'grid-change?gmatrixEntity='
      + (updateType === ProductionUpdateType.Resource ? GMATRIX_ENTITY.Resource : GMATRIX_ENTITY.Hub)
      + '&theID='
      + (updateType === ProductionUpdateType.Resource ? this.currentResource.resourceId : this.currentHub.hubId)
      + '&gridAction='
      + action
      + '&numYears='
      + numYears;
    var postbackInfo = this.getPostbackInfo(false, false);
    if (postbackInfo.productionPayload !== null) {
      postbackInfo = this.resetPostBackAnnualValues(postbackInfo);
    }
    const onSuccess = (result) => this.setProductionData(new ProductionResult(result), callback, true);
    apiPost(url, JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
  }

  canPerformAction(action) {
    if (typeof action !== 'string' || action.length === 0) {
      throw new Error('Invalid action param supplied to canPerformAction');
    }

    if (this.state.productionData.issues.filter(e => e.issueTypeId === IssueType.error).length > 0) {
      this.messageBoxGlobal.showError('You cannot ' + action + '  until you have cleared all errors!');
      return false;
    }
    return true;
  }

  getCurrentResourceAttributeActions() {
    let currentProductionData = this.state.productionData;

    return {
      onEdit: (item) => {
        editResourceAttributes(item);
        currentProductionData.ResourceProductionAttributes = item;

        if (item.enableDeclineCurve === false) {
          currentProductionData.resourceAnnuals.forEach((res) => {
            if (res['isAutoAbex'] === false) res['isAutoGenerated'] = false;
          });
        }

        this.setState({ productionData: currentProductionData, edited: true, activeComponent: ACTIVECOMPONENT.NONGRID });
      }
    };
  }

  getHubAttributesActions() {
    let currentProductionData = this.state.productionData;

    return {
      onEdit: (item) => {
        editHubAttributes(item);
        currentProductionData.HubProductionAttributes = item;
        this.setState({ productionData: currentProductionData, edited: true, activeComponent: ACTIVECOMPONENT.NONGRID });
      }
    };
  }

  getHubOverrideActions() {
    return {
      onAdd: () => {
        let newHubOverride = new HubEmissionOverride();
        newHubOverride.hubId = this.currentHub.hubId;
        let currentProductionData = this.state.productionData;
        currentProductionData.hubEmissionOverrides.push(newHubOverride);
        this.setState({ productionData: currentProductionData, selectedEmissionsOverrideRows: [], edited: true });
      },
      onDelete: (selectedIndexes) => {
        let currentProductionData = this.state.productionData;

        for (var i = selectedIndexes.length - 1; i >= 0; i--)
          currentProductionData.hubEmissionOverrides.splice(selectedIndexes[i], 1);

        this.setState({ productionData: currentProductionData, selectedEmissionsOverrideRows: [], edited: true });
      },
      onSelectEmissionOverrideRow: (index, selected) => {
        let selectedEmissionsOverrideRows = this.state.selectedEmissionsOverrideRows;
        if (selected) selectedEmissionsOverrideRows.push(index);
        else {
          var selectedIndex = selectedEmissionsOverrideRows.indexOf(index);
          selectedEmissionsOverrideRows.splice(selectedIndex, 1);
        }
        this.setState({ selectedEmissionsOverrideRows: selectedEmissionsOverrideRows, edited: true });
      }
    };
  }

  getCurrentResourceDescriptionsActions(currentResource) {
    return {
      onAdd: (textCategoryId) => {
        addResourceText(currentResource.resourceDescriptiveText, currentResource.resourceId, textCategoryId);
        this.forceUpdate();
      },
      onDelete: (item) => {
        deleteResourceText(currentResource.resourceDescriptiveText, item);
        this.forceUpdate();
      },
      onEdit: (item) => {
        editResourceText(item);
        this.forceUpdate();
      }
    };
  }

  getHubCommentActions(currentHub) {
    return {
      onAdd: () => {
        addHubComment(currentHub.hubComments, currentHub.hubId);
        this.forceUpdate();
      },
      onDelete: (item) => {
        deleteHubComment(currentHub.hubComments, item);
        this.forceUpdate();
      },
      onEdit: (item) => {
        editHubComment(item);
        this.forceUpdate();
      }
    };
  }

  getHubDescriptionActions(currentHub) {
    return {
      onAdd: (textCategoryId) => {
        addHubText(currentHub.hubDescriptiveText, currentHub.hubId, textCategoryId);
        this.forceUpdate();
      },
      onDelete: (item) => {
        deleteHubText(currentHub.hubDescriptiveText, item);
        this.forceUpdate();
      },
      onEdit: (item) => {
        editHubText(item);
        this.forceUpdate();
      }
    };
  }

  onChangeHubDescription(text, date) {
    let currentProductionData = this.state.productionData;
    currentProductionData.hubAttributes.clientChangeDescription = text;
    currentProductionData.hubAttributes.clientChangeDescriptionDate = moment(date).toDate();
    currentProductionData.hubAttributes.status = RECORD_STATUS.edited;
    this.setState({ productionData: currentProductionData });
  }

  onChangeHubSnapshotComment(text, date) {
    let currentProductionData = this.state.productionData;
    currentProductionData.hubAttributes.snapshopComment = text;
    currentProductionData.hubAttributes.snapshotCommentDate = moment(date).toDate();
    currentProductionData.hubAttributes.status = RECORD_STATUS.edited;
    this.setState({ productionData: currentProductionData });
  }

  onGoalSeek = (e, callback = undefined) => {
    var buttonId = Number(e.currentTarget.id);

    if (callback !== undefined && typeof callback !== 'function') {
      throw new Error('Invalid callback param supplied to handleGridAction');
    }

    const alertCallback = () => {
      if (typeof callback === 'function') {
        callback();
      }
      const { alert } = this.state.productionData;
      if (typeof alert === 'string' && alert.length > 0) {
        this.messageBoxGlobal.showMessage(alert, null, MessageBoxIcon.Information);
      }
    };

    const url = this.apiPrefix + 'goal-seek?theId='
      + this.currentResource.resourceId
      + '&goalSeekType='
      + buttonId;
    var postbackInfo = this.getPostbackInfo(false, false);
    if (postbackInfo.productionPayload !== null) {
      postbackInfo = this.resetPostBackAnnualValues(postbackInfo);
    }
    const onSuccess = (result) => this.setProductionData(new ProductionResult(result), alertCallback);
    apiPost(url, JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
  }

  render() {
    const currentResource = this.currentResource;

    if (currentResource === null || this.lookups === null) {
      return <div className="view-panel no-data"><h1>Please select a resource.</h1></div>;
    }

    const { productionData, showResourceGrid, canEnable, startYear, endYear, recordLocked, showEmissionsGrid, selectedEmissionsOverrideRows } = this.state;

    //const selectedStartYear = this.state.selectedStartYear === 0 ? startYear : this.state.selectedStartYear;
    //const selectedEndYear = this.state.selectedEndYear === 0 ? endYear : this.state.selectedEndYear;
    const selectedStartYear = this.state.selectedStartYear;
    const selectedEndYear = this.state.selectedEndYear;
    const allResourceAttributes = productionData.resourceAttributes.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const currentResourceAttributes = currentResource;
    const allResourceAnnuals = productionData.resourceAnnuals.filter(obj => obj.status !== RECORD_STATUS.deleted);

    let currentResourceAnnuals = allResourceAnnuals.filter(obj => obj.resourceId === currentResourceAttributes.resourceId).sort((a, b) => a.year - b.year);

    currentResourceAnnuals = calculateCumulativeValues(currentResourceAnnuals);
    currentResourceAnnuals = calculateDeclineValues(currentResourceAnnuals);

    currentResourceAnnuals = changeFalseValueForYearCSS(currentResourceAnnuals);

    let hubAnnuals = productionData.hubAnnuals.filter(obj => obj.status !== RECORD_STATUS.deleted).sort((a, b) => a.year - b.year);
    hubAnnuals = changeFalseValueForYearCSS(hubAnnuals);
    let hubEmissionAnnuals = productionData.hubEmissionAnnuals;
    let hubEmissionOverrides = productionData.hubEmissionOverrides;
    let productionEmissions = productionData.productionEmissions;
    let emissionsIntensity = productionData.emissionsIntensity;
    const hubAttributes = productionData.hubAttributes;
    const currentResourceComments = currentResourceAttributes.resourceComments.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const currentResourceProductionComments = currentResourceAttributes.productionComments.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const currentResourceSnapshotComments = currentResourceAttributes.resourceSnapshotComments;
    const currentResourceDescriptions = currentResourceAttributes.resourceDescriptiveText.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const currentResourceReservesHistory = currentResourceAttributes.reservesHistory;
    const allCurrentResourceSnapshotAnnuals = productionData.resourceSnapshotAnnuals.filter(obj => obj.resourceId === currentResourceAttributes.resourceId).sort((a, b) => a.year - b.year);
    const currentResourceSnapshotHistory = currentResourceAttributes.resourceSnapshotHistory;
    const hubComments = hubAttributes.hubComments.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const hubDescriptions = hubAttributes.hubDescriptiveText.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const editTimeout = this.editingGlobal.value && productionData.editLock !== undefined && productionData.editLock !== null ? moment(productionData.editLock.expiryTime).toDate() : undefined;

    const shortcuts = [
      { label: 'Open in ' + Views.displayName(Views.Resource), onClick: () => this.viewGlobal.setAndId(Views.Resource, this.idGlobal.value, currentResource.resourceName, () => this.searchGlobal.search(currentResource.resourceName, false)) }
    ];

    const toolbarItems = [
      <TabComponent selected={showResourceGrid} onClick={() => this.setState({ showResourceGrid: true, showEmissionsGrid: false })}>{currentResourceAttributes.resourceName}</TabComponent>,
      <TabComponent selected={!showResourceGrid && !showEmissionsGrid} onClick={() => this.setState({ showResourceGrid: false, showEmissionsGrid: false })}>{hubAttributes.hubName + ' Hub Cashflow'}</TabComponent>,
      <TabComponent selected={showEmissionsGrid} onClick={() => this.setState({ showResourceGrid: false, showEmissionsGrid: true })}>{hubAttributes.hubName + ' Hub Emissions'}</TabComponent>
    ];

    return (
      <EditableViewComponent
        className="view-panel production-view"
        loadingGlobal={this.loadingGlobal}
        messageBoxGlobal={this.messageBoxGlobal}
        editingGlobal={this.editingGlobal}
        issues={productionData.issues}
        canRefresh
        canEnable={canEnable}
        recordLocked={recordLocked}
        refresh={this.refresh}
        beginEdit={this.beginEdit}
        cancelEdit={this.cancelEdit}
        saveEdit={this.saveEdit}
        editTimeout={editTimeout}
        resetEditTimeout={this.resetEditTimeout}
        shortcuts={shortcuts}
        toolbarItems={toolbarItems}
        toolbarItemsPosition="before"
        startYear={startYear}
        endYear={endYear}
        selectedStartYear={selectedStartYear}
        selectedEndYear={selectedEndYear}
        yearStartChange={this.props.yearStartChange}
        yearEndChange={this.props.yearEndChange}
        updateSnapshotAudit={this.updateSnapshotAudit}
        edited={this.state.edited}
        calculate={this.calculate}
        isProduction={true}
        viewGlobal={this.props.viewGlobal}
        searchGlobal={this.props.searchGlobal}
        currentView={this.gmatrixEntity}
        currentId={this.currentResource.resourceId}//this.idGlobal.value
        canDelete={false}
        onDelete={() => { }}
        canCopyPin={false}
        onCopyPin={() => { }}
        showCalc
      >
        <SplitterPanelComponent vertical={false} percentage secondarySize={50}>
          <TopPanelComponent
            messageBoxGlobal={this.messageBoxGlobal}
            editing={this.editingGlobal.value}
            showResourceGrid={showResourceGrid}
            showEmissionsGrid={showEmissionsGrid}
            resourceAnnuals={currentResourceAnnuals}
            allResourceAnnuals={allResourceAnnuals}
            hubAnnuals={hubAnnuals}
            hubEmissionAnnuals={hubEmissionAnnuals}
            canHandleGridAction={this.canHandleGridAction}
            handleGridAction={this.handleGridAction}
            currentResourceID={this.currentResource.resourceId}
            canButtonsEnable={this.canButtonsEnable}
            canEnable={canEnable}
            selectedStartYear={selectedStartYear}
            selectedEndYear={selectedEndYear}
            editYear={currentResourceAttributes.lastManualInputRateYear}
            enableDeclineCurve={currentResourceAttributes.enableDeclineCurve}
            handleCalculatedValues={this.handleCalculatedValues}
            activeComponent={this.state.activeComponent}
          />
          <BottomPanelComponent
            editing={this.editingGlobal.value}
            showResourceGrid={showResourceGrid}
            messageBoxGlobal={this.messageBoxGlobal}
            allResourceAttributes={allResourceAttributes}
            allResourceAnnuals={allResourceAnnuals}
            currentResourceAttributes={currentResourceAttributes}
            currentResourceAttributeActions={this.getCurrentResourceAttributeActions()}
            currentResourceAnnuals={currentResourceAnnuals}
            currentResourceReservesHistory={currentResourceReservesHistory}
            currentResourceComments={currentResourceComments}
            currentResourceCommentActions={this.getActions(currentResourceAttributes.resourceComments, RELATED_ITEM_TYPE.RESOURCECOMMENT, currentResourceAttributes.resourceId)}
            currentResourceProductionComments={currentResourceProductionComments}
            currentResourceProductionCommentActions={this.getActions(currentResourceAttributes.productionComments, RELATED_ITEM_TYPE.PRODUCTIONCOMMENT, currentResourceAttributes.resourceId)}
            currentResourceDescriptions={currentResourceDescriptions}
            currentResourceDescriptionActions={this.getCurrentResourceDescriptionsActions(currentResource)}
            hubAnnuals={hubAnnuals}
            hubAttributes={hubAttributes}
            hubEmissionOverrides={hubEmissionOverrides}
            selectedEmissionsOverrideRows={selectedEmissionsOverrideRows}
            hubOverrideActions={this.getHubOverrideActions()}
            hubAttributeActions={this.getHubAttributesActions()}
            hubComments={hubComments}
            hubCommentActions={this.getHubCommentActions(hubAttributes)}
            hubDescriptions={hubDescriptions}
            hubDescriptionActions={this.getHubDescriptionActions(hubAttributes)}
            lookups={this.lookups}
            onChangeHubDescription={this.onChangeHubDescription}
            onChangeHubSnapshotComment={this.onChangeHubSnapshotComment}
            selectedStartYear={selectedStartYear}
            selectedEndYear={selectedEndYear}
            currentResourceSnapshotComments={currentResourceSnapshotComments}
            currentResourceSnapshotHistory={currentResourceSnapshotHistory}
            onSnapshotSelectionChanged={this.onSnapshotSelectionChanged}
            resourceSnapshotAnnuals={allCurrentResourceSnapshotAnnuals}
            onGoalSeek={this.onGoalSeek}
            handleCalculatedValues={this.handleCalculatedValues}
            productionEmissions={productionEmissions}
            emissionsIntensity={emissionsIntensity}
          />
        </SplitterPanelComponent>
      </EditableViewComponent>
    );
  }
}

ProductionView.propTypes = {
  viewGlobal: PropTypes.instanceOf(ViewGlobal).isRequired,
  idGlobal: PropTypes.instanceOf(IdGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  countriesGlobal: PropTypes.instanceOf(CountriesGlobal).isRequired,
  searchGlobal: PropTypes.instanceOf(SearchGlobal).isRequired,
  editingGlobal: PropTypes.instanceOf(EditingGlobal).isRequired
}

export default ProductionView;
