import React from 'react';
import PropTypes from 'prop-types';

import ResourceProductionChartComponent from './resource-charts/resource-production-chart-component';
import HubProductionShareChartComponent from './resource-charts/hub-production-share-chart-component';
import HubProductionChartComponent from '../shared/hub-production-chart-component';
import CashflowChart from '../../../../../common/components/chart/cashflow-chart';

import { ResourceAnnual } from '../../../models/production-result';
import TabPanelComponent, { Tab } from '../../../../../shared/components/tab-panel/tab-panel-component';

const ResourceChartsComponent = (props) => {
  const {
    currentResourceAnnuals,
    hubMinYear,
    hubMaxYear,
    maxCombinedValue2,
    allResourceAnnuals,
    hubAnnuals,
    allResourceAttributes
  } = props;

  const tabs = [
    new Tab('Field Production', <ResourceProductionChartComponent currentResourceAnnuals={currentResourceAnnuals} hubMinYear={hubMinYear} hubMaxYear={hubMaxYear} maxCombinedValue2={maxCombinedValue2} />),
    new Tab('Field Cashflow', <CashflowChart cashflowData={currentResourceAnnuals} xTitle="Year" yTitle="$mm" />),
    new Tab('Hub Production', <HubProductionChartComponent allResourceAnnuals={allResourceAnnuals} hubAnnuals={hubAnnuals} allResourceAttributes={allResourceAttributes} hubMinYear={hubMinYear} hubMaxYear={hubMaxYear} />),
    new Tab('Hub Production Share', <HubProductionShareChartComponent hubMinYear={hubMinYear} hubMaxYear={hubMaxYear} maxCombinedValue2={maxCombinedValue2} allResourceAnnuals={allResourceAnnuals} allResourceAttributes={allResourceAttributes}  />)
  ];

  return (
    <TabPanelComponent align="top" toolbarItems={tabs} selectedItemIndex={0} />
  );

};

ResourceChartsComponent.propTypes = {
  currentResourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  hubMinYear: PropTypes.number.isRequired,
  hubMaxYear: PropTypes.number.isRequired,
  maxCombinedValue2: PropTypes.number.isRequired
};

export default ResourceChartsComponent;