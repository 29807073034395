class WestwoodDataDPFField {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to WestwoodDataDPFField.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'string') { throw new Error('Invalid obj.Id param supplied to WestwoodDataDPFField.ctor'); }
      if (typeof obj.fieldName !== 'string') { throw new Error('Invalid obj.fieldName param supplied to WestwoodDataDPFField.ctor'); }
      if (obj.projectName !== undefined && obj.projectName !== null && typeof obj.projectName !== 'string') { throw new Error('Invalid obj.projectName param supplied to WestwoodDataDPFField.ctor'); }
      if (obj.country !== undefined && obj.country !== null && typeof obj.country !== 'string') { throw new Error('Invalid obj.country param supplied to WestwoodDataDPFField.ctor'); }
      if (obj.status !== undefined && obj.status !== null && typeof obj.status !== 'string') { throw new Error('Invalid obj.status param supplied to WestwoodDataDPFField.ctor'); }
      if (obj.fieldProduct !== undefined && obj.fieldProduct !== null && typeof obj.fieldProduct !== 'string') { throw new Error('Invalid obj.fieldProduct param supplied to WestwoodDataDPFField.ctor'); }
      if (obj.developmentStructure !== undefined && obj.developmentStructure !== null && typeof obj.developmentStructure !== 'string') { throw new Error('Invalid obj.licenceName param supplied to WestwoodDataDPFField.ctor'); }
      if (obj.developmentType !== undefined && obj.developmentType !== null && typeof obj.developmentType !== 'string') { throw new Error('Invalid obj.developmentType param supplied to WestwoodDataDPFField.ctor'); }
      if (obj.developmentSummary !== undefined && obj.developmentSummary !== null && typeof obj.developmentSummary !== 'string') { throw new Error('Invalid obj.developmentSummary param supplied to WestwoodDataDPFField.ctor'); }
      if (obj.blockNumber !== undefined && obj.blockNumber !== null && typeof obj.blockNumber !== 'string') { throw new Error('Invalid obj.blockNumber param supplied to WestwoodDataDPFField.ctor'); }
    }

    this.id = obj === null ? '' : obj.id;
    this.fieldName = obj === null ? '' : obj.fieldName ?? '';
    this.projectName = obj === null ? '' : obj.projectName ?? '';
    this.country = obj === null ? '' : obj.country ?? '';
    this.status = obj === null ? '' : obj.status ?? '';
    this.fieldProduct = obj === null ? '' : obj.fieldProduct ?? '';
    this.developmentStructure = obj === null ? '' : obj.developmentStructure ?? '';
    this.developmentType = obj === null ? '' : obj.developmentType ?? '';
    this.developmentSummary = obj === null ? '' : obj.developmentSummary ?? '';
    this.blockNumber = obj === null ? '' : obj.blockNumber ?? '';
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "WestwoodDataDPFField.FromArray"');
    }

    const result = array.map(obj => new WestwoodDataDPFField(obj));
    return result;
  }
}

export { WestwoodDataDPFField };
