export class HubAnnual {
  hubId = 0;
  hubAnnualId = 0;
  year = 0;
  fields = 0;
  liquidMbod = 0;
  gasMmscfd = 0;
  capexUSDMM = 0;
  opexChargeUsdMM = 0;
  opexReceiptsUsdMM = 0;
  abexUSDMM = 0;
  revenueUsdMM = 0;
  operatingCashFlowUsdMM = 0;
  preTaxCashFlowUsdMM = 0;
  cumulativePreTaxCashflowUsdMM = 0;
  corparateTaxUsdMM = 0;
  specialTaxUsdMM = 0;
  undiscountedPostTaxCashFlowUsdMM = 0;
  cumulativeUndiscountedPostTaxCashFlowUsdMM = 0;
  discountedPostTaxCashFlowUsdMM = 0;
  cumulativeDiscountedPostTaxCashFlowUsdMM = 0;
  comment = '';
  status = '';
  gasMboed = 0;
  updated = false;
  falseColumn = 'dontChange';
  combinedMboed = 0;
  etsChargeUsdMM = 0;
  carbonChargeUsdMM = 0;
  totalEmissionsChargeUSDMM = 0;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

}
