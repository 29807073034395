
const defaultOptions = {
  radius: 6,
  border: true,
  borderColor: '#3388ff',
  borderOpacity: 1,
  borderWidth: 2,
  fill: true,
  fillColor: '#3388ff',
  fillOpacity: 1,
  popup: undefined
};

class CircleMarkerModel {
  constructor(lat, lng, options = {}) {
    if (typeof lat !== 'number') { throw new Error('Invalid \'lat\' supplied to \'CircleMarkerModel.ctor\''); }
    if (typeof lng !== 'number') { throw new Error('Invalid \'lng\' supplied to \'CircleMarkerModel.ctor\''); }
    if (typeof options !== 'object') { throw new Error('Invalid \'options\' supplied to \'CircleMarkerModel.ctor\''); }

    this.lat = lat;
    this.lng = lng;
    this.options = { ...defaultOptions, ...options };

    this.compareShapes = CircleMarkerModel.compareShapes;
  }

  get latLng() { return [this.lat, this.lng]; }

  static compareShapes = (a, b) => {
    if (typeof a !== 'object' || typeof b !== 'object') {
      throw new Error('Invalid "CircleMarkerModel" param supplied to "CircleMarkerModel.compareShapes"');
    }

    if (a.lat !== b.lat) return false;
    if (a.lng !== b.lng) return false;

    return true;
  }

}

export { CircleMarkerModel };
