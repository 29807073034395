import React from 'react';
import PropTypes from 'prop-types';

// Custom Formatter component
class ReadOnlyNumberFormatter extends React.Component {
    static propTypes = {
        value: PropTypes.number
    };

    static defaultProps = {
        value: null
    };

    render() {
        const renderedVal = this.props.value === null ? '-' : this.props.value;

        return (
            <div className="readOnlyColumn">{renderedVal}</div>);
    }
}

export default ReadOnlyNumberFormatter;