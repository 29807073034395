import React from 'react';
import PropTypes from 'prop-types';

import { ResourceSnapshotComment } from '../../../../models/production-result';
import DateTextGenericComponent from '../../../../../../common/components/date-text-generic/date-text-generic-component';

const SnapshotCommentsComponent = (props) => {
  const {
    currentResourceSnapshotComments,
    resourceSnapshotId
  } = props;

  const snapshotComment = currentResourceSnapshotComments !== undefined && currentResourceSnapshotComments !== null && currentResourceSnapshotComments.length > 0
    ? currentResourceSnapshotComments.filter(obj => obj.resourceSnapshotId === resourceSnapshotId)
    : null; 

  const comment = snapshotComment !== undefined && snapshotComment !== null && snapshotComment.length > 0 ? snapshotComment[0].snapshotComment : null;
  const commentDate = snapshotComment !== undefined && snapshotComment !== null && snapshotComment.length > 0 ? snapshotComment[0].snapshotCommentDate : null;

  return <DateTextGenericComponent editing={false} commentText={comment} commentDate={commentDate} onChange={() => { }} readOnly={true} />;
}

SnapshotCommentsComponent.propTypes = {
  currentResourceSnapshotComments: PropTypes.arrayOf(PropTypes.instanceOf(ResourceSnapshotComment)).isRequired,
  resourceSnapshotId: PropTypes.number.isRequired
};

export default SnapshotCommentsComponent;