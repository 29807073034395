import { Issue } from '../../../common/models/issue';
import { EditLock } from '../../../common/models/edit-lock';
import { GMatrixGenericComment } from '../../../common/models/generic-comment';

class Attributes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Attributes.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to Attributes.ctor'); }
      if (typeof obj.displayName !== 'string') { throw new Error('Invalid obj.displayName param supplied to Attributes.ctor'); }
      if (obj.rigName !== null && typeof obj.rigName !== 'string') { throw new Error('Invalid obj.rigName param supplied to Attributes.ctor'); }
      if (obj.rigTypeId !== null && typeof obj.rigTypeId !== 'number') { throw new Error('Invalid obj.rigTypeId param supplied to Attributes.ctor'); }
      if (obj.imo !== null && typeof obj.imo !== 'number') { throw new Error('Invalid obj.imo param supplied to Attributes.ctor'); }
      if (obj.mmsi !== null && typeof obj.mmsi !== 'number') { throw new Error('Invalid obj.mmsi param supplied to Attributes.ctor'); }
      if (obj.westwoodLegacyRigId !== null && typeof obj.westwoodLegacyRigId !== 'number') { throw new Error('Invalid obj.westwoodLegacyRigId param supplied to Attributes.ctor'); }
      if (obj.westwoodDataId !== null && typeof obj.westwoodDataId !== 'string') { throw new Error('Invalid obj.westwoodDataId param supplied to Attributes.ctor'); }
      if (obj.comments !== null && !Array.isArray(obj.comments)) { throw new Error('Invalid obj.comments param supplied to Attributes.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.displayName = obj === null ? '' : obj.displayName;
    this.rigName = obj === null ? 0 : obj.rigName;

    this.rigTypeId = obj === null ? null : obj.rigTypeId;
    this.imo = obj === null ? null : obj.imo;
    this.mmsi = obj === null ? null : obj.mmsi;

    this.westwoodLegacyRigId = obj === null ? null : obj.westwoodLegacyRigId;
    this.westwoodDataId = obj === null ? null : obj.westwoodDataId;
    this.comments = (obj === null || obj.comments === null) ? [new GMatrixGenericComment(null)] : obj.comments.map(item => new GMatrixGenericComment(item));

    this.status = obj === null ? '' : obj.status;
    this.originalStatus = obj === null ? '' : obj.originalStatus;
  }
}

export class Result {
  constructor(obj) {
    obj && Object.assign(this, obj);
    this.attributes = obj === null || obj === undefined ? new Attributes(null) : new Attributes(obj.attributes);
    this.issues = obj === null || obj === undefined ? [new Issue()] : obj.issues.map(item => new Issue(item));
    this.editLock = obj === null || obj === undefined ? new EditLock() : new EditLock(obj.editLock);
  }
}

export { Issue, EditLock, Attributes };
