import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";

import './loading-component.scss';
import { LoadingImage } from './loading-image';

class LoadingComponent extends React.Component {
  constructor(props) {
    super(props);

    this.txtRef = null;

    this.state = { visible: this.props.visible};
  }

  componentDidUpdate() {
    this.focusTxtRef();
  }

  focusTxtRef = () => {
    if (this.props.visible && this.txtRef !== undefined && this.txtRef !== null && typeof this.txtRef.focus === 'function') {
      this.txtRef.focus();
    }
  }

  getCss() {
    switch (this.props.image) {
      case LoadingImage.Loading: return 'loading';
      case LoadingImage.Logo: return 'logo';
      case LoadingImage.Save: return 'save';
      default: throw new Error('Invalid "value" param supplied to "LoadingImage.getCss"');
    }
  }

  show(isVisible) {
    this.setState({ visible: isVisible });
  }

  render() {
    const { image } = this.props;
    const { visible } = this.state;
    const nodeRef = React.createRef(null);

    return (
      <React.Fragment>
        {visible && <div className={'loading-component ' + this.getCss()} />}
        <CSSTransition nodeRef={nodeRef} in={visible} timeout={500} classNames="loading-component-animation" unmountOnExit>
          <div id="loading-div">
            <img src={LoadingImage.getImageSrc(image)} alt="!" />
            <br />
            <input ref={ref => this.txtRef = ref} onBlur={this.txtRefOnBlur} onKeyDown={event => { event.preventDefault(); }} />
          </div>
        </CSSTransition>
      </React.Fragment>
    );
  }
}

LoadingComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  image: PropTypes.number.isRequired
};

export default LoadingComponent;
export { LoadingImage };
