import * as XLSX from 'xlsx';

class GenericExcelExportColumn {
  _displayName = null;
  _fieldName = null;
  _format = null;

  constructor(displayName, fieldName, format = undefined) {
    this.displayName = displayName;
    this.fieldName = fieldName;
    this.format = format;
  }

  get displayName() { return this._displayName; }
  set displayName(value) {
    if (typeof value !== 'string' || value.trim().length === 0) {
      throw new Error('Invalid "value" param supplied to "GenericExcelExportColumn.displayName.set"');
    }

    this._displayName = value;
  }

  get fieldName() { return this._fieldName; }
  set fieldName(value) {
    if (typeof value !== 'string' || value.trim().length === 0) {
      throw new Error('Invalid "value" param supplied to "GenericExcelExportColumn.fieldName.set"');
    }

    this._fieldName = value;
  }

  get format() { return this._format; }
  set format(value) {
    const validValue = value === undefined || typeof value === 'function' || (typeof value === 'string' && value.trim().length > 0);
    if (!validValue) {
      throw new Error('Invalid "value" param supplied to "GenericExcelExportColumn.format.set"');
    }

    this._format = value;
  }
}

class GenericExcelExport {
  constructor() {
    this.HEADER_COUNT = 1;
    this.SUMMARY_SHEET_NAME = 'Summary';
  }

  _addText = (text, rows) => {
    const line = {};
    line[0] = text;
    rows.push(line);
  }

  createWorkbook() {
    const result = XLSX.utils.book_new();
    return result;
  }

  getWorkbookSuffix() { return '.xlsx'; }

  writeWorkbook(workbook, fileName) {
    if (typeof fileName !== 'string') {
      throw new Error('Invalid "fileName" param supplied to "GenericExcelExport.writeWorkbook"');
    }
    XLSX.writeFile(workbook, fileName);
  }

  _writeSheetHeaders(dataRows, columns) {
    const line = {};
    for (var loop = 0; loop < columns.length; loop++) {
      const fieldName = columns[loop].fieldName;
      const displayName = columns[loop].displayName;
      line[fieldName] = displayName;
    }
    dataRows.unshift(line);
  }

  _writeTerms(date, rows) {
    this._addText('https://https://www.westwoodenergy.com/terms-and-conditions', rows);
    this._addText('Please review terms & conditions for usage of this data', rows);
    this._addText('Copyright Westwood Global Energy Group ' + date.getFullYear(), rows);

    this._addText('', rows);
  }

    _formatDataRow(dataRow, columns) {
    const result = {};
    for (var loop = 0; loop < columns.length; loop++) {
      const fieldName = columns[loop].fieldName;
      result[fieldName] = dataRow[fieldName];
    }
    return result;
  }

  writeSheet(workbook, sheetName, dataRows, columns) {
    if (typeof sheetName !== 'string') {
      throw new Error('Invalid "sheetName" param supplied to "BaseExcelExport.writeSheet"');
    }
    if (!Array.isArray(dataRows)) {
      throw new Error('Invalid "dataRows" param supplied to "BaseExcelExport.writeSheet"');
    }
    if (!Array.isArray(columns) || columns.filter(obj => !(obj instanceof GenericExcelExportColumn)).length > 0) {
      throw new Error('Invalid "columns" param supplied to "BaseExcelExport.writeSheet"');
    }

    const outputDataRows = dataRows.map(obj => this._formatDataRow(obj, columns));
    this._writeSheetHeaders(outputDataRows, columns);
    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = XLSX.utils.json_to_sheet(outputDataRows, { skipHeader: 1 });

    const sheet = workbook.Sheets[sheetName];
    for (var colLoop = 0; colLoop < columns.length; colLoop++) {
      var colPrefix = '';
      var colLetter = colLoop;

      if (colLoop >= 26 && colLoop < 53) {
        colLetter = colLoop - 26;
        colPrefix = 'A';
      }
      else if (colLoop >= 53 && colLoop < 78) {
        colLetter = colLoop - 53;
        colPrefix = 'B';
      }

      const letter = colPrefix + String.fromCharCode(colLetter + 65); // 65 === 'A';
      const column = columns[colLoop];

      if (typeof column.format !== undefined) {
        for (var rowLoop = 0; rowLoop < dataRows.length + this.HEADER_COUNT; rowLoop++) {
          const cell = sheet[letter + (rowLoop + this.HEADER_COUNT)];
          if (cell !== undefined) {
            if (typeof column.format === 'string') { // Set cell format
              cell.z = column.format;
            } else if (typeof column.format === 'function') { // Set cell value
              cell.v = column.format(dataRows[rowLoop]);
            }
          }
        }
      }
    }

    return sheet;
  }
}

export { GenericExcelExportColumn, GenericExcelExport };
