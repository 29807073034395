import React from 'react';
import PropTypes from 'prop-types';

import LeftPanelComponent from './left-panel/left-panel-component';
import RightPanelComponent from './right-panel/right-panel-component';

import { Attributes, ResourceAnnual } from '../../models/result';
import { LookupResult } from '../../../../common/models/lookup-result';
import { LoadingGlobal, MessageBoxGlobal, PinLocationGlobal } from '../../../../common/globals/globals';
import { StringLookupResult } from '../../../../common/models/string-lookup-result';
import SplitterPanelComponent from '../../../../shared/components/splitter-panel/splitter-panel-component';

const TopPanelComponent = (props) => {
  const {
    editing,
    loadingGlobal,
    messageBoxGlobal,
    pinLocationGlobal,
    lookups,
    attributes,
    resourceAttributesActions,
    resourceAnnuals,
    geoserverUrl,
    mapComponentName,
    contextLayerType,
    resourceAliasActions,
    lookupsWestwood,
  } = props;

  return (
    <SplitterPanelComponent vertical percentage secondarySize={50}>
      <LeftPanelComponent editing={editing} loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} lookups={lookups} attributes={attributes} resourceAttributesActions={resourceAttributesActions} resourceAliasActions={resourceAliasActions} lookupsWestwood={lookupsWestwood} />
      <RightPanelComponent messageBoxGlobal={messageBoxGlobal} loadingGlobal={loadingGlobal} pinLocationGlobal={pinLocationGlobal} geoserverUrl={geoserverUrl} mapComponentName={mapComponentName} contextLayerType={contextLayerType} resourceAnnuals={resourceAnnuals} attributes={attributes} />
    </SplitterPanelComponent>
  );
};

TopPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  pinLocationGlobal: PropTypes.instanceOf(PinLocationGlobal).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  attributes: PropTypes.instanceOf(Attributes).isRequired,
  resourceAttributesActions: PropTypes.shape({ onEdit: PropTypes.func.isRequired }).isRequired,
  resourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  mapComponentName: PropTypes.string.isRequired,
  contextLayerType: PropTypes.number.isRequired,
  lookupsWestwood: PropTypes.instanceOf(StringLookupResult).isRequired,
};

export default TopPanelComponent;
