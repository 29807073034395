import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';
import { NewWellDataResult } from '../../models/new-well-data-result';
import './popup-panel.scss';

const NewWellComponent = (props) => {
  const { newWellData, onClose } = props;

  const [errorPanel, setErrorPanel] = useState(null);
  const [westwoodWellProgrammesList, setWestwoodWellProgrammesList] = useState(null);
  const [selectedWellProgrammeId, setSelectedWellProgrammeId] = useState(null);

  let filterRef = null;
  let countryRef = null;
  let isUnassignedRef = null;
  let hasError = false;

  const onFilter = () => {
    let id = parseInt(countryRef.value);

    let gmWellProgrammes = newWellData.gmWellProgrammes.find(x => x.key === id);
    let filterValue = filterRef.value.trim();

    if (gmWellProgrammes === null || gmWellProgrammes === undefined) {
      setWestwoodWellProgrammesList(null);
      return;
    }

    let wwList = null;

    if (filterValue !== '') {
      wwList = gmWellProgrammes.value.filter(x => x.value.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0);
    }
    else {
      wwList = gmWellProgrammes === null ? null : gmWellProgrammes.value;
    }

    setWestwoodWellProgrammesList(wwList);
  }

  const onOkClick = () => {
    if (validate() === true) {
      props.onClose(false, selectedWellProgrammeId, countryRef.value, isUnassignedRef.checked);
    }
  }

  const validate = () => {
    hasError = false;
    let id = typeof selectedWellProgrammeId === 'number' ? selectedWellProgrammeId : null;

    if (countryRef.value === null || countryRef.value === -1 || countryRef.value.trim() === '') {
      setErrorPanel(<div className="error-panel">A Country must be selected!</div>);
      return false;
    }
    else if ((id === undefined || id === null) && isUnassignedRef.checked === false) {
      setErrorPanel(<div className="error-panel">A Well Programme must be selected unless the Well Programme is marked as Unassigned!</div>);
      return false;
    }
    else {
      setErrorPanel(null);
      return true;
    }
  }

  const onWellProgrammeClick = (value) => {
    let id = '';
    if (typeof value === 'number')
      id = value;

    if (id !== undefined && id !== null) {
      isUnassignedRef.checked = false;
      setSelectedWellProgrammeId(id);
    }
  }

  const onIsUnassignedClick = () => {
    setSelectedWellProgrammeId(null);
  }

  const getClassName = () => {
    return 'well-programmes' + (errorPanel !== null ? ' error' : '');
  }

  return (
    <div className="popup-panel-component">
      <div className="content new-well">

        {errorPanel}

        <div>
          <span className="span-text">Please select a Country to show the Well Programme list</span>
          <Input innerRef={ref => countryRef = ref} className="inputs" type="select" id="cbCountrySelect" bsSize="sm" onChange={() => onFilter()}>
            <option key={''} value={''}>{'- Select -'}</option>
            {newWellData.countries.map(obj => <option key={obj.key} value={obj.key}>{obj.value}</option>)}
          </Input>
        </div>

        <div>
          <span>
            <Button className="filter-button" size="sm" onClick={() => onFilter()} disabled={westwoodWellProgrammesList === null} >Filter</Button>
            Enter a Well Programme filter:
            <Input innerRef={ref => filterRef = ref} className="inputs textbox" type="text" id="well_programme_filter" onKeyDown={(e) => e.keyCode === 13 ? onFilter() : null} />
          </span>
        </div>

        <div>
          <span>Unassigned Well Programme
            <Input innerRef={ref => isUnassignedRef = ref} className="inputs checkbox" type="checkbox" id="is_unassigned" onClick={onIsUnassignedClick} />
          </span>
        </div>

        {westwoodWellProgrammesList === null ?
          null
          :
          <>
            <span className="span-text list-header">Wildcat Well Programmes:</span>
            <div className={getClassName()}>
              <ul className="labels">
                {westwoodWellProgrammesList.map(x =>
                  <li key={x.key} className={(x.key === selectedWellProgrammeId ? 'selected' : '')}>
                    <div className="label-name" onClick={() => onWellProgrammeClick(x.key)}>{x.value}</div></li>)
                }
              </ul>
            </div>
          </>
        }

      </div>
      <div className="buttons">
        <Button className="ok-button" size="md" onClick={() => onOkClick()}>Ok</Button>
        <Button className="cancel-button" size="md" onClick={() => onClose(true)}>Cancel</Button>
      </div>
    </div>
  );
};

NewWellComponent.propTypes = {
  newWellData: PropTypes.instanceOf(NewWellDataResult).isRequired,
  onClose: PropTypes.func.isRequired
};

export default NewWellComponent;
