const getDate = (value) => {
  // this function requires 'value' to be a string.
  // if it is then the length must be at least 10 chars
  // if the string is more than 10 chars then only the 1st 10 chars will be used
  // the 10 chars need to contain 2 separators, either - or / chars.
  // the resulting object is checked to confirm its a valid date & if yes it is returned
  // ASSUMPTION: the format of 'value' should be en-gb, e.g. 01/01/2001; 1st 2 chars are the day, next 2 are the month, last 4 are the year. 
  // Thats it, thats what this function does. If you want / need more please do feel free to code a replacement....

  if (value === null || value === undefined) return null;
  if (!(typeof value === 'string' || value instanceof String)) return null;
  if (value.length < 10) return null;

  let potentialDate = value.length > 10 ? value.substr(0, 10) : value;

  let splitChar = '';
  if (potentialDate.includes('-') && potentialDate.includes('/')) return null;
  if (potentialDate.includes('-')) splitChar = '-';
  if (potentialDate.includes('/')) splitChar = '/';

  let dateParts = potentialDate.split(splitChar);
  if (dateParts === null || dateParts === undefined || dateParts.length < 3) return null;

  let theDate = null;

  if (dateParts[0].length === 4) {
    theDate = new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2], 12, 0, 0, 0));
  }
  else {
    theDate = new Date(Date.UTC(dateParts[2], dateParts[1] - 1, dateParts[0], 12, 0, 0, 0));
  }

  if (!isDateValid(theDate)) return null;

  return theDate;
};

const isDateValid = (value) => {
  return isNaN(Date.parse(value)) ? false : true;
};

const formatDate = (value) => {
  if (value === null || value === undefined) return null;
  if (!isDateValid(value)) return null;

  let day = value.getUTCDate();
  let month = value.getUTCMonth() + 1; // Return Value is 0 indexed
  let year = value.getUTCFullYear();

  let dateString = day.toString().padStart(2, '0') + '/' + month.toString().padStart(2, '0') + '/' + year.toString();

  return dateString;
};

export { getDate, isDateValid, formatDate };