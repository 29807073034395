import { Issue } from '../../../common/models/issue';
import { EditLock } from '../../../common/models/edit-lock';
import { GMatrixGenericComment } from '../../../common/models/generic-comment';

class Attributes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Attributes.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to Attributes.ctor'); }
      if (typeof obj.displayName !== 'string') { throw new Error('Invalid obj.displayName param supplied to Attributes.ctor'); }
      if (typeof obj.round !== 'string') { throw new Error('Invalid obj.round param supplied to Attributes.ctor'); }
      if (typeof obj.countryId !== 'number') { throw new Error('Invalid obj.countryId param supplied to Attributes.ctor'); }
      if (obj.countryName !== null && typeof obj.countryName !== 'string') { throw new Error('Invalid obj.countryName param supplied to Attributes.ctor'); }
      if (obj.year !== null && typeof obj.year !== 'string') { throw new Error('Invalid obj.year param supplied to Attributes.ctor'); }
      if (typeof obj.blocksOffered !== 'number') { throw new Error('Invalid obj.blocksOffered param supplied to Attributes.ctor'); }
      if (typeof obj.blocksAppliedFor !== 'number') { throw new Error('Invalid obj.blocksAppliedFor param supplied to Attributes.ctor'); }
      if (typeof obj.blocksAwarded !== 'number') { throw new Error('Invalid obj.blocksAwarded param supplied to Attributes.ctor'); }
      if (typeof obj.totalAcreageAwarded !== 'number') { throw new Error('Invalid obj.totalAcreageAwarded param supplied to Attributes.ctor'); }
      if (typeof obj.companyApplications !== 'number') { throw new Error('Invalid obj.companyApplications param supplied to Attributes.ctor'); } 
      if (typeof obj.companiesAwarded !== 'number') { throw new Error('Invalid obj.companiesAwarded param supplied to Attributes.ctor'); } 
      if (typeof obj.companiesAwardedOperatorship !== 'number') { throw new Error('Invalid obj.companiesAwardedOperatorship param supplied to Attributes.ctor'); }
      if (typeof obj.licencesAwarded !== 'number') { throw new Error('Invalid obj.licencesAwarded param supplied to Attributes.ctor'); } 
      if (obj.licenceNumbers !== null && typeof obj.licenceNumbers !== 'string') { throw new Error('Invalid obj.licenceNumbers param supplied to Attributes.ctor'); }
      if (obj.term !== null && typeof obj.term !== 'string') { throw new Error('Invalid obj.term param supplied to Attributes.ctor'); }
      if (obj.expiryDates !== null && typeof obj.expiryDates !== 'string') { throw new Error('Invalid obj.expiryDates param supplied to Attributes.ctor'); }

      if (obj.comments !== null && !Array.isArray(obj.comments)) { throw new Error('Invalid obj.comments param supplied to Attributes.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.displayName = obj === null ? '' : obj.displayName;

    this.round = obj === null ? 0 : obj.round;
    this.countryId = obj === null ? 0 : obj.countryId;
    this.countryName = obj === null || obj.countryName === null ? '' : obj.countryName;
    this.year = obj === null ? 0 : obj.year;
    this.blocksOffered = obj === null ? 0 : obj.blocksOffered;
    this.blocksAppliedFor = obj === null ? 0 : obj.blocksAppliedFor;
    this.blocksAwarded = obj === null ? 0 : obj.blocksAwarded;
    this.totalAcreageAwarded = obj === null ? 0 : obj.totalAcreageAwarded;
    this.companyApplications = obj === null ? 0 : obj.companyApplications;
    this.companiesAwarded = obj === null ? 0 : obj.companiesAwarded;
    this.companiesAwardedOperatorship = obj === null ? 0 : obj.companiesAwardedOperatorship;
    this.licencesAwarded = obj === null ? 0 : obj.licencesAwarded;

    this.licenceNumbers = obj === null ? '' : obj.licenceNumbers;
    this.term = obj === null ? '' : obj.term;
    this.expiryDates = obj === null ? '' : obj.expiryDates;

    this.comments = (obj === null || obj.comments === null) ? [new GMatrixGenericComment(null)] : obj.comments.map(item => new GMatrixGenericComment(item));

    this.status = obj === null ? '' : obj.status;
    this.originalStatus = obj === null ? '' : obj.originalStatus;
  }
}

export class Result {
  constructor(obj) {
    obj && Object.assign(this, obj);
    this.attributes = obj === null || obj === undefined ? new Attributes(null) : new Attributes(obj.attributes);
    this.issues = obj === null || obj === undefined ? [new Issue()] : obj.issues.map(item => new Issue(item));
    this.editLock = obj === null || obj === undefined ? new EditLock() : new EditLock(obj.editLock);
  }
}

export { Issue, EditLock, Attributes };
