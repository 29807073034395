import { IN_DEV_MODE } from '../../shared/helpers/common';

const internalViews = IN_DEV_MODE
  ? {
    Home: 0,
    Production: 10,
    Resource: 20,
    Hub: 30,
    Well: 40,
    WellProgramme: 50,
    Block: 60,
    License: 70,
    LicenseRound: 80,
    Relinquishment: 90,
    Corporate: 100,

    HistoricProspect: 110,
    Rig: 120,
    RigContracts: 130,

    Queries: 500,
    Fiscal: 550,
    NSTACompare: 560,
    Spatial: 570,
    HubUpside: 575,
    EmissionsUploader: 580,
    NPDWellsCompare: 590,
    
    Variables: 600,

    HermesCompareView: 610,
    HermesManagementView: 620,

    AutoUpdateView: 630,

    PhilTest: 1000,
    JimTest: 1010,
    RobTest: 1020,
    Test: 1030,
  }
  : {
    // To make a view available in production, just uncomment it. *NOTE*, the test views should never be available in production!
    Home: 0,
    Production: 10,
    Resource: 20,
    Hub: 30,
    Well: 40,
    WellProgramme: 50,
    Block: 60,
    License: 70,
    LicenseRound: 80,
    Relinquishment: 90,
    Corporate: 100,

    //HistoricProspect: 110,
    Rig: 120,
    //RigContracts: 130,

    Queries: 500,
    Fiscal: 550,
    NSTACompare: 560,
    Spatial: 570,
    HubUpside: 575,
    EmissionsUploader: 580,

    //NPDWellsCompare: 590,
    //Variables: 600,

    HermesCompareView: 610,
    HermesManagementView: 620,
    AutoUpdateView: 630,

    //PhilTest: 1000,
    //JimTest: 1010,
    //RobTest: 1020,
    //Test: 1030,
  };
Object.freeze(internalViews);

const Views = {
  ...internalViews,
  values: () => Object.values(internalViews),
  isValid: (value) => Views.values().includes(value),
  displayName: (view) => {
    switch (view) {
      case Views.Home: return 'Home';
      case Views.Production: return 'Production';
      case Views.Resource: return 'Resource';
      case Views.NSTACompare: return 'NSTA Licence Compare';
      case Views.NPDWellsCompare: return 'NPD Wells';
      case Views.HermesCompareView: return 'Hermes Compare';
      case Views.HermesManagementView: return 'Hermes Management';
      case Views.AutoUpdateView: return 'Auto Updates';
      case Views.EmissionsUploader: return 'Emissions Uploader';
      case Views.Block: return 'Block';
      case Views.Hub: return 'Hub';
      case Views.Well: return 'Well';
      case Views.WellProgramme: return 'Well Programme';
      case Views.Corporate: return 'Corporate';
      case Views.License: return 'Licence';
      case Views.LicenseRound: return 'Licence Round';
      case Views.HistoricProspect: return 'Historic Prospect';
      case Views.Relinquishment: return 'Relinquishment';
      case Views.Rig: return 'Rig';
      case Views.RigContracts: return 'Rig Contracts';
      case Views.Variables: return 'Variables';
      case Views.Queries: return 'Queries';
      case Views.Fiscal: return 'Fiscal';
      case Views.Spatial: return 'Spatial';
      case Views.HubUpside: return 'Hub Upside';

      case Views.PhilTest: return 'DEV: Phil Test';
      case Views.JimTest: return 'DEV: Jim Test';
      case Views.RobTest: return 'DEV: Rob Test';
      case Views.Test: return 'DEV: Test';
      default: throw new Error('Unsupported view (' + view + ') supplied to Views.displayName()');
    }
  },
  showFilters: (view) => {
    switch (view) {
      case Views.Home: return false;
      case Views.Production: return true;
      case Views.Resource: return true;
      case Views.NSTACompare: return false;
      case Views.NPDWellsCompare: return false;
      case Views.HermesCompareView: return false;
      case Views.HermesManagementView: return false;
      case Views.AutoUpdateView: return false;
      case Views.EmissionsUploader: return false;
      case Views.Block: return true;
      case Views.Hub: return true;
      case Views.Well: return true;
      case Views.WellProgramme: return true;
      case Views.Corporate: return true;
      case Views.License: return true;
      case Views.LicenseRound: return true;
      case Views.HistoricProspect: return true;
      case Views.Relinquishment: return true;
      case Views.Rig: return true;
      case Views.RigContracts: return true;
      case Views.Variables: return false;
      case Views.Queries: return true;
      case Views.Fiscal: return false;
      case Views.Spatial: return false;
      case Views.HubUpside: return false;
        
      case Views.PhilTest: return true;
      case Views.JimTest: return true;
      case Views.RobTest: return true;
      case Views.Test: return true;
      default: throw new Error('Unsupported view (' + view + ') supplied to Views.showFilters()');
    }
  },
  canSelectSiblingWhileEditing: (view) => {
    if (!Views.showFilters(view)) {
      return false;
    }
    switch (view) {
      case Views.Home: return false;
      case Views.Production: return true;
      case Views.Resource: return false;
      case Views.NSTACompare: return false;
      case Views.NPDWellsCompare: return false;
      case Views.HermesCompareView: return false;
      case Views.HermesManagementView: return false;
      case Views.AutoUpdateView: return false;
      case Views.EmissionsUploader: return false;
      case Views.Block: return false;
      case Views.Hub: return false;
      case Views.Well: return false;
      case Views.WellProgramme: return false;
      case Views.Corporate: return false;
      case Views.License: return false;
      case Views.LicenseRound: return false;
      case Views.HistoricProspect: return false;
      case Views.Relinquishment: return false;
      case Views.Rig: return false;
      case Views.RigContracts: return false;
      case Views.Variables: return false;
      case Views.Queries: return false;
      case Views.Fiscal: return false;
      case Views.Spatial: return false;
      case Views.HubUpside: return false;

      case Views.PhilTest: return true;
      case Views.JimTest: return true;
      case Views.RobTest: return true;
      case Views.Test: return false;
      default: throw new Error('Unsupported view (' + view + ') supplied to Views.showFilters()');
    }
  },
  canAdd: (view) => {
    switch (view) {
      case Views.Home: return false;
      case Views.Production: return false;
      case Views.Resource: return true;
      case Views.NSTACompare: return false;
      case Views.NPDWellsCompare: return false;
      case Views.HermesCompareView: return false;
      case Views.HermesManagementView: return false;
      case Views.AutoUpdateView: return false;
      case Views.EmissionsUploader: return false;
      case Views.Block: return true;
      case Views.Hub: return true;
      case Views.Well: return true;
      case Views.WellProgramme: return true;
      case Views.Corporate: return true;
      case Views.License: return true;
      case Views.LicenseRound: return true;
      case Views.HistoricProspect: return true;
      case Views.Relinquishment: return false;
      case Views.Rig: return true;
      case Views.RigContracts: return true;
      case Views.Variables: return false;
      case Views.Queries: return false;
      case Views.Fiscal: return false;
      case Views.Spatial: return false;
      case Views.HubUpside: return false;

      case Views.PhilTest: return true;
      case Views.JimTest: return true;
      case Views.RobTest: return true;
      case Views.Test: return true;
      default: throw new Error('Unsupported view (' + view + ') supplied to Views.canAdd()');
    }
  },
  apiUrl: (view) => {
    switch (view) {
      case Views.Production: return 'api/app/production/';
      case Views.Resource: return 'api/app/resources/';
      case Views.NSTACompare: return 'api/nsta-data/';
      case Views.NPDWellsCompare: return 'api/nsta-data/';
      /*case Views.NPDWellsCompare: return 'api/npd-data';*/
      case Views.EmissionsUploader: return 'api/fileupload/loadTheFile/';
      case Views.Block: return 'api/app/blocks/';
      case Views.Hub: return 'api/app/hubs/';
      case Views.Well: return 'api/app/wells/';
      case Views.WellProgramme: return 'api/app/well-programmes/';
      case Views.Corporate: return 'api/app/corporate/';
      case Views.License: return 'api/app/licenses/';
      case Views.LicenseRound: return 'api/app/licenceRounds/';
      case Views.HistoricProspect: return 'api/app/historic-prospects/';
      case Views.Relinquishment: return 'api/app/relinquishment/';
      case Views.Rig: return 'api/app/rigs/';
      case Views.RigContracts: return 'api/app/rig-contracts/';
      case Views.Queries: return 'api/queries/basic-search';
      case Views.Fiscal: return 'api/portfolio/resource/basic-search/';
      case Views.Spatial: return 'api/app/spatial/';
      case Views.HubUpside: return 'api/app/hub-upside/';

      case Views.PhilTest: return 'api/app/production/';
      case Views.JimTest: return 'api/app/production/';
      case Views.RobTest: return 'api/app/production/';
      case Views.Test: return 'api/app/production/';
      default: throw new Error('Unsupported view (' + view + ') in Views.apiUrl()');
    }
  },
  reportOnly: (view) => {
    switch (view) {
      case Views.Home: return false;
      case Views.Production: return false;
      case Views.Resource: return false;
      case Views.NSTACompare: return false;
      case Views.NPDWellsCompare: return false;
      case Views.HermesCompareView: return false;
      case Views.HermesManagementView: return false;
      case Views.AutoUpdateView: return false;
      case Views.EmissionsUploader: return false;
      case Views.Block: return false;
      case Views.Hub: return false;
      case Views.Well: return false;
      case Views.WellProgramme: return false;
      case Views.Corporate: return false;
      case Views.License: return false;
      case Views.LicenseRound: return false;
      case Views.HistoricProspect: return false;
      case Views.Relinquishment: return false;
      case Views.Rig: return false;
      case Views.RigContracts: return false;
      case Views.Variables: return false;
      case Views.Queries: return true;
      case Views.Fiscal: return false;
      case Views.Spatial: return false;
      case Views.HubUpside: return false;

      case Views.PhilTest: return false;
      case Views.JimTest: return false;
      case Views.RobTest: return false;
      case Views.Test: return false;
      default: throw new Error('Unsupported view (' + view + ') supplied to Views.reportOnly()');
    }
  }
};
Object.freeze(Views);

class ViewGlobal {
  constructor(value, set, setAndId) {
    if (typeof set !== 'function') {
      throw new Error('Invalid set param supplied to ViewGlobal.ctor is invalid');
    }
    if (typeof setAndId !== 'function') {
      throw new Error('Invalid setViewAndId param supplied to ViewGlobal.ctor is invalid');
    }

    this._value = value;
    this._set = set;
    this._setAndId = setAndId;
  }

  get value() { return this._value; };
  get set() { return this._set; };
  get setAndId() { return this._setAndId; };
}

export { ViewGlobal, Views };
