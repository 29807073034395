import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';
import { NewPartBlockDataResult } from '../../models/new-part-block-data-result';
import './popup-panel.scss';

const NewPartBlockComponent = (props) => {
  const { newBlockData, onClose } = props;

  const [errorPanel, setErrorPanel] = useState(null);
  const [parentBlockList, setParentBlockList] = useState(null);
  const [selectedParentBlockId, setSelectedParentBlockId] = useState(-1);

  let filterRef = null;
  let countryRef = null;
  let isLicencedRef = null;

  const onFilter = () => {
    let value = countryRef.value;

    let index = isNaN(value) ? -1 : Number(value);
    setSelectedParentBlockId(-1);

    if (index <= 0) {
      setParentBlockList(null);
      return;
    }

    let parentBlocks = newBlockData.parentBlocks.find(x => x.key === Number(value));
    let filterValue = filterRef.value.trim();

    if (parentBlocks === null || parentBlocks === undefined) {
      setParentBlockList(null);
      return;
    }

    let pbList = null;

    if (filterValue !== '') {
      pbList = parentBlocks.value.filter(x => x.value.indexOf(filterValue) >= 0);
    }
    else {
      pbList = parentBlocks === null ? null : parentBlocks.value;
    }

    setParentBlockList(pbList);
  }

  const onOkClick = () => {
    if (validate() === true) {
      props.onClose(false, selectedParentBlockId, isLicencedRef.checked);
    }
  }

  const onParentBlockClick = (value) => {
    let id = isNaN(value) ? -1 : Number(value);
    if (id > 0) {
      setSelectedParentBlockId(id);
    }
  }

  const validate = () => {
    if (selectedParentBlockId < 1) {
      setErrorPanel(<div className="error-panel">A Parent Block must be selected!</div>);
      return false;
    }
    else
    {
      setErrorPanel(null);
      return true;
    }
  }

  return (
    <div className="popup-panel-component">
      <div className="content new-block">

        {errorPanel}

        <div>
          <span className="span-text">Please select a Country to show the Parent Block list</span>
          <Input innerRef={ref => countryRef = ref} className="inputs" type="select" id="cbCountrySelect" bsSize="sm" onChange={() => onFilter()}>
            <option key={-99} value={-99}>{'- Select -'}</option>
            {newBlockData.countries.map(obj => <option key={obj.key} value={obj.key}>{obj.value}</option>)}
          </Input>
        </div>

        <div>
          <span>
            <Button className="filter-button" size="sm" onClick={() => onFilter()} disabled={parentBlockList === null} >Filter</Button>
            Enter a Parent Block filter:
            <Input innerRef={ref => filterRef = ref} className="inputs textbox" type="text" id="parent_block_filter" onKeyDown={(e) => e.keyCode === 13 ? onFilter() : null } />
          </span>
        </div>

        <div>
          <span>Set new block Licenced
            <Input innerRef={ref => isLicencedRef = ref} className="inputs checkbox" type="checkbox" id="licence_status" />
          </span>
        </div>

        {parentBlockList === null ?
          null
          :
          <div className="parent-blocks">
            <ul className="labels">
              {parentBlockList.map(x =>
                <li key={x.key} className={(x.key === selectedParentBlockId ? 'selected' : '')}>
                <div className="label-name" onClick={() => onParentBlockClick(x.key)}>{x.value}</div></li>)
              }
            </ul>
          </div>
        }

      </div>
      <div className="buttons">
        <Button className="ok-button" size="md" onClick={() => onOkClick()} disabled={selectedParentBlockId < 1}>Ok</Button>
        <Button className="cancel-button" size="md" onClick={() => onClose(true)}>Cancel</Button>
      </div>
    </div>
  );
};

NewPartBlockComponent.propTypes = {
  newBlockData: PropTypes.instanceOf(NewPartBlockDataResult).isRequired,
  onClose: PropTypes.func.isRequired
};

export default NewPartBlockComponent;
