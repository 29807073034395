import React from 'react';
import PropTypes from 'prop-types';
import './top-panel-component.scss';

import LeftPanelComponent from './left-panel/left-panel-component';

import { Attributes } from '../../models/result';
import { LookupResult } from '../../../../common/models/lookup-result';
import { LoadingGlobal, MessageBoxGlobal, PinLocationGlobal } from '../../../../common/globals/globals';

import SplitterPanelComponent from '../../../../shared/components/splitter-panel/splitter-panel-component';
import MapComponent from '../../../../common/components/map/map-component';

const TopPanelComponent = (props) => {
  const {
    editing,
    loadingGlobal,
    messageBoxGlobal,
    pinLocationGlobal,
    lookups,
    attributes,
    updateAttributes,
    geoserverUrl,
    mapComponentName,
    contextLayerType,
    lookupsFiltered
  } = props;

  const map = attributes.points === null || attributes.points === undefined ?
    <div className='no-map-data'>A valid location could not be found</div>
    :
    <MapComponent
      messageBoxGlobal={messageBoxGlobal}
      loadingGlobal={loadingGlobal}
      pinLocationGlobal={pinLocationGlobal}
      geoserverUrl={geoserverUrl}
      mapComponentName={mapComponentName}
      contextLayerType={contextLayerType}
      points={attributes.points}
      shapes={[attributes.shapes]}
    />;

  return (
    <SplitterPanelComponent vertical percentage secondarySize={50}>
      <LeftPanelComponent
        editing={editing}
        lookups={lookups}
        attributes={attributes}
        updateAttributes={updateAttributes}
        lookupsFiltered={lookupsFiltered}
      />
      { map }
    </SplitterPanelComponent>
  );
};

TopPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  pinLocationGlobal: PropTypes.instanceOf(PinLocationGlobal).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  attributes: PropTypes.instanceOf(Attributes).isRequired,
  updateAttributes: PropTypes.func.isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  mapComponentName: PropTypes.string.isRequired,
  contextLayerType: PropTypes.number.isRequired,
  lookupsFiltered: PropTypes.instanceOf(LookupResult).isRequired
};

export default TopPanelComponent;