import moment from 'moment';

class HubText {
  hubTextId = 0;
  hubId = 0;
  textCategoryId = 0;
  textValue = '';
  dateAdded = moment().format('YYYY-MM-DD 00:00:00.000');
  dateAmended = this.dateAdded;
  status = '';

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

export { HubText };