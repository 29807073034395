// Common helpers
import { apiGet, apiPost, callApiDefaultOnFail } from '../../common/helpers/api-helpers';
import { IssueType } from '../../common/components/editable-view/editable-view-component';
import { LookupResult } from '../../common/models/lookup-result';
import { MessageBoxIcon } from '../../shared/components/message-box/message-box-classes';
import { convertDecimalLocationToDMS } from '../../common/helpers/location-helpers';
import { RECORD_STATUS } from '../../common/models/record-status';
import { EditLock } from '../../common/models/edit-lock';
import { GMatrixSelectedItem } from '../../common/models/generic-selected-item';
import { RELATED_ITEM_TYPE } from '../../common/models/related-item-type';

export function beginEdit() {
  // Get a fresh copy of the data, and put us in edit mode
  this.getData(true, () => this.editingGlobal.set(true));
}

export function cancelEdit(autoClose = false) {
  const callback = () => this.editingGlobal.set(false);

  if (this.idGlobal.isNewRecordId | this.idGlobal.isCopyRecordId) {
    this.idGlobal.clear(callback);
  } else {
    const postbackInfo = this.getPostbackInfo(false, autoClose);
    const onSuccess = (result) => this.setData(result, callback);
    apiPost(this.apiPrefix + 'cancel', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
  }
}

export function canButtonsEnable(enable) {
  this.setState({ canEnable: enable });
}

export function canPerformAction(action) {
  if (typeof action !== 'string' || action.length === 0) {
    throw new Error('Invalid action param supplied to canPerformAction');
  }

  if (this.state.entityData.issues.filter(e => e.issueTypeId === IssueType.error).length > 0) {
    this.messageBoxGlobal.showError('You cannot ' + action + '  until you have cleared all errors!');
    return false;
  }
  return true;
}

export function copyEntity(postbackInfo) {
  const onSuccess = (result) => this.setData(result, () => this.editingGlobal.set(true));
  const onFail = (e) => this.idGlobal.clear(() => callApiDefaultOnFail(e, this.messageBoxGlobal));
  apiPost(this.apiPrefix + 'copy', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, onFail);
}

export function copyPinLocationToLocationObject(obj) {
  if (obj !== null) {
    let location = convertDecimalLocationToDMS(this.pinLocationGlobal.value);
    obj.dmsLatDegrees = location.dmsLatDegrees;
    obj.dmsLatMinutes = location.dmsLatMinutes;
    obj.dmsLatSeconds = location.dmsLatSeconds;
    obj.dmsNorthing = location.dmsNorthing;
    obj.dmsLongDegrees = location.dmsLongDegrees;
    obj.dmsLongMinutes = location.dmsLongMinutes;
    obj.dmsLongSeconds = location.dmsLongSeconds;
    obj.dmsEasting = location.dmsEasting;
    obj.status = obj.status === RECORD_STATUS.added
      ? obj.status
      : RECORD_STATUS.edited;
  }
}

export function deleteEntity() {
  const tryDelete = () => {
    const onSuccess = (result) => {
      if (result === true) {
        const onDeleteSuccess = () => {
          this.idGlobal.clear();
          this.updateSearchResults();
          this.setState({ entityData: null });
        }
        const onFail = () => this.messageBoxGlobal.showMessage('An error occured while trying to delete this record.', null, MessageBoxIcon.Error);
        apiGet(this.apiPrefix + 'delete?id=' + this.idGlobal.value, this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onDeleteSuccess, onFail);
      }
      else {
        this.messageBoxGlobal.showMessage('This record can not be deleted.', null, MessageBoxIcon.Error);
      }
    }
    apiGet(this.apiPrefix + 'canDelete?id=' + this.idGlobal.value, this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
  }
  this.messageBoxGlobal.showYesNoPrompt('Are you sure you want to delete this record?', tryDelete, 'Delete');
}

export function didMount() {
  const onSuccess = (result) => {
    this.lookups = new LookupResult(result);
    this.initData();
  }
  apiGet(this.apiPrefix + 'lookups', this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
}

export function getActions(obj, type, entityId = -1) {
  return {
    onAdd: (item, push = false) => {
      const ids = obj.filter(obj => obj.id < 0).map(obj => obj.id).sort((a, b) => a - b);
      item.id = (ids.length > 0 ? ids[0] : 0) - 1;
      if (entityId !== undefined && entityId !== null && entityId > 0 && item.entityId !== undefined) {
        item.entityId = entityId;
      }

      if (push === false) {
        obj.unshift(item);
      } else {
        obj.push(item);
      }

      this.setSelected(obj, item.id, type);
      this.updateData();
    },
    onDelete: (value) => {
      var item = obj.find(x => x.id === value);

      if (item.status === RECORD_STATUS.added) {
        obj.splice(obj.indexOf(item), 1)
      } else {
        item.status = RECORD_STATUS.deleted;
      }

      let objs = obj.filter(x => x.status !== RECORD_STATUS.deleted)

      if (objs.length > 0) this.setSelected(obj, objs[0].id, type);
      
      this.updateData();
    },
    onEdit: (item) => {
      item.status = item.status === RECORD_STATUS.added
        ? item.status
        : RECORD_STATUS.edited;

      if (type === RELATED_ITEM_TYPE.COMMENT || type === RELATED_ITEM_TYPE.RESOURCECOMMENT || type === RELATED_ITEM_TYPE.PRODUCTIONCOMMENT) {
        this.forceUpdate();
      }
      else {
        this.updateData();
      }
    },
    onSelected: (value) => {
      this.setSelected(obj, value, type);

      this.forceUpdate();
    }
  };
}

export function getCurrentEntity() {
  const { entityData } = this.state;
  const result = entityData === null || entityData === undefined || !this.idGlobal.isSet
    ? undefined
    : entityData
  return result === undefined ? null : result;
};

export function getData(forEdit, callback = undefined) {
  if (typeof forEdit !== 'boolean') {
    throw new Error('Invalid forEdit param supplied to getData');
  }
  if (callback !== undefined && typeof callback !== 'function') {
    throw new Error('Invalid callback param supplied to getData');
  }
  if (this.idGlobal.isNewRecordId) {
    throw new Error('Cannot call getData for a new record');
  }
  if (this.idGlobal.isCopyRecordId) {
    throw new Error('Cannot call getData for a copied record');
  }

  const alertCallback = () => {
    if (typeof callback === 'function') {
      callback();
    }
    const { alert } = this.state.entityData;
    if (typeof alert === 'string' && alert.length > 0) {
      this.messageBoxGlobal.showMessage(alert, null, MessageBoxIcon.Information);
    }
  };

  const postbackInfo = this.getPostbackInfo(forEdit, false);
  // clear any dirty postback contents since this is a Load & we'll get a clean copy of the result from the server
  postbackInfo.payload = null;
  const onSuccess = (result) => this.setData(result, alertCallback);
  const onFail = (e) => this.idGlobal.clear(() => callApiDefaultOnFail(e, this.messageBoxGlobal));
  apiPost(this.apiPrefix + 'load', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, onFail);
}

export function handleCopyEntity() {
  const postbackInfo = this.getPostbackInfo(false, false);
  this.idGlobal.setCopyRecordId(() => this.copyEntity(postbackInfo));
}

export function initData() {
  if (this.idGlobal.isSet) {
    if (this.idGlobal.isNewRecordId) {
      this.newData();
    }
    else if (this.idGlobal.isCopyRecordId) {
      return;
    }
    else {
      this.getData(false);
    }
  } else if (this.state.entityData !== null) {
    this.setData(null);
  }
}

export function newData() {
  const postbackInfo = this.getPostbackInfo(true, false);
  const onSuccess = (result) => this.setData(result, () => this.editingGlobal.set(true));
  const onFail = (e) => this.idGlobal.clear(() => callApiDefaultOnFail(e, this.messageBoxGlobal));
  apiPost(this.apiPrefix + 'new', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, onFail);
}

export function populatePostbackInfo(result, forEdit, autoClose) {
  result.payload = this.state.entityData;
  result.countryIds = this.countriesGlobal.value;
  result.id = this.idGlobal.value;
  result.forEdit = forEdit;
  result.autoClose = autoClose;
  result.selectedItems = this.state.selectedItems;
  return result;
}

export function refresh() {
  // Get a fresh copy of the data
  this.getData(this.editingGlobal.value);
}

export function resetEditTimeout() {
  const postbackInfo = this.getPostbackInfo(false, false);
  const onSuccess = (result) => {
    let entity = this.getCurrentEntity();
    entity.editLock = new EditLock(result);

    this.setState({ entityData: entity });
  };
  apiPost(this.apiPrefix + 'reset', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
}

export function saveEdit() {
  if (!this.canPerformAction('save')) {
    return;
  }

  const postbackInfo = this.getPostbackInfo(false, false);
  const onSuccess = (result) => this.setData(result, () => {
    let attributes = result.attributes === null || result.attributes === undefined ? null : result.attributes;

    if (attributes !== null && this.idGlobal.value !== attributes.id) {
      this.editingGlobal.set(false, this.idGlobal.set(attributes.id, attributes.displayName, this.searchGlobal.search(attributes.displayName)));
    }
    else {
      this.editingGlobal.set(false, this.updateSearchResults());
    }

  });
  apiPost(this.apiPrefix + 'save', JSON.stringify(postbackInfo), this.loadingGlobal.showSave, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
}

export function setDataInState(newData, callback = undefined) {
  if (callback !== undefined && typeof callback !== 'function') {
    throw new Error('Invalid callback param supplied to setData');
  }

  this.setState(prevState => {
    return prevState.entityData === newData
      ? null
      //: { entityData: newData, canEnable: true }
      : { entityData: newData, canEnable: true, selectedItems: prevState.selectedItems }
  }, callback);
}

export function setSelected(obj, value, type) {
  obj.forEach(x => {
    x.selected = false;
  });

  obj.find(x => x.id === value).selected = true;

  if (this.state.selectedItems !== null) {
    var selectedItem = this.state.selectedItems.find(x => x.type === type);
    if (selectedItem !== null && selectedItem !== undefined) this.state.selectedItems.splice(selectedItem);
  }

  if (this.state.selectedItems === null) {
    var arrayItem = [];
    arrayItem.push(new GMatrixSelectedItem({ id: value, type: type }));
    this.state.selectedItems = arrayItem;
  }
  else {
    this.state.selectedItems.push(new GMatrixSelectedItem({ id: value, type: type }));
  }
}

export function updateAttributes(attributes) {
  attributes.status = attributes.status === RECORD_STATUS.added
    ? attributes.status
    : RECORD_STATUS.edited;

  this.updateData();
}

export function updateData() {
  const postbackInfo = this.getPostbackInfo(false, false);
  const onSuccess = (result) => this.setData(result);
  apiPost(this.apiPrefix + 'update', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, undefined);
}

export function updateSearchResults(callback = null) {
  if (callback !== undefined && callback !== null && typeof callback !== 'function') {
    throw new Error('Invalid callback param supplied to updateSearchResults');
  }

  if (this.searchGlobal.searchString.trim() === '' && callback !== undefined && callback !== null) {
    callback();
    return;
  }

  if (this.searchGlobal.searchString !== '') {
    this.searchGlobal.search(this.searchGlobal.searchString, callback);
  }

}
