import React from 'react';
import PropTypes from 'prop-types';

import './search-results-component.scss';
import TreeViewComponent, { TreeNode, addHighlight } from '../../shared/components/tree-view/tree-view-component';
import { IdGlobal, SearchGlobal, ViewGlobal, Views } from '../../common/globals/globals';

const SearchResultsComponent = (props) => {
  const { idGlobal, editing, searchGlobal, viewGlobal } = props;

  let selectedNode = null;

  const toNodes = (searchString, searchResults, setAsParent) => {
    if (Array.isArray(searchResults)) {
      return searchResults.map(obj => {
        const result = new TreeNode(addHighlight(obj.resultName, searchString), obj);
        if (setAsParent === true) {
          result.isParent = true;
        }        
        result.disabled = editing;
        const childNodes = toNodes(searchString, obj.children, false);
        if (childNodes !== null) {
          result.addNodes(childNodes);
        }

        result.selected = !result.isParent && obj.resultId === idGlobal.value;
        if (result.selected) {
          selectedNode = result;
        }
        return result;
      });
    }
    return null;
  };

  const enableSiblings = () => {
    if (selectedNode !== null) {
      let parentNode = selectedNode.parent;

      for (var loop = 0; loop < parentNode.children.length; loop++) {
        parentNode.children[loop].disabled = false;
      }

      while (parentNode !== null) {
        parentNode.disabled = false;
        parentNode = parentNode.parent;
      }
    }
  };

  const nodes = toNodes(searchGlobal.searchString, searchGlobal.searchResults, true);
  if (editing && Views.canSelectSiblingWhileEditing(viewGlobal.value)) {
    enableSiblings();
  }

  const labelClick = (node) => {
    if (node.isParent) {
      if (node.hasChildren) {
        node.collapsed = !node.collapsed;
      }
      return false;
    } else if (!node.disabled) {
      idGlobal.set(node.tag.resultId, node.tag.resultName);
    }
  };

  return (
    <div className="search-results-component">
      <TreeViewComponent nodes={nodes} labelClick={labelClick} />
    </div>
  );
};

SearchResultsComponent.propTypes = {
  idGlobal: PropTypes.instanceOf(IdGlobal).isRequired,
  editing: PropTypes.bool.isRequired,
  viewGlobal: PropTypes.instanceOf(ViewGlobal).isRequired,
  searchGlobal: PropTypes.instanceOf(SearchGlobal).isRequired
};

export default SearchResultsComponent;