import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MaskedInput from '../react-text-mask/react-text-mask';

const DateInputMaskComponentPlaceholder = '--/--/----';
const DateInputMaskComponentMask = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

class DateInputMaskComponent extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.state = { value: this.props.value };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  onChange(event) {
    this.setState({ value: event.target.value });
  }

  onKeyPress(event) {
    if ((event.which === 13 || event.keyCode === 13) && typeof this.props.onKeyPress === 'function') {
      this.onBlur();
      this.props.onKeyPress(event);
    }
  };

  onBlur(event) {
    if (this.state.value !== this.props.value) {
      var str = this.state.value;
      var changedDate;

      if (str.substring(2, 3) === "/" && str.substring(5, 6) === "/") {
        changedDate = str.substring(6, 10) + "/" + str.substring(3, 5) + "/" + str.substring(0, 2);
      }
      else if (str.substring(4, 5) === "/" && str.substring(7, 5) === "/") {
        changedDate = str.substring(0, 4) + "/" + str.substring(5, 7) + "/" + str.substring(8, 10);
      }

      var resetDate = this.props.onChange(changedDate);
      if (resetDate) {
        this.setState({ value: this.props.value });
      }
    }
  }

  render() {
    const newProps = { ...this.props, value: this.state.value, onBlur: this.onBlur, onChange: this.onChange, onKeyPress: this.onKeyPress };

    return (
      <div className="date-input-mask-component">
        <MaskedInput {...newProps} />
      </div>
    );
  }
}

DateInputMaskComponent.propTypes = {
  keepCharPositions: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired
};

DateInputMaskComponent.defaultProps = {
  keepCharPositions: true,
  placeholder: DateInputMaskComponentPlaceholder,
  mask: DateInputMaskComponentMask
};

export default DateInputMaskComponent;
export { DateInputMaskComponentPlaceholder, DateInputMaskComponentMask, moment };
