import React from 'react';
import PropTypes from 'prop-types';

import { ResourceAnnual } from '../../../models/production-result';

import SnapshotAnnualChart from '../../../../../common/components/chart/snapshot-annual-chart';

const SnapshotAnnualComparisonChartComponent = (props) => {
  const {
    currentResourceAnnuals,
    startYear,
    endYear
  } = props;

  return (
    <SnapshotAnnualChart
      resourceData={currentResourceAnnuals}
      isStacked="true"
      chartType="Line"
      startYear={startYear}
      endYear={endYear}
      xTitle="Year"
      yTitle="Mboed"
    />
  );
};

SnapshotAnnualComparisonChartComponent.propTypes = {
  currentResourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired
};

export default SnapshotAnnualComparisonChartComponent;