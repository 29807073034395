class WestwoodDataFloatingPlatform {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to WestwoodDataFloatingPlatform.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'string') { throw new Error('Invalid obj.Id param supplied to WestwoodDataFloatingPlatform.ctor'); }
      if (typeof obj.name !== 'string') { throw new Error('Invalid obj.name param supplied to WestwoodDataFloatingPlatform.ctor'); }
      if (obj.projectName !== undefined && obj.projectName !== null && typeof obj.projectName !== 'string') { throw new Error('Invalid obj.projectName param supplied to WestwoodDataFloatingPlatform.ctor'); }
      if (obj.fieldName !== undefined && obj.fieldName !== null && typeof obj.fieldName !== 'string') { throw new Error('Invalid obj.fieldName param supplied to WestwoodDataFloatingPlatform.ctor'); }
      if (obj.region !== undefined && obj.region !== null && typeof obj.region !== 'string') { throw new Error('Invalid obj.region param supplied to WestwoodDataFloatingPlatform.ctor'); }

      if (obj.status !== undefined && obj.status !== null && typeof obj.status !== 'string') { throw new Error('Invalid obj.status param supplied to WestwoodDataFloatingPlatform.ctor'); }
      if (obj.fpsType !== undefined && obj.fpsType !== null && typeof obj.fpsType !== 'string') { throw new Error('Invalid obj.fpsType param supplied to WestwoodDataFloatingPlatform.ctor'); }
      if (obj.waterDepthMeters !== undefined && obj.waterDepthMeters !== null && typeof obj.waterDepthMeters !== 'number') { throw new Error('Invalid obj.waterDepthMeters param supplied to WestwoodDataFloatingPlatform.ctor'); }
      if (obj.ownershipType !== undefined && obj.ownershipType !== null && typeof obj.ownershipType !== 'string') { throw new Error('Invalid obj.ownershipType param supplied to WestwoodDataFloatingPlatform.ctor'); }
      
      if (obj.vesselIMO !== undefined && obj.vesselIMO !== null && typeof obj.vesselIMO !== 'string') { throw new Error('Invalid obj.vesselIMO param supplied to WestwoodDataFloatingPlatform.ctor'); }
      if (obj.weightTotalTonnes !== undefined && obj.weightTotalTonnes !== null && typeof obj.weightTotalTonnes !== 'number') { throw new Error('Invalid obj.weightTotalTonnes param supplied to WestwoodDataFloatingPlatform.ctor'); }
      if (obj.mooringSystem !== undefined && obj.mooringSystem !== null && typeof obj.mooringSystem !== 'string') { throw new Error('Invalid obj.mooringSystem param supplied to WestwoodDataFloatingPlatform.ctor'); }
      if (obj.mooringType !== undefined && obj.mooringType !== null && typeof obj.mooringType !== 'string') { throw new Error('Invalid obj.mooringType param supplied to WestwoodDataFloatingPlatform.ctor'); }

      if (obj.capexUSDMM !== undefined && obj.capexUSDMM !== null && typeof obj.capexUSDMM !== 'number') { throw new Error('Invalid obj.capexUSDMM param supplied to WestwoodDataFloatingPlatform.ctor'); }
      if (obj.capexEstimated !== undefined && obj.capexEstimated !== null && typeof obj.capexEstimated !== 'boolean') { throw new Error('Invalid obj.capexEstimated param supplied to WestwoodDataFloatingPlatform.ctor'); }

      if (obj.product !== undefined && obj.product !== null && typeof obj.product !== 'string') { throw new Error('Invalid obj.product param supplied to WestwoodDataFloatingPlatform.ctor'); }
      if (obj.developmentType !== undefined && obj.developmentType !== null && typeof obj.developmentType !== 'string') { throw new Error('Invalid obj.developmentType param supplied to WestwoodDataFloatingPlatform.ctor'); }
      if (obj.developmentStructure !== undefined && obj.developmentStructure !== null && typeof obj.developmentStructure !== 'string') { throw new Error('Invalid obj.licenceName param supplied to WestwoodDataFloatingPlatform.ctor'); }
    }

    this.id = obj === null ? '' : obj.id;
    this.name = obj === null ? '' : obj.name ?? '';
    this.projectName = obj === null ? '' : obj.projectName ?? '';
    this.fieldName = obj === null ? '' : obj.fieldName ?? '';
    this.region = obj === null ? '' : obj.region ?? '';

    this.status = obj === null ? '' : obj.status ?? '';
    this.fpsType = obj === null ? '' : obj.fpsType ?? '';
    this.waterDepthMeters = obj === null ? 0 : obj.waterDepthMeters;
    this.ownershipType = obj === null ? '' : obj.ownershipType ?? '';

    this.vesselIMO = obj === null ? '' : obj.vesselIMO ?? '';
    this.weightTotalTonnes = obj === null ? 0 : obj.weightTotalTonnes;
    this.mooringSystem = obj === null ? '' : obj.mooringSystem ?? '';
    this.mooringType = obj === null ? '' : obj.mooringType ?? '';

    this.capexUSDMM = obj === null ? 0 : obj.capexUSDMM;
    this.capexEstimated = obj === null ? false : obj.capexEstimated ?? false;

    this.product = obj === null ? '' : obj.product ?? '';
    this.developmentType = obj === null ? '' : obj.developmentType ?? '';
    this.developmentStructure = obj === null ? '' : obj.developmentStructure ?? '';
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "WestwoodDataFloatingPlatform.FromArray"');
    }

    const result = array.map(obj => new WestwoodDataFloatingPlatform(obj));
    return result;
  }
}

export { WestwoodDataFloatingPlatform };
