import React from 'react';
import PropTypes from 'prop-types';

import { Attributes } from '../../models/result';
import { IN_DEV_MODE } from '../../../../shared/helpers/common';
import { LookupResult } from '../../../../common/models/lookup-result';
import PropertyGridComponent, { PropertyGridStringValue, PropertyGridSelectValue, PropertyGridNumberValue } from '../../../../shared/components/property-grid/property-grid-component';
import { INPUTTYPES } from '../../../../shared/components/number-format/number-formatter';
import { valueTypes } from '../../../../shared/helpers/value-handler';
import { StringLookupResult } from '../../../../common/models/string-lookup-result';

const IDS = {
  ID: 5,
  RIG_NAME: 10,
  WESTWOOD_RIG_ID: 20,
  RIG_TYPE_ID: 30,
  IMO: 40,
  MMSI: 50,
};
Object.freeze(IDS);

const getSections = (attributes, lookups, valueChanged, westwoodLookups) => {
  const result = [
    {
      label: 'Rig',
      rows: [
        { label: 'Rig Name', values: [new PropertyGridStringValue(IDS.RIG_NAME, attributes.rigName, valueChanged)] },
        { label: 'Rig Type', values: [new PropertyGridSelectValue(IDS.RIG_TYPE_ID, attributes.rigTypeId, valueChanged, 'key', 'value', lookups.RigType, true)] },
        { label: 'IMO', values: [new PropertyGridNumberValue(IDS.IMO, INPUTTYPES.NUMBER, attributes.imo, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
        { label: 'MMSI', values: [new PropertyGridNumberValue(IDS.MMSI, INPUTTYPES.NUMBER, attributes.mmsi, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
        { label: 'Westwood Rig', values: [new PropertyGridSelectValue(IDS.WESTWOOD_RIG_ID, attributes.westwoodDataId, valueChanged, 'key', 'value', westwoodLookups.Rig, true)] },
      ]
    },
  ];

  if (IN_DEV_MODE) result[0].rows.unshift({ label: 'Rig Id', values: [new PropertyGridStringValue(IDS.ID, attributes.id)] });
  return result;
};

const RigAttributesComponent = (props) => {
  const {
    editing,
    lookups,
    attributes,
    updateAttributes,
    westwoodLookups
  } = props;

  const valueChanged = (value, id) => {
    switch (id) {
      case IDS.RIG_NAME: attributes.rigName = value; break;
      case IDS.RIG_TYPE_ID: attributes.rigTypeId = Number(value); break;
      case IDS.IMO: attributes.imo = Number(value); break;
      case IDS.MMSI: attributes.mmsi = Number(value); break;
      case IDS.WESTWOOD_RIG_ID: attributes.westwoodDataId = value; break;

      default: throw new Error('Id ' + id + ' is not supported in LeftPanelComponent.valueChanged');
    }
    updateAttributes(attributes);
    return true;
  };

  const sections = getSections(attributes, lookups, valueChanged, westwoodLookups);

  return <PropertyGridComponent sections={sections} readOnly={!editing} />;
};

RigAttributesComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  westwoodLookups: PropTypes.instanceOf(StringLookupResult).isRequired,
  attributes: PropTypes.instanceOf(Attributes).isRequired,
  updateAttributes: PropTypes.func.isRequired,
};

export default RigAttributesComponent;
