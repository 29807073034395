import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as ViewFunctions from '../view-common/view-functions';
import './block-view.scss';

// Models
import { Result } from './models/result';
import { PostbackInfo } from '../view-common/postback-info';
import { NewPartBlockDataResult } from './models/new-part-block-data-result';
// Components
import TopPanelComponent from './components/top-panel/top-panel-component';
import BottomPanelComponent from './components/bottom-panel/bottom-panel-component';
import RelinquishBlockComponent from './components/popup-panels/relinquish-block-component';
import NewPartBlockComponent from './components/popup-panels/new-part-block-component';
// Common models
import { RECORD_STATUS } from './../../common/models/record-status';
import { Views, ViewGlobal, IdGlobal, LoadingGlobal, MessageBoxGlobal, CountriesGlobal, EditingGlobal, SearchGlobal, PinLocationGlobal } from '../../common/globals/globals';
// Common components
import { Button } from 'reactstrap';
import EditableViewComponent, { layerType, IssueType } from '../../common/components/editable-view/editable-view-component';
import { MessageBoxIcon } from '../../shared/components/message-box/message-box-classes';
// Shared components
import SplitterPanelComponent from '../../shared/components/splitter-panel/splitter-panel-component';
// Helpers
import { apiGet, apiPost } from '../../common/helpers/api-helpers';
import { RELATED_ITEM_TYPE } from '../../common/models/related-item-type';
import { GMATRIX_ENTITY } from '../../common/models/gmatrix-entity';

class BlockView extends React.Component {
  constructor(props) {
    super(props);

    this.beginEdit = ViewFunctions.beginEdit.bind(this);
    this.cancelEdit = ViewFunctions.cancelEdit.bind(this);
    this.canButtonsEnable = ViewFunctions.canButtonsEnable.bind(this);
    this.canPerformAction = ViewFunctions.canPerformAction.bind(this);
    this.didMount = ViewFunctions.didMount.bind(this);
    this.getActions = ViewFunctions.getActions.bind(this);
    this.getCurrentEntity = ViewFunctions.getCurrentEntity.bind(this);
    this.getData = ViewFunctions.getData.bind(this);
    this.initData = ViewFunctions.initData.bind(this);
    this.populatePostbackInfo = ViewFunctions.populatePostbackInfo.bind(this);
    this.refresh = ViewFunctions.refresh.bind(this);
    this.resetEditTimeout = ViewFunctions.resetEditTimeout.bind(this);
    this.saveEdit = ViewFunctions.saveEdit.bind(this);
    this.setDataInState = ViewFunctions.setDataInState.bind(this);
    this.setSelected = ViewFunctions.setSelected.bind(this);
    this.updateAttributes = ViewFunctions.updateAttributes.bind(this);
    this.updateData = ViewFunctions.updateData.bind(this);
    this.updateSearchResults = ViewFunctions.updateSearchResults.bind(this);

    // OVERRIDE COMMON FUNCTION METHODS
    this.deleteEntity = this.deleteEntity.bind(this);
    this.newData = this.newData.bind(this);

    // VIEW SPECIFIC METHODS

    // LOCAL VARIABLES & INITIAL STATE
    this.lookups = null;
    this.filteredLookups = null;
    this.state = { entityData: null, contextLayers: [], canEnable: true, selectedItems: null };
  }

  get apiPrefix() { return 'api/block/' };
  get className() { return 'view-panel block-view' };
  get currentView() { return Views.Block };
  get currentViewName() { return Views.displayName(this.currentView) };
  get mapComponentName() { return 'block-view-component-map' };
  get contextLayerType() { return layerType.Blocks };
  get ownershiplookupURL() { return 'api/block/lookups/ownership' };
  get gmatrixEntity() { return GMATRIX_ENTITY.Block; }

  get viewGlobal() { return this.props.viewGlobal; };
  get idGlobal() { return this.props.idGlobal; };
  get editingGlobal() { return this.props.editingGlobal; };
  get countriesGlobal() { return this.props.countriesGlobal; };
  get loadingGlobal() { return this.props.loadingGlobal; };
  get messageBoxGlobal() { return this.props.messageBoxGlobal; };
  get searchGlobal() { return this.props.searchGlobal; };
  get pinLocationGlobal() { return this.props.pinLocationGlobal; };
  

  componentDidMount() {
    this.didMount();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idGlobal.value !== this.props.idGlobal.value) {
      this.initData();
    }
  }

  getPostbackInfo(forEdit, autoClose) {
    const result = new PostbackInfo();
    this.populatePostbackInfo(result, forEdit, autoClose);

    if (result !== null && result.payload !== null && result.payload.attributes !== null) {
      result.payload.attributes.shapes = null;
    }

    return result;
  }

  setData(newData, callback = undefined) {
    let entity = newData === null ? null : new Result(newData);

    if (entity !== null && !(entity instanceof Result)) {
      throw new Error('Invalid newData param supplied to setData');
    }

    this.setDataInState(entity, callback);
  }

  /* START - COMMON VIEW FUNCTION OVERRIDES */
  deleteEntity() {
    const startDelete = () => {
      const onCanDeleteSuccess = (canDeleteResult) => {
        if (canDeleteResult == true) {

          const onDeleteListSuccess = (result) => {
            if (result === '') { this.deleteBlock(); }
            else {
              this.messageBoxGlobal.showYesNoPrompt(result, this.deleteBlock, 'Associated Records found');
            }

          }
          const onDeleteListFail = () => this.messageBoxGlobal.showMessage('An error occured while trying to list the items related to this Block.', null, MessageBoxIcon.Error);
          apiGet(this.apiPrefix + 'delete-list-related?id=' + this.idGlobal.value, this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onDeleteListSuccess, onDeleteListFail);

        }
        else {
          // CAN DELETE FAIL
          this.messageBoxGlobal.showMessage('This record can not be deleted.', null, MessageBoxIcon.Error);
        }
      }
      apiGet(this.apiPrefix + 'canDelete?id=' + this.idGlobal.value, this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onCanDeleteSuccess, undefined);
    }
    this.messageBoxGlobal.showYesNoPrompt('Are you sure you want to delete this record?', startDelete, 'Delete');
  }

  newData() {
    const countryIds = this.countriesGlobal.value;
    if (countryIds === null || countryIds === undefined || countryIds.length === 0) {
      this.messageBoxGlobal.showError('New Part Block. Countries not found!');
      this.newBlockCancelFail(false);
      return;
    }

    const onSuccess = (result) => {
      const newBlockData = new NewPartBlockDataResult(result);
      let msgContents = <NewPartBlockComponent newBlockData={newBlockData} onClose={this.handleNewPartBlock} />;
      this.messageBoxGlobal.showPanel(msgContents, 'New Part Block', 'lg');
    };
    apiPost(this.apiPrefix + 'new-part-block-data', JSON.stringify(countryIds), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, null);
  }

  /* END - COMMON VIEW FUNCTION OVERRIDES */

  /* START - UNIQUE VIEW METHODS */

  newBlockCancelFail = (clear = true) => {
    if (clear === true) this.messageBoxGlobal.clear();
    this.idGlobal.clear();
    this.updateSearchResults();
    this.setState({ entityData: null });
  }

  handleNewPartBlock = (cancel, parentBlockId, isLicenced = false) => {
    if (cancel === true) {
      this.newBlockCancelFail();
      return;
    }

    if (parentBlockId === null || !parentBlockId > 0 ) {
      throw new Error('Invalid Parent Block Id passed to handleNewPartBlock: ' + parentBlockId.toString());
      this.newBlockCancelFail();
    }
    const postbackInfo = this.getPostbackInfo(true, false);

    postbackInfo.id = parentBlockId;
    postbackInfo.newPartBlockIsLicenced = isLicenced;

    const onSuccess = (result) => this.setData(result, () => {
      this.messageBoxGlobal.clear(this.idGlobal.set(result.attributes.id, result.attributes.blockNo, this.searchGlobal.search(result.attributes.parentBlockNo)));
      this.editingGlobal.set(true);
      
    });
    //const onFail = (e) => this.idGlobal.clear(() => callApiDefaultOnFail(e, this.messageBoxGlobal));
    apiPost(this.apiPrefix + 'new', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, null);
  }

  changeLicenceStatus = () => {
    if (this.idGlobal.isNewRecordId) {
      throw new Error('Cannot call changeLicenceStatus for a new record');
    }
    const entity = this.getCurrentEntity();
    if (entity === null) return;

    const { attributes, issues } = entity;

    const hasErrors = issues === null || issues === undefined ? false : issues.filter(e => e.issueTypeId === IssueType.error).length > 0;

    if (hasErrors) {
      this.messageBoxGlobal.showError('You cannot alter Licence Status until you have cleared all errors!');
      return false;
    }

    if (attributes.isLicenced) {
      let msgContents = <RelinquishBlockComponent blockNo={attributes.blockNo} onClose={this.handleRelinquish} />;
      this.messageBoxGlobal.showPanel(msgContents, 'Relinquish Block', 'lg');
    }
    else {
      const callback = (result) => {
        if (result != null && result.editLock !== null && result.editLock !== undefined && result.editLock.id !== 0) {
          this.editingGlobal.set(true)
        }
      }
      const postbackInfo = this.getPostbackInfo(false, false);
      const onSuccess = (result) => this.setData(result, callback(result));
      apiPost(this.apiPrefix + 'change-licence-status', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, null);
    }
  }

  deleteBlock = () => {
    const onDeleteSuccess = () => {
      this.idGlobal.clear();
      this.updateSearchResults();
      this.setState({ entityData: null });
    }
    const onDeleteFail = () => this.messageBoxGlobal.showMessage('An error occured while trying to delete this record.', null, MessageBoxIcon.Error);
    apiGet(this.apiPrefix + 'delete?id=' + this.idGlobal.value, this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onDeleteSuccess, onDeleteFail);
  }

  handleRelinquish = (cancel, relinquishComment, relinquishDate) => {
    if (cancel === true) {
      this.messageBoxGlobal.clear();
      return;
    }

    const callback = (result) => {
      this.messageBoxGlobal.clear();
      if (result != null && result.editLock !== null && result.editLock !== undefined && result.editLock.id !== 0) {
        this.editingGlobal.set(true)
      }
    }
    const postbackInfo = this.getPostbackInfo(false, false);
    postbackInfo.payload.attributes.relinquishComment = relinquishComment;
    postbackInfo.payload.attributes.relinquishDate = relinquishDate;

    const onSuccess = (result) => this.setData(result, callback(result));
    apiPost(this.apiPrefix + 'change-licence-status', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, null);
  }

  render() {
    const entity = this.getCurrentEntity();
    const { canEnable } = this.state;

    if (entity === null || this.lookups === null) {
      return <div className="view-panel no-data"><h1>Please select a {this.currentViewName}, or click<span className="new-button" onClick={() => this.idGlobal.setNewRecordId()} />for a new {this.currentViewName}.</h1></div>;
    }

    const { attributes, editLock, issues, lookupsFiltered } = entity;
    const { id } = entity.attributes;

    const comments = attributes.comments.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const ownership = attributes.ownership === null || attributes.ownership === undefined ? null : attributes.ownership.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const editTimeout = this.editingGlobal.value && editLock !== undefined && editLock !== null ? moment(editLock.expiryTime).toDate() : undefined;

    const canDelete = !attributes.isLicenced;

    const toolbarItems = [
      <Button key={0} className="btn-image first-button change-licence-status" size="sm" disabled={this.editingGlobal.value} onClick={this.changeLicenceStatus}>{attributes.isLicenced ? 'Relinquish' : 'Change Licence Status'}</Button>,
    ];

    return (
      <EditableViewComponent
        className={this.className}
        loadingGlobal={this.loadingGlobal}
        header={this.currentViewName + ': ' + attributes.blockNo}
        messageBoxGlobal={this.messageBoxGlobal}
        editingGlobal={this.editingGlobal}
        issues={issues}
        canRefresh={!(this.idGlobal.isNewRecordId | this.idGlobal.isCopyRecordId)}
        canEnable={canEnable}
        refresh={this.refresh}
        beginEdit={this.beginEdit}
        cancelEdit={this.cancelEdit}
        saveEdit={this.saveEdit}
        editTimeout={editTimeout}
        resetEditTimeout={this.resetEditTimeout}
        toolbarItems={toolbarItems}
        toolbarItemsPosition="after"
        startYear={0}
        endYear={0}
        selectedStartYear={0}
        selectedEndYear={0}
        yearStartChange={() => { }}
        yearEndChange={() => { }}
        isProduction={false}
        canDelete={canDelete}
        onDelete={this.deleteEntity}
        canCopyPin={false}
        pinLocationGlobal={this.pinLocationGlobal}
        onCopyPin={() => { }}
        viewGlobal={this.props.viewGlobal}
        searchGlobal={this.props.searchGlobal}
        currentView={this.gmatrixEntity}
        currentId={this.idGlobal.value}
        recordLocked={false}
        updateSnapshotAudit={() => { }}
        edited={false}
        calculate={() => { }}
        showCalc
        shortcuts={[]}
      >
        <SplitterPanelComponent vertical={false} percentage secondarySize={40}>
          <TopPanelComponent
            editing={this.editingGlobal.value}
            loadingGlobal={this.loadingGlobal}
            messageBoxGlobal={this.messageBoxGlobal}
            pinLocationGlobal={this.pinLocationGlobal}
            lookups={this.lookups}
            attributes={attributes}
            updateAttributes={this.updateAttributes}
            geoserverUrl={this.props.geoserverUrl}
            mapComponentName={this.mapComponentName}
            contextLayerType={this.contextLayerType}
            lookupsFiltered={lookupsFiltered}
          />
          <BottomPanelComponent
            editing={this.editingGlobal.value}
            messageBoxGlobal={this.messageBoxGlobal}
            loadingGlobal={this.loadingGlobal}
            comments={comments}
            commentActions={this.getActions(attributes.comments, RELATED_ITEM_TYPE.COMMENT)}
            isLicenced={attributes.isLicenced}
            ownership={ownership}
            ownershipActions={this.getActions(attributes.ownership, RELATED_ITEM_TYPE.OWNERSHIP)}
            ownershiplookupURL={this.ownershiplookupURL}
            id={id}
          />
        </SplitterPanelComponent>
      </EditableViewComponent>
    );
  }
};

BlockView.propTypes = {
  viewGlobal: PropTypes.instanceOf(ViewGlobal).isRequired,
  idGlobal: PropTypes.instanceOf(IdGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  countriesGlobal: PropTypes.instanceOf(CountriesGlobal).isRequired,
  searchGlobal: PropTypes.instanceOf(SearchGlobal).isRequired,
  editingGlobal: PropTypes.instanceOf(EditingGlobal).isRequired,
  pinLocationGlobal: PropTypes.instanceOf(PinLocationGlobal).isRequired
}

export default BlockView;
