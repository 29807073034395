import { IdGlobal } from './id-global';
import { SearchGlobal } from './search-global';
import { EditingGlobal } from './editing-global';
import { ViewGlobal, Views } from './view-global';
import { CountriesGlobal } from './countries-global';
import { PrintModeGlobal } from './print-mode-global';
import { MessageBoxGlobal } from './message-box-global';
import { LoadingGlobal, LoadingImage } from './loading-global';
import { PinLocationGlobal } from './pin-location-global';

const dummyFunc = () => { };

const ACTIVECOMPONENT = {
  GRID: 0,
  NONGRID: 1
};
Object.freeze(ACTIVECOMPONENT);


class DummyCountriesGlobal extends CountriesGlobal {
  constructor() {
    super([], dummyFunc);
  }
}

class DummyEditingGlobal extends EditingGlobal {
  constructor() {
    super(false, dummyFunc);
  }
}

class DummyPrintModeGlobal extends EditingGlobal {
  constructor() {
    super(false, dummyFunc);
  }
}

class DummyIdGlobal extends IdGlobal {
  constructor() {
    super(null, dummyFunc, dummyFunc, false, false, dummyFunc);
  }
}

class DummyLoadingGlobal extends LoadingGlobal {
  constructor() {
    super(dummyFunc, dummyFunc);
  }
}

class DummyMessageBoxGlobal extends MessageBoxGlobal {
  constructor() {
    super(null, dummyFunc, false, dummyFunc, dummyFunc);
  }
}

class DummySearchGlobal extends SearchGlobal {
  constructor() {
    super('', [], dummyFunc, dummyFunc, dummyFunc, dummyFunc, dummyFunc, dummyFunc);
  }
}

class DummyViewGlobal extends ViewGlobal {
  constructor() {
    super(Views.Home, dummyFunc, dummyFunc, dummyFunc);
  }
}

class DummyPinLocationGlobal extends PinLocationGlobal {
  constructor() {
    super([], dummyFunc);
  }
}

export {
  PrintModeGlobal,
  CountriesGlobal,
  EditingGlobal,
  IdGlobal,
  LoadingGlobal,
  LoadingImage,
  MessageBoxGlobal,
  SearchGlobal,
  ViewGlobal,
  Views,
  PinLocationGlobal,
  dummyFunc,
  DummyCountriesGlobal,
  DummyEditingGlobal,
  DummyPrintModeGlobal,
  DummyIdGlobal,
  DummyLoadingGlobal,
  DummyMessageBoxGlobal,
  DummySearchGlobal,
  DummyViewGlobal,
  DummyPinLocationGlobal,
  ACTIVECOMPONENT
};