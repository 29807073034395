import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Label, Input } from 'reactstrap';

import { Lookup } from '../../../common/models/lookup-result';

const ArchiveProspectComponent = (props) => {
  return (
    <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Row>
        <Col>
          {'To archive prospect \'' + props.resourceName + '\', please select a Historic Status.'}
        </Col>
      </Row>
      <Row className="mt-3 pl-3">
        <Col xs="2">
          <Label for="cbHistoricStatus">Historic Status</Label>
        </Col>
        <Col xs="3">
          <Input style={{ paddingLeft: '1px' }} type="select" id="cbHistoricStatus" bsSize="sm" onChange={event => props.onChange(event.target.value)}>
            {props.lookups.map(obj => <option key={obj.key} value={obj.key}>{obj.value}</option>)}
          </Input>
        </Col>
      </Row>
    </Container>
  );
};

ArchiveProspectComponent.propTypes = {
  resourceName: PropTypes.string.isRequired,
  lookups: PropTypes.arrayOf(PropTypes.instanceOf(Lookup)).isRequired,
  onChange: PropTypes.func.isRequired
};

export default ArchiveProspectComponent;
