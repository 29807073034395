import React from 'react';
import PropTypes from 'prop-types';

import './home-view.scss';
import IntegrityGridComponent from './integrity-grid-component';

import { apiPost } from '../../common/helpers/api-helpers';
import { LoadingGlobal, MessageBoxGlobal, CountriesGlobal, ViewGlobal } from '../../common/globals/globals';
import { Button } from 'reactstrap'

import TabPanelComponent, { Tab } from '../../shared/components/tab-panel/tab-panel-component';

class HomeView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { databaseData: null, mapData: null };
  }

  get apiPrefix() { return 'api/home/' };
  get countriesGlobal() { return this.props.countriesGlobal; };
  get loadingGlobal() { return this.props.loadingGlobal; };
  get messageBoxGlobal() { return this.props.messageBoxGlobal; };
  get viewGlobal() { return this.props.viewGlobal; };

  componentDidMount() {
    this.initHomeData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.countriesGlobal.value !== this.props.countriesGlobal.value) {
      this.initHomeData();
    }
  }

  initHomeData() {
    const headerBody = JSON.stringify({ countryIds: this.countriesGlobal.value });

    const onDatabaseSuccess = (result) => this.setState({ databaseData: result });
    apiPost(this.apiPrefix + 'integrity-get', headerBody, this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onDatabaseSuccess, undefined);

    const onMapSuccess = (result) => this.setState({ mapData: result });
    apiPost(this.apiPrefix + 'integrity-get-map', headerBody, this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onMapSuccess, undefined);
  }

  render() {
    const { databaseData, mapData } = this.state;

    let dbCount = databaseData !== null && databaseData !== undefined ? databaseData.length : 0;
    let mapCount = mapData !== null && mapData !== undefined ? mapData.length : 0;

    let tabs = [
      new Tab('Database Integrity (' + dbCount.toString() + ')', Array.isArray(databaseData) ? <IntegrityGridComponent data={databaseData} messageBoxGlobal={this.messageBoxGlobal} viewGlobal={this.viewGlobal} /> : null),
      new Tab('Map Integrity (' + mapCount.toString() + ')', Array.isArray(mapData) ? <IntegrityGridComponent data={mapData} messageBoxGlobal={this.messageBoxGlobal} viewGlobal={this.viewGlobal} /> : null)
    ];

    return (
      <div className="view-panel home-view">
        <TabPanelComponent toolbarItems={tabs} selectedItemIndex={0} />
        <div className="footer" ><Button size="sm" onClick={() => this.initHomeData()}>Refresh</Button></div>
      </div>
    )
  }
}

HomeView.propTypes = {
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  countriesGlobal: PropTypes.instanceOf(CountriesGlobal).isRequired,
  viewGlobal: PropTypes.instanceOf(ViewGlobal).isRequired
}

export default HomeView;