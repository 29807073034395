import React from 'react';
import PropTypes from 'prop-types';

import { Attributes, unitConversions } from '../../../models/result';
import { ResourceAlias } from '../../../models/result';

import { IN_DEV_MODE } from '../../../../../shared/helpers/common';
import { apiGet } from '../../../../../common/helpers/api-helpers';
import { LoadingGlobal, MessageBoxGlobal } from '../../../../../common/globals/globals';
import { LookupResult } from '../../../../../common/models/lookup-result';
import { valueTypes } from '../../../../../shared/helpers/value-handler';
import { StringLookupResult } from '../../../../../common/models/string-lookup-result';

import PropertyGridComponent, { validateDate, PropertyGridStringValue, PropertyGridSelectValue, PropertyGridNumberValue, PropertyGridNumberValueDouble, PropertyGridBooleanValue, PropertyGridPercentageValue, PropertyGridDateValueUTC, getDate, formatDate } from '../../../../../shared/components/property-grid/property-grid-component';
import { INPUTTYPES } from '../../../../../shared/components/number-format/number-formatter';

import { RESOURCE_PARENT_IDS } from '../../../../../common/globals/enums';

const IDS = {
  // ********* RESOURCE *********
  RESOURCE_ID: 1,
  RESOURCE_NAME: 2,
  BLOCK_NO: 3,
  STATUS_ID: 4,
  TYPE_ID: 6,
  CONDITION_ID: 8,
  PRODUCTION_SCHEME_ID: 10,
  PRODUCTIVITY_GROUP_ID: 12,
  DEVELOPMENT_CONCEPT_ID: 14,
  CLASSIFICATION_ID: 16,

  WATER_DEPTH_FT: 60,
  WATER_DEPTH_M: 61,

  ACTIVITY_AUDITED: 70,

  INITIAL_COMBINED_MMBOE: 80,
  INITIAL_COMBINED_M3: 81,

  REMAINING_COMBINED_MMBOE: 90,
  REMAINING_COMBINED_M3: 91,

  HAS_WGE_SHAPE: 95,
  STRAT_LEVEL_ID: 97,

  // ********* LIQUIDS *********
  INITIAL_LIQUID_MMB: 100,
  INITIAL_LIQUID_M3: 101,

  OIIP_MMB: 102,
  OIIP_M3: 103,

  SANCTIONED_INITIAL_LIQUID_MMB: 104,
  SANCTIONED_INITIAL_LIQUID_M3: 105,

  FUEL_LIQUID: 106,
  REMAINING_LIQUID_MMB: 107,
  REMAINING_LIQUID_M3: 108,

  LIQUID_PERCENT_REMAINING: 109,

  // ********* GAS *********
  INITIAL_GAS_BCF: 200,
  INITIAL_GAS_M3: 201,

  GIIP_BCF: 202,
  GIIP_M3: 203,

  SANCTIONED_INITIAL_GAS_BCF: 204,
  SANCTIONED_INITIAL_GAS_M3: 205,

  REMAINING_GAS_BCF: 206,
  REMAINING_GAS_M3: 207,

  FUEL_GAS: 208,
  GAS_PERCENT_REMAINING: 209,

  // ********* DATES *********
  //NTD_START_YEAR: 300,
  //NTD_START_YEAR_SOURCE: 302,
  EXPECTED_SANCTION_YEAR: 303,
  FIELD_APPROVAL_DATE: 304,
  PRODUCTION_START_DATE: 306,
  FIRST_PRODUCTION_YEAR: 307,
  PRODUCTION_CESSATION_DATE: 308,
  DECOMMISSION_YEAR: 310,

  // ********* TECHNICAL *********
  RESERVOIR_AGE_ID: 400,
  TRAP_TYPE_ID: 402,

  RESERVOIR_DEPTH_FT: 404,
  RESERVOIR_DEPTH_M: 405,

  GAS_OIL_CONTACT_FT_TVDSS: 406,
  GAS_OIL_CONTACT_M_TVDSS: 407,

  GAS_WATER_CONTACT_FT_TVDSS: 408,
  GAS_WATER_CONTACT_M_TVDSS: 409,

  OIL_WATER_CONTACT_FT_TVDSS: 410,
  OIL_WATER_CONTACT_M_TVDSS: 411,

  CONTACT_COMMENT: 412,

  GAS_COLUMN_FT: 414,
  GAS_COLUMN_M: 415,

  OIL_COLUMN_FT: 416,
  OIL_COLUMN_M: 417,

  GROSS_RESERVOIR_INTERVAL_AVG_FT: 418,
  GROSS_RESERVOIR_INTERVAL_AVG_M: 419,

  NET_TO_GROSS_RATIO_AVG_FT: 420,
  POROSITY_AVERAGE_PERCENT: 422,
  PERMEABILITY_AVERAGE_MILLIDARCIES: 424,
  HYDROCARBON_SATURATION_PERCENT: 426,
  OIL_DENSITY_DEG_API: 428,
  GAS_GRAVITY_GRAMS_PER_CC: 430,
  VISCOSITY_CENTIPOISE: 432,

  VISCOSITY_TEMPERATURE_FAHRENHEIT: 434,
  VISCOSITY_TEMPERATURE_CELSIUS: 435,

  GAS_TO_OIL_RATIO_SCF_PER_BBL: 436,
  CONDENSATE_TO_GAS_TO_RATIO_STB_PER_MMSCF: 438,

  INITIAL_RESERVOIR_PRESSURE_PSI: 440,
  INITIAL_RESERVOIR_PRESSURE_KPA: 441,

  INITIAL_RESERVOIR_PRESSURE_DEPTH_FT_TVDSS: 442,
  INITIAL_RESERVOIR_PRESSURE_DEPTH_M_TVDSS: 443,

  RESERVOIR_TEMPERATURE_FAHRENHEIT: 444,
  RESERVOIR_TEMPERATURE_CELSIUS: 445,

  INITIAL_RESERVOIR_TEMPARATURE_DEPTH_FT_TVDSS: 446,
  INITIAL_RESERVOIR_TEMPARATURE_DEPTH_M_TVDSS: 447,

  SULPHUR_PERCENT: 448,

  //********** FISCAL **********
  LIQUID_PRICE_DISCOUNT_PREMIUM_PERCENT: 500,
  GAS_PRICE_DISCOUNT_PREMIUM_PERCENT: 502,
  IS_PRT: 506,
  TOTAL_ABEX_USDMM: 508,
  CARRY_FORWARD_2015_ALLOWANCE: 509,

  //********** ADMIN **********
  MAP_VINTAGE: 600,
  SOURCE_COMMENT: 602,
  IMPORT_NAME: 604,
  OFFICIAL_UID: 610,

  //********** DPF FIELD **********
  DPF_FIELD_ID: 700
};
Object.freeze(IDS);

const getSections = (attributes, lookups, valueChanged, resourceNameChanged, lookupsWestwood) => {
  const getResourceStatuses = () => {
    const { statusParentId } = attributes;
    switch (statusParentId) {
      case RESOURCE_PARENT_IDS.FIELD:
        return lookups.ResourceStatus_Field;
      case RESOURCE_PARENT_IDS.DISCOVERY:
        return lookups.ResourceStatus_Discovery;
      case RESOURCE_PARENT_IDS.PROSPECT:
        return lookups.ResourceStatus_Prospect;
      default:
        throw new Error('Unsupported resource status parent id (' + statusParentId + ') supplied to AttributesComponent.getSections.getResourceStatuses()');
    }
  };

  let topRows = { label: 'Resource', rows: [] };
  topRows.rows.push({ label: 'Resource Name', values: [new PropertyGridStringValue(IDS.RESOURCE_NAME, attributes.resourceName, resourceNameChanged)] });
  if (attributes.statusParentId === RESOURCE_PARENT_IDS.FIELD) topRows.rows.push({ label: 'DPF Field', values: [new PropertyGridSelectValue(IDS.DPF_FIELD_ID, attributes.westwoodDataFieldId, valueChanged, 'key', 'value', lookupsWestwood.DPFField, true)] });
  topRows.rows.push({ label: 'Status', values: [new PropertyGridSelectValue(IDS.STATUS_ID, attributes.statusId, attributes.statusParentId === RESOURCE_PARENT_IDS.FIELD ? valueChanged : undefined, 'key', 'value', getResourceStatuses())] });
  topRows.rows.push({ label: 'Block No', values: [new PropertyGridStringValue(IDS.BLOCK_NO, attributes.blockNo)] });
  topRows.rows.push({ label: 'Type', values: [new PropertyGridSelectValue(IDS.TYPE_ID, attributes.typeId, valueChanged, 'key', 'value', lookups.ResourceType)] });
  topRows.rows.push({ label: 'Reservoir Condition', values: [new PropertyGridSelectValue(IDS.CONDITION_ID, attributes.conditionId, valueChanged, 'key', 'value', lookups.ReservoirCondition)] });
  topRows.rows.push({ label: 'Production Scheme', values: [new PropertyGridSelectValue(IDS.PRODUCTION_SCHEME_ID, attributes.productionSchemeId, valueChanged, 'key', 'value', lookups.ProductionScheme)] });
  topRows.rows.push({ label: 'Productivity Group', values: [new PropertyGridSelectValue(IDS.PRODUCTIVITY_GROUP_ID, attributes.productivityGroupId, valueChanged, 'key', 'value', lookups.ProductivityGroup)] });
  topRows.rows.push({ label: 'Development Concept', values: [new PropertyGridSelectValue(IDS.DEVELOPMENT_CONCEPT_ID, attributes.developmentConceptId, valueChanged, 'key', 'value', lookups.DevelopmentConcept)] });
  topRows.rows.push({ label: 'Discovery Classification', values: [new PropertyGridSelectValue(IDS.CLASSIFICATION_ID, attributes.classificationId, valueChanged, 'key', 'value', lookups.DiscoveryClassification)] });
  topRows.rows.push({ label: 'Water Depth', values: [new PropertyGridNumberValueDouble(IDS.WATER_DEPTH_FT, IDS.WATER_DEPTH_M, attributes.waterDepthFt, attributes.waterDepthM, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, valueChanged, 2)] });
  topRows.rows.push({ label: 'Total Recoverable Reserves', values: [new PropertyGridNumberValueDouble(IDS.INITIAL_COMBINED_MMBOE, IDS.INITIAL_COMBINED_M3, attributes.initialCombinedMmboe, attributes.initialCombinedM3, valueTypes.UnitString(valueTypes.mmb), valueTypes.UnitString(valueTypes.msm3), INPUTTYPES.NUMBER, undefined, 2)] });
  topRows.rows.push({ label: 'Remaining Reserves', values: [new PropertyGridNumberValueDouble(IDS.REMAINING_COMBINED_MMBOE, IDS.REMAINING_COMBINED_M3, attributes.remainingCombinedMmboe, attributes.remainingCombinedM3, valueTypes.UnitString(valueTypes.mmb), valueTypes.UnitString(valueTypes.msm3), INPUTTYPES.NUMBER, undefined, 2)] });
  topRows.rows.push({ label: 'Activity Audit Required', values: [new PropertyGridBooleanValue(IDS.ACTIVITY_AUDITED, attributes.activityAudited, valueChanged)] });
  topRows.rows.push({ label: 'Has WGE Shape', values: [new PropertyGridBooleanValue(IDS.HAS_WGE_SHAPE, attributes.hasWGEShape, valueChanged)] });
  topRows.rows.push({ label: 'Stratigraphic Level', values: [new PropertyGridSelectValue(IDS.STRAT_LEVEL_ID, attributes.stratLevelId, valueChanged, 'key', 'value', lookups.StratLevel, true)] });

  const result = [
    //{
    //  label: 'Resource',
    //  rows: [
    //    { label: 'Resource Name', values: [new PropertyGridStringValue(IDS.RESOURCE_NAME, attributes.resourceName, resourceNameChanged)] },
    //    { label: 'DPF Field', values: [new PropertyGridSelectValue(IDS.DPF_FIELD_ID, attributes.westwoodDataFieldId, valueChanged, 'key', 'value', lookupsWestwood.DPFField, true)] },
    //    { label: 'Status', values: [new PropertyGridSelectValue(IDS.STATUS_ID, attributes.statusId, attributes.statusParentId === 1 ? valueChanged : undefined, 'key', 'value', getResourceStatuses())] }, // attributes.statusParentId === 1 === Field === editable
    //    { label: 'Block No', values: [new PropertyGridStringValue(IDS.BLOCK_NO, attributes.blockNo)] },
    //    { label: 'Type', values: [new PropertyGridSelectValue(IDS.TYPE_ID, attributes.typeId, valueChanged, 'key', 'value', lookups.ResourceType)] },
    //    { label: 'Reservoir Condition', values: [new PropertyGridSelectValue(IDS.CONDITION_ID, attributes.conditionId, valueChanged, 'key', 'value', lookups.ReservoirCondition)] },
    //    { label: 'Production Scheme', values: [new PropertyGridSelectValue(IDS.PRODUCTION_SCHEME_ID, attributes.productionSchemeId, valueChanged, 'key', 'value', lookups.ProductionScheme)] },
    //    { label: 'Productivity Group', values: [new PropertyGridSelectValue(IDS.PRODUCTIVITY_GROUP_ID, attributes.productivityGroupId, valueChanged, 'key', 'value', lookups.ProductivityGroup)] },=== 1
    //    { label: 'Development Concept', values: [new PropertyGridSelectValue(IDS.DEVELOPMENT_CONCEPT_ID, attributes.developmentConceptId, valueChanged, 'key', 'value', lookups.DevelopmentConcept)] },
    //    { label: 'Discovery Classification', values: [new PropertyGridSelectValue(IDS.CLASSIFICATION_ID, attributes.classificationId, valueChanged, 'key', 'value', lookups.DiscoveryClassification)] },
    //    { label: 'Water Depth', values: [new PropertyGridNumberValueDouble(IDS.WATER_DEPTH_FT, IDS.WATER_DEPTH_M, attributes.waterDepthFt, attributes.waterDepthM, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, valueChanged, 2)] },
    //    { label: 'Total Recoverable Reserves', values: [new PropertyGridNumberValueDouble(IDS.INITIAL_COMBINED_MMBOE, IDS.INITIAL_COMBINED_M3, attributes.initialCombinedMmboe, attributes.initialCombinedM3, valueTypes.UnitString(valueTypes.mmb), valueTypes.UnitString(valueTypes.msm3), INPUTTYPES.NUMBER, undefined, 2)] },
    //    { label: 'Remaining Reserves', values: [new PropertyGridNumberValueDouble(IDS.REMAINING_COMBINED_MMBOE, IDS.REMAINING_COMBINED_M3, attributes.remainingCombinedMmboe, attributes.remainingCombinedM3, valueTypes.UnitString(valueTypes.mmb), valueTypes.UnitString(valueTypes.msm3), INPUTTYPES.NUMBER, undefined, 2)] },

    //    { label: 'Activity Audit Required', values: [new PropertyGridBooleanValue(IDS.ACTIVITY_AUDITED, attributes.activityAudited, valueChanged)] },
    //    { label: 'Has WGE Shape', values: [new PropertyGridBooleanValue(IDS.HAS_WGE_SHAPE, attributes.hasWGEShape, valueChanged)] },
    //    { label: 'Stratigraphic Level', values: [new PropertyGridSelectValue(IDS.STRAT_LEVEL_ID, attributes.stratLevelId, valueChanged, 'key', 'value', lookups.StratLevel, true)] },
    //  ]
    //},
    topRows,
    {
      label: 'Liquids',
      rows: [
        { label: 'Sanctioned / Expected Liquid Reserves', values: [new PropertyGridNumberValueDouble(IDS.SANCTIONED_INITIAL_LIQUID_MMB, IDS.SANCTIONED_INITIAL_LIQUID_M3, attributes.sanctionedInitialLiquidMMB, attributes.sanctionedInitialLiquidM3, valueTypes.UnitString(valueTypes.mmb), valueTypes.UnitString(valueTypes.msm3), INPUTTYPES.NUMBER, attributes.statusParentId === RESOURCE_PARENT_IDS.FIELD ? valueChanged : undefined, 2)] },
        { label: 'Total Recoverable Liquid Reserves', values: [new PropertyGridNumberValueDouble(IDS.INITIAL_LIQUID_MMB, IDS.INITIAL_LIQUID_M3, attributes.initialLiquidMMB, attributes.initialLiquidM3, valueTypes.UnitString(valueTypes.mmb), valueTypes.UnitString(valueTypes.msm3), INPUTTYPES.NUMBER, attributes.statusParentId !== 1 ? valueChanged : undefined, 2)] },
        { label: 'Remaining Liquid', values: [new PropertyGridNumberValueDouble(IDS.REMAINING_LIQUID_MMB, IDS.REMAINING_LIQUID_M3, attributes.remainingLiquidMMB, attributes.remainingLiquidM3, valueTypes.UnitString(valueTypes.mmb), valueTypes.UnitString(valueTypes.msm3), INPUTTYPES.NUMBER, undefined, 2)] },
        { label: 'Remaining Liquid (' + valueTypes.UnitString(valueTypes.equity) + ')', values: [new PropertyGridPercentageValue(IDS.LIQUID_PERCENT_REMAINING, INPUTTYPES.NUMBER, attributes.liquidPercentRemaining, undefined, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'OIIP', values: [new PropertyGridNumberValueDouble(IDS.OIIP_MMB, IDS.OIIP_M3, attributes.oiipMMB, attributes.oiipM3, valueTypes.UnitString(valueTypes.mmb), valueTypes.UnitString(valueTypes.msm3), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Liquid To Fuel', values: [new PropertyGridBooleanValue(IDS.FUEL_LIQUID, attributes.liquidToFuel, valueChanged)] }
      ]
    },
    {
      label: 'Gas',
      rows: [
        { label: 'Sanctioned / Expected Gas Reserves', values: [new PropertyGridNumberValueDouble(IDS.SANCTIONED_INITIAL_GAS_BCF, IDS.SANCTIONED_INITIAL_GAS_M3, attributes.sanctionedInitialGasBCF, attributes.sanctionedInitialGasM3, valueTypes.UnitString(valueTypes.bcf), valueTypes.UnitString(valueTypes.bsm3), INPUTTYPES.NUMBER, attributes.statusParentId === RESOURCE_PARENT_IDS.FIELD ? valueChanged : undefined, 2)] },
        { label: 'Total Recoverable Gas Reserves', values: [new PropertyGridNumberValueDouble(IDS.INITIAL_GAS_BCF, IDS.INITIAL_GAS_M3, attributes.initialGasBCF, attributes.initialGasM3, valueTypes.UnitString(valueTypes.bcf), valueTypes.UnitString(valueTypes.bsm3), INPUTTYPES.NUMBER, attributes.statusParentId !== 1 ? valueChanged : undefined, 2)] },
        { label: 'Remaining Gas', values: [new PropertyGridNumberValueDouble(IDS.REMAINING_GAS_BCF, IDS.REMAINING_GAS_M3, attributes.remainingGasBCF, attributes.remainingGasM3, valueTypes.UnitString(valueTypes.bcf), valueTypes.UnitString(valueTypes.bsm3), INPUTTYPES.NUMBER, undefined, 2)] },
        { label: 'Remaining Gas (' + valueTypes.UnitString(valueTypes.equity) + ')', values: [new PropertyGridPercentageValue(IDS.GAS_PERCENT_REMAINING, INPUTTYPES.NUMBER, attributes.gasPercentRemaining, undefined, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'GIIP', values: [new PropertyGridNumberValueDouble(IDS.GIIP_BCF, IDS.GIIP_M3, attributes.giipBCF, attributes.giipM3, valueTypes.UnitString(valueTypes.bcf), valueTypes.UnitString(valueTypes.bsm3), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Gas To Fuel', values: [new PropertyGridBooleanValue(IDS.FUEL_GAS, attributes.gasToFuel, valueChanged)] }
      ]
    },
    {
      label: 'Dates',
      rows: [
        //{ label: 'NTD Start Year', values: [new PropertyGridNumberValue(IDS.NTD_START_YEAR, attributes.ntdStartYear, valueChanged)] },
        //{ label: 'NTD Start Year Source', values: [new PropertyGridStringValue(IDS.NTD_START_YEAR_SOURCE, attributes.ntdStartYearSource, valueChanged)] },
        { label: 'Expected Sanction Year', values: [new PropertyGridNumberValue(IDS.EXPECTED_SANCTION_YEAR, INPUTTYPES.YEAR, attributes.expectedSanctionYear, valueChanged)] },
        { label: 'Field Approval Date', values: [new PropertyGridDateValueUTC(IDS.FIELD_APPROVAL_DATE, attributes.fieldApprovalDate, valueChanged)] },
        { label: 'First Production Year', values: [new PropertyGridNumberValue(IDS.FIRST_PRODUCTION_YEAR, INPUTTYPES.YEAR, attributes.firstProductionYear)] },
        { label: 'Field Production Date', values: [new PropertyGridDateValueUTC(IDS.PRODUCTION_START_DATE, attributes.productionStartDate, valueChanged)] },
        { label: 'Production Cessation Date', values: [new PropertyGridDateValueUTC(IDS.PRODUCTION_CESSATION_DATE, attributes.productionCessationDate, valueChanged)] },
        { label: 'Decommission Year', values: [new PropertyGridNumberValue(IDS.DECOMMISSION_YEAR, INPUTTYPES.YEAR, attributes.decommissionYear, valueChanged)] }
      ]
    },
    {
      label: 'Technical',
      rows: [
        { label: 'Reservoir Age', values: [new PropertyGridSelectValue(IDS.RESERVOIR_AGE_ID, attributes.reservoirAgeId, valueChanged, 'key', 'value', lookups.ReservoirAge)] },
        { label: 'Trap Type', values: [new PropertyGridSelectValue(IDS.TRAP_TYPE_ID, attributes.trapTypeId, valueChanged, 'key', 'value', lookups.TrapType)] },
        { label: 'Reservoir Depth', values: [new PropertyGridNumberValueDouble(IDS.RESERVOIR_DEPTH_FT, IDS.RESERVOIR_DEPTH_M, attributes.reservoirDepthFt, attributes.reservoirDepthM, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Gas Oil Contact TVDSS', values: [new PropertyGridNumberValueDouble(IDS.GAS_OIL_CONTACT_FT_TVDSS, IDS.GAS_OIL_CONTACT_M_TVDSS, attributes.gasOilContactFTTVDSS, attributes.gasOilContactMTVDSS, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Gas Water Contact TVDSS', values: [new PropertyGridNumberValueDouble(IDS.GAS_WATER_CONTACT_FT_TVDSS, IDS.GAS_WATER_CONTACT_M_TVDSS, attributes.gasWaterContactFTTVDSS, attributes.gasWaterContactMTVDSS, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Oil Water Contact TVDSS', values: [new PropertyGridNumberValueDouble(IDS.OIL_WATER_CONTACT_FT_TVDSS, IDS.OIL_WATER_CONTACT_M_TVDSS, attributes.oilWaterContactFTTVDSS, attributes.oilWaterContactMTVDSS, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Contact Comment', values: [new PropertyGridStringValue(IDS.CONTACT_COMMENT, attributes.contactComment, valueChanged)] },
        { label: 'Gas Column', values: [new PropertyGridNumberValueDouble(IDS.GAS_COLUMN_FT, IDS.GAS_COLUMN_M, attributes.gasColumnFT, attributes.gasColumnM, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Oil Column', values: [new PropertyGridNumberValueDouble(IDS.OIL_COLUMN_FT, IDS.OIL_COLUMN_M, attributes.oilColumnFT, attributes.oilColumnM, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Gross Reservoir Interval Average', values: [new PropertyGridNumberValueDouble(IDS.GROSS_RESERVOIR_INTERVAL_AVG_FT, IDS.GROSS_RESERVOIR_INTERVAL_AVG_M, attributes.grossReservoirIntervalAvgFT, attributes.grossReservoirIntervalAvgM, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Net to Gross Ratio Average', values: [new PropertyGridNumberValue(IDS.NET_TO_GROSS_RATIO_AVG_FT, INPUTTYPES.NUMBER, attributes.netToGrossRatioAvgFT, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Porosity Average (' + valueTypes.UnitString(valueTypes.equity) + ')', values: [new PropertyGridNumberValue(IDS.POROSITY_AVERAGE_PERCENT, INPUTTYPES.NUMBER, attributes.porosityAveragePercent, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Permeability Average (millidarcies)', values: [new PropertyGridNumberValue(IDS.PERMEABILITY_AVERAGE_MILLIDARCIES, INPUTTYPES.NUMBER, attributes.permeabilityAverageMillidarcies, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Hydrocarbon Saturation (' + valueTypes.UnitString(valueTypes.equity) + ')', values: [new PropertyGridNumberValue(IDS.HYDROCARBON_SATURATION_PERCENT, INPUTTYPES.NUMBER, attributes.hydrocarbonSaturationPercent, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Oil Density (degree API)', values: [new PropertyGridNumberValue(IDS.OIL_DENSITY_DEG_API, INPUTTYPES.NUMBER, attributes.oilDensityDegApi, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Gas Gravity (g/cc)', values: [new PropertyGridNumberValue(IDS.GAS_GRAVITY_GRAMS_PER_CC, INPUTTYPES.NUMBER, attributes.gasGravityGramsPerCC, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Viscosity (centipoise)', values: [new PropertyGridNumberValue(IDS.VISCOSITY_CENTIPOISE, INPUTTYPES.NUMBER, attributes.viscosityCentipoise, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Viscosity Temperature', values: [new PropertyGridNumberValueDouble(IDS.VISCOSITY_TEMPERATURE_FAHRENHEIT, IDS.VISCOSITY_TEMPERATURE_CELSIUS, attributes.viscosityTemperatureFahrenheit, attributes.viscosityTemperatureCelsius, valueTypes.UnitString(valueTypes.farenheit), valueTypes.UnitString(valueTypes.celsius), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Gas to Oil Ratio (scf/bbl)', values: [new PropertyGridNumberValue(IDS.GAS_TO_OIL_RATIO_SCF_PER_BBL, INPUTTYPES.NUMBER, attributes.gasToOilRatioSCFPerBBL, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Condensate to Gas Ratio (stb/mmscf)', values: [new PropertyGridNumberValue(IDS.CONDENSATE_TO_GAS_TO_RATIO_STB_PER_MMSCF, INPUTTYPES.NUMBER, attributes.condensateToGasToRatioSTBPerMMSCF, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Initial Reservoir Pressure', values: [new PropertyGridNumberValueDouble(IDS.INITIAL_RESERVOIR_PRESSURE_PSI, IDS.INITIAL_RESERVOIR_PRESSURE_KPA, attributes.initialReservoirPressurePSI, attributes.initialReservoirPressureKPA, valueTypes.UnitString(valueTypes.psi), valueTypes.UnitString(valueTypes.kiloPascal), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Initial Reservoir Pressure Depth TVDSS', values: [new PropertyGridNumberValueDouble(IDS.INITIAL_RESERVOIR_PRESSURE_DEPTH_FT_TVDSS, IDS.INITIAL_RESERVOIR_PRESSURE_DEPTH_M_TVDSS, attributes.initialReservoirPressureDepthFtTVDSS, attributes.initialReservoirPressureDepthMTVDSS, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Initial Reservoir Temperature', values: [new PropertyGridNumberValueDouble(IDS.RESERVOIR_TEMPERATURE_FAHRENHEIT, IDS.RESERVOIR_TEMPERATURE_CELSIUS, attributes.reservoirTemperatureFahrenheit, attributes.reservoirTemperatureCelsius, valueTypes.UnitString(valueTypes.farenheit), valueTypes.UnitString(valueTypes.celsius), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Initial Reservoir Temperature Depth TVDSS', values: [new PropertyGridNumberValueDouble(IDS.INITIAL_RESERVOIR_TEMPARATURE_DEPTH_FT_TVDSS, IDS.INITIAL_RESERVOIR_TEMPARATURE_DEPTH_M_TVDSS, attributes.initialReservoirTemparatureDepthFtTVDSS, attributes.initialReservoirTemparatureDepthMTVDSS, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, valueChanged, 2)] },
        { label: 'Sulphur (' + valueTypes.UnitString(valueTypes.equity) + ')', values: [new PropertyGridNumberValue(IDS.SULPHUR_PERCENT, INPUTTYPES.NUMBER, attributes.sulphurPercent, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] }
      ]
    },
    {
      label: 'Fiscal',
      rows: [
        { label: 'Liquid Price Discount / Premium (' + valueTypes.UnitString(valueTypes.equity) + ')', values: [new PropertyGridNumberValue(IDS.LIQUID_PRICE_DISCOUNT_PREMIUM_PERCENT, INPUTTYPES.NUMBER, attributes.liquidPriceDiscountPremiumPercent, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Gas Price Discount / Premium (' + valueTypes.UnitString(valueTypes.equity) + ')', values: [new PropertyGridNumberValue(IDS.GAS_PRICE_DISCOUNT_PREMIUM_PERCENT, INPUTTYPES.NUMBER, attributes.gasPriceDiscountPremiumPercent, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Is PRT', values: [new PropertyGridBooleanValue(IDS.IS_PRT, attributes.isPRT, valueChanged)] },
        { label: 'Total Abex (' + valueTypes.UnitString(valueTypes.usdMM) + ')', values: [new PropertyGridNumberValue(IDS.TOTAL_ABEX_USDMM, INPUTTYPES.NUMBER, attributes.totalAbexUSDMM, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Carry Forward 2015 Allowance (' + valueTypes.UnitString(valueTypes.usdMM) + ')', values: [new PropertyGridNumberValue(IDS.CARRY_FORWARD_2015_ALLOWANCE, INPUTTYPES.NUMBER, attributes.carryForward2015Allowance, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] }
      ]
    },
    {
      label: 'Admin',
      rows: [
        //{ label: 'Official UID', values: [new PropertyGridStringValue(IDS.OFFICIAL_UID, attributes.officialUID, valueChanged)] },
        { label: 'Map Vintage', values: [new PropertyGridNumberValue(IDS.MAP_VINTAGE, INPUTTYPES.NUMBER, attributes.mapVintage, valueChanged)] },
        { label: 'Source Comment', values: [new PropertyGridStringValue(IDS.SOURCE_COMMENT, attributes.sourceComment, valueChanged)] },
        //{ label: 'Import Name', values: [new PropertyGridStringValue(IDS.IMPORT_NAME, attributes.importName, valueChanged)] }
      ]
    }
  ];

  if (IN_DEV_MODE) {
    result[0].rows.unshift({ label: 'Resource Id', values: [new PropertyGridStringValue(IDS.RESOURCE_ID, attributes.id)] });
  }

  return result;
};

const LeftPanelComponent = (props) => {
  const {
    editing,
    loadingGlobal,
    messageBoxGlobal,
    lookups,
    attributes,
    resourceAttributesActions,
    resourceAliasActions,
    lookupsWestwood,
  } = props;

  const resourceNameChanged = (value, id) => {
    if (id !== IDS.RESOURCE_NAME) {
      throw new Error('Id ' + id + ' is not supported in AttributesComponent.resourceNameChanged');
    }

    const onSuccess = (result) => {
      if (result) {
        messageBoxGlobal.showWarning('Resource Name ' + value + ' already exists in the Resource Table');
        resourceNameChanged(IDS.RESOURCE_NAME, attributes.resourceName);
      }
      else {
        let newItem = new ResourceAlias(null);
        newItem.getNew(id);
        newItem.statusId = attributes.statusId;
        newItem.alias = value;
        resourceAliasActions.onAdd(newItem);
        resourceAttributesActions.onEdit(attributes);
      }
    };

    if (value === attributes.resourceName) {
      attributes.originalResourceName = '';
      attributes.newResourceName = '';
      //attributes.status = attributes.status;
      if (attributes.resourceAlias.Count > 0) {
        //attributes.resourceAlias[0].endDate = attributes.resourceAlias[0].endDate;
      }
    }
    else {
      attributes.originalResourceName = attributes.resourceName;
      attributes.resourceName = value;
      attributes.newResourceName = value;
      if (attributes.resourceAlias.length > 0) {
        let activeAlias = attributes.resourceAlias.find(x => x.endDate === null);
        if (activeAlias !== null && activeAlias !== undefined) {
          let newDate = formatDate(new Date());
          activeAlias.endDate = getDate(newDate);
          resourceAliasActions.onEdit(activeAlias);
        }
      }
      apiGet('api/resource/nameCheck?name=' + value, loadingGlobal.show, loadingGlobal.hide, messageBoxGlobal, onSuccess, undefined);
    }

    resourceAttributesActions.onEdit(attributes)
  };

  const valueChanged = (value, id) => {
    switch (id) {
      // ********* RESOURCE *********
      case IDS.STATUS_ID: attributes.statusId = Number(value); break;
      case IDS.TYPE_ID: attributes.typeId = Number(value); break;
      case IDS.CONDITION_ID: attributes.conditionId = Number(value); break;
      case IDS.PRODUCTION_SCHEME_ID: attributes.productionSchemeId = Number(value); break;
      case IDS.PRODUCTIVITY_GROUP_ID: attributes.productivityGroupId = Number(value); break;
      case IDS.DEVELOPMENT_CONCEPT_ID: attributes.developmentConceptId = Number(value); break;
      case IDS.CLASSIFICATION_ID: attributes.classificationId = Number(value); break;
      case IDS.WATER_DEPTH_FT: attributes.waterDepthFt = Number(value); break;
      case IDS.WATER_DEPTH_M: attributes.waterDepthFt = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;
      case IDS.ACTIVITY_AUDITED: attributes.activityAudited = value; break;
      case IDS.HAS_WGE_SHAPE: attributes.hasWGEShape = value; break;
      case IDS.STRAT_LEVEL_ID: attributes.stratLevelId = Number(value); break;

      // ********* LIQUIDS *********
      case IDS.INITIAL_LIQUID_MMB: attributes.initialLiquidMMB = Number(value); break;
      case IDS.INITIAL_LIQUID_M3: attributes.initialLiquidMMB = unitConversions.Convert(unitConversions.cubicMeterToBOE, Number(value)); break;

      case IDS.OIIP_MMB: attributes.oiipMMB = Number(value); break;
      case IDS.OIIP_M3: attributes.oiipMMB = unitConversions.Convert(unitConversions.cubicMeterToBOE, Number(value)); break;

      case IDS.SANCTIONED_INITIAL_LIQUID_MMB: attributes.sanctionedInitialLiquidMMB = Number(value); break;
      case IDS.SANCTIONED_INITIAL_LIQUID_M3: attributes.sanctionedInitialLiquidMMB = unitConversions.Convert(unitConversions.cubicMeterToBOE, Number(value)); break;

      case IDS.FUEL_LIQUID: attributes.liquidToFuel = value; break;

      // ********* GAS *********
      case IDS.INITIAL_GAS_BCF: attributes.initialGasBCF = Number(value); break;
      case IDS.INITIAL_GAS_M3: attributes.initialGasBCF = unitConversions.Convert(unitConversions.cubicMeterToCubicFeet, Number(value)); break;

      case IDS.GIIP_BCF: attributes.giipBCF = Number(value); break;
      case IDS.GIIP_M3: attributes.giipBCF = unitConversions.Convert(unitConversions.cubicMeterToCubicFeet, Number(value)); break;

      case IDS.SANCTIONED_INITIAL_GAS_BCF: attributes.sanctionedInitialGasBCF = Number(value); break;
      case IDS.SANCTIONED_INITIAL_GAS_M3: attributes.sanctionedInitialGasBCF = unitConversions.Convert(unitConversions.cubicMeterToCubicFeet, Number(value)); break;

      case IDS.FUEL_GAS: attributes.gasToFuel = value; break;

      // ********* DATES *********
      //case IDS.NTD_START_YEAR: attributes.ntdStartYear = value; break;
      //case IDS.NTD_START_YEAR_SOURCE: attributes.ntdStartYearSource = value; break;
      case IDS.EXPECTED_SANCTION_YEAR: attributes.expectedSanctionYear = value; break;
      case IDS.FIELD_APPROVAL_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid Field Approval Date');
          return false;
        }
        attributes.fieldApprovalDate = value === undefined ? attributes.fieldApprovalDate = null : getDate(value); break;
      case IDS.PRODUCTION_START_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid Field Production Date');
          return false;
        }
        attributes.productionStartDate = value === undefined ? attributes.productionStartDate = null : getDate(value); break;
      case IDS.PRODUCTION_CESSATION_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid Production Cessation Date');
          return false;
        }
        attributes.productionCessationDate = value === undefined ? attributes.productionCessationDate = null : getDate(value); break;

      case IDS.DECOMMISSION_YEAR: attributes.decommissionYear = value; break;

      // ********* TECHNICAL *********
      case IDS.RESERVOIR_AGE_ID: attributes.reservoirAgeId = Number(value); break;
      case IDS.TRAP_TYPE_ID: attributes.trapTypeId = Number(value); break;

      case IDS.RESERVOIR_DEPTH_FT: attributes.reservoirDepthFt = Number(value); break;
      case IDS.RESERVOIR_DEPTH_M: attributes.reservoirDepthFt = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;

      case IDS.GAS_OIL_CONTACT_FT_TVDSS: attributes.gasOilContactFTTVDSS = Number(value); break;
      case IDS.GAS_OIL_CONTACT_M_TVDSS: attributes.gasOilContactFTTVDSS = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;

      case IDS.GAS_WATER_CONTACT_FT_TVDSS: attributes.gasWaterContactFTTVDSS = Number(value); break;
      case IDS.GAS_WATER_CONTACT_M_TVDSS: attributes.gasWaterContactFTTVDSS = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;

      case IDS.OIL_WATER_CONTACT_FT_TVDSS: attributes.oilWaterContactFTTVDSS = Number(value); break;
      case IDS.OIL_WATER_CONTACT_M_TVDSS: attributes.oilWaterContactFTTVDSS = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;

      case IDS.CONTACT_COMMENT: attributes.contactComment = value; break;

      case IDS.GAS_COLUMN_FT: attributes.gasColumnFT = Number(value); break;
      case IDS.GAS_COLUMN_M: attributes.gasColumnFT = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;

      case IDS.OIL_COLUMN_FT: attributes.oilColumnFT = value; break;
      case IDS.OIL_COLUMN_M: attributes.oilColumnFT = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;

      case IDS.GROSS_RESERVOIR_INTERVAL_AVG_FT: attributes.grossReservoirIntervalAvgFT = Number(value); break;
      case IDS.GROSS_RESERVOIR_INTERVAL_AVG_M: attributes.grossReservoirIntervalAvgFT = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;

      case IDS.NET_TO_GROSS_RATIO_AVG_FT: attributes.netToGrossRatioAvgFT = Number(value); break;
      case IDS.POROSITY_AVERAGE_PERCENT: attributes.porosityAveragePercent = Number(value); break;
      case IDS.PERMEABILITY_AVERAGE_MILLIDARCIES: attributes.permeabilityAverageMillidarcies = Number(value); break;
      case IDS.HYDROCARBON_SATURATION_PERCENT: attributes.hydrocarbonSaturationPercent = Number(value); break;
      case IDS.OIL_DENSITY_DEG_API: attributes.oilDensityDegApi = Number(value); break;
      case IDS.GAS_GRAVITY_GRAMS_PER_CC: attributes.gasGravityGramsPerCC = Number(value); break;
      case IDS.VISCOSITY_CENTIPOISE: attributes.viscosityCentipoise = Number(value); break;

      case IDS.VISCOSITY_TEMPERATURE_FAHRENHEIT: attributes.viscosityTemperatureFahrenheit = Number(value); break;
      case IDS.VISCOSITY_TEMPERATURE_CELSIUS: attributes.viscosityTemperatureFahrenheit = unitConversions.Convert(unitConversions.celsiusToFahrenheit, Number(value)); break;

      case IDS.GAS_TO_OIL_RATIO_SCF_PER_BBL: attributes.gasToOilRatioSCFPerBBL = Number(value); break;
      case IDS.CONDENSATE_TO_GAS_TO_RATIO_STB_PER_MMSCF: attributes.condensateToGasToRatioSTBPerMMSCF = Number(value); break;

      case IDS.INITIAL_RESERVOIR_PRESSURE_PSI: attributes.initialReservoirPressurePSI = Number(value); break;
      case IDS.INITIAL_RESERVOIR_PRESSURE_KPA: attributes.initialReservoirPressurePSI = unitConversions.Convert(unitConversions.kilopascalToPsi, Number(value)); break;

      case IDS.INITIAL_RESERVOIR_PRESSURE_DEPTH_FT_TVDSS: attributes.initialReservoirPressureDepthFtTVDSS = Number(value); break;
      case IDS.INITIAL_RESERVOIR_PRESSURE_DEPTH_M_TVDSS: attributes.initialReservoirPressureDepthFtTVDSS = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;

      case IDS.RESERVOIR_TEMPERATURE_FAHRENHEIT: attributes.reservoirTemperatureFahrenheit = Number(value); break;
      case IDS.RESERVOIR_TEMPERATURE_CELSIUS: attributes.reservoirTemperatureFahrenheit = unitConversions.Convert(unitConversions.celsiusToFahrenheit, Number(value)); break;

      case IDS.INITIAL_RESERVOIR_TEMPARATURE_DEPTH_FT_TVDSS: attributes.initialReservoirTemparatureDepthFtTVDSS = Number(value); break;
      case IDS.INITIAL_RESERVOIR_TEMPARATURE_DEPTH_M_TVDSS: attributes.initialReservoirTemparatureDepthFtTVDSS = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;

      case IDS.SULPHUR_PERCENT: attributes.sulphurPercent = Number(value); break;

      //********** FISCAL **********
      case IDS.LIQUID_PRICE_DISCOUNT_PREMIUM_PERCENT: attributes.liquidPriceDiscountPremiumPercent = Number(value); break;
      case IDS.GAS_PRICE_DISCOUNT_PREMIUM_PERCENT: attributes.gasPriceDiscountPremiumPercent = Number(value); break;
      case IDS.IS_PRT: attributes.isPRT = value; break;
      case IDS.TOTAL_ABEX_USDMM: attributes.totalAbexUSDMM = Number(value); break;
      case IDS.CARRY_FORWARD_2015_ALLOWANCE: attributes.carryForward2015Allowance = Number(value); break;

      //********** ADMIN **********
      case IDS.OFFICIAL_UID: attributes.officialUID = value; break;
      case IDS.MAP_VINTAGE: attributes.mapVintage = value; break;
      case IDS.SOURCE_COMMENT: attributes.sourceComment = value; break;
      case IDS.IMPORT_NAME: attributes.importName = value; break;

      //********** DPF FIELD **********
      case IDS.DPF_FIELD_ID: attributes.westwoodDataFieldId = value; break;

      default: throw new Error('Id ' + id + ' is not supported in AttributesComponent.valueChanged');
    }
    resourceAttributesActions.onEdit(attributes)
    return true;
  };

  const sections = getSections(attributes, lookups, valueChanged, resourceNameChanged, lookupsWestwood);

  return <PropertyGridComponent sections={sections} readOnly={!editing} />;
};

LeftPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  attributes: PropTypes.instanceOf(Attributes).isRequired,
  resourceAttributesActions: PropTypes.shape({ onEdit: PropTypes.func.isRequired }).isRequired,
  lookupsWestwood: PropTypes.instanceOf(StringLookupResult).isRequired,
};

export default LeftPanelComponent;
