import React from 'react';
import PropTypes from 'prop-types';

import { ResourceAnnual } from '../../models/resource-annual';
import { valueTypes } from '../../../shared/helpers/value-handler';

import AGGridComponent, {
  COLUMN_SMALL,
  COLUMN_MEDIUM,
  COLUMN_MEDIUM_MEDIUM,
  DoubleFormatter,
  ReadOnlyNumberFormatter,
  ReadOnlyDoubleFormatter,
  numberParser,
  GRIDTYPES
} from './ag-grid-component';
import { MessageBoxGlobal } from '../../../common/globals/globals';

const OpexTypes = [
  { id: 1, title: 'Calculated' },
  { id: 2, title: 'Manual' },
  { id: 3, title: 'Percent' }
];
Object.freeze(OpexTypes);

const ResourceAnnualsGridComponent = (props) => {
  const { resourceAnnuals, editing, readOnly, rowAdded, top, scrollToTop, startYear, endYear, editYear, enableDeclineCurve, handleCalculatedValues } = props;

  var opexTypesAG = { "1": "Calculated", "2": "Manual" };

  const setCellClass = (params) => {
    return ((enableDeclineCurve && params.data.year > editYear) || params.data.isAutoAbex) ? 'ag-grey-out-class' : 'ag-normal-class';
  }

  const columnDefs = [];

  const yearGroup = [];
  yearGroup.push({
    headerName: "Year", suppressHeaderMenuButton: true, field: "year", sortable: false, filter: 'agNumberColumnFilter', filterParams: { newRowsAction: 'keep' }, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyNumberFormatter, pinned: 'left', type: 'numeric', suppressSizeToFit: true
  });
  columnDefs.push({ headerName: 'Year', children: yearGroup });

  const snapShotGroup = [];
  snapShotGroup.push({ headerName: "Liquid (" + valueTypes.UnitString(valueTypes.mboed) + ")", suppressHeaderMenuButton: true, field: "snapshotLiquidMbod", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true });
  snapShotGroup.push({ headerName: "Gas (" + valueTypes.UnitString(valueTypes.mmscfd) + ")", suppressHeaderMenuButton: true, field: "snapshotGasMmscfd", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true });
  snapShotGroup.push({ headerName: "Capex (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "snapshotCapexUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true });
  snapShotGroup.push({ headerName: "Opex (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "snapshotOpexUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true });
  snapShotGroup.push({ headerName: "Cumulative Operating Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "snapshotCumulativeOperatingCashFlowUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_MEDIUM_MEDIUM, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true });
  snapShotGroup.push({ headerName: "Abex (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "snapshotAbexUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true });

  columnDefs.push({ headerName: 'Last Snapshot', children: snapShotGroup });

  const liquidGroup = [];
  liquidGroup.push({
    headerName: "Liquid (" + valueTypes.UnitString(valueTypes.mboed) + ")",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, field: "liquidMbod", sortable: false, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true
  });
  liquidGroup.push({
    headerName: "Liquid Price (" + valueTypes.UnitString(valueTypes.usdBOE) + ")",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, field: "liquidPriceUSDBOE", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true
  });
  if (!readOnly) liquidGroup.push({
    headerName: "Liquid Decline (" + valueTypes.UnitString(valueTypes.equity) + ")",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, field: "liquidDeclinePercent", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'percent', suppressSizeToFit: true
  });
  liquidGroup.push({ headerName: "Cumulative Liquid (" + valueTypes.UnitString(valueTypes.mmb) + ")", suppressHeaderMenuButton: true, field: "cumulativeLiquidMMB", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true });
  columnDefs.push({ headerName: 'Liquid', children: liquidGroup });

  const gasGroup = [];
  gasGroup.push({
    headerName: "Gas (" + valueTypes.UnitString(valueTypes.mmscfd) + ")",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, field: "gasMmscfd", sortable: false, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true
  });
  gasGroup.push({
    headerName: "Gas Price (" + valueTypes.UnitString(valueTypes.usdMM) + ")",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, field: "gasPriceUSDMCF", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true
  });
  gasGroup.push({
    headerName: "Gas Decline (" + valueTypes.UnitString(valueTypes.equity) + ")",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, field: "gasDeclinePercent", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'percent', suppressSizeToFit: true
  });
  gasGroup.push({ headerName: "Cumulative Gas (" + valueTypes.UnitString(valueTypes.bcf) + ")", suppressHeaderMenuButton: true, field: "cumulativeGasBCF", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true });
  columnDefs.push({ headerName: 'Gas', children: gasGroup });

  const capexGroup = [];
  capexGroup.push({
    headerName: "Capex (" + valueTypes.UnitString(valueTypes.usdMM) + ")",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, field: "capexUSDMM", sortable: false, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: readOnly ? ReadOnlyDoubleFormatter : DoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true
  });
  columnDefs.push({ headerName: 'Capex', children: capexGroup });

  const opexGroup = [];
  if (!readOnly) opexGroup.push({
    headerName: "Manual Hub Opex (" + valueTypes.UnitString(valueTypes.usdMM) + ")",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, field: "opexManualUsdMM", sortable: false, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: readOnly ? ReadOnlyDoubleFormatter : DoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true
  });
  opexGroup.push({
    headerName: "Field Specific Opex (" + valueTypes.UnitString(valueTypes.usdMM) + ")",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, field: "fixedOpexUsdMm", sortable: false, filter: false, editable: editing, width: COLUMN_MEDIUM, cellRenderer: readOnly ? ReadOnlyDoubleFormatter : DoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true
  });
  opexGroup.push({
    headerName: "Opex Type",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, cellEditorPopup:true, field: "opexTypeId", sortable: false, filter: false, type: 'dropdown', suppressSizeToFit: true, singleClickEdit: true,
    editable: editing, width: COLUMN_MEDIUM, cellEditor: "agRichSelectCellEditor", cellEditorParams: {
      values: extractValues(opexTypesAG)
    },
    valueFormatter: function (params) {
      return lookupValue(opexTypesAG, params.value);
    },
    valueParser: function (params) {
      return lookupKey(opexTypesAG, params.newValue);
    }
  });
  opexGroup.push({ headerName: "Actual OPEX (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "opexActualUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true, enablePivot: true });
  columnDefs.push({ headerName: 'Opex', children: opexGroup });

  const tarriffGroup = [];
  tarriffGroup.push({
    headerName: "Liquid Tariff (" + valueTypes.UnitString(valueTypes.usdBOE) + ")",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, field: "liquidTariffUSDBOE", sortable: false, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true
  });
  tarriffGroup.push({
    headerName: "Gas Tariff (" + valueTypes.UnitString(valueTypes.usdBOE) + ")",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, field: "gasTariffUSDBOE", sortable: false, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true
  });
  tarriffGroup.push({ headerName: "Total Tariff (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "totalTariffUSDMM", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });
  columnDefs.push({ headerName: 'Tariff', children: tarriffGroup });

  const emissionsGroup = [];
  emissionsGroup.push({
    headerName: "ETS Charge (" + valueTypes.UnitString(valueTypes.usdMM) + ")",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, field: "etsChargeUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true
  });
  emissionsGroup.push({
    headerName: "Carbon Charge (" + valueTypes.UnitString(valueTypes.usdMM) + ")",
    cellClass: setCellClass,
    suppressHeaderMenuButton: true, field: "carbonChargeUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true
  });
  emissionsGroup.push({ headerName: "Total Emissions Charge (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "totalEmissionsChargeUSDMM", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });
  columnDefs.push({ headerName: 'Emissions', children: emissionsGroup });

  const abexGroup = [];
  abexGroup.push({
    headerName: "Abex (" + valueTypes.UnitString(valueTypes.usdMM) + ")",
    cellClass: setCellClass,
    field: "abexUSDMM", suppressHeaderMenuButton: true, sortable: false, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: readOnly ? ReadOnlyDoubleFormatter : DoubleFormatter, valueParser: numberParser, type: 'numeric', suppressSizeToFit: true
  });
  columnDefs.push({ headerName: 'Abex', children: abexGroup });

  const revenueGroup = [];
  revenueGroup.push({ headerName: "Revenue (" + valueTypes.UnitString(valueTypes.usdMM) + ")", field: "revenueUsdMM", suppressHeaderMenuButton: true, sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });
  columnDefs.push({ headerName: 'Revenue', children: revenueGroup });

  const cashflowGroup = [];
  cashflowGroup.push({ headerName: "Pre Tax Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "preTaxCashFlowUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_MEDIUM, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });
  cashflowGroup.push({ headerName: "Cumulative Pre Tax Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "cumulativePreTaxCashflowUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_MEDIUM_MEDIUM, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });
  cashflowGroup.push({ headerName: "Cumulative Operating Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "cumulativeOperatingCashFlowUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_MEDIUM_MEDIUM, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });
  columnDefs.push({ headerName: 'Pre-Tax Cashflow', children: cashflowGroup });

  const taxGroup = [];
  taxGroup.push({ headerName: "Corporate Tax (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "corparateTaxUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });
  taxGroup.push({ headerName: "Special Tax (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "specialTaxUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });
  taxGroup.push({ headerName: "Energy Profit Levy (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "levyUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });
  columnDefs.push({ headerName: 'Tax', children: taxGroup });

  const postTaxCashflowGroup = [];
  postTaxCashflowGroup.push({ headerName: "Post Tax Undiscounted Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "undiscountedPostTaxCashFlowUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_MEDIUM_MEDIUM, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });
  postTaxCashflowGroup.push({ headerName: "Post Tax Discounted Cashflow ($" + valueTypes.UnitString(valueTypes.usdMM) + "mm)", suppressHeaderMenuButton: true, field: "discountedPostTaxCashFlowUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_MEDIUM_MEDIUM, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });
  postTaxCashflowGroup.push({ headerName: "Cumulative Discounted Post Tax Cashflow (" + valueTypes.UnitString(valueTypes.usdMM) + ")", suppressHeaderMenuButton: true, field: "cumulativeDiscountedPostTaxCashFlowUsdMM", sortable: false, filter: false, editable: false, width: COLUMN_MEDIUM_MEDIUM, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });
  columnDefs.push({ headerName: 'Post-Tax Cashflow', children: postTaxCashflowGroup });

  const falseGroup = [];
  postTaxCashflowGroup.push({ headerName: "falseColumn", hide: true, suppressColumnsToolPanel: true, suppressHeaderMenuButton: true, field: "falseColumn", sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, type: 'numeric', suppressSizeToFit: true });
  columnDefs.push({ headerName: 'na', children: falseGroup });

  const generalGroup = [];
  generalGroup.push({ headerName: "Production Share (" + valueTypes.UnitString(valueTypes.equity) + ")", field: "hubProductionPercent", suppressHeaderMenuButton: true, sortable: false, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyDoubleFormatter, valueParser: numberParser, type: 'percent', suppressSizeToFit: true });
  columnDefs.push({ headerName: 'General', children: generalGroup });

  const numColumns = ["liquidMbod", "gasMmscfd", "capexUSDMM", "opexManualUsdMM", "liquidTariffUSDBOE", "gasTariffUSDBOE", "abexUSDMM"];
  const numPositiveColumns = ["liquidMbod", "gasMmscfd"];
  const numPercentColumns = ["liquidDeclinePercent", "gasDeclinePercent", "opexCostSharePercent", "hubProductionPercent"];
  const selectColumns = ["opexTypeId"];

  function extractValues(mappings) {
    return Object.keys(mappings);
  }

  function lookupValue(mappings, key) {
    return mappings[key];
  }

  function lookupKey(mappings, name) {
    for (var key in mappings) {
      if (mappings.hasOwnProperty(key)) {
        if (name === mappings[key]) {
          return key;
        }
      }
    }
  }

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {}
  };

  return (
    <AGGridComponent
      messageBoxGlobal={props.messageBoxGlobal}
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      gridData={resourceAnnuals}
      rowsCount={resourceAnnuals.length}
      editing={editing}
      rowAdded={rowAdded}
      top={top}
      allowPaste
      scrollToTop={scrollToTop}
      id={props.id}
      numColumns={numColumns}
      numPercentColumns={numPercentColumns}
      numPositiveColumns={numPositiveColumns}
      selectColumns={selectColumns}
      canButtonsEnable={props.canButtonsEnable}
      startYear={startYear}
      endYear={endYear}
      gridType={GRIDTYPES.RESOURCE}
      editYear={editYear}
      enableDeclineCurve={enableDeclineCurve}
      handleCalculatedValues={handleCalculatedValues}
      activeComponent={props.activeComponent}
    />
  );
};

ResourceAnnualsGridComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  editing: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  resourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  id: PropTypes.number,
  canButtonsEnable: PropTypes.func.isRequired,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  handleCalculatedValues: PropTypes.func.isRequired
};

export default ResourceAnnualsGridComponent;
export { ResourceAnnual, OpexTypes };
