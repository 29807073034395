import React from 'react';
import PropTypes from 'prop-types';

import { ResourceAnnual } from '../../../models/result';

import ProductionChart from '../../../../../common/components/chart/production-chart';

const ProductionComponent = (props) => {
  const {
    resourceAnnuals
  } = props;

  return (
    <ProductionChart
      entityData={resourceAnnuals}
      isStacked="true"
      chartType="Line"
      hubMinYear={0}
      hubMaxYear={0}
      maxCombinedValue={0}
      xTitle="Year"
      yTitle="Mboed"
    />
  );
};

ProductionComponent.propTypes = {
  resourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired
};

export default ProductionComponent;
