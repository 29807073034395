//import { Button } from 'reactstrap';
import './rob-test-view.scss';
import { apiGet, apiPost, apiPostFile/*, apiPost, callApiDefaultOnFail*/ } from '../../common/helpers/api-helpers';
import { Views, ViewGlobal, IdGlobal, LoadingGlobal, MessageBoxGlobal, CountriesGlobal, EditingGlobal, SearchGlobal } from '../../common/globals/globals';

import React from 'react';

class RobTestView extends React.Component {
  constructor() {
    super();

    this.inputFileRef = React.createRef();

    this.onButtonClick = this.onButtonClick.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);

    this.state = { fileOutput: '', file: null, fileName: ''};
  };

  onSubmitButtonClick = () => {
    var file = new FormData();

    file.append(this.state.fileName, this.state.file);

    apiPostFile('api/fileupload/loadTheFile', file, LoadingGlobal.show, LoadingGlobal.hide, MessageBoxGlobal, undefined, undefined);
  };

  onButtonClick = () => {
    this.inputFileRef.current.click();
  };

  onChangeFile = (event) => {
    var file = event.target.files[0];
    console.log(file);
    var iFileOutput = 
      <li key={file.name}>
        {file.name} 
      </li>
    this.setState({ fileOutput: iFileOutput, file: file, fileName: file.name})
  }

  render() {
    return (
      <div>
        <input type="file" id="file" ref={this.inputFileRef} style={{ display: 'none' }} onChange={this.onChangeFile} />
        <button onClick={this.onButtonClick}>Open file upload window</button> 
        {this.state.fileOutput}
        <button onClick={this.onSubmitButtonClick}>Submit a file</button>
      </div>
    );
  }
};







//const RobTestView = (props) => {
//  const dropzoneRef = createRef();
//  const openDialog = () => {
//    // Note that the ref is set async,
//    // so it might be null at some point 
//    if (dropzoneRef.current) {
//      dropzoneRef.current.open()
//    }
//  };

//  // Disable click and keydown behavior on the <Dropzone>
//  return (
//    <Dropzone ref={dropzoneRef} noClick noKeyboard>
//      {({ getRootProps, getInputProps, acceptedFiles }) => {
//        console.log(' files ' + acceptedFiles);
//        //if (acceptedFiles.length !== 1)
//        //  return null;
//        //else

//        var fileOutput = '';
//        if (acceptedFiles.length > 1) {
//          fileOutput = <span>Select one file</span>
//        }
//        else if (acceptedFiles.length === 1) {
//          console.log(' files ' + window.URL.createObjectURL(acceptedFiles[0]));
//          fileOutput = acceptedFiles.map(file => (
//            <li key={file.path}>
//              {file.path} - {file.size} bytes
//            </li>
//          ))
//        };

//        return (
//          <div className="container">
//            <div {...getRootProps({ className: 'dropzone' })}>
//              <input {...getInputProps()} />
//              <p>Drag 'n' drop some files here</p>
//              <button
//                type="button"
//                onClick={openDialog}
//              >
//                Open File Dialog
//              </button>
//            </div>
//            <aside>
//              <h4>Files</h4>
//              {fileOutput}
//            </aside>
//          </div>
//        );
//      }}
//    </Dropzone>
//  );


//};


//<ul>
//  {acceptedFiles.map(file => (
//    <li key={file.path}>
//      {file.path} - {file.size} bytes
//    </li>
//  ))}
//</ul>




//const RobTestView = (props) => {

//  const loadExcel = () => {
//    var theFile = 'c:\\excelexportgme\\test1.xlsx';
//    //var theFile = 'test1';
//    apiGet('api/fileupload/loadFile?fileLocation=' + theFile, null, null, null, undefined, undefined);
//  }

//  return (
//    <div className="view-panel rob-test-view">
//      <h1>RobTestView</h1>
//      <Button size="sm" className="btn-refresh btn-image" onClick={() => loadExcel()}><span>Load Excel</span></Button>
//    </div>
//  );
//};

RobTestView.propTypes = {
}

export default RobTestView;

////import React from "react";
//////import ReactDOM from "react-dom";
////import ReactDataGrid from "react-data-grid";

////class RobTestView extends React.Component {
////  constructor(props) {
////    super(props);
////    this._columns = [
////      {
////        key: "id",
////        name: "ID"
////      },
////      {
////        key: "title",
////        name: "Title"
////      },
////      {
////        key: "count",
////        name: "Count"
////      }
////    ];

////    let rows = [];
////    for (let i = 1; i < 1000; i++) {
////      rows.push({
////        id: i,
////        title: "Title " + i,
////        count: i * 1000
////      });
////    }
////    this.state = { rows, selectedIndexes: [0] };
////  }

////  rowGetter = i => {
////    return this.state.rows[i];
////  };

////  onRowsSelected = rows => {

////    //this.setState({
////    //selectedIndexes: this.state.selectedIndexes.concat(
////    //rows.map(r => r.rowIdx)
////    //)
////    //});
////    //console.log(rows);
////    this.setState({
////      //selectedIndexes: rows.rowIdx
////      selectedIndexes: rows.map(r => r.rowIdx)
////    });


////    //console.log(this.state.selectedIndexes);
////    //console.log(rows.length);
////    if (rows.length > 1) {
////      //console.log('hi ' + rows.length);
////      this.setState({ selectedIndexes: [0] });
////    }
////  };

////  onRowsDeselected = rows => {
////    //let rowIndexes = rows.map(r => r.rowIdx);
////    console.log(this.state.selectedIndexes);
////    //this.setState({
////    //selectedIndexes: this.state.selectedIndexes.filter(
////    //i => rowIndexes.indexOf(i) === -1
////    // )
////    //});

////    //console.log(this.state.selectedIndexes);
////    //if(this.selectedIndexes === []){
////    //this.setState({selectedIndexes: [0] });
////    //}

////  };

////  onCellSelected = ({ rowIdx, idx }) => {
////    //console.log('rowIdx ' + rowIdx + ' idx' + idx);
////    document.getElementsByClassName('rdg-selected')[0].style.opacity = 0;
////  };

////  render() {
////    const rowText = this.state.selectedIndexes.length === 1 ? "row" : "rows";
////    return (
////      <div>
////        <span>
////          {this.state.selectedIndexes.length} {rowText} selected
////        </span>
////        <ReactDataGrid
////          rowKey="id"
////          columns={this._columns}
////          rowGetter={this.rowGetter}
////          rowsCount={this.state.rows.length}
////          minHeight={500}
////          enableCellSelect={false}
////          onCellSelected={this.onCellSelected}
////          rowSelection={{
////            showCheckbox: true,
////            enableShiftSelect: true,
////            onRowsSelected: this.onRowsSelected,
////            onRowsDeselected: this.onRowsDeselected,
////            selectBy: {
////              indexes: this.state.selectedIndexes
////            }
////          }}
////        />
////      </div>
////    );
////  }
////}

//////const rootElement = document.getElementById("root");
//////ReactDOM.render(<Example />, rootElement);

////export default RobTestView;



//////import React, { Component } from 'react';
////////import logo from './logo.svg';
////////import './App.scss';
//////import { AgGridReact } from 'ag-grid-react';
//////import 'ag-grid-community/dist/styles/ag-grid.css';
//////import 'ag-grid-community/dist/styles/ag-theme-balham.css';

//////import './rob-test-view.scss';

//////class RobTestView extends Component {
//////  constructor(props) {
//////    super(props);
//////    this.state = {
//////      columnDefs: [{
//////        headerName: "Make", field: "make", type: 'text', sortable: true, editable: true
//////      }, {
//////          headerName: "Model", field: "model", type: 'text', sortable: true, editable: true
//////      }, {
//////          headerName: "Price", field: "price", type: 'numeric', sortable: true, editable: true
//////      }],
//////      rowData: [{
//////        make: "Toyota", model: "Celica", price: 35000
//////      }, {
//////        make: "Ford", model: "Mondeo", price: 32000
//////      }, {
//////        make: "Porsche", model: "Boxter", price: 72000
//////        }, {
//////          make: "Porsche1", model: "Boxtersdf", price: 61000
//////        }, {
//////          make: "Porsche2", model: "Boxteradasd", price: 88000
//////        }, {
//////          make: "Porsche3", model: "Boxterdd", price: 72345
//////        }, {
//////          make: "Porsche4", model: "Boxterada", price: 73300
//////        }, {
//////          make: "Porsche5", model: "Boxteradasfd", price: 11100
//////        }],
//////      columnTypes: ["numeric", "text", "percent", "none"]
//////    }
//////  }

//////  render() {
//////    return (
//////      <div
//////        className="ag-theme-balham"
//////        style={{
//////          height: '500px',
//////          width: '600px'
//////        }}
//////      >
//////        <AgGridReact
//////          columnDefs={this.state.columnDefs}
//////          columnTypes={this.state.columnTypes}
//////          rowData={this.state.rowData}
//////          enableRangeSelection>
//////        </AgGridReact>
//////      </div>
//////    );
//////  }
//////}

//////RobTestView.propTypes = {
//////}

//////export default RobTestView;
