import React from 'react';

import AppComponent from './components/app-component';
import { AppConfiguration } from '../common/helpers/app-configuration';
import { WithAuth } from './msal/msal-auth-provider';

const RootApp = (props) => {
  const appSiteConfig = AppConfiguration.Setting();
  const version = appSiteConfig.appVersion;
  document.title = 'GMatrix Editor v' + version.major + '.' + version.minor + '.' + version.build;

  if (props.auth.isAuthenticated) {
    sessionStorage.setItem('userName', props.auth.user.userName);
    return <AppComponent version={version} logout={props.onSignOut} />
  }
};

export const App = WithAuth(RootApp);
