import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as ViewFunctions from '../view-common/view-functions';
import './hub-view.scss';

// Hub models
import { Result } from './models/result';
import { PostbackInfo } from '../view-common/postback-info';

// Hub components
import TopPanelComponent from './components/top-panel/top-panel-component';
import BottomPanelComponent from './components/bottom-panel/bottom-panel-component';

// Common models
import { RECORD_STATUS } from './../../common/models/record-status';
import { Views, ViewGlobal, IdGlobal, LoadingGlobal, MessageBoxGlobal, CountriesGlobal, EditingGlobal, SearchGlobal, PinLocationGlobal } from '../../common/globals/globals';
// Common components
import EditableViewComponent, { layerType } from '../../common/components/editable-view/editable-view-component';

// Shared components
import SplitterPanelComponent from '../../shared/components/splitter-panel/splitter-panel-component';

import { RELATED_ITEM_TYPE } from '../../common/models/related-item-type';
import { GMATRIX_ENTITY } from '../../common/models/gmatrix-entity';

class HubView extends React.Component {
  constructor(props) {
    super(props);

    this.beginEdit = ViewFunctions.beginEdit.bind(this);
    this.cancelEdit = ViewFunctions.cancelEdit.bind(this);
    this.canButtonsEnable = ViewFunctions.canButtonsEnable.bind(this);
    this.canPerformAction = ViewFunctions.canPerformAction.bind(this);
    this.copyPinLocationToLocationObject = ViewFunctions.copyPinLocationToLocationObject.bind(this);
    this.deleteEntity = ViewFunctions.deleteEntity.bind(this);
    this.didMount = ViewFunctions.didMount.bind(this);
    this.getActions = ViewFunctions.getActions.bind(this);
    this.getCurrentEntity = ViewFunctions.getCurrentEntity.bind(this);
    this.getData = ViewFunctions.getData.bind(this);
    this.initData = ViewFunctions.initData.bind(this);
    this.newData = ViewFunctions.newData.bind(this);
    this.populatePostbackInfo = ViewFunctions.populatePostbackInfo.bind(this);
    this.refresh = ViewFunctions.refresh.bind(this);
    this.resetEditTimeout = ViewFunctions.resetEditTimeout.bind(this);
    this.saveEdit = ViewFunctions.saveEdit.bind(this);
    this.setDataInState = ViewFunctions.setDataInState.bind(this)
    this.setSelected = ViewFunctions.setSelected.bind(this);
    this.updateAttributes = ViewFunctions.updateAttributes.bind(this);
    this.updateData = ViewFunctions.updateData.bind(this)
    this.updateSearchResults = ViewFunctions.updateSearchResults.bind(this);

    this.lookups = null;
    this.state = { entityData: null, contextLayers: [], canEnable: true, selectedItems: null };
  }

  get apiPrefix() { return 'api/hub/' };
  get className() { return 'view-panel hub-view' };
  get currentView() { return Views.Hub };
  get currentViewName() { return Views.displayName(this.currentView) };
  get mapComponentName() { return 'hub-view-component-map' };
  get contextLayerType() { return layerType.Hubs };

  get viewGlobal() { return this.props.viewGlobal; };
  get idGlobal() { return this.props.idGlobal; };
  get editingGlobal() { return this.props.editingGlobal; };
  get countriesGlobal() { return this.props.countriesGlobal; };
  get loadingGlobal() { return this.props.loadingGlobal; };
  get messageBoxGlobal() { return this.props.messageBoxGlobal; };
  get searchGlobal() { return this.props.searchGlobal; };
  get pinLocationGlobal() { return this.props.pinLocationGlobal; };
  get gmatrixEntity() { return GMATRIX_ENTITY.Hub; }

  componentDidMount() {
    this.didMount();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idGlobal.value !== this.props.idGlobal.value) {
      this.initData();
    }
  }

  getPostbackInfo(forEdit, autoClose) {
    const result = new PostbackInfo();
    this.populatePostbackInfo(result, forEdit, autoClose);

    return result;
  }

  setData(newData, callback = undefined) {
    let entity = newData === null ? null : new Result(newData);

    if (entity !== null && !(entity instanceof Result)) {
      throw new Error('Invalid newData param supplied to setData');
    }

    this.setDataInState(entity, callback);
  }

  copyPinLocation = () => {
    let entity = this.getCurrentEntity();

    if (entity !== null) {
      this.copyPinLocationToLocationObject(entity.attributes);
      this.updateData();
    }
  }

  /************* VIEW SPECIFIC METHODS *************/

  onChangeHubDescription = (text, date) => {
    let entity = this.getCurrentEntity();

    if (entity !== null) {
      let attributes = entity.attributes;
      attributes.hubDescriptionDate = moment(date).utc(true);
      attributes.hubDescription = text;
    }
  }

  render() {
    const entity = this.getCurrentEntity();
    const { canEnable } = this.state;

    if (entity === null || this.lookups === null) {
      return <div className="view-panel no-data"><h1>Please select a {this.currentViewName}, or click<span className="new-button" onClick={() => this.idGlobal.setNewRecordId()} />for a new {this.currentViewName}.</h1></div>;
    }

    const { attributes, editLock, issues } = entity;

    const comments = attributes.comments.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const editTimeout = this.editingGlobal.value && editLock !== undefined && editLock !== null ? moment(editLock.expiryTime).toDate() : undefined;

    return (
      <EditableViewComponent
        className={this.className}
        loadingGlobal={this.loadingGlobal}
        header={this.currentViewName + ': ' + attributes.hubName}
        messageBoxGlobal={this.messageBoxGlobal}
        editingGlobal={this.editingGlobal}
        issues={issues}
        canRefresh={!(this.idGlobal.isNewRecordId | this.idGlobal.isCopyRecordId)}
        canEnable={canEnable}
        refresh={this.refresh}
        beginEdit={this.beginEdit}
        cancelEdit={this.cancelEdit}
        saveEdit={this.saveEdit}
        editTimeout={editTimeout}
        resetEditTimeout={this.resetEditTimeout}
        startYear={0}
        endYear={0}
        selectedStartYear={0}
        selectedEndYear={0}
        yearStartChange={() => { }}
        yearEndChange={() => { }}
        isProduction={false}
        canDelete={true}
        onDelete={this.deleteEntity}
        canCopyPin={true}
        pinLocationGlobal={this.pinLocationGlobal}
        onCopyPin={this.copyPinLocation}
        viewGlobal={this.props.viewGlobal}
        searchGlobal={this.props.searchGlobal}
        currentView={this.gmatrixEntity}
        currentId={this.idGlobal.value}
        recordLocked={false}
        updateSnapshotAudit={() => { }}
        edited={false}
        calculate={() => { }}
        showCalc
        shortcuts={[]}
        toolbarItems={[]}
        toolbarItemsPosition={'after'}
      >
        <SplitterPanelComponent vertical={false} percentage secondarySize={40}>
          <TopPanelComponent
            editing={this.editingGlobal.value}
            loadingGlobal={this.loadingGlobal}
            messageBoxGlobal={this.messageBoxGlobal}
            pinLocationGlobal={this.pinLocationGlobal}
            lookups={this.lookups}
            attributes={attributes}
            updateAttributes={this.updateAttributes}
            geoserverUrl={this.props.geoserverUrl}
            mapComponentName={this.mapComponentName}
            contextLayerType={this.contextLayerType}
          />
          <BottomPanelComponent
            editing={this.editingGlobal.value}
            messageBoxGlobal={this.messageBoxGlobal}
            comments={comments}
            commentActions={this.getActions(attributes.comments, RELATED_ITEM_TYPE.COMMENT)}
            fields={attributes.fields}
            hubDescriptionDate={attributes.hubDescriptionDate}
            hubDescription={attributes.hubDescription}
            onChangeHubDescription={this.onChangeHubDescription}
          />
        </SplitterPanelComponent>
      </EditableViewComponent>
    );
  }
};

HubView.propTypes = {
  viewGlobal: PropTypes.instanceOf(ViewGlobal).isRequired,
  idGlobal: PropTypes.instanceOf(IdGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  countriesGlobal: PropTypes.instanceOf(CountriesGlobal).isRequired,
  searchGlobal: PropTypes.instanceOf(SearchGlobal).isRequired,
  editingGlobal: PropTypes.instanceOf(EditingGlobal).isRequired,
  pinLocationGlobal: PropTypes.instanceOf(PinLocationGlobal).isRequired
}

export default HubView;
