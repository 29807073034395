import React from 'react';
import PropTypes from 'prop-types';
import { valueTypes } from '../../helpers/value-handler';
import { ChartData } from './chart-data';
import { ChartType } from './chart-type';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import './generic-chart.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
);

const GenericChart = (props) => {
  let { chartType, isXStacked, isYStacked } = props;
  let { chartDataItems, showXAxisLabel, xAxisLabel, showXAxisGridLines, showYAxisLabel, yAxisLabel, showYAxisGridLines, showLegend, showToolTips, showPoints } = props;
  const labels = chartDataItems.xLabels;

  if (showPoints === undefined || showPoints === null) showPoints = false;
  if (showToolTips === undefined || showToolTips === null) showToolTips = true;
  if (showLegend === undefined || showLegend === null) showLegend = true;
  if (showXAxisGridLines === undefined || showXAxisGridLines === null) showXAxisGridLines = false;
  if (showYAxisGridLines === undefined || showYAxisGridLines === null) showYAxisGridLines = false;
  if (xAxisLabel === undefined || xAxisLabel === null) xAxisLabel = '';
  if (yAxisLabel === undefined || yAxisLabel === null) yAxisLabel = '';
  if (showXAxisLabel === undefined || showXAxisLabel === null) showXAxisLabel = false;
  if (showYAxisLabel === undefined || showYAxisLabel === null) showYAxisLabel = false;
  if (isXStacked === undefined || isXStacked === null) isXStacked = false;
  if (isYStacked === undefined || isYStacked === null) isYStacked = false;

  const isStackedAreaWithSingleValues = () => {
    if (chartType === ChartType.Line && isXStacked !== isYStacked) {
      const datasets = isXStacked ? chartDataItems.xVal : chartDataItems.yVal;

      for (var dsLoop = 0; dsLoop < datasets.length; dsLoop++) {
        const dataset = datasets[dsLoop];
        const values = isXStacked ? dataset.xVals : dataset.yVals;

        if (values.length !== 1) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  if (isStackedAreaWithSingleValues()) {
    // Convert to a stacked bar
    chartType = ChartType.Bar;
    isXStacked = true;
    isYStacked = true;
  }

  const toDataset = (y) => {
    return {
      spanGaps: true,
      order: y.yValIndex,
      label: y.yValLabel,
      backgroundColor: y.color,
      borderColor: y.color,
      data: y.yVals.map(obj => valueTypes.ValueOnly(valueTypes.generic2, obj)),
      pointBackgroundColor: showPoints === true ? y.color : 'rgba(0, 0, 0, 0)',
      pointBorderColor: showPoints === true ? y.color : 'rgba(0, 0, 0, 0)',
      pointRadius: 5,
      fill: 'origin'
    };
  };

  const chartData = {
    labels: labels,
    datasets: chartDataItems.yVal.map(obj => toDataset(obj))
  };

  const indexAxisLetter = chartType === ChartType.HorizontalBar ? 'y' : 'x';

  const chartOptions = {
    indexAxis: indexAxisLetter,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: isXStacked,
        title: {
          text: xAxisLabel,
          display: showXAxisLabel
        },
        grid: {
          drawOnChartArea: showXAxisGridLines,
        },
      },
      y: {
        stacked: isYStacked,
        title: {
          text: yAxisLabel,
          display: showYAxisLabel
        },
        grid: {
          drawOnChartArea: showYAxisGridLines,
        },
        ticks: {
          beginAtZero: true
        }
      }
    },
    tooltips: { enabled: showToolTips, displayColors: showPoints },
    plugins: {
      legend: {
        display: showLegend,
        position: 'bottom'
      },
      filler: {
        propagate: true
      }
    }
  };

  let contentDiv = null;
  if (chartType === ChartType.Line) { contentDiv = (<Line data={chartData} options={chartOptions} />); }
  else { contentDiv = (<Bar data={chartData} options={chartOptions} />); }

  //if (chartType === ChartType.Bar) { contentDiv = (<Bar data={chartData} options={chartOptions} />); }
  //else if (chartType === ChartType.Line) { contentDiv = (<Line data={chartData} options={chartOptions} />); }
  //else if (chartType === ChartType.HorizontalBar) { contentDiv = (<HorizontalBar data={chartData} options={chartOptions} />); }

  return (
    <div className="generic-chart">
      {contentDiv}
    </div>
  );
}

GenericChart.propTypes = {
  chartDataItems: PropTypes.instanceOf(ChartData).isRequired,
  chartType: ChartType.propType,
  isXStacked: PropTypes.bool,
  isYStacked: PropTypes.bool,
  showXAxisLabel: PropTypes.bool,
  xAxisLabel: PropTypes.string,
  showXAxisGridLines: PropTypes.bool,
  showYAxisLabel: PropTypes.bool,
  yAxisLabel: PropTypes.string,
  showYAxisGridLines: PropTypes.bool,
  showLegend: PropTypes.bool,
  showToolTips: PropTypes.bool,
  showPoints: PropTypes.bool,
};

export default GenericChart;
export { ChartType };
