import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import './search-component.scss';
import { SearchGlobal } from '../../common/globals/globals';

class SearchComponent extends React.Component {
  constructor(props) {
    super(props);

    this.ignoreUpdate = false;

    this.toggle = this.toggle.bind(this);
    this.txtKeyPress = this.txtKeyPress.bind(this);
    this.searchClick = this.searchClick.bind(this);

    this.txtRef = null;
    this.state = { isOpen: false };
  }

  componentDidUpdate() {
    if (this.txtRef !== null) {
      const searchString = this.props.searchGlobal.searchString;
      if (this.ignoreUpdate) {
        if (this.txtRef.value === searchString) {
          this.ignoreUpdate = false;
        }
      } else if (this.txtRef.value !== searchString) {
        this.txtRef.value = searchString;
      }
    }
  }

  toggle() {
    this.setState(prevState => { return { isOpen: !prevState.isOpen }; });
  }

  txtKeyPress(event) {
    if (event.which === 13 || event.keyCode === 13) {
      this.searchClick();
    }
  };

  searchClick() {
    this.ignoreUpdate = true;
    this.props.searchGlobal.search(this.txtRef.value);
  }

  render() {
    const { editing, searchGlobal } = this.props;

    const menuItems = searchGlobal.getSearchHistory().length === 0
      ? [<DropdownItem key="noHistory" disabled>No history</DropdownItem>]
      : searchGlobal.getSearchHistory().map((obj, index) => <DropdownItem key={'history' + index} onClick={() => searchGlobal.search(obj)}>{obj}</DropdownItem>);
    menuItems.push(<DropdownItem key="divider" divider />);
    menuItems.push(<DropdownItem key="clearSearch" disabled={searchGlobal.searchString === ''} onClick={() => searchGlobal.search('')}>Clear Search</DropdownItem >);
    menuItems.push(<DropdownItem key="clearHistory" disabled={searchGlobal.getSearchHistory().length === 0} onClick={() => searchGlobal.clearSearchHistory()}>Clear History</DropdownItem>);

    return (
      <div className="search-component">
        <Input innerRef={ref => this.txtRef = ref} className="txt-search" disabled={editing} onKeyPress={this.txtKeyPress} bsSize="sm" spellCheck={false} />
        <ButtonDropdown className="btn-search-dropdown" isOpen={this.state.isOpen} toggle={this.toggle} size="sm">
          <Button id="caret" className="btn-search btn-image" disabled={editing} size="sm" onClick={this.searchClick} />
          <DropdownToggle disabled={editing} caret />
          <DropdownMenu>{menuItems}</DropdownMenu>
        </ButtonDropdown>
      </div>
    )
  }
}

SearchComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  searchGlobal: PropTypes.instanceOf(SearchGlobal).isRequired
};

export default SearchComponent;
