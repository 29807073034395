import React from 'react';
//import PropTypes from 'prop-types';

import './historic-prospect-view.scss';
import ComingSoonComponent from '../../common/components/coming-soon/coming-soon-component';

const HistoricProspectView = (props) => {
  return (
    <div className="view-panel historic-prospect-view">
      <h1>HistoricProspectView</h1>
      <ComingSoonComponent />
    </div>
  );
};

HistoricProspectView.propTypes = {
}

export default HistoricProspectView;