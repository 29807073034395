import { HubProductionAttributes } from '../models/production-result';
import { RECORD_STATUS } from '../../../common/models/record-status';

const editHubAttributes = (item) => {
  if (!(item instanceof HubProductionAttributes)) {
    throw new Error('Invalid item param supplied to editHubAttributes');
  }

  item.status = item.status === RECORD_STATUS.added
    ? item.status
    : RECORD_STATUS.edited;
};

export { editHubAttributes };