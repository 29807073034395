import React from 'react';
import ReactDOM from 'react-dom/client'; //THIS IS FOR MOVING TO REACT 18 - ReactDOM>render is deprecated
import { App } from './app/app';
import { unregister } from './registerServiceWorker';
unregister();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <App />
);


