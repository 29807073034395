import { valueTypes } from '../../../shared/helpers/value-handler';
import {
  COLUMN_SMALL,
  COLUMN_MEDIUM,
  COLUMN_LARGE,
  COLUMN_EXTRA_LARGE,
  GasFormatter,
  DoubleFormatter,
  LiquidFormatter,
  PercentFormatter,
} from '../../../common/components/grid/ag-grid-component';

// GMatrix3.dbo.tbl_data_viewer_queries WHERE query_id = 9 -- Triton/Company Field & NTD Reserves
const initColumnsFor_Triton_CompanyFieldAndNtdReserves = (columns) => {  
  for (var loop = 0; loop < columns.length; loop++) {
    const column = columns[loop];
    switch (column.headerName.toLowerCase()) {
      case 'corporate':
        column.width = COLUMN_EXTRA_LARGE; /*column.cellRenderer = Filters.AutoCompleteFilter;*/ break;
      case 'tier':
        column.width = COLUMN_SMALL; /*column.cellRenderer = Filters.SingleSelectFilter;*/ break;
      case 'resource':
        column.width = COLUMN_LARGE; /*column.cellRenderer = Filters.AutoCompleteFilter;*/ break;
      case 'area':
        column.width = COLUMN_MEDIUM; /*column.cellRenderer = Filters.SingleSelectFilter;*/ break;
      case 'status':
        column.width = COLUMN_LARGE; /*column.cellRenderer = Filters.SingleSelectFilter;*/ break;
      case 'core':
        column.width = COLUMN_SMALL; /*column.cellRenderer = Filters.SingleSelectFilter;*/ break;
      case 'operator':
        column.width = COLUMN_SMALL; /*column.cellRenderer = Filters.SingleSelectFilter;*/ break;
      case 'equity':
        column.width = COLUMN_SMALL; column.cellRenderer = DoubleFormatter; /*column.filterRenderer = Filters.NumericFilter;*/ break;
      case 'net remaining oil (' + valueTypes.UnitString(valueTypes.mmb) + ')':
        column.width = COLUMN_SMALL; column.cellRenderer = LiquidFormatter; /*column.filterRenderer = Filters.NumericFilter;*/ break;
      case 'net remaining gas (' + valueTypes.UnitString(valueTypes.bcf) + ')':
        column.width = COLUMN_SMALL; column.cellRenderer = GasFormatter; /*column.filterRenderer = Filters.NumericFilter;*/ break;
      case 'net remaining (' + valueTypes.UnitString(valueTypes.mmmboe) + ')':
        column.width = COLUMN_SMALL; column.cellRenderer = DoubleFormatter; /*column.filterRenderer = Filters.NumericFilter;*/ break;
      case '% remaining':
        column.width = COLUMN_MEDIUM; column.cellRenderer = PercentFormatter; /*column.filterRenderer = Filters.NumericFilter;*/ break;
      case 'abandonment':
        column.width = COLUMN_MEDIUM; /*column.cellRenderer = Filters.NumericFilter;*/ break;
      case 'start year':
        column.width = COLUMN_SMALL; /*column.cellRenderer = Filters.NumericFilter;*/ break;
      default:
        break;
    }
  }
};

export { initColumnsFor_Triton_CompanyFieldAndNtdReserves };
