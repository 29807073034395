import React from 'react';
import PropTypes from 'prop-types';

import { Attributes } from '../models/result';
import { IN_DEV_MODE } from '../../../shared/helpers/common';
import { LookupResult } from '../../../common/models/lookup-result';
import PropertyGridComponent, { validateDate, PropertyGridStringValue, PropertyGridNumberValue, PropertyGridBooleanValue, PropertyGridSelectValue, PropertyGridDateValueUTC, getDate } from '../../../shared/components/property-grid/property-grid-component';
import { MessageBoxGlobal } from '../../../common/globals/globals';
import { INPUTTYPES } from '../../../shared/components/number-format/number-formatter';
import { valueTypes } from '../../../shared/helpers/value-handler';

const IDS = {
  ID: 5,
  BLOCK_NO: 10,
  SUB_AREA: 15,
  RELINQUISHMENT_TYPE_ID: 18,
  RELINQUISHMENT_DATE: 20,
  CORPORATE: 25,
  EQUITY: 30,
  CORE_STATUS: 35,
  IS_OPERATOR: 40,
  COMMENT: 45,
  COMMERCIAL_COMMENT: 50,
  ADMIN_COMMENT: 55
};
Object.freeze(IDS);

const getSections = (attributes, lookups, valueChanged) => {

  const result = [
    {
      label: 'Relinquishment',
      rows: [
        { label: 'Block No', values: [new PropertyGridStringValue(IDS.BLOCK_NO, attributes.blockNo)] },
        { label: 'Sub Area', values: [new PropertyGridStringValue(IDS.SUB_AREA, attributes.subArea)] },
        { label: 'Relinquishment Type', values: [new PropertyGridSelectValue(IDS.RELINQUISHMENT_TYPE_ID, attributes.relinquishmentTypeId, valueChanged, 'key', 'value', lookups.RelinquishmentType)] },
        { label: 'Relinquishment Date', values: [new PropertyGridDateValueUTC(IDS.RELINQUISHMENT_DATE, attributes.relinquishmentDate, valueChanged)] },
        { label: 'Corporate', values: [new PropertyGridStringValue(IDS.CORPORATE, attributes.corporate, valueChanged)] },
        { label: 'Equity (%)', values: [new PropertyGridNumberValue(IDS.EQUITY, INPUTTYPES.NUMBER, attributes.equity, valueChanged, valueTypes.GetPrecision(valueTypes.equity, 0))] },
        { label: 'Core', values: [new PropertyGridBooleanValue(IDS.CORE_STATUS, attributes.coreStatus, valueChanged)] },
        { label: 'Operator', values: [new PropertyGridBooleanValue(IDS.IS_OPERATOR, attributes.isOperator, valueChanged)] },
        { label: 'Comment', values: [new PropertyGridStringValue(IDS.COMMENT, attributes.comment, valueChanged)] },
        { label: 'Commercial Comment', values: [new PropertyGridStringValue(IDS.COMMERCIAL_COMMENT, attributes.commercialComment, valueChanged)] },
        { label: 'Admin Comment', values: [new PropertyGridStringValue(IDS.ADMIN_COMMENT, attributes.adminComment, valueChanged)] },
      ]
    },
  ];

  if (IN_DEV_MODE) result[0].rows.unshift({ label: 'Relinquishment Id', values: [new PropertyGridStringValue(IDS.ID, attributes.id)] });
  return result;
};

const RelinquishmentAttributesComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    lookups,
    attributes,
    updateAttributes,
  } = props;

  const valueChanged = (value, id) => {
    switch (id) {
      case IDS.RELINQUISHMENT_TYPE_ID: attributes.relinquishmentTypeId = Number(value); break;
      case IDS.CORPORATE: attributes.corporate = value; break;
      case IDS.EQUITY: attributes.equity = Number(value); break;
      case IDS.CORE_STATUS: attributes.coreStatus = value; break;
      case IDS.IS_OPERATOR: attributes.isOperator = value; break;
      case IDS.RELINQUISHMENT_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid Relinquishment Date');
          return false;
        }
        attributes.relinquishmentDate = value === undefined ? attributes.relinquishmentDate = null : getDate(value);
        break;

      case IDS.COMMENT: attributes.comment = value; break;
      case IDS.COMMERCIAL_COMMENT: attributes.commercialComment = value; break;
      case IDS.ADMIN_COMMENT: attributes.adminComment = value; break;

      default: throw new Error('Id ' + id + ' is not supported in RelinquishmentAttributesComponent.valueChanged');
    }
    updateAttributes(attributes)
    return true;
  };

  const sections = getSections(attributes, lookups, valueChanged);

  return <PropertyGridComponent sections={sections} readOnly={!editing} />;
};

RelinquishmentAttributesComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  attributes: PropTypes.instanceOf(Attributes).isRequired,
  updateAttributes: PropTypes.func.isRequired,
};

export default RelinquishmentAttributesComponent;
