import React from 'react';
import PropTypes from 'prop-types';
import './emissions-c02-vs-co2-intensity-chart.scss';
import { valueTypes } from '../../helpers/value-handler';
import { ChartData } from '../generic-chart/chart-data';

import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const EmissionsC02vsCO2IntensityChart = (props) => {
  let { isXStacked, isYStacked, chartDataItems, showXAxisLabel, xAxisLabel, showXAxisGridLines, showYAxisLabel, yAxisLabel, showYAxisGridLines, showLegend, showToolTips, showPoints, primaryFill, secondaryFill, primaryType, secondaryType } = props;
  const labels = chartDataItems.xLabels;

  if (xAxisLabel === undefined || xAxisLabel === null) xAxisLabel = '';
  if (yAxisLabel === undefined || yAxisLabel === null) yAxisLabel = '';
  if (showYAxisLabel === undefined || showYAxisLabel === null) showYAxisLabel = false;
  if (showYAxisGridLines === undefined || showYAxisGridLines === null) showYAxisGridLines = false;
  if (showLegend === undefined || showLegend === null) showLegend = true;
  if (showToolTips === undefined || showToolTips === null) showToolTips = true;
  if (showPoints === undefined || showPoints === null) showPoints = false;
  if (primaryFill === undefined || primaryFill === null) primaryFill = false;
  if (secondaryFill === undefined || secondaryFill === null) secondaryFill = false;
  if (isXStacked === undefined || isXStacked === null) isXStacked = false;
  if (isYStacked === undefined || isYStacked === null) isYStacked = false;

  const toDataset = (y) => {
    return {
      spanGaps: true,
      label: y.yValLabel,
      backgroundColor: y.color,
      borderColor: y.color,
      data: y.yVals.map(obj => valueTypes.ValueOnly(valueTypes.generic2, obj)),
      pointBackgroundColor: showPoints === true ? y.color : 'rgba(0, 0, 0, 0)',
      pointBorderColor: showPoints === true ? y.color : 'rgba(0, 0, 0, 0)',
      pointRadius: 5,
      yAxisID: y.yValIndex === 0 ? 'co2' : 'intensity',
      type: y.yValIndex === 0 ? primaryType : secondaryType,
      fill: y.yValIndex === 0 ? primaryFill : secondaryFill,
      order: y.yValIndex === 0 ? 1 : 0
    };
  };

  const chartData = {
    labels: labels,
    datasets: chartDataItems.yVal.map(obj => toDataset(obj))
  };

  const yLabel1 = chartDataItems.yVal[0].yValLabel;
  const yLabel2 = chartDataItems.yVal[1].yValLabel;

  const chartOptions = {
    //indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          text: xAxisLabel,
          display: showXAxisLabel
        },
        grid: {
          drawOnChartArea: showXAxisGridLines,
        },
      },
      co2: {
        stacked: true,
        position: 'left',
        type: 'linear',
        beginAtZero: true,
        title: {
          text: yLabel1,
          display: true
        },
        grid: {
          drawOnChartArea: showYAxisGridLines,
        },
        ticks: {
          beginAtZero: true
        }
      },
      intensity: {
        stacked: false,
        position: 'right',
        type: 'linear',
        beginAtZero: true,
        title: {
          text: yLabel2,
          display: true
        },
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true
        }
      },
    },
    tooltips: { enabled: showToolTips, displayColors: showPoints },
    plugins: {
      legend: {
        display: showLegend,
        position: 'bottom'
      },
      filler: {
        propagate: true
      }
    }
  };

  return (
    <div className="emissions-c02-vs-co2Intensity-chart-component">
      <Line data={chartData} options={chartOptions} />
    </div>
  );

};

EmissionsC02vsCO2IntensityChart.propTypes = {
  chartDataItems: PropTypes.instanceOf(ChartData).isRequired,
  isXStacked: PropTypes.bool,
  isYStacked: PropTypes.bool,
  showXAxisLabel: PropTypes.bool.isRequired,
  xAxisLabel: PropTypes.string,
  showXAxisGridLines: PropTypes.bool.isRequired,
  showYAxisLabel: PropTypes.bool,
  yAxisLabel: PropTypes.string,
  showYAxisGridLines: PropTypes.bool,
  showLegend: PropTypes.bool,
  showToolTips: PropTypes.bool,
  showPoints: PropTypes.bool,
  primaryFill: PropTypes.string,
  secondaryFill: PropTypes.string,
  primaryType: PropTypes.string.isRequired,
  secondaryType: PropTypes.string.isRequired
};

export default EmissionsC02vsCO2IntensityChart;
