export class HubEmissionOverride {
  hubId = 0;
  hubEmissionsOverrideId = 0;
  hubEmissionsOverrideTypeId = 1;
  year = 0;
  overrideValue = 0;
  emissionsMethodId = 1;
  emissionsOverrideComment = '';
  falseColumn = false;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

}
