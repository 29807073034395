import React from 'react';
import PropTypes from 'prop-types';

import { ResourceAnnual } from '../../../../models/production-result';
import ProductionChart from '../../../../../../common/components/chart/production-chart';

const ResourceProductionChartComponent = (props) => {
  const {
    hubMinYear,
    hubMaxYear,
    maxCombinedValue2,
    currentResourceAnnuals
  } = props;

  return (
    <ProductionChart
      entityData={currentResourceAnnuals}
      isStacked="true"
      chartType="Line"
      hubMinYear={hubMinYear}
      hubMaxYear={hubMaxYear}
      maxCombinedValue={maxCombinedValue2}
      xTitle="Year"
      yTitle="Mboed"
    />
  );
};

ResourceProductionChartComponent.propTypes = {
  hubMinYear: PropTypes.number.isRequired,
  hubMaxYear: PropTypes.number.isRequired,
  maxCombinedValue2: PropTypes.number.isRequired,
  currentResourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired
};

export default ResourceProductionChartComponent;