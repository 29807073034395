class SearchGlobal {
  constructor(searchString, searchResults, set, setSearchString, setSearchResults, search, getSearchHistory, clearSearchHistory) {
    if (typeof set !== 'function') {
      throw new Error('Invalid set param supplied to SearchResultsGlobal.ctor is invalid');
    }
    if (typeof setSearchString !== 'function') {
      throw new Error('Invalid setSearchString param supplied to SearchResultsGlobal.ctor is invalid');
    }
    if (typeof setSearchResults !== 'function') {
      throw new Error('Invalid setSearchResults param supplied to SearchResultsGlobal.ctor is invalid');
    }
    if (typeof search !== 'function') {
      throw new Error('Invalid search param supplied to SearchResultsGlobal.ctor is invalid');
    }
    if (typeof getSearchHistory !== 'function') {
      throw new Error('Invalid getSearchHistory param supplied to SearchResultsGlobal.ctor is invalid');
    }
    if (typeof clearSearchHistory !== 'function') {
      throw new Error('Invalid clearSearchHistory param supplied to SearchResultsGlobal.ctor is invalid');
    }

    this._searchString = searchString;
    this._searchResults = searchResults;
    this._set = set;
    this._setSearchString = setSearchString;
    this._setSearchResults = setSearchResults;
    this._search = search;
    this._getSearchHistory = getSearchHistory;
    this._clearSearchHistory = clearSearchHistory;
  }

  get searchString() { return this._searchString; };
  get searchResults() { return this._searchResults; };
  get set() { return this._set; };
  get setSearchString() { return this._setSearchString; };
  get setSearchResults() { return this._setSearchResults; };
  get search() { return this._search; };
  get getSearchHistory() { return this._getSearchHistory; };
  get clearSearchHistory() { return this._clearSearchHistory; };
}

export { SearchGlobal };