import { AppConfiguration } from "./app-configuration";

import { responseIsJson, callApiDefaultOnFail, callApi, callApiAsync } from '../../shared/helpers/api-helpers';

const getVersionNo = () => {
  const appSiteConfig = AppConfiguration.Setting();
  //const version = appSiteConfig.appVersion;
  //const version = '';

  //version['build'] = appSiteConfig.appVersion.build
  //version['major'] = appSiteConfig.appVersion.major
  //version['minor'] = appSiteConfig.appVersion.minor

  const version = 'build:' + appSiteConfig.appVersion.build + ';major:' + appSiteConfig.appVersion.major + ';minor:' + appSiteConfig.appVersion.minor

  return version;
};

const getUserId = () => {
  const userName = sessionStorage.getItem('userName');
  
  return userName;
};

const apiGet = (apiUrl, showLoading, hideLoading, messageBox, onSuccess, onFail) => {
  const requestInit = {
    method: 'get',
    headers: { 'Content-Type': 'application/json; version=' + getVersionNo(), 'Authorization': 'Bearer ' + getUserId(), 'Version': getVersionNo() }
  };
  callApi(apiUrl, requestInit, showLoading, hideLoading, messageBox, onSuccess, onFail);
};

const apiGetAsynch = (apiUrl, showLoading, hideLoading, messageBox, onSuccess, onFail) => {
  const requestInit = {
    method: 'get',
    headers: { 'Content-Type': 'application/json; version=' + getVersionNo(), 'Authorization': 'Bearer ' + getUserId(), 'Version': getVersionNo() }
  };
  callApiAsync(apiUrl, requestInit, showLoading, hideLoading, messageBox, onSuccess, onFail);
};



const apiPost = (apiUrl, headerBody, showLoading, hideLoading, messageBox, onSuccess, onFail) => {
  const requestInit = {
    method: 'post',
    headers: { 'Content-Type': 'application/json; version=' + getVersionNo(), 'Authorization': 'Bearer ' + getUserId(), 'Version': getVersionNo() },
    body: headerBody
  };
  callApi(apiUrl, requestInit, showLoading, hideLoading, messageBox, onSuccess, onFail);
};

const apiPostFile = (apiUrl, headerBody, showLoading, hideLoading, messageBox, onSuccess, onFail) => {
  const requestInit = {
    method: 'post',
    headers: { 'Accept': 'application/json; version=' + getVersionNo(), 'Authorization': 'Bearer ' + getUserId(), 'Version': getVersionNo() },
    body: headerBody
  };
  callApi(apiUrl, requestInit, showLoading, hideLoading, messageBox, onSuccess, onFail);
};

export { getVersionNo, getUserId, responseIsJson, callApiDefaultOnFail, apiGet, apiPost, apiPostFile, apiGetAsynch }
