import { RECORD_STATUS } from '../models/record-status';
import { HubText } from '../models/hub-text';

const addHubText = (items, hubId, textCategoryId, push = false) => {
  if (!Array.isArray(items) || items.filter(obj => !(obj instanceof HubText)).length > 0) {
    throw new Error('Invalid items param supplied to addHubText');
  }
  if (typeof hubId !== 'number') {
    throw new Error('Invalid hubId param supplied to addHubText');
  }
  if (typeof textCategoryId !== 'number') {
    throw new Error('Invalid textCategoryId param supplied to addHubText');
  }

  const ids = items.filter(obj => obj.hubTextId < 0).map(obj => obj.hubTextId).sort((a, b) => b - a);
  const result = new HubText({
    hubTextId: (ids.length > 0 ? ids[0] : 0) - 1,
    hubId: hubId,
    textCategoryId: textCategoryId,
    textValue: '',
    dateAdded: new Date(),
    dateAmended: new Date(),
    status: RECORD_STATUS.added,
    allowAdd: false,
    allowRemove: false
  });

  if (push === false) {
    items.unshift(result);
  } else {
    items.push(result);
  }

  return result;
};

const deleteHubText = (items, item) => {
  if (!Array.isArray(items) || items.filter(obj => !(obj instanceof HubText)).length > 0) {
    throw new Error('Invalid items param supplied to deleteHubText');
  }
  if (!(item instanceof HubText)) {
    throw new Error('Invalid item param supplied to deleteHubText');
  }

  if (item.status === RECORD_STATUS.added) {
    items.splice(items.indexOf(item), 1);
  } else {
    item.status = RECORD_STATUS.deleted;
  }
};

const editHubText = (item) => {
  if (!(item instanceof HubText)) {
    throw new Error('Invalid item param supplied to editHubText');
  }

  item.status = item.status === RECORD_STATUS.added
    ? item.status
    : RECORD_STATUS.edited;
};

export { addHubText, deleteHubText, editHubText };