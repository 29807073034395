import React from 'react';
import PropTypes from 'prop-types';
import './hermes-management.scss';

import { apiGet, apiPost } from '../../common/helpers/api-helpers';
import { LoadingGlobal, MessageBoxGlobal } from '../../common/globals/globals';
import { EndPointAttributes, EmailRecipientAttributes, Entities } from './models/result';
import HermesSelectComponent from '../hermes/hermes-select-component';
import HermesApiDetailsComponent from './hermes-api-details-component';
import HermesEmailListComponent from './hermes-email-list-component';
import { Button } from 'reactstrap';
import * as _ from 'lodash';
import NewEntityComponent from './new-entity-component';

const URLIDS = {
  ENDPOINTURL: 10,
  ENDPOINTTYPEID: 20,
  UIDATTRIBUTE: 30,
  NAMEATTRIBUTE: 40,
  JSONPATH: 50,
  ATTRIBUTEEXCLUDELIST: 60,
  EXPORTPATH: 70,
  EXPORTFILENAME: 80,
  ACTIVE: 90,
  TEAMSWEBHOOKURL: 100,
  ALLOWEMPTYJSON: 110
};
Object.freeze(URLIDS);

class HermesManagementView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { endPoints: null, newEndPoints: null, entityType: null, entities: null, newEntities: null, emails: null, newEmails: null, id: null, newId: null, editting: false, newEntity: false };
  };

  get apiPrefix() { return 'api/hermes/' };

  componentDidMount() {
    this.getManagementData();
  }

  getManagementData() {
    const onSuccess = (result) => {
      this.setState({ entities: result.entities.Entity, id: result.selectedEntityId === null ? result.entities.Entity[0].key : result.selectedEntityId, endPoints: result.endPoints.map(obj => new EndPointAttributes(obj)), emails: result.emailRecipients.map(obj => new EmailRecipientAttributes(obj)), endPointTypes: result.lookUps.EndPointType }); 
    }
    apiGet(this.apiPrefix + 'management-data', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
  }

  selectEntity = (id) => {
    this.setState({id: id});
  }

  validation = () => {
    const { newEmails, newEndPoints, id, newId, newEntity, entities, newEntities } = this.state;

    let warning = 'Nothing to Save';

    if (newEmails === null && newEndPoints === null) {
      this.props.messageBoxGlobal.showWarning(warning);
      return false;
    }

    let entityTypeId = newEntity ? newEntities[newId - 1].key : entities[id - 1].key;
    let newEndPoint = newEndPoints.find(x => x.entityTypeId === entityTypeId);

    if (newEndPoint !== null) {
      if (newEndPoint.endPointURL.trim() === '' || newEndPoint.endPointURL === undefined || newEndPoint.endPointURL === null) warning = 'Must enter URL';
      else if (newEndPoint.uidAttribute.trim() === '' || newEndPoint.uidAttribute === undefined || newEndPoint.uidAttribute === null) warning = 'Must enter UID Atribute';
      else if (newEndPoint.nameAttribute.trim() === '' || newEndPoint.nameAttribute === undefined || newEndPoint.nameAttribute === null) warning = 'Must enter Name Attribute';
      else if (newEndPoint.exportPath.trim() === '' || newEndPoint.exportPath === undefined || newEndPoint.exportPath === null) warning = 'Must enter Export Path';
      else if (newEndPoint.exportFilename.trim() === '' || newEndPoint.exportFilename === undefined || newEndPoint.exportFilename === null) warning = 'Must enter Export Filename';
      else if (newEndPoint.endPointTypeId <= 0) warning = 'Must select URL Type';
      else warning = null;

      if (warning !== null) {
        this.props.messageBoxGlobal.showWarning(warning);
        return false;
      }
    }

    if (newEmails !== null) {
      let emptyEmails = newEmails.find(x => x.emailAddress.trim() === '' || x.emailAddress === null);
      if (emptyEmails !== null && emptyEmails !== undefined) {
        warning = 'Email Addresses cannot be blank';
        this.props.messageBoxGlobal.showWarning(warning);
        return false;
      }
    }

    return true;
  }

  save = () => {
    const { newEmails, emails, endPoints, newEndPoints, entities, newEntities, id, newId, newEntity } = this.state;

    if (this.validation()) {
      const onSuccess = (result) => {
        this.setState(
          {
            entities: result.entities.Entity,
            newEntities: null,
            id: result.selectedEntityId === null ? result.entities.Entity[0].key : result.selectedEntityId,
            newId: null,
            endPoints: result.endPoints.map(obj => new EndPointAttributes(obj)),
            newEndPoints: null,
            emails: result.emailRecipients.map(obj => new EmailRecipientAttributes(obj)),
            newEmails: null,
            endPointTypes: result.lookUps.EndPointType,
            editting: false,
            newEntity: false
          });
      }

      let entityTypeID = newEntity ? newEntities[newId - 1].key : entities[id - 1].key;
      let entityType = newEntity ? newEntities[newId - 1].value : entities[id - 1].value;

      let emailList = newEmails === null ? emails : newEmails;
      const emailFilteredList = emailList.filter(x => x.entityTypeId === entityTypeID);

      let allEndPoints = newEndPoints === null ? endPoints : newEndPoints;
      const endPointAttributes = allEndPoints.find(x => x.entityTypeId === entityTypeID);

      let postbackInfo = {
        endPointAttributes,
        emailFilteredList,
      }

      apiPost(this.apiPrefix + 'save-data?entityType=' + entityType + '&newEntity=' + newEntity, JSON.stringify(postbackInfo), this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
    }

  }

  edit = () => {
    this.setState({ editting: true });
  }

  cancel = () => {
    this.props.messageBoxGlobal.clear();
    this.setState({ editting: false, newEmails: null, newEntity: false, newEntities: null, newId: null, newEndPoints: null });
  }

  onEmailChange = (name, id) => {
    const { newEmails, emails } = this.state;

    let emailList = newEmails === null ? _.cloneDeep(emails) : newEmails;
    emailList.find(x => x.recipientId == id).emailAddress = name;
    this.setState({ newEmails: emailList });
  }

  onUrlChange = (value, ids) => {
    const { newEntity, newEndPoints, endPoints, newId, id, newEntities, entities, editting } = this.state;

    let endPointList = newEndPoints === null ? _.cloneDeep(endPoints) : newEndPoints;
    let entityTypeId = newEntity ? newEntities[newId - 1].key : entities[id - 1].key;

    switch (ids) {
      case URLIDS.ENDPOINTURL: endPointList.find(x => x.entityTypeId === entityTypeId).endPointURL = value; break;
      case URLIDS.ENDPOINTTYPEID:
        endPointList.find(x => x.entityTypeId === entityTypeId).endPointTypeId = Number(value);
        endPointList.find(x => x.entityTypeId === entityTypeId).endPointType = Number(value);
        break;
      case URLIDS.UIDATTRIBUTE: endPointList.find(x => x.entityTypeId === entityTypeId).uidAttribute = value; break;
      case URLIDS.NAMEATTRIBUTE: endPointList.find(x => x.entityTypeId === entityTypeId).nameAttribute = value; break;
      case URLIDS.JSONPATH: endPointList.find(x => x.entityTypeId === entityTypeId).jsonPath = value; break;
      case URLIDS.ATTRIBUTEEXCLUDELIST: endPointList.find(x => x.entityTypeId === entityTypeId).attributeExcludeList = value; break;
      case URLIDS.EXPORTPATH: endPointList.find(x => x.entityTypeId === entityTypeId).exportPath = value; break;
      case URLIDS.EXPORTFILENAME: endPointList.find(x => x.entityTypeId === entityTypeId).exportFilename = value; break;
      case URLIDS.ACTIVE: endPointList.find(x => x.entityTypeId === entityTypeId).active = value === 'true' ? true : false; break;
      case URLIDS.TEAMSWEBHOOKURL: endPointList.find(x => x.entityTypeId === entityTypeId).teamsWebhookURL = value; break;
      case URLIDS.ALLOWEMPTYJSON: endPointList.find(x => x.entityTypeId === entityTypeId).allowEmptyJson = value === 'true' ? true : false; break;
      default: throw new Error('Id ' + ids + ' is not supported changing URL Details');
    }

    this.setState({ newEndPoints: endPointList });
  }

  addNewEntity = (name) => {
    let entitiesList = _.cloneDeep(this.state.entities);

    var obj = {key: -99, value: name}
    entitiesList.push(obj);

    let newId = entitiesList.length;

    var urlObj = {
      endPointId: -99,
      entityTypeId: -99,
      endPointType: -99,
      entityType: name,
      endPointTypeId: 0,
      endPointURL: '',
      uidAttribute: '',
      nameAttribute: '',
      jsonPath: '',
      attributeExcludeList: '',
      exportPath: '',
      exportFilename: '',
      active: false,
      teamsWebhookURL: '',
      allowEmptyJson: false
    }

    let url = new EndPointAttributes(urlObj);

    let endPointsList = _.cloneDeep(this.state.endPoints);
    endPointsList.push(url);

    this.newEmail(-99);

    this.props.messageBoxGlobal.clear();

    this.setState({ editting: true, newEntity: true, newId: newId, newEntities: entitiesList, newEndPoints: endPointsList });
  }

  nameCheck = (EntityName, callback) => {
    const entityNameCheck = this.apiPrefix + 'nameCheck?newEntityName=' + EntityName;

    apiGet(entityNameCheck, this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, callback, undefined);
  }

  newEntity = () => {
    let msgContents = '';
    msgContents = <NewEntityComponent
      onClose={this.cancel}
      onNameCheck={this.nameCheck}
      addNewEntity={this.addNewEntity}
    />

    const header = 'New Entity';
    this.props.messageBoxGlobal.showPanel(msgContents, header, 'lg');
  }

  newEmail = (entityTypeId = null) => {
    const { emails, id, newEmails, newId } = this.state;
    let emailList = newEmails === null ? _.cloneDeep(emails) : newEmails;

    entityTypeId = entityTypeId === null ? newId === null ? id : newId : entityTypeId;

    const negativeEmailIds = emailList.filter(emailList => emailList.recipientId < 0).map(emailList => emailList.recipientId).sort((a, b) => a - b);
    let uniqueId = (negativeEmailIds.length > 0 ? negativeEmailIds[0] : 0) - 1;

    var obj = {
      recipientId: uniqueId,
      entityTypeId: entityTypeId,
      emailAddress: ''
    };

    let email = new EmailRecipientAttributes(obj)
    emailList.push(email);

    this.setState({ editting: true, newEmails: emailList });
  }

  deleteEmail = (id) => {
    const { emails, newEmails } = this.state;

    let emailList = newEmails === null ? _.cloneDeep(emails) : newEmails;

    let newEmailList = emailList.filter(x => x.recipientId !== id);

    this.setState({ newEmails: newEmailList });
  }

  render() {
    const { entities, endPoints, emails, newEmails, id, editting, newEntities, newEndPoints, newId, endPointTypes } = this.state;
    if (entities === null || id === null) return null;

    let entitiesList = newEntities === null ? entities : newEntities;

    let selectedEntity = entitiesList !== null && entitiesList.length > 0 && id !== null ? entitiesList[id - 1].key : null;
    if (newId !== null) selectedEntity = entitiesList[newId - 1].key

    let allEndPoints = newEndPoints === null ? endPoints : newEndPoints;
    const selectedApi = allEndPoints.find(x => x.entityTypeId === selectedEntity);

    let emailList = newEmails === null ? emails : newEmails;
    const emailFilteredListList = emailList.filter(x => x.entityTypeId === selectedEntity);

    const title = 'Hermes Management';

    const forTesting = false;

    return (
      <div className="hermes-management-view">
        <h1 className="title">{title}</h1>
        <div className="entity-select-area"><HermesSelectComponent selectEntity={this.selectEntity} entities={entitiesList} value={selectedEntity} /></div>
        <HermesApiDetailsComponent details={selectedApi} editting={editting} onChange={this.onUrlChange} endPointTypes={endPointTypes} />
        <HermesEmailListComponent emailList={emailFilteredListList} editting={editting} messageBoxGlobal={this.props.messageBoxGlobal} onDeleteEmail={this.deleteEmail} onChange={this.onEmailChange} />
        <div className="buttons">
          <Button className="newEmailBtn" hidden={!editting} onClick={() => this.newEmail()}>New Email</Button>
          <Button className="editBtn" hidden={editting} onClick={this.edit}>Edit</Button>
          <Button className="entityBtn" hidden={editting} onClick={this.newEntity}>New Entity</Button>
          <Button className="cancelBtn" hidden={!editting} onClick={this.cancel}>Cancel</Button>
          <Button className="saveBtn" hidden={!editting} disabled={forTesting} onClick={this.save}>Save</Button>
        </div>
      </div>
    );
  }

};

HermesManagementView.propTypes = {
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
}

export default HermesManagementView;
export { URLIDS };
