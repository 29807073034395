import React from 'react';
import PropTypes from 'prop-types';
import SplitterPanelComponent from '../../../../../shared/components/splitter-panel/splitter-panel-component';
import TabPanelComponent, { Tab } from '../../../../../shared/components/tab-panel/tab-panel-component';
import SnapshotHistoryComponent from './resource-history-panel/snapshot-history-component';
import SnapshotCommentsComponent from './resource-history-panel/snapshot-comments-component';
import SnapshotAnnualComponent from './resource-history-panel/snapshot-annual-component';
import HistoryComponent from './resource-history-panel/history-component';
import { MessageBoxGlobal } from '../../../../../common/globals/globals';
import { ResourceReservesHistory, ResourceSnapshotComment, ResourceSnapshotHistory } from '../../../models/production-result';
import { ResourceSnapshotAnnual } from '../../../../../common/models/resource-snapshot-annual';

class ResourceHistoryComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { resourceSnapshotId: 0 };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resourceId !== this.props.resourceId) {
      this.setState({ resourceSnapshotId: 0 });
    }
  }

  onSnapshotSelectionChanged = (id) => {
    this.setState({ resourceSnapshotId: id });
  }
  
  render() {
    const { messageBoxGlobal, reservesHistory, currentResourceSnapshotComments, currentResourceSnapshotHistory, resourceSnapshotAnnuals, resourceId } = this.props;
    const { resourceSnapshotId } = this.state;

    let currentResourceSnapshotAnnuals = [];
    if (resourceSnapshotAnnuals !== undefined && resourceSnapshotAnnuals !== null && resourceSnapshotAnnuals.length > 0) {
      currentResourceSnapshotAnnuals = this.state.resourceSnapshotId === 0 ? resourceSnapshotAnnuals : resourceSnapshotAnnuals.filter(obj => obj.resourceSnapshotId === this.state.resourceSnapshotId);
    }

    const rightTabs = [
      new Tab('Snapshot Annual', <SnapshotAnnualComponent resourceSnapshotAnnuals={currentResourceSnapshotAnnuals} messageBoxGlobal={messageBoxGlobal} resourceSnapshotId={resourceSnapshotId} />)
    ];

    const leftTabs = [
      new Tab('Snapshot History', <SnapshotHistoryComponent resourceSnapshotHistory={currentResourceSnapshotHistory} messageBoxGlobal={messageBoxGlobal} resourceSnapshotId={resourceSnapshotId} onSnapshotSelectionChanged={this.onSnapshotSelectionChanged} resourceId={resourceId} />),
      new Tab('Snapshot Comments', <SnapshotCommentsComponent currentResourceSnapshotComments={currentResourceSnapshotComments} resourceSnapshotId={resourceSnapshotId} />),
      new Tab('History', <HistoryComponent reservesHistory={reservesHistory} messageBoxGlobal={messageBoxGlobal} />)
    ];

    return (
      <SplitterPanelComponent vertical percentage secondarySize={60}>
        <TabPanelComponent align="top" toolbarItems={leftTabs} selectedItemIndex={0} />
        <TabPanelComponent align="top" toolbarItems={rightTabs} selectedItemIndex={0} />
      </SplitterPanelComponent>
    );
  };
}

ResourceHistoryComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  reservesHistory: PropTypes.arrayOf(PropTypes.instanceOf(ResourceReservesHistory)).isRequired,
  currentResourceSnapshotComments: PropTypes.arrayOf(PropTypes.instanceOf(ResourceSnapshotComment)).isRequired,
  currentResourceSnapshotHistory: PropTypes.arrayOf(PropTypes.instanceOf(ResourceSnapshotHistory)).isRequired,
  resourceSnapshotAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceSnapshotAnnual)).isRequired
};

export default ResourceHistoryComponent;