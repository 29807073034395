import React from 'react';
import PropTypes from 'prop-types';

import { HubAnnual } from '../../models/production-result';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import HubAnnualsGridComponent from '../../../../common/components/grid/hub-annuals-grid-component';

const HubAnnualsComponent = (props) => {

  return <HubAnnualsGridComponent
    editing={props.editing}
    readOnly={false}
    messageBoxGlobal={props.messageBoxGlobal}
    hubAnnuals={props.hubAnnuals}
    top={props.top}
    id={props.id}
    canButtonsEnable={props.canButtonsEnable}
    startYear={props.startYear}
    endYear={props.endYear}
    editYear={props.editYear}
    enableDeclineCurve={props.enableDeclineCurve}
    handleCalculatedValues={props.handleCalculatedValues}
    activeComponent={props.activeComponent}
  />;
};

HubAnnualsComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  editing: PropTypes.bool.isRequired,
  hubAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(HubAnnual)).isRequired,
  id: PropTypes.number,
  canButtonsEnable: PropTypes.func.isRequired,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  handleCalculatedValues: PropTypes.func.isRequired
};

export default HubAnnualsComponent;