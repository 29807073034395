import React from 'react';
import SpatialGridComponent from '../spatial/spatial-grid-component';
import TabPanelComponent, { Tab } from '../../shared/components/tab-panel/tab-panel-component';

import './spatial-import-results.scss';

const SpatialImportResults = (props) => {
  const { messageBoxGlobal, blocks, fields } = props;

  let hasBlocks = blocks !== null && blocks !== undefined;
  let blocksCount = hasBlocks ? blocks.length : 0;

  let hasFields = fields !== null && fields !== undefined;
  let fieldsCount = hasFields ? fields.length : 0;

  const tabs = [
    new Tab('Blocks (' + blocksCount.toString() + ')', hasBlocks ? <SpatialGridComponent messageBoxGlobal={messageBoxGlobal} data={blocks} type={'block_results'} /> : null),
    new Tab('Fields (' + fieldsCount.toString() + ')', hasFields ? <SpatialGridComponent messageBoxGlobal={messageBoxGlobal} data={fields} type={'field_results'} /> : null),
  ];

  return (
    <div className="spatial-import-results">
      <h1>Spatial Import Results</h1>
      <TabPanelComponent toolbarItems={tabs} selectedItemIndex={0} />
    </div>
  );
};


export default SpatialImportResults;