import React from 'react';
import PropTypes from 'prop-types';
import { doubleHelper } from './double-helper';

// Custom Formatter component
class ReadOnlyDoubleFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.number
  };

  static defaultProps = {
    value: null
  };

  render() {    
    let decimalPlaces = this.props.decimalPlaces;
    if (this.props.dependentValues !== undefined) {
      decimalPlaces = this.props.dependentValues;
    }

    const renderedVal = this.props.value === null ? '-' : doubleHelper(this.props.value, decimalPlaces);

    return (
      <div className="readOnlyColumn">{renderedVal}</div>);
  }
}

ReadOnlyDoubleFormatter.propTypes = {
  decimalPlaces: PropTypes.number.isRequired
};

ReadOnlyDoubleFormatter.defaultProps = {
  decimalPlaces: 3
};

export default ReadOnlyDoubleFormatter;