import L from 'leaflet';

class Location {
  dmsLatDegrees = 0;
  dmsLatMinutes = 0;
  dmsLatSeconds = 0;
  dmsNorthing = '';

  dmsLongDegrees = 0;
  dmsLongMinutes = 0;
  dmsLongSeconds = 0;
  dmsEasting = '';

  decimalLatitude = 0;
  decimalLongitude = 0;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
} 


const convertDecimalLocationToDMS = (point) => {
  let lat = 0;
  let lng = 0;

  if (point !== undefined && point instanceof L.LatLng) {
    lat = point.lat;
    lng = point.lng;
  }
  let location = new Location();

  // LAT
  location.dmsNorthing = lat >= 0 ? 'N' : 'S';
  lat = Math.abs(lat);
  location.dmsLatDegrees = Math.trunc(lat);

  let latMins = (lat - location.dmsLatDegrees) * 60.0;
  location.dmsLatMinutes = Math.trunc(latMins);

  location.dmsLatSeconds = (latMins - location.dmsLatMinutes) * 60.0;

  // LONG
  location.dmsEasting = lng >= 0 ? 'E' : 'W';
  lng = Math.abs(lng);
  location.dmsLongDegrees = Math.trunc(lng);

  let longMins = (lng - location.dmsLongDegrees) * 60.0;
  location.dmsLongMinutes = Math.trunc(longMins);

  location.dmsLongSeconds = (longMins - location.dmsLongMinutes) * 60.0;

  return location;
};


export { Location, convertDecimalLocationToDMS }
