import { formatDate } from '../helpers/date-helper';

class WestwoodDataWell {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to WestwoodDataWell.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'string') { throw new Error('Invalid obj.Id param supplied to WestwoodDataWell.ctor'); }
      if (typeof obj.wellName !== 'string') { throw new Error('Invalid obj.wellName param supplied to WestwoodDataWell.ctor'); }
      if (obj.wellClass !== undefined && obj.wellClass !== null && typeof obj.wellClass !== 'string') { throw new Error('Invalid obj.wellClass param supplied to WestwoodDataWell.ctor'); }
      if (obj.countryName !== undefined && obj.countryName !== null && typeof obj.countryName !== 'string') { throw new Error('Invalid obj.countryName param supplied to WestwoodDataWell.ctor'); }
      if (obj.basinName !== undefined && obj.basinName !== null && typeof obj.basinName !== 'string') { throw new Error('Invalid obj.basinName param supplied to WestwoodDataWell.ctor'); }
      if (obj.blockName !== undefined && obj.blockName !== null && typeof obj.blockName !== 'string') { throw new Error('Invalid obj.blockName param supplied to WestwoodDataWell.ctor'); }
      if (obj.wellStatus !== undefined && obj.wellStatus !== null && typeof obj.wellStatus !== 'string') { throw new Error('Invalid obj.wellStatus param supplied to WestwoodDataWell.ctor'); }
      if (obj.licenceName !== undefined && obj.licenceName !== null && typeof obj.licenceName !== 'string') { throw new Error('Invalid obj.licenceName param supplied to WestwoodDataWell.ctor'); }
      if (obj.tieInPoint !== undefined && obj.tieInPoint !== null && typeof obj.tieInPoint !== 'string') { throw new Error('Invalid obj.tieInPoint param supplied to WestwoodDataWell.ctor'); }
      if (obj.distanceToTieInPointKM !== undefined && obj.distanceToTieInPointKM !== null && typeof obj.distanceToTieInPointKM !== 'number') { throw new Error('Invalid obj.distanceToTieInPointKM param supplied to WestwoodDataWell.ctor'); }
      if (obj.waterDepth !== undefined && obj.waterDepth !== null && typeof obj.waterDepth !== 'number') { throw new Error('Invalid obj.waterDepth param supplied to WestwoodDataWell.ctor'); }
      if (obj.spudDate !== undefined && obj.spudDate !== null && typeof obj.spudDate !== 'string') { throw new Error('Invalid obj.spudDate param supplied to WestwoodDataWell.ctor'); }
      if (obj.completionDate !== undefined && obj.completionDate !== null && typeof obj.completionDate !== 'string') { throw new Error('Invalid obj.completionDate param supplied to WestwoodDataWell.ctor'); }
      if (obj.drillingDays !== undefined && obj.drillingDays !== null && typeof obj.drillingDays !== 'number') { throw new Error('Invalid obj.drillingDays param supplied to WestwoodDataWell.ctor'); }
      if (obj.wellCost !== undefined && obj.wellCost !== null && typeof obj.wellCost !== 'number') { throw new Error('Invalid obj.wellCost param supplied to WestwoodDataWell.ctor'); }
      if (obj.wellCostQuality !== undefined && obj.wellCostQuality !== null && typeof obj.wellCostQuality !== 'number') { throw new Error('Invalid obj.wellCostQuality param supplied to WestwoodDataWell.ctor'); }
      if (obj.wellCostNotes !== undefined && obj.wellCostNotes !== null && typeof obj.wellCostNotes !== 'string') { throw new Error('Invalid obj.wellCostNotes param supplied to WestwoodDataWell.ctor'); }
      if (obj.totalDepth !== undefined && obj.totalDepth !== null && typeof obj.totalDepth !== 'number') { throw new Error('Invalid obj.totalDepth param supplied to WestwoodDataWell.ctor'); }
      if (obj.totalDepthQualifier !== undefined && obj.totalDepthQualifier !== null && typeof obj.totalDepthQualifier !== 'string') { throw new Error('Invalid obj.totalDepthQualifier param supplied to WestwoodDataWell.ctor'); }
      if (obj.totalDepthAge !== undefined && obj.totalDepthAge !== null && typeof obj.totalDepthAge !== 'string') { throw new Error('Invalid obj.totalDepthAge param supplied to WestwoodDataWell.ctor'); }
      if (obj.infrastructureLedExploration !== undefined && obj.infrastructureLedExploration !== null && typeof obj.infrastructureLedExploration !== 'boolean') { throw new Error('Invalid obj.infrastructureLedExploration param supplied to WestwoodDataWell.ctor'); }
      if (obj.highImpact !== undefined && obj.highImpact !== null && typeof obj.highImpact !== 'boolean') { throw new Error('Invalid obj.highImpact param supplied to WestwoodDataWell.ctor'); }
      if (obj.commercialityStatus !== undefined && obj.commercialityStatus !== null && typeof obj.commercialityStatus !== 'string') { throw new Error('Invalid obj.commercialityStatus param supplied to WestwoodDataWell.ctor'); }
      if (obj.failureCategory !== undefined && obj.failureCategory !== null && typeof obj.failureCategory !== 'string') { throw new Error('Invalid obj.failureCategory param supplied to WestwoodDataWell.ctor'); }
      if (obj.playMaturity !== undefined && obj.playMaturity !== null && typeof obj.playMaturity !== 'string') { throw new Error('Invalid obj.playMaturity param supplied to WestwoodDataWell.ctor'); }
      if (obj.lithology !== undefined && obj.lithology !== null && typeof obj.lithology !== 'string') { throw new Error('Invalid obj.lithology param supplied to WestwoodDataWell.ctor'); }
      if (obj.phaseTarget !== undefined && obj.phaseTarget !== null && typeof obj.phaseTarget !== 'string') { throw new Error('Invalid obj.phaseTarget param supplied to WestwoodDataWell.ctor'); }
      if (obj.trapType !== undefined && obj.trapType !== null && typeof obj.trapType !== 'string') { throw new Error('Invalid obj.trapType param supplied to WestwoodDataWell.ctor'); }
      if (obj.wellResult !== undefined && obj.wellResult !== null && typeof obj.wellResult !== 'string') { throw new Error('Invalid obj.wellResult param supplied to WestwoodDataWell.ctor'); }
      if (obj.hydrocarbons !== undefined && obj.hydrocarbons !== null && typeof obj.hydrocarbons !== 'string') { throw new Error('Invalid obj.hydrocarbons param supplied to WestwoodDataWell.ctor'); }
      if (obj.preDrillSummary !== undefined && obj.preDrillSummary !== null && typeof obj.preDrillSummary !== 'string') { throw new Error('Invalid obj.preDrillSummary param supplied to WestwoodDataWell.ctor'); }
      if (obj.postDrillSummary !== undefined && obj.postDrillSummary !== null && typeof obj.postDrillSummary !== 'string') { throw new Error('Invalid obj.postDrillSummary param supplied to WestwoodDataWell.ctor'); }

      // ADMIN ATTRIBUTES
      if (obj.wellType !== undefined && obj.wellType !== null && typeof obj.wellType !== 'string') { throw new Error('Invalid obj.wellType param supplied to WestwoodDataWell.ctor'); }
    }

    this.id = obj === null ? '' : obj.id;
    this.wellName = obj === null ? '' : obj.wellName ?? '';
    this.wellClass = obj === null ? '' : obj.wellClass ?? '';
    this.countryName = obj === null ? '' : obj.countryName ?? '';
    this.basinName = obj === null ? '' : obj.basinName ?? '';
    this.blockName = obj === null ? '' : obj.blockName ?? '';
    this.wellStatus = obj === null ? '' : obj.wellStatus ?? '';
    this.licenceName = obj === null ? '' : obj.licenceName ?? '';
    this.tieInPoint = obj === null ? '' : obj.tieInPoint ?? '';
    this.distanceToTieInPointKM = obj === null ? 0 : obj.distanceToTieInPointKM;
    this.waterDepth = obj === null ? 0 : obj.waterDepth;
    this.spudDate = obj === null ? null : formatDate(obj.spudDate);
    this.completionDate = obj === null ? null : formatDate(obj.completionDate);
    this.drillingDays = obj === null ? 0 : obj.drillingDays;
    this.wellCost = obj === null ? 0 : obj.wellCost;
    this.wellCostQuality = obj === null ? 0 : obj.wellCostQuality;
    this.wellCostNotes = obj === null ? '' : obj.wellCostNotes ?? '';
    this.totalDepth = obj === null ? 0 : obj.totalDepth;
    this.totalDepthQualifier = obj === null ? '' : obj.totalDepthQualifier ?? '';
    this.totalDepthAge = obj === null ? '' : obj.totalDepthAge ?? '';
    this.infrastructureLedExploration = obj === null ? false : obj.infrastructureLedExploration ?? false;
    this.highImpact = obj === null ? false : obj.highImpact ?? false;
    this.commercialityStatus = obj === null ? '' : obj.commercialityStatus ?? '';
    this.failureCategory = obj === null ? '' : obj.failureCategory ?? '';
    this.playMaturity = obj === null ? '' : obj.playMaturity ?? '';
    this.lithology = obj === null ? '' : obj.lithology ?? '';
    this.phaseTarget = obj === null ? '' : obj.phaseTarget ?? '';
    this.trapType = obj === null ? '' : obj.trapType ?? '';
    this.wellResult = obj === null ? '' : obj.wellResult ?? '';
    this.hydrocarbons = obj === null ? '' : obj.hydrocarbons ?? '';
    this.preDrillSummary = obj === null ? '' : obj.preDrillSummary ?? '';
    this.postDrillSummary = obj === null ? '' : obj.postDrillSummary ?? '';

    // ADMIN ATTRIBUTES
    this.wellType = obj === null ? '' : obj.wellType ?? '';
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "WestwoodDataWell.FromArray"');
    }

    const result = array.map(obj => new WestwoodDataWell(obj));
    return result;
  }
}

export { WestwoodDataWell };
