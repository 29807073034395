import React from 'react';
import PropTypes from 'prop-types';
import './hermes-compare.scss';

import { apiGet } from '../../common/helpers/api-helpers';
import { LoadingGlobal, MessageBoxGlobal } from '../../common/globals/globals';
import HermesCompareGridComponent from './hermes-compare-grid-component';
import HermesSelectComponent from './hermes-select-component';
import { DifferenceAttributes, Entities } from './models/result';
import HermesToggleComponent from './hermes-toggle-component';

class HermesCompareView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { differences: null, entityType: null, entities: null, id: null, showAll: false };
  };

  get apiPrefix() { return 'api/hermes/' };

  componentDidMount() {
    this.getEntities();
  }

  selectEntity = (id) => {
    this.initDifferenceData(id);
  }

  initDifferenceData(id) {
    const onSuccess = (result) => {
      this.setState({ id: id, differences: result.differences.map(obj => new DifferenceAttributes(obj)), entityType: result.entityType });

    }
    apiGet(this.apiPrefix + 'difference-data?entityTypeId=' + id, this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
  }

  getEntities() {
    const onSuccess = (result) => {
      /* this.setState({ entities: result.map(obj => new Entities(obj)) });*/
      this.setState({ entities: result.Entity, id: result.Entity[0].key }, this.initDifferenceData(result.Entity[0].key) );
    }
    apiGet(this.apiPrefix + 'entities', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
  }

  showAllDifferences = (b) => {
    this.setState({ showAll: b});
  }

  getLatestDifference = (differences) => {
    let maximumDate = new Date(Math.max.apply(null, differences.map(function (e) {
      return new Date(e.newDate);
    })));

    let maxDiffs = differences.filter(x => x.newDate !== null && x.newDate.getTime() === maximumDate.getTime());

    return maxDiffs;
  }

  render() {
    const { differences, entityType, entities, id, showAll } = this.state;

    if (entities === null || id === null) return null;

    if (entities !== null && entities.length > 0 && differences === null && id === null) this.initDifferenceData(entities[0].key);

    let filteredDifferences = showAll || differences === null ? differences : this.getLatestDifference(differences);
    let hasDifferences = filteredDifferences !== null;
    let differencesCount = hasDifferences ? filteredDifferences.length : 0;

    const title = entityType !== null && entityType !== '' ? entityType + ' Differences (' + differencesCount + ')' : 'Entity Differences';

    const toggleTitle = showAll ? 'Show All:' : 'Show Latest:';

    const compareGrid = hasDifferences || differencesCount > 0
      ? <HermesCompareGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={filteredDifferences} />
      : <div>No Results Found, Please select another entity</div>

    return (
      <div className="hermes-compare-view">
        <h1 className="title">{title}</h1>
        <HermesSelectComponent selectEntity={this.selectEntity} entities={entities} value={entities[id - 1].key} />
        <HermesToggleComponent label={toggleTitle} toggled={showAll} onClick={this.showAllDifferences} />
        {compareGrid}
      </div>
    );
  }

};

HermesCompareView.propTypes = {
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
}

export default HermesCompareView;
