import React from "react";
import PropTypes from 'prop-types';

import { MessageBoxGlobal } from '../../common/globals/globals';

import AGGridComponent, { COLUMN_SMALL, COLUMN_MEDIUM, COLUMN_LARGE, COLUMN_MEDIUM_MEDIUM, COLUMN_EXTRA_LARGE, COLUMN_WIDE } from '../../common/components/grid/ag-grid-component';

const SpatialGridComponent = (props) => {
  const { messageBoxGlobal, data, type } = props;

  const columnDefs = [];

  switch (type) {
    case 'stratsplit':
      columnDefs.push({ headerName: 'Block No. A', suppressHeaderMenuButton: true, resizable: true, field: 'blockNoA', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'text' });
      columnDefs.push({ headerName: 'Block No. B', suppressHeaderMenuButton: true, resizable: true, field: 'blockNoB', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'text' });
      columnDefs.push({ headerName: 'Strat Split A', suppressHeaderMenuButton: true, resizable: true, field: 'stratSplitA', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'text' });
      columnDefs.push({ headerName: 'Strat Split B', suppressHeaderMenuButton: true, resizable: true, field: 'stratSplitB', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'text' });
      columnDefs.push({ headerName: 'Area AB', suppressHeaderMenuButton: true, resizable: true, field: 'abArea', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'text' });

      break;

    case 'validationResults': 
      //columnDefs.push({ headerName: 'Country', suppressHeaderMenuButton: true, resizable: true, field: 'country_name', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      //columnDefs.push({ headerName: 'Description', suppressHeaderMenuButton: true, resizable: true, field: 'integrity_check_description', sortable: true, filter: false, editable: false, width: COLUMN_EXTRA_LARGE, type: 'text' });

      columnDefs.push({ headerName: 'Country', suppressHeaderMenuButton: true, resizable: true, field: 'entityCountry', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'text' });
      columnDefs.push({ headerName: 'Entity Type', suppressHeaderMenuButton: true, resizable: true, field: 'entityTypeName', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'text' });
      columnDefs.push({ headerName: 'Entity', suppressHeaderMenuButton: true, resizable: true, field: 'entityName', sortable: true, filter: false, editable: false, width: 200, type: 'text' });
      columnDefs.push({ headerName: 'Description', suppressHeaderMenuButton: true, resizable: true, field: 'issue', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'text' });
      columnDefs.push({ headerName: 'Check Name', suppressHeaderMenuButton: true, resizable: true, field: 'checkName', sortable: true, filter: false, editable: false, width: 200, type: 'text' });

      break;

    case 'shapeIssues':

      columnDefs.push({ headerName: 'Country', suppressHeaderMenuButton: true, resizable: true, field: 'countryName', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'text' });
      columnDefs.push({ headerName: 'Polygon Type', suppressHeaderMenuButton: true, resizable: true, field: 'polygonTypeName', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'text' });
      columnDefs.push({ headerName: 'Name', suppressHeaderMenuButton: true, resizable: true, field: 'name', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'text' });
      columnDefs.push({ headerName: 'PostGIS Area', suppressHeaderMenuButton: true, resizable: true, field: 'postGISArea', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'numeric' });
      columnDefs.push({ headerName: 'SQL Area', suppressHeaderMenuButton: true, resizable: true, field: 'sqlArea', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'numeric' });
      columnDefs.push({ headerName: 'Area Diff', suppressHeaderMenuButton: true, resizable: true, field: 'areaDiff', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'numeric' });
      columnDefs.push({ headerName: 'PostGIS Centroid X', suppressHeaderMenuButton: true, resizable: true, field: 'postGISCentroidX', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'numeric' });
      columnDefs.push({ headerName: 'PostGIS Centroid Y', suppressHeaderMenuButton: true, resizable: true, field: 'postGISCentroidY', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'numeric' });
      columnDefs.push({ headerName: 'SQL Centroid X', suppressHeaderMenuButton: true, resizable: true, field: 'sqlCentroidX', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'numeric' });
      columnDefs.push({ headerName: 'SQL Centroid Y', suppressHeaderMenuButton: true, resizable: true, field: 'sqlCentroidY', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'numeric' });
      columnDefs.push({ headerName: 'PostGIS Points', suppressHeaderMenuButton: true, resizable: true, field: 'postGISPoints', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'numeric' });
      columnDefs.push({ headerName: 'SQL Points', suppressHeaderMenuButton: true, resizable: true, field: 'sqlPoints', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'numeric' });
      columnDefs.push({ headerName: 'PostGIS Polygons', suppressHeaderMenuButton: true, resizable: true, field: 'postGISPolygons', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'numeric' });
      columnDefs.push({ headerName: 'SQL Polygons', suppressHeaderMenuButton: true, resizable: true, field: 'sqlPolygons', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'numeric' });

      break;

    case 'block_results':
      columnDefs.push({ headerName: 'Country', suppressHeaderMenuButton: true, resizable: true, field: 'countryName', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'Block No', suppressHeaderMenuButton: true, resizable: true, field: 'blockNo', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'Block Id', suppressHeaderMenuButton: true, resizable: true, field: 'blockId', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      break;

    case 'field_results':
      columnDefs.push({ headerName: 'Country', suppressHeaderMenuButton: true, resizable: true, field: 'countryName', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'Resource Name', suppressHeaderMenuButton: true, resizable: true, field: 'resourceName', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'Resource Status Parent', suppressHeaderMenuButton: true, resizable: true, field: 'resourceStatusParent', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'Resource Status', suppressHeaderMenuButton: true, resizable: true, field: 'resourceStatus', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'Resource Id', suppressHeaderMenuButton: true, resizable: true, field: 'resourceId', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      break;
   
    default:
    // code block
  }

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {},
    image: {}
  };

  return (
    <AGGridComponent
      messageBoxGlobal={messageBoxGlobal}
      columnDefs={columnDefs}
      rowsCount={data.length}
      columnTypes={columnTypes}
      gridData={data}
      allowPaste={false}
      editYear={0}
      autoResize
    />
  );
};

SpatialGridComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
};

export default SpatialGridComponent;
