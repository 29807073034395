import React from 'react';
import PropTypes from 'prop-types';
import CommentsComponent from '../../../../common/components/comments/comments-component';
import { GMatrixGenericComment } from '../../../../common/models/generic-comment';
import { CorporateField } from '../../models/corporate-result';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import TabPanelComponent, { Tab } from '../../../../shared/components/tab-panel/tab-panel-component';

import FieldsComponent from './fields-component';
import LicencesComponent from './licences-component';

import SplitterPanelComponent from '../../../../shared/components/splitter-panel/splitter-panel-component';
import DateTextGenericComponent from '../../../../common/components/date-text-generic/date-text-generic-component';

const BottomPanelComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    comments,
    commentActions,
    fields,
    licences,
    corporateDescriptionDate,
    corporateDescription,
    onChangeCorporateDescription
  } = props;

  const leftTabs = [
    new Tab('Fields', <FieldsComponent messageBoxGlobal={messageBoxGlobal} fields={fields} editing={editing} />),
    new Tab('Licences', <LicencesComponent messageBoxGlobal={messageBoxGlobal} licences={licences} editing={editing} />)
  ];

  const rightTabs = [
    new Tab('Comments', <CommentsComponent editing={editing} messageBoxGlobal={messageBoxGlobal} comments={comments} commentActions={commentActions} />),
    new Tab('Corporate Description', <DateTextGenericComponent key="1" editing={editing} commentText={corporateDescription} commentDate={corporateDescriptionDate} onChange={onChangeCorporateDescription} />)
  ];

  return (
    <SplitterPanelComponent vertical percentage secondarySize={65}>
      <TabPanelComponent align="top" toolbarItems={leftTabs} selectedItemIndex={0} />
      <TabPanelComponent align="top" toolbarItems={rightTabs} selectedItemIndex={0} />
    </SplitterPanelComponent>
  );
};

BottomPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  comments: PropTypes.arrayOf(PropTypes.instanceOf(GMatrixGenericComment)).isRequired,
  commentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  fields: PropTypes.arrayOf(PropTypes.instanceOf(CorporateField)).isRequired,
  onChangeCorporateDescription: PropTypes.func.isRequired
};

export default BottomPanelComponent;
