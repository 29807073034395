export class EditLock {
  id = 0;
  type = 0;
  description = '';
  userName = '';
  startTime = undefined;
  expiryTime = undefined;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}