import React from 'react';
import PropTypes from 'prop-types';

import { Attributes } from '../../models/result';
import { IN_DEV_MODE } from '../../../../shared/helpers/common';
import { LookupResult } from '../../../../common/models/lookup-result';
import PropertyGridComponent, { PropertyGridStringValue, PropertyGridNumberValue, PropertyGridSelectValue } from '../../../../shared/components/property-grid/property-grid-component';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import { INPUTTYPES } from '../../../../shared/components/number-format/number-formatter';

const IDS = {
  ID: 5,
  ROUND: 10,
  COUNTRY_ID: 15,
  YEAR: 20,
  BLOCKS_OFFERED: 25,
  BLOCKS_APPLIED_FOR: 30,
  BLOCKS_AWARDED: 35,
  TOTAL_ACREAGE: 40,
  COMPANY_APPLICATIONS: 45,
  COMPANIES_AWARDED: 50,
  COMPANIES_AWARDED_OPERATORSHIP: 55,
  LICENCES_AWARDED: 60,
  LICENCE_NUMBERS: 65,
  TERM: 70,
  EXPIRY_DATES: 75
};
Object.freeze(IDS);

const getSections = (attributes, lookups, valueChanged) => {

  const result = [
    {
      label: 'Licence Round',
      rows: [
        { label: 'Round', values: [new PropertyGridStringValue(IDS.ROUND, attributes.round, valueChanged )] },
        //{ label: 'Country', values: [new PropertyGridStringValue(IDS.COUNTRY_NAME, attributes.countryName)] },
        { label: 'Country', values: [new PropertyGridSelectValue(IDS.COUNTRY_ID, attributes.countryId, valueChanged, 'key', 'value', lookups.Country)] },
        { label: 'Year', values: [new PropertyGridStringValue(IDS.YEAR, attributes.year, valueChanged)] },
        { label: 'Blocks Offered', values: [new PropertyGridNumberValue(IDS.BLOCKS_OFFERED, INPUTTYPES.NUMBER, attributes.blocksOffered, valueChanged)] },
        { label: 'Blocks Applied For', values: [new PropertyGridNumberValue(IDS.BLOCKS_APPLIED_FOR, INPUTTYPES.NUMBER, attributes.blocksAppliedFor, valueChanged)] },
        { label: 'Blocks Awarded', values: [new PropertyGridNumberValue(IDS.BLOCKS_AWARDED, INPUTTYPES.NUMBER, attributes.blocksAwarded, valueChanged)] },
        { label: 'Total Acreage Awarded (km2)', values: [new PropertyGridNumberValue(IDS.TOTAL_ACREAGE, INPUTTYPES.NUMBER, attributes.totalAcreageAwarded, valueChanged)] },
        { label: 'Company Applications', values: [new PropertyGridNumberValue(IDS.COMPANY_APPLICATIONS, INPUTTYPES.NUMBER, attributes.companyApplications, valueChanged)] },
        { label: 'Companies Awarded', values: [new PropertyGridNumberValue(IDS.COMPANIES_AWARDED, INPUTTYPES.NUMBER, attributes.companiesAwarded, valueChanged)] },
        { label: 'Companies AwardedOperatorship', values: [new PropertyGridNumberValue(IDS.COMPANIES_AWARDED_OPERATORSHIP, INPUTTYPES.NUMBER, attributes.companiesAwardedOperatorship, valueChanged)] },
        { label: 'Licences Awarded', values: [new PropertyGridNumberValue(IDS.LICENCES_AWARDED, INPUTTYPES.NUMBER, attributes.licencesAwarded, valueChanged)] },

        { label: 'Licence Numbers', values: [new PropertyGridStringValue(IDS.LICENCE_NUMBERS, attributes.licenceNumbers, valueChanged)] },
        { label: 'Term', values: [new PropertyGridStringValue(IDS.TERM, attributes.term, valueChanged)] },
        { label: 'Expiry Date', values: [new PropertyGridStringValue(IDS.EXPIRY_DATES, attributes.expiryDates, valueChanged)] }
      ]
    },
  ];

  if (IN_DEV_MODE) result[0].rows.unshift({ label: 'Licence Round Id', values: [new PropertyGridStringValue(IDS.ID, attributes.id)] });
  return result;
};

const LicenceAttributesComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    lookups,
    attributes,
    updateAttributes,
  } = props;

  const valueChanged = (value, id) => {
    switch (id) {
      case IDS.ROUND: attributes.round = value; break;
      case IDS.YEAR: attributes.year = value; break;
      case IDS.COUNTRY_ID: attributes.countryId = Number(value); break;
      case IDS.BLOCKS_OFFERED: attributes.blocksOffered = Number(value); break;
      case IDS.BLOCKS_APPLIED_FOR: attributes.blocksAppliedFor = Number(value); break;
      case IDS.BLOCKS_AWARDED: attributes.blocksAwarded = Number(value); break;
      case IDS.TOTAL_ACREAGE: attributes.totalAcreageAwarded = Number(value); break;
      case IDS.COMPANY_APPLICATIONS: attributes.companyApplications = Number(value); break;
      case IDS.COMPANIES_AWARDED: attributes.companiesAwarded = Number(value); break;
      case IDS.COMPANIES_AWARDED_OPERATORSHIP: attributes.companiesAwardedOperatorship = Number(value); break;
      case IDS.LICENCES_AWARDED: attributes.licencesAwarded = Number(value); break;

      case IDS.LICENCE_NUMBERS: attributes.licenceNumbers = value; break;
      case IDS.TERM: attributes.term = value; break;
      case IDS.EXPIRY_DATES: attributes.expiryDates = value; break;

      default: throw new Error('Id ' + id + ' is not supported in LeftPanelComponent.valueChanged');
    }
    updateAttributes(attributes)
    return true;
  };

  const sections = getSections(attributes, lookups, valueChanged);

  return <PropertyGridComponent sections={sections} readOnly={!editing} />;
};

LicenceAttributesComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  attributes: PropTypes.instanceOf(Attributes).isRequired,
  updateAttributes: PropTypes.func.isRequired,
};

export default LicenceAttributesComponent;
