import React from 'react';
import PropTypes from 'prop-types';

import { WestwoodDataWell } from '../../models/westwood-data-well';
import PropertyGridComponent, { PropertyGridStringValue, PropertyGridBooleanValue, PropertyGridNumberValue } from '../../../shared/components/property-grid/property-grid-component';
import { PropertyGridLargeStringValue } from '../../../shared/components/property-grid/property-grid-table-component';
import { INPUTTYPES } from '../../../shared/components/number-format/number-formatter';

const IDS = {
  ID: 5,
  WELL_NAME: 10,
  COUNTRY_NAME: 15,
  BASIN_NAME: 20,
  BLOCK_NAME: 25,
  WELL_STATUS: 30,
  LICENCE: 35,
  TIE_IN_POINT: 36,
  DISTANCE_TO_TIE_IN_POINT_KM: 37,
  WATER_DEPTH: 40,
  SPUD_DATE: 45,
  COMPLETION_DATE: 50,
  DRILLING_DAYS: 55,
  WELL_COST: 60,
  WELL_COST_QUALITY: 65,
  WELL_COST_NOTES: 70,
  TOTAL_DEPTH: 75,
  TOTAL_DEPTH_QUALIFIER: 80,
  TOTAL_DEPTH_AGE: 85,
  ILX: 90,
  WELL_CLASS: 100,
  HIGH_IMPACT: 105,
  COMMERCIALITY_STATUS: 110,
  FAILURE_CATEGORY: 115,
  PLAY_MATURITY: 120,
  LITHOLOGY: 125,
  PHASE_TARGET: 130,
  TRAP_TYPE: 135,
  WELL_RESULT: 140,
  HYDROCARBONS: 145,
  PRE_DRILL_SUMMARY: 150,
  POST_DRILL_SUMMARY: 155,
};
Object.freeze(IDS);

const getSections = (westwoodDataWell) => {
  const result = [
    {
      label: 'Westwood Data Well Information',
      rows: [
        { label: 'Well', values: [new PropertyGridStringValue(IDS.WELL_NAME, westwoodDataWell.wellName)] },
        { label: 'Class', values: [new PropertyGridStringValue(IDS.WELL_CLASS, westwoodDataWell.wellClass)] },
        { label: 'ILX', values: [new PropertyGridBooleanValue(IDS.ILX, westwoodDataWell.infrastructureLedExploration)] },
        { label: 'High Impact', values: [new PropertyGridBooleanValue(IDS.HIGH_IMPACT, westwoodDataWell.highImpact)] },
        { label: 'Country', values: [new PropertyGridStringValue(IDS.COUNTRY_NAME, westwoodDataWell.countryName)] },
        { label: 'Basin', values: [new PropertyGridStringValue(IDS.BASIN_NAME, westwoodDataWell.basinName)] },
        { label: 'Licence', values: [new PropertyGridStringValue(IDS.LICENCE, westwoodDataWell.licenceName)] },
        { label: 'Block', values: [new PropertyGridStringValue(IDS.BLOCK_NAME, westwoodDataWell.blockName)] },
        { label: 'Water Depth', values: [new PropertyGridNumberValue(IDS.WATER_DEPTH, INPUTTYPES.NUMBER, westwoodDataWell.waterDepth, null, 2)] },
        { label: 'Well Status', values: [new PropertyGridStringValue(IDS.WELL_STATUS, westwoodDataWell.wellStatus)] },
        { label: 'Spud Date', values: [new PropertyGridStringValue(IDS.SPUD_DATE, westwoodDataWell.spudDate)] },
        { label: 'Completion Date', values: [new PropertyGridStringValue(IDS.COMPLETION_DATE, westwoodDataWell.completionDate)] },
        { label: 'Drilling Days', values: [new PropertyGridNumberValue(IDS.DRILLING_DAYS, INPUTTYPES.NUMBER, westwoodDataWell.drillingDays, null, 0)] },
        { label: 'Well Cost', values: [new PropertyGridNumberValue(IDS.WELL_COST, INPUTTYPES.NUMBER, westwoodDataWell.wellCost, null, 2)] },
        { label: 'Well Cost Quality', values: [new PropertyGridNumberValue(IDS.WELL_COST_QUALITY, INPUTTYPES.NUMBER, westwoodDataWell.wellCostQuality, null, 0)] },
        { label: 'Well Cost Notes', values: [new PropertyGridLargeStringValue(IDS.WELL_COST_NOTES, westwoodDataWell.wellCostNotes)] },
        { label: 'Pre Drill Summary', values: [new PropertyGridLargeStringValue(IDS.PRE_DRILL_SUMMARY, westwoodDataWell.preDrillSummary)] },
        { label: 'Play Maturity', values: [new PropertyGridStringValue(IDS.PLAY_MATURITY, westwoodDataWell.playMaturity)] },
        { label: 'Lithology', values: [new PropertyGridStringValue(IDS.LITHOLOGY, westwoodDataWell.lithology)] },
        { label: 'Phase Target', values: [new PropertyGridStringValue(IDS.PHASE_TARGET, westwoodDataWell.phaseTarget)] },
        { label: 'Trap Type', values: [new PropertyGridStringValue(IDS.TRAP_TYPE, westwoodDataWell.trapType)] },
        { label: 'Tie In Point', values: [new PropertyGridStringValue(IDS.TIE_IN_POINT, westwoodDataWell.tieInPoint)] },
        { label: 'Distance To Tie In Point (km)', values: [new PropertyGridNumberValue(IDS.DISTANCE_TO_TIE_IN_POINT_KM, INPUTTYPES.NUMBER, westwoodDataWell.distanceToTieInPointKM, null, 2)] },
        { label: 'Post Drill Summary', values: [new PropertyGridLargeStringValue(IDS.POST_DRILL_SUMMARY, westwoodDataWell.postDrillSummary)] },
        { label: 'Total Depth', values: [new PropertyGridNumberValue(IDS.TOTAL_DEPTH, INPUTTYPES.NUMBER, westwoodDataWell.totalDepth, null, 2)] },
        { label: 'Total Depth Qualifier', values: [new PropertyGridStringValue(IDS.TOTAL_DEPTH_QUALIFIER, westwoodDataWell.totalDepthQualifier)] },
        { label: 'Total Depth Age', values: [new PropertyGridStringValue(IDS.TOTAL_DEPTH_AGE, westwoodDataWell.totalDepthAge)] },
        { label: 'Result', values: [new PropertyGridStringValue(IDS.WELL_RESULT, westwoodDataWell.wellResult)] },
        { label: 'Hydrocarbons', values: [new PropertyGridStringValue(IDS.HYDROCARBONS, westwoodDataWell.hydrocarbons)] },
        { label: 'Commerciality Status', values: [new PropertyGridStringValue(IDS.COMMERCIALITY_STATUS, westwoodDataWell.commercialityStatus)] },
        { label: 'Failure Category', values: [new PropertyGridStringValue(IDS.FAILURE_CATEGORY, westwoodDataWell.failureCategory)] },
      ]
    },

  ];

  return result;
};

const WestwoodDataWellComponent = (props) => {
  const { westwoodDataWell } = props;

  const sections = getSections(westwoodDataWell);

  return <PropertyGridComponent sections={sections} readOnly={true} />;
};

WestwoodDataWellComponent.propTypes = {
  westwoodDataWell: PropTypes.instanceOf(WestwoodDataWell).isRequired,
};

export default WestwoodDataWellComponent;
