//Object.defineProperty(exports, "__esModule", { value: true });

class AppConfiguration {
  static Setting() {
    return this.json;
  }
}

switch (process.env.NODE_ENV.toLowerCase()) {
  case 'development':
    AppConfiguration.json = require('../../appconfiguration.Development.json');
    break;
  default:
    AppConfiguration.json = require('../../appconfiguration.Production.json');
    break;
}

//console.log(' AppConfiguration ' + AppConfiguration);
const _AppConfiguration = AppConfiguration;
export { _AppConfiguration as AppConfiguration };
