import React from 'react';
import PropTypes from 'prop-types';

import './text-box-wrapper-double-component.scss';

import NumberFormatter from '../number-format/number-formatter';

class TextBoxWrapperDoubleComponent extends React.Component {
  constructor(props) {
    super(props);

    this.onPrimaryChange = this.onPrimaryChange.bind(this);
    this.onSecondaryChange = this.onSecondaryChange.bind(this);
    this.onPrimaryBlur = this.onPrimaryBlur.bind(this);
    this.onSecondaryBlur = this.onSecondaryBlur.bind(this);

    this.state = { primaryValue: this.props.primaryValue, secondaryValue: this.props.secondaryValue };
  }

  componentDidUpdate(prevProps) {
    if (this.props.primaryValue !== prevProps.primaryValue || this.props.secondaryValue !== prevProps.secondaryValue) {
      this.setState({ primaryValue: this.props.primaryValue, secondaryValue: this.props.secondaryValue });
    }
  }

  onPrimaryChange(event) {
    this.setState({ primaryValue: event.target.value });
  }

  onSecondaryChange(event) {
    this.setState({ secondaryValue: event.target.value });
  }

  onPrimaryBlur(event) {
    if (this.state.primaryValue !== this.props.primaryValue && typeof this.props.onPrimaryChange === 'function') {
      this.props.onPrimaryChange(this.state.primaryValue);
    }
  }

  onSecondaryBlur(event) {
    if (this.state.secondaryValue !== this.props.secondaryValue && typeof this.props.onSecondaryChange === 'function') {
      this.props.onSecondaryChange(this.state.secondaryValue);
    }
  }

  render() {
    const { primaryValue, secondaryValue } = this.state;
    const { primaryUnitString, secondaryUnitString, isNarrow } = this.props;

    const newPrimaryProps = { ...this.props };
    newPrimaryProps.onChange = this.onPrimaryChange;
    newPrimaryProps.onBlur = this.onPrimaryBlur;
    newPrimaryProps.value = primaryValue;
    newPrimaryProps.title = primaryValue;
    newPrimaryProps.prefix = "";
    newPrimaryProps.precision = this.props.precision;

    const newSecondaryProps = { ...this.props };
    newSecondaryProps.onChange = this.onSecondaryChange;
    newSecondaryProps.onBlur = this.onSecondaryBlur;
    newSecondaryProps.value = secondaryValue;
    newSecondaryProps.title = secondaryValue;
    newSecondaryProps.prefix = "";
    newSecondaryProps.precision = this.props.precision;

    const valueClass = isNarrow ? "value narrow" : "value";

    return (
      <div className="text-box-wrapper-double-component">
        <div className="contents" >
          <div className={valueClass}>
            <NumberFormatter {...newPrimaryProps} thousandSeparator={""} allowNegative={true} numberType={this.props.numberType} widthPercent="100%" />
          </div>
          <span className="unit">{primaryUnitString}</span>
        </div>
        <div className="contents">
          <div className={valueClass}>
            <NumberFormatter {...newSecondaryProps} thousandSeparator={""} allowNegative={true} numberType={this.props.numberType} widthPercent="100%" />
          </div>
          <span className="unit">{secondaryUnitString}</span>
        </div>
      </div>
    );
  }
}

TextBoxWrapperDoubleComponent.propTypes = {
  onPrimaryChange: PropTypes.func,
  onSecondaryChange: PropTypes.func,
  title: PropTypes.string,
  precision: PropTypes.number,
  isNarrow: PropTypes.bool
};

TextBoxWrapperDoubleComponent.defaultPropTypes = {
  precision: 0,
  isNarrow: false
}

export default TextBoxWrapperDoubleComponent;
