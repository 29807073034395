class TreeNode {
  constructor(label, tag = null) {
    this._label = label;
    this._tag = tag;
    this._parent = null;
    this._children = null;
    this._disabled = null;
    this._selected = null;
    this._collapsed = null;
    this._isParent = null;
  }

  get label() {
    return this._label;
  }

  get tag() {
    return this._tag;
  }

  get parent() {
    return this._parent;
  }

  get children() {
    return this._children;
  }

  get disabled() {
    return this._disabled === true;
  }

  set disabled(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid param \'' + value + '\' supplied to NewTreeViewComponent.disabled.set');
    }

    this._disabled = value;
  }

  get selected() {
    return this._selected === true;
  }

  set selected(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid param \'' + value + '\' supplied to NewTreeViewComponent.selected.set');
    }

    this._selected = value;
  }

  get collapsed() {
    return this._collapsed === true;
  }

  set collapsed(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid param \'' + value + '\' supplied to NewTreeViewComponent.collapsed.set');
    }

    this._collapsed = value;
  }

  get isParent() {
    return typeof this._isParent === 'boolean' ? this._isParent : Array.isArray(this._children);
  }

  set isParent(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid param \'' + value + '\' supplied to NewTreeViewComponent._isParent.set');
    }

    this._isParent = value;
  }

  get hasChildren() {
    return this.childCount > 0
  }

  get childCount() {
    return this.isParent && Array.isArray(this._children)
      ? this._children.length
      : 0;
  }

  createNode(label, tag = null) {
    const result = new TreeNode(label, tag);
    this.addNode(result);
    return result;
  }

  addNodes(nodes) {
    if (!Array.isArray(nodes)) {
      throw new Error('Invalid param \'' + nodes + '\' supplied to NewTreeViewComponent.addNodes');
    }

    for (var loop = 0; loop < nodes.length; loop++) {
      this.addNode(nodes[loop]);
    }
  }

  addNode(node, insert = false) {
    if (!(node instanceof TreeNode)) {
      throw new Error('Invalid param \'' + node + '\' supplied to NewTreeViewComponent.addNode');
    }

    node._parent = this;
    this.addChild(node, insert);
  }

  addChild(item, insert = false) {
    if (Array.isArray(this._children)) {
      if (insert === false) {
        this._children.push(item);
      } else {
        this._children.unshift(item);
      }
    } else {
      this._children = [item];
    }
  }
}

export { TreeNode };