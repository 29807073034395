import React from 'react';

import { IN_DEV_MODE } from '../../shared/helpers/common';
import { ApiErrorComponent, MessageBoxIcon, MessageBoxButton, MessageBoxButtons } from '../../shared/components/message-box/message-box-classes';

const DEFAULT_MESSAGEBOX_SIZE = 'md';

class MessageBoxGlobal {
  constructor(value, set, isSet, clear, logout) {
    if (typeof set !== 'function') {
      throw new Error('Invalid set param supplied to MessageBoxGlobal.ctor is invalid');
    }
    if (typeof isSet !== 'boolean') {
      throw new Error('Invalid isSet param supplied to MessageBoxGlobal.ctor is invalid');
    }
    if (typeof clear !== 'function') {
      throw new Error('Invalid clear param supplied to MessageBoxGlobal.ctor is invalid');
    }
    if (typeof logout !== 'function') {
      throw new Error('Invalid logout param supplied to MessageBoxGlobal.ctor is invalid');
    }

    this._value = value;
    this._isSet = isSet;
    this._show = (body, callback = () => { }, buttons = [MessageBoxButton.OK], header = null, icon = MessageBoxIcon.None, size = DEFAULT_MESSAGEBOX_SIZE, showFooter = true) => set({ body: body, callback: callback, buttons: buttons, header: header, icon: icon, size: size, showFooter: showFooter });
    this._showError = (body, callback, buttons, header) => this.show(body, callback, buttons, header, MessageBoxIcon.Error);
    this._showQuestion = (body, callback, buttons, header) => this.show(body, callback, buttons, header, MessageBoxIcon.Question);
    this._showWarning = (body, callback, buttons, header) => this.show(body, callback, buttons, header, MessageBoxIcon.Warning);
    this._showInformation = (body, callback, buttons, header) => this.show(body, callback, buttons, header, MessageBoxIcon.Information);
    this._showApiError = (httpStatusCode, errorDetails) => {
      if (typeof httpStatusCode !== 'number') {
        throw new Error('Invalid httpStatusCode param supplied to SearchGlobal.showApiError');
      }

      let errorRef = '';
      let errorMessage = '';

      if (errorDetails !== undefined && errorDetails !== null) {
        if (typeof errorDetails.errorRef === 'string') {
          errorRef = errorDetails.errorRef;
        } else if (typeof errorDetails.ErrorRef === 'string') {
          errorRef = errorDetails.ErrorRef;
        }

        if (typeof errorDetails.errorMessage === 'string') {
          errorMessage = errorDetails.errorMessage;
        } else if (typeof errorDetails.ErrorMessage === 'string') {
          errorMessage = errorDetails.ErrorMessage;
        }
      }

      const body = <ApiErrorComponent errorRef={errorRef} errorMessage={errorMessage} />;
      switch (httpStatusCode) {
        case 400: // BadRequest
          return this.showWarning(body);
        case 401: // Unauthorized
          return this.showError(<ApiErrorComponent />, logout);
        case 404: // Not Found
          return this.showError('Record Not Found.');
        default:
          return this.showError(body, IN_DEV_MODE ? undefined : () => window.location.reload());
      }
    };
    this._showOKCancelPrompt = (body, okCallback = () => { }, header = null, icon = MessageBoxIcon.Question, size = DEFAULT_MESSAGEBOX_SIZE ) => {
      const callback = (button) => { if (button === MessageBoxButton.OK) { return okCallback(); } };
      this.show(body, callback, MessageBoxButtons.OKCancel, header, icon, size);
    };
    this._showYesNoPrompt = (body, yesCallback = () => { }, header = null, icon = MessageBoxIcon.Question, size = DEFAULT_MESSAGEBOX_SIZE) => {
      const callback = (button) => { if (button === MessageBoxButton.Yes) { yesCallback(); } };
      this.show(body, callback, MessageBoxButtons.YesNo, header, icon, size);
    };
    this._showMessage = (body, header = null, icon = MessageBoxIcon.None, size = DEFAULT_MESSAGEBOX_SIZE) => {
      this.show(body, () => { }, [MessageBoxButton.OK], header, icon, size);
    };
    this._showPanel = (body, header = null, size = DEFAULT_MESSAGEBOX_SIZE) => {
      this.show(body, () => { }, [MessageBoxButton.OK], header, MessageBoxIcon.None, size, false);
    };

    this._clear = clear;
  }

  get value() { return this._value; };
  get isSet() { return this._isSet; };
  get show() { return this._show; };
  get showError() { return this._showError; };
  get showQuestion() { return this._showQuestion; };
  get showWarning() { return this._showWarning; };
  get showInformation() { return this._showInformation; };
  get showApiError() { return this._showApiError; };
  get showOKCancelPrompt() { return this._showOKCancelPrompt; };
  get showYesNoPrompt() { return this._showYesNoPrompt; };
  get showMessage() { return this._showMessage; };
  get showPanel() { return this._showPanel; };
  get clear() { return this._clear; };
}

export { MessageBoxGlobal };