class GMatrixSelectedItem {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to GMatrixSelectedItem.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to GMatrixSelectedItem.ctor'); }
      if (typeof obj.type !== 'number') { throw new Error('Invalid obj.type param supplied to GMatrixSelectedItem.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.type = obj === null ? 0 : obj.type;
  }
}

export { GMatrixSelectedItem };
