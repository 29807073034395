import React from 'react';
import PropTypes from 'prop-types';

import AttributeStaticListComponent from '../../../../common/components/attribute-list/attribute-static-list-component';

import { ResourceProcessing } from '../../models/result';

import { apiGet } from '../../../../common/helpers/api-helpers';
import { LookupResult } from '../../../../common/models/lookup-result';
import { RECORD_STATUS } from '../../../../common/models/record-status';
import { MessageBoxGlobal, LoadingGlobal } from '../../../../common/globals/globals';
import { PropertyGridSelectValue, PropertyGridStringValue } from '../../../../shared/components/property-grid/property-grid-component';

const IDS = {
  HUB_ID: 0,
  HUB_DECISION: 10
};
Object.freeze(IDS);

class ProcessingComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { lookups: null };

    this.loadLookups();
  }

  componentDidUpdate() {
    this.loadLookups();
  }

  loadLookups() {
    if (this.props.editing && this.state.lookups === null) {
      const apiUrl = 'api/resource/lookups/processing';
      const onSuccess = (result) => this.setState({ lookups: new LookupResult(result) });
      apiGet(apiUrl, this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
    }
  }

  render() {
    const { lookups } = this.state;
    const { editing, messageBoxGlobal, resourceProcessing, resourceProcessingActions, id } = this.props;

    const onEdit = (id, value, item) => {
      switch (id) {
        case IDS.HUB_ID:
          item.hubId = Number(value);
          item.hubName = lookups.Hubs.find(obj => obj.key === item.hubId).value;
          break;
        // R/O case IDS.HUB_DECISION: item.hubDecision = value; break;
        default: throw new Error('Id ' + id + ' is not supported in ProcessingComponent.onEdit');
      }
      resourceProcessingActions.onEdit(item);
    };

    const getTitle = (item) => {
      const value = item.hubName;
      return item.status === RECORD_STATUS.added && (value === undefined || value === null || value === '')
        ? '-New Hub-'
        : value;
    };

    const items = resourceProcessing.map(item => {
      return {
        label: getTitle(item),
        data: item,
        sections: [{
          label: 'Processing',
          rows: [
            { label: 'Hub Name', values: [new PropertyGridSelectValue(IDS.HUB_ID, item.hubId, (value, id) => onEdit(id, value, item), 'key', 'value', lookups === null ? [{ key: item.hubId, value: item.hubName }] : lookups.Hubs)] },
            { label: 'Decision Type', values: [new PropertyGridStringValue(IDS.HUB_DECISION, item.hubDecision)] }
          ]
        }]
      }
    });

    const add = () => {
      let newItem = new ResourceProcessing(null);
      newItem.getNew(id);

      resourceProcessingActions.onAdd(newItem);
    }

    return (
      <AttributeStaticListComponent
        editing={editing}
        messageBoxGlobal={messageBoxGlobal}
        items={items}
        itemDescription="hub"
        canAdd={items.length === 0}
        canDelete
        onAdd={add}
        onDelete={resourceProcessingActions.onDelete}
        onSelected={resourceProcessingActions.onSelected}
        onAddPosition={"start"}
      />
    );
  }
};

ProcessingComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  resourceProcessing: PropTypes.arrayOf(PropTypes.instanceOf(ResourceProcessing)).isRequired,
  resourceProcessingActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired
};

export default ProcessingComponent;
