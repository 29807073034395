import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import './phil-test-view.scss';
import SplitterPanelComponent from '../../shared/components/splitter-panel/splitter-panel-component';
import TabPanelComponent, { Tab } from '../../shared/components/tab-panel/tab-panel-component';
import { IdGlobal, EditingGlobal } from '../../common/globals/globals';

const PhilTestView = (props) => {
  //TODO: this is how to load light/dark css require('./rob-test-view.scss');

  const [pane, setPane] = useState(true);
  const { idGlobal, editingGlobal } = props;

  const getItems = () => {
    const result = [];
    result.push(<img src="/images/collapse-white.png" alt="!" style={{ width: '20px', height: '20px' }} />);
    result.push(<img src="/images/cog-white.png" alt="!" style={{ width: '20px', height: '20px' }} />);
    result.push(new Tab('Tab1', <Button disabled={!idGlobal.isSet} onClick={() => editingGlobal.set(!editingGlobal.value)}>{editingGlobal.value ? 'Cancel' : 'Edit'}</Button>));
    result.push(new Tab('Tab2', <h1>Tab2 stuff</h1>));
    result.push(<Button size="sm" className="btn-refresh btn-image" onClick={() => setPane(!pane)}><span>Refresh</span></Button>);
    result.push(<Button size="sm" className="btn-add btn-image no-text first-button" />);
    result.push(<Button size="sm" className="btn-add btn-image no-text middle-button" disabled />);
    result.push(<Button size="sm" className="btn-add btn-image middle-button"><span>Middle</span></Button>);
    result.push(<Button size="sm" className="btn-add btn-image no-text middle-button" />);
    result.push(<Button size="sm" className="btn-add btn-image no-text last-button" />);
    return result;
  };

  return (
    <div className="view-panel phil-test-view">
      <SplitterPanelComponent visiblePanes={pane ? 'both' : 'secondary'}>
        <TabPanelComponent header="Header" toolbarColor="primary" toolbarItems={getItems()} align="top" selectedItemIndex={0} />
        <TabPanelComponent header="Header" toolbarColor="primary" toolbarItems={getItems()} align="left" selectedItemIndex={0} />
      </SplitterPanelComponent>
    </div >
  );
};

PhilTestView.propTypes = {
  idGlobal: PropTypes.instanceOf(IdGlobal).isRequired,
  editingGlobal: PropTypes.instanceOf(EditingGlobal).isRequired
}

export default PhilTestView;