export class Groups {
  admins = [];
  spatialEditors = [];
  emissionsEditors = [];

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

