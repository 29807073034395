import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Table } from 'reactstrap';

import './resource-hyperbolic-control-component.scss';
import { MessageBoxGlobal } from '../../../../../common/globals/globals';
import PropertyGridComponent, { PropertyGridNumberValue, PropertyGridBooleanValue } from '../../../../../shared/components/property-grid/property-grid-component';
import { ResourceProductionAttributes } from '../../../models/production-result';
import { ResourceAnnual } from '../../../../../common/models/resource-annual';
import { valueTypes } from '../../../../../shared/helpers/value-handler';

import NumberFormatter, { INPUTTYPES } from '../../../../../shared/components/number-format/number-formatter';

const IDS = {
  RESOURCE_ID: 1,
  ENABLE_DECLINE: 2,
  LAST_MANUAL_INPUT_RATE_YEAR: 3,
  COMBINED_PHYSICAL_LIMIT_MBOEPD: 4,
  ECONOMIC_CUT_OFF_OVERRIDE_YEAR: 5,
  SNAPSHOT_COP: 6,
  CURRENT_COP: 7,
  PRODUCTION_RATE_LIQUID: 8,
  PRODUCTION_RATE_GAS: 9,
  CUMULATIVE_START_YEAR_LIQUID: 10,
  CUMULATIVE_START_YEAR_GAS: 11,
  CUMULATIVE_PRODUCTION_TARGET_LIQUID: 12,
  CUMULATIVE_PRODUCTION_TARGET_GAS: 13,
  CUMULATIVE_PRODUCTION_TARGET_YEAR_LIQUID: 14,
  CUMULATIVE_PRODUCTION_TARGET_YEAR_GAS: 15,
  DECLINE_RATE_LIQUID: 16,
  DECLINE_RATE_GAS: 17,
  HYBERBOLIC_RATE_LIQUID: 18,
  HYBERBOLIC_RATE_GAS: 19,
  REMAINING_LIQUID_MMB: 20,
  REMAINING_GAS_BCF: 21,
  INITIAL_LIQUID_MMB: 22,
  INITIAL_GAS_BCF: 23,
  SANCTION_INITIAL_LIQUID_MMB: 24,
  SANCTION_INITIAL_GAS_BCF: 25,
  LIQUID_PERCENT_REMAINING: 26,
  GAS_PERCENT_REMAINING: 27,
  OIIP_MMB: 28,
  GIIP_BCF: 29,
  LIQUID_PRICE_DISCOUNT_PREMIUM_PERCENT: 30,
  GAS_PRICE_DISCOUNT_PREMIUM_PERCENT: 31,
  LIQUID_TO_FUEL: 32,
  GAS_TO_FUEL: 33
};
Object.freeze(IDS);

const GOAL_SEEK_BUTTON_IDS = {
  PRODUCTION_RATE_LIQUID: 0,
  PRODUCTION_RATE_GAS: 1,
  CUMULATIVE_PRODUCTION_TARGET_LIQUID: 2,
  CUMULATIVE_PRODUCTION_TARGET_GAS: 3,
  CUMULATIVE_PRODUCTION_TARGET_YEAR_LIQUID: 4,
  CUMULATIVE_PRODUCTION_TARGET_YEAR_GAS: 5,
  DECLINE_RATE_LIQUID: 6,
  DECLINE_RATE_GAS: 7,
  HYBERBOLIC_RATE_LIQUID: 8,
  HYBERBOLIC_RATE_GAS: 9
};
Object.freeze(GOAL_SEEK_BUTTON_IDS);

class ResourceHyperbolicControlComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { resourceAttributes: this.props.resourceAttributes };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resourceAttributes !== this.props.resourceAttributes) {
      this.setState({ resourceAttributes: this.props.resourceAttributes });
    }
  }

  getSections = (resourceAttributes, valueChanged) => {
    var allowValueChanged = resourceAttributes.enableDeclineCurve ? valueChanged : null;
    return [
      {
        label: 'Hyperbolic',
        rows: [
          { label: 'Economic Cut Off Override Year', values: [new PropertyGridNumberValue(IDS.ECONOMIC_CUT_OFF_OVERRIDE_YEAR, INPUTTYPES.YEAR, resourceAttributes.economicCutOffOverrideYear, valueChanged)] },
          { label: 'Enable Decline Curve', values: [new PropertyGridBooleanValue(IDS.ENABLE_DECLINE, resourceAttributes.enableDeclineCurve, valueChanged)] },
          { label: 'Last Manual Input Rate Year', values: [new PropertyGridNumberValue(IDS.LAST_MANUAL_INPUT_RATE_YEAR, INPUTTYPES.YEAR, resourceAttributes.lastManualInputRateYear, allowValueChanged)] },
          { label: 'Combined Physical Limit (mboepd)', values: [new PropertyGridNumberValue(IDS.COMBINED_PHYSICAL_LIMIT_MBOEPD, INPUTTYPES.NUMBER, resourceAttributes.combinedPhysicalLimitMBOED, allowValueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
          { label: 'Snapshot COP', values: [new PropertyGridNumberValue(IDS.SNAPSHOT_COP, INPUTTYPES.YEAR, resourceAttributes.snapshotCOPYear)] },
          { label: 'Current COP', values: [new PropertyGridNumberValue(IDS.CURRENT_COP, INPUTTYPES.YEAR, resourceAttributes.currentCOPYear)] }
        ]
      }
    ];
  };

  valueChangedInitial = (value, id) => {
    var resourceAttributesChanged = this.state.resourceAttributes;
    id = Number(id);
    switch (id) {
      case IDS.CUMULATIVE_START_YEAR_LIQUID: resourceAttributesChanged.liquidCumulativeProductionStartYear = value; break;
      case IDS.CUMULATIVE_START_YEAR_GAS: resourceAttributesChanged.gasCumulativeProductionStartYear = value; break;
      case IDS.CUMULATIVE_PRODUCTION_TARGET_LIQUID: resourceAttributesChanged.liquidCumulativeProductionTargetMMB = value; break;
      case IDS.CUMULATIVE_PRODUCTION_TARGET_GAS: resourceAttributesChanged.gasCumulativeProductionTargetBCF = value; break;
      case IDS.CUMULATIVE_PRODUCTION_TARGET_YEAR_LIQUID: resourceAttributesChanged.liquidCumulativeProductionTargetYear = value; break;
      case IDS.CUMULATIVE_PRODUCTION_TARGET_YEAR_GAS: resourceAttributesChanged.gasCumulativeProductionTargetYear = value; break;
      case IDS.DECLINE_RATE_LIQUID: resourceAttributesChanged.liquidDeclineRatePercent = value; break;
      case IDS.DECLINE_RATE_GAS: resourceAttributesChanged.gasDeclineRatePercent = value; break;
      case IDS.HYBERBOLIC_RATE_LIQUID: resourceAttributesChanged.liquidHyperbolicRatePercent = value; break;
      case IDS.HYBERBOLIC_RATE_GAS: resourceAttributesChanged.gasHyperbolicRatePercent = value; break;
      case IDS.REMAINING_LIQUID_MMB: resourceAttributesChanged.remainingLiquidMMB = value; break;
      case IDS.REMAINING_GAS_BCF: resourceAttributesChanged.remainingGasBCF = value; break;
      case IDS.INITIAL_LIQUID_MMB: resourceAttributesChanged.initialLiquidMMB = value; break;
      case IDS.INITIAL_GAS_BCF: resourceAttributesChanged.initialGasBCF = value; break;
      case IDS.SANCTION_INITIAL_LIQUID_MMB: resourceAttributesChanged.sanctionInitialLiquidMMB = value; break;
      case IDS.SANCTION_INITIAL_GAS_BCF: resourceAttributesChanged.sanctionInitialGasBCF = value; break;
      case IDS.LIQUID_PERCENT_REMAINING: resourceAttributesChanged.liquidPercentRemaining = value; break;
      case IDS.GAS_PERCENT_REMAINING: resourceAttributesChanged.gasPercentRemaining = value; break;
      case IDS.OIIP_MMB: resourceAttributesChanged.oiipMMB = value; break;
      case IDS.GIIP_BCF: resourceAttributesChanged.giipBCF = value; break;
      case IDS.LIQUID_PRICE_DISCOUNT_PREMIUM_PERCENT: resourceAttributesChanged.liquidPriceDiscountPremiumPercent = value; break;
      case IDS.GAS_PRICE_DISCOUNT_PREMIUM_PERCENT: resourceAttributesChanged.gasPriceDiscountPremiumPercent = value; break;
      case IDS.LIQUID_TO_FUEL: resourceAttributesChanged.liquidToFuel = value; break;
      case IDS.GAS_TO_FUEL: resourceAttributesChanged.gasToFuel = value; break;
      default: throw new Error('Id ' + id + ' is not supported in HyperbolicControlComponent.valuevalueChangedInitialChanged');
    }

    this.setState({ resourceAttributes: resourceAttributesChanged });
  }

  valueChanged = (value, id) => {
    id = Number(id);

    const { resourceAttributes, resourceAttributesActions } = this.props;

    switch (id) {
      case IDS.ECONOMIC_CUT_OFF_OVERRIDE_YEAR: resourceAttributes.economicCutOffOverrideYear = value; break; //top
      case IDS.ENABLE_DECLINE: resourceAttributes.enableDeclineCurve = value; break; //top    
      case IDS.LAST_MANUAL_INPUT_RATE_YEAR: resourceAttributes.lastManualInputRateYear = value; break;
      case IDS.COMBINED_PHYSICAL_LIMIT_MBOEPD: resourceAttributes.combinedPhysicalLimitMBOED = value; break;
      case IDS.CUMULATIVE_START_YEAR_LIQUID: resourceAttributes.liquidCumulativeProductionStartYear = value; break;
      case IDS.CUMULATIVE_START_YEAR_GAS: resourceAttributes.gasCumulativeProductionStartYear = value; break;
      case IDS.CUMULATIVE_PRODUCTION_TARGET_LIQUID: resourceAttributes.liquidCumulativeProductionTargetMMB = value; break;
      case IDS.CUMULATIVE_PRODUCTION_TARGET_GAS: resourceAttributes.gasCumulativeProductionTargetBCF = value; break;
      case IDS.CUMULATIVE_PRODUCTION_TARGET_YEAR_LIQUID: resourceAttributes.liquidCumulativeProductionTargetYear = value; break;
      case IDS.CUMULATIVE_PRODUCTION_TARGET_YEAR_GAS: resourceAttributes.gasCumulativeProductionTargetYear = value; break;
      case IDS.DECLINE_RATE_LIQUID: resourceAttributes.liquidDeclineRatePercent = value; break;
      case IDS.DECLINE_RATE_GAS: resourceAttributes.gasDeclineRatePercent = value; break;
      case IDS.HYBERBOLIC_RATE_LIQUID: resourceAttributes.liquidHyperbolicRatePercent = value; break;
      case IDS.HYBERBOLIC_RATE_GAS: resourceAttributes.gasHyperbolicRatePercent = value; break;
      case IDS.REMAINING_LIQUID_MMB: resourceAttributes.remainingLiquidMMB = value; break;
      case IDS.REMAINING_GAS_BCF: resourceAttributes.remainingGasBCF = value; break;
      case IDS.INITIAL_LIQUID_MMB: resourceAttributes.initialLiquidMMB = value; break;
      case IDS.INITIAL_GAS_BCF: resourceAttributes.initialGasBCF = value; break;
      case IDS.SANCTION_INITIAL_LIQUID_MMB: resourceAttributes.sanctionInitialLiquidMMB = value; break;
      case IDS.SANCTION_INITIAL_GAS_BCF: resourceAttributes.sanctionInitialGasBCF = value; break;
      case IDS.LIQUID_PERCENT_REMAINING: resourceAttributes.liquidPercentRemaining = value; break;
      case IDS.GAS_PERCENT_REMAINING: resourceAttributes.gasPercentRemaining = value; break;
      case IDS.OIIP_MMB: resourceAttributes.oiipMMB = value; break;
      case IDS.GIIP_BCF: resourceAttributes.giipBCF = value; break;
      case IDS.LIQUID_PRICE_DISCOUNT_PREMIUM_PERCENT: resourceAttributes.liquidPriceDiscountPremiumPercent = value; break;
      case IDS.GAS_PRICE_DISCOUNT_PREMIUM_PERCENT: resourceAttributes.gasPriceDiscountPremiumPercent = value; break;
      case IDS.LIQUID_TO_FUEL: resourceAttributes.liquidToFuel = value; break;
      case IDS.GAS_TO_FUEL: resourceAttributes.gasToFuel = value; break;
      default: throw new Error('Id ' + id + ' is not supported in HyperbolicControlComponent.valueChanged');
    }

    resourceAttributesActions.onEdit(resourceAttributes);
    return true;
  };

  goalSeek = (e) => {
    this.props.onGoalSeek(e);
  }

  render() {
    const { editing, resourceAnnuals } = this.props;

    var allowEdit = !(editing && this.props.resourceAttributes.enableDeclineCurve);

    const buttons = [
      <Button id={GOAL_SEEK_BUTTON_IDS.PRODUCTION_RATE_LIQUID} className="no-text" size="sm" disabled={allowEdit} onClick={this.goalSeek}>Goal Seek</Button>,
      <Button id={GOAL_SEEK_BUTTON_IDS.PRODUCTION_RATE_GAS} className="no-text" size="sm" disabled={allowEdit} onClick={this.goalSeek}>Goal Seek</Button>,
      <Button id={GOAL_SEEK_BUTTON_IDS.CUMULATIVE_PRODUCTION_TARGET_LIQUID} className="no-text" size="sm" disabled={allowEdit} onClick={this.goalSeek}>Goal Seek</Button>,
      <Button id={GOAL_SEEK_BUTTON_IDS.CUMULATIVE_PRODUCTION_TARGET_GAS} className="no-text" size="sm" disabled={allowEdit} onClick={this.goalSeek}>Goal Seek</Button>,
      <Button id={GOAL_SEEK_BUTTON_IDS.CUMULATIVE_PRODUCTION_TARGET_YEAR_LIQUID} className="no-text" size="sm" disabled={allowEdit} onClick={this.goalSeek}>Goal Seek</Button>,
      <Button id={GOAL_SEEK_BUTTON_IDS.CUMULATIVE_PRODUCTION_TARGET_YEAR_GAS} className="no-text" size="sm" disabled={allowEdit} onClick={this.goalSeek}>Goal Seek</Button>,
      <Button id={GOAL_SEEK_BUTTON_IDS.DECLINE_RATE_LIQUID} className="no-text" size="sm" disabled={allowEdit} onClick={this.goalSeek}>Goal Seek</Button>,
      <Button id={GOAL_SEEK_BUTTON_IDS.DECLINE_RATE_GAS} className="no-text" size="sm" disabled={allowEdit} onClick={this.goalSeek}>Goal Seek</Button>,
      <Button id={GOAL_SEEK_BUTTON_IDS.HYBERBOLIC_RATE_LIQUID} className="no-text" size="sm" disabled={allowEdit} onClick={this.goalSeek}>Goal Seek</Button>,
      <Button id={GOAL_SEEK_BUTTON_IDS.HYBERBOLIC_RATE_GAS} className="no-text" size="sm" disabled={allowEdit} onClick={this.goalSeek}>Goal Seek</Button>
    ]

    const sections = this.getSections(this.props.resourceAttributes, this.valueChanged);

    const { resourceAttributes } = this.state;

    var temp = resourceAnnuals.filter(x => x.year === this.props.resourceAttributes.lastManualInputRateYear);
    var productionRateLiquid = temp !== null && temp !== undefined && temp.length > 0 ? temp[0].liquidMbod : 0;
    var productionRateGas = temp !== null && temp !== undefined && temp.length > 0 ? temp[0].gasMmscfd : 0;

    let content = (
      <Table striped size="sm">
        <thead>
          <tr>
            <th></th>
            <th colSpan="2">Liquid</th>
            <th colSpan="2">Gas</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Initial Production Rate (mbopd / mmscfd) ({this.props.resourceAttributes.lastManualInputRateYear})</td>
            <td className="value-cell"><NumberFormatter id={IDS.PRODUCTION_RATE_LIQUID} disabled value={productionRateLiquid} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td>
            <td className="button-cell">{buttons[GOAL_SEEK_BUTTON_IDS.PRODUCTION_RATE_LIQUID]}</td>
            <td className="value-cell"><NumberFormatter id={IDS.PRODUCTION_RATE_GAS} disabled value={productionRateGas} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td>
            <td className="button-cell">{buttons[GOAL_SEEK_BUTTON_IDS.PRODUCTION_RATE_GAS]}</td>
          </tr>
          <tr>
            <td>Cumulative production start year</td>
            <td className="value-cell"><NumberFormatter id={IDS.CUMULATIVE_START_YEAR_LIQUID} numberType={INPUTTYPES.YEAR} disabled={allowEdit} value={resourceAttributes.liquidCumulativeProductionStartYear} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} /></td>
            <td className="button-cell"></td>
            <td className="value-cell"><NumberFormatter id={IDS.CUMULATIVE_START_YEAR_GAS} numberType={INPUTTYPES.YEAR} disabled={allowEdit} value={resourceAttributes.gasCumulativeProductionStartYear} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} /></td>
            <td className="button-cell"></td>
          </tr>
          <tr>
            <td>Cumulative Production Target (mmb / bcf)</td>
            <td className="value-cell"><NumberFormatter id={IDS.CUMULATIVE_PRODUCTION_TARGET_LIQUID} disabled={allowEdit} value={resourceAttributes.liquidCumulativeProductionTargetMMB} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td>
            <td className="button-cell">{buttons[GOAL_SEEK_BUTTON_IDS.CUMULATIVE_PRODUCTION_TARGET_LIQUID]}</td>
            <td className="value-cell"><NumberFormatter id={IDS.CUMULATIVE_PRODUCTION_TARGET_GAS} disabled={allowEdit} value={resourceAttributes.gasCumulativeProductionTargetBCF} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td>
            <td className="button-cell">{buttons[GOAL_SEEK_BUTTON_IDS.CUMULATIVE_PRODUCTION_TARGET_GAS]}</td>
          </tr>
          <tr>
            <td>Cumulative Production Target Year</td>
            <td className="value-cell"><NumberFormatter id={IDS.CUMULATIVE_PRODUCTION_TARGET_YEAR_LIQUID} numberType={INPUTTYPES.YEAR} disabled={allowEdit} value={resourceAttributes.liquidCumulativeProductionTargetYear} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} /></td>
            <td className="button-cell">{buttons[GOAL_SEEK_BUTTON_IDS.CUMULATIVE_PRODUCTION_TARGET_YEAR_LIQUID]}</td>
            <td className="value-cell"><NumberFormatter id={IDS.CUMULATIVE_PRODUCTION_TARGET_YEAR_GAS} numberType={INPUTTYPES.YEAR} disabled={allowEdit} value={resourceAttributes.gasCumulativeProductionTargetYear} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} /></td>
            <td className="button-cell">{buttons[GOAL_SEEK_BUTTON_IDS.CUMULATIVE_PRODUCTION_TARGET_YEAR_GAS]}</td>
          </tr>
          <tr>
            <td>Decline Rate (%)</td>
            <td className="value-cell"><NumberFormatter id={IDS.DECLINE_RATE_LIQUID} disabled={allowEdit} value={resourceAttributes.liquidDeclineRatePercent} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td>
            <td className="button-cell">{buttons[GOAL_SEEK_BUTTON_IDS.DECLINE_RATE_LIQUID]}</td>
            <td className="value-cell"><NumberFormatter id={IDS.DECLINE_RATE_GAS} disabled={allowEdit} value={resourceAttributes.gasDeclineRatePercent} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td>
            <td className="button-cell">{buttons[GOAL_SEEK_BUTTON_IDS.DECLINE_RATE_GAS]}</td>
          </tr>
          <tr>
            <td>Hyperbolic Rate</td>
            <td className="value-cell"><NumberFormatter id={IDS.HYBERBOLIC_RATE_LIQUID} disabled={allowEdit} value={resourceAttributes.liquidHyperbolicRatePercent} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td>
            <td className="button-cell">{buttons[GOAL_SEEK_BUTTON_IDS.HYBERBOLIC_RATE_LIQUID]}</td>
            <td className="value-cell"><NumberFormatter id={IDS.HYBERBOLIC_RATE_GAS} disabled={allowEdit} value={resourceAttributes.gasHyperbolicRatePercent} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td>
            <td className="button-cell">{buttons[GOAL_SEEK_BUTTON_IDS.HYBERBOLIC_RATE_GAS]}</td>
          </tr>
          <tr>
            <td>Remaining Reserves (mmb / bcf)</td>
            <td className="value-cell"><NumberFormatter id={IDS.REMAINING_LIQUID_MMB} disabled value={resourceAttributes.remainingLiquidMMB} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td><td className="button-cell"></td>
            <td className="value-cell"><NumberFormatter id={IDS.REMAINING_GAS_BCF} disabled value={resourceAttributes.remainingGasBCF} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td><td className="button-cell"></td>
          </tr>
          <tr>
            <td>Total Recoverable Reserves (mmb / bcf)</td>
            <td className="value-cell"><NumberFormatter id={IDS.INITIAL_LIQUID_MMB} disabled value={resourceAttributes.initialLiquidMMB} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td><td className="button-cell"></td>
            <td className="value-cell"><NumberFormatter id={IDS.INITIAL_GAS_BCF} disabled value={resourceAttributes.initialGasBCF} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td><td className="button-cell"></td>
          </tr>
          <tr>
            <td>Sanctioned/ Expected Reserves (mmb / bcf)</td>
            <td className="value-cell"><NumberFormatter id={IDS.SANCTION_INITIAL_LIQUID_MMB} disabled={!editing} value={resourceAttributes.sanctionInitialLiquidMMB} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td><td className="button-cell"></td>
            <td className="value-cell"><NumberFormatter id={IDS.SANCTION_INITIAL_GAS_BCF} disabled={!editing} value={resourceAttributes.sanctionInitialGasBCF} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td><td className="button-cell"></td>
          </tr>
          <tr>
            <td>Remaining (%)</td>
            <td className="value-cell"><NumberFormatter id={IDS.LIQUID_PERCENT_REMAINING} disabled value={resourceAttributes.liquidPercentRemaining} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td><td className="button-cell"></td>
            <td className="value-cell"><NumberFormatter id={IDS.GAS_PERCENT_REMAINING} disabled value={resourceAttributes.gasPercentRemaining} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td><td className="button-cell"></td>
          </tr>
          <tr>
            <td>Initially in Place (mmb / bcf)</td>
            <td className="value-cell"><NumberFormatter id={IDS.OIIP_MMB} disabled={!editing} value={resourceAttributes.oiipMMB} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td><td className="button-cell"></td>
            <td className="value-cell"><NumberFormatter id={IDS.GIIP_BCF} disabled={!editing} value={resourceAttributes.giipBCF} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td><td className="button-cell"></td>
          </tr>
          <tr>
            <td>Price Discount (%)</td>
            <td className="value-cell"><NumberFormatter id={IDS.LIQUID_PRICE_DISCOUNT_PREMIUM_PERCENT} disabled={!editing} value={resourceAttributes.liquidPriceDiscountPremiumPercent} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td><td className="button-cell"></td>
            <td className="value-cell"><NumberFormatter id={IDS.GAS_PRICE_DISCOUNT_PREMIUM_PERCENT} disabled={!editing} value={resourceAttributes.gasPriceDiscountPremiumPercent} onBlur={(event) => this.valueChanged(event.currentTarget.value, event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(event.currentTarget.value, event.currentTarget.id)} precision={valueTypes.GetPrecision(valueTypes.generic3, 0)} /></td><td className="button-cell"></td>
          </tr>
          <tr>
            <td>To Fuel</td>
            <td className="value-cell">
              <select id={IDS.LIQUID_TO_FUEL} disabled={!editing} defaultValue={resourceAttributes.liquidToFuel} onBlur={(event) => this.valueChanged(JSON.parse(event.currentTarget.value), event.currentTarget.id)} onChange={(event) => this.valueChangedInitial(JSON.parse(event.target.value), event.target.id)}>
                <option value={false} >No</option>
                <option value={true} >Yes</option>
              </select>
            </td>
            <td className="button-cell"></td>
            <td className="value-cell">
              <select id={IDS.GAS_TO_FUEL} disabled={!editing} defaultValue={resourceAttributes.gasToFuel} onBlur={(event) => this.valueChanged(JSON.parse(event.currentTarget.value), event.currentTarget.id, event)} onChange={(event) => this.valueChangedInitial(JSON.parse(event.target.value), event.target.id, event)}>
                <option value={false} >No</option>
                <option value={true} >Yes</option>
              </select>
            </td>
            <td className="button-cell"></td>
          </tr>
        </tbody>
      </Table >
    );

    return (
      <div className="resource-hyberbolic-control-component" >
        <PropertyGridComponent sections={sections} readOnly={!editing} />
        {content}
      </div>
    );
  }
}

ResourceHyperbolicControlComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  resourceAttributes: PropTypes.instanceOf(ResourceProductionAttributes).isRequired,
  resourceAttributesActions: PropTypes.shape({ onEdit: PropTypes.func.isRequired }).isRequired,
  resourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  onGoalSeek: PropTypes.func.isRequired,
};

export default ResourceHyperbolicControlComponent;
export { GOAL_SEEK_BUTTON_IDS }
