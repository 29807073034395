import React from 'react';
import PropTypes from 'prop-types';

import './views-component.scss';
import { IdGlobal, ViewGlobal, SearchGlobal, Views } from '../../common/globals/globals';

const ViewsComponent = (props) => {
  const { editing, viewGlobal, searchGlobal, idGlobal, userGroups } = props;
  const views = Views.values();
  const userName = sessionStorage.getItem('userName');

  const showSpatial = userGroups !== null && (userGroups.admins.includes(userName) || userGroups.spatialEditors.includes(userName));
  const showEmissions = userGroups !== null && (userGroups.admins.includes(userName) || userGroups.emissionsEditors.includes(userName));

  const createView = (newView) => {
    const selected = newView === viewGlobal.value;
    const disabled = editing;
    const canAdd = selected && Views.canAdd(newView);
    const onClick = selected || editing
      ? undefined
      : () => viewGlobal.set(newView, Views.reportOnly(newView) ? () => searchGlobal.search('') : undefined);
    const className = 'view' +
      (selected ? ' selected' : ' unselected') +
      (canAdd ? ' can-add' : '') +
      (disabled ? ' disabled' : '');

    if (!displayable(newView)) {
      return null;
    }
    else {
      return (
        <li key={newView} className={className} onClick={onClick}>
          <div className="display-name">{Views.displayName(newView)}</div>
          {canAdd ? <div className="add-btn" onClick={disabled ? undefined : () => idGlobal.setNewRecordId()} /> : null}
        </li>
      );

    }
  };

  const displayable = (view) => {
    switch (view) {
      case Views.Spatial: return showSpatial;
      case Views.EmissionsUploader: return showEmissions;
      default: return true;
    }
  }

  return (
    <ul className="views-component">
      {views.map(obj => createView(obj))}
    </ul >
  );
};

ViewsComponent.propTypes = {
  idGlobal: PropTypes.instanceOf(IdGlobal).isRequired,
  editing: PropTypes.bool.isRequired,
  viewGlobal: PropTypes.instanceOf(ViewGlobal).isRequired,
  searchGlobal: PropTypes.instanceOf(SearchGlobal).isRequired
};

export default ViewsComponent;
