import L from 'leaflet';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';

import { getIcon, addEventHandler, getObjectClassName, getMapEvents, disableMapEvents, restoreMapEvents } from '../../helpers/control-helper';

L.Control.SearchControl = L.Control.extend({
  initialize: function (options) { this.options = options; },
  onAdd: function () { return this.options.container; }
});

class SearchControl {
  constructor(leafletMap) {
    this._objectClassName = getObjectClassName(this);

    if (!(leafletMap instanceof L.Map)) {
      throw new Error('Invalid "leafletMap" param supplied to "' + this._objectClassName + '.ctor"');
    }

    this._mapEvents = null;
    this._container = L.DomUtil.create('div', 'leaflet-bar input-group search-box-control');
    this._container.style.width = '300px';
    addEventHandler(this._container, 'mouseenter', this._disableMapEvents);
    addEventHandler(this._container, 'mouseleave', this._enableMapEvents);

    this._input = L.DomUtil.create('input', 'form-control', this._container);
    this._input.style.border = 0;
    this._input.setAttribute('type', 'text');
    this._input.setAttribute('spellcheck', 'false');
    this._input.setAttribute('placeholder', 'Search...');
    addEventHandler(this._input, 'keyup', this._onKeyUp);
    addEventHandler(this._input, 'focus', this._focus);
    addEventHandler(this._input, 'blur', this._blur);

    const div = L.DomUtil.create('div', 'input-group-append', this._container);
    this._clearButton = this._createButton(div, faTimes, 'Clear', this._onClearClick, true);
    this._searchButton = this._createButton(div, faSearch, 'Search', this._onSearchClick, false);

    this._onTextSearch = null;
    this._leafletMap = leafletMap;

    this._leafletMap.addControl(new L.Control.SearchControl({ position: 'topright', container: this._container }));

    this.clearButtonVisible = false;
  }

  get visible() {
    const result = getComputedStyle(this._container).display.toLowerCase() !== 'none';
    return result;
  }
  set visible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "' + this._objectClassName + '.visible.set"');
    }

    if (this.visible !== value) {
      if (value) {
        this._container.style.display = 'flex';
      } else {
        this._container.style.display = 'none';
      }
    }
  }

  _focus = () => {
    this._input.select();
    this.clearButtonVisible = true;
  }

  _blur = () => {
    this.clearButtonVisible = false;
  }

  get clearButtonVisible() {
    return this._clearButton.style.backgroundColor !== 'white';
  }
  set clearButtonVisible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid "value" param supplied to "SearchControl.clearButtonVisible.set"');
    }

    this._clearButton.style.backgroundColor = value
      ? this._searchButton.style.backgroundColor
      : 'white';
  }

  get onTextSearch() { return this._onTextSearch; }
  set onTextSearch(value) {
    if (this._onTextSearch !== value) {
      this._onTextSearch = value;
    }
  }

  get value() { }
  set value(value) {
    if (typeof value !== 'string') {
      throw new Error('Invalid "value" param supplied to "SearchControl.value.set"');
    }

    if (this.value !== value) {
      this._input.value = value;
    }
  }

  _disableMapEvents = () => {
    if (this._mapEvents === null) {
      this._mapEvents = getMapEvents(this._leafletMap);
      disableMapEvents(this._leafletMap);
    }
  }

  _enableMapEvents = () => {
    if (this._mapEvents !== null) {
      restoreMapEvents(this._leafletMap, this._mapEvents);
      this._mapEvents = null;
    }
  }

  _createButton(container, iconDef, title, onClick, left = true) {
    const result = L.DomUtil.create('button', 'btn btn-secondary', container);
    result.setAttribute('type', 'button');
    result.style.width = '29px';
    result.style.paddingLeft = '6px';
    result.style.paddingRight = '6px';
    result.style.border = 0;
    if (left) {
      result.style.borderBottomRightRadius = '0px';
      result.style.borderTopRightRadius = '0px';
    }
    else {
      result.style.borderBottomRightRadius = '0.25rem';
      result.style.borderTopRightRadius = '0.25rem';
    }

    result.style.borderBottomLeftRadius = '0px';
    result.style.borderTopLeftRadius = '0px';
    result.innerHTML = getIcon(iconDef, { title: title });
    addEventHandler(result, 'click', onClick);
    return result;
  }

  _onKeyUp = (e) => {
    if (e.keyCode === 13) {
      this._onSearchClick();
    }
  }

  _onClearClick = () => {
    this._input.value = '';
    this._input.focus();
  }

  _onSearchClick = () => {
    if (typeof this._onTextSearch === 'function') {
      const value = this._input.value.trim();
      if (value.length > 0) {
        this._onTextSearch(this._input.value);
      }
    }
  }
}

export { SearchControl };
