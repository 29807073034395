import { getDate } from '../../../shared/helpers/date-helper';
import { objectToEnum } from '../../../shared/helpers/common';

const autoUpdateTypes = objectToEnum({
  Error: 1,
  InGMatrixNotInGovt: 2,
  InGovtNotInGMatrix: 3,
  RelatedRecordNotFound: 4,
  Removal: 5,
  Exclusion: 6,
  Update: 7,
  Compare: 8
}, false);

const autoUpdateEntity = objectToEnum({
  Well: 1
}, false);

autoUpdateTypes.getDisplayString = (value) => {
  switch (value) {
    case autoUpdateTypes.Error:
      return 'Error';
    case autoUpdateTypes.InGMatrixNotInGovt:
      return 'In GMatrix Not in Govt';
    case autoUpdateTypes.InGovtNotInGMatrix:
      return 'In Govt Not in GMatrix';
    case autoUpdateTypes.RelatedRecordNotFound:
      return 'Related Record Not Found';
    case autoUpdateTypes.Removal:
      return 'Removal';
    case autoUpdateTypes.Exclusion:
      return 'Exclusion';
    case autoUpdateTypes.Update:
      return 'Update';
    case autoUpdateTypes.Compare:
      return 'Compare';
    case autoUpdateTypes.RemovedAtribute:
      return 'Removed Attribute';
    default:
      return 'Unknown';
  }
};

autoUpdateEntity.getDisplayString = (value) => {
  switch (value) {
    case autoUpdateEntity.Well:
      return 'Well';
    default:
      return 'Unknown';
  }
};

class AutoUpdateTypes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to AutoUpdateTypes.ctor'); }
    if (obj !== null) {
      if (typeof obj.autoUpdateLogTypeId !== 'number') { throw new Error('Invalid obj.autoUpdateLogTypeId param supplied to AutoUpdateTypes.ctor'); }
      if (typeof obj.autoUpdateLogType !== 'string') { throw new Error('Invalid obj.autoUpdateLogType param supplied to AutoUpdateTypes.ctor'); }
    }

    this.autoUpdateLogTypeId = obj === null ? 0 : obj.autoUpdateLogTypeId;
    this.autoUpdateLogType = obj === null ? '' : obj.autoUpdateLogType;
  }
}

class AutoUpdateLogs {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to AutoUpdateLogs.ctor'); }
    if (obj !== null) {
      if (obj.entityUID !== null && typeof obj.entityUID !== 'string') { throw new Error('Invalid obj.entityUID param supplied to AutoUpdateLogs.ctor'); }
      if (obj.entityName !== null && typeof obj.entityName !== 'string') { throw new Error('Invalid obj.entityName param supplied to AutoUpdateLogs.ctor'); }
      if (typeof obj.message !== 'string') { throw new Error('Invalid obj.message param supplied to AutoUpdateLogs.ctor'); }
      if (obj.messageDateTime !== null && typeof obj.messageDateTime !== 'string') { throw new Error('Invalid obj.messageDateTime param supplied to AutoUpdateLogs.ctor'); }
      if (typeof obj.entityType !== 'number') { throw new Error('Invalid obj.entityType param supplied to AutoUpdateLogs.ctor'); }
      if (typeof obj.logType !== 'number') { throw new Error('Invalid obj.logType param supplied to AutoUpdateLogs.ctor'); }
      if (obj.countryId !== null && typeof obj.countryId !== 'number') { throw new Error('Invalid obj.countryId param supplied to AutoUpdateLogs.ctor'); }
      if (obj.countryName !== null && typeof obj.countryName !== 'string') { throw new Error('Invalid obj.countryName param supplied to AutoUpdateLogs.ctor'); }

    }

    this.entityUID = obj === null ? '0' : obj.entityUID;
    this.entityName = obj === null ? '' : obj.entityName;
    this.message = obj === null ? '' : obj.message;
    this.messageDateTime = obj === null ? '' : getDate(obj.messageDateTime);

    this.entityType = obj === null ? 'Unknown' : autoUpdateEntity.getDisplayString(obj.entityType);
    this.logType = obj === null ? 'Unknown' : autoUpdateTypes.getDisplayString(obj.logType);
    this.countryId = obj === null ? '0' : obj.countryId;
    this.countryName = obj === null ? '' : obj.countryName;
  }
}

export { AutoUpdateLogs, AutoUpdateTypes };
