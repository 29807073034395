import React from "react";
import PropTypes from 'prop-types';
import { NumericFormat } from "react-number-format";

const INPUTTYPES = {
  NUMBER: 0,
  YEAR: 1
};
Object.freeze(INPUTTYPES);

const NumberFormatter = (props) => {
  let { prefix, thousandSeparator, allowNegative, decimalScale, title, numberType, widthPercent, topPX, leftPX, precision, id, disabled } = props;
  if (prefix === undefined || prefix === null) prefix = '';
  if (thousandSeparator === undefined || thousandSeparator === null) thousandSeparator = '';
  if (allowNegative === undefined || allowNegative === null) allowNegative = true;
  if (decimalScale === undefined || decimalScale === null) decimalScale = 0;
  if (title === undefined || title === null) title = '';
  if (numberType === undefined || numberType === null) numberType = INPUTTYPES.NUMBER;
  if (widthPercent === undefined || widthPercent === null) widthPercent = "98%";
  if (topPX === undefined || topPX === null) topPX = '4px';
  if (leftPX === undefined || leftPX === null) leftPX = '4px';
  if (precision === undefined || precision === null) precision = 0;
  if (id === undefined || id === null) id = 0;
  if (disabled === undefined || disabled === null) disabled = false;

  const stepper = precision === 'undefined' || precision === 0 ? 1 : 1.0 / Math.pow(10, precision);
  const yearFormatter = numberType === INPUTTYPES.YEAR ? "####" : undefined;
  //const yearMask = props.numberType === INPUTTYPES.YEAR ? "-" : undefined;
  const value = numberType === INPUTTYPES.YEAR && (props.value === 0 || props.value === "0") ? "0000" : props.value;
  const disabledBackGroundColour = disabled ? "#cbd3da" : "white";

  const handleFocus = (event) => event.target.select();

  return (
    <div style={{ border: 'none', position: 'relative', width: widthPercent, top: topPX, left: leftPX, backgroundColor: disabledBackGroundColour }}>
      <NumericFormat style={{ border: 'none', width: '100%' }}
        thousandsGroupStyle="thousand"
        value={value}
        prefix={prefix}
        decimalSeparator="."
        displayType="input"
        type="text"
        step={stepper}
        thousandSeparator={thousandSeparator}
        allowNegative={allowNegative}
        decimalScale={precision}
        onChange={props.onChange}
        onBlur={props.onBlur}
        id={id}
        disabled={disabled}
        autoComplete="off"
        format={yearFormatter}
        //mask={yearMask}
        onClick={handleFocus}
      />
    </div>
  );
};

NumberFormatter.propTypes = {
  prefix: PropTypes.string,
  thousandSeparator: PropTypes.string,
  allowNegative: PropTypes.bool,
  decimalScale: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  title: PropTypes.string,
  id: PropTypes.number,
  disabled: PropTypes.bool,
  numberType: PropTypes.number,
  precision: PropTypes.number,
  widthPercent: PropTypes.string,
  topPX: PropTypes.string,
  leftPX: PropTypes.string,
};

export default NumberFormatter;
export { INPUTTYPES };
