import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import moment from 'moment';

import * as ViewFunctions from '../view-common/view-functions';
import './resource-view.scss';

// Models
import { Result } from './models/result';
import { PostbackInfo } from '../view-common/postback-info';
// Components
import TopPanelComponent from './components/top-panel/top-panel-component';
import ArchiveProspectComponent from './components/archive-prospect-component';
import BottomPanelComponent from './components/bottom-panel/bottom-panel-component';
import PromoteDiscoveryToNtdComponent from './components/promote-discovery-to-ntd-component';
// Common models
import { RECORD_STATUS } from './../../common/models/record-status';
import { Views, ViewGlobal, IdGlobal, LoadingGlobal, MessageBoxGlobal, CountriesGlobal, EditingGlobal, SearchGlobal, PinLocationGlobal } from '../../common/globals/globals';
import { StringLookupResult } from '../../common/models/string-lookup-result';
// Common components
import { MessageBoxButton, MessageBoxButtons } from '../../shared/components/message-box/message-box-classes';
import EditableViewComponent, { layerType } from '../../common/components/editable-view/editable-view-component';
import { convertDecimalLocationToDMS } from '../../common/helpers/location-helpers';
// Shared components
import SplitterPanelComponent from '../../shared/components/splitter-panel/splitter-panel-component';
// Helpers
import { editLocation } from './../../common/components/location/location-helper';
import { addResourceText, deleteResourceText, editResourceText } from '../../common/helpers/resource-text-helpers';
import { apiPost, apiGet, callApiDefaultOnFail } from '../../common/helpers/api-helpers';
import { GMATRIX_ENTITY } from '../../common/models/gmatrix-entity';
import { RELATED_ITEM_TYPE } from '../../common/models/related-item-type';

// Resource helpers
import { editAttributes } from './helpers/attributes-helper';

class ResourceView extends React.Component {
  constructor(props) {
    super(props);

    this.refresh = ViewFunctions.refresh.bind(this);
    this.beginEdit = ViewFunctions.beginEdit.bind(this);
    this.saveEdit = ViewFunctions.saveEdit.bind(this);
    this.cancelEdit = ViewFunctions.cancelEdit.bind(this);
    this.copyEntity = ViewFunctions.copyEntity.bind(this);
    this.resetEditTimeout = ViewFunctions.resetEditTimeout.bind(this);
    this.canButtonsEnable = ViewFunctions.canButtonsEnable.bind(this);
    this.canPerformAction = ViewFunctions.canPerformAction.bind(this);
    this.didMount = ViewFunctions.didMount.bind(this);
    this.getCurrentEntity = ViewFunctions.getCurrentEntity.bind(this);  
    this.getData = ViewFunctions.getData.bind(this);
    this.handleCopyEntity = ViewFunctions.handleCopyEntity.bind(this);
    this.initData = ViewFunctions.initData.bind(this);
    this.updateSearchResults = ViewFunctions.updateSearchResults.bind(this);
    this.newData = this.newData.bind(this);
    this.getActions = ViewFunctions.getActions.bind(this);
    this.setSelected = ViewFunctions.setSelected.bind(this);
    this.updateData = ViewFunctions.updateData.bind(this);
    this.populatePostbackInfo = ViewFunctions.populatePostbackInfo.bind(this);
    this.setDataInState = ViewFunctions.setDataInState.bind(this); 

    this.promote = this.promote.bind(this);
    this.demote = this.demote.bind(this);

    //not used yet -  
    //this.updateAttributes = ViewFunctions.updateAttributes.bind(this); //WHY AM I NOT USING THIS YET?
    //this.deleteEntity = this.deleteEntity.bind(this); //CANT DELETE RESOURCE 
    //

    this.lookups = null;
    this.filteredLookups = null;
    this.lookupsWestwood = null;
    this.state = {
      entityData: null, showResourceGrid: true, canEnable: true, selectedItems: null
    };
  }

  get apiPrefix() { return 'api/resource/' };
  get ownershipLookupURL() { return this.apiPrefix + 'lookups/ownership' };
  get countryLookupURL() { return this.apiPrefix + 'lookups/countries' };
  get viewGlobal() { return this.props.viewGlobal; };
  get idGlobal() { return this.props.idGlobal; };
  get editingGlobal() { return this.props.editingGlobal; };
  get countriesGlobal() { return this.props.countriesGlobal; };
  get loadingGlobal() { return this.props.loadingGlobal; };
  get messageBoxGlobal() { return this.props.messageBoxGlobal; };
  get searchGlobal() { return this.props.searchGlobal; };
  get pinLocationGlobal() { return this.props.pinLocationGlobal; };
  get gmatrixEntity() { return GMATRIX_ENTITY.Resource; }
  get westwoodlookupURL() { return this.apiPrefix + 'lookups-westwood' };

  componentDidMount() {
    this.loadLookupsWestwood(this.didMount);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idGlobal.value !== this.props.idGlobal.value) {
      this.initData();
    }
  }

  getPostbackInfo(forEdit, autoClose) {
    const result = new PostbackInfo();
    this.populatePostbackInfo(result, forEdit, autoClose);

    if (result !== null && result.payload !== null && result.payload.attributes !== null) {
      result.payload.attributes.shapes = null;
    }

    return result;
  }

  newData() {
    const messageBoxCallback = (button) => {
      let apiUrl = this.apiPrefix + 'new?resourceStatusParent=';
      switch (button) {
        case -1: apiUrl += '3'; break; // ResourceStatusParent.Prospect
        case -2: apiUrl += '2'; break; // ResourceStatusParent.Discovery
        default: this.idGlobal.clear(); return; // Cancel
      }

      const postbackInfo = this.getPostbackInfo(true, false);
      const onSuccess = (result) => this.setData(new Result(result), () => this.editingGlobal.set(true));
      const onFail = (e) => this.idGlobal.clear(() => callApiDefaultOnFail(e, this.messageBoxGlobal));
      apiPost(apiUrl, JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, onFail);
    };

    this.messageBoxGlobal.showQuestion('Do you want to create a prospect or a discovery?', messageBoxCallback,
      ['Prospect', 'Discovery', MessageBoxButton.Cancel], 'New Resource'
    );
  }

  setData(newData, callback = undefined) {
    let entity = newData === null ? null : new Result(newData);

    if (entity !== null && !(entity instanceof Result)) {
      throw new Error('Invalid newData param supplied to setData');
    }

    this.setDataInState(entity, callback);
  }

  loadLookupsWestwood(callBack = undefined) {
    const apiUrl = this.westwoodlookupURL;
    const onSuccess = (result) => {
      this.lookupsWestwood = new StringLookupResult(result);
      if (callBack !== undefined) callBack();
    }
    apiGet(apiUrl, this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
  }

  promote() {
    let entity = this.getCurrentEntity();

    if (entity !== null && entity.attributes !== null) {
      const currentResource = entity.attributes;

      if (!this.canPerformAction('promote')) {
        return;
      }

      let ntdStartYear = new Date().getFullYear();
      let ntdDuration = 1;

      const msgCallback = (button) => {
        if (button === MessageBoxButton.OK) {
          const url = this.apiPrefix + 'promote?ntdStartYear=' + ntdStartYear + '&ntdDuration=' + ntdDuration;
          const apiCallback = (result) => {
            this.refresh();
            this.updateSearchResults();
          };
          const postbackInfo = this.getPostbackInfo(false, false);
          apiPost(url, JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, apiCallback, undefined);
        }
      };

      let header = 'Promote';
      let msgContents = 'Are you sure you want to promote \'' + currentResource.resourceName + '\'?';
      if (currentResource.statusId === 6) { // Discovery
        header = 'Promote Discovery';
        msgContents = <PromoteDiscoveryToNtdComponent resourceName={currentResource.resourceName} ntdStartYear={ntdStartYear} ntdDuration={ntdDuration} onNtdStartYearChange={value => ntdStartYear = value} onNtdDurationChange={value => ntdDuration = value} />;
      }
      this.messageBoxGlobal.showQuestion(msgContents, msgCallback, MessageBoxButtons.OKCancel, header);
    }

  }

  demote() {
    let entity = this.getCurrentEntity();

    if (entity !== null && entity.attributes !== null) {
      const currentResource = entity.attributes;

      if (currentResource.statusParentId === 3) { // PROSPECT
        this.archiveProspect();
        return;
      }

      if (!this.canPerformAction('demote')) {
        return;
      }

      const msgCallback = (button) => {
        if (button === MessageBoxButton.OK) {
          const apiCallback = (result) => {
            this.refresh();
            this.updateSearchResults();
          };
          const postbackInfo = this.getPostbackInfo(false, false);
          apiPost(this.apiPrefix + 'demote', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, apiCallback, undefined);
        }
      };

      const msgContents = 'Are you sure you want to demote \'' + currentResource.resourceName + '\'?';
      this.messageBoxGlobal.showQuestion(msgContents, msgCallback, MessageBoxButtons.OKCancel, 'Demote');
    }
  }

  archiveProspect() {
    let entity = this.getCurrentEntity();

    if (entity !== null && entity.attributes !== null) {
      const currentResource = entity.attributes;

      if (!this.canPerformAction('archive')) {
        return;
      }

      let histStatusId = 1;
      const msgCallback = (button) => {
        if (button === MessageBoxButton.OK) {
          const url = this.apiPrefix + 'archive?historicStatusId=' + histStatusId;
          const apiCallback = (result) => {
            this.idGlobal.clear();
            this.updateSearchResults();
          };
          const postbackInfo = this.getPostbackInfo(false, false);
          apiPost(url, JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, apiCallback, undefined);
        }
      };

      const msgContents = <ArchiveProspectComponent resourceName={currentResource.resourceName} lookups={this.lookups.HistoricProspectStatus} onChange={value => histStatusId = value} />;
      this.messageBoxGlobal.showQuestion(msgContents, msgCallback, MessageBoxButtons.OKCancel, 'Archive Prospect');
    }
  }

  getResourceAttributesActions() {
    return {
      onEdit: (item) => {
        editAttributes(item);
        this.updateData(); 
      }
    };
  }

  getResourceDescriptionActions(currentResource) {
    return {
      onAdd: (textCategoryId) => {
        addResourceText(currentResource.resourceDescriptiveText, currentResource.id, textCategoryId);
        this.updateData(); 
      },
      onDelete: (item) => {
        deleteResourceText(currentResource.resourceDescriptiveText, item);
        this.updateData(); 
      },
      onEdit: (item) => {
        editResourceText(item);
        this.updateData(); 
      }
    };
  }

  copyPinLocation = () => {
    let entity = this.getCurrentEntity();

    if (entity !== null && entity.attributes !== null && entity.attributes.locations !== null) {
      let location = convertDecimalLocationToDMS(this.pinLocationGlobal.value);

      let resourceLocations = entity.attributes.locations.filter(obj => obj.status !== RECORD_STATUS.deleted);
      if (resourceLocations !== null && resourceLocations !== undefined && resourceLocations.length > 0) {
        let primary = resourceLocations.find(x => x.stratigraphicSplit === false);
        if (primary !== null && primary !== undefined) {
          primary.dmsLatDegrees = location.dmsLatDegrees;
          primary.dmsLatMinutes = location.dmsLatMinutes;
          primary.dmsLatSeconds = location.dmsLatSeconds;
          primary.dmsNorthing = location.dmsNorthing;
          primary.dmsLongDegrees = location.dmsLongDegrees;
          primary.dmsLongMinutes = location.dmsLongMinutes;
          primary.dmsLongSeconds = location.dmsLongSeconds;
          primary.dmsEasting = location.dmsEasting;

          editLocation(primary);
          this.updateData();
        }
      }
    }
  }

  render() {
    const entity = this.getCurrentEntity();

    if (entity === null || this.lookups === null) {
      return <div className="view-panel no-data"><h1>Please select a resource, or click<span className="new-button" onClick={() => this.idGlobal.setNewRecordId()} />for a new resource.</h1></div>;
    }

    const { attributes, issues, associatedDataContents, editLock, lookupsFiltered } = entity;
    const { id } = entity.attributes;

    const { canEnable } = this.state;

    const productionComments = attributes.productionComments.filter(obj => obj.status !== RECORD_STATUS.deleted); 
    const resourceAliases = attributes.resourceAlias.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const resourceAnnuals = attributes.resourceAnnuals.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const resourceBlocks = attributes.resourceBlock.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const officialUIDs = attributes.officialUID.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const resourceComments = attributes.resourceComments.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const resourceDescriptions = attributes.resourceDescriptiveText.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const locations = attributes.locations.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const ownership = attributes.ownership.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const resourceProcessing = attributes.resourceProcessing.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const resourceWells = attributes.resourceWell.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const fixedPlatforms = attributes.fixedPlatforms?.filter(obj => obj.status !== RECORD_STATUS.deleted) ?? null;
    const floatingPlatforms = attributes.floatingPlatforms?.filter(obj => obj.status !== RECORD_STATUS.deleted) ?? null;

    const editTimeout = this.editingGlobal.value && editLock !== undefined && editLock !== null ? moment(editLock.expiryTime).toDate() : undefined;

    const shortcuts = [
      { label: 'Open in ' + Views.displayName(Views.Production), onClick: () => this.viewGlobal.setAndId(Views.Production, this.idGlobal.value, attributes.resourceName, () => this.searchGlobal.search(attributes.resourceName, false)) }
    ];

    const toolbarItems = [
      <Button key={0} className="btn-image first-button copy-button" size="sm" disabled={this.editingGlobal.value} onClick={this.handleCopyEntity}>Copy</Button>,
      <Button key={1} className="btn-image first-button promote-button" size="sm" disabled={this.editingGlobal.value || !attributes.canPromote} onClick={this.promote}>Promote</Button>,
      <Button key={2} className="btn-image last-button demote-button" size="sm" disabled={this.editingGlobal.value || !attributes.canDemote} onClick={this.demote}>Demote</Button>

    ];

    let resourcelayerType = layerType.Fields;
    if (attributes !== null) {
      switch (attributes.statusId) {
        case 6:
          resourcelayerType = layerType.Discoveries;
          break;
        case 7:
          resourcelayerType = layerType.Prospects;
          break;
        default:
          resourcelayerType = layerType.Fields;
          break;
      }
    }

    return (
      <EditableViewComponent
        className="view-panel resource-view"
        loadingGlobal={this.loadingGlobal}
        header={'Resource: ' + attributes.resourceName}
        messageBoxGlobal={this.messageBoxGlobal}
        editingGlobal={this.editingGlobal}
        issues={issues}
        canRefresh={!this.idGlobal.isNewRecordId}
        canEnable={canEnable}
        refresh={this.refresh}
        beginEdit={this.beginEdit}
        cancelEdit={this.cancelEdit}
        saveEdit={this.saveEdit}
        editTimeout={editTimeout}
        resetEditTimeout={this.resetEditTimeout}
        shortcuts={shortcuts}
        toolbarItems={toolbarItems}
        toolbarItemsPosition="after"
        isProduction={false}
        canCopyPin={attributes === null ? false : (attributes.statusId === 6 || attributes.statusId === 7) ? true : false}
        pinLocationGlobal={this.pinLocationGlobal}
        onCopyPin={this.copyPinLocation}
        viewGlobal={this.props.viewGlobal}
        searchGlobal={this.props.searchGlobal}
        currentView={this.gmatrixEntity}
        currentId={this.idGlobal.value}
        recordLocked={false}
        startYear={0}
        endYear={0}
        selectedStartYear={0}
        selectedEndYear={0}
        yearStartChange={() => { }}
        yearEndChange={() => { }}
        updateSnapshotAudit={() => { }}
        edited={false}
        calculate={() => { }}
        canDelete={false}
        onDelete={() => { }}
        showCalc
      >
        <SplitterPanelComponent vertical={false} percentage secondarySize={50}>
          <TopPanelComponent
            editing={this.editingGlobal.value}
            loadingGlobal={this.loadingGlobal}
            messageBoxGlobal={this.messageBoxGlobal}
            pinLocationGlobal={this.pinLocationGlobal}
            lookups={this.lookups}
            attributes={attributes}
            resourceAttributesActions={this.getResourceAttributesActions()}
            resourceAnnuals={resourceAnnuals}
            invalidateCalculatedValues={() => { }}
            geoserverUrl={this.props.geoserverUrl}
            mapComponentName={'resource-view-component-map'}
            contextLayerType={resourcelayerType}
            resourceAliasActions={this.getActions(attributes.resourceAlias, RELATED_ITEM_TYPE.ALIAS)}
            lookupsWestwood={this.lookupsWestwood}
          />
          <BottomPanelComponent
            editing={this.editingGlobal.value}
            loadingGlobal={this.loadingGlobal}
            messageBoxGlobal={this.messageBoxGlobal}
            lookups={this.lookups}
            countryId={attributes.countryId}
            associatedDataContents={associatedDataContents}
            resourceAnnuals={resourceAnnuals}
            productionComments={productionComments}
            productionCommentActions={this.getActions(attributes.productionComments, RELATED_ITEM_TYPE.PRODUCTIONCOMMENT)}
            resourceComments={resourceComments}
            resourceCommentActions={this.getActions(attributes.resourceComments, RELATED_ITEM_TYPE.RESOURCECOMMENT)}
            resourceDescriptions={resourceDescriptions}
            resourceDescriptionActions={this.getResourceDescriptionActions(attributes)}
            resourceAliases={resourceAliases}
            resourceAliasActions={this.getActions(attributes.resourceAlias, RELATED_ITEM_TYPE.ALIAS)}
            resourceBlocks={resourceBlocks}
            resourceBlockActions={this.getActions(attributes.resourceBlock, RELATED_ITEM_TYPE.BLOCK)}
            locations={locations}
            locationActions={this.getActions(attributes.locations, RELATED_ITEM_TYPE.LOCATION)}
            officialUIDs={officialUIDs}
            officialUIDActions={this.getActions(attributes.officialUID, RELATED_ITEM_TYPE.OFFICIALUID)}
            countryLookupURL={this.countryLookupURL}
            ownership={ownership}
            ownershipActions={this.getActions(attributes.ownership, RELATED_ITEM_TYPE.OWNERSHIP)}
            ownershiplookupURL={this.ownershipLookupURL}
            resourceProcessing={resourceProcessing}
            resourceProcessingActions={this.getActions(attributes.resourceProcessing, RELATED_ITEM_TYPE.HUB)}
            resourceWells={resourceWells}
            resourceWellActions={this.getActions(attributes.resourceWell, RELATED_ITEM_TYPE.WELL)}
            lookupsFiltered={lookupsFiltered}
            canButtonsEnable={this.canButtonsEnable}
            canEnable={canEnable}
            onChangeHubDescription={() => { }}
            onChangeHubSnapshotComment={() => { }}
            entityId={id}
            canEditOwnership={attributes.canEditOwnership}
            dpfField={attributes.dpfField}
            fixedPlatforms={fixedPlatforms}
            fixedPlatformActions={this.getActions(attributes.fixedPlatforms, RELATED_ITEM_TYPE.WESTWOOD_DATA_FIXED_PLATFORM)}
            floatingPlatforms={floatingPlatforms}
            floatingPlatformActions={this.getActions(attributes.floatingPlatforms, RELATED_ITEM_TYPE.WESTWOOD_DATA_FLOATING_PLATFORM)}
            lookupsWestwood={this.lookupsWestwood}
          />
        </SplitterPanelComponent>
      </EditableViewComponent>
    );
  }
}

ResourceView.propTypes = {
  viewGlobal: PropTypes.instanceOf(ViewGlobal).isRequired,
  idGlobal: PropTypes.instanceOf(IdGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  countriesGlobal: PropTypes.instanceOf(CountriesGlobal).isRequired,
  searchGlobal: PropTypes.instanceOf(SearchGlobal).isRequired,
  editingGlobal: PropTypes.instanceOf(EditingGlobal).isRequired,
  pinLocationGlobal: PropTypes.instanceOf(PinLocationGlobal).isRequired
};

export default ResourceView;
