import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap'

import './tree-view-component.scss';
import { TreeNode } from './tree-node';
import { appendClassNames } from '../../helpers/common';

const getNodeClassName = (node) => {
  if (!(node instanceof TreeNode)) {
    throw new Error('Invalid node param \'' + node + '\' supplied to TreeViewComponent.getNodeClassName');
  }

  let result = 'node' + (node.disabled ? ' disabled' : '') + (node.selected ? ' selected' : '');
  if (node.isParent) {
    result += ' parent';
    if (node.hasChildren) {
      result += (node.collapsed ? ' collapsed' : ' expanded');
    } else {
      result += ' empty';
    }
  } else {
    result += ' child';
  }
  return result;
};

const addHighlight = (label, highlightText) => {
  if (typeof label !== 'string' || typeof highlightText !== 'string' || label.length === 0 || highlightText.length === 0) {
    return label;
  }

  const idx = label.toLowerCase().indexOf(highlightText.toLowerCase());
  if (idx !== -1) {
    const start = label.slice(0, idx);
    const middle = label.slice(idx, idx + highlightText.length);
    const end = label.slice(idx + highlightText.length);
    label = <React.Fragment>{start}<span className="highlight">{middle}</span>{end}</React.Fragment>;
  }
  return label;
};


const findSelectedNode = (nodes) => {
  for (var loop = 0; loop < nodes.length; loop++) {
    if (nodes[loop].selected) {
      return nodes[loop];
    }

    if (nodes[loop].hasChildren) {
      const result = findSelectedNode(nodes[loop].children);
      if (result !== null) {
        return result;
      }
    }
  }
  return null;
}


class TreeViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.toggleClick = this.toggleClick.bind(this);
    this.labelClick = this.labelClick.bind(this);

    this.selectedNode = findSelectedNode(this.props.nodes);
    this.state = { nodes: this.props.nodes };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nodes !== this.props.nodes) {
      this.selectedNode = findSelectedNode(this.props.nodes);
      this.setState({ nodes: this.props.nodes });
    }
  }

  toggleClick(node) {
    if (typeof this.props.toggleClick === 'function' && this.props.toggleClick(node) === false) {
      this.forceUpdate();
      return;
    }

    if (node.isParent && node.hasChildren) {
      node.collapsed = !node.collapsed;
      this.forceUpdate();
    }
  }

  labelClick(node) {
    if (typeof this.props.labelClick === 'function' && this.props.labelClick(node) === false) {
      this.forceUpdate();
      return;
    }

    if (!node.disabled && node !== this.selectedNode) {
      if (this.selectedNode !== null) {
        this.selectedNode.selected = false;
      }
      this.selectedNode = node;
      this.selectedNode.selected = true;
      this.forceUpdate();
    }
  }

  render() {
    const renderItem = (item, key) => {
      if (Array.isArray(item)) {
        return item.map((tmp, index) => renderItem(tmp, key + index));
      }

      if (!(item instanceof TreeNode)) {
        return <div key={key}>{item}</div>
      }

      const drawToggle = (canvas, collapsed, color) => {
        if (!canvas || !canvas.getContext) {
          return;
        }

        canvas.width = 9;
        canvas.height = 9;
        const context = canvas.getContext('2d');
        context.strokeStyle = color;
        context.lineWidth = 1;

        // Draw box
        context.strokeRect(0, 0, canvas.width, canvas.height);
        // Draw horz line
        context.moveTo(2, 4.5);
        context.lineTo(7, 4.5);
        context.stroke();
        // Draw vert line
        if (collapsed) {
          context.moveTo(4.5, 2);
          context.lineTo(4.5, 7);
          context.stroke();
        }
      }

      const toggle = item.isParent
        ? (
          <div className="toggle-div">
            <canvas className="toggle-img" ref={ref => drawToggle(ref, item.collapsed, item.selected ? 'white' : 'black')} />
          </div>
        )
        : null;

      return (
        <div key={key} className={getNodeClassName(item)}>
          <div className="label-wrapper">
            {toggle}
            <div className="label" onClick={() => this.labelClick(item)}>{item.label}</div>
          </div>
          {item.isParent && item.hasChildren && <Collapse className="children" isOpen={!item.collapsed}>{renderItem(item.children, key)}</Collapse>}
        </div>
      );
    }

    return (
      <div className={appendClassNames('tree-view-component', this.props.className)}>
        {renderItem(this.props.nodes, '')}
      </div>
    );
  }
}

TreeViewComponent.propTypes = {
  className: PropTypes.string,
  nodes: PropTypes.arrayOf(PropTypes.instanceOf(TreeNode)),
  toggleClick: PropTypes.func,
  labelClick: PropTypes.func
};

TreeViewComponent.defaultProps = {
  nodes: []
};

export default TreeViewComponent;
export { TreeNode, addHighlight };
