import React from 'react';
import PropTypes from 'prop-types';

import { Attributes } from '../../../models/result';
import { IN_DEV_MODE } from '../../../../../shared/helpers/common';
import { apiGet } from '../../../../../common/helpers/api-helpers';
import { LoadingGlobal, MessageBoxGlobal } from '../../../../../common/globals/globals';
import { LookupResult } from '../../../../../common/models/lookup-result';
import { valueTypes } from '../../../../../shared/helpers/value-handler';
import PropertyGridComponent, { PropertyGridStringValue, PropertyGridSelectValue, PropertyGridNumberValue, PropertyGridBooleanValue, PropertyGridDateValue, PropertyGridPercentageValue } from '../../../../../shared/components/property-grid/property-grid-component';
import { INPUTTYPES } from '../../../../../shared/components/number-format/number-formatter';

const IDS = {
  // ********* HUB *********
  HUB_ID: 5,
  HUB_NAME: 10,
  BLOCK_NO: 15,
  PRIME_RESOURCE_ID: 20,
  HUB_FLUID_TYPE_ID: 25,
  HUB_TYPE_ID: 26,
  OIL_EXPORT_TYPE_ID: 30,
  GAS_EXPORT_TYPE_ID: 35,
  OIL_TERMINAL_ID: 40,
  GAS_TERMINAL_ID: 45,
  OIL_PIPELINE_ID: 50,
  GAS_PIPELINE_ID: 55,

  OPERATOR_ID: 60,
  ACTUAL_OIL_CAPACITY: 65,
  ACTUAL_GAS_CAPACITY: 70,
  OIL_CAPACITY_AVAILABLE: 75,
  GAS_CAPACITY_AVAILABLE: 80,
  HUB_CAPTURE_RADIUS: 85,

  DMS_LAT_DEGREES: 90,
  DMS_LAT_MINUTES: 95,
  DMS_LAT_SECONDS: 100,
  DMS_NORTHING: 105,
  DMS_LONG_DEGREES: 110,
  DMS_LONG_MINUTES: 115,
  DMS_LONG_SECONDS: 120,
  DMS_EASTING: 125,
  STRAT_LEVEL_ID: 127,

  DECIMAL_LATITUDE: 130,
  DECIMAL_LONGITUDE: 135,

  HUB_STATUS_ID: 140,
  HPHT_PROCESSING_CAPABILTIY: 145,
  PLATFORM_COUNT: 150,
  PROCESSING_PLATFORM_COUNT: 155,
  PRODUCED_WATER_CAPACITY_BPD: 160,
  WATER_INJECTION_HANDLING_BPD: 165

};
Object.freeze(IDS);

const getSections = (attributes, lookups, valueChanged, hubNameChanged) => {

  const result = [
    {
      label: 'Hub',
      rows: [
        { label: 'Hub Name', values: [new PropertyGridStringValue(IDS.HUB_NAME, attributes.hubName, hubNameChanged)] },
        { label: 'Block No', values: [new PropertyGridStringValue(IDS.BLOCK_NO, attributes.blockNo)] },
        { label: 'Prime Resource', values: [new PropertyGridSelectValue(IDS.PRIME_RESOURCE_ID, attributes.primeResourceId, valueChanged, 'key', 'value', lookups.HubPrimeResource)] },
        { label: 'Hub Status', values: [new PropertyGridSelectValue(IDS.HUB_STATUS_ID, attributes.hubStatusId, valueChanged, 'key', 'value', lookups.HubStatus)] },
        { label: 'Hub Fluid Type', values: [new PropertyGridSelectValue(IDS.HUB_FLUID_TYPE_ID, attributes.hubFluidTypeId, valueChanged, 'key', 'value', lookups.HubFluidType)] },
        { label: 'Hub Type', values: [new PropertyGridSelectValue(IDS.HUB_TYPE_ID, attributes.hubTypeId, valueChanged, 'key', 'value', lookups.HubType)] },
        { label: 'HPHT Processing Capabiltiy', values: [new PropertyGridBooleanValue(IDS.HPHT_PROCESSING_CAPABILTIY, attributes.hphtProcessingCapabiltiy, valueChanged)] },
        { label: 'Hub Operator', values: [new PropertyGridSelectValue(IDS.OPERATOR_ID, attributes.operatorId, valueChanged, 'key', 'value', lookups.HubOperator)] },
        { label: 'Hub Capture Radius (km)', values: [new PropertyGridNumberValue(IDS.HUB_CAPTURE_RADIUS, INPUTTYPES.NUMBER, attributes.hubCaptureRadiusKm, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
        { label: 'No. of Platforms', values: [new PropertyGridNumberValue(IDS.PLATFORM_COUNT, INPUTTYPES.NUMBER, attributes.platformCount, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
        { label: 'No. of Processing Platforms', values: [new PropertyGridNumberValue(IDS.PROCESSING_PLATFORM_COUNT, INPUTTYPES.NUMBER, attributes.processingPlatformCount, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
      ]
    },
    {
      label: 'Oil',
      rows: [
        { label: 'Oil Export Type', values: [new PropertyGridSelectValue(IDS.OIL_EXPORT_TYPE_ID, attributes.oilExportTypeId, valueChanged, 'key', 'value', lookups.HubExportType)] },
        { label: 'Oil Terminal', values: [new PropertyGridSelectValue(IDS.OIL_TERMINAL_ID, attributes.oilTerminalId, valueChanged, 'key', 'value', lookups.Terminal)] },
        { label: 'Oil Pipeline', values: [new PropertyGridSelectValue(IDS.OIL_PIPELINE_ID, attributes.oilPipelineId, valueChanged, 'key', 'value', lookups.Pipeline)] },
        { label: 'Oil Capacity (bopd)', values: [new PropertyGridNumberValue(IDS.ACTUAL_OIL_CAPACITY, INPUTTYPES.NUMBER, attributes.actualOilCapacityBopd, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
        { label: 'Third Party Tie-in Oil Capacity Available', values: [new PropertyGridBooleanValue(IDS.OIL_CAPACITY_AVAILABLE, attributes.oilCapacityAvailable, valueChanged)] }
      ]
    },
    {
      label: 'Gas',
      rows: [
        { label: 'Gas Export Type', values: [new PropertyGridSelectValue(IDS.GAS_EXPORT_TYPE_ID, attributes.gasExportTypeId, valueChanged, 'key', 'value', lookups.HubExportType)] },
        { label: 'Gas Terminal', values: [new PropertyGridSelectValue(IDS.GAS_TERMINAL_ID, attributes.gasTerminalId, valueChanged, 'key', 'value', lookups.Terminal)] },
        { label: 'Gas Pipeline', values: [new PropertyGridSelectValue(IDS.GAS_PIPELINE_ID, attributes.gasPipelineId, valueChanged, 'key', 'value', lookups.Pipeline)] },
        { label: 'Gas Capacity (mmscfpd)', values: [new PropertyGridNumberValue(IDS.ACTUAL_GAS_CAPACITY, INPUTTYPES.NUMBER, attributes.actualGasCapacityMmscfd, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
        { label: 'Third Party Tie-in Gas Capacity Available', values: [new PropertyGridBooleanValue(IDS.GAS_CAPACITY_AVAILABLE, attributes.gasCapacityAvailable, valueChanged)] }
      ]
    },
    {
      label: 'Water',
      rows: [
        { label: 'Produced Water Capacity (bpd)', values: [new PropertyGridNumberValue(IDS.PRODUCED_WATER_CAPACITY_BPD, INPUTTYPES.NUMBER, attributes.producedWaterCapacityBpd, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
        { label: 'Water Injection Handling (bpd)', values: [new PropertyGridNumberValue(IDS.WATER_INJECTION_HANDLING_BPD, INPUTTYPES.NUMBER, attributes.waterInjectionHandlingBpd, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
      ]
    },
    {
      label: 'Location',
      rows: [
        { label: 'Latitude Degrees', values: [new PropertyGridNumberValue(IDS.DMS_LAT_DEGREES, INPUTTYPES.NUMBER, attributes.dmsLatDegrees, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
        { label: 'Latitude Minutes', values: [new PropertyGridNumberValue(IDS.DMS_LAT_MINUTES, INPUTTYPES.NUMBER, attributes.dmsLatMinutes, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
        { label: 'Latitude Seconds', values: [new PropertyGridNumberValue(IDS.DMS_LAT_SECONDS, INPUTTYPES.NUMBER, attributes.dmsLatSeconds, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Latitude Northing/Southing', values: [new PropertyGridSelectValue(IDS.DMS_NORTHING, attributes.dmsNorthing, valueChanged, 'key', 'value', [{ key: 'N', value: 'Northing' }, { key: 'S', value: 'Southing' }])] },

        { label: 'Longitude Degrees', values: [new PropertyGridNumberValue(IDS.DMS_LONG_DEGREES, INPUTTYPES.NUMBER, attributes.dmsLongDegrees, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
        { label: 'Longitude Minutes', values: [new PropertyGridNumberValue(IDS.DMS_LONG_MINUTES, INPUTTYPES.NUMBER, attributes.dmsLongMinutes, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
        { label: 'Longitude Seconds', values: [new PropertyGridNumberValue(IDS.DMS_LONG_SECONDS, INPUTTYPES.NUMBER, attributes.dmsLongSeconds, valueChanged, valueTypes.GetPrecision(valueTypes.generic3, 0))] },
        { label: 'Longitude Easting/Westing', values: [new PropertyGridSelectValue(IDS.DMS_EASTING, attributes.dmsEasting, valueChanged, 'key', 'value', [{ key: 'E', value: 'Easting' }, { key: 'W', value: 'Westing' }])] },

        { label: 'Decimal Latitude', values: [new PropertyGridNumberValue(IDS.DECIMAL_LATITUDE, INPUTTYPES.NUMBER, attributes.decimalLatitude, undefined, 2)] },
        { label: 'Decimal Longitude', values: [new PropertyGridNumberValue(IDS.DECIMAL_LONGITUDE, INPUTTYPES.NUMBER, attributes.decimalLongitude, undefined, 2)] },

        { label: 'Stratigraphic Level', values: [new PropertyGridSelectValue(IDS.STRAT_LEVEL_ID, attributes.stratLevelId, valueChanged, 'key', 'value', lookups.StratLevel, true)] },
      ]
    },
  ];

  if (IN_DEV_MODE) {
    result[0].rows.unshift({ label: 'Hub Id', values: [new PropertyGridStringValue(IDS.HUB_ID, attributes.id)] });
  }

  return result;
};

const LeftPanelComponent = (props) => {
  const {
    editing,
    loadingGlobal,
    messageBoxGlobal,
    lookups,
    attributes,
    updateAttributes
  } = props;

  const hubNameChanged = (value, id) => {
    if (id !== IDS.HUB_NAME) {
      throw new Error('Id ' + id + ' is not supported in AttributesComponent.hubNameChanged');
    }

    const onSuccess = (result) => {
      if (result) {
        messageBoxGlobal.showWarning('Hub Name ' + value + ' already exists in the Hub Table');
        hubNameChanged(attributes.hubName, IDS.HUB_NAME);
      }
      else {
        updateAttributes(attributes);
      }
    };

    if (value === attributes.hubName) {
      attributes.originalHubName = '';
      attributes.newHubName = '';
    }
    else {
      attributes.originalHubName = attributes.hubName;
      attributes.hubName = value;
      attributes.newHubName = value;
      apiGet('api/hub/nameCheck?name=' + value, loadingGlobal.show, loadingGlobal.hide, messageBoxGlobal, onSuccess, undefined);
    }

    updateAttributes(attributes)
  };

  const valueChanged = (value, id) => {
    switch (id) {
      // ********* HUB *********
      case IDS.PRIME_RESOURCE_ID: attributes.primeResourceId = Number(value); break;
      case IDS.HUB_STATUS_ID: attributes.hubStatusId = Number(value); break;
      case IDS.HUB_FLUID_TYPE_ID: attributes.hubFluidTypeId = Number(value); break;
      case IDS.HUB_TYPE_ID: attributes.hubTypeId = Number(value); break;
      case IDS.HPHT_PROCESSING_CAPABILTIY: attributes.hphtProcessingCapabiltiy = value; break;
      case IDS.OPERATOR_ID: attributes.operatorId = Number(value); break;
      case IDS.HUB_CAPTURE_RADIUS: attributes.hubCaptureRadiusKm = value; break;
      case IDS.PLATFORM_COUNT: attributes.platformCount = value; break;
      case IDS.PROCESSING_PLATFORM_COUNT: attributes.processingPlatformCount = value; break;

      // ********* OIL *********
      case IDS.OIL_EXPORT_TYPE_ID: attributes.oilExportTypeId = Number(value); break;
      case IDS.OIL_TERMINAL_ID: attributes.oilTerminalId = Number(value); break;
      case IDS.OIL_PIPELINE_ID: attributes.oilPipelineId = Number(value); break;
      case IDS.ACTUAL_OIL_CAPACITY: attributes.actualOilCapacityBopd = value; break;
      case IDS.OIL_CAPACITY_AVAILABLE: attributes.oilCapacityAvailable = value; break;

      // ********* GAS *********
      case IDS.GAS_EXPORT_TYPE_ID: attributes.gasExportTypeId = Number(value); break;
      case IDS.GAS_TERMINAL_ID: attributes.gasTerminalId = Number(value); break;
      case IDS.GAS_PIPELINE_ID: attributes.gasPipelineId = Number(value); break;
      case IDS.ACTUAL_GAS_CAPACITY: attributes.actualGasCapacityMmscfd = value; break;
      case IDS.GAS_CAPACITY_AVAILABLE: attributes.gasCapacityAvailable = value; break;

      // ********* WATER *********
      case IDS.PRODUCED_WATER_CAPACITY_BPD: attributes.producedWaterCapacityBpd = value; break;
      case IDS.WATER_INJECTION_HANDLING_BPD: attributes.waterInjectionHandlingBpd = value; break;

      // ********* LOCATION *********
      case IDS.DMS_LAT_DEGREES: attributes.dmsLatDegrees = value; break;
      case IDS.DMS_LAT_MINUTES: attributes.dmsLatMinutes = value; break;
      case IDS.DMS_LAT_SECONDS: attributes.dmsLatSeconds = value; break;
      case IDS.DMS_NORTHING: attributes.dmsNorthing = value; break;

      case IDS.DMS_LONG_DEGREES: attributes.dmsLongDegrees = value; break;
      case IDS.DMS_LONG_MINUTES: attributes.dmsLongMinutes = value; break;
      case IDS.DMS_LONG_SECONDS: attributes.dmsLongSeconds = value; break;
      case IDS.DMS_EASTING: attributes.dmsEasting = value; break;

      case IDS.STRAT_LEVEL_ID: attributes.stratLevelId = Number(value); break;

      default: throw new Error('Id ' + id + ' is not supported in AttributesComponent.valueChanged');
    }
    updateAttributes(attributes)
    return true;
  };

  const sections = getSections(attributes, lookups, valueChanged, hubNameChanged);

  return <PropertyGridComponent sections={sections} readOnly={!editing} />;
};

LeftPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  attributes: PropTypes.instanceOf(Attributes).isRequired,
  updateAttributes: PropTypes.func.isRequired,
};

export default LeftPanelComponent;
