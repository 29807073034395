import L from 'leaflet';
import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-light-svg-icons';

import { createButton as hlpCreateButton } from '../helpers/control-helper';

const createButton = hlpCreateButton;

class StackItem {
  constructor(map) {
    this.latLng = map.getCenter();
    this.zoom = map.getZoom();
    //console.log(this.latLng, this.zoom);
  }
}

const stackItemsMatch = (first, last) => {
  const result = first.latLng.lat === last.latLng.lat &&
    first.latLng.lng === last.latLng.lng &&
    first.zoom === last.zoom;
  return result;
};

const setButtonEnabled = (button, enabled) => {
  const DISABLED_STR = ' leaflet-disabled';
  let className = button.getAttribute('class');
  if (enabled) {
    if (className.includes(DISABLED_STR)) {
      className = className.replace(DISABLED_STR, '');
    }
  } else {
    if (!className.includes(DISABLED_STR)) {
      className += DISABLED_STR;
    }
  }
  button.setAttribute('class', className);
};

L.Control.NavbarControl = L.Control.extend({
  initialize: function (options) {
    this.options = options;
    this._forwardStack = [];
    this._backStack = [];
    this._trackMove = true;
  },
  onAdd: function (map) {
    this._container = L.DomUtil.create('div', 'leaflet-bar');

    map.on('moveend', this._onMove, this);

    this._backStack.push(new StackItem(map));

    this._homeButton = createButton(this._container, '', faHome, () => this._onHomeClick(), { title: 'Home' });
    this._backButton = createButton(this._container, '', faArrowLeft, () => this._onBackClick(), { title: 'Back' });
    this._forwardButton = createButton(this._container, '', faArrowRight, () => this._onForwardClick(), { title: 'Forward' });

    this._updateButtonsEnabled();

    return this._container;
  },
  onRemove: function (map) {
    map.off('moveend', this._onMove, this);
  },
  _onMove: function () {
    if (this._trackMove === true) {
      const newItem = new StackItem(this._map);
      const lastItem = this._backStack[this._backStack.length - 1];
      if (!stackItemsMatch(newItem, lastItem)) {
        this._backStack.push(newItem);
        this._forwardStack = [];
      }
    }
    this._updateButtonsEnabled();
    this._trackMove = true;
  },
  _updateButtonsEnabled: function () {
    setButtonEnabled(this._homeButton, this._homeEnabled());
    setButtonEnabled(this._backButton, this._backEnabled());
    setButtonEnabled(this._forwardButton, this._forwardEnabled());
  },
  _homeEnabled: function () {
    return this._backStack.length > 1;
  },
  _onHomeClick: function () {
    if (this._homeEnabled()) {
      this._trackMove = false;
      this._forwardStack = this._backStack.slice(1, this._backStack.length);
      this._backStack = [this._backStack[0]];
      this._goto(this._backStack[0]);
    }
  },
  _backEnabled: function () {
    return this._backStack.length > 1;
  },
  _onBackClick: function () {
    if (this._backEnabled()) {
      this._trackMove = false;
      this._forwardStack.push(this._backStack.pop());
      this._goto(this._backStack[this._backStack.length - 1]);
    }
  },
  _forwardEnabled: function () {
    return this._forwardStack.length > 0;
  },
  _onForwardClick: function () {
    if (this._forwardEnabled()) {
      this._trackMove = false;
      this._backStack.push(this._forwardStack.pop());
      this._goto(this._backStack[this._backStack.length - 1]);
    }
  },
  _goto: function (stackItem) {
    this._map.flyTo(stackItem.latLng, stackItem.zoom, { animate: false });
  }
});

L.control.navbarControl = (opts) => {
  return new L.Control.NavbarControl(opts);
};

const addNavbarControl = (leafletMap, position) => {
  const result = L.control.navbarControl({ position: position });
  leafletMap.addControl(result);
  return result;
};

export { addNavbarControl };