const ProductionPostbackInfoUpdateType = {
  unknown: 0,
  resourceAnnual: 10,
  resourceAttribute: 20,
  resourceComment: 30,
  resourceProductionComment: 40,
  resourceDescription: 50,
  hubAnnual: 60,
  hubAttribute: 70,
  hubComment: 80,
  hubDescription: 90,
  values: () => Object.values(ProductionPostbackInfoUpdateType).filter(obj => typeof obj === 'number'),
  isValid: (value) => ProductionPostbackInfoUpdateType.values().includes(value)
};
Object.freeze(ProductionPostbackInfoUpdateType);

class ProductionPostbackGridInfo {
  constructor() {
    this.updateType = ''; // ResourceAnnual or HubAnnual
    this.yearFrom = 0;
    this.yearTo = 0;
    this.columnName = '';
    this.newValue = [];
  }
}

class ProductionPostbackInfo extends ProductionPostbackGridInfo {
  constructor() {
    super();

    this.productionPayload = {};
    this.countryIds = [];
    this.resourceId = 0;
    this.forEdit = false;
    this.userName = '';
    this.autoClose = false;
  }
}

export { ProductionPostbackInfoUpdateType, ProductionPostbackGridInfo, ProductionPostbackInfo };