const LoadingImage = {
  Loading: 0,
  Logo: 1,
  Save: 2,
  values: () => Object.values(LoadingImage)
};
Object.freeze(LoadingImage);

class LoadingGlobal {
  constructor(show, hide) {
    if (typeof show !== 'function') {
      throw new Error('Invalid show param supplied to LoadingGlobal.ctor is invalid');
    }
    if (typeof hide !== 'function') {
      throw new Error('Invalid hide param supplied to LoadingGlobal.ctor is invalid');
    }

    this._show = show;
    this._hide = hide;
    this._showLoading = (delay, callback) => this._show(LoadingImage.Loading, delay, callback);
    this._showLogo = (delay, callback) => this._show(LoadingImage.Logo, delay, callback);
    this._showSave = (delay, callback) => this._show(LoadingImage.Save, delay, callback);
  }

  get show() { return this._show; };
  get hide() { return this._hide; };
  get showLoading() { return this._showLoading; };
  get showLogo() { return this._showLogo; };
  get showSave() { return this._showSave; };
}

export { LoadingGlobal, LoadingImage };