import { GMatrixSelectedItem } from '../../../common/models/generic-selected-item';
import { GMatrixGenericComment } from '../../../common/models/generic-comment';
import { Issue } from '../../../common/models/issue';
import { EditLock } from '../../../common/models/edit-lock';
import moment from 'moment';

class CorporateField {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to CorporateField.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to HubField.ctor'); }
      if (typeof obj.entityId !== 'number') { throw new Error('Invalid obj.entityId param supplied to HubField.ctor'); }
      if (typeof obj.fieldName !== 'string') { throw new Error('Invalid obj.fieldName param supplied to HubField.ctor'); }
      if (typeof obj.resourceStatus !== 'string') { throw new Error('Invalid obj.resourceStatus param supplied to HubField.ctor'); }
      if (typeof obj.resourceStatusId !== 'number') { throw new Error('Invalid obj.resourceStatusId param supplied to HubField.ctor'); }
      if (typeof obj.allowAdd !== 'boolean') { throw new Error('Invalid obj.allowAdd param supplied to HubField.ctor'); }
      if (typeof obj.allowRemove !== 'boolean') { throw new Error('Invalid obj.allowRemove param supplied to HubField.ctor'); }
      if (typeof obj.status !== 'number') { throw new Error('Invalid obj.status param supplied to HubField.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.entityId = obj === null ? 0 : obj.entityId;
    this.fieldName = obj === null ? '' : obj.fieldName;
    this.resourceStatus = obj === null ? '' : obj.resourceStatus;
    this.resourceStatusId = obj === null ? 0 : obj.resourceStatusId;
    this.allowAdd = obj === null ? true : obj.allowAdd;
    this.allowRemove = obj === null ? true : obj.allowRemove;
    this.status = obj === null ? 0 : obj.status;
  }
}

class CorporateLicence {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to CorporateLicence.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to CorporateLicence.ctor'); }
      if (typeof obj.entityId !== 'number') { throw new Error('Invalid obj.entityId param supplied to CorporateLicence.ctor'); }
      if (typeof obj.licenceNumber !== 'string') { throw new Error('Invalid obj.licenceNumber param supplied to CorporateLicence.ctor'); }
      if (typeof obj.licenceRound !== 'string') { throw new Error('Invalid obj.licenceRound param supplied to CorporateLicence.ctor'); }
      if (typeof obj.licenceType !== 'string') { throw new Error('Invalid obj.licenceType param supplied to CorporateLicence.ctor'); }
      if (typeof obj.originalLicenceType !== 'string') { throw new Error('Invalid obj.originalLicenceType param supplied to CorporateLicence.ctor'); }

      if (obj.startDate !== null && typeof obj.startDate !== 'string') { throw new Error('Invalid obj.startDate param supplied to CorporateLicence.ctor'); }
      if (obj.endDate !== null && typeof obj.endDate !== 'string') { throw new Error('Invalid obj.endDate param supplied to CorporateLicence.ctor'); }
      if (obj.segregationDate !== null && typeof obj.segregationDate !== 'string') { throw new Error('Invalid obj.segregationDate param supplied to CorporateLicence.ctor'); }

      if (typeof obj.allowAdd !== 'boolean') { throw new Error('Invalid obj.allowAdd param supplied to CorporateLicence.ctor'); }
      if (typeof obj.allowRemove !== 'boolean') { throw new Error('Invalid obj.allowRemove param supplied to CorporateLicence.ctor'); }
      if (typeof obj.status !== 'number') { throw new Error('Invalid obj.status param supplied to CorporateLicence.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.entityId = obj === null ? 0 : obj.entityId;
    this.licenceNumber = obj === null ? '' : obj.licenceNumber;
    this.licenceRound = obj === null ? 0 : obj.licenceRound;
    this.licenceType = obj === null ? 0 : obj.licenceType;
    this.originalLicenceType = obj === null ? 0 : obj.originalLicenceType;
    this.startDate = obj === null ? 0 : obj.startDate;
    this.endDate = obj === null ? 0 : obj.endDate;
    this.segregationDate = obj === null ? 0 : obj.segregationDate;

    this.allowAdd = obj === null ? true : obj.allowAdd;
    this.allowRemove = obj === null ? true : obj.allowRemove;
    this.status = obj === null ? 0 : obj.status;
  }
}

class CorporateList {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to CorporateList.ctor'); }
    if (obj !== null) {
      if (typeof obj.corporateId !== 'number') { throw new Error('Invalid obj.corporateId param supplied to CorporateList.ctor'); }
      if (typeof obj.corporateName !== 'string') { throw new Error('Invalid obj.corporateName param supplied to CorporateList.ctor'); }
      if (typeof obj.selected !== 'boolean') { throw new Error('Invalid obj.selected param supplied to CorporateList.ctor'); }
    }

    this.corporateId = obj === null ? 0 : obj.corporateId;
    this.corporateName = obj === null ? '' : obj.corporateName;
    this.selected = obj === null ? false : obj.selected;
  }
}

export class CorporateAttributes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to CorporateAttributes.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to CorporateAttributes.ctor'); }
      if (typeof obj.displayName !== 'string') { throw new Error('Invalid obj.displayName param supplied to CorporateAttributes.ctor'); }
      if (typeof obj.corporateName !== 'string') { throw new Error('Invalid obj.corporateName param supplied to CorporateAttributes.ctor'); }
      if (typeof obj.corporateShortName !== 'string') { throw new Error('Invalid obj.corporateShortName param supplied to CorporateAttributes.ctor'); }
      if (typeof obj.corporateCategoryId !== 'number') { throw new Error('Invalid obj.corporateCategoryId param supplied to CorporateAttributes.ctor'); }
      if (typeof obj.corporateCategory !== 'string') { throw new Error('Invalid obj.corporateCategory param supplied to CorporateAttributes.ctor'); }
      if (typeof obj.countryLookUpId !== 'number') { throw new Error('Invalid obj.countryLookUpId param supplied to CorporateAttributes.ctor'); }
      if (typeof obj.core !== 'boolean') { throw new Error('Invalid obj.core param supplied to CorporateAttributes.ctor'); }
      if (typeof obj.countryLookUpName !== 'string') { throw new Error('Invalid obj.countryLookUpName param supplied to CorporateAttributes.ctor'); }
      if (typeof obj.active !== 'boolean') { throw new Error('Invalid obj.active param supplied to CorporateAttributes.ctor'); }
      if (typeof obj.allowDeactivation !== 'boolean') { throw new Error('Invalid obj.allowDeactivation param supplied to CorporateAttributes.ctor'); }
      if (obj.corporateActionDate !== null && typeof obj.corporateActionDate !== 'string' && !moment.isMoment(obj.corporateActionDate) )
      {
        throw new Error('Invalid obj.corporateActionDate param supplied to CorporateAttributes.ctor');
      }
      if (obj.corporateActionComment !== null && typeof obj.corporateActionComment !== 'string') { throw new Error('Invalid obj.corporateActionComment param supplied to CorporateAttributes.ctor'); }

      if (!Array.isArray(obj.comments)) { throw new Error('Invalid obj.comments param supplied to CorporateAttributes.ctor'); }
      if (!Array.isArray(obj.corporateList)) { throw new Error('Invalid obj.corporateList param supplied to CorporateAttributes.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.displayName = obj === null ? '' : obj.displayName;
    this.corporateName = obj === null ? '' : obj.corporateName;
    this.corporateShortName = obj === null ? '' : obj.corporateShortName;
    this.newCorporateName = obj === null || obj.newCorporateName === null || obj.newCorporateName === undefined ? '' : obj.newCorporateName;
    this.corporateCategoryId = obj === null ? 0 : obj.corporateCategoryId;
    this.corporateCategory = obj === null ? '' : obj.corporateCategory;
    this.countryLookUpId = obj === null ? 0 : obj.countryLookUpId;
    this.core = obj === null ? true : obj.core;
    this.coreLookUpID = obj === null ? 1 : Number(obj.core);
    this.countryLookUpName = obj === null ? '' : obj.countryLookUpName;
    this.active = obj === null ? '' : obj.active;
    this.comments = (obj === null || obj.comments === null) ? [new GMatrixGenericComment(null)] : obj.comments.map(item => new GMatrixGenericComment(item));
    this.fields = (obj === null || obj.fields === null) ? [new CorporateField(null)] : obj.fields.map(item => new CorporateField(item));
    this.licences = (obj === null || obj.licences === null) ? [new CorporateLicence(null)] : obj.licences.map(item => new CorporateLicence(item));
    this.status = obj === null ? '' : obj.status;
    this.originalStatus = obj === null ? '' : obj.originalStatus;
    this.corporateDescription = obj === null ? '' : obj.corporateDescription;
    this.corporateDescriptionDate = obj === null ? '' : obj.corporateDescriptionDate;
    this.corporateList = (obj === null || obj.corporateList === null) ? [new CorporateList(null)] : obj.corporateList.map(item => new CorporateList(item));
    this.allowDeactivation = obj === null ? false : obj.allowDeactivation;

    const now = new Date();
    this.corporateActionDate = obj === null || obj.corporateActionDate === null ? moment(now).utc(true) : moment(obj.corporateActionDate);
    this.corporateActionComment = obj === null ? null : obj.corporateActionComment;
  }

}

export class CorporateResult {
  attributes = new CorporateAttributes(null);
  issues = [new Issue()];
  editLock = new EditLock();
  alert = '';

  constructor(obj) {
    obj && Object.assign(this, obj);
    this.attributes = obj === undefined ? new CorporateAttributes() : new CorporateAttributes(obj.attributes);
    this.issues = obj === undefined ? [new Issue()] : obj.issues.map(item => new Issue(item));
    this.editLock = obj === undefined ? new EditLock() : new EditLock(obj.editLock);
  }
}

export { Issue, EditLock, CorporateField, CorporateLicence };
