import { objectToEnum } from "../../common";
import { zoomLevels } from "../helpers/zoom-levels";
import { PolygonsModel } from "../models/polygons-model";
import { createPolygonsLayer } from "../layers/polygons-layer";
import {
  //WgeCorporateBlue,
  //WgeLightGrey,
  WgeDarkGrey,
  //WgeSectorsRed,
  WgeWildcatBlue,
  WgeAnalyticsGreen,
  //WgeAnalyticsGreen,
  //WgeRigLogixYellow,
  //WgeAtlasPurple,
  //WgeEnergentBlue,
  //WgeGlrGreen,
  //WgeFarmOutsOrange,
  //WgeRigEdgeBlue,
  //WgeRigOutlookMaroon,
  //WgeAdditional1,
  //WgeAdditional2,
  //WgeAdditional3,
  //WgeAdditional4,
  //WgeAdditional5,
  //WgeAdditional6,
  //WgeAdditional7,
  //WgeAdditional8,
  //WgeAdditional9,
  //WgeAdditional10
}
  from '../../../scss/wge-colors'

const searchResultType = objectToEnum({
  Block: 10,
  Field: 20,
  Well: 30,
  Hub: 40,
  Discovery: 50,
  Prospect: 60,
  Licence: 70,
  Corporate: 80,
  Wind: 90,
  Cluster: 100,
  Hydrogen: 110,
  CarbonCapture: 120,
  CarbonStorage: 130,
  CarbonStorageLicenceRound: 140,
  CarbonStorageLicence: 150,

}, false);

searchResultType.getColor = (type) => {
  switch (type) {
    case searchResultType.Block: return WgeDarkGrey;
    case searchResultType.Field: return WgeWildcatBlue;
    case searchResultType.Well: return WgeAnalyticsGreen;
    case searchResultType.Hub: return WgeAnalyticsGreen;
    case searchResultType.Discovery: return WgeAnalyticsGreen;
    case searchResultType.Prospect: return WgeAnalyticsGreen;
    case searchResultType.Licence: return WgeDarkGrey;
    case searchResultType.Corporate: return WgeAnalyticsGreen;
    case searchResultType.Corporate: return WgeAnalyticsGreen;
    case searchResultType.Wind: return WgeAnalyticsGreen;
    case searchResultType.Cluster: return WgeAnalyticsGreen;
    case searchResultType.Hydrogen: return WgeAnalyticsGreen;
    case searchResultType.CarbonCapture: return WgeAnalyticsGreen;
    case searchResultType.CarbonStorage: return WgeAnalyticsGreen;
    case searchResultType.CarbonStorageLicenceRound: return WgeAnalyticsGreen;
    case searchResultType.CarbonStorageLicence: return WgeAnalyticsGreen;
    default: throw new Error('Unsupported \'type\' param (' + type + ') supplied to \'searchResultType.getColor\'');
  }
};

searchResultType.getShapeModel = (type, displayName, geometries) => {
  const color = searchResultType.getColor(type);
  switch (type) {
    case searchResultType.Block:
    case searchResultType.Field:
    case searchResultType.Well:
    case searchResultType.Hub:
    case searchResultType.Discovery:
    case searchResultType.Prospect:
    case searchResultType.Wind:
    case searchResultType.Cluster:
    case searchResultType.Hydrogen:
    case searchResultType.CarbonCapture:
    case searchResultType.CarbonStorage:
    case searchResultType.CarbonStorageLicenceRound:
    case searchResultType.CarbonStorageLicence:
    case searchResultType.Licence: return new PolygonsModel(geometries, { color: color, fillColor: 'transparent' });
    case searchResultType.Corporate: return null;
    default: throw new Error('Unsupported \'type\' param (' + type + ') supplied to \'searchResultType.getModel\'');
  }
};

searchResultType.createLayer = (type, shapeModel) => {
  switch (type) {
    case searchResultType.Block:
    case searchResultType.Field:
    case searchResultType.Well:
    case searchResultType.Hub:
    case searchResultType.Discovery:
    case searchResultType.Prospect:
    case searchResultType.Wind:
    case searchResultType.Cluster:
    case searchResultType.Hydrogen:
    case searchResultType.CarbonCapture:
    case searchResultType.CarbonStorage:
    case searchResultType.CarbonStorageLicenceRound:
    case searchResultType.CarbonStorageLicence:
    case searchResultType.Licence: return createPolygonsLayer(shapeModel);
    case searchResultType.Corporate: return null;
    default: throw new Error('Unsupported \'type\' param (' + type + ') supplied to \'searchResultType.createLayer\'');
  }
};

searchResultType.defaultZoomLevel = (type) => {
  switch (type) {
    case searchResultType.Block: return zoomLevels._3km;
    case searchResultType.Field: return zoomLevels._3km;
    case searchResultType.Well: return zoomLevels._2km;
    case searchResultType.Hub: return zoomLevels._5km;
    case searchResultType.Discovery: return zoomLevels._2km;
    case searchResultType.Prospect: return zoomLevels._2km;
    case searchResultType.Licence: return zoomLevels._5km;
    case searchResultType.Corporate: return zoomLevels._5km;
    case searchResultType.Wind: return zoomLevels._2km;
    case searchResultType.Cluster: return zoomLevels._2km;
    case searchResultType.Hydrogen: return zoomLevels._2km;
    case searchResultType.CarbonCapture: return zoomLevels._2km;
    case searchResultType.CarbonStorage: return zoomLevels._2km;
    case searchResultType.CarbonStorageLicenceRound: return zoomLevels._5km;
    case searchResultType.CarbonStorageLicence: return zoomLevels._5km;
    default: throw new Error('Unsupported \'type\' param (' + type + ') supplied to \'searchResultType.createLayer\'');
  }
};

Object.freeze(searchResultType);

class SearchResultModel {
  constructor(obj) {
    if (typeof obj !== 'object') throw new Error('Invalid \'obj\' param supplied to \'SearchResultModel.ctor\'');
    if (searchResultType.isInvalid(obj.type)) throw new Error('Invalid \'obj.type\' supplied to \'SearchResultModel.ctor\'');
    if (typeof obj.id !== 'string') throw new Error('Invalid \'obj.id\' supplied to \'SearchResultModel.ctor\'');
    if (typeof obj.displayName !== 'string') throw new Error('Invalid \'obj.displayName\' supplied to \'SearchResultModel.ctor\'');
    if (!Array.isArray(obj.geometries)) throw new Error('Invalid \'obj.geometries\' supplied to \'SearchResultModel.ctor\'');

    this.type = obj.type;
    this.id = obj.id;
    this.displayName = obj.displayName;
    this.shapeModel = searchResultType.getShapeModel(this.type, this.displayName, obj.geometries);
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "SearchResultModel.FromArray"');
    }

    const result = array.map(obj => new SearchResultModel(obj));
    return result;
  }
}

class SearchResultGroupModel {
  constructor(obj) {
    if (typeof obj !== 'object') throw new Error('Invalid \'obj\' param supplied to \'SearchResultGroupModel.ctor\'');
    if (searchResultType.isInvalid(obj.type)) throw new Error('Invalid \'obj.type\' supplied to \'SearchResultModel.ctor\'');
    if (typeof obj.displayName !== 'string') throw new Error('Invalid \'obj.displayName\' supplied to \'SearchResultGroupModel.ctor\'');
    if (!Array.isArray(obj.results)) {
      throw new Error('Invalid \'obj.results\' supplied to \'SearchResultGroupModel.ctor\'');
    }

    this.type = obj.type;
    this.displayName = obj.displayName;
    this.searchResults = obj.results.map(x => new SearchResultModel(x));
  }

  static FromArray(array) {
    if (!Array.isArray(array)) {
      throw new Error('Invalid "array" param supplied to "SearchResultGroupModel.FromArray"');
    }

    const result = array.map(obj => new SearchResultGroupModel(obj));
    return result;
  }
}

export { searchResultType, SearchResultModel, SearchResultGroupModel };
