const EditGridsAction = {
  addStart: 0,
  deleteStart: 10,
  moveStart: 20,
  refresh: 30,
  moveEnd: 40,
  deleteEnd: 50,
  addEnd: 60,
  values: () => Object.values(EditGridsAction).filter(obj => typeof obj === 'number')
};
Object.freeze(EditGridsAction);

export { EditGridsAction };