import { objectToEnum } from './common';

// LENGTH
const feetPerMeter = 3.28084;

// TEMPARATURE
const celsiusPerFahrenheit = 0.55556;
const celsiusFahrenheitConstant = 32;
const psiPerKilopascal = 6.89476;

//LIQUID & GAS
const cubicFeetPerCubicMeter = 35.3147;
const boePerCubicMeter = 6.29;

const unitConversions = objectToEnum({
  feetToMeters: 0,
  metersToFeet: 1,

  celsiusToFahrenheit: 2,
  fahrenheitToCelsius: 3,

  psiToKilopascal: 4,
  kilopascalToPsi: 5,

  cubicFeetToCubicMeter: 6,
  cubicMeterToCubicFeet: 7,

  boeToCubicMeter: 8,
  cubicMeterToBOE: 9
}, false);

unitConversions.Convert = (unitConversion, value) => {
  switch (unitConversion) {
    case unitConversions.feetToMeters: return value / feetPerMeter;
    case unitConversions.metersToFeet: return value * feetPerMeter;

    case unitConversions.celsiusToFahrenheit: return (value / celsiusPerFahrenheit) + celsiusFahrenheitConstant;
    case unitConversions.fahrenheitToCelsius: return (value - celsiusFahrenheitConstant) * celsiusPerFahrenheit;

    case unitConversions.psiToKilopascal: return value * psiPerKilopascal;
    case unitConversions.kilopascalToPsi: return value / psiPerKilopascal;

    case unitConversions.cubicFeetToCubicMeter: return value / cubicFeetPerCubicMeter;
    case unitConversions.cubicMeterToCubicFeet: return value * cubicFeetPerCubicMeter;

    case unitConversions.boeToCubicMeter: return value / boePerCubicMeter;
    case unitConversions.cubicMeterToBOE: return value * boePerCubicMeter;

    default: throw new Error('Unsupported \'unitConversion\' param (' + unitConversion + ') supplied to \'unitConversions.Convert\'');
  }
};

Object.freeze(unitConversions);

export { unitConversions };

//export function feetToMeters(feet) {
//  return feet / feetPerMeter;
//}

//export function metersToFeet(meters) {
//  return meters * feetPerMeter;
//}