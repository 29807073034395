import moment from 'moment';
import AttributeStaticListComponent from '../attribute-list/attribute-static-list-component';
import { PropertyGridLargeStringValue, PropertyGridDateValue } from '../../../shared/components/property-grid/property-grid-table-component';
import React from 'react';
import PropTypes from 'prop-types';

import { GMatrixGenericComment } from '../../models/generic-comment';
import { MessageBoxGlobal } from '../../globals/globals';

const IDS = {
  DATE: 20,
  COMMENTTEXT: 30
};
Object.freeze(IDS);

const CommentsComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    comments,
    commentActions
  } = props;

  const onEdit = (id, value, item) => {
    switch (id) {
      case IDS.COMMENTTEXT: item.textValue = value.currentTarget.value; break;
      case IDS.DATE: item.commentDate = moment(value).format('YYYY-MM-DD[T]hh:mm:ss.sss'); break;
      default: throw new Error('Id ' + id + ' is not supported in CommentsComponent.onEdit');
    }
    commentActions.onEdit(item);
  };

  const items = comments.map(item => {
    return {
      label: moment(item.commentDate).format('DD/MM/YYYY'),
      data: item,
      sections: [{
        label: 'Comments',
        rows: [
          { label: 'Date', values: [new PropertyGridDateValue(IDS.DATE, item.commentDate, (value, id) => onEdit(id, value, item))] },
          { label: 'Comment', values: [new PropertyGridLargeStringValue(IDS.COMMENTTEXT, item.textValue, (value, id) => onEdit(id, value, item))] }
        ]
      }]
    }
  });

  const add = () => {
    let newItem = new GMatrixGenericComment(null);
    newItem.getNew();

    commentActions.onAdd(newItem);
  }


  return (
    <AttributeStaticListComponent
      editing={editing}
      messageBoxGlobal={messageBoxGlobal}
      items={items}
      itemDescription="comment"
      canAdd
      canDelete
      onAdd={add}
      onDelete={commentActions.onDelete}
      onSelected={commentActions.onSelected}
      onAddPosition="start"
      className="expanded"
    />
  );
}

CommentsComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  comments: PropTypes.arrayOf(PropTypes.instanceOf(GMatrixGenericComment)).isRequired,
  commentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired
};

export default CommentsComponent;
