import React from 'react';
import PropTypes from 'prop-types';

import './tab-component.scss';
import { browserIsMicrosoft } from '../../helpers/browser-detect';

class Tab {
  constructor(header, content) {
    //TODO: validate params

    this.header = header;
    this.content = content;
  }
}

const TabComponent = (props) => {
  let { align, children, onClick, selected } = props;
  if (align === undefined || align === null) align = 'top';
  const className = 'tab-component '
    + align
    + (browserIsMicrosoft() ? ' fixed-height' : '')
    + (selected ? ' selected' : ' unselected');

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};

TabComponent.propTypes = {
  align: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
};

export default TabComponent;
export { Tab };
