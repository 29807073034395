import React from 'react';
import PropTypes from 'prop-types';

import { ChartData } from '../../../../../shared/components/generic-chart/chart-data'
import GenericChart, { ChartType } from '../../../../../shared/components/generic-chart/generic-chart';
import { valueTypes } from '../../../../../shared/helpers/value-handler';

const ProductionComponent = (props) => {
  const { productionByField } = props;

  return (
    <GenericChart
      chartDataItems={productionByField}
      chartType={ChartType.Line}
      isXStacked={false}
      isYStacked={true}
      showYAxisLabel={true}
      yAxisLabel={valueTypes.UnitString(valueTypes.mboed)}
      showYAxisGridLines={true}
    />
  );
};

ProductionComponent.propTypes = {
  productionByField: PropTypes.instanceOf(ChartData).isRequired
};

export default ProductionComponent;