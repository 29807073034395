import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from "react-transition-group";

import './slider-panel-component.scss';
import { appendClassNames } from '../../helpers/common';

const SliderPanelComponent = (props) => {
  return (
    <div className={appendClassNames('slider-panel-component', props.className)}>
      <TransitionGroup component={null}>
        <CSSTransition key={props.childKey} timeout={props.timeout} classNames={props.animation}>
          {props.children}
        </CSSTransition>
      </TransitionGroup>
    </div >
  );
};

SliderPanelComponent.propTypes = {
  className: PropTypes.string,
  animation: PropTypes.oneOf(['slide', 'scale']).isRequired,
  childKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  timeout: PropTypes.number.isRequired
};

export default SliderPanelComponent;
