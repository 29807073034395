import React from 'react';
import PropTypes from 'prop-types';
import { doubleHelper } from './double-helper';

class PercentFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired
  };

  render() {    
    const percentValue = doubleHelper(this.props.value, this.props.decimalPlaces) + '%';
    return (
      <div className="progress" style={{ marginTop: '0px' }}>
        <div className="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: percentValue, color: 'black', backgroundColor: '#00dfffb5', textAlign: 'inherit', fontSize: '12px' }}>
          {percentValue}
        </div>
      </div>);
  }
}

PercentFormatter.propTypes = {
  decimalPlaces: PropTypes.number.isRequired
};

PercentFormatter.defaultProps = {
  decimalPlaces: 1
};

export default PercentFormatter;