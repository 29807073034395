import { WgeCorporateBlue, WgeLightGrey, WgeDarkGrey, WgeAtlasPurple} from './wge-colors';

const LightGrey = WgeLightGrey;
const DarkGrey = WgeDarkGrey;
const Highlight = WgeAtlasPurple;
const Toolbar = WgeCorporateBlue;
const MapShapeBorder = '#40FCFD';

export {
  LightGrey,
  DarkGrey,
  Highlight,
  Toolbar,
  MapShapeBorder
}