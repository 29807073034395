import React from 'react';
import './cashflow-chart.scss';
import { appendClassNames } from '../../../shared/helpers/common';
import cloneDeep from 'lodash/cloneDeep';
import Functions from './chart-functions';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
);

const CashFlowChart = (props) => {
  const { className, cashflowData, yTitle } = props;

  var entityData = cloneDeep(cashflowData);

  //round to 1 d.p.
  var entityDataLength = entityData.length;
  for (let val = 0; val < entityDataLength; val++) {
    entityData[val].preTaxCashFlowUsdMM = Functions.round(entityData[val].preTaxCashFlowUsdMM, 1);
    entityData[val].cumulativePreTaxCashflowUsdMM = Functions.round(entityData[val].cumulativePreTaxCashflowUsdMM, 1);
  }

  var chartData = {
    labels: entityData.map(e => e.year),
    datasets: [{
      label: "Pre-Tax Cumulative Cashflow ($mm)",
      fill: false,
      borderColor: '#4e82bb',
      yAxisID: 'B',
      data: entityData.map(e => e.cumulativePreTaxCashflowUsdMM),
      pointRadius: 0,
      type: 'line',
    }, {
      label: "Pre-Tax Cashflow ($mm)",
      backgroundColor: '#1e90ff',
      borderColor: '#1e90ff',
      yAxisID: 'A',
      data: entityData.map(e => e.preTaxCashFlowUsdMM),
      pointRadius: 0
    }]
  };

  var chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      A: {
        type: 'linear',
        position: 'left',
        title: {
          text: yTitle,
          display: true
        }
      },
      B: {
        type: 'linear',
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        title: {
          text: yTitle,
          display: true
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      },
    }
  };

  let contentDiv = null;
  contentDiv = (<Bar data={chartData} options={chartOptions} />);

  return (
    <div className={appendClassNames('hub-chart-component', className)}>
      {contentDiv}
    </div>
  );

};

export default CashFlowChart;
