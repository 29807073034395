import React from 'react';
import PropTypes from 'prop-types';

import { ResourceAnnual } from '../../models/production-result';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import ResourceAnnualsGridComponent from '../../../../common/components/grid/resource-annuals-grid-component';

const ResourceAnnualsComponent = (props) => {
  var preventScrollToTop = props.preventScrollToTop;

  return <ResourceAnnualsGridComponent
    editing={props.editing}
    readOnly={false}
    messageBoxGlobal={props.messageBoxGlobal}
    resourceAnnuals={props.resourceAnnuals}
    top={props.top}
    preventScrollToTop={preventScrollToTop}
    id={props.id}
    canButtonsEnable={props.canButtonsEnable}
    startYear={props.startYear}
    endYear={props.endYear}
    editYear={props.editYear}
    enableDeclineCurve={props.enableDeclineCurve}
    handleCalculatedValues={props.handleCalculatedValues}
    activeComponent={props.activeComponent}
  />;
};

ResourceAnnualsComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  editing: PropTypes.bool.isRequired,
  resourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  id: PropTypes.number,
  canButtonsEnable: PropTypes.func.isRequired,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  handleCalculatedValues: PropTypes.func.isRequired
};

export default ResourceAnnualsComponent;