export class HubEmissionAnnual {
  hubId = 0;
  hubEmissionAnnualId = 0;
  year = 0;
  production= 0;
  cO2TotalTonnes = 0;
  ch4TotalTonnes = 0;
  fuelGasConsumptionSM3 = 0;
  dieselConsumptionLitres = 0;
  flaringSM3 = 0;
  ventingTotalSM3 = 0;
  ventingCO2SM3 = 0;
  cO2EtsAllowanceTonnes = 0;
  isAutoGenerated = false;
  cO2EmissionsIntensity = 0;
  cH4EmissionsIntensity = 0;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

}
