import React from 'react';
import PropTypes from 'prop-types';

import { HubEmissionAnnual } from '../../models/hub-emission-annual';
import { valueTypes } from '../../../shared/helpers/value-handler';

import AGGridComponent, {
  COLUMN_SMALL,
  DoubleFormatter,
  ReadOnlyNumberFormatter,
  ReadOnlyDoubleFormatter,
  GRIDTYPES
} from './ag-grid-component';
import { MessageBoxGlobal } from '../../../common/globals/globals';

const OpexTypes = [
  { id: 1, title: 'Calculated' },
  { id: 2, title: 'Manual' },
  { id: 3, title: 'Percent' }
];
Object.freeze(OpexTypes);

const HubEmissionsAnnualsGridComponent = (props) => {
  const { hubEmissionAnnuals, /*editing,*/ readOnly, rowAdded, top, startYear, endYear, editYear, enableDeclineCurve, handleCalculatedValues } = props;

  const setCellClass = (params) => {
    return (params.data.isAutoGenerated) ? 'ag-grey-out-class' : 'ag-normal-class';
  }

  const zeroDPRenderer = (params) => {
    const value = readOnly ? <ReadOnlyDoubleFormatter value={params.value} decimalPlaces={0} /> : <DoubleFormatter value={params.value} decimalPlaces={0} />
    return value;
  }

  let editing = false; //this is to be replaced with editing in props once editing is allowed - currently not

  const columnDefs = [];
  columnDefs.push({ headerName: "Year", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "year", sortable: false, filter: 'agNumberColumnFilter', filterParams: { newRowsAction: 'keep' }, editable: false, width: COLUMN_SMALL, cellRenderer: ReadOnlyNumberFormatter, pinned: 'left', type: 'numeric' });
  columnDefs.push({ headerName: "Production (mboed)", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "production", sortable: true, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: readOnly ? ReadOnlyDoubleFormatter : DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Total " + valueTypes.UnitString(valueTypes.CO2) + " Emissions (t" + valueTypes.UnitString(valueTypes.CO2) + ")", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "cO2TotalTonnes", sortable: true, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: zeroDPRenderer, type: 'numeric' });
  columnDefs.push({ headerName: "Total " + valueTypes.UnitString(valueTypes.CH4) + " Emissions (t" + valueTypes.UnitString(valueTypes.CH4) + ")", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "cH4TotalTonnes", sortable: true, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: zeroDPRenderer, type: 'numeric' });
  columnDefs.push({ headerName: valueTypes.UnitString(valueTypes.CO2) + " Emissions Intensity (kg" + valueTypes.UnitString(valueTypes.CO2) + "/boe)", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "cO2EmissionsIntensity", sortable: true, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: readOnly ? ReadOnlyDoubleFormatter : DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: valueTypes.UnitString(valueTypes.CH4) + " Emissions Intensity (kg" + valueTypes.UnitString(valueTypes.CH4) + "/boe)", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "cH4EmissionsIntensity", sortable: true, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: readOnly ? ReadOnlyDoubleFormatter : DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: "Fuel Gas Consumption (" + valueTypes.UnitString(valueTypes.SM3) + ")", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "fuelGasConsumptionSM3", sortable: true, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: zeroDPRenderer, type: 'numeric' });
  columnDefs.push({ headerName: "Diesel Consumption (L)", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "dieselConsumptionLitres", sortable: true, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: zeroDPRenderer, type: 'numeric' });
  columnDefs.push({ headerName: "Flaring (" + valueTypes.UnitString(valueTypes.SM3) + ")", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "flaringSM3", sortable: true, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: zeroDPRenderer, type: 'numeric' });
  columnDefs.push({ headerName: "Venting Total (" + valueTypes.UnitString(valueTypes.SM3) + ")", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "ventingTotalSM3", sortable: true, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: zeroDPRenderer, type: 'numeric' });
  columnDefs.push({ headerName: "Venting " + valueTypes.UnitString(valueTypes.CO2) + " (" + valueTypes.UnitString(valueTypes.SM3) + ")", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "ventingCO2SM3", sortable: true, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: zeroDPRenderer, type: 'numeric' });
  columnDefs.push({ headerName: "ETS Allowance (t)", cellClass: setCellClass, suppressHeaderMenuButton: true, field: "cO2EtsAllowanceTonnes", sortable: true, filter: false, editable: editing, width: COLUMN_SMALL, cellRenderer: zeroDPRenderer, type: 'numeric' });

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {}
  };

  const numColumns = ["noneyet"];
  const numPositiveColumns = ["noneyet"];
  const numPercentColumns = ["noneyet"];
  const selectColumns = ["noneyet"];

  return (
    <AGGridComponent
      messageBoxGlobal={props.messageBoxGlobal}
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      gridData={hubEmissionAnnuals}
      rowsCount={hubEmissionAnnuals.length}
      editing={editing}
      rowAdded={rowAdded}
      top={top}
      allowPaste
      id={props.id}
      numColumns={numColumns}
      numPercentColumns={numPercentColumns}
      numPositiveColumns={numPositiveColumns}
      selectColumns={selectColumns}
      canButtonsEnable={props.canButtonsEnable}
      startYear={startYear}
      endYear={endYear}
      gridType={GRIDTYPES.HUBEMISSIONS} 
      editYear={editYear}
      enableDeclineCurve={enableDeclineCurve}
      handleCalculatedValues={handleCalculatedValues}
      activeComponent={props.activeComponent}
    />
  );
};

HubEmissionsAnnualsGridComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  editing: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  hubEmissionAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(HubEmissionAnnual)).isRequired,
  id: PropTypes.number,
  canButtonsEnable: PropTypes.func.isRequired,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  handleCalculatedValues: PropTypes.func.isRequired
};

HubEmissionsAnnualsGridComponent.defaultProps = {
  readOnly: false,
  handleCalculatedValues: () => { }
};

export default HubEmissionsAnnualsGridComponent;
export { HubEmissionAnnual };
