import moment from 'moment';

class ResourceText {
  id = 0;
  entityId = 0;
  textCategoryId = 0;
  textValue = '';
  dateAdded = moment().format('YYYY-MM-DD 00:00:00.000');
  dateAmended = this.dateAdded;
  allowAdd = false;
  allowRemove = false;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

export { ResourceText };
