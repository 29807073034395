import React, { Component } from 'react';
import { MsalConfig } from './msal-config';
import { UserAgentApplication } from 'msal';

import LoginComponent from '../components/login-component';

export const msalAuth = new UserAgentApplication({
  auth: MsalConfig
});

export function WithAuth(HocComponent) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isAuthenticated: false,
        user: {},
        renewIframe: false,
        hasError: false,
        errorMessage: null
      };
    }

    async componentDidMount() { //NEED TO RETURN TO THIS PROMPTLY IN CASE IT STOPS WORKING HENCE PREVENTING LOGIN!!
      msalAuth.handleRedirectCallback(() => {
        let userAccount = msalAuth.getAccount();

        this.setState({
          isAuthenticated: true,
          user: userAccount
        });
      }, (authErr, accountState) => {  // on fail
        console.log(authErr);

        this.setState({
          hasError: true,
          errorMessage: authErr.errorMessage
        });
      });

      if (msalAuth.isCallback(window.location.hash)) {
        this.setState({
          auth: {
            renewIframe: true
          }
        });
        return;
      }

      let userAccount = msalAuth.getAccount();
      if (!userAccount) {
        //msalAuth.loginRedirect({});  //NB - put this back in of you want to bypass the welcome page and have to press the login button!
        return;
      } else {
        this.setState({
          isAuthenticated: true,
          user: userAccount
        });
      }
    }

    onSignIn() {
      msalAuth.loginRedirect({});
    }

    onSignOut() {
      msalAuth.logout();
    }

    render() {
      if (this.state.renewIframe) {
        return <div>hidden renew iframe - not visible</div>;
      }

      if (this.state.isAuthenticated) {
        return <HocComponent auth={this.state} onSignIn={() => this.onSignIn()} onSignOut={() => this.onSignOut()} {...this.props} />;
      }
      else if (!this.state.isAuthenticated) {
        return <LoginComponent login={() => this.onSignIn()} />
      }

      if (this.state.hasError) {
        return <div>{this.state.errorMessage}</div>;
      }

      return <div>Login in progress...</div>;
    }
  };
}
