import React from 'react';
import PropTypes from 'prop-types';

import { ResourceSnapshotHistory } from '../../../../models/production-result';
import { MessageBoxGlobal } from '../../../../../../common/globals/globals';
import AGGridComponent, { COLUMN_SMALL, COLUMN_MEDIUM, LiquidFormatter, GasFormatter, DoubleFormatter, GRIDTYPES, ReadOnlyGeneralFormatter } from '../../../../../../common/components/grid/ag-grid-component';
import { valueTypes } from '../../../../../../shared/helpers/value-handler';

const SnapshotHistoryComponent = (props) => {
  const { resourceSnapshotHistory, messageBoxGlobal, onSnapshotSelectionChanged, resourceSnapshotId, resourceId } = props;
  const columnDefs = [];

  columnDefs.push({ headerName: 'Snapshot Date', suppressHeaderMenuButton: true, field: 'snapshotDate', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, cellRenderer: ReadOnlyGeneralFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Total Recoverable Liquid (' + valueTypes.UnitString(valueTypes.mmb) + ')', suppressHeaderMenuButton: true, field: 'initialLiquid', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: LiquidFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Remaining Liquid (' + valueTypes.UnitString(valueTypes.mmb) + ')', suppressHeaderMenuButton: true, field: 'remainingLiquid', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: LiquidFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Total Recoverable Gas Reserves (' + valueTypes.UnitString(valueTypes.bcf) + ')', suppressHeaderMenuButton: true, field: 'initialGas', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, cellRenderer: GasFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Remaining Gas (' + valueTypes.UnitString(valueTypes.bcf) + ')', suppressHeaderMenuButton: true, field: 'remainingGas', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: GasFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'Full Field NPV10', suppressHeaderMenuButton: true, field: 'fullFieldNPV10', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, type: 'numeric'});
  columnDefs.push({ headerName: 'Remaining NPV10', suppressHeaderMenuButton: true, field: 'remainingNPV10', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRenderer: DoubleFormatter, type: 'numeric' });
  columnDefs.push({ headerName: 'COP Year', suppressHeaderMenuButton: true, field: 'copYear', sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'numeric'});

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {}
  };

  const selectedIndex = resourceSnapshotId === 0 ? 0 : resourceSnapshotHistory.findIndex(obj => obj.resourceSnapshotId === resourceSnapshotId);

  return (
    <AGGridComponent
      messageBoxGlobal={messageBoxGlobal}
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      gridData={resourceSnapshotHistory}
      rowsCount={resourceSnapshotHistory.length}
      editing={false}
      allowPaste={false}
      gridType={GRIDTYPES.SNAPSHOTHISTORY}
      rowSelectionEnabled={true}
      rowSelectChanged={onSnapshotSelectionChanged}
      selectedIndex={selectedIndex}
      id={resourceId}
    />
  );
};

SnapshotHistoryComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  resourceSnapshotHistory: PropTypes.arrayOf(PropTypes.instanceOf(ResourceSnapshotHistory)).isRequired,
  onSnapshotSelectionChanged: PropTypes.func.isRequired,
  resourceSnapshotId: PropTypes.number.isRequired
};

export default SnapshotHistoryComponent;