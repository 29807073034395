import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Label } from 'reactstrap';
import { EditGridsAction } from '../../../..//views/production/components/top-panel/edit-grids-action';
import { ProductionUpdateType } from '../../../../views/production/models/production-update-type';
import { ResourceAnnual, HubAnnual } from '../../../../views/production/models/production-result';
import './change-years-component.scss';

class ChangeYearsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { years: this.getInitialYears() };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.getInitialYears = this.getInitialYears.bind(this);
    this.getMax = this.getMax.bind(this);
  }

  getInitialYears = () => {
    var yearStartValue = 1; //validation????  or should the initial value of 1 be allowed as button visibility is already validated!
    this.props.onChangeYears(yearStartValue);
    return yearStartValue;
  }


  getMax = () => {
    const currentResourceAnnuals = this.props.resourceAnnuals.filter(r => r.resourceId === this.props.currentResourceID && r.isAutoAbex === false && r.hasResourceAnnual === true);
    var noResourceYears = currentResourceAnnuals.length > 0 ? currentResourceAnnuals.length : 0;
    var noHubYears = this.props.hubAnnuals.length > 0 ? this.props.hubAnnuals.length : 0;

    var minResourceStartYear = currentResourceAnnuals.length > 0 ? currentResourceAnnuals[0].year : 0;
    var minHubStartYear = this.props.hubAnnuals.length > 0 ? this.props.hubAnnuals[0].year : 0;

    var maxYears = 50;

    if (this.props.updateType === ProductionUpdateType.Hub) {
      if (this.props.action === EditGridsAction.deleteStart || this.props.action === EditGridsAction.deleteEnd) {
        maxYears = noHubYears - 1;
      }
    }
    else if (this.props.updateType === ProductionUpdateType.Resource) {
      if (this.props.action === EditGridsAction.deleteStart || this.props.action === EditGridsAction.deleteEnd) {
        maxYears = noResourceYears - 1;
      }
      if (this.props.action === EditGridsAction.addStart) {
        //field cannot have a start year earlier than its hub
        maxYears = minResourceStartYear - minHubStartYear;
      }
    }

    return maxYears;
  }

  handleUpdate(e) {
    var numYears = Number(e.target.value);
    this.setState({ years: numYears });

    this.props.onChangeYears(numYears);
  }

  render() {
    const minYears = 1;
    var yearVal = this.state.years;

    return (
      <Container className="change-years-component" fluid style={{ paddingLeft: 0, paddingRight: 0, overflowX: "hidden" }}>
        <Row className="mt-3 pl-3">
          <Col xs="2">
            <Label for="cbYear">No. of Years</Label>
          </Col>
          <Col xs="2">
            <input type="number" className="years" name="years" min={minYears} max={this.getMax()} value={yearVal} onChange={this.handleUpdate} step="any" />
          </Col>
        </Row>
      </Container>
    );
  }
}

ChangeYearsComponent.propTypes = {
  resourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  hubAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(HubAnnual)).isRequired,
  action: PropTypes.number.isRequired,
  updateType: PropTypes.number.isRequired,
  currentResourceID: PropTypes.number.isRequired,
  handleGridAction: PropTypes.func.isRequired,
  onChangeYears: PropTypes.func.isRequired
};

export default ChangeYearsComponent;
