import React from 'react';
import './snapshot-annual-chart.scss';
import { appendClassNames } from '../../../shared/helpers/common';
import cloneDeep from 'lodash/cloneDeep';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
);

const SnapshotAnnualChart = (props) => {
  const { className, resourceData, chartType, startYear, endYear, xTitle, yTitle } = props;

  var entityData = cloneDeep(resourceData);

  entityData = entityData.filter(r => r.year >= startYear && r.year <= endYear);

  entityData.sort((a, b) => a.year - b.year);

  var chartData = {
    labels: entityData.map(e => e.year),
    datasets: [{
      label: "Snapshot Liquid",
      borderColor: 'lightgreen',
      backgroundColor: 'lightgreen',
      data: entityData.map(e => e.snapshotLiquidMbod),
      pointRadius: 0,
    }, {
      label: "Snapshot Gas",
      borderColor: 'darkred',
      backgroundColor: 'darkred',
      data: entityData.map(e => e.snapshotGasMmscfd / 6),
      pointRadius: 0,
    }, {
      label: "Snapshot Total",
      borderColor: 'saddlebrown',
      backgroundColor: 'saddlebrown',
      data: entityData.map(e => e.snapshotLiquidMbod + (e.snapshotGasMmscfd / 6)),
      pointRadius: 0,
    }, {
      label: "Liquid",
      borderColor: 'green',
      backgroundColor: 'green',
      data: entityData.map(e => e.liquidMbod),
      pointRadius: 0,
    }, {
      label: "Gas",
      borderColor: 'red',
      backgroundColor: 'red',
      data: entityData.map(e => e.gasMboed),
      pointRadius: 0,
    }, {
      label: "Total",
      borderColor: 'brown',
      backgroundColor: 'brown',
      data: entityData.map(e => e.liquidMbod + e.gasMboed),
      pointRadius: 0,
    }]
  };

  var chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        position: 'left',
        type: 'linear',
        title: {
          text: yTitle,
          display: true
        },
      },
      x: {
        ticks: {
          minRotation: 45,
          autoSkip: true
        },
        title: {
          text: xTitle,
          display: true
        },
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      }
    }
  };

  let contentDiv = null;
  if (chartType === "Line") {
    contentDiv = (<Line data={chartData} options={chartOptions} />);
  }
  else if (chartType === "Bar") { contentDiv = (<Bar data={chartData} options={chartOptions} />); }

  return (
    <div className={appendClassNames('snapshot-annual-chart-component', className)}>
      {contentDiv}
    </div>
  );

};

export default SnapshotAnnualChart;