import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './related-item-component.scss';
import { ViewGlobal, SearchGlobal, Views } from '../../globals/globals';
import { apiGet } from '../../helpers/api-helpers';
import { RelatedItem } from '../../models/related-item';
import { AddRelatedItem } from '../../models/related-item';
import { GMATRIX_ENTITY } from '../../models/gmatrix-entity';

export default class RelatedItemDropdownComponent extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.getRelatedItems = this.getRelatedItems.bind(this);

    this.state = {
      relatedItems: [],
      addRelatedItems: [],
      activeTab: 0,
      dropDownOpen: false
    };

    this.navItemList = [];
    this.tabPaneList = [];

    this.tabbing = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.tabbing = false;
      this.setState({ dropDownOpen: false });
    }
  }

  getRelatedItems() {
    if (!this.state.dropDownOpen && !this.tabbing) {
      const { messageBoxGlobal, loadingGlobal } = this.props;
      const onSuccess = (result) => {
        var relatedItems = result.relatedItemList.map(obj => new RelatedItem(obj));
        var addRelatedItems = result.canAddRelatedItemsList.map(obj => new AddRelatedItem(obj));
        var activeTab = 0;

        relatedItems.forEach(ri => {
          if (activeTab === 0 && Object.keys(ri).length > 0) {
            activeTab = ri.id + ri.viewKey;
          }
        });

        this.setState({ relatedItems: relatedItems, addRelatedItems: addRelatedItems, activeTab: activeTab, dropDownOpen: !this.state.dropDownOpen });
      };
      apiGet('api/app/relatedItems?id=' + this.props.currentId + '&gmatrixEntity=' + this.props.currentView, loadingGlobal.show, loadingGlobal.hide, messageBoxGlobal, onSuccess, undefined);
    }
    else if (this.tabbing) {
      this.tabbing = false;
    }
    else if (this.state.dropDownOpen && !this.tabbing) {
      this.setState({ dropDownOpen: !this.state.dropDownOpen });
    }

  }

  toggle(tab) {
    this.tabbing = true;
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  goToRelatedItem = (entity, relatedItemId, displayName) => {
    var view = GMATRIX_ENTITY.findView(entity);

    this.props.viewGlobal.setAndId(view, relatedItemId, displayName, () => this.props.searchGlobal.search(displayName, false));
  }

  addRelatedItem = (viewId) => {
    console.log(' add related item');
    console.log(' add related item current view' + this.props.currentView);
    console.log(' add related item id ' + this.props.currentId);
    console.log(' add related item viewid ' + viewId);
    //the current view we are in is this.props.currentView
    //the id of what we are in is this.props.currentId - currently passing the resource Id from the production screen? do we need hub id? (can always get it from resource id)
    //Goto the the 'add item' section of wherever we want to go to e.g. Well and pass the relevant id e.g. resource ID - dont know how to do this yet

    //WHAT DO WE DO HERE - WHERE DO WE GO AND WHAT INFO NEEDS PASSING WITH IT
  }

  createMenu = (relatedItems, addRelatedItems) => {
    this.navItemList = [];
    this.tabPaneList = [];

    relatedItems.forEach((r) => {
      var view = GMATRIX_ENTITY.findView(r.id);
      if (view !== null && view !== undefined) {

        let navItem =
          <NavItem key={"nav_" + r.id + "_" + r.viewKey}>
            <NavLink
              className={classnames({ active: this.state.activeTab === r.id + r.viewKey })}
              onClick={() => { this.toggle(r.id + r.viewKey); }}
            >
              {r.displayName}
            </NavLink>
          </NavItem>

        this.navItemList.push(navItem);

        let dropDownItemList = [];

        var addRelatedItem = addRelatedItems.find(x => x.id === r.id);
        if (addRelatedItem !== undefined && addRelatedItem !== null) {
          if (addRelatedItem.canAdd) {
            let dropDownItem =
              <DropdownItem className="btn related-button" key={"ri_" + r.id} onClick={() => { this.addRelatedItem(r.id) }} title="">
                {'Add Related ' + r.view}
              </DropdownItem>

            dropDownItemList.push(dropDownItem);
          }
        }

        let dropDownItemNoItems =
          <span key={"dd_" + r.id} className="no-related-items">No related Items</span>

        if (r.items.length === 0) dropDownItemList.push(dropDownItemNoItems);
        r.items.forEach((i) => {
          if (i.id !== -1) {
            let dropDownItem =
              <DropdownItem key={"dd_" + r.id + "_" + i.id} onClick={() => { this.goToRelatedItem(r.id, i.id, i.name) }} title="">
                {i.name}
              </DropdownItem>

            dropDownItemList.push(dropDownItem);
          }
          else dropDownItemList.push(dropDownItemNoItems);
        });

        let tabPanelItem =
          <TabPane key={"tab_" + r.id + "_" + r.viewKey} tabId={r.id + r.viewKey}>
            {dropDownItemList}
          </TabPane>

        this.tabPaneList.push(tabPanelItem);
      }

    });
  }

  render() {
    if (this.state.relatedItems.length !== 0)
      this.createMenu(this.state.relatedItems, this.state.addRelatedItems);

    const className = this.props.editing ? "shortcuts-button-related disabled" : "shortcuts-button-related";

    var dropDownNavContent = null;
    var dropDownTabContent = null;

    if (this.state.relatedItems.length > 0) {
      dropDownNavContent =
        <Nav tabs>
          {this.navItemList}
        </Nav>
      dropDownTabContent =
        <TabContent activeTab={this.state.activeTab}>
          {this.tabPaneList}
        </TabContent>
    }
    else {
      dropDownNavContent = <span className="no-related-items">No related Items</span>
      dropDownTabContent = null
    }

    return (
      <UncontrolledDropdown isOpen={this.state.dropDownOpen} title="Open Related Items" className={className} size="sm" disabled={this.props.editing} onClick={() => { this.getRelatedItems() }}>
        <DropdownToggle caret disabled={this.props.editing} ><span></span></DropdownToggle>
        <DropdownMenu className="dropdown-menu-left custom-dropdown-menu-right" style={{ position: 'fixed !important' }}>
          {dropDownNavContent}
          {dropDownTabContent}
        </DropdownMenu>
      </UncontrolledDropdown>

    );
  }

};

RelatedItemDropdownComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  viewGlobal: PropTypes.instanceOf(ViewGlobal).isRequired,
  searchGlobal: PropTypes.instanceOf(SearchGlobal).isRequired,
  //currentView: PropTypes.oneOf(Views.values().map(str => { return Number(str); })).isRequired,
  currentId: PropTypes.number.isRequired
};

RelatedItemDropdownComponent.defaultProps = {
};
