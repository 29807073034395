import { RECORD_STATUS } from './record-status';

class Ownership {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Ownership.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.blockOwnershipId param supplied to Ownership.ctor'); }
      if (typeof obj.entityId !== 'number') { throw new Error('Invalid obj.blockId param supplied to Ownership.ctor'); }
      if (typeof obj.corporateId !== 'number') { throw new Error('Invalid obj.corporateId param supplied to Ownership.ctor'); }
      if (obj.blockNo !== null && typeof obj.blockNo !== 'string') { throw new Error('Invalid obj.blockNo param supplied to Ownership.ctor'); }
      if (typeof obj.corporateName !== 'string') { throw new Error('Invalid obj.corporateName param supplied to Ownership.ctor'); }

      if (typeof obj.equity !== 'number') { throw new Error('Invalid obj.equity param supplied to Ownership.ctor'); }
      if (typeof obj.isOperator !== 'boolean') { throw new Error('Invalid obj.isOperator param supplied to Ownership.ctor'); }
      if (typeof obj.isCore !== 'boolean') { throw new Error('Invalid obj.isCore param supplied to Ownership.ctor'); }
      if (typeof obj.isAssumed !== 'boolean') { throw new Error('Invalid obj.isAssumed param supplied to Ownership.ctor'); }

      if (obj.operatorOnly !== undefined && obj.operatorOnly !== null && typeof obj.operatorOnly !== 'boolean') { throw new Error('Invalid obj.operatorOnly param supplied to Ownership.ctor'); }
      if (obj.tierId !== undefined && obj.tierId !== null && typeof obj.tierId !== 'number') { throw new Error('Invalid obj.tierId param supplied to Ownership.ctor'); }

      if (obj.commercialComment !== null && typeof obj.commercialComment !== 'string') { throw new Error('Invalid obj.commercialComment param supplied to Ownership.ctor'); }
      if (obj.adminComment !== null && typeof obj.adminComment !== 'string') { throw new Error('Invalid obj.adminComment param supplied to Ownership.ctor'); }

      if (obj.selected !== null && typeof obj.selected !== 'boolean') { throw new Error('Invalid obj.selected param supplied to Ownership.ctor'); }
      if (typeof obj.allowAdd !== 'boolean') { throw new Error('Invalid obj.allowAdd param supplied to Ownership.ctor'); }
      if (typeof obj.allowRemove !== 'boolean') { throw new Error('Invalid obj.allowRemove param supplied to Ownership.ctor'); }
      if (typeof obj.status !== 'number') { throw new Error('Invalid obj.status param supplied to Ownership.ctor'); }
    }

    this.id = obj === null ? -1 : obj.id;
    this.entityId = obj === null ? -1 : obj.entityId;
    this.corporateId = obj === null ? -1 : obj.corporateId;
    this.blockNo = obj === null ? '' : obj.blockNo;
    this.corporateName = obj === null ? '' : obj.corporateName;
    this.equity = obj === null ? -1 : obj.equity;

    this.isOperator = obj === null ? false : obj.isOperator;
    this.isCore = obj === null ? false : obj.isCore;
    this.isAssumed = obj === null ? false : obj.isAssumed;

    this.operatorOnly = obj === null || obj.operatorOnly === undefined ? false : obj.operatorOnly;
    this.tierId = obj === null || obj.tierId === undefined ? -1 : obj.tierId;

    this.commercialComment = obj === null || obj.commercialComment === null ? '' : obj.commercialComment;
    this.adminComment = obj === null || obj.adminComment === null ? '' : obj.adminComment;

    this.selected = obj === null ? false : obj.selected;
    this.allowAdd = obj === null ? true : obj.allowAdd;
    this.allowRemove = obj === null ? true : obj.allowRemove;

    this.status = obj === null ? '' : obj.status;
  }

  getNew() {
    this.corporateId = -1;
    this.blockNo = '';
    this.corporateName = '-- New Corporate --';
    this.equity = 0;
    this.isOperator = false;
    this.isCore = false;
    this.isAssumed = false;
    this.commercialComment = '';
    this.adminComment = '';
    this.allowAdd = true;
    this.allowRemove = true;
    this.status = RECORD_STATUS.added;

    this.operatorOnly = false;
    this.tierId = -1;
    this.selected = true;
  }
}

export default Ownership;
