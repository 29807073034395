import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Label, Input, Button } from 'reactstrap';
import { CORPORATE_EDIT_TYPES } from '../corporate-view';
import DateTextGenericComponent from '../../../common/components/date-text-generic/date-text-generic-component';
import moment from 'moment';
import './corporate-edit-component.scss';

const CorporateEditComponent = (props) => {
  const { corporateEditType, onClose } = props;

  let editMessage = null;
  let additionalText = null;

  switch (corporateEditType) {
    case CORPORATE_EDIT_TYPES.MERGE:
      editMessage = 'Please select the corporates to merge by ticking the checkboxes and press OK';
      additionalText = ' has been merged with ';
      break;
    case CORPORATE_EDIT_TYPES.CORRECT:
      editMessage = 'Please correct Corporate name and press OK';
      additionalText = ' has been renamed to ';
      break;
    case CORPORATE_EDIT_TYPES.REBRAND:
      editMessage = 'Please enter new Corporate Name';
      additionalText = ' has been rebranded to ';
      break;
    case CORPORATE_EDIT_TYPES.ACQUISITION:
      editMessage = 'Please select Corporates to acquire';
      additionalText = ' has acquired ';
      break;
  }

  let corporates = props.corporates;

  let initialMessage = corporateEditType === CORPORATE_EDIT_TYPES.ACTIVATE ? props.corporateName + ' has been Activated' : corporateEditType === CORPORATE_EDIT_TYPES.DEACTIVATE ? props.corporateName + ' has been De-activated' : null; 

  const [corporateName, setCorporateName] = useState(props.corporateName);
  const [corporateActionMessage, setCorporateActionMessage] = useState(initialMessage);
  const [corporateActionDate, setCorporateActionDate] = useState(props.corporateActionDate);
  let [selectionList, setSelectedItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  let createNewCorporate = true;

  const updateCorporateComment = (_corporateName) => {
    let newCorporateName = _corporateName === '' ? '[Enter Corporate Name]' : _corporateName;

    if (corporateEditType === CORPORATE_EDIT_TYPES.CORRECT || corporateEditType === CORPORATE_EDIT_TYPES.REBRAND) {
      let corporateMessage = props.corporateName + additionalText + newCorporateName;
      setCorporateActionMessage(corporateMessage);
    }
    else if (selectionList.length > 0) {
      let corporateMessage = newCorporateName + additionalText;
      let firstCorporate = true;
      for (var k = 0; k < corporates.length; k++) {
        if (selectionList.includes(corporates[k].corporateId)) {
          if (!firstCorporate) corporateMessage = corporateMessage + ' and ';
          corporateMessage = corporateMessage + corporates[k].corporateName;
          firstCorporate = false;
        }

        setCorporateActionMessage(corporateMessage);
      }
    }
    else {
      setCorporateActionMessage(null);
    }
  }

  const onChangeCorporateName = (event) => {
    setErrorMessage(null);
    setCorporateName(event.target.value);
    updateCorporateComment(event.target.value);
  }

  const updateCorporateSelections = (event) => {
    setErrorMessage(null);
    var newSelectionList = selectionList;

    if (corporateEditType === CORPORATE_EDIT_TYPES.ACQUISITION) {
      if (newSelectionList.length === 1) {
        newSelectionList.splice(0, 1);
      }
    }

    if (event.target.checked) {
      newSelectionList.push(Number(event.currentTarget.id));
    }
    else {
      var index = newSelectionList.indexOf(Number(event.currentTarget.id));
      if (index !== -1) {
        newSelectionList.splice(index, 1);
      }
    }

    setSelectedItems(newSelectionList); 

    updateCorporateComment(corporateName);
  }

  const onChangeDate = (text, date) => {
    setCorporateActionDate(moment(date).utc(true));
    setCorporateActionMessage(text);
  }

  const nameCheckCallback = (result) => {
    if (result) {
      setErrorMessage('The Corporate Name you have entered already exists in the Database!');
    }
    else {
      submitCorporateEdit();
    }
  }

  const validate = () => {
    if (corporateEditType === CORPORATE_EDIT_TYPES.ACQUISITION || corporateEditType === CORPORATE_EDIT_TYPES.ACTIVATE || corporateEditType === CORPORATE_EDIT_TYPES.DEACTIVATE)
      props.onNameCheck(corporateName, corporateEditType, nameCheckCallback);

    if (corporateEditType === CORPORATE_EDIT_TYPES.CORRECT || corporateEditType === CORPORATE_EDIT_TYPES.REBRAND) {
      if (corporateEditType === CORPORATE_EDIT_TYPES.CORRECT) createNewCorporate = false;
      props.onNameCheck(corporateName, corporateEditType, nameCheckCallback);
    }
    else if (corporateEditType === CORPORATE_EDIT_TYPES.MERGE) {
      var foundCorporate = corporates.find(c => c.corporateName === corporateName);
      if (foundCorporate !== null && foundCorporate !== undefined) {
        var checked = selectionList.includes(foundCorporate.corporateId);
        if (!checked) {
          setErrorMessage('The Corporate Name you have entered is in list but you havnt selected it!');
        }
        else {
          createNewCorporate = false;
          submitCorporateEdit();
        }
      }
      else {

        submitCorporateEdit();
      }
    }
  }

  const submitCorporateEdit = () => {
    props.onSubmitCorporateEdit(props.corporateId, corporateEditType, selectionList, corporateName, moment(corporateActionDate).format('YYYY-MM-DD[T]hh:mm:ss.sss'), corporateActionMessage, createNewCorporate);
  }

  const submit = async () => {

    validate();
  }

  const setOKDisabled = () => {
    let disabled = false;

    switch (corporateEditType) {
      case CORPORATE_EDIT_TYPES.MERGE:
        if (selectionList.length < 2 || corporateName.trim() === '') disabled = true;
        break;
      case CORPORATE_EDIT_TYPES.CORRECT:
        if (corporateName.trim() === '') disabled = true;
        break;
      case CORPORATE_EDIT_TYPES.REBRAND:
        if (corporateName.trim() === '' || corporateName === props.corporateName) disabled = true;
        break;
      case CORPORATE_EDIT_TYPES.ACQUISITION:
        if (selectionList.length < 1 || corporateName.trim() === '') disabled = true;
        break;
    }

    return disabled;
  }


  let row1 = null;
  let row2 = null;
  let row3 = null;
  let row4 = null;
  let row5 = errorMessage === null ? null :
    <div className="error-message">
      <span>{errorMessage}</span>
    </div>

  let row6 =
    <div className="buttons">
      <Button className="ok-button" disabled={setOKDisabled()} size="md" onClick={() => submit()}>Ok</Button>
      <Button className="cancel-button" size="md" onClick={() => onClose()}>Cancel</Button>
    </div>

  if (props.corporateEditType === CORPORATE_EDIT_TYPES.MERGE || props.corporateEditType === CORPORATE_EDIT_TYPES.ACQUISITION) {
    var corporateSelections = [];

    if (corporates !== undefined && corporates !== null && corporates.length > 0) {
      for (var j = 0; j < corporates.length; j++) {
        var isSelected = selectionList.includes(corporates[j].corporateId);
        corporateSelections.push(<div key={j} style={{ 'height': '18px' }}><input id={corporates[j].corporateId} type="checkbox" className="category_check" checked={isSelected} onChange={(event) => updateCorporateSelections(event)} /><span style={{ 'top': '-2px', 'position': 'relative', 'marginLeft': '5px' }}>{corporates[j].corporateName} </span>  </div>);
      }
    }

    row1 =
      <Row>
        <Col >
          {editMessage}
        </Col>
      </Row>
    row2 = props.corporateEditType === CORPORATE_EDIT_TYPES.ACQUISITION ? null :
      <Row className="mt-3 pl-3" >
        <Col xs="4">
          <Label for="cbNtdStartYear">New Corporate Name</Label>
        </Col>
        <Col xs="7">
          <Input style={{ 'height': '30px' }} type="textarea" name="text" id="exampleText" value={corporateName} onChange={onChangeCorporateName} />
        </Col>
      </Row>
    row3 =
      <Row className="mt-3 pl-3">
        <Col xs="3">
          <Label for="cbNtdStartYear">Corporates</Label>
        </Col>
        <Col xs="8" style={{ 'height': '300px', 'overflow': 'auto' }}>   
          {corporateSelections}
        </Col>
      </Row>
    row4 = corporateActionMessage === null ? null :
      <Row className="corporate-comment">
        <Col >
          <DateTextGenericComponent key="1" editing commentText={corporateActionMessage} commentDate={corporateActionDate} onChange={onChangeDate} />
        </Col>
      </Row>
  }
  else {
    row1 =
      <Row>
        <Col>
          {editMessage}
        </Col>
      </Row>
    row2 =
      <Row className="mt-3 pl-3" >
        <Col xs="4">
          <Label for="cbNtdStartYear">Corporate Name</Label>
        </Col>
        <Col xs="7">
          <Input style={{ 'height': '30px' }} type="textarea" name="text" id="exampleText" value={corporateName} onChange={props.corporateEditType === CORPORATE_EDIT_TYPES.ACTIVATE || props.corporateEditType === CORPORATE_EDIT_TYPES.DEACTIVATE ? () => { } : onChangeCorporateName} />
        </Col>
      </Row>
    row3 = null
    row4 = corporateActionMessage === null ? null :
      <Row className="corporate-comment">
        <Col >
          <DateTextGenericComponent key="1" editing commentText={corporateActionMessage} commentDate={corporateActionDate} onChange={onChangeDate} />
        </Col>
      </Row>
  }

  return (
    <Container className="corporate-edit-component" fluid style={{ paddingLeft: 0, paddingRight: 0, overflowX: "hidden", overflow: "hidden" }}>
      {row1}
      {row2}
      {row3}
      {row4}
      {row5}
      {row6}
    </Container>
  );
};

CorporateEditComponent.propTypes = {
  corporateName: PropTypes.string.isRequired,
  corporates: PropTypes.arrayOf(PropTypes.shape({
    corporateId: PropTypes.number.isRequired,
    corporateName: PropTypes.string.isRequired
  })).isRequired
};

export default CorporateEditComponent;
