import React from 'react';
import PropTypes from 'prop-types';

import './toolbar-component.scss';

const DEFAULT_COLOR = 'primary';
const COLORS = [DEFAULT_COLOR, 'primary', 'secondary'];

const ToolbarComponent = (props) => {
  let { color, align, items } = props;

  if (color === undefined || color === null) color = DEFAULT_COLOR;

  const className = 'toolbar-component' +
    (align !== 'vertical' ? ' horizontal' : ' vertical') +
    (color === 'none' ? '' : ' ' + color);

  let header = props.header;
  if (typeof header === 'string') {
    header = <h4>{header}</h4>;
  }

  return (
    <div className={className}>
      {header && <div className="toolbar-item header">{header}</div>}
      {items.map((item, index) => <div key={'toolbar-item' + index} className="toolbar-item">{item}</div>)}
    </div>
  );
};

ToolbarComponent.propTypes = {
  align: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  color: PropTypes.oneOf(COLORS),
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default ToolbarComponent;
export { DEFAULT_COLOR, COLORS };
