import L from 'leaflet';

import { faMousePointer } from '@fortawesome/pro-regular-svg-icons';

import './search-buttons-control.scss';
import { MeasureButtonControl } from '../common/measure-button-control';
import { BoxSelectButtonControl } from './box-select-button-control';
import { PointSelectButtonControl } from './point-select-button-control';
import { RadiusSelectButtonControl } from './radius-select-button-control';

import { DISABLED_CLASS_NAME, createButton, hasClass, toggleClass, addClass, removeClass } from '../../helpers/control-helper';

L.Control.SearchButtonsControl = L.Control.extend({
  initialize: function (options) { this.options = options; },
  onAdd: function () { return this.options.container; }
});

const HIDDEN_CLASS_NAME = 'sbc-hidden';

class SearchButtonsControl {
  constructor(leafletMap) {
    if (!(leafletMap instanceof L.Map)) {
      throw new Error('Invalid "leafletMap" param supplied to "SearchButtonsControl.ctor"');
    }

    this._ignoreMouseEvents = false;
    this._leafletMap = leafletMap;

    this._container = L.DomUtil.create('div', 'leaflet-bar search-buttons-control');
    L.DomEvent.on(this._container, 'mouseenter', () => this._ignoreMouseEvents = true, this);
    L.DomEvent.on(this._container, 'mouseleave', () => this._ignoreMouseEvents = false, this);

    //TODO: commented out until we revisit 181 - Retain Select Tool Function
    //this._cancelButton = createButton(this._container, DISABLED_CLASS_NAME, faMousePointer, this.cancelAll, { title: 'Cancel' });
    this.pointSelect = new PointSelectButtonControl(this);
    this.boxSelect = new BoxSelectButtonControl(this);
    this.radiusSelect = new RadiusSelectButtonControl(this);
    this.measure = new MeasureButtonControl(this);

    this._leafletMap.addControl(new L.Control.SearchButtonsControl({ position: 'topleft', container: this._container }));
  }

  get ignoreMouseEvents() {
    return this._ignoreMouseEvents;
  }
  get leafletMap() {
    return this._leafletMap;
  }
  get container() {
    return this._container;
  }
  get visible() {
    return !hasClass(this._container, HIDDEN_CLASS_NAME);
  }
  set visible(value) {
    if (value) {
      removeClass(this._container, HIDDEN_CLASS_NAME);
    } else {
      addClass(this._container, HIDDEN_CLASS_NAME);
    }
  }

  cancelAll = () => {
    this.pointSelect.cancel();
    this.boxSelect.cancel();
    this.radiusSelect.cancel();
    this.measure.cancel();
  }

  updateCancelButton() {
    //TODO: commented out until we revisit 181 - Retain Select Tool Function
    //const toggled = this.pointSelect.toggled ||
    //  this.boxSelect.toggled ||
    //  this.radiusSelect.toggled ||
    //  this.measure.toggled;

    //const disabled = hasClass(this._cancelButton, DISABLED_CLASS_NAME);
    //if (toggled === disabled) {
    //  toggleClass(this._cancelButton, DISABLED_CLASS_NAME);
    //}
  }
}

export { SearchButtonsControl };
