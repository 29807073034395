import { Issue } from '../../../common/models/issue';
import { EditLock } from '../../../common/models/edit-lock';
import { GMatrixGenericComment } from '../../../common/models/generic-comment';
import { WestwoodDataWell } from '../../../common/models/westwood-data-well';

class Attributes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Attributes.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to Attributes.ctor'); }
      if (typeof obj.displayName !== 'string') { throw new Error('Invalid obj.displayName param supplied to Attributes.ctor'); }
      if (obj.wellProgramme !== null && typeof obj.wellProgramme !== 'string') { throw new Error('Invalid obj.wellProgramme param supplied to Attributes.ctor'); }
      if (typeof obj.countryId !== 'number') { throw new Error('Invalid obj.countryId param supplied to Attributes.ctor'); }
      if (obj.countryName !== null && typeof obj.countryName !== 'string') { throw new Error('Invalid obj.countryName param supplied to Attributes.ctor'); }
      if (obj.westwoodDataId !== null && typeof obj.westwoodDataId !== 'string') { throw new Error('Invalid obj.westwoodDataId param supplied to Attributes.ctor'); }
      if (obj.westwoodLegacyWellId !== null && typeof obj.westwoodLegacyWellId !== 'number') { throw new Error('Invalid obj.westwoodLegacyWellId param supplied to Attributes.ctor'); }
      if (obj.comments !== null && !Array.isArray(obj.comments)) { throw new Error('Invalid obj.comments param supplied to Attributes.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.displayName = obj === null ? '' : obj.displayName;
    this.wellProgramme = obj === null ? 0 : obj.wellProgramme;
    this.countryId = obj === null ? 0 : obj.countryId;
    this.countryName = obj === null || obj.countryName === null ? '' : obj.countryName;
    this.westwoodDataId = obj === null ? '' : obj.westwoodDataId;
    this.westwoodLegacyWellId = obj === null ? 0 : obj.westwoodLegacyWellId;

    this.comments = (obj === null || obj.comments === null) ? [new GMatrixGenericComment(null)] : obj.comments.map(item => new GMatrixGenericComment(item));

    this.status = obj === null ? '' : obj.status;
    this.originalStatus = obj === null ? '' : obj.originalStatus;
  }
}

export class Result {
  constructor(obj) {
    obj && Object.assign(this, obj);
    this.attributes = obj === null || obj === undefined ? new Attributes(null) : new Attributes(obj.attributes);
    this.westwoodDataWell = obj === null || obj === undefined ? null : new WestwoodDataWell(obj.westwoodDataWell);
    this.issues = obj === null || obj === undefined ? [new Issue()] : obj.issues.map(item => new Issue(item));
    this.editLock = obj === null || obj === undefined ? new EditLock() : new EditLock(obj.editLock);
  }
}

export { Issue, EditLock, Attributes };
