import React from 'react';
import PropTypes from 'prop-types';

import AttributeStaticListComponent from '../../../../common/components/attribute-list/attribute-static-list-component';

import { ResourceAlias } from '../../models/result';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import { LookupResult } from '../../../../common/models/lookup-result';
import { RECORD_STATUS } from '../../../../common/models/record-status';
import { validateDate, PropertyGridStringValue, PropertyGridDateValueUTC, PropertyGridSelectValue, getDate, formatDate } from '../../../../shared/components/property-grid/property-grid-component';

const IDS = {
  ALIAS: 0,
  STATUS_ID: 10,
  START_DATE: 20,
  END_DATE: 30
};
Object.freeze(IDS);

const AliasComponent = (props) => {
  const { editing, messageBoxGlobal, lookups, resourceAliases, resourceAliasActions, id } = props;
  const statuses = [...lookups.ResourceStatus_Discovery, ...lookups.ResourceStatus_Field, ...lookups.ResourceStatus_Prospect].sort((a, b) => { return a.key - b.key; });

  const onEdit = (id, value, item) => {
    switch (id) {
      case IDS.ALIAS: item.alias = value; break;
      case IDS.STATUS_ID: item.statusId = Number(value); break;
      case IDS.START_DATE:
        if (!validateDate(value, item)) {
          return false;
        }
        if (value === undefined || value === null) item.startDate = null;
        else item.startDate = getDate(value);
        break;
      case IDS.END_DATE:
        if (!validateDate(value, item)) {
          return false;
        }
        if (value === undefined || value === null) item.endDate = null;
        else item.endDate = getDate(value);
        break;
      default: throw new Error('Id ' + id + ' is not supported in AliasComponent.onEdit');
    }

    //if (item.startDate !== undefined && item.startDate !== null && item.startDate !== '')
      resourceAliasActions.onEdit(item);
    return true;
  };

  const getTitle = (item) => {
    let value = item.alias === undefined || item.alias === null ? '' : item.alias;

    if (item.status === RECORD_STATUS.added && value === '') return '-New Alias--';

    let startDate = item.startDate === undefined || item.startDate === null ? '-' : formatDate(item.startDate);
    let endDate = item.endDate === undefined || item.endDate === null ? '-' : formatDate(item.endDate);

    return value === '' ? '-' : value + ' (' + startDate + ' to ' + endDate + ')';
  };

  const items = resourceAliases.map(item => {
    return {
      label: getTitle(item),
      data: item,
      sections: [{
        label: 'Alias',
        rows: [
          { label: 'Resource Alias', values: [new PropertyGridStringValue(IDS.ALIAS, item.alias, (value, id) => onEdit(id, value, item))] },
          { label: 'Status', values: [new PropertyGridSelectValue(IDS.STATUS_ID, item.statusId, (value, id) => onEdit(id, value, item), 'key', 'value', statuses)] },
          { label: 'Start Date', values: [new PropertyGridDateValueUTC(IDS.START_DATE, item.startDate, (value, id) => onEdit(id, value, item))] },
          { label: 'End Date', values: [new PropertyGridDateValueUTC(IDS.END_DATE, item.endDate, (value, id) => onEdit(id, value, item))] }
        ]
      }]
    }
  });

  const add = () => {
    let newItem = new ResourceAlias(null);
    newItem.getNew(id);

    if (resourceAliases.length > 0) {
      if (resourceAliases[0].endDate === null || resourceAliases[0].endDate === undefined) {
        let newDate = formatDate(new Date());
        resourceAliases[0].endDate = getDate(newDate);
      }
      resourceAliases[0].status = resourceAliases[0].status === RECORD_STATUS.added ? resourceAliases[0].status : RECORD_STATUS.edited;
    }

    resourceAliasActions.onAdd(newItem);
  }

  return (
    <AttributeStaticListComponent
      editing={editing}
      messageBoxGlobal={messageBoxGlobal}
      items={items}
      itemDescription="alias"
      canAdd
      canDelete
      onAdd={add}
      onDelete={resourceAliasActions.onDelete}
      onSelected={resourceAliasActions.onSelected}
      onAddPosition={"start"}
      className={""}
    />
  );
};

AliasComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  resourceAliases: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAlias)).isRequired,
  resourceAliasActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired
};

export default AliasComponent;
