import React from 'react';
import PropTypes from 'prop-types';

import './toolbar-panel-component.scss';
import ToolbarComponent, { DEFAULT_COLOR as DEFAULT_TOOLBAR_COLOR, COLORS as TOOLBAR_COLORS } from './toolbar-component';
import { appendClassNames } from '../../helpers/common';

const ToolbarPanelComponent = (props) => {
  const align = props.align === 'left' || props.align === 'right' ? 'vertical' : 'horizontal';
  const className = appendClassNames('toolbar-panel-component ' + align, props.className);
  const toolbarColor = (props.toolbarColor === undefined || props.toolbarColor === null) ? DEFAULT_TOOLBAR_COLOR : props.toolbarColor;
  const toolbar = <div className="toolbar"><ToolbarComponent align={align} color={toolbarColor} header={props.header} items={props.toolbarItems} /></div>;
  const content = <div className="content">{props.children}</div>;

  return (
    <div className={className}>
      {props.align === 'top' || props.align === 'left' ? toolbar : content}
      {props.align === 'top' || props.align === 'left' ? content : toolbar}
    </div>
  );
};

ToolbarPanelComponent.propTypes = {
  align: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
  toolbarColor: PropTypes.oneOf(TOOLBAR_COLORS),
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  toolbarItems: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default ToolbarPanelComponent;
export { DEFAULT_TOOLBAR_COLOR, TOOLBAR_COLORS };
