import L from 'leaflet';

const removeLayersOfType = (leafletMap, layerType) => {
  if (!(leafletMap instanceof L.Map)) {
    throw new Error('Invalid \'leafletMap\' param supplied to \'removeLayersOfType\'');
  }
  if (typeof layerType !== 'function') {
    throw new Error('Invalid \'layerType\' param supplied to \'removeLayersOfType\'');
  }

  const layers = Object.values(leafletMap._layers).filter(obj => obj instanceof layerType);
  for (var loop = 0; loop < layers.length; loop++) {
    leafletMap.removeLayer(layers[loop]);
  }
};

export { removeLayersOfType };