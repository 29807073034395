import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import './editable-view-component.scss';
import TimeoutComponent from './timeout-component';
import YearsFilterComponent from './years-filter-component';
import { appendClassNames } from '../../../shared/helpers/common';
import ToolbarPanelComponent from '../../../shared/components/toolbar-panel/toolbar-panel-component';
import IssuesGridComponent, { IssueType, Issue } from './issues-grid-component';
import SplitterPanelComponent from '../../../shared/components/splitter-panel/splitter-panel-component';
import { MessageBoxGlobal, EditingGlobal, LoadingGlobal, PinLocationGlobal } from '../../../common/globals/globals';
import { MessageBoxButton, MessageBoxButtons } from '../../../shared/components/message-box/message-box-classes';
import { layerType } from '../../../shared/helpers/leaflet/models/context-layer-model';

import RelatedItemDropdownComponent from './related-item-dropdown-component';

const getIssueButtonClassName = (issues) => {
  if (!Array.isArray(issues) || issues.filter(obj => !IssueType.values().includes(obj.issueTypeId)).length > 0) {
    throw new Error('Invalid issues param supplied to EditableViewComponent.getIssueButtonClassName');
  }

  let issueType = IssueType.none;
  let count = issues.filter(obj => obj.issueTypeId === IssueType.error).length;
  if (count > 0) {
    issueType = IssueType.error;
  } else {
    count = issues.filter(obj => obj.issueTypeId === IssueType.warning).length;
    if (count > 0) {
      issueType = IssueType.warning;
    }
  }
  return 'btn-image issues-button' + (issueType === IssueType.none ? '' : ' ' + IssueType.displayName(issueType).toLowerCase());
};

const EditableViewComponent = (props) => {
  const { editingGlobal, messageBoxGlobal, shortcuts, issues, edited, isProduction, pinLocationGlobal, viewGlobal, searchGlobal, currentView, currentId, loadingGlobal, showCalc } = props;
  const editing = editingGlobal.value;
  const [issuesVisible, setIssuesVisible] = useState(false);

  const toggleIssues = () => setIssuesVisible(!issuesVisible);

  const refresh = () => {
    if (editing) {
      const callback = (button) => { if (button === MessageBoxButton.OK) { props.refresh(); } };
      messageBoxGlobal.showWarning('Refreshing the data will overwrite your changes!', callback, MessageBoxButtons.OKCancel, 'Refresh Data');
    } else {
      props.refresh();
    }
  };

  const onTimedOut = () => {
    props.cancelEdit(true);
    messageBoxGlobal.showWarning('Your edit session has timed out.');
  };

  const toolbarItems = [];

  if (props.toolbarItemsPosition === 'before') {
    toolbarItems.push(...props.toolbarItems);
  }

  const relatedDropdown = <RelatedItemDropdownComponent editing={editing} viewGlobal={viewGlobal} searchGlobal={searchGlobal} currentView={currentView} currentId={currentId} loadingGlobal={loadingGlobal} messageBoxGlobal={messageBoxGlobal} />
  toolbarItems.push(relatedDropdown);

  const onSaveEdit = () => {
    props.loadingGlobal.showSave();
    const onSave = () => {
      props.saveEdit();
      props.loadingGlobal.hide();
    }
    setTimeout(onSave, 1000);
  }

  const enablePin = pinLocationGlobal !== undefined && pinLocationGlobal !== null && pinLocationGlobal.value !== undefined && pinLocationGlobal.value !== null;

  toolbarItems.push(<Button className="btn-image refresh-all-button" size="sm" disabled={!props.canRefresh || !props.canEnable} onClick={refresh}>Refresh</Button>);
  if (editing) {
    toolbarItems.push(<Button className="btn-image first-button save-button" size="sm" disabled={!props.canEnable || edited} onClick={onSaveEdit}>Save</Button>);
  } else {
    toolbarItems.push(<Button className="btn-image first-button edit-button" size="sm" disabled={!props.canEnable || props.recordLocked} onClick={() => props.beginEdit()}>Edit</Button>);
  }

  toolbarItems.push(<Button className="btn-image last-button cancel-button" size="sm" disabled={!editing || !props.canEnable} onClick={() => props.cancelEdit()}>Cancel</Button>);

  if (props.canCopyPin && editing) toolbarItems.push(<Button className="btn-image first-button pin-location-button" size="sm" disabled={!enablePin} onClick={() => props.onCopyPin()}>Copy Pin Location</Button>);

  toolbarItems.push(<Button className={getIssueButtonClassName(issues)} size="sm" onClick={toggleIssues}>{'Issues' + (issues.length <= 99 ? ' (' + issues.length + ')' : '')}</Button>);

  if (props.canDelete && !editing) toolbarItems.push(<Button className="btn-image delete-button" size="sm" onClick={() => props.onDelete()}>Delete</Button>);

  if (props.toolbarItemsPosition === 'after') {
    toolbarItems.push(...props.toolbarItems);
  }
  if (editing) {
    toolbarItems.push(<TimeoutComponent timeout={props.editTimeout} onTimedOut={onTimedOut} onReset={props.resetEditTimeout} canEnable={props.canEnable} />);
  }
  if (editing && showCalc) {
    toolbarItems.push(<Button className="btn-image first-button calc-button" size="sm" disabled={!editing || !props.canEnable || !edited} onClick={() => props.calculate()}>Calculate</Button>);
  }
  if (isProduction) toolbarItems.push(<Button className="btn-image first-button snap-button" size="sm" disabled={editing || props.recordLocked} onClick={() => props.updateSnapshotAudit()}>Snapshot/Audit</Button>);
  if (isProduction) toolbarItems.push(<YearsFilterComponent className="years" startYear={props.startYear} endYear={props.endYear} selectedStartYear={props.selectedStartYear} selectedEndYear={props.selectedEndYear} onYearStartChange={(e) => props.yearStartChange(e)} onYearEndChange={(e) => props.yearEndChange(e)} />);

  return (
    <ToolbarPanelComponent
      className={appendClassNames('editable-view-component', props.className)}
      header={props.header}
      toolbarColor="primary"
      toolbarItems={toolbarItems}
      align={'top'}>
      <SplitterPanelComponent
        animateSizeChange
        primaryIndex={1}
        secondarySize={25}
        visiblePanes={issuesVisible ? 'both' : 'primary'}
      >
        <IssuesGridComponent messageBoxGlobal={props.messageBoxGlobal} editingGlobal={props.editingGlobal} issues={props.issues} />
        {props.children}
      </ SplitterPanelComponent>
    </ToolbarPanelComponent>
  );
};

EditableViewComponent.propTypes = {
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  editingGlobal: PropTypes.instanceOf(EditingGlobal).isRequired,
  className: PropTypes.string.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  issues: PropTypes.arrayOf(PropTypes.instanceOf(Issue)).isRequired,
  canRefresh: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  beginEdit: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  saveEdit: PropTypes.func.isRequired,
  resetEditTimeout: PropTypes.func.isRequired,
  shortcuts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  })).isRequired,
  toolbarItems: PropTypes.arrayOf(PropTypes.node),
  toolbarItemsPosition: PropTypes.oneOf(['before', 'after']).isRequired,
  editTimeout: PropTypes.instanceOf(Date),
  canEnable: PropTypes.bool.isRequired,
  recordLocked: PropTypes.bool.isRequired,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  selectedStartYear: PropTypes.number.isRequired,
  selectedEndYear: PropTypes.number.isRequired,
  yearStartChange: PropTypes.func.isRequired,
  yearEndChange: PropTypes.func.isRequired,
  updateSnapshotAudit: PropTypes.func.isRequired,
  edited: PropTypes.bool.isRequired,
  calculate: PropTypes.func.isRequired,
  isProduction: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  canCopyPin: PropTypes.bool.isRequired,
  pinLocationGlobal: PropTypes.instanceOf(PinLocationGlobal),
  onCopyPin: PropTypes.func.isRequired,
  showCalc: PropTypes.bool.isRequired,
}

export default EditableViewComponent;
export { IssueType, layerType };
