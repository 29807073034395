import React from 'react';
import PropTypes from 'prop-types';

import { Attributes, unitConversions } from '../../models/result';
import { IN_DEV_MODE } from '../../../../shared/helpers/common';
import { LookupResult } from '../../../../common/models/lookup-result';
import PropertyGridComponent, { validateDate, PropertyGridStringValue, PropertyGridSelectValue, PropertyGridNumberValue, PropertyGridNumberValueDouble, PropertyGridBooleanValue, PropertyGridDateValueUTC, getDate } from '../../../../shared/components/property-grid/property-grid-component';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import { INPUTTYPES } from '../../../../shared/components/number-format/number-formatter';
import { valueTypes } from '../../../../shared/helpers/value-handler';

const IDS = {
  WELL_ID: 5,
  WELL_NAME: 10,
  BLOCK_NO: 15,
  WELL_PROGRAMME_ID: 20,
  CLASS_ID: 25,
  SUB_CLASS_ID: 30,
  INTENDED_TRACK_ID: 35,
  CURRENT_STATUS_ID: 40,
  ORIGINAL_STATUS_ID: 45,
  PRIMARY_OBJECTIVE_ID: 50,
  SECONDARY_OBJECTIVE_ID: 55,
  RESERVOIR_CONDITION_ID: 60,
  BHF_ID: 65,
  LICENCE_ID: 70,
  START_DATE: 75,
  TD_DATE: 80,
  END_DATE: 85,
  TMD_FT: 90,
  TMD_M: 91,
  WD_FT: 95,
  WD_M: 96,
  OFFICIAL_UID: 100,
  PROSPECT_DISCOVERY_FIELD_NAME: 105,
  GOVERNMENT_WELL_NAME: 110,
  FILEMAKER_URN: 115,
  STRAT_LEVEL_ID: 117,
  DMS_LONG_SECONDS: 120,
  DTI_PON_14_SUBMITTED: 125,
  DTI_PON_14_APPROVED: 130,
  DTI_PON_15_SUBMITTED: 135,
  DTI_PON_15_APPROVED: 140,
  PRE_SPUD_COMMENT: 145,
  EARLIEST_TIMING_START: 150,
  LATEST_TIMING_START: 155,
  TIMING: 160,
  DATE_COMMENT: 165,
  DEPTH_COMMENT: 170,
  PTD_FT: 175,
  PTD_M: 176,
  TVD_SS_FT: 180,
  TVD_SS_M: 181,
  COMMITMENT_ID: 185,
  LOCATION_PROVENACE_ID: 190,
  DEAL_ID: 195,
  DAYS_TO_TD: 200,
  DAYS_FROM_TD: 205,
  FULL_DURATION: 210,
  TD_DURATION: 215,
  OPPORTUNITY: 220,
  RIG_ID: 230,
  ELEVATION_TYPE_ID: 240,
  COST_SOURCE_ID: 250,
  ELEVATION_FT: 260,
  ELEVATION_M: 261,
  RIG_RATE_DPD: 270,
  RIG_UPLIFT: 280,
  CALCULATED_WELL_COST_USDMM: 290,
  MANUAL_WELL_COST_USDMM: 300,
  COST_SOURCE_DESCRIPTION: 310,
  WELL_PURPOSE_ID: 320,
  AUTO_UPDATE: 330
};
Object.freeze(IDS);

const getSections = (attributes, lookups, valueChanged, lookupsFiltered) => {
  const autoUpdate = attributes.classId === 2 ? attributes.autoUpdate : false;

  const result = [
    {
      label: 'Well Header',
      rows: [
        { label: 'Well Programme', values: [new PropertyGridSelectValue(IDS.WELL_PROGRAMME_ID, attributes.wellProgrammeId, autoUpdate ? null : valueChanged, 'key', 'value', lookupsFiltered.WellProgramme)] },
        { label: 'Well', values: [new PropertyGridStringValue(IDS.WELL_NAME, attributes.well, valueChanged)] },
        { label: 'Block No', values: [new PropertyGridStringValue(IDS.BLOCK_NO, attributes.blockNo)] },
        { label: 'Purpose', values: [new PropertyGridSelectValue(IDS.WELL_PURPOSE_ID, attributes.wellPurposeId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.WellPurpose)] },
        { label: 'Class', values: [new PropertyGridSelectValue(IDS.CLASS_ID, attributes.classId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.WellClass)] },
        { label: 'Sub Class', values: [new PropertyGridSelectValue(IDS.SUB_CLASS_ID, attributes.subClassId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.WellType)] },
        { label: 'Intended Track', values: [new PropertyGridSelectValue(IDS.INTENDED_TRACK_ID, attributes.intendedTrackId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.IntendedTrack)] },
        { label: 'Prospect/ Discovery/ Field Name', values: [new PropertyGridStringValue(IDS.PROSPECT_DISCOVERY_FIELD_NAME, attributes.prospectDiscoveryFieldName, autoUpdate ? null : valueChanged)] },
        { label: 'Current Status', values: [new PropertyGridSelectValue(IDS.CURRENT_STATUS_ID, attributes.currentStatusId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.WellStatus)] },
        { label: 'Original Status', values: [new PropertyGridSelectValue(IDS.ORIGINAL_STATUS_ID, attributes.originalStatusId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.WellStatus)] },
        { label: 'Licence', values: [new PropertyGridSelectValue(IDS.LICENCE_ID, attributes.licenceId, autoUpdate ? null : valueChanged, 'key', 'value', lookupsFiltered.Licence, true)] },
        { label: 'UID for Atlas', values: [new PropertyGridStringValue(IDS.OFFICIAL_UID, attributes.officialUID, autoUpdate ? null : valueChanged)] },
        { label: 'Government Well Name', values: [new PropertyGridStringValue(IDS.GOVERNMENT_WELL_NAME, attributes.governmentWellName, autoUpdate ? null : valueChanged)] },
        { label: 'Filemaker URN', values: [new PropertyGridNumberValue(IDS.FILEMAKER_URN, INPUTTYPES.NUMBER, attributes.filemakerURN, valueChanged, valueTypes.GetPrecision(valueTypes.generic0, 0))] },
        { label: 'Stratigraphic Level', values: [new PropertyGridSelectValue(IDS.STRAT_LEVEL_ID, attributes.stratLevelId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.StratLevel, true)] },
        { label: 'Auto Update', values: [new PropertyGridBooleanValue(IDS.AUTO_UPDATE, attributes.autoUpdate, valueChanged)] },
      ]
    },
    {
      label: 'Approval Date',
      rows: [
        { label: 'Environmental Permit Submitted', values: [new PropertyGridDateValueUTC(IDS.DTI_PON_14_SUBMITTED, attributes.dtiPon14Submitted, valueChanged)] },
        { label: 'Environmental Permit Approved', values: [new PropertyGridDateValueUTC(IDS.DTI_PON_14_APPROVED, attributes.dtiPon14Approved, valueChanged)] },
        { label: 'Drilling Permit Submitted', values: [new PropertyGridDateValueUTC(IDS.DTI_PON_15_SUBMITTED, attributes.dtiPon15Submitted, valueChanged)] },
        { label: 'Drilling Permit Approved', values: [new PropertyGridDateValueUTC(IDS.DTI_PON_15_APPROVED, attributes.dtiPon15Approved, valueChanged)] },
      ]
    },
    {
      label: 'Dates & Durations',
      rows: [
        { label: 'PreSpud Comment', values: [new PropertyGridStringValue(IDS.PRE_SPUD_COMMENT, attributes.preSpudComment, valueChanged)] },
        { label: 'Earliest Timing Start', values: [new PropertyGridDateValueUTC(IDS.EARLIEST_TIMING_START, attributes.earliestTimingStart, valueChanged)] },
        { label: 'Latest Timing Start', values: [new PropertyGridDateValueUTC(IDS.LATEST_TIMING_START, attributes.latestTimingStart, valueChanged)] },
        { label: 'Timing', values: [new PropertyGridStringValue(IDS.TIMING, attributes.timing)] },
        { label: 'Start Date', values: [new PropertyGridDateValueUTC(IDS.START_DATE, attributes.startDate, autoUpdate ? null : valueChanged)] },
        { label: 'Total Depth Date', values: [new PropertyGridDateValueUTC(IDS.TD_DATE, attributes.tdDate, autoUpdate ? null : valueChanged)] },
        { label: 'End Date', values: [new PropertyGridDateValueUTC(IDS.END_DATE, attributes.endDate, autoUpdate ? null : valueChanged)] },
        { label: 'Days to TD', values: [new PropertyGridNumberValue(IDS.DAYS_TO_TD, INPUTTYPES.NUMBER, attributes.daysToTD, null, 0)] },
        { label: 'Days From TD', values: [new PropertyGridNumberValue(IDS.DAYS_FROM_TD, INPUTTYPES.NUMBER, attributes.daysFromTD, null, 0)] },
        { label: 'Duration (Start to End Date)', values: [new PropertyGridNumberValue(IDS.FULL_DURATION, INPUTTYPES.NUMBER, attributes.fullDuration, null, 0)] },
        { label: 'Duration (Days to TD + Days From TD)', values: [new PropertyGridNumberValue(IDS.TD_DURATION, INPUTTYPES.NUMBER, attributes.tdDuration, null, 0)] },
        { label: 'Predicted Duration', values: [new PropertyGridStringValue(IDS.DATE_COMMENT, attributes.dateComment, valueChanged)] },
      ]
    },
    {
      label: 'Depths',
      rows: [
        { label: 'Depth Comment', values: [new PropertyGridStringValue(IDS.DEPTH_COMMENT, attributes.depthComment, valueChanged)] },
        //{ label: 'Water Depth (ft)', values: [new PropertyGridNumberValue(IDS.WD_FT, INPUTTYPES.NUMBER, attributes.wdFt, valueChanged, 2)] },
        { label: 'Water Depth', values: [new PropertyGridNumberValueDouble(IDS.WD_FT, IDS.WD_M, attributes.wdFt, attributes.wdM, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, autoUpdate ? null : valueChanged, 2)] },
        //{ label: 'Predicted Depth (ft)', values: [new PropertyGridNumberValue(IDS.PTD_FT, INPUTTYPES.NUMBER, attributes.ptdFt, valueChanged, 2)] },
        { label: 'Predicted Depth', values: [new PropertyGridNumberValueDouble(IDS.PTD_FT, IDS.PTD_M, attributes.ptdFt, attributes.ptdM, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, valueChanged, 2)] },
        //{ label: 'True Measured Depth (ft)', values: [new PropertyGridNumberValue(IDS.TMD_FT, INPUTTYPES.NUMBER, attributes.tmdFt, valueChanged, 2)] },
        { label: 'True Measured Depth', values: [new PropertyGridNumberValueDouble(IDS.TMD_FT, IDS.TMD_M, attributes.tmdFt, attributes.tmdM, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, autoUpdate ? null : valueChanged, 2)] },
        //{ label: 'True Verticle Depth SubSea (ft)', values: [new PropertyGridNumberValue(IDS.TVD_SS_FT, INPUTTYPES.NUMBER, attributes.tvdssFt, valueChanged, 2)] },
        { label: 'True Verticle Depth SubSea', values: [new PropertyGridNumberValueDouble(IDS.TVD_SS_FT, IDS.TVD_SS_M, attributes.tvdssFt, attributes.tvdssM, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, autoUpdate ? null : valueChanged, 2)] },
        { label: 'Bottom Hole Fomation', values: [new PropertyGridSelectValue(IDS.BHF_ID, attributes.bhfId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.BottomHoleFormation)] },
        { label: 'Location Provenance', values: [new PropertyGridSelectValue(IDS.LOCATION_PROVENACE_ID, attributes.locationProvenaceId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.LocationProvenace)] },
      ]
    },
    {
      label: 'Well Cost',
      rows: [
        { label: 'Rig', values: [new PropertyGridSelectValue(IDS.RIG_ID, attributes.rigId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.Rig)] },
        { label: 'Elevation Type', values: [new PropertyGridSelectValue(IDS.ELEVATION_TYPE_ID, attributes.elevationTypeId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.ElevationReference)] },
        //{ label: 'Elevation (ft)', values: [new PropertyGridNumberValue(IDS.ELEVATION_FT, INPUTTYPES.NUMBER, attributes.elevationFt, valueChanged, 2)] },
        { label: 'Elevation', values: [new PropertyGridNumberValueDouble(IDS.ELEVATION_FT, IDS.ELEVATION_M, attributes.elevationFt, attributes.elevationM, valueTypes.UnitString(valueTypes.feet), valueTypes.UnitString(valueTypes.meter), INPUTTYPES.NUMBER, autoUpdate ? null : valueChanged, 2)] },
        { label: 'Rig Rate ($ 000s per day)', values: [new PropertyGridNumberValue(IDS.RIG_RATE_DPD, INPUTTYPES.NUMBER, attributes.rigRateDpd, valueChanged, 2)] },
        { label: 'Rig Uplift', values: [new PropertyGridNumberValue(IDS.RIG_UPLIFT, INPUTTYPES.NUMBER, attributes.rigUplift, valueChanged, 2)] },
        { label: 'Calculated Well Cost ($mm)', values: [new PropertyGridNumberValue(IDS.CALCULATED_WELL_COST_USDMM, INPUTTYPES.NUMBER, attributes.calculatedWellCostUSDMM, null, 2)] },
        { label: 'Manual Well Cost ($mm)', values: [new PropertyGridNumberValue(IDS.MANUAL_WELL_COST_USDMM, INPUTTYPES.NUMBER, attributes.manualWellCostUSDMM, valueChanged, 2)] },
        { label: 'Cost Qualifier', values: [new PropertyGridSelectValue(IDS.COST_SOURCE_ID, attributes.costSourceId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.CostSource)] },
        { label: 'Cost Source', values: [new PropertyGridStringValue(IDS.COST_SOURCE_DESCRIPTION, attributes.costSourceDescription, valueChanged)] },
      ]
    },
    {
      label: 'Geology',
      rows: [
        { label: 'Primary Objective', values: [new PropertyGridSelectValue(IDS.PRIMARY_OBJECTIVE_ID, attributes.primaryObjectiveId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.ReservoirAge)] },
        { label: 'Secondary Objective', values: [new PropertyGridSelectValue(IDS.SECONDARY_OBJECTIVE_ID, attributes.secondaryObjectiveId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.ReservoirAge)] },
        { label: 'Reservoir Condition', values: [new PropertyGridSelectValue(IDS.RESERVOIR_CONDITION_ID, attributes.reservoirConditionId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.ReservoirCondition)] },
      ]
    },
    {
      label: 'Opportunity',
      rows: [
        { label: 'Commitment', values: [new PropertyGridSelectValue(IDS.COMMITMENT_ID, attributes.commitmentId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.Commitment)] },
        { label: 'Opportunity', values: [new PropertyGridBooleanValue(IDS.OPPORTUNITY, attributes.opportunity, autoUpdate ? null : valueChanged)] },
        { label: 'Deal', values: [new PropertyGridSelectValue(IDS.DEAL_ID, attributes.dealId, autoUpdate ? null : valueChanged, 'key', 'value', lookups.Deal)] },
      ]
    },
  ];

  if (IN_DEV_MODE) {
    result[0].rows.unshift({ label: 'Well Id', values: [new PropertyGridStringValue(IDS.WELL_ID, attributes.id)] });
  }

  return result;
};

const LeftPanelComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    lookups,
    attributes,
    updateAttributes,
    lookupsFiltered
  } = props;

  const valueChanged = (value, id) => {
    switch (id) {
      // ********* WELL HEADER *********
      case IDS.WELL_PROGRAMME_ID: attributes.wellProgrammeId = Number(value); break;
      case IDS.WELL_NAME: attributes.well = value; break;
      case IDS.WELL_PURPOSE_ID: attributes.wellPurposeId = Number(value); break;
      case IDS.CLASS_ID: attributes.classId = Number(value); break;
      case IDS.SUB_CLASS_ID: attributes.subClassId = Number(value); break;
      case IDS.INTENDED_TRACK_ID: attributes.intendedTrackId = Number(value); break;
      case IDS.PROSPECT_DISCOVERY_FIELD_NAME: attributes.prospectDiscoveryFieldName = value; break;
      case IDS.CURRENT_STATUS_ID: attributes.currentStatusId = Number(value); break;
      case IDS.ORIGINAL_STATUS_ID: attributes.originalStatusId = Number(value); break;
      case IDS.LICENCE_ID: attributes.licenceId = Number(value); break;
      case IDS.OFFICIAL_UID: attributes.officialUID = value; break;
      case IDS.GOVERNMENT_WELL_NAME: attributes.governmentWellName = value; break;
      case IDS.FILEMAKER_URN: attributes.filemakerURN = Number(value); break;
      case IDS.STRAT_LEVEL_ID: attributes.stratLevelId = Number(value); break;
      case IDS.AUTO_UPDATE: attributes.autoUpdate = value; break;

      // ********* APPROVAL DATE *********
      case IDS.DTI_PON_14_SUBMITTED:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid dtiPon14Submitted');
          return false;
        }
        attributes.dtiPon14Submitted = value === undefined ? attributes.dtiPon14Submitted = null : getDate(value);
        break;

      case IDS.DTI_PON_14_APPROVED:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid dtiPon14Approved');
          return false;
        }
        attributes.dtiPon14Approved = value === undefined ? attributes.dtiPon14Approved = null : getDate(value);
        break;

      case IDS.DTI_PON_15_SUBMITTED:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid dtiPon15Submitted');
          return false;
        }
        attributes.dtiPon15Submitted = value === undefined ? attributes.dtiPon15Submitted = null : getDate(value);
        break;

      case IDS.DTI_PON_15_APPROVED:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid dtiPon15Approved');
          return false;
        }
        attributes.dtiPon15Approved = value === undefined ? attributes.dtiPon15Approved = null : getDate(value);
        break;

      // ********* DATES & DURATIONS *********
      case IDS.PRE_SPUD_COMMENT: attributes.preSpudComment = value; break;

      case IDS.EARLIEST_TIMING_START:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid earliestTimingStart');
          return false;
        }
        attributes.earliestTimingStart = value === undefined ? attributes.earliestTimingStart = null : getDate(value);
        break;

      case IDS.LATEST_TIMING_START:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid latestTimingStart');
          return false;
        }
        attributes.latestTimingStart = value === undefined ? attributes.latestTimingStart = null : getDate(value);
        break;

      case IDS.TIMING: attributes.timing = value; break;

      case IDS.START_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid startDate');
          return false;
        }
        attributes.startDate = value === undefined ? attributes.startDate = null : getDate(value);
        break;

      case IDS.TD_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid tdDate');
          return false;
        }
        attributes.tdDate = value === undefined ? attributes.tdDate = null : getDate(value);
        break;

      case IDS.END_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid endDate');
          return false;
        }
        attributes.endDate = value === undefined ? attributes.endDate = null : getDate(value);
        break;

      case IDS.DATE_COMMENT: attributes.dateComment = value; break;

      // ********* DEPTHS *********
      case IDS.DEPTH_COMMENT: attributes.depthComment = value; break;
      case IDS.WD_FT: attributes.wdFt = Number(value); break;
      case IDS.WD_M: attributes.wdFt = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;
      case IDS.PTD_FT: attributes.ptdFt = Number(value); break;
      case IDS.PTD_M: attributes.ptdFt = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;
      case IDS.TMD_FT: attributes.tmdFt = Number(value); break;
      case IDS.TMD_M: attributes.tmdFt = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;
      case IDS.TVD_SS_FT: attributes.tvdssFt = Number(value); break;
      case IDS.TVD_SS_M: attributes.tvdssFt = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;
      case IDS.BHF_ID: attributes.bhfId = Number(value); break;
      case IDS.LOCATION_PROVENACE_ID: attributes.locationProvenaceId = Number(value); break;

      // ********* WELL COST *********
      case IDS.RIG_ID: attributes.rigId = Number(value); break;
      case IDS.ELEVATION_TYPE_ID: attributes.elevationTypeId = Number(value); break;
      case IDS.ELEVATION_FT: attributes.elevationFt = Number(value); break;
      case IDS.ELEVATION_M: attributes.elevationFt = unitConversions.Convert(unitConversions.metersToFeet, Number(value)); break;
      case IDS.RIG_RATE_DPD: attributes.rigRateDpd = Number(value); break;
      case IDS.RIG_UPLIFT: attributes.rigUplift = Number(value); break;
      case IDS.MANUAL_WELL_COST_USDMM: attributes.manualWellCostUSDMM = Number(value); break;
      case IDS.COST_SOURCE_ID: attributes.costSourceId = Number(value); break;
      case IDS.COST_SOURCE_DESCRIPTION: attributes.costSourceDescription = value; break;

      // ********* GEOLOGY *********
      case IDS.PRIMARY_OBJECTIVE_ID: attributes.primaryObjectiveId = Number(value); break;
      case IDS.SECONDARY_OBJECTIVE_ID: attributes.secondaryObjectiveId = Number(value); break;
      case IDS.RESERVOIR_CONDITION_ID: attributes.reservoirConditionId = Number(value); break;

      // ********* OPPORTUNITY *********
      case IDS.COMMITMENT_ID: attributes.commitmentId = Number(value); break;
      case IDS.OPPORTUNITY: attributes.opportunity = value; break;
      case IDS.DEAL_ID: attributes.dealId = Number(value); break;

      default: throw new Error('Id ' + id + ' is not supported in AttributesComponent.valueChanged');
    }
    updateAttributes(attributes)
    return true;
  };



  const sections = getSections(attributes, lookups, valueChanged, lookupsFiltered);

  return <PropertyGridComponent sections={sections} readOnly={!editing} />;
};

LeftPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  attributes: PropTypes.instanceOf(Attributes).isRequired,
  updateAttributes: PropTypes.func.isRequired,
  lookupsFiltered: PropTypes.instanceOf(LookupResult).isRequired
};

export default LeftPanelComponent;
