import moment from 'moment';
import { Issue } from '../../../common/models/issue';
import { EditLock } from '../../../common/models/edit-lock';
import { GMatrixGenericComment } from '../../../common/models/generic-comment';
import { ChartData } from '../../../shared/components/generic-chart/chart-data'
import { assignColours } from '../../../shared/helpers/array-color-helper'
import { CircleModel } from '../../../shared/helpers/leaflet/models/circle-model';
import { MapShapeBorder } from '../../../shared/scss/app-colors';

class HubField {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to HubField.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to HubField.ctor'); }
      if (typeof obj.entityId !== 'number') { throw new Error('Invalid obj.entityId param supplied to HubField.ctor'); }
      if (typeof obj.fieldName !== 'string') { throw new Error('Invalid obj.fieldName param supplied to HubField.ctor'); }
      if (typeof obj.resourceStatus !== 'string') { throw new Error('Invalid obj.resourceStatus param supplied to HubField.ctor'); }
      if (typeof obj.resourceStatusId !== 'number') { throw new Error('Invalid obj.resourceStatusId param supplied to HubField.ctor'); }
      if (typeof obj.allowAdd !== 'boolean') { throw new Error('Invalid obj.allowAdd param supplied to HubField.ctor'); }
      if (typeof obj.allowRemove !== 'boolean') { throw new Error('Invalid obj.allowRemove param supplied to HubField.ctor'); }
      if (typeof obj.status !== 'number') { throw new Error('Invalid obj.status param supplied to HubField.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.entityId = obj === null ? 0 : obj.entityId;
    this.fieldName = obj === null ? '' : obj.fieldName;
    this.resourceStatus = obj === null ? '' : obj.resourceStatus;
    this.resourceStatusId = obj === null ? 0 : obj.resourceStatusId;
    this.allowAdd = obj === null ? true : obj.allowAdd;
    this.allowRemove = obj === null ? true : obj.allowRemove;
    this.status = obj === null ? 0 : obj.status;
  }
}

class Attributes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Attributes.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to Attributes.ctor'); }
      if (typeof obj.displayName !== 'string') { throw new Error('Invalid obj.displayName param supplied to Attributes.ctor'); }
      if (typeof obj.hubName !== 'string') { throw new Error('Invalid obj.hubName param supplied to Attributes.ctor'); }
      if (typeof obj.newHubName !== 'string') { throw new Error('Invalid obj.newHubName param supplied to Attributes.ctor'); }
      if (typeof obj.originalHubName !== 'string') { throw new Error('Invalid obj.originalHubName param supplied to Attributes.ctor'); }
      if (obj.blockId !== null && typeof obj.blockId !== 'number') { throw new Error('Invalid obj.blockId param supplied to Attributes.ctor'); }
      if (typeof obj.blockNo !== 'string') { throw new Error('Invalid obj.blockNo param supplied to Attributes.ctor'); }
      if (obj.primeResourceId !== null && typeof obj.primeResourceId !== 'number') { throw new Error('Invalid obj.primeResourceId param supplied to Attributes.ctor'); }
      if (typeof obj.hubFluidTypeId !== 'number') { throw new Error('Invalid obj.hubFluidTypeId param supplied to Attributes.ctor'); }
      if (typeof obj.hubTypeId !== 'number') { throw new Error('Invalid obj.hubTypeId param supplied to Attributes.ctor'); }
      if (obj.oilExportTypeId !== null && typeof obj.oilExportTypeId !== 'number') { throw new Error('Invalid obj.oilExportTypeId param supplied to Attributes.ctor'); }
      if (obj.gasExportTypeId !== null && typeof obj.gasExportTypeId !== 'number') { throw new Error('Invalid obj.gasExportTypeId param supplied to Attributes.ctor'); }
      if (obj.oilTerminalId !== null && typeof obj.oilTerminalId !== 'number') { throw new Error('Invalid obj.oilTerminalId param supplied to Attributes.ctor'); }
      if (obj.gasTerminalId !== null && typeof obj.gasTerminalId !== 'number') { throw new Error('Invalid obj.gasTerminalId param supplied to Attributes.ctor'); }
      if (obj.oilPipelineId !== null && typeof obj.oilPipelineId !== 'number') { throw new Error('Invalid obj.oilPipelineId param supplied to Attributes.ctor'); }
      if (obj.gasPipelineId !== null && typeof obj.gasPipelineId !== 'number') { throw new Error('Invalid obj.gasPipelineId param supplied to Attributes.ctor'); }
      if (obj.operatorId !== null && typeof obj.operatorId !== 'number') { throw new Error('Invalid obj.operatorId param supplied to Attributes.ctor'); }
      if (typeof obj.actualOilCapacityBopd !== 'number') { throw new Error('Invalid obj.actualOilCapacityBopd param supplied to Attributes.ctor'); }
      if (typeof obj.actualGasCapacityMmscfd !== 'number') { throw new Error('Invalid obj.actualGasCapacityMmscfd param supplied to Attributes.ctor'); }
      if (typeof obj.oilCapacityAvailable !== 'boolean') { throw new Error('Invalid obj.oilCapacityAvailable param supplied to Attributes.ctor'); }
      if (typeof obj.gasCapacityAvailable !== 'boolean') { throw new Error('Invalid obj.gasCapacityAvailable param supplied to Attributes.ctor'); }
      if (typeof obj.hubCaptureRadiusKm !== 'number') { throw new Error('Invalid obj.hubCaptureRadiusKm param supplied to Attributes.ctor'); }
      if (typeof obj.dmsLatDegrees !== 'number') { throw new Error('Invalid obj.dmsLatDegrees param supplied to Attributes.ctor'); }
      if (typeof obj.dmsLatMinutes !== 'number') { throw new Error('Invalid obj.dmsLatMinutes param supplied to Attributes.ctor'); }
      if (typeof obj.dmsLatSeconds !== 'number') { throw new Error('Invalid obj.dmsLatSeconds param supplied to Attributes.ctor'); }
      if (typeof obj.dmsNorthing !== 'string') { throw new Error('Invalid obj.dmsNorthing param supplied to Attributes.ctor'); }
      if (typeof obj.dmsLongDegrees !== 'number') { throw new Error('Invalid obj.dmsLongDegrees param supplied to Attributes.ctor'); }
      if (typeof obj.dmsLongMinutes !== 'number') { throw new Error('Invalid obj.dmsLongMinutes param supplied to Attributes.ctor'); }
      if (typeof obj.dmsLongSeconds !== 'number') { throw new Error('Invalid obj.dmsLongSeconds param supplied to Attributes.ctor'); }
      if (typeof obj.dmsEasting !== 'string') { throw new Error('Invalid obj.dmsEasting param supplied to Attributes.ctor'); }
      if (typeof obj.epsgCode !== 'number') { throw new Error('Invalid obj.epsgCode param supplied to Attributes.ctor'); }
      if (obj.stratLevelId !== null && typeof obj.stratLevelId !== 'number') { throw new Error('Invalid obj.stratLevelId param supplied to Attributes.ctor'); }
      if (typeof obj.decimalLatitude !== 'number') { throw new Error('Invalid obj.decimalLatitude param supplied to Attributes.ctor'); }
      if (typeof obj.decimalLongitude !== 'number') { throw new Error('Invalid obj.decimalLongitude param supplied to Attributes.ctor'); }
      if (!Array.isArray(obj.comments)) { throw new Error('Invalid obj.comments param supplied to Attributes.ctor'); }

      if (typeof obj.hubStatusId !== 'number') { throw new Error('Invalid obj.hubStatusId param supplied to Attributes.ctor'); }
      if (typeof obj.hphtProcessingCapabiltiy !== 'boolean') { throw new Error('Invalid obj.hphtProcessingCapabiltiy param supplied to Attributes.ctor'); }
      if (typeof obj.platformCount !== 'number') { throw new Error('Invalid obj.platformCount param supplied to Attributes.ctor'); }
      if (typeof obj.processingPlatformCount !== 'number') { throw new Error('Invalid obj.processingPlatformCount param supplied to Attributes.ctor'); }
      if (typeof obj.producedWaterCapacityBpd !== 'number') { throw new Error('Invalid obj.producedWaterCapacityBpd param supplied to Attributes.ctor'); }
      if (typeof obj.waterInjectionHandlingBpd !== 'number') { throw new Error('Invalid obj.waterInjectionHandlingBpd param supplied to Attributes.ctor'); }
      if (obj.hubDescriptionDate !== null && typeof obj.hubDescriptionDate !== 'string') { throw new Error('Invalid obj.hubDescriptionDate param supplied to Attributes.ctor'); }
      if (obj.hubDescription !== null && typeof obj.hubDescription !== 'string') { throw new Error('Invalid obj.hubDescription param supplied to Attributes.ctor'); }
      if (obj.productionByField !== null && typeof obj.productionByField !== 'object') { throw new Error('Invalid obj.productionByField param supplied to Attributes.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.displayName = obj === null ? '' : obj.displayName;
    this.hubName = obj === null ? '' : obj.hubName;
    this.newHubName = obj === null ? '' : obj.newHubName;
    this.originalHubName = obj === null ? 0 : obj.originalHubName;
    this.blockId = obj === null ? null : obj.blockId;
    this.blockNo = obj === null ? '' : obj.blockNo;
    this.primeResourceId = obj === null ? null : obj.primeResourceId;
    this.hubFluidTypeId = obj === null ? null : obj.hubFluidTypeId;
    this.hubTypeId = obj === null ? null : obj.hubTypeId;
    this.oilExportTypeId = obj === null ? null : obj.oilExportTypeId;
    this.gasExportTypeId = obj === null ? null : obj.gasExportTypeId;
    this.oilTerminalId = obj === null ? null : obj.oilTerminalId;
    this.gasTerminalId = obj === null ? null : obj.gasTerminalId;
    this.oilPipelineId = obj === null ? null : obj.oilPipelineId;
    this.gasPipelineId = obj === null ? null : obj.gasPipelineId;
    this.operatorId = obj === null ? null : obj.operatorId;
    this.actualOilCapacityBopd = obj === null ? 0 : obj.actualOilCapacityBopd;
    this.actualGasCapacityMmscfd = obj === null ? 0 : obj.actualGasCapacityMmscfd;
    this.oilCapacityAvailable = obj === null ? false : obj.oilCapacityAvailable;
    this.gasCapacityAvailable = obj === null ? false : obj.gasCapacityAvailable;
    this.hubCaptureRadiusKm = obj === null ? 0 : obj.hubCaptureRadiusKm;
    this.dmsLatDegrees = obj === null ? 0 : obj.dmsLatDegrees;
    this.dmsLatMinutes = obj === null ? 0 : obj.dmsLatMinutes;
    this.dmsLatSeconds = obj === null ? 0 : obj.dmsLatSeconds;
    this.dmsNorthing = obj === null ? '' : obj.dmsNorthing;
    this.dmsLongDegrees = obj === null ? 0 : obj.dmsLongDegrees;
    this.dmsLongMinutes = obj === null ? 0 : obj.dmsLongMinutes;
    this.dmsLongSeconds = obj === null ? 0 : obj.dmsLongSeconds;
    this.dmsEasting = obj === null ? '' : obj.dmsEasting;
    this.epsgCode = obj === null ? null : obj.epsgCode;
    this.stratLevelId = obj === null || obj.stratLevelId === null ? null : obj.stratLevelId;
    this.decimalLatitude = obj === null ? 0 : obj.decimalLatitude;
    this.decimalLongitude = obj === null ? 0 : obj.decimalLongitude;

    this.hubStatusId = obj === null ? 0 : obj.hubStatusId;
    this.hphtProcessingCapabiltiy = obj === null ? false : obj.hphtProcessingCapabiltiy;
    this.platformCount = obj === null ? 0 : obj.platformCount;
    this.processingPlatformCount = obj === null ? 0 : obj.processingPlatformCount;
    this.producedWaterCapacityBpd = obj === null ? 0 : obj.producedWaterCapacityBpd;
    this.waterInjectionHandlingBpd = obj === null ? 0 : obj.waterInjectionHandlingBpd;
    this.hubDescriptionDate = obj === null || obj.hubDescriptionDate === null ? null : moment(obj.hubDescriptionDate);
    this.hubDescription = obj === null ? null : obj.hubDescription;
    this.shape = obj === null || obj.shape === null ? null : new CircleModel(obj.shape.y, obj.shape.x, { borderColor: MapShapeBorder, fillColor: 'transparent', radius: 900 });

    this.comments = (obj === null || obj.comments === null) ? [new GMatrixGenericComment(null)] : obj.comments.map(item => new GMatrixGenericComment(item));
    this.fields = (obj === null || obj.fields === null) ? [new HubField(null)] : obj.fields.map(item => new HubField(item));

    this.status = obj === null ? '' : obj.status;
    this.originalStatus = obj === null ? '' : obj.originalStatus;

    this.productionByField = null;
    if (obj !== null && obj.productionByField !== null) {
      this.productionByField = new ChartData(obj.productionByField);
      if (this.productionByField !== null) {
        assignColours(this.productionByField.yVal, 'color');
      }
    }
  }
}

export class Result {
  attributes = new Attributes(null);
  issues = [new Issue()];
  editLock = new EditLock();
  alert = '';

  constructor(obj) {
    obj && Object.assign(this, obj);
    this.attributes = obj === null || obj === undefined ? new Attributes() : new Attributes(obj.attributes);
    this.issues = obj === null || obj === undefined ? [new Issue()] : obj.issues.map(item => new Issue(item));
    this.editLock = obj === null || obj === undefined ? new EditLock() : new EditLock(obj.editLock);
  }
}

export { Issue, EditLock, Attributes, HubField };
