const IssueType = {
  none: -1,
  error: 0,
  warning: 1,
  values: () => Object.values(IssueType),
  displayName: (issueType) => {
    switch (issueType) {
      case IssueType.none:
        return '';
      case IssueType.error:
        return 'Error';
      case IssueType.warning:
        return 'Warning';
      default:
        throw new Error('IssueType "' + issueType + '" is not supported in "IssueType.displayName"');
    }
  },
  image: (issueType) => {
    const url = window.location.href;
    switch (issueType) {
      case IssueType.none:
        return url + 'images/info.png';
      case IssueType.error:
        return url + 'images/error.png';
      case IssueType.warning:
        return url + 'images/warning.png';
      default:
        throw new Error('IssueType "' + issueType + '" is not supported in "IssueType.image"');
    }
  }
};
Object.freeze(IssueType);

export { IssueType };
