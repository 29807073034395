//import L from 'leaflet';

//function getRandomInt() {
//  return Math.floor(Math.random() * 200000) + 1;
//}

//const TOPOGRAPHY_LAYER_URL = 'https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/mapserv?randomint=' + getRandomInt();

//const _getAttributionString = (attribution) => {
//  if (!Array.isArray(attribution)) {
//    attribution = [
//      { name: 'OGA', title: 'UK Oil & Gas Authority', url: 'https://www.ogauthority.co.uk/' },
//      { name: 'DCCAE', title: 'Ireland Department of Communications, Climate Action & Environment', url: 'http://www.dccae.gov.ie/en-ie/natural-resources/topics/Oil-Gas-Exploration-Production/Pages/home.aspx' },
//      { name: 'NPD', title: 'Norwegian Petroleum Directorate', url: 'http://www.npd.no/en/' },
//      { name: 'WGEG', title: 'Westwood Global Energy Group Ltd', url: 'https://www.westwoodenergy.com/terms-and-conditions' },
//      { name: 'GEBCO', title: 'Imagery reproduced from the GEBCO_2014 Grid, version 20150318, www.gebco.net', url: 'http://www.gebco.net/data_and_products/gebco_web_services/web_map_service/' }
//    ];
//  }

//  const attrs = attribution.map(att => '<a target="_blank" title="' + att.title + '" href="' + att.url + '">' + att.name + '</a>');
//  const result = attrs.join(' | ', attrs);
//  return result;
//};

//const createTopographyLayer = (tileSize, attribution) => {
//  if (typeof tileSize !== 'number') {
//    throw new Error('Invalid \'tileSize\' param supplied to \'createTopographyLayer\'');
//  }

//  //TODO: validate attribution
//  const options = {
//    attribution: _getAttributionString(attribution),
//    tileSize: tileSize,
//    layers: 'gebco_latest',
//    format: 'image/png',
//    transparent: true,
//    opacity: 0.5,
//    maxZoom: 16,
//    noWrap: true
//  };
//  const result = L.tileLayer.wms(TOPOGRAPHY_LAYER_URL, options);
//  return result;
//};

//export { createTopographyLayer };

import L from 'leaflet';
import "../extenders/tilelayer.wms.canvas";

const TOPOGRAPHY_LAYER_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

const _getAttributionString = (attribution) => {
  if (!Array.isArray(attribution)) {
    attribution = [
      { name: 'NSTA', title: 'North Sea Transition Authority', url: 'https://www.nstauthority.co.uk/' },
      { name: 'DCCAE', title: 'Ireland Department of Communications, Climate Action & Environment', url: 'http://www.dccae.gov.ie/en-ie/natural-resources/topics/Oil-Gas-Exploration-Production/Pages/home.aspx' },
      { name: 'NPD', title: 'Norwegian Petroleum Directorate', url: 'http://www.npd.no/en/' },
      { name: 'WGEG', title: 'Westwood Global Energy Group Ltd', url: '../TermsConditions' },
      { name: '&copy; OpenStreetMap contributors', title: 'OpenStreetMap', url: 'https://www.openstreetmap.org/copyright' }
    ];
  }

  const attrs = attribution.map(att => '<a target="_blank" title="' + att.title + '" href="' + att.url + '">' + att.name + '</a>');
  const result = attrs.join(' | ', attrs);
  return result;
};

const createTopographyLayer = (attribution = undefined) => {
  const options = {
    attribution: _getAttributionString(attribution),
    maxZoom: 19,
    noWrap: true,
    crossOrigin: true
  };

  const result = L.tileLayer.wms.canvas(TOPOGRAPHY_LAYER_URL, options);
  result._isTopographyLayer = true;
  return result;
};

export { createTopographyLayer };
