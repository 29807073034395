import React from "react";
import PropTypes from 'prop-types';
import { EndPointAttributes } from './models/result';
//import { MessageBoxGlobal } from '../../common/globals/globals';
import { Button, Input } from 'reactstrap';
import { URLIDS } from './hermes-management-view';

const HermesApiDetailsComponent = (props) => {
  const { details, editting, endPointTypes } = props;

  if (props.details === null) return null;

  const inputValueChanged = (e) => {
    let id = Number(e.target.id);
    props.onChange(e.target.value, id);
  }

  const title = "EndPoint Details";
  return (
    <div className="url-main">
      <h4 className="title">{title}</h4>
      <div><span className="url-title">URL</span><Input id={URLIDS.ENDPOINTURL} className="url" value={details.endPointURL} type="text" disabled={!editting} onChange={(e) => inputValueChanged(e)} /></div>
      <div>
        <span className="url-type">URL Type</span>
        <select
          id={URLIDS.ENDPOINTTYPEID}
          disabled={!editting}
          value={details.endPointTypeId === 0 ? -99 : details.endPointTypeId}
          onChange={(e) => inputValueChanged(e)}
        >
          {details.endPointTypeId === 0 ? <option key={-99} value={-99}>{'- Select -'}</option> : null}
          {props.endPointTypes.map((endPointType) => <option key={endPointType['key']} value={endPointType['key']}>{endPointType['value']}</option>)}
        </select>

      </div>
      <div><span className="url-uid">UID Attribute</span><Input id={URLIDS.UIDATTRIBUTE} className="uid" value={details.uidAttribute} type="text" disabled={!editting} onChange={(e) => inputValueChanged(e)} /></div>
      <div><span className="url-name">Name Attribute</span><Input id={URLIDS.NAMEATTRIBUTE} className="name" value={details.nameAttribute} type="text" disabled={!editting} onChange={(e) => inputValueChanged(e)} /></div>
      <div><span className="url-json">JSON Path</span><Input id={URLIDS.JSONPATH} className="json" value={details.jsonPath} type="text" disabled={!editting} onChange={(e) => inputValueChanged(e)} /></div>
      <div><span className="url-exclude">Attribute Exclude List</span><Input id={URLIDS.ATTRIBUTEEXCLUDELIST} className="exclude" value={details.attributeExcludeList} type="text" disabled={!editting} onChange={(e) => inputValueChanged(e)} /></div>
      <div><span className="url-path">Export Path</span><Input id={URLIDS.EXPORTPATH} className="path" value={details.exportPath} type="text" disabled={!editting} onChange={(e) => inputValueChanged(e)} /></div>
      <div><span className="url-file">Export Filename</span><Input id={URLIDS.EXPORTFILENAME} className="file" value={details.exportFilename} type="text" disabled={!editting} onChange={(e) => inputValueChanged(e)} /></div>
      <div>
        <span className="url-active">Active</span>
        <select
          id={URLIDS.ACTIVE}
          disabled={!editting}
          value={details.active}
          onChange={(e) => inputValueChanged(e)}
        >
          <option key={1} value={true}>{'Yes'}</option>
          <option key={2} value={false}>{'No'}</option>
        </select>
      </div>
      <div><span className="url-teams">Teams URL</span><Input id={URLIDS.TEAMSWEBHOOKURL} className="teams" value={details.teamsWebhookURL} type="text" disabled={!editting} onChange={(e) => inputValueChanged(e)} /></div>
      <div>
        <span className="url-allow-empty-json">Allow Empty Json</span>
        <select
          id={URLIDS.ALLOWEMPTYJSON}
          disabled={!editting}
          value={details.allowEmptyJson}
          onChange={(e) => inputValueChanged(e)}
        >
          <option key={1} value={true}>{'Yes'}</option>
          <option key={2} value={false}>{'No'}</option>
        </select>
      </div>
    </div>
  );
};

HermesApiDetailsComponent.propTypes = {
  //messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  details: PropTypes.instanceOf(EndPointAttributes).isRequired,
  onChange: PropTypes.func.isRequired,
  editting: PropTypes.bool.isRequired,
  endPointTypes: PropTypes.array.isRequired
};

export default HermesApiDetailsComponent;
