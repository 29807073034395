import React from 'react';
import PropTypes from 'prop-types';

import './query-params-component.scss';
import { QueryParams } from '../models/query-params';

const QueryParamsComponent = (props) => {
  const { queryParams } = props;

  let str = 'Please';
  if (queryParams.param1IsSet || queryParams.param2IsSet) {
    str += ' enter';
  }
  if (queryParams.param1IsSet) {
    str += ' ' + queryParams.param1DisplayName;
  }
  if (queryParams.param1IsSet && queryParams.param2IsSet) {
    str += ' &';
  }
  if (queryParams.param2IsSet) {
    str += ' ' + queryParams.param2DisplayName;
  }
  if (queryParams.param1IsSet || queryParams.param2IsSet) {
    str += ', then';
  }
  str += ' click Refresh.';
  //str += ' Wait.';

  str = 'Please Wait.';

  return (
    <div className="query-params-component">
      <h1>{str}</h1>
    </div>
  );
};

QueryParamsComponent.propTypes = {
  queryParams: PropTypes.instanceOf(QueryParams).isRequired
};

export default QueryParamsComponent;