import React from 'react';
import PropTypes from 'prop-types';
import { doubleHelper } from './double-helper';

// Custom Formatter component
class DoubleFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };

  static defaultProps = {
    value: null
  };

  render() {
    let decimalPlaces = this.props.decimalPlaces;
    if (this.props.dependentValues !== undefined) {
      decimalPlaces = this.props.dependentValues;
    }

    const renderedVal = this.props.value === null ? '-' : doubleHelper(this.props.value, decimalPlaces);

    return (
      <div className="editColumn">{renderedVal}</div>);
  }
}

DoubleFormatter.propTypes = {
  decimalPlaces: PropTypes.number.isRequired
};

DoubleFormatter.defaultProps = {
  decimalPlaces: 3
};

export default DoubleFormatter;