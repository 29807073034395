import React from 'react';
import PropTypes from 'prop-types';

import { HubEmissionAnnual } from '../../models/production-result';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import HubEmissionsAnnualsGridComponent from '../../../../common/components/grid/hub-emissions-annuals-grid-component';

const HubEmissionsAnnualsComponent = (props) => {

  return <HubEmissionsAnnualsGridComponent
    editing={props.editing}
    readOnly={false}
    messageBoxGlobal={props.messageBoxGlobal}
    hubEmissionAnnuals={props.hubEmissionAnnuals}
    top={props.top}
    id={props.id}
    canButtonsEnable={props.canButtonsEnable}
    startYear={props.startYear}
    endYear={props.endYear}
    editYear={props.editYear}
    enableDeclineCurve={props.enableDeclineCurve}
    handleCalculatedValues={props.handleCalculatedValues}
    activeComponent={props.activeComponent}
  />;
};

HubEmissionsAnnualsComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  editing: PropTypes.bool.isRequired,
  hubEmissionAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(HubEmissionAnnual)).isRequired,
  id: PropTypes.number,
  canButtonsEnable: PropTypes.func.isRequired,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  handleCalculatedValues: PropTypes.func.isRequired
};

export default HubEmissionsAnnualsComponent;
