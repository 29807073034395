import React from 'react';
import PropTypes from 'prop-types';

import AttributeStaticListComponent from '../../../../common/components/attribute-list/attribute-static-list-component';

import { OfficialUID } from '../../models/result';

import { RECORD_STATUS } from '../../../../common/models/record-status';
import { MessageBoxGlobal, LoadingGlobal } from '../../../../common/globals/globals';
import { PropertyGridStringValue } from '../../../../shared/components/property-grid/property-grid-component';

const IDS = {
  OFFICIALUID: 0
};
Object.freeze(IDS);

class OfficialUIDComponent extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { editing, messageBoxGlobal, officialUIDs, officialUIDActions, id } = this.props;

    const onEdit = (id, value, item) => {
      switch (id) {
        case IDS.OFFICIALUID: item.officialUID = value; break;
        default: throw new Error('Id ' + id + ' is not supported in OfficialUIDComponent.onEdit');
      }
      officialUIDActions.onEdit(item);
    };

    const getTitle = (item) => {
      const value = item.officialUID;
      return item.status === RECORD_STATUS.added && (value === undefined || value === null || value === '')
        ? '-New Official UID-'
        : value;
    };

    const items = officialUIDs.map(item => {
      return {
        label: getTitle(item),
        data: item,
        sections: [{
          label: 'Official UID',
          rows: [
            { label: 'Official UID', values: [new PropertyGridStringValue(IDS.OFFICIALUID, item.officialUID, (value, id) => onEdit(id, value, item))] }
          ]
        }]
      }
    });

    const add = () => {
      let newItem = new OfficialUID(null);
      newItem.getNew(id);

      officialUIDActions.onAdd(newItem);
    }

    return (
      <AttributeStaticListComponent
        editing={editing}
        messageBoxGlobal={messageBoxGlobal}
        items={items}
        itemDescription="officialUID"
        canAdd
        canDelete
        onAdd={add}
        onDelete={officialUIDActions.onDelete}
        onSelected={officialUIDActions.onSelected}
        onAddPosition={"start"}
      />
    );
  }
}

OfficialUIDComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  officialUIDs: PropTypes.arrayOf(PropTypes.instanceOf(OfficialUID)).isRequired,
  countryId: PropTypes.number.isRequired,
  officialUIDActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired
};

export default OfficialUIDComponent;
