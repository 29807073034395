import { Issue } from '../../../common/models/issue';
import { EditLock } from '../../../common/models/edit-lock';
import { GMatrixGenericComment } from '../../../common/models/generic-comment';
import { getDate } from '../../../shared/helpers/date-helper';

class LicenceBlock {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to LicenceBlock.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to LicenceBlock.ctor'); }
      if (typeof obj.blockName !== 'string') { throw new Error('Invalid obj.blockName param supplied to HubField.ctor'); }
      if (obj.subArea !== null && typeof obj.subArea !== 'string') { throw new Error('Invalid obj.subArea param supplied to HubField.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.blockName = obj === null ? '' : obj.blockName;
    this.subArea = obj === null || obj.subArea === null ? '' : obj.subArea;
  }
}

class Attributes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Attributes.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to Attributes.ctor'); }
      if (typeof obj.displayName !== 'string') { throw new Error('Invalid obj.displayName param supplied to Attributes.ctor'); }
      if (typeof obj.licenceRoundId !== 'number') { throw new Error('Invalid obj.licenceRoundId param supplied to Attributes.ctor'); }
      if (typeof obj.licenceNumber !== 'string') { throw new Error('Invalid obj.licenceNumber param supplied to Attributes.ctor'); }
      if (typeof obj.originalLicenceTypeId !== 'number') { throw new Error('Invalid obj.originalLicenceTypeId param supplied to Attributes.ctor'); }
      if (typeof obj.licenceTypeId !== 'number') { throw new Error('Invalid obj.licenceTypeId param supplied to Attributes.ctor'); }
      if (obj.startDate !== null && typeof obj.startDate !== 'string') { throw new Error('Invalid obj.startDate param supplied to Attributes.ctor'); }
      if (obj.endDate !== null && typeof obj.endDate !== 'string') { throw new Error('Invalid obj.endDate param supplied to Attributes.ctor'); }
      if (obj.officalUID !== null && typeof obj.officalUID !== 'string') { throw new Error('Invalid obj.officalUID param supplied to Attributes.ctor'); }
      if (obj.countryName !== null && typeof obj.countryName !== 'string') { throw new Error('Invalid obj.countryName param supplied to Attributes.ctor'); }

      if (obj.blocks !== null && !Array.isArray(obj.blocks)) { throw new Error('Invalid obj.blocks param supplied to Attributes.ctor'); }
      if (obj.comments !== null && !Array.isArray(obj.comments)) { throw new Error('Invalid obj.comments param supplied to Attributes.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.displayName = obj === null ? '' : obj.displayName;
    this.licenceRoundId = obj === null ? -1 : obj.licenceRoundId;
    this.licenceNumber = obj === null ? '' : obj.licenceNumber;
    this.originalLicenceTypeId = obj === null ? -1 : obj.originalLicenceTypeId;
    this.licenceTypeId = obj === null ? -1 : obj.licenceTypeId;
    this.officalUID = obj === null || obj.officalUID === null ? '' : obj.officalUID;
    this.countryName = obj === null || obj.countryName === null ? '' : obj.countryName;

    this.startDate = obj === null ? null : getDate(obj.startDate);
    this.endDate = obj === null ? null : getDate(obj.endDate);

    this.blocks = (obj === null || obj.blocks === null) ? [new LicenceBlock(null)] : obj.blocks.map(item => new LicenceBlock(item));
    this.comments = (obj === null || obj.comments === null) ? [new GMatrixGenericComment(null)] : obj.comments.map(item => new GMatrixGenericComment(item));

    this.status = obj === null ? '' : obj.status;
    this.originalStatus = obj === null ? '' : obj.originalStatus;
  }
}

export class Result {
  constructor(obj) {
    obj && Object.assign(this, obj);
    this.attributes = obj === null || obj === undefined ? new Attributes(null) : new Attributes(obj.attributes);
    this.issues = obj === null || obj === undefined ? [new Issue()] : obj.issues.map(item => new Issue(item));
    this.editLock = obj === null || obj === undefined ? new EditLock() : new EditLock(obj.editLock);
  }
}

export { Issue, EditLock, Attributes, LicenceBlock };