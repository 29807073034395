import React from 'react';
import DateInputMaskComponent, { moment } from '../date-picker/date-input-mask-component';
import { formatDate } from '../../helpers/date-helper';
import TextBoxWrapperComponent from './text-box-wrapper-component';
import TextBoxWrapperDoubleComponent from './text-box-wrapper-double-component';
import './property-grid-values.scss';

const LABEL_CLASS_NAME = 'label';
const READ_ONLY_LABEL_CLASS_NAME = LABEL_CLASS_NAME + ' read-only';

class PropertyGridBaseValue {
  constructor(id, value, onChange) {
    if (typeof id !== 'number') {
      throw new Error('Invalid id param supplied to PropertyGridBaseValue.ctor');
    }
    if (onChange !== undefined && onChange !== null && typeof onChange !== 'function') {
      throw new Error('Invalid onChange param supplied to PropertyGridBaseValue.ctor');
    }

    this.id = id;
    this.value = value;
    this.onChange = onChange;
  }

  get canEdit() {
    return typeof this.onChange === 'function';
  }

  getLabel() {
    const className = this.canEdit
      ? LABEL_CLASS_NAME
      : READ_ONLY_LABEL_CLASS_NAME;
    return <div className={className}>{this.getLabelText()}</div>;
  }

  getLabelText() {
    return this.value === undefined || this.value === null ? '' : this.value.toString();
  }

  fireOnChange(newValue) {
    if (typeof this.onChange === 'function' && this.value !== newValue) {
      this.onChange(newValue, this.id);
    }
  }
}

class PropertyGridStringValue extends PropertyGridBaseValue {
  constructor(id, value, onChange) {
    super(id, value === undefined || value === null ? '' : value, onChange);
  }

  getLabel() {
    const className = this.canEdit
      ? LABEL_CLASS_NAME
      : READ_ONLY_LABEL_CLASS_NAME;
    return <div className={className} title={this.value}>{this.getLabelText()}</div>;
  }

  getControl() {
    return (
      <TextBoxWrapperComponent
        type="text"
        value={this.value}
        onChange={newValue => this.fireOnChange(newValue)}
      />
    );
  }
}

class PropertyGridLargeStringValue extends PropertyGridBaseValue {
  constructor(id, value, onChange) {
    super(id, value === undefined || value === null ? '' : value, onChange);
  }

  getLabel() {
    const className = this.canEdit
      ? LABEL_CLASS_NAME
      : READ_ONLY_LABEL_CLASS_NAME;
    //return <div className={className} title={this.value}>{this.getLabelText()}</div>;
    return <div style={{ display: 'inline-block', overflow: 'auto', height: '100%', whiteSpace: 'pre-wrap' }} className={className} title={this.getLabelText()}>{this.getLabelText()}</div>;

  }

  getControl() {
    return (
      <textarea style={{ width: '100%', minHeight: '100px' }} value={this.value} onChange={newValue => this.fireOnChange(newValue)} />
    );
  }
}

class PropertyGridDateValue extends PropertyGridBaseValue {
  constructor(id, value, onChange, popperPlacement) {
    super(id, moment(value).isValid() ? moment(value).toDate() : null, onChange);
    this.popperPlacement = popperPlacement;
  }

  getLabelText() {
    return this.value === null ? null : moment(this.value).format("DD/MM/YYYY");
  }

  getControl() {
    const theDate = this.value === null ? '' : moment(this.value).format("DD/MM/YYYY");

    return (
      <DateInputMaskComponent
        value={theDate}
        onChange={e => this.fireOnChange(e)}
        keepCharPositions
      />
    );
  }
}

class PropertyGridDateValueUTC extends PropertyGridBaseValue {
  constructor(id, value, onChange, popperPlacement) {
    super(id, value, onChange);
    this.popperPlacement = popperPlacement;
  }

  getLabelText() {
    let theDate = this.value === null ? null : formatDate(this.value);
    return theDate;
  }

  getControl() {
    let theDate = formatDate(this.value);
    if (theDate === null) theDate = '';

    return (
      <DateInputMaskComponent
        value={theDate}
        onChange={e => this.fireOnChange(e)}
        keepCharPositions
      />
    );
  }
}

class PropertyGridNumberValue extends PropertyGridBaseValue {
  constructor(id, numberType, value, onChange, precision, min, max) {
    super(id, Number(value), onChange);

    precision = typeof precision === 'number' ? precision : 0;
    this.value = this.value.toFixed(precision);
    this.step = precision > 0 ? '0.' + '0'.repeat(precision - 1) + '1' : undefined;
    this.min = typeof min === 'number' ? min : undefined;
    this.max = typeof max === 'number' ? max : undefined;
    this.precision = precision;
    this.numberType = numberType;
  }

  fireOnChange(newValue) {
    super.fireOnChange(Number(newValue));
  }

  getControl() {
    return (
      <TextBoxWrapperComponent
        type="number"
        value={this.value}
        onChange={newValue => this.fireOnChange(newValue)}
        step={this.step}
        min={this.min}
        max={this.max}
        precision={this.precision}
        numberType={this.numberType}
      />
    );
  }
}

class PropertyGridPercentageValue extends PropertyGridNumberValue {
  getLabelText() {
    return super.getLabelText() + '%';
  }
}

class PropertyGridSelectValue extends PropertyGridBaseValue {
  constructor(id, value, onChange, optionKey, optionValue, options, showNull = false) {
    super(id, value, onChange);

    this.optionKey = optionKey;
    this.optionValue = optionValue;
    this.options = options;
    this.showNull = showNull;
  }


  getSelectedOption() {
    return Array.isArray(this.options)
      ? this.options.find(opt => opt[this.optionKey] === this.value)
      : undefined;
  }

  getLabelText() {
    const selectedOption = this.getSelectedOption();
    return !selectedOption
      ? undefined
      : selectedOption[this.optionValue];
  }

  getControl() {
    const chooseOption = !this.showNull && !this.getSelectedOption()
      ? <option value={-999999}>{'-Choose-'}</option>
      : null;

    const chooseNull = this.showNull
      ? <option key={-99} value={-99}>{'-Not Applicable-'}</option>
      : null;

    return (
      <select
        value={this.value === null ? '' : this.value}
        onChange={(event) => this.fireOnChange(event.target.value)}
      >
        {chooseNull}
        {chooseOption}
        {this.options.map((option) => (
          <option
            key={option[this.optionKey]}
            value={option[this.optionKey]}
          >
            {option[this.optionValue]}
          </option>
        ))}
      </select>
    );
  }
}

class PropertyGridBooleanValue extends PropertyGridSelectValue {
  constructor(id, value, onChange, trueString = 'Yes', falseString = 'No') {
    super(id, value === true, onChange, 'key', 'value', [{ key: true, value: trueString }, { key: false, value: falseString }]);

    this.trueString = trueString;
    this.falseString = falseString;
  }

  getLabelText() {
    return this.value ? this.trueString : this.falseString;
  }

  fireOnChange(value) {
    super.fireOnChange(value === true.toString());
  }
}

class PropertyGridNumberValueDouble extends PropertyGridSelectValue {
  constructor(primaryId, secondaryId, primaryValue, secondaryValue, primaryUnitString, secondaryUnitString, numberType, onChange, precision, isNarrow, min, max) {
    super(primaryId, Number(primaryValue), onChange);
    if (typeof primaryId !== 'number') {
      throw new Error('Invalid primaryId param supplied to PropertyGridNumberValueDouble.ctor');
    }
    if (typeof secondaryId !== 'number') {
      throw new Error('Invalid secondaryId param supplied to PropertyGridNumberValueDouble.ctor');
    }
    if (onChange !== undefined && onChange !== null && typeof onChange !== 'function') {
      throw new Error('Invalid onChange param supplied to PropertyGridNumberValueDouble.ctor');
    }

    precision = typeof precision === 'number' ? precision : 0;

    this.primaryId = primaryId;
    this.secondaryId = secondaryId;
    this.onChange = onChange;
    this.primaryValue = Number(primaryValue).toFixed(precision);
    this.secondaryValue = Number(secondaryValue).toFixed(precision);
    this.step = precision > 0 ? '0.' + '0'.repeat(precision - 1) + '1' : undefined;
    this.min = typeof min === 'number' ? min : undefined;
    this.max = typeof max === 'number' ? max : undefined;
    this.precision = precision;
    this.numberType = numberType;
    this.primaryUnitString = primaryUnitString;
    this.secondaryUnitString = secondaryUnitString;
    this.isNarrow = typeof isNarrow === 'boolean' ? isNarrow : false;
  }

  get canEdit() {
    return typeof this.onChange === 'function';
  }

  getLabel() {
    const className = this.canEdit
      ? LABEL_CLASS_NAME
      : READ_ONLY_LABEL_CLASS_NAME;
    return <div className={className}>{this.getLabelText()}</div>;
  }

  getLabelText() {
    let primaryText = this.primaryValue === undefined || this.primaryValue === null ? '' : this.primaryValue.toString();
    let secondaryText = this.secondaryValue === undefined || this.secondaryValue === null ? '' : this.secondaryValue.toString()

    let content =
      <div className="double-input-label">
        <div className="contents">
          <span className="value">{primaryText}</span>
          <span className="unit">{this.primaryUnitString}</span>
        </div>
        <div className="contents">
          <span className="value">{secondaryText}</span>
          <span className="unit">{this.secondaryUnitString}</span>
        </div>
      </div>;

    return content;
  }

  fireOnPrimaryChange(newValue) {
    if (typeof this.onChange === 'function' && this.primaryValue !== newValue) {
      this.onChange(newValue, this.primaryId);
    }
  }

  fireOnSecondaryChange(newValue) {
    if (typeof this.onChange === 'function' && this.secondaryValue !== newValue) {
      this.onChange(newValue, this.secondaryId);
    }
  }

  getControl() {
    return (
      <TextBoxWrapperDoubleComponent
        type="number"
        primaryValue={this.primaryValue}
        secondaryValue={this.secondaryValue}
        onPrimaryChange={newValue => this.fireOnPrimaryChange(newValue)}
        onSecondaryChange={newValue => this.fireOnSecondaryChange(newValue)}
        step={this.step}
        min={this.min}
        max={this.max}
        precision={this.precision}
        numberType={this.numberType}
        primaryUnitString={this.primaryUnitString}
        secondaryUnitString={this.secondaryUnitString}
        isNarrow={this.isNarrow}
      />
    );
  }
}

export { LABEL_CLASS_NAME, PropertyGridBaseValue, PropertyGridStringValue, PropertyGridDateValueUTC, PropertyGridDateValue, PropertyGridNumberValue, PropertyGridNumberValueDouble, PropertyGridPercentageValue, PropertyGridSelectValue, PropertyGridBooleanValue, PropertyGridLargeStringValue };
