import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import './top-panel-component.scss';
import { EditGridsAction } from './edit-grids-action';
import HubAnnualsComponent from './hub-annuals-component';
import ResourceAnnualsComponent from './resource-annuals-component';
import HubEmissionsAnnualsComponent from './hub-emissions-annuals-component';
import { ResourceAnnual, HubAnnual, HubEmissionAnnual } from '../../models/production-result';
import { ProductionUpdateType } from '../../models/production-update-type';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import ToolbarPanelComponent from '../../../../shared/components/toolbar-panel/toolbar-panel-component';
import ChangeYearsComponent from './change-years-component';
import { MessageBoxButton, MessageBoxButtons } from '../../../../shared/components/message-box/message-box-classes';

const TopPanelComponent = (props) => {
  const { editing, showResourceGrid, showEmissionsGrid, resourceAnnuals, hubAnnuals, hubEmissionAnnuals, currentResourceID, allResourceAnnuals, messageBoxGlobal, canButtonsEnable, handleCalculatedValues } = props;

  var preventScrollToTop = false;
  var numberOfYears = 0;

  const getTitle = (action) => {
    switch (action) {
      case EditGridsAction.addStart: return 'Add start year';
      case EditGridsAction.deleteStart: return 'Delete start year';
      case EditGridsAction.moveStart: return 'Move start year';
      case EditGridsAction.refresh: return 'Refresh';
      case EditGridsAction.moveEnd: return 'Move end year';
      case EditGridsAction.deleteEnd: return 'Delete end year';
      case EditGridsAction.addEnd: return 'Add end year';
      default: throw new Error('Unsupported action (' + action + ') supplied to TopPanelComponent.getTitle()');
    }
  };

  const getClassName = (action) => {
    switch (action) {
      case EditGridsAction.addStart: return 'btn-add first-button';
      case EditGridsAction.deleteStart: return 'btn-delete middle-button';
      case EditGridsAction.moveStart: return 'btn-move last-button';
      case EditGridsAction.refresh: return 'btn-refresh'
      case EditGridsAction.moveEnd: return 'btn-move rotate first-button';
      case EditGridsAction.deleteEnd: return 'btn-delete middle-button';
      case EditGridsAction.addEnd: return 'btn-add last-button';
      default: throw new Error('Unsupported action (' + action + ') supplied to EditGridsComponent.getClassName()');
    }
  };

  const onChangeNumberOfYears = (x) => {
    numberOfYears = x;
  }

  const handleGridAction = (action) => {
    const updateType = props.showResourceGrid ? ProductionUpdateType.Resource : !props.showEmissionsGrid ? ProductionUpdateType.Hub : ProductionUpdateType.HubEmissions;

    if (action === EditGridsAction.addStart || action === EditGridsAction.addEnd || action === EditGridsAction.deleteStart || action === EditGridsAction.deleteEnd) {
      const msgCallback = (button) => {
        if (button === MessageBoxButton.OK) {

          var yearsOK = true;
          if (numberOfYears === 0) yearsOK = false;

          if (!yearsOK) return false;
          else props.handleGridAction(updateType, action, numberOfYears);
        }
      };

      let header = 'Years';
      var fullTitle;
      const localUpdateType = updateType === ProductionUpdateType.Resource ? ' Resource ' : ProductionUpdateType.Hub ? ' Hub Opex ' : ' Hub Emissions';
      if (action === EditGridsAction.addStart) fullTitle = 'Add Start Year(s) to ' + localUpdateType + ' Profile...';
      else if (action === EditGridsAction.deleteStart) fullTitle = 'Remove Start Year(s) from ' + localUpdateType + ' Profile...';
      else if (action === EditGridsAction.addEnd) fullTitle = 'Add End Year(s) to ' + localUpdateType + ' Profile...';
      else if (action === EditGridsAction.deleteEnd) fullTitle = 'Remove End Year(s) from ' + localUpdateType + ' Profile...';

      let msgContents = 'Are you sure ?';

      header = fullTitle;
      msgContents = <ChangeYearsComponent resourceAnnuals={allResourceAnnuals} hubAnnuals={hubAnnuals} hubEmissions={hubAnnuals} action={action} updateType={updateType} currentResourceID={currentResourceID} handleGridAction={props.handleGridAction} onChangeYears={onChangeNumberOfYears} />;

      messageBoxGlobal.showQuestion(msgContents, msgCallback, MessageBoxButtons.OKCancel, header);
    }
    else {
      props.handleGridAction(updateType, action, 1);
    }
  };

  const toolbarItems = EditGridsAction.values().map(action => (
    <Button
      size="sm"
      title={getTitle(action)}
      disabled={!props.canHandleGridAction(action) || !props.canEnable || ((action === EditGridsAction.addEnd || action === EditGridsAction.deleteEnd || action === EditGridsAction.moveEnd) && (props.enableDeclineCurve && props.showResourceGrid))}
      className={'btn-image no-text ' + getClassName(action)}
      onClick={() => handleGridAction(action)}
    />
  ));

  return (
    <ToolbarPanelComponent className="top-panel-component" align="left" toolbarColor="secondary" toolbarItems={toolbarItems}>
      {
        showResourceGrid
          ? (<ResourceAnnualsComponent
            resourceAnnuals={resourceAnnuals}
            editing={editing}
            messageBoxGlobal={props.messageBoxGlobal}
            preventScrollToTop={preventScrollToTop}
            id={props.currentResourceID}
            canButtonsEnable={canButtonsEnable}
            startYear={props.selectedStartYear}
            endYear={props.selectedEndYear}
            editYear={props.editYear}
            enableDeclineCurve={props.enableDeclineCurve}
            handleCalculatedValues={handleCalculatedValues}
            activeComponent={props.activeComponent}
          />)
          : showEmissionsGrid
            ?
            (<HubEmissionsAnnualsComponent
              hubEmissionAnnuals={hubEmissionAnnuals}
              editing={editing}
              messageBoxGlobal={props.messageBoxGlobal}
              id={1}
              canButtonsEnable={canButtonsEnable}
              startYear={props.selectedStartYear}
              endYear={props.selectedEndYear}
              editYear={props.editYear}
              enableDeclineCurve={props.enableDeclineCurve}
              handleCalculatedValues={handleCalculatedValues}
              activeComponent={props.activeComponent}
            />)
            : (<HubAnnualsComponent
              hubAnnuals={hubAnnuals}
              editing={editing}
              messageBoxGlobal={props.messageBoxGlobal}
              id={1}
              canButtonsEnable={canButtonsEnable}
              startYear={props.selectedStartYear}
              endYear={props.selectedEndYear}
              editYear={props.editYear}
              enableDeclineCurve={props.enableDeclineCurve}
              handleCalculatedValues={handleCalculatedValues}
              activeComponent={props.activeComponent}
            />)
      }
    </ToolbarPanelComponent>
  );
};

TopPanelComponent.propTypes = {
  canHandleGridAction: PropTypes.func.isRequired,
  handleGridAction: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  showResourceGrid: PropTypes.bool.isRequired,
  showEmissionsGrid: PropTypes.bool.isRequired,
  resourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  hubAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(HubAnnual)).isRequired,
  hubEmissionAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(HubEmissionAnnual)).isRequired,
  currentResourceID: PropTypes.number.isRequired,
  canButtonsEnable: PropTypes.func.isRequired,
  selectedStartYear: PropTypes.number.isRequired,
  selectedEndYear: PropTypes.number.isRequired,
  enableDeclineCurve: PropTypes.bool.isRequired,
  handleCalculatedValues: PropTypes.func.isRequired
};

export default TopPanelComponent;
export { EditGridsAction };
