export class ResourceAnnual {
  id = 0;
  entityId = 0;
  resourceName = "";
  year = 0;
  liquidMbod = 0;
  gasMmscfd = 0;
  hubProductionPercent = 0;
  capexUSDMM = 0;
  opexTypeId = 0;
  opexManualUsdMM = 0;
  opexCostSharePercent = 0;
  opexCalculatedUsdMM = 0;
  opexActualUsdMM = 0;
  liquidTariffUSDBOE = 0;
  gasTariffUSDBOE = 0;
  totalTariffUSDMM = 0;
  abexUSDMM = 0;
  revenueUsdMM = 0;
  operatingCashFlowUsdMM = 0;
  preTaxCashFlowUsdMM = 0;
  cumulativePreTaxCashflowUsdMM = 0;
  corparateTaxUsdMM = 0;
  specialTaxUsdMM = 0;
  levyUsdMM = 0;
  undiscountedPostTaxCashFlowUsdMM = 0;
  cumulativeUndiscountedPostTaxCashFlowUsdMM = 0;
  discountedPostTaxCashFlowUsdMM = 0;
  cumulativeDiscountedPostTaxCashFlowUsdMM = 0;
  comment = '';
  liquidDeclinePercent = 0;
  gasDeclinePercent = 0;
  status = '';
  priceDiscountPercent = 0;
  //abexUSDMM = 0;
  combinedMboed = 0;
  gasMboed = 0;
  liquidPriceUSDBOE = 0;
  gasPriceUSDMCF = 0;
  fixedOpexUsdMm = 0;
  cumulativeOperatingCashFlowUsdMM = 0;
  cumulativeLiquidMMB = 0;
  cumulativeGasBCF = 0;
  snapshotLiquidMbod = 0;
  snapshotGasMmscfd = 0;
  snapshotCapexUsdMM = 0;
  snapshotOpexUsdMM = 0;
  snapshotCumulativeOperatingCashFlowUsdMM = 0;
  snapshotAbexUsdMM = 0;
  falseColumn = 'dontChange';
  updated = false;
  isAutoGenerated = false;
  hasSnapshot = false;
  hasResourceAnnual = true;
  isAutoAbex = false;
  liquidTariffUSDMM = 0;
  gasTariffUSDMM = 0;
  corparateTaxRate = 0;
  specialTaxRate = 0;
  discountYear = 0;
  discountRate = 0;
  etsChargeUsdMM = 0;
  carbonChargeUsdMM = 0;
  totalEmissionsChargeUSDMM = 0;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}
