import React from 'react';

class GeneralFormatter extends React.Component {

  render() {
    let value = this.props.value;
    if (typeof value === 'object' && value !== null) {
      value = null;
    }

    return (
      <div className="editColumn">{value}</div>);
  }
}

export default GeneralFormatter;