import moment from 'moment';

class ResourceSnapshotComment {
  resourceSnapshotId = 0;
  resourceId = 0;
  snapshotCommentDate = moment().format('YYYY-MM-DD 00:00:00.000');
  snapshotComment = '';

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

export { ResourceSnapshotComment };