import React from 'react';
import PropTypes from 'prop-types';

import AttributeStaticListComponent from '../../../../common/components/attribute-list/attribute-static-list-component';

import { ResourceWestwoodDataFixedPlatform } from '../../models/result';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import { StringLookupResult } from '../../../../common/models/string-lookup-result';
import { RECORD_STATUS } from '../../../../common/models/record-status';
import { PropertyGridStringValue, PropertyGridSelectValue, PropertyGridNumberValue, PropertyGridBooleanValue } from '../../../../shared/components/property-grid/property-grid-component';
import { INPUTTYPES } from '../../../../shared/components/number-format/number-formatter';

const IDS = {
  FACILITY_ID: 5,
  NAME: 10,
  PROJECT_NAME: 15,
  FIELD_NAME: 16,
  WATER_DEPTH: 20,
  STATUS: 25,
  STRUCTURE_TYPE: 30,
  SIZE_CATEGORY: 35,
  WEIGHT_JACKET_TONNES: 40,
  WEIGHT_TOPSIDES_TONNES: 45,
  UNMANNED: 50,
  WELLHEAD: 55,
  COMPRESSION: 60,
  PROCESSING: 65,
  ACCOMMODATION: 70,
  DRILLING: 75,
  INJECTION: 80,
  PRODUCT: 85,
  DEVELOPMENT_TYPE: 90,
  DEVELOPMENT_STRUCTURE: 95
};
Object.freeze(IDS);

const FixedPlatformComponent = (props) => {
  const { editing, messageBoxGlobal, lookupsWestwood, fixedPlatforms, fixedPlatformActions, id } = props;

  const onEdit = (id, value, item) => {
    switch (id) {
      case IDS.FACILITY_ID: item.facilityId = value; break;

      default: throw new Error('Id ' + id + ' is not supported in AliasComponent.onEdit');
    }

    fixedPlatformActions.onEdit(item);
    return true;
  };

  const getTitle = (item) => {
    let value = item.fixedPlatform === undefined || item.fixedPlatform === null ? '' : item.fixedPlatform.name;

    if (item.status === RECORD_STATUS.added && value === '') return '-New Fixed Platform--';

    return value;
  };

  const getRows = (item) => {
    let rows = [];
    rows.push({ label: 'Platform', values: [new PropertyGridSelectValue(IDS.FACILITY_ID, item.facilityId, (value, id) => onEdit(id, value, item), 'key', 'value', lookupsWestwood.FixedPlatform)] });
    if (item.fixedPlatform === undefined || item.fixedPlatform === null) return rows;

    let fp = item.fixedPlatform;
    rows.push({ label: 'Westwood Data Platform Id', values: [new PropertyGridStringValue(IDS.FACILITY_ID, fp.id)] });
    rows.push({ label: 'Project', values: [new PropertyGridStringValue(IDS.PROJECT_NAME, fp.projectName)] });
    rows.push({ label: 'Field', values: [new PropertyGridStringValue(IDS.FIELD_NAME, fp.fieldName)] });
    rows.push({ label: 'Water Depth (m)', values: [new PropertyGridNumberValue(IDS.WATER_DEPTH, INPUTTYPES.NUMBER, fp.waterDepthMeters, null, 2)] });
    rows.push({ label: 'Status', values: [new PropertyGridStringValue(IDS.STATUS, fp.status)] });

    rows.push({ label: 'Structure Type', values: [new PropertyGridStringValue(IDS.STRUCTURE_TYPE, fp.structureType)] });
    rows.push({ label: 'Size Category', values: [new PropertyGridStringValue(IDS.SIZE_CATEGORY, fp.sizeCategory)] });
    rows.push({ label: 'Weight Jacket (tonnes)', values: [new PropertyGridNumberValue(IDS.WEIGHT_JACKET_TONNES, INPUTTYPES.NUMBER, fp.weightJacketTonnes, null, 2)] });
    rows.push({ label: 'Weight Topsides (tonnes)', values: [new PropertyGridNumberValue(IDS.WEIGHT_TOPSIDES_TONNES, INPUTTYPES.NUMBER, fp.weightTopsidesTonnes, null, 2)] });

    rows.push({ label: 'Unmanned', values: [new PropertyGridBooleanValue(IDS.UNMANNED, fp.unmanned)] });
    rows.push({ label: 'Wellhead', values: [new PropertyGridBooleanValue(IDS.WELLHEAD, fp.wellhead)] });
    rows.push({ label: 'Compression', values: [new PropertyGridBooleanValue(IDS.COMPRESSION, fp.compression)] });
    rows.push({ label: 'Processing', values: [new PropertyGridBooleanValue(IDS.PROCESSING, fp.processing)] });
    rows.push({ label: 'Accommodation', values: [new PropertyGridBooleanValue(IDS.ACCOMMODATION, fp.accommodation)] });
    rows.push({ label: 'Drilling', values: [new PropertyGridBooleanValue(IDS.DRILLING, fp.drilling)] });
    rows.push({ label: 'Injection', values: [new PropertyGridBooleanValue(IDS.INJECTION, fp.injection)] });

    rows.push({ label: 'Product', values: [new PropertyGridStringValue(IDS.PRODUCT, fp.product)] });
    rows.push({ label: 'Development Type', values: [new PropertyGridStringValue(IDS.DEVELOPMENT_TYPE, fp.developmentType)] });
    rows.push({ label: 'Development Structure', values: [new PropertyGridStringValue(IDS.DEVELOPMENT_STRUCTURE, fp.developmentStructure)] });

    return rows;
  }

  const items = fixedPlatforms.map(item => {
    return {
      label: getTitle(item),
      data: item,
      sections: [{
        label: 'Fixed Platform',
        rows: getRows(item)
      }]
    }
  });

  const add = () => {
    let newItem = new ResourceWestwoodDataFixedPlatform(null);
    newItem.getNew(id);

    if (fixedPlatforms.length > 0) {
      fixedPlatforms[0].status = fixedPlatforms[0].status === RECORD_STATUS.added ? fixedPlatforms[0].status : RECORD_STATUS.edited;
    }

    fixedPlatformActions.onAdd(newItem);
  }

  return (
    <AttributeStaticListComponent
      editing={editing}
      messageBoxGlobal={messageBoxGlobal}
      items={items}
      itemDescription="Fixed Platform"
      canAdd
      canDelete
      onAdd={add}
      onDelete={fixedPlatformActions.onDelete}
      onSelected={fixedPlatformActions.onSelected}
      onAddPosition={"start"}
    />
  );
};

FixedPlatformComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  lookupsWestwood: PropTypes.instanceOf(StringLookupResult).isRequired,
  fixedPlatforms: PropTypes.arrayOf(PropTypes.instanceOf(ResourceWestwoodDataFixedPlatform)).isRequired,
  fixedPlatformActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired
};

export default FixedPlatformComponent;
