import React from 'react';
import PropTypes from 'prop-types';
import CommentsComponent from '../../../../common/components/comments/comments-component';
import { GMatrixGenericComment } from '../../../../common/models/generic-comment';
import { MessageBoxGlobal, LoadingGlobal } from '../../../../common/globals/globals';
import TabPanelComponent, { Tab } from '../../../../shared/components/tab-panel/tab-panel-component';
import Ownership from '../../../../common/models/ownership';
import OwnershipComponent from '../../../../common/components/ownership/ownership-component';

const BottomPanelComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    loadingGlobal,
    comments,
    commentActions,
    isLicenced,
    ownership,
    ownershipActions,
    ownershiplookupURL,
    entityId
  } = props;

  let selectedTab = 0;
  const tabs = [];

  if (isLicenced) {
    tabs.push(new Tab('Ownership', <OwnershipComponent editing={editing} messageBoxGlobal={messageBoxGlobal} loadingGlobal={loadingGlobal} ownership={ownership} ownershipActions={ownershipActions} lookupURL={ownershiplookupURL} id={entityId} />));
  }

  tabs.push(new Tab('Comments', <CommentsComponent editing={editing} messageBoxGlobal={messageBoxGlobal} comments={comments} commentActions={commentActions} />));
  return (
    <TabPanelComponent align="top" toolbarItems={tabs} selectedItemIndex={selectedTab} />
  );
};

BottomPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  comments: PropTypes.arrayOf(PropTypes.instanceOf(GMatrixGenericComment)).isRequired,
  commentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  isLicenced: PropTypes.bool.isRequired,
  ownership: PropTypes.arrayOf(PropTypes.instanceOf(Ownership)),
  ownershipActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  ownershiplookupURL: PropTypes.string.isRequired,
};

export default BottomPanelComponent;
