class QueryResultsInput {
  constructor(countryIds, queryId, param1, param2) {
    if (!Array.isArray(countryIds) || countryIds.length === 0) {
      throw new Error('Invalid countryIds \'' + countryIds + '\' supplied to QueryResultsInput.ctor');
    }
    if (typeof queryId !== 'number') {
      throw new Error('Invalid queryId \'' + queryId + '\' supplied to QueryResultsInput.ctor');
    }
    if (param1 !== null && typeof param1 !== 'string') {
      throw new Error('Invalid param1 \'' + param1 + '\' supplied to QueryResultsInput.ctor');
    }
    if (param2 !== null && typeof param2 !== 'string') {
      throw new Error('Invalid param2 \'' + param2 + '\' supplied to QueryResultsInput.ctor');
    }

    this.countryIds = countryIds;
    this.queryId = queryId;
    this.param1 = param1;
    this.param2 = param2;
  }
}

class QueryResultsOutput {
  constructor(columnNames, rows) {
    if (!Array.isArray(columnNames) || columnNames.length === 0 || columnNames.filter(obj => typeof obj !== 'string').length > 0) {
      throw new Error('Invalid columnNames \'' + columnNames + '\' supplied to QueryResultsOutput.ctor');
    }
    if (!Array.isArray(rows)) {
      throw new Error('Invalid rows \'' + rows + '\' supplied to QueryResultsOutput.ctor');
    }

    this.columnNames = columnNames;
    this.rows = rows;
  }
}

export { QueryResultsInput, QueryResultsOutput };