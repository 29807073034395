import React from "react";
import PropTypes from 'prop-types';

import { MessageBoxGlobal } from '../../../common/globals/globals';
import AGGridComponent, {
  GeneralFormatter,
  COLUMN_MEDIUM
} from '../../../common/components/grid/ag-grid-component';

const createColumnDefs = (columnNames) => {
  return columnNames.map((obj, index) => {
    return {
      headerName: obj,
      field: index.toString(),
      sortable: true,
      editable: false,
      width: COLUMN_MEDIUM, 
      maxWidth: 500,
      cellRenderer: GeneralFormatter,
      type: 'text',
      filter: 'agSetColumnFilter',
      resizable: true,
      //suppressSizeToFit: true
    };
  });
};

const QueryResultsGridComponentNew = (props) => {
  const { messageBoxGlobal, queryId, columnNames, rows } = props;
  const columnDefs = createColumnDefs(columnNames);

  switch (queryId) {
    case 9:
      require('./initcolumnsfor-triton-companyfieldandntdreserves-new').initColumnsFor_Triton_CompanyFieldAndNtdReserves(columnDefs);
      break;
    default:
      break;
  }
  
  //const columnTypes = ["numeric", "text", "percent", "none"];
  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {}
  };

  return (
    <AGGridComponent
      messageBoxGlobal={messageBoxGlobal}
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      gridData={rows}
      rowsCount={rows.length}
      showFilters={false}
      id={props.queryId}
      autoResize={true}
      //headerRowHeight={30}
    />

  );
};

QueryResultsGridComponentNew.propTypes = {
  showFilters: PropTypes.bool.isRequired,
  setFilters: PropTypes.func.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  queryId: PropTypes.number.isRequired,
  columnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])
    )
  ).isRequired
};

export default QueryResultsGridComponentNew;
