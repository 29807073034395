import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Label, Input } from 'reactstrap';

const PromoteDiscoveryToNtdComponent = (props) => {
  const ntdStartYears = [];
  for (var loop = 0; loop < 10; loop++) {
    ntdStartYears.push(props.ntdStartYear + loop);
  }

  return (
    <Container fluid style={{ paddingLeft: 0, paddingRight: 0, overflowX: "hidden" }}>
      <Row>
        <Col>
          {'To promote discovery \'' + props.resourceName + '\' to a Near Term Development, please select a start year and duration.'}
        </Col>
      </Row>
      <Row className="mt-3 pl-3">
        <Col xs="2">
          <Label for="cbNtdStartYear">Start Year</Label>
        </Col>
        <Col xs="3">
          <Input style={{ paddingLeft: '1px' }} type="select" id="cbNtdStartYear" bsSize="sm" defaultValue={props.ntdStartYear} onChange={event => props.onNtdStartYearChange(event.target.value)}>
            {ntdStartYears.map(obj => <option key={obj} value={obj}>{obj}</option>)}
          </Input>
        </Col>
      </Row>
      <Row className="mt-2 pl-3">
        <Col xs="2">
          <Label for="txtNtdDuration">Duration (years)</Label>
        </Col>
        <Col xs="3">
          <Input type="number" id="txtNtdDuration" bsSize="sm" min="1" max="100" defaultValue={props.ntdDuration} onChange={event => props.onNtdDurationChange(event.target.value)} />
        </Col>
      </Row>
    </Container>
  );
};

PromoteDiscoveryToNtdComponent.propTypes = {
  ntdDuration: PropTypes.number.isRequired,
  ntdStartYear: PropTypes.number.isRequired,
  onNtdDurationChange: PropTypes.func.isRequired,
  onNtdStartYearChange: PropTypes.func.isRequired,
  resourceName: PropTypes.string.isRequired
};

export default PromoteDiscoveryToNtdComponent;
