import React from 'react';
import PropTypes from 'prop-types';
import './hub-emission-intensity-chart-component.scss';
import { ChartData } from '../../../../../shared/components/generic-chart/chart-data';
import EmissionsC02vsCO2IntensityChart from '../../../../../shared/components/emissions-c02-vs-co2-intensity-chart/emissions-c02-vs-co2-intensity-chart';

const HubEmissionIntensityChartComponent = (props) => {
  const { chartData } = props;

  //const hasEmissionsData = chartData !== null && chartData !== undefined;
  //const chartStyle = { height: 'calc(100 % - 30px) !important', display: 'inline-block', width: '100%' };

  //const emissionsChart = hasEmissionsData ?
  //  (
  //    <EmissionsC02vsCO2IntensityChart
  //      chartDataItems={chartData}
  //      showXAxisLabel={true}
  //      showXAxisGridLines={true}
  //      primaryFill='origin'
  //      secondaryFill='false'
  //    />
  //  )
  //  :
  //  <div>No Emissions Information Available</div>;

  return (
    chartData !== null && chartData !== undefined ?
      <EmissionsC02vsCO2IntensityChart
        chartDataItems={chartData}
        showXAxisLabel={true}
        showXAxisGridLines={true}
        primaryFill='origin'
        secondaryFill='false'
      />
      : <div>No Emissions Information Available</div>
  )
};

HubEmissionIntensityChartComponent.propTypes = {
  emissionsIntensity: PropTypes.instanceOf(ChartData)
};

export default HubEmissionIntensityChartComponent;
