const WgeCharcoalPlum = '#2C293E';
const WgeIndigoBlue = '#3D35DB';
const WgeVibrantGreen = '#80CE8C';
const WgeLightGrey = '#E1E0E7';
const WgeEnergentBlue = '#9B3BF9';
const WgeWildcatBlue = '#4A7AFA';
const WgeNewEnergies = '#66A671';
const WgeAtlasPurple = '#5F2EC4';
const WgePlatformLogix = '#6EB8FC';
const WgeRigLogix = '#DE5A48';
const WgeGlrGreen = '#559497';
const WgeRigLogixYellow = '#ECB54F';
const WgeEnergentLight = '#B683F7';
const WgeWildcatDark = '#3756A5';
const WgeNewEnergiesLight = '#9EC2A2';
const WgeAtlasDark = '#3F2780';
const WgePlatformLogixAlt = '#A5D0FA';
const WgeRigLogixDark = '#8E3C33';
const WgeGlrLight = '#93B7B8';
const WgeWindLogixDark = '#9A783C';
const WgeEnergentDark = '#6333A3';
const WgeWildcatLight = '#8BA9F8';
const WgeNewEnergiesDark = '#4A6F4F';
const WgeAtlasLight = '#9178D3';
const WgePlatformLogixAlt2 = '#527DA7';
const WgeRigLogixLight = '#E19086';
const WgeGlrDark = '#406365';
const WgeWindLogixLight = '#EECB8F';

const WgeCorporateBlue = '#1B365D';
const WgeSectorsRed = '#A30046';
const WgeAnalyticsGreen = '#51AE32';
const WgeFarmOutsOrange = '#D63515';
const WgeRigEdgeBlue = '#006996';
const WgeRigOutlookMaroon = '#560025';
const WgeAdditional1 = '#8E87C3';
const WgeAdditional2 = '#286638';
const WgeAdditional3 = '#D159C9';
const WgeAdditional4 = '#074E66';
const WgeAdditional5 = '#FB9792';
const WgeAdditional6 = '#2C2F2B';
const WgeAdditional7 = '#2A1B90';
const WgeAdditional8 = '#049E33';
const WgeAdditional9 = '#B75818';
const WgeAdditional10 = '#2D172B';
const WgeDarkGrey = '#636362';


export {
  WgeCharcoalPlum,
  WgeIndigoBlue,
  WgeVibrantGreen,
  WgeLightGrey,
  WgeEnergentBlue,
  WgeWildcatBlue,
  WgeNewEnergies,
  WgeAtlasPurple,
  WgePlatformLogix,
  WgeRigLogix,
  WgeGlrGreen,
  WgeRigLogixYellow,
  WgeEnergentLight,
  WgeWildcatDark,
  WgeNewEnergiesLight,
  WgeAtlasDark,
  WgePlatformLogixAlt,
  WgeRigLogixDark,
  WgeGlrLight,
  WgeWindLogixDark,
  WgeEnergentDark,
  WgeWildcatLight,
  WgeNewEnergiesDark,
  WgeAtlasLight,
  WgePlatformLogixAlt2,
  WgeRigLogixLight,
  WgeGlrDark,
  WgeWindLogixLight,

  WgeCorporateBlue,
  WgeSectorsRed,
  WgeAnalyticsGreen,
  WgeFarmOutsOrange,
  WgeRigEdgeBlue,
  WgeRigOutlookMaroon,
  WgeAdditional1,
  WgeAdditional2,
  WgeAdditional3,
  WgeAdditional4,
  WgeAdditional5,
  WgeAdditional6,
  WgeAdditional7,
  WgeAdditional8,
  WgeAdditional9,
  WgeAdditional10,
  WgeDarkGrey
};
