import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, Input } from 'reactstrap';

import './record-locks-component.scss';
import { EditLock } from '../../common/models/edit-lock';
import { IN_DEV_MODE } from '../../shared/helpers/common';

class SelectedEditLock {
  constructor(editLock, canEdit) {
    this.editLock = new EditLock(editLock);
    this.canEdit = canEdit || IN_DEV_MODE;
    this.selected = this.canEdit;
  }
}

const RecordLocksComponent = (props) => {
  const [editLocks, setEditLocks] = useState(props.editLocks);

  const setAll = (selected) => {
    for (var loop = 0; loop < editLocks.length; loop++) {
      if (editLocks[loop].canEdit) {
        editLocks[loop].selected = selected;
      }
    }
    setEditLocks([...editLocks]);
  };

  const toggle = (editLock) => {
    if (editLock.canEdit) {
      editLock.selected = !editLock.selected;
      setEditLocks([...editLocks]);
    }
  };

  const now = new Date();

  const getTimeStr = (date1, date2) => {
    const ms = Math.abs(moment(date1).toDate() - moment(date2).toDate());
    const min = Math.floor((ms / 1000) / 60);
    return min.toString() + ' mins';
  };

  let content = <h1>No record locks found.</h1>;
  if (editLocks.length > 0) {
    content = (
      <Table borderless striped size="sm">
        <thead>
          <tr>
            <th className="selector"><Input type="checkbox" defaultChecked onChange={(event) => setAll(event.target.checked)} /></th>
            <th>Name</th>
            <th>User</th>
            <th>Locked</th>
            <th>Expires</th>
          </tr>
        </thead>
        <tbody>
          {editLocks.map(item => (
            <tr key={item.editLock.id} style={{ cursor: item.canEdit ? 'pointer' : 'default' }} onClick={() => toggle(item)} >
              <td className="selector"><Input type="checkbox" disabled={!item.canEdit} checked={item.canEdit && item.selected} onChange={() => { }} /></td>
              <td>{item.editLock.description}</td>
              <td>{item.editLock.userName}</td>
              <td>{getTimeStr(now, item.editLock.startTime)}</td>
              <td>{getTimeStr(item.editLock.expiryTime, now)}</td>
            </tr>
          ))}
        </tbody>
      </Table >
    );
  }

  return <div className="record-locks-component">{content}</div>;
};

RecordLocksComponent.propTypes = {
  editLocks: PropTypes.arrayOf(PropTypes.instanceOf(SelectedEditLock)).isRequired
};

export default RecordLocksComponent;
export { SelectedEditLock };