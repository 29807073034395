import moment from 'moment';
import { ResourceSnapshotComment } from '../../../common/models/resource-snapshot-comment';
import { HubComment } from '../../../common/models/hub-comment';
import { HubText } from '../../../common/models/hub-text';
import { HubAnnual } from '../../../common/models/hub-annual';
import { HubEmissionAnnual } from '../../../common/models/hub-emission-annual';
import { HubEmissionOverride } from '../../../common/models/hub-emission-override';
import { ResourceAnnual } from '../../../common/models/resource-annual';
import { ResourceSnapshotAnnual } from '../../../common/models/resource-snapshot-annual';
import { ResourceText } from '../../../common/models/resource-text';
import { Issue } from '../../../common/models/issue';
import { EditLock } from '../../../common/models/edit-lock';
import { ChartData } from '../../../shared/components/generic-chart/chart-data';
import { WgeCorporateBlue, WgeLightGrey } from '../../../shared/scss/wge-colors';
import { GMatrixGenericComment } from '../../../common/models/generic-comment';
import { getDate } from '../../../shared/helpers/date-helper';
import { unitConversions } from '../../../shared/helpers/unit-conversions';

export class ButtonProperties {
  allowDeleteStart = false;
  allowDeleteEnd = false;
  allowMoveStart = false;
  allowMoveEnd = false;
  allowInsertStart = false;
  allowInsertEnd = false;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

export class ResourceReservesHistory {
  reservesHistoryId = 0;
  resourceId = 0;
  initialLiquid = 0;
  remainingLiquid = 0;
  initialGas = 0;
  remainingGas = 0;
  dateAdded = '';

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

export class ResourceSnapshotHistory {
  resourceSnapshotId = 0;
  resourceId = 0;
  initialLiquid = 0;
  remainingLiquid = 0;
  initialGas = 0;
  remainingGas = 0;
  fullFieldNPV10 = 0;
  remainingNPV10 = 0;
  copYear = 0;
  snapshotDate = moment().format('YYYY-MM-DD 00:00:00.000');

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

export class HubProductionAttributes {
  hubId = 0;
  hubName = 0;
  hubComments = [new HubComment()];
  hubDescriptiveText = [new HubText()];
  clientChangeDescriptionDate = moment().format('YYYY-MM-DD 00:00:00.000');
  clientChangeDescription = "";
  snapshotCommentDate = moment().format('YYYY-MM-DD 00:00:00.000');
  snapshopComment = "";
  buttons = [new ButtonProperties()];
  productionEndDate = moment().format('YYYY-MM-DD 00:00:00.000');
  status = '';
  co2EmissionsMethodId = 0;
  ch4EmissionsMethodId = 0;
  hubElectrificationStatusId = 1;
  emissionsComment = '';
  applyEts = false;
  applyCarbonCharge = false;

  constructor(obj) {
    obj && Object.assign(this, obj);
    this.hubComments = obj === undefined ? [new HubComment()] : obj.hubComments.map(item => new HubComment(item));
    this.hubDescriptiveText = obj === undefined ? [new HubText()] : obj.hubDescriptiveText.map(item => new HubText(item));
    this.buttons = obj === undefined ? [new ButtonProperties()] : new ButtonProperties(this.buttons);
  }
}

export class ResourceProductionAttributes {
  constructor(obj) {
    if (obj === undefined) obj = null;
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to ResourceProductionAttributes.ctor'); }
    if (obj !== null) {
      if (obj.resourceId !== null && typeof obj.resourceId !== 'number') { throw new Error('Invalid obj.resourceId param supplied to ResourceProductionAttributes.ctor'); }
      if (obj.resourceName !== null && typeof obj.resourceName !== 'string') { throw new Error('Invalid obj.resourceName param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.resourceStatusId !== 'number') { throw new Error('Invalid obj.resourceStatusId param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.liquidToFuel !== 'boolean') { throw new Error('Invalid obj.liquidToFuel param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.gasToFuel !== 'boolean') { throw new Error('Invalid obj.gasToFuel param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.activityAudited !== 'boolean') { throw new Error('Invalid obj.activityAudited param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.expectedSanctionYear !== 'number') { throw new Error('Invalid obj.expectedSanctionYear param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.liquidPriceDiscountPremiumPercent !== 'number') { throw new Error('Invalid obj.liquidPriceDiscountPremiumPercent param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.gasPriceDiscountPremiumPercent !== 'number') { throw new Error('Invalid obj.gasPriceDiscountPremiumPercent param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.remainingCombinedMmboe !== 'number') { throw new Error('Invalid obj.remainingCombinedMmboe param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.initialCombinedMmboe !== 'number') { throw new Error('Invalid obj.initialCombinedMmboe param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.oiipMMB !== 'number') { throw new Error('Invalid obj.oiipMMB param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.giipBCF !== 'number') { throw new Error('Invalid obj.giipBCF param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.sanctionInitialLiquidMMB !== 'number') { throw new Error('Invalid obj.sanctionInitialLiquidMMB param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.sanctionInitialGasBCF !== 'number') { throw new Error('Invalid obj.sanctionInitialGasBCF param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.developmentWells !== 'number') { throw new Error('Invalid obj.developmentWells param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.totalAbexUSDMM !== 'number') { throw new Error('Invalid obj.totalAbexUSDMM param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.carryForward2015Allowance !== 'number') { throw new Error('Invalid obj.carryForward2015Allowance param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.economicCutOffOverrideYear !== 'number') { throw new Error('Invalid obj.economicCutOffOverrideYear param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.enableDeclineCurve !== 'boolean') { throw new Error('Invalid obj.enableDeclineCurve param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.lastManualInputRateYear !== 'number') { throw new Error('Invalid obj.lastManualInputRateYear param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.combinedPhysicalLimitMBOED !== 'number') { throw new Error('Invalid obj.combinedPhysicalLimitMBOED param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.liquidCumulativeProductionStartYear !== 'number') { throw new Error('Invalid obj.liquidCumulativeProductionStartYear param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.gasCumulativeProductionStartYear !== 'number') { throw new Error('Invalid obj.gasCumulativeProductionStartYear param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.liquidCumulativeProductionTargetMMB !== 'number') { throw new Error('Invalid obj.liquidCumulativeProductionTargetMMB param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.gasCumulativeProductionTargetBCF !== 'number') { throw new Error('Invalid obj.gasCumulativeProductionTargetBCF param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.liquidCumulativeProductionTargetYear !== 'number') { throw new Error('Invalid obj.liquidCumulativeProductionTargetYear param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.gasCumulativeProductionTargetYear !== 'number') { throw new Error('Invalid obj.gasCumulativeProductionTargetYear param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.liquidDeclineRatePercent !== 'number') { throw new Error('Invalid obj.liquidDeclineRatePercent param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.gasDeclineRatePercent !== 'number') { throw new Error('Invalid obj.gasDeclineRatePercent param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.liquidHyperbolicRatePercent !== 'number') { throw new Error('Invalid obj.liquidHyperbolicRatePercent param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.gasHyperbolicRatePercent !== 'number') { throw new Error('Invalid obj.gasHyperbolicRatePercent param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.remainingLiquidMMB !== 'number') { throw new Error('Invalid obj.remainingLiquidMMB param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.remainingGasBCF !== 'number') { throw new Error('Invalid obj.remainingGasBCF param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.initialLiquidMMB !== 'number') { throw new Error('Invalid obj.initialLiquidMMB param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.initialGasBCF !== 'number') { throw new Error('Invalid obj.initialGasBCF param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.liquidPercentRemaining !== 'number') { throw new Error('Invalid obj.liquidPercentRemaining param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.gasPercentRemaining !== 'number') { throw new Error('Invalid obj.gasPercentRemaining param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.snapshotCOPYear !== 'number') { throw new Error('Invalid obj.snapshotCOPYear param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.currentCOPYear !== 'number') { throw new Error('Invalid obj.currentCOPYear param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.totalTechnicalReservesMmboe !== 'number') { throw new Error('Invalid obj.totalTechnicalReservesMmboe param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.reportedRemainingLiquidMMB !== 'number') { throw new Error('Invalid obj.reportedRemainingLiquidMMB param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.reportedRemainingLiquidSourceId !== 'number') { throw new Error('Invalid obj.reportedRemainingLiquidSourceId param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.reportedRemainingGasBCF !== 'number') { throw new Error('Invalid obj.reportedRemainingGasBCF param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.reportedRemainingGasSourceId !== 'number') { throw new Error('Invalid obj.reportedRemainingGasSourceId param supplied to ResourceProductionAttributes.ctor'); }
      if (obj.reportedCOPYear !== null && typeof obj.reportedCOPYear !== 'number') { throw new Error('Invalid obj.reportedCOPYear param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.reportedCOPSourceId !== 'number') { throw new Error('Invalid obj.reportedCOPSourceId param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.breakEvenYear !== 'number') { throw new Error('Invalid obj.breakEvenYear param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.breakEvenPrice !== 'number') { throw new Error('Invalid obj.breakEvenPrice param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.fullFieldNPVUSDMM !== 'number') { throw new Error('Invalid obj.fullFieldNPVUSDMM param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.fullFieldNPV10USDMM !== 'number') { throw new Error('Invalid obj.fullFieldNPV10USDMM param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.fullInternalRateOfReturn !== 'number') { throw new Error('Invalid obj.fullInternalRateOfReturn param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.fullFieldOperatingCostUSDMM !== 'number') { throw new Error('Invalid obj.fullFieldOperatingCostUSDMM param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.pointForwardNPVUSDMM !== 'number') { throw new Error('Invalid obj.pointForwardNPVUSDMM param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.pointForwardNPV10USDMM !== 'number') { throw new Error('Invalid obj.pointForwardNPV10USDMM param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.pointForwardOperatingCostUSDMM !== 'number') { throw new Error('Invalid obj.pointForwardOperatingCostUSDMM param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.discountYear !== 'number') { throw new Error('Invalid obj.discountYear param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.discountRate !== 'number') { throw new Error('Invalid obj.discountRate param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.status !== 'number') { throw new Error('Invalid obj.status param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.minCumulativeCashflowYear !== 'number') { throw new Error('Invalid obj.minCumulativeCashflowYear param supplied to ResourceProductionAttributes.ctor'); }
      if (typeof obj.sortOrder !== 'number') { throw new Error('Invalid obj.sortOrder param supplied to ResourceProductionAttributes.ctor'); }
      
      if (obj.fieldApprovalDate !== null && typeof obj.fieldApprovalDate !== 'string') { throw new Error('Invalid obj.fieldApprovalDate param supplied to ResourceProductionAttributes.ctor'); }
      if (obj.productionStartDate !== null && typeof obj.productionStartDate !== 'string') { throw new Error('Invalid obj.productionStartDate param supplied to ResourceProductionAttributes.ctor'); }
      if (obj.productionEndDate !== null && typeof obj.productionEndDate !== 'string') { throw new Error('Invalid obj.productionEndDate param supplied to ResourceProductionAttributes.ctor'); }
      if (obj.reportedRemainingLiquidEffectiveDate !== null && typeof obj.reportedRemainingLiquidEffectiveDate !== 'string') { throw new Error('Invalid obj.reportedRemainingLiquidEffectiveDate param supplied to ResourceProductionAttributes.ctor'); }
      if (obj.reportedRemainingGasEffectiveDate !== null && typeof obj.reportedRemainingGasEffectiveDate !== 'string') { throw new Error('Invalid obj.reportedRemainingGasEffectiveDate param supplied to ResourceProductionAttributes.ctor'); }
      if (obj.reportedCOPDate !== null && typeof obj.reportedCOPDate !== 'string') { throw new Error('Invalid obj.reportedCOPDate param supplied to ResourceProductionAttributes.ctor'); }
    }

    this.resourceId = obj === null || obj.resourceId === null ? 0 : obj.resourceId;
    this.resourceName = obj === null || obj.resourceName === null ? '' : obj.resourceName;
    this.resourceStatusId = obj === null ? 0 : obj.resourceStatusId;
    this.liquidToFuel = obj === null ? false : obj.liquidToFuel;
    this.gasToFuel = obj === null ? false : obj.gasToFuel;
    this.totalAbexUSDMM = obj === null ? 0 : obj.totalAbexUSDMM;
    this.carryForward2015Allowance = obj === null ? 0 : obj.carryForward2015Allowance;
    this.activityAudited = obj === null ? false : obj.activityAudited;

    this.initialLiquidMMB = obj === null ? 0 : obj.initialLiquidMMB;
    this.initialLiquidM3 = obj === null || obj.initialLiquidMMB === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.initialLiquidMMB);

    this.sanctionInitialLiquidMMB = obj === null ? 0 : obj.sanctionInitialLiquidMMB;
    this.sanctionInitialLiquidM3 = obj === null || obj.sanctionInitialLiquidMMB === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.sanctionInitialLiquidMMB);

    this.remainingLiquidMMB = obj === null ? 0 : obj.remainingLiquidMMB;
    this.remainingLiquid3 = obj === null || obj.remainingLiquidMMB === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.remainingLiquidMMB);

    this.oiipMMB = obj === null ? 0 : obj.oiipMMB;
    this.oiipM3 = obj === null || obj.oiipMMB === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.oiipMMB);

    this.initialGasBCF = obj === null ? 0 : obj.initialGasBCF;
    this.initialGasM3 = obj === null || obj.initialGasBCF === 0 ? 0 : unitConversions.Convert(unitConversions.cubicFeetToCubicMeter, obj.initialGasBCF);

    this.sanctionInitialGasBCF = obj === null ? 0 : obj.sanctionInitialGasBCF;
    this.sanctionInitialGasM3 = obj === null || obj.sanctionInitialGasBCF === 0 ? 0 : unitConversions.Convert(unitConversions.cubicFeetToCubicMeter, obj.sanctionInitialGasBCF);

    this.remainingGasBCF = obj === null ? 0 : obj.remainingGasBCF;
    this.remainingGasM3 = obj === null || obj.remainingGasBCF === 0 ? 0 : unitConversions.Convert(unitConversions.cubicFeetToCubicMeter, obj.remainingGasBCF);

    this.giipBCF = obj === null ? 0 : obj.giipBCF;
    this.giipM3 = obj === null || obj.giipBCF === 0 ? 0 : unitConversions.Convert(unitConversions.cubicFeetToCubicMeter, obj.giipBCF);

    this.remainingCombinedMmboe = obj === null ? 0 : obj.remainingCombinedMmboe;
    this.remainingCombinedM3 = obj === null || obj.remainingCombinedMmboe === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.remainingCombinedMmboe);

    this.liquidPercentRemaining = obj === null ? 0 : obj.liquidPercentRemaining;
    this.gasPercentRemaining = obj === null ? 0 : obj.gasPercentRemaining;

    this.initialCombinedMmboe = obj === null ? 0 : obj.initialCombinedMmboe;
    this.initialCombinedM3 = obj === null || obj.initialCombinedMmboe === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.initialCombinedMmboe);

    this.minCumulativeCashflowYear = obj === null ? 0 : obj.minCumulativeCashflowYear;
    this.expectedSanctionYear = obj === null ? 0 : obj.expectedSanctionYear;
    this.sortOrder = obj === null ? 0 : obj.sortOrder;
    this.liquidPriceDiscountPremiumPercent = obj === null ? 0 : obj.liquidPriceDiscountPremiumPercent;
    this.gasPriceDiscountPremiumPercent = obj === null ? 0 : obj.gasPriceDiscountPremiumPercent;
    this.developmentWells = obj === null ? 0 : obj.developmentWells;

    this.economicCutOffOverrideYear = obj === null ? 0 : obj.economicCutOffOverrideYear;
    this.enableDeclineCurve = obj === null ? false : obj.enableDeclineCurve;
    this.lastManualInputRateYear = obj === null ? 0 : obj.lastManualInputRateYear;
    this.combinedPhysicalLimitMBOED = obj === null ? 0 : obj.combinedPhysicalLimitMBOED;
    this.liquidCumulativeProductionStartYear = obj === null ? 0 : obj.liquidCumulativeProductionStartYear;
    this.gasCumulativeProductionStartYear = obj === null ? 0 : obj.gasCumulativeProductionStartYear;

    this.liquidCumulativeProductionTargetMMB = obj === null ? 0 : obj.liquidCumulativeProductionTargetMMB;
    this.liquidCumulativeProductionTargetM3 = obj === null || obj.liquidCumulativeProductionTargetMMB === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.liquidCumulativeProductionTargetMMB);

    this.gasCumulativeProductionTargetBCF = obj === null ? 0 : obj.gasCumulativeProductionTargetBCF;
    this.gasCumulativeProductionTargetM3 = obj === null || obj.gasCumulativeProductionTargetBCF === 0 ? 0 : unitConversions.Convert(unitConversions.cubicFeetToCubicMeter, obj.gasCumulativeProductionTargetBCF);

    this.liquidCumulativeProductionTargetYear = obj === null ? 0 : obj.liquidCumulativeProductionTargetYear;
    this.gasCumulativeProductionTargetYear = obj === null ? 0 : obj.gasCumulativeProductionTargetYear;
    this.liquidDeclineRatePercent = obj === null ? 0 : obj.liquidDeclineRatePercent;
    this.gasDeclineRatePercent = obj === null ? 0 : obj.gasDeclineRatePercent;
    this.liquidHyperbolicRatePercent = obj === null ? 0 : obj.liquidHyperbolicRatePercent;
    this.gasHyperbolicRatePercent = obj === null ? 0 : obj.gasHyperbolicRatePercent;
    
    this.snapshotCOPYear = obj === null ? 0 : obj.snapshotCOPYear;
    this.currentCOPYear = obj === null ? 0 : obj.currentCOPYear;

    this.totalTechnicalReservesMmboe = obj === null ? 0 : obj.totalTechnicalReservesMmboe;
    this.totalTechnicalReservesM3 = obj === null || obj.totalTechnicalReservesMmboe === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.totalTechnicalReservesMmboe);

    this.reportedRemainingLiquidMMB = obj === null ? 0 : obj.reportedRemainingLiquidMMB;
    this.reportedRemainingLiquidM3 = obj === null || obj.reportedRemainingLiquidMMB === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.reportedRemainingLiquidMMB);

    this.reportedRemainingLiquidSourceId = obj === null ? 0 : obj.reportedRemainingLiquidSourceId;

    this.reportedRemainingGasBCF = obj === null ? 0 : obj.reportedRemainingGasBCF;
    this.reportedRemainingGasM3 = obj === null || obj.reportedRemainingGasBCF === 0 ? 0 : unitConversions.Convert(unitConversions.cubicFeetToCubicMeter, obj.reportedRemainingGasBCF);

    this.reportedRemainingGasSourceId = obj === null ? 0 : obj.reportedRemainingGasSourceId;
    this.reportedCOPYear = obj === null || obj.reportedCOPYear === null ? null : obj.reportedCOPYear;
    this.reportedCOPSourceId = obj === null ? 0 : obj.reportedCOPSourceId;

    this.breakEvenYear = obj === null ? 0 : obj.breakEvenYear;
    this.breakEvenPrice = obj === null ? 0 : obj.breakEvenPrice;
    this.fullFieldNPVUSDMM = obj === null ? 0 : obj.fullFieldNPVUSDMM;
    this.fullFieldNPV10USDMM = obj === null ? 0 : obj.fullFieldNPV10USDMM;
    this.fullInternalRateOfReturn = obj === null ? 0 : obj.fullInternalRateOfReturn;
    this.fullFieldOperatingCostUSDMM = obj === null ? 0 : obj.fullFieldOperatingCostUSDMM;
    this.pointForwardNPVUSDMM = obj === null ? 0 : obj.pointForwardNPVUSDMM;
    this.pointForwardNPV10USDMM = obj === null ? 0 : obj.pointForwardNPV10USDMM;
    this.pointForwardOperatingCostUSDMM = obj === null ? 0 : obj.pointForwardOperatingCostUSDMM;

    // dates
    this.fieldApprovalDate = obj === null ? null : getDate(obj.fieldApprovalDate);
    this.productionStartDate = obj === null ? null : getDate(obj.productionStartDate);
    this.productionEndDate = obj === null ? null : getDate(obj.productionEndDate);
    this.reportedRemainingLiquidEffectiveDate = obj === null ? null : getDate(obj.reportedRemainingLiquidEffectiveDate);
    this.reportedRemainingGasEffectiveDate = obj === null ? null : getDate(obj.reportedRemainingGasEffectiveDate);
    this.reportedCOPDate = obj === null ? null : getDate(obj.reportedCOPDate);

    this.countryId = obj === null ? 0 : obj.countryId;
    this.developmentConceptId = obj === null ? 0 : obj.developmentConceptId;

    this.discountYear = obj === null ? 0 : obj.discountYear;
    this.discountRate = obj === null ? 0 : obj.discountRate;

    this.status = obj === null ? 0 : obj.status;

    // collections
    this.buttons = (obj === undefined || obj === null || obj.buttons === undefined || obj.buttons === null) ? new ButtonProperties() : new ButtonProperties(obj.buttons);
    this.resourceComments = (obj === undefined || obj === null || obj.resourceComments === undefined || obj.resourceComments === null) ? [new GMatrixGenericComment()] : obj.resourceComments.map(item => new GMatrixGenericComment(item));
    this.resourceDescriptiveText = (obj === undefined || obj === null || obj.resourceDescriptiveText === undefined || obj.resourceDescriptiveText === null) ? [new ResourceText()] : obj.resourceDescriptiveText.map(item => new ResourceText(item));
    this.productionComments = (obj === undefined || obj === null || obj.productionComments === undefined || obj.productionComments === null) ? [new GMatrixGenericComment()] : obj.productionComments.map(item => new GMatrixGenericComment(item));
    this.resourceSnapshotComments = (obj === undefined || obj === null || obj.resourceSnapshotComments === undefined || obj.resourceSnapshotComments === null) ? [new ResourceSnapshotComment()] : obj.resourceSnapshotComments.map(item => new ResourceSnapshotComment(item));
    this.reservesHistory = (obj === undefined || obj === null || obj.reservesHistory === undefined || obj.reservesHistory === null) ? [new ResourceReservesHistory()] : obj.reservesHistory.map(item => new ResourceReservesHistory(item));
    this.resourceSnapshotHistory = (obj === undefined || obj === null || obj.resourceSnapshotHistory === undefined || obj.resourceSnapshotHistory === null) ? [new ResourceSnapshotHistory()] : obj.resourceSnapshotHistory.map(item => new ResourceSnapshotHistory(item));

  }
}

//export class ResourceProductionAttributes {
//  resourceId = 0;
//  resourceName = '';
//  resourceStatusId = 0;
//  fieldApprovalDate = moment().format('YYYY-MM-DD 00:00:00.000');
//  liquidToFuel = false;
//  gasToFuel = false;
//  activityAudited = false;

//  resourceComments = [new GMatrixGenericComment()];
//  resourceDescriptiveText = [new ResourceText()]
//  productionComments = [new GMatrixGenericComment(this.productionComments)];
//  resourceSnapshotComments = [new ResourceSnapshotComment(this.resourceSnapshotComments)];
//  reservesHistory = [new ResourceReservesHistory()];
//  resourceSnapshotHistory = [new ResourceSnapshotHistory()]
//  buttons = [new ButtonProperties()];

//  expectedSanctionYear = 0;
//  productionEndDate = moment().format('YYYY-MM-DD 00:00:00.000');
//  status = '';
//  liquidPriceDiscountPremiumPercent = 0;
//  gasPriceDiscountPremiumPercent = 0;
//  remainingCombinedMmboe = 0;
//  initialCombinedMmboe = 0;
//  oiipMMB = 0;
//  giipBCF = 0;
//  sanctionInitialLiquidMMB = 0;
//  sanctionInitialGasBCF = 0;
//  developmentWells = 0;
//  totalAbexUSDMM = 0;
//  carryForward2015Allowance = 0;
//  economicCutOffOverrideYear = 0;
//  enableDeclineCurve = false;
//  lastManualInputRateYear = 0;
//  combinedPhysicalLimitMBOED = 0;
//  liquidCumulativeProductionStartYear = 0;
//  gasCumulativeProductionStartYear = 0;
//  liquidCumulativeProductionTargetMMB = 0;
//  gasCumulativeProductionTargetBCF = 0;
//  liquidCumulativeProductionTargetYear = 0;
//  gasCumulativeProductionTargetYear = 0;
//  liquidDeclineRatePercent = 0;
//  gasDeclineRatePercent = 0;
//  liquidHyperbolicRatePercent = 0;
//  gasHyperbolicRatePercent = 0;
//  remainingLiquidMMB = 0;
//  remainingGasBCF = 0;
//  initialLiquidMMB = 0;
//  initialGasBCF = 0;
//  liquidPercentRemaining = 0;
//  gasPercentRemaining = 0;
//  snapshotCOPYear = 0;
//  currentCOPYear = 0;
//  productionStartDate = moment().format('YYYY-MM-DD 00:00:00.000');
//  totalTechnicalReservesMmboe = 0;
//  reportedRemainingLiquidMMB = 0;
//  reportedRemainingLiquidSourceId = 0;
//  reportedRemainingLiquidEffectiveDate = moment().format('YYYY-MM-DD 00:00:00.000');
//  reportedRemainingGasBCF = 0;
//  reportedRemainingGasSourceId = 0;
//  reportedRemainingGasEffectiveDate = moment().format('YYYY-MM-DD 00:00:00.000');
//  reportedCOPYear = 0;
//  reportedCOPSourceId = 0;
//  reportedCOPDate = moment().format('YYYY-MM-DD 00:00:00.000');
//  breakEvenYear = 0;
//  breakEvenPrice = 0;
//  fullFieldNPVUSDMM = 0;
//  fullFieldNPV10USDMM
//  fullInternalRateOfReturn = 0;
//  fullFieldOperatingCostUSDMM = 0;
//  pointForwardNPVUSDMM = 0;
//  pointForwardNPV10USDMM = 0;
//  pointForwardOperatingCostUSDMM = 0;
//  discountYear = 0;
//  discountRate = 0;

//  constructor(obj) {
//    obj && Object.assign(this, obj);
//    this.buttons = obj === undefined ? [new ButtonProperties()] : new ButtonProperties(this.buttons);
//    this.resourceComments = obj === undefined ? [new GMatrixGenericComment()] : obj.resourceComments.map(item => new GMatrixGenericComment(item));
//    this.resourceDescriptiveText = obj === undefined ? [new ResourceText()] : obj.resourceDescriptiveText.map(item => new ResourceText(item));
//    this.productionComments = obj === undefined ? [new GMatrixGenericComment()] : obj.productionComments.map(item => new GMatrixGenericComment(item));
//    this.reservesHistory = obj === undefined ? [new ResourceReservesHistory()] : obj.reservesHistory.map(item => new ResourceReservesHistory(item));
//    this.resourceSnapshotComments = obj === undefined ? [new ResourceSnapshotComment()] : obj.resourceSnapshotComments.map(item => new ResourceSnapshotComment(item));
//    this.resourceSnapshotHistory = obj === undefined ? [new ResourceSnapshotHistory()] : obj.resourceSnapshotHistory.map(item => new ResourceSnapshotHistory(item));
//  }
//}

export class OpexTypes {
  opexTypeId = 0;
  opexType = '';
  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

export class ProductionResult {
  hubAnnuals = [new HubAnnual()];
  hubEmissionAnnuals = [new HubEmissionAnnual()]
  hubEmissionOverrides = [new HubEmissionOverride()];
  hubAttributes = [new HubProductionAttributes()];
  opexTypes = [new OpexTypes()];
  resourceAnnuals = [new ResourceAnnual()];
  resourceAttributes = [new ResourceProductionAttributes()];
  resourceSnapshotAnnuals = [new ResourceSnapshotAnnual()];
  issues = [new Issue()];
  alert = '';
  startYear = 0;
  endYear = 0;
  emissionsIntensity = null;
  productionEmissions = null;

  constructor(obj) {
    obj && Object.assign(this, obj);
    this.hubAnnuals = obj === undefined ? [new HubAnnual()] : obj.hubAnnuals.map(item => new HubAnnual(item));
    this.hubEmissionAnnuals = obj === undefined ? [new HubEmissionAnnual()] : obj.hubEmissionAnnuals.map(item => new HubEmissionAnnual(item));
    this.hubEmissionOverrides = obj === undefined ? [new HubEmissionOverride()] : obj.hubEmissionOverrides.map(item => new HubEmissionOverride(item));
    this.hubAttributes = obj === undefined ? [new HubProductionAttributes()] : new HubProductionAttributes(this.hubAttributes);
    this.opexTypes = obj === undefined ? [new OpexTypes()] : this.opexTypes.map(item => new OpexTypes(item));
    this.resourceAnnuals = obj === undefined ? [new ResourceAnnual()] : this.resourceAnnuals.map(item => new ResourceAnnual(item));
    this.resourceAttributes = obj === undefined ? [new ResourceProductionAttributes()] : this.resourceAttributes.map(item => new ResourceProductionAttributes(item));
    this.resourceSnapshotAnnuals = obj === undefined ? [new ResourceSnapshotAnnual()] : this.resourceSnapshotAnnuals.map(item => new ResourceSnapshotAnnual(item));
    this.issues = obj === undefined ? [new Issue()] : this.issues.map(item => new Issue(item));
    this.startYear = obj === undefined ? 0 : obj.startYear;
    this.endYear = obj === undefined ? 0 : obj.endYear;
    this.currentYear = obj === undefined ? 0 : obj.currentYear;

    this.emissionsIntensity = (obj.emissionsChartdata.emissionsIntensity !== null && obj.emissionsChartdata.emissionsIntensity !== undefined) ? new ChartData(obj.emissionsChartdata.emissionsIntensity) : null;
    if (this.emissionsIntensity !== null) { this.emissionsIntensity.yVal[0].color = WgeCorporateBlue; this.emissionsIntensity.yVal[1].color = WgeLightGrey;}
    this.productionEmissions = (obj.emissionsChartdata.productionEmissions !== null && obj.emissionsChartdata.productionEmissions !== undefined) ? new ChartData(obj.emissionsChartdata.productionEmissions) : null;
    if (this.productionEmissions !== null) { this.productionEmissions.yVal[0].color = WgeCorporateBlue; this.productionEmissions.yVal[1].color = WgeLightGrey; }
  }
}

export { HubComment, HubText, ResourceAnnual, HubAnnual, HubEmissionAnnual, ResourceText, Issue, EditLock, ResourceSnapshotComment, unitConversions };
