class PostbackInfo {
  payload = {};
  countryIds = [];
  id = 0;
  forEdit = false;
  autoClose = false;
  selectedItems = [];
}

export { PostbackInfo };
