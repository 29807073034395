class IdGlobal {
  constructor(value, set, setNewRecordId, isSet, isNewRecordId, clear, setCopyRecordId, isCopyRecordId) {
    if (typeof set !== 'function') {
      throw new Error('Invalid set param supplied to IdGlobal.ctor is invalid');
    }
    if (typeof setNewRecordId !== 'function') {
      throw new Error('Invalid setNewRecordId param supplied to IdGlobal.ctor is invalid');
    }
    if (typeof isSet !== 'boolean') {
      throw new Error('Invalid isSet param supplied to IdGlobal.ctor is invalid');
    }
    if (typeof isNewRecordId !== 'boolean') {
      throw new Error('Invalid isNewRecordId param supplied to IdGlobal.ctor is invalid');
    }
    if (typeof clear !== 'function') {
      throw new Error('Invalid clear param supplied to IdGlobal.ctor is invalid');
    }

    this._value = value;
    this._set = set;
    this._setNewRecordId = setNewRecordId;
    this._isSet = isSet;
    this._isNewRecordId = isNewRecordId;
    this._clear = clear;
    this._setCopyRecordId = setCopyRecordId;
    this._isCopyRecordId = isCopyRecordId;
  }

  get value() { return this._value; };
  get set() { return this._set; };
  get isSet() { return this._isSet; };
  get setNewRecordId() { return this._setNewRecordId };
  get isNewRecordId() { return this._isNewRecordId };
  get clear() { return this._clear; };
  get setCopyRecordId() { return this._setCopyRecordId };
  get isCopyRecordId() { return this._isCopyRecordId };
}

export { IdGlobal };