/*C# object must be a GMatrixGenericComment */

import moment from 'moment';
import { RECORD_STATUS } from './record-status';

class GMatrixGenericComment {
  constructor(obj) {
    //if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to GMatrixGenericComment.ctor'); }
    if (obj !== null && obj !== undefined) {
      if (typeof obj.id !== 'number') {
        throw new Error('Invalid obj.id param supplied to GMatrixGenericComment.ctor');
      }
      if (typeof obj.entityId !== 'number') {
        throw new Error('Invalid obj.entityId param supplied to GMatrixGenericComment.ctor');
      }
      if (typeof obj.commentDate !== 'string') {
        throw new Error('Invalid obj.commentDate param supplied to GMatrixGenericComment.ctor');
      }
      if (obj.textValue !== null && typeof obj.textValue !== 'string') {
        throw new Error('Invalid obj.textValue param supplied to GMatrixGenericComment.ctor');
      }
      if (typeof obj.allowAdd !== 'boolean') {
        throw new Error('Invalid obj.allowAdd param supplied to GMatrixGenericComment.ctor');
      }
      if (typeof obj.allowRemove !== 'boolean') {
        throw new Error('Invalid obj.allowRemove param supplied to GMatrixGenericComment.ctor');
      }
      if (typeof obj.status !== 'number') {
        throw new Error('Invalid obj.status param supplied to GMatrixGenericComment.ctor');
      }
      if (typeof obj.selected !== 'boolean') {
        throw new Error('Invalid obj.selected param supplied to GMatrixGenericComment.ctor');
      }
    }

    this.id = obj === null || obj === undefined ? 0 : obj.id;
    this.entityId = obj === null || obj === undefined ? 0 : obj.entityId;
    this.commentDate = obj === null || obj === undefined ? null : obj.commentDate;
    this.textValue = obj === null || obj === undefined ? '' : obj.textValue;
    this.allowAdd = obj === null || obj === undefined ? true : obj.allowAdd;
    this.allowRemove = obj === null || obj === undefined ? true : obj.allowRemove;
    this.status = obj === null || obj === undefined ? 0 : obj.status;
    this.selected = obj === null || obj === undefined ? 0 : obj.selected;
  }

  getNew() {
    this.commentDate = moment().format('YYYY-MM-DD[T]hh:mm:ss.sss');
    this.textValue = '';
    this.allowAdd = true;
    this.allowRemove = true;
    this.selected = true;
    this.status = RECORD_STATUS.added;
  }
}

export { GMatrixGenericComment };
