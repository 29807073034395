import { Issue } from '../../../common/models/issue';
import { EditLock } from '../../../common/models/edit-lock';
import { getDate } from '../../../shared/helpers/date-helper';

class Attributes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Attributes.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to Attributes.ctor'); }
      if (typeof obj.displayName !== 'string') { throw new Error('Invalid obj.displayName param supplied to Attributes.ctor'); }
      if (typeof obj.parentBlockId !== 'number') { throw new Error('Invalid obj.parentBlockId param supplied to Attributes.ctor'); }
      if (typeof obj.relinquishmentTypeId !== 'number') { throw new Error('Invalid obj.relinquishmentTypeId param supplied to Attributes.ctor'); }
      if (typeof obj.blockNo !== 'string') { throw new Error('Invalid obj.blockNo param supplied to Attributes.ctor'); }
      if (obj.subArea !== null && typeof obj.subArea !== 'string') { throw new Error('Invalid obj.subArea param supplied to Attributes.ctor'); }
      if (typeof obj.licenceId !== 'number') { throw new Error('Invalid obj.licenceId param supplied to Attributes.ctor'); }
      if (typeof obj.corporate !== 'string') { throw new Error('Invalid obj.corporate param supplied to Attributes.ctor'); }
      if (typeof obj.equity !== 'number') { throw new Error('Invalid obj.equity param supplied to Attributes.ctor'); }
      if (typeof obj.coreStatus !== 'boolean') { throw new Error('Invalid obj.coreStatus param supplied to Attributes.ctor'); }
      if (typeof obj.isOperator !== 'boolean') { throw new Error('Invalid obj.isOperator param supplied to Attributes.ctor'); }
      if (obj.relinquishmentDate !== null && typeof obj.relinquishmentDate !== 'string') { throw new Error('Invalid obj.relinquishmentDate param supplied to Attributes.ctor'); }
      if (obj.comment !== null && typeof obj.comment !== 'string') { throw new Error('Invalid obj.comment param supplied to Attributes.ctor'); }
      if (obj.adminComment !== null && typeof obj.adminComment !== 'string') { throw new Error('Invalid obj.adminComment param supplied to Attributes.ctor'); }
      if (obj.commercialComment !== null && typeof obj.commercialComment !== 'string') { throw new Error('Invalid obj.commercialComment param supplied to Attributes.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.displayName = obj === null ? '' : obj.displayName;
    this.parentBlockId = obj === null ? -1 : obj.parentBlockId;
    this.relinquishmentTypeId = obj === null ? -1 : obj.relinquishmentTypeId;
    this.blockNo = obj === null ? '' : obj.blockNo;
    this.subArea = obj === null || obj.subArea === null ? '' : obj.subArea;
    this.licenceId = obj === null ? -1 : obj.licenceId
    this.corporate = obj === null ? '' : obj.corporate;
    this.equity = obj === null ? -1 : obj.equity;
    this.coreStatus = obj === null ? false : obj.coreStatus;
    this.isOperator = obj === null ? false : obj.isOperator;
    this.relinquishmentDate = obj === null ? null : getDate(obj.relinquishmentDate);
    this.comment = obj === null ? '' : obj.comment;
    this.adminComment = obj === null ? '' : obj.adminComment;
    this.commercialComment = obj === null ? '' : obj.commercialComment;

    this.status = obj === null ? '' : obj.status;
    this.originalStatus = obj === null ? '' : obj.originalStatus;
  }
}

export class Result {
  constructor(obj) {
    obj && Object.assign(this, obj);
    this.attributes = obj === null || obj === undefined ? new Attributes(null) : new Attributes(obj.attributes);
    this.issues = obj === null || obj === undefined ? [new Issue()] : obj.issues.map(item => new Issue(item));
    this.editLock = obj === null || obj === undefined ? new EditLock() : new EditLock(obj.editLock);
  }
}

export { Issue, EditLock, Attributes };