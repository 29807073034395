import React from 'react';
import PropTypes from 'prop-types';

import './right-panel-component.scss';

import ProductionComponent from './production-component';
import MapComponent from '../../../../../common/components/map/map-component';
import { Attributes, ResourceAnnual } from '../../../models/result';
import TabPanelComponent, { Tab } from '../../../../../shared/components/tab-panel/tab-panel-component';
import { LoadingGlobal, MessageBoxGlobal, PinLocationGlobal } from '../../../../../common/globals/globals';

const RightPanelComponent = (props) => {
  const { messageBoxGlobal, loadingGlobal, pinLocationGlobal, geoserverUrl, mapComponentName, contextLayerType, attributes, resourceAnnuals} = props;

  let points = attributes.points;
  let shapes = attributes.shapes;

  const tabs = [
    new Tab('Map',
      points === null || points === undefined ?
        <div className='no-resource-data'>A valid location could not be found</div>
        :
        <MapComponent
          messageBoxGlobal={messageBoxGlobal}
          loadingGlobal={loadingGlobal}
          pinLocationGlobal={pinLocationGlobal}
          geoserverUrl={geoserverUrl}
          mapComponentName={mapComponentName}
          contextLayerType={contextLayerType}
          points={points}
          shapes={[shapes]}
        />),
    new Tab('Production',
      resourceAnnuals === null || resourceAnnuals === undefined ?
        <div className='no-hub-data'>Production data could not be found</div>
        :
        <ProductionComponent resourceAnnuals={resourceAnnuals} />)
  ];

  return (
    <TabPanelComponent align="left" toolbarColor="secondary" toolbarItems={tabs} selectedItemIndex={0} />
  );
};

RightPanelComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  pinLocationGlobal: PropTypes.instanceOf(PinLocationGlobal).isRequired,
  resourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  attributes: PropTypes.instanceOf(Attributes).isRequired,
  geoserverUrl: PropTypes.string.isRequired,
  mapComponentName: PropTypes.string.isRequired,
  contextLayerType: PropTypes.number.isRequired,
};

export default RightPanelComponent;
