class KeyValue {
  constructor(obj) {
    if (obj !== null) {
      if (typeof obj !== 'object') {
        throw new Error('Invalid "obj" param supplied to "KeyValue.ctor"');
      }
      if (typeof obj.key !== 'number' && typeof obj.key !== 'string') {
        throw new Error('Invalid "obj.key" param supplied to "KeyValue.ctor"');
      }
      if (typeof obj.value !== 'string') {
        throw new Error('Invalid "obj.value" param supplied to "KeyValue.ctor"');
      }
    }

    this.key = obj === null ? typeof obj.key === 'number' ? -1 : '' : obj.key;
    this.value = obj === null ? '' : obj.value;
  }
}

class KeyValues {
  constructor(obj) {
    if (obj !== null) {
      if (typeof obj !== 'object') {
        throw new Error('Invalid "obj" param supplied to "KeyValues.ctor"');
      }
      if (typeof obj.key !== 'number' && typeof obj.key !== 'string') {
        throw new Error('Invalid "obj.key" param supplied to "KeyValues.ctor"');
      }
      if (!Array.isArray(obj.value)) {
        throw new Error('Invalid "obj.value" param supplied to "KeyValues.ctor"');
      }
    }

    this.key = obj === null ? typeof obj.key === 'number' ? -1 : '' : obj.key;
    this.value = obj === null ? '' : obj.value.map(x => new KeyValue(x));

  }
}

class NewWellDataResult {
  constructor(obj) {
    if (obj !== null) {
      if (typeof obj !== 'object') {
        throw new Error('Invalid "obj" param supplied to "NewWellDataResult.ctor"');
      }
      if (!Array.isArray(obj.countries)) {
        throw new Error('Invalid "obj.countries" param supplied to "NewWellDataResult.ctor"');
      }
      if (typeof obj.gmWellProgrammes !== 'object') {
        throw new Error('Invalid "obj.gmWellProgrammes" param supplied to "NewWellDataResult.ctor"');
      }
    }

    this.countries = obj === null ? null : obj.countries.map(x => new KeyValue(x));
    this.gmWellProgrammes = obj === null ? null : obj.gmWellProgrammes.map(x => new KeyValues(x));
  }
}

export { NewWellDataResult, KeyValue, KeyValues };