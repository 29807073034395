class Lookup {
  key = -1;
  value = '';

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

class LookupResult {
  constructor(obj) {
    obj && Object.assign(this, obj);

    if (obj !== undefined) {
      Object.keys(obj).map(key => this[key] = obj[key].map(kvp => new Lookup(kvp)));
    }
  }
}

export { Lookup, LookupResult };