import React from 'react';
import PropTypes from 'prop-types';

import './countries-component.scss';
import CheckedDropdownComponent from '../../shared/components/checked-dropdown/checked-dropdown-component';
import { CountriesGlobal } from '../../common/globals/globals';

const CountriesComponent = (props) => {
  const { editing, countriesGlobal, countries } = props;

  const getCountriesText = () => {
    const result = countries.filter(country => countriesGlobal.value.includes(country.countryId)).map(country => country.countryName);
    if (result.length === 0) {
      return 'None';
    } else if (result.length === countries.length) {
      return 'All';
    } else if (result.length > 2) {
      return 'Multiple';
    }
    return result.join(', ');
  };

  const onChange = (country) => {
    let newCountryIds = [...countriesGlobal.value];

    const index = newCountryIds.indexOf(country.countryId);
    if (index === -1) {
      newCountryIds.push(country.countryId);
    } else {
      newCountryIds.splice(index, 1);
    }

    if (newCountryIds.length > 0) {
      newCountryIds = newCountryIds.sort((a, b) => a - b);
      countriesGlobal.set(newCountryIds);
    }
  };

  const onSelectAll = () => {
    countriesGlobal.set(countries.map(obj => obj.countryId));
  };

  return (
    <div className="countries-component">
      <CheckedDropdownComponent
        className=''
        disabled={editing}
        keyName="countryId"
        items={countries}
        text={getCountriesText()}
        valueName="countryName"
        selectedItems={countriesGlobal.value}
        onChange={onChange}
        onSelectAll={onSelectAll}
      />
    </div>
  );
};

CountriesComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  countriesGlobal: PropTypes.instanceOf(CountriesGlobal).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({
    countryId: PropTypes.number.isRequired,
    countryName: PropTypes.string.isRequired
  })).isRequired
};

export default CountriesComponent;
