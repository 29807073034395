import React from 'react';
import PropTypes from 'prop-types';

import './right-panel-component.scss';

import MapComponent from '../../../../../common/components/map/map-component';
import ProductionComponent from './production-component';
import TabPanelComponent, { Tab } from '../../../../../shared/components/tab-panel/tab-panel-component';
import { ChartData } from '../../../../../shared/components/generic-chart/chart-data'
import { MessageBoxGlobal, LoadingGlobal, PinLocationGlobal } from '../../../../../common/globals/globals';

const RightPanelComponent = (props) => {
  const { messageBoxGlobal, loadingGlobal, pinLocationGlobal, productionByField, geoserverUrl, mapComponentName, contextLayerType, shape } = props;

  const tabs = [
    new Tab('Map',
      shape === null || shape === undefined ?
        <div className='no-hub-data'>A valid location could not be found</div>
        :
        <MapComponent
          messageBoxGlobal={messageBoxGlobal}
          loadingGlobal={loadingGlobal}
          pinLocationGlobal={pinLocationGlobal}
          geoserverUrl={geoserverUrl}
          mapComponentName={mapComponentName}
          contextLayerType={contextLayerType}
          points={[shape]} />),
    new Tab('Production',
      productionByField === null || productionByField === undefined ?
        <div className='no-hub-data'>Production data could not be found</div>
        :
        <ProductionComponent productionByField={productionByField} />)
  ];

  return (
    <TabPanelComponent align="left" toolbarColor="secondary" toolbarItems={tabs} selectedItemIndex={0} />
  );
};

RightPanelComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  pinLocationGlobal: PropTypes.instanceOf(PinLocationGlobal).isRequired,
  productionByField: PropTypes.instanceOf(ChartData),
  geoserverUrl: PropTypes.string.isRequired,
  mapComponentName: PropTypes.string.isRequired,
  contextLayerType: PropTypes.number.isRequired,
  shape: PropTypes.object,
};

export default RightPanelComponent;