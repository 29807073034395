import React from 'react';
import PropTypes from 'prop-types';

import { Attributes } from '../../models/result';
import { IN_DEV_MODE } from '../../../../shared/helpers/common';
import { LookupResult } from '../../../../common/models/lookup-result';
import PropertyGridComponent, { validateDate, PropertyGridStringValue, PropertyGridSelectValue, PropertyGridDateValueUTC, getDate } from '../../../../shared/components/property-grid/property-grid-component';
import { MessageBoxGlobal } from '../../../../common/globals/globals';

const IDS = {
  ID: 5,
  LICENCE_NO: 10,
  ROUND_ID: 15,
  ORIGINAL_LICENCE_TYPE_ID: 20,
  LICENCE_TYPE_ID: 25,
  START_DATE: 30,
  END_DATE: 35,
  OFFICIAL_UID: 40,
  COUNTRY_NAME: 45,
};
Object.freeze(IDS);

const getSections = (attributes, lookups, valueChanged) => {

  const result = [
    {
      label: 'Licence',
      rows: [
        { label: 'Country', values: [new PropertyGridStringValue(IDS.COUNTRY_NAME, attributes.countryName)] },
        { label: 'Licence No', values: [new PropertyGridStringValue(IDS.LICENCE_NO, attributes.licenceNumber, valueChanged)] },
        { label: 'Licence Round', values: [new PropertyGridSelectValue(IDS.ROUND_ID, attributes.licenceRoundId, valueChanged, 'key', 'value', lookups.LicenceRounds, true)] },
        { label: 'Original Licence Type', values: [new PropertyGridSelectValue(IDS.ORIGINAL_LICENCE_TYPE_ID, attributes.originalLicenceTypeId, valueChanged, 'key', 'value', lookups.LicenceType, true)] },
        { label: 'Subsequent Licence Type', values: [new PropertyGridSelectValue(IDS.LICENCE_TYPE_ID, attributes.licenceTypeId, valueChanged, 'key', 'value', lookups.LicenceType, true)] },
        { label: 'Start Date', values: [new PropertyGridDateValueUTC(IDS.START_DATE, attributes.startDate, valueChanged)] },
        { label: 'End Date', values: [new PropertyGridDateValueUTC(IDS.END_DATE, attributes.endDate, valueChanged)] },
        { label: 'Official UID', values: [new PropertyGridStringValue(IDS.OFFICIAL_UID, attributes.officalUID, valueChanged)] },
      ]
    },
  ];

  if (IN_DEV_MODE) result[0].rows.unshift({ label: 'Licence Id', values: [new PropertyGridStringValue(IDS.ID, attributes.id)] });
  return result;
};

const LicenceAttributesComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    lookups,
    attributes,
    updateAttributes,
  } = props;

  const valueChanged = (value, id) => {
    switch (id) {
      case IDS.LICENCE_NO: attributes.licenceNumber = value; break;
      case IDS.ROUND_ID: attributes.licenceRoundId = Number(value); break;
      case IDS.ORIGINAL_LICENCE_TYPE_ID: attributes.originalLicenceTypeId = Number(value); break;
      case IDS.LICENCE_TYPE_ID: attributes.licenceTypeId = Number(value); break;
      case IDS.START_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid Start Date');
          return false;
        }
        attributes.startDate = value === undefined ? attributes.startDate = null : getDate(value); break;

      case IDS.END_DATE: 
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid End Date');
          return false;
        }
        attributes.endDate = value === undefined ? attributes.endDate = null : getDate(value); break;
      case IDS.OFFICIAL_UID: attributes.officalUID = value; break;

      default: throw new Error('Id ' + id + ' is not supported in LeftPanelComponent.valueChanged');
    }
    updateAttributes(attributes)
    return true;
  };

  const sections = getSections(attributes, lookups, valueChanged);

  return <PropertyGridComponent sections={sections} readOnly={!editing} />;
};

LicenceAttributesComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired,
  attributes: PropTypes.instanceOf(Attributes).isRequired,
  updateAttributes: PropTypes.func.isRequired,
};

export default LicenceAttributesComponent;
