import React from "react";
import PropTypes from 'prop-types';

import { MessageBoxGlobal } from '../../common/globals/globals';

import AGGridComponent, { COLUMN_MEDIUM, COLUMN_MEDIUM_MEDIUM, COLUMN_EXTRA_LARGE, COLUMN_WIDE } from '../../common/components/grid/ag-grid-component';

const NSTACompareGridComponent = (props) => {
  const { messageBoxGlobal, data, type } = props;

  const columnDefs = [];

  switch (type) {
    case 1: //GM Blocks Not In NSTA
      columnDefs.push({ headerName: 'WGE Block Id', suppressHeaderMenuButton: true, resizable: true, field: 'blockId', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      columnDefs.push({ headerName: 'WGE Block No', suppressHeaderMenuButton: true, resizable: true, field: 'wgeBlockNo', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'Sub Area', suppressHeaderMenuButton: true, resizable: true, field: 'subArea', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'text' });
      columnDefs.push({ headerName: 'Block Suffix', suppressHeaderMenuButton: true, resizable: true, field: 'blockSuffix', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'Sub Area Abbrev', suppressHeaderMenuButton: true, resizable: true, field: 'subAreaAbbrev', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'Licence No', suppressHeaderMenuButton: true, resizable: true, field: 'licenceNo', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      break;

    case 2: //NSTA Blocks Not In GM
      columnDefs.push({ headerName: 'Block', suppressHeaderMenuButton: true, resizable: true, field: 'block', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'Sub Area', suppressHeaderMenuButton: true, resizable: true, field: 'subArea', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'text' });
      break;

    case 3: //NSTA Companies Not In GM
      columnDefs.push({ headerName: 'Company', suppressHeaderMenuButton: true, resizable: true, field: 'corporate', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'text' });
      break;

    case 4: //NSTA Ownership
      columnDefs.push({ headerName: 'Block', suppressHeaderMenuButton: true, resizable: true, field: 'block', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'Sub Area', suppressHeaderMenuButton: true, resizable: true, field: 'subArea', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'text' });
      columnDefs.push({ headerName: 'Company', suppressHeaderMenuButton: true, resizable: true, field: 'corporate', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'text' });
      columnDefs.push({ headerName: 'Equity %', suppressHeaderMenuButton: true, resizable: true, field: 'equity', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'numeric' });
      break;

    case 5: //WGE Ownership
      columnDefs.push({ headerName: 'Block', suppressHeaderMenuButton: true, resizable: true, field: 'block', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'Company', suppressHeaderMenuButton: true, resizable: true, field: 'corporate', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'text' });
      columnDefs.push({ headerName: 'Equity %', suppressHeaderMenuButton: true, resizable: true, field: 'equity', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'numeric' });
      break;

    case 6: //WGE vs NSTA Ownership Diff
      columnDefs.push({ headerName: 'Block Id', suppressHeaderMenuButton: true, resizable: true, field: 'blockId', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      columnDefs.push({ headerName: 'WGE Block No', suppressHeaderMenuButton: true, resizable: true, field: 'wgeBlockNo', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'WGE Sub Area', suppressHeaderMenuButton: true, resizable: true, field: 'wgeSubArea', sortable: true, filter: false, editable: false, width: COLUMN_EXTRA_LARGE, type: 'text' });
      columnDefs.push({ headerName: 'WGE Corporate', suppressHeaderMenuButton: true, resizable: true, field: 'wgeCorporate', sortable: true, filter: false, editable: false, width: COLUMN_EXTRA_LARGE, type: 'text' });
      columnDefs.push({ headerName: 'WGE Equity %', suppressHeaderMenuButton: true, resizable: true, field: 'wgeEquity', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      columnDefs.push({ headerName: 'WGE Licence', suppressHeaderMenuButton: true, resizable: true, field: 'wgeLicence', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'NSTA Block', suppressHeaderMenuButton: true, resizable: true, field: 'nstaBlock', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'NSTA Sub Area', suppressHeaderMenuButton: true, resizable: true, field: 'nstaSubArea', sortable: true, filter: false, editable: false, width: COLUMN_EXTRA_LARGE, type: 'text' });
      columnDefs.push({ headerName: 'NSTA Corporate', suppressHeaderMenuButton: true, resizable: true, field: 'nstaCorporate', sortable: true, filter: false, editable: false, width: COLUMN_EXTRA_LARGE, type: 'text' });
      columnDefs.push({ headerName: 'NSTA Equity %', suppressHeaderMenuButton: true, resizable: true, field: 'nstaEquity', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      break;

    case 7: //NSTA vs WGE Ownership Diff
      columnDefs.push({ headerName: 'Block Id', suppressHeaderMenuButton: true, resizable: true, field: 'blockId', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      columnDefs.push({ headerName: 'WGE Block No', suppressHeaderMenuButton: true, resizable: true, field: 'wgeBlockNo', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'WGE Sub Area', suppressHeaderMenuButton: true, resizable: true, field: 'wgeSubArea', sortable: true, filter: false, editable: false, width: COLUMN_EXTRA_LARGE, type: 'text' });
      columnDefs.push({ headerName: 'WGE Corporate', suppressHeaderMenuButton: true, resizable: true, field: 'wgeCorporate', sortable: true, filter: false, editable: false, width: COLUMN_EXTRA_LARGE, type: 'text' });
      columnDefs.push({ headerName: 'WGE Equity %', suppressHeaderMenuButton: true, resizable: true, field: 'wgeEquity', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      columnDefs.push({ headerName: 'WGE Licence', suppressHeaderMenuButton: true, resizable: true, field: 'wgeLicence', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'NSTA Block', suppressHeaderMenuButton: true, resizable: true, field: 'nstaBlock', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'text' });
      columnDefs.push({ headerName: 'NSTA Sub Area', suppressHeaderMenuButton: true, resizable: true, field: 'nstaSubArea', sortable: true, filter: false, editable: false, width: COLUMN_EXTRA_LARGE, type: 'text' });
      columnDefs.push({ headerName: 'NSTA Corporate', suppressHeaderMenuButton: true, resizable: true, field: 'nstaCorporate', sortable: true, filter: false, editable: false, width: COLUMN_EXTRA_LARGE, type: 'text' });
      columnDefs.push({ headerName: 'NSTA Equity %', suppressHeaderMenuButton: true, resizable: true, field: 'nstaEquity', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      break;

    case 8: //NSTA to WGE Corp Match
      columnDefs.push({ headerName: 'NSTA Corporate', suppressHeaderMenuButton: true, resizable: true, field: 'nstaCorporate', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'text' });
      columnDefs.push({ headerName: 'WGE Corporate', suppressHeaderMenuButton: true, resizable: true, field: 'wgeCorporate', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'text' });
      break;

    default:
    // code block
  }

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {},
    image: {}
  };

  return (
    <AGGridComponent
      messageBoxGlobal={messageBoxGlobal}
      columnDefs={columnDefs}
      rowsCount={data.length}
      columnTypes={columnTypes}
      gridData={data}
      allowPaste={false}
      editYear={0}
      autoResize
    />
  );
};

NSTACompareGridComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
};

export default NSTACompareGridComponent;
