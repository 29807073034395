import React from 'react';
import PropTypes from 'prop-types';
import './nsta-licence-compare.scss';

import { apiGet } from '../../common/helpers/api-helpers';
import { LoadingGlobal, MessageBoxGlobal } from '../../common/globals/globals';
import TabPanelComponent, { Tab } from '../../shared/components/tab-panel/tab-panel-component';
import NSTACompareGridComponent from '../nsta-licence-compare/nsta-compare-grid-component';

class NSTALicenceCompareView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { compareData: null };
  };

  get apiPrefix() { return 'api/nsta-data/' };

  componentDidMount() {
    this.initCompareData();
  }

  initCompareData() {
    const onSuccess = (result) => this.setState({ compareData: result });
    apiGet(this.apiPrefix + 'wge-vs-nsta-licence-data', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, undefined);
  }

  render() {
    const { compareData } = this.state;

    let hasCompareData = compareData !== null && compareData !== undefined;

    let hasWGELicencedNotInNSTA = hasCompareData && compareData.wgeLicencedNotInNSTA !== null;
    let wgeLicencedNotInNSTACount = hasWGELicencedNotInNSTA ? compareData.wgeLicencedNotInNSTA.length : 0;

    let hasNSTANotInWGE = hasCompareData && compareData.nstaNotInWGE !== null;
    let nstaNotInWGECount = hasNSTANotInWGE ? compareData.nstaNotInWGE.length : 0;

    let hasNSTACompaniesNotInWGE = hasCompareData && compareData.nstaCompaniesNotInWGE !== null;
    let nstaCompaniesNotInWGECount = hasNSTACompaniesNotInWGE ? compareData.nstaCompaniesNotInWGE.length : 0;

    let hasNSTAOwnership = hasCompareData && compareData.nstaOwnership !== null;
    let nstaOwnershipCount = hasNSTAOwnership ? compareData.nstaOwnership.length : 0;

    let hasWGEOwnership = hasCompareData && compareData.wgeOwnership !== null;
    let wgeOwnershipCount = hasNSTAOwnership ? compareData.wgeOwnership.length : 0;

    let hasWGEvsNSTADifferences = hasCompareData && compareData.wgEvsNSTADifferences !== null;
    let wgEvsNSTADifferencesCount = hasWGEvsNSTADifferences ? compareData.wgEvsNSTADifferences.length : 0;

    let hasNSTAvsWGEDifferences = hasCompareData && compareData.nstAvsWGEDifferences !== null;
    let nstAvsWGEDifferencesCount = hasWGEvsNSTADifferences ? compareData.nstAvsWGEDifferences.length : 0;

    let hasNSTAToWGECorporates = hasCompareData && compareData.nstaToWGECorporates !== null;
    let nstaToWGECorporatesCount = hasNSTAToWGECorporates ? compareData.nstaToWGECorporates.length : 0;

    const tabs = [
      new Tab('GM Blocks Not In NSTA (' + wgeLicencedNotInNSTACount.toString() + ')', hasWGELicencedNotInNSTA ? <NSTACompareGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={compareData.wgeLicencedNotInNSTA} type={1} /> : null),
      new Tab('NSTA Blocks Not In GM (' + nstaNotInWGECount.toString() + ')', hasNSTANotInWGE ? <NSTACompareGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={compareData.nstaNotInWGE} type={2} /> : null),
      new Tab('NSTA Companies Not In GM (' + nstaCompaniesNotInWGECount.toString() + ')', hasNSTACompaniesNotInWGE ? <NSTACompareGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={compareData.nstaCompaniesNotInWGE} type={3} /> : null),
      new Tab('NSTA Ownership (' + nstaOwnershipCount.toString() + ')', hasNSTAOwnership ? <NSTACompareGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={compareData.nstaOwnership} type={4} /> : null),
      new Tab('WGE Ownership (' + wgeOwnershipCount.toString() + ')', hasWGEOwnership ? <NSTACompareGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={compareData.wgeOwnership} type={5} /> : null),
      new Tab('WGE vs NSTA Ownership Diff (' + wgEvsNSTADifferencesCount.toString() + ')', hasWGEvsNSTADifferences ? <NSTACompareGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={compareData.wgEvsNSTADifferences} type={6} /> : null),
      new Tab('NSTA vs WGE Ownership Diff (' + nstAvsWGEDifferencesCount.toString() + ')', hasNSTAvsWGEDifferences ? <NSTACompareGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={compareData.nstAvsWGEDifferences} type={7} /> : null),
      new Tab('NSTA to WGE Corp Match (' + nstaToWGECorporatesCount.toString() + ')', hasNSTAToWGECorporates ? <NSTACompareGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={compareData.nstaToWGECorporates} type={8} /> : null),

    ];
    return (
      <div className="nsta-licence-compare-view">
        <h1>NSTA Licence Compare</h1>
        <TabPanelComponent toolbarItems={tabs} selectedItemIndex={0} />
      </div>
    );
  }

};

NSTALicenceCompareView.propTypes = {
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
}

export default NSTALicenceCompareView;