import L from 'leaflet';

import { addZoomControl } from '../controls/zoom-control';
import { addScaleControl } from '../controls/scale-control';
import { addNavbarControl } from '../controls/navbar-control';
import { ContextLayerController } from './context-layer-controller';
import { addCoordinatesControl } from '../controls/coordinates-control';

import { SearchControl } from '../controls/search/search-control';
import { SearchResultsControl } from '../controls/search-results/search-results-control';
import { SearchButtonsControl } from '../controls/search-buttons/search-buttons-control';
import { GMEButtonsControl } from '../controls/gme-buttons/gme-buttons-control';
import { LayersControl } from '../controls/layers/layers-control';

import { addPinLocationCoordinatesControl } from '../controls/pin-location-coordinates-control';

class ControlWrapper {
  constructor(addFunc, removeFunc, visible = true) {
    if (typeof addFunc !== 'function') {
      throw new Error('Invalid \'addFunc\' param supplied to \'ControlWrapper.ctor\'');
    }
    if (typeof removeFunc !== 'function') {
      throw new Error('Invalid \'removeFunc\' param supplied to \'ControlWrapper.ctor\'');
    }
    if (typeof visible !== 'boolean') {
      throw new Error('Invalid \'visible\' param supplied to \'ControlWrapper.ctor\'');
    }

    this._control = null;
    this.show = () => {
      if (this._control === null) {
        this._control = addFunc();
      }
    };
    this.hide = () => {
      if (this._control !== null) {
        removeFunc(this._control);
        this._control = null;
      }
    }

    if (visible) {
      this.show();
    } else {
      this.hide();
    }
  }

  get visible() { return this._control !== null; }
  set visible(value) {
    if (typeof value !== 'boolean') {
      throw new Error('Invalid \'value\' param supplied to \'ControlWrapper.visible.set\'');
    }

    if (this.visible !== value) {
      if (value) {
        this.show();
      } else {
        this.hide();
      }
    }
  }
}

class MapControls {
  constructor(leafletMap, contextLayerController, excelExport = null, isGME = undefined) {
    if (!(leafletMap instanceof L.Map)) {
      throw new Error('Invalid \'leafletMap\' param supplied to \'MapControls.ctor\'');
    }
    if (!(contextLayerController instanceof ContextLayerController)) {
      throw new Error('Invalid \'contextLayerController\' param supplied to \'MapControls.ctor\'');
    }

    const removeFunc = leafletMap.removeControl;
    this.layers = new LayersControl(leafletMap, contextLayerController);
    this.zoom = new ControlWrapper(() => addZoomControl(leafletMap, 'topleft'), removeFunc);
    this.scale = new ControlWrapper(() => addScaleControl(leafletMap, 'bottomleft'), removeFunc);
    this.coordinates = new ControlWrapper(() => addCoordinatesControl(leafletMap, 'bottomright'), removeFunc);
    this.pinCoordinates = new ControlWrapper(() => addPinLocationCoordinatesControl(leafletMap, 'topright'), removeFunc);
    this.pinCoordinates.visible = false;
    this.navbar = new ControlWrapper(() => addNavbarControl(leafletMap, 'topleft'), removeFunc);
    if (isGME) {
      this.search = undefined;
      this.searchButtons = undefined;
      this.searchResults = undefined;
      this.gmeButtons = new GMEButtonsControl(leafletMap);  
    }
    else {
      this.search = new SearchControl(leafletMap);
      this.searchButtons = new SearchButtonsControl(leafletMap);
      this.searchResults = new SearchResultsControl(leafletMap, excelExport);
      this.gmeButtons = undefined;
    }
  }

  destroy() {

    if (this.layers !== null) {
      this.layers.destroy();
    }

    delete this.layers;
    delete this.zoom;
    delete this.scale;
    delete this.coordinates;
    delete this.pinCoordinates;
    delete this.navbar;
    delete this.search;
    delete this.searchButtons;
    delete this.searchResults;
    delete this.gmeButtons;

    delete this;
  }

}

export { MapControls };