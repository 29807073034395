const QueryParamType = {
  string: 12,
  date: 31
};
Object.freeze(QueryParamType);

class QueryParams {
  queryDisplayName = '';
  param1DisplayName = null;
  param1Type = null;
  param2DisplayName = null;
  param2Type = null;

  get param1IsSet() { return this.param1DisplayName !== null && this.param1Type !== null };
  get param2IsSet() { return this.param2DisplayName !== null && this.param2Type !== null };

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

export { QueryParamType, QueryParams };