import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './tab-panel-component.scss';
import TabComponent, { Tab } from './tab-component';
import ToolbarPanelComponent, { DEFAULT_TOOLBAR_COLOR, TOOLBAR_COLORS } from '../toolbar-panel/toolbar-panel-component';

class TabPanelComponent extends Component {
  constructor(props) {
    super(props);

    this.handleTabClick = this.handleTabClick.bind(this);
    this.state = { selectedItemIndex: 0 };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.toolbarItems === null || prevProps.toolbarItems === undefined || this.props.toolbarItems === null || this.props.toolbarItems === undefined) {
      this.setState({ selectedItemIndex: 0 });
    }
    else if (JSON.stringify(prevProps.toolbarItems.map(x => x.header)) !== JSON.stringify(this.props.toolbarItems.map(x => x.header))) {
      this.setState({ selectedItemIndex: 0 });
    }
  }

  handleTabClick(index) {
    this.setState(prevState => prevState.selectedItemIndex === index ? {} : { selectedItemIndex: index });
  }

  render() {
    const { selectedItemIndex } = this.state;
    const tabs = this.props.toolbarItems.filter(item => item instanceof Tab);
    const toolbarItems = this.props.toolbarItems.map(item => {
      const index = tabs.indexOf(item);
      if (index === -1) {
        return item;
      }
      return (
        <TabComponent align={this.props.align} onClick={() => this.handleTabClick(index)} selected={selectedItemIndex === index} >
          {item.header}
        </TabComponent>
      );
    });

    return (
      <ToolbarPanelComponent align={this.props.align} header={this.props.header} toolbarColor={this.props.toolbarColor} toolbarItems={toolbarItems} >
        {tabs.length === 0 || selectedItemIndex < 0 || selectedItemIndex > tabs.length - 1 ? null : tabs[selectedItemIndex].content}
      </ToolbarPanelComponent>
    );
  }
}

TabPanelComponent.propTypes = {
  align: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  toolbarItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(Tab)])),
  toolbarColor: PropTypes.oneOf(TOOLBAR_COLORS).isRequired,
  selectedItemIndex: PropTypes.number.isRequired
};

TabPanelComponent.defaultProps = {
  align: 'top',
  header: null,
  toolbarItems: [],
  toolbarColor: DEFAULT_TOOLBAR_COLOR,
  selectedItemIndex: -1
};

export default TabPanelComponent;
export { Tab };
