import React from "react";
import PropTypes from 'prop-types';
import { EmailRecipientAttributes } from './models/result';
import { MessageBoxGlobal } from '../../common/globals/globals';
import { Button, Input } from 'reactstrap';

const HermesEmailListComponent = (props) => {
  const title = "Emails Receiving Alerts";
  const { emailList, editting } = props;
  if (emailList === null || emailList === 'undefined') return null;

  const emailValueChanged = (e, id) => {
    props.onChange(e.target.value, id);
  }

  const deleteEmail = (id) => {
    props.onDeleteEmail(id);
  }

  return (
    <div className="hermes-email-view">
      <h4 className="title">{title}</h4>
      {emailList.map(x => (
        <div key={x.recipientId}>
          <span className="email-title">Email</span>
          <Input className="email" value={x.emailAddress} type="text" disabled={!editting} onChange={(e) => emailValueChanged(e, x.recipientId)} />  
          <Button className="emailBtn" hidden={!editting} onClick={() => deleteEmail(x.recipientId)} >Delete</Button>
        </div>))}
    </div>
  );
};

HermesEmailListComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  emailList: PropTypes.arrayOf(PropTypes.instanceOf(EmailRecipientAttributes)).isRequired,
  onDeleteEmail: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  editting: PropTypes.bool.isRequired
};

export default HermesEmailListComponent;

