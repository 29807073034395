import { RECORD_STATUS } from './record-status';

export class ResourceWell {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to ResourceWell.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.Id param supplied to ResourceWell.ctor'); }
      if (typeof obj.resourceId !== 'number') { throw new Error('Invalid obj.resourceId param supplied to ResourceWell.ctor'); }
      if (typeof obj.wellId !== 'number') { throw new Error('Invalid obj.wellId param supplied to ResourceWell.ctor'); }
      if (typeof obj.countryId !== 'number') { throw new Error('Invalid obj.countryId param supplied to ResourceWell.ctor'); }
      if (typeof obj.isDiscoveryWell !== 'boolean') { throw new Error('Invalid obj.isDiscoveryWell param supplied to ResourceWell.ctor'); }
      if (typeof obj.resourceStatusParentId !== 'number') { throw new Error('Invalid obj.resourceStatusParentId param supplied to ResourceWell.ctor'); }
      if (obj.resourceName !== undefined && obj.resourceName !== null && typeof obj.resourceName !== 'string') { throw new Error('Invalid obj.resourceName param supplied to ResourceWell.ctor'); }
      if (obj.wellName !== undefined && obj.wellName !== null && typeof obj.wellName !== 'string') { throw new Error('Invalid obj.wellName param supplied to ResourceWell.ctor'); }
      if (obj.selected !== undefined && obj.selected !== null && typeof obj.selected !== 'boolean') { throw new Error('Invalid obj.selected param supplied to ResourceWell.ctor'); }
    }

    this.id = obj === null ? -1 : obj.id;
    this.resourceId = obj === null ? -1 : obj.resourceId;
    this.wellId = obj === null ? -1 : obj.wellId;
    this.countryId = obj === null ? -1 : obj.countryId;
    this.isDiscoveryWell = obj === null ? false : obj.isDiscoveryWell;
    this.resourceStatusParentId = obj === null ? -1 : obj.resourceStatusParentId;
    this.resourceName = obj === null ? '' : obj.resourceName;
    this.wellName = obj === null ? '' : obj.wellName;
    this.status = obj === null ? '' : obj.status;
    this.selected = obj === null ? false : obj.selected;
  }

  getNew() {
    this.resourceId = -1;
    this.wellId = -1;
    this.isDiscoveryWell = false;
    this.resourceStatusParentId = -1;
    this.countryId = 0;
    this.resourceName = '-- New Resource --';
    this.wellName = '-- New Well --';
    this.status = RECORD_STATUS.added;
    this.selected = true;
  }
}
