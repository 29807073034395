import { ResourceProductionAttributes } from '../models/production-result';
import { RECORD_STATUS } from '../../../common/models/record-status';

const editResourceAttributes = (item) => {
  if (!(item instanceof ResourceProductionAttributes)) {
    throw new Error('Invalid item param supplied to editResourceAttributes');
  }

  item.status = item.status === RECORD_STATUS.added
    ? item.status
    : RECORD_STATUS.edited;
};

export { editResourceAttributes };