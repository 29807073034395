import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { MessageBoxGlobal } from '../../../../../common/globals/globals';
import { HubProductionAttributes } from '../../../models/production-result';
import { LookupResult } from '../../../../../common/models/lookup-result';
import { valueTypes } from '../../../../../shared/helpers/value-handler';

import PropertyGridComponent, { validateDate, PropertyGridStringValue, PropertyGridDateValue, PropertyGridBooleanValue, PropertyGridSelectValue } from '../../../../../shared/components/property-grid/property-grid-component';

const IDS = {
  HUB_NAME: 0,
  PRODUCTION_CESSATION_DATE: 15,
  CO2_EMISSIONS_METHOD_ID: 30,
  CH4_EMISSIONS_METHOD_ID: 45,
  HUB_ELECTRIFICATION_STATUS_ID: 60,
  EMISSIONS_COMMENT: 75,
  APPLY_ETS: 80,
  APPLY_CARBON_CHARGE: 85,
};
Object.freeze(IDS);

const getSections = (hubAttributes, lookups, valueChanged) => {
  return [
    {
      label: 'Hub Opex',
      rows: [
        { label: 'Hub Name', values: [new PropertyGridStringValue(IDS.HUB_NAME, hubAttributes.hubName)] }
      ],
    },
    {
      label: 'Dates',
      rows: [
        { label: 'Production Cessation Date', values: [new PropertyGridDateValue(IDS.PRODUCTION_CESSATION_DATE, hubAttributes.productionEndDate, valueChanged)] }
      ]
    },
    {
      label: 'Hub Emissions',
      rows: [
        { label: valueTypes.UnitString(valueTypes.CO2) + ' Emissions Method', values: [new PropertyGridSelectValue(IDS.CO2_EMISSIONS_METHOD_ID, hubAttributes.co2EmissionsMethodId, valueChanged, 'key', 'value', lookups.FutureEmissions)] },
        { label: valueTypes.UnitString(valueTypes.CH4) + ' Emissions Method', values: [new PropertyGridSelectValue(IDS.CH4_EMISSIONS_METHOD_ID, hubAttributes.ch4EmissionsMethodId, valueChanged, 'key', 'value', lookups.FutureEmissions)] },
        { label: 'Hub Electrification Status', values: [new PropertyGridSelectValue(IDS.HUB_ELECTRIFICATION_STATUS_ID, hubAttributes.hubElectrificationStatusId, valueChanged, 'key', 'value', lookups.HubElectrification)] },
        { label: 'Client Emissions Comment', values: [new PropertyGridStringValue(IDS.EMISSIONS_COMMENT, hubAttributes.emissionsComment, valueChanged)] },
        { label: 'Apply ETS', values: [new PropertyGridBooleanValue(IDS.APPLY_ETS, hubAttributes.applyEts, valueChanged)] },
        { label: 'Apply Carbon Charge', values: [new PropertyGridBooleanValue(IDS.APPLY_CARBON_CHARGE, hubAttributes.applyCarbonCharge, valueChanged)] }
      ],
    },
  ];
};

const HubAttributesComponent = (props) => {
  const {
    editing,
    messageBoxGlobal,
    hubAttributes,
    hubAttributeActions,
    lookups
  } = props;


  const valueChanged = (value, id) => {
    switch (id) {
      case IDS.PRODUCTION_CESSATION_DATE:
        if (!validateDate(value, messageBoxGlobal)) {
          messageBoxGlobal.showWarning('Invalid Production Cessation Date');
          return false;
        }
        hubAttributes.productionEndDate = value === undefined ? hubAttributes.productionEndDate = null : moment(value).format('YYYY-MM-DD[T]hh:mm:ss.sss'); break;
      case IDS.CO2_EMISSIONS_METHOD_ID: hubAttributes.co2EmissionsMethodId = value; break;
      case IDS.CH4_EMISSIONS_METHOD_ID: hubAttributes.ch4EmissionsMethodId = value; break;
      case IDS.HUB_ELECTRIFICATION_STATUS_ID: hubAttributes.hubElectrificationStatusId = Number(value); break;
      case IDS.EMISSIONS_COMMENT: hubAttributes.emissionsComment = value; break;
      case IDS.APPLY_ETS: hubAttributes.applyEts = value; break;
      case IDS.APPLY_CARBON_CHARGE: hubAttributes.applyCarbonCharge = value; break;
      default: throw new Error('Id ' + id + ' is not supported in AttributesComponent.valueChanged');
    }

    hubAttributeActions.onEdit(hubAttributes);
    return true;
  };

  const sections = getSections(hubAttributes, lookups, valueChanged);

  return <PropertyGridComponent sections={sections} readOnly={!editing} />;
};

HubAttributesComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  hubAttributes: PropTypes.instanceOf(HubProductionAttributes).isRequired,
  hubAttributeActions: PropTypes.shape({ onEdit: PropTypes.func.isRequired }).isRequired,
  lookups: PropTypes.instanceOf(LookupResult).isRequired
};

export default HubAttributesComponent;
