import React from 'react';
import './production-chart.scss';
import { appendClassNames } from '../../../shared/helpers/common';
import cloneDeep from 'lodash/cloneDeep';
import Functions from './chart-functions';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
);

const ProductionChart = (props) => {
  const { className, entityData, isStacked, chartType, xTitle, yTitle } = props;  //, hubMinYear, hubMaxYear, maxCombinedValue - required if zeros years put back in

  var prodData = cloneDeep(entityData);

  //round to 1 d.p.
  var entityDataLength = prodData.length;
  for (let val = 0; val < entityDataLength; val++) {
    prodData[val].liquidMbod = Functions.round(prodData[val].liquidMbod, 1);
    prodData[val].gasMboed = Functions.round((prodData[val].gasMmscfd / 6), 1);
  }
    //sort entityData by year - mainly needed if adding the zero value years
  prodData.sort((a, b) => a.year - b.year);

  var chartData = {
    labels: prodData.map(r => r.year),
    datasets: [{
      fill: 'origin',
      label: "Liquid",
      backgroundColor: 'green',
      borderColor: 'green',
      data: prodData.map(r => r.liquidMbod),
      //pointRadius: 10
      elements: {
        point: {
          radius: 0,
          hitRadius: 1,
          hoverRadius: 25,
        }
      } 
    }, {
      fill: 'origin',
      label: "Gas",
      backgroundColor: 'red',
      borderColor: 'red',
      data: prodData.map(r => r.gasMboed),
      pointRadius: 0,
        elements: {
          point: {
            radius: 0,
            hitRadius: 1,
            hoverRadius: 25,
          }
        } 
    }]
  };

  var chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: isStacked === "true" ? true : false,
        title: {
          text: yTitle,
          display: true
        },
      },
      x: {
        ticks: {
          minRotation: 45,
          autoSkip: true
        },
        title: {
          text: xTitle,
          display: true
        },
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      },
      filler: {
        propagate: true
      }
    }
  };

  let contentDiv = null;
  if (chartType === "Line") { contentDiv = (<Line data={chartData} options={chartOptions} />); }
  else if (chartType === "Bar") { contentDiv = (<Bar data={chartData} options={chartOptions} />); }

  //contentDiv = (<CashflowChart className="cashflow-chart-manager" cashflowData={entityData} />);

  return (

    <div className={appendClassNames('production-chart-component', className)}>
      {contentDiv}
    </div>
  );

};

export default ProductionChart;
