import React from "react";
import PropTypes from 'prop-types';

import { MessageBoxGlobal } from '../../common/globals/globals';
import { valueTypes } from '../../shared/helpers/value-handler';

import AGGridComponent, { COLUMN_SMALL, COLUMN_MEDIUM, COLUMN_MEDIUM_MEDIUM, COLUMN_LARGE, COLUMN_EXTRA_LARGE, COLUMN_WIDE, DoubleFormatter } from '../../common/components/grid/ag-grid-component';

import { AgGridReact } from 'ag-grid-react';

const FiscalViewGridComponent = (props) => {
  const { messageBoxGlobal, data, type } = props;

  const columnDefs = [];

  let usdMM = valueTypes.UnitString(valueTypes.usdMM);

  switch (type) {
    case 1:
      columnDefs.push({ headerName: 'Resource Id', suppressHeaderMenuButton: true, resizable: true, field: 'resourceId', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      columnDefs.push({ headerName: 'Resource Name', suppressHeaderMenuButton: true, resizable: true, field: 'resourceName', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'text' });
      columnDefs.push({ headerName: 'Hub Name', suppressHeaderMenuButton: true, resizable: true, field: 'hubName', sortable: true, filter: false, editable: false, width: COLUMN_WIDE, type: 'text' });
      columnDefs.push({ headerName: 'Year', suppressHeaderMenuButton: true, resizable: true, field: 'year', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      columnDefs.push({ headerName: 'Message', suppressHeaderMenuButton: true, resizable: true, field: 'message', sortable: true, filter: false, editable: false, width: COLUMN_EXTRA_LARGE, type: 'text' });
      break;

    case 2:
      columnDefs.push({ headerName: 'Resource Id', suppressHeaderMenuButton: true, resizable: true, field: 'resourceId', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      columnDefs.push({ headerName: 'Resource Name', suppressHeaderMenuButton: true, resizable: true, field: 'resourceName', sortable: true, filter: false, editable: false, width: COLUMN_LARGE, type: 'text' });
      columnDefs.push({ headerName: 'Year', suppressHeaderMenuButton: true, resizable: true, field: 'year', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      columnDefs.push({ headerName: "Liquid (" + valueTypes.UnitString(valueTypes.mboed) + ")", suppressHeaderMenuButton: true, resizable: true, field: 'liquidMbod', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Gas (" + valueTypes.UnitString(valueTypes.mmscfd) + ")", suppressHeaderMenuButton: true, resizable: true, field: 'gasMmscfd', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Capex (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'capexUSDMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Actual OPEX (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'opexActualUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Total Tariff (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'totalTariffUSDMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "ETS Charge (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'etsChargeUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Carbon Charge (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'carbonChargeUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Total Emissions Charge (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'totalEmissionsChargeUSDMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Abex (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'abexUSDMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Revenue (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'revenueUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Pre Tax Cashflow (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'preTaxCashFlowUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Cumulative Operating Cashflow (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'cumulativeOperatingCashFlowUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Corporate Tax (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'corparateTaxUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Special Tax (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'specialTaxUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Post Tax Undiscounted Cashflow (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'undiscountedPostTaxCashFlowUsdMM', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      //columnDefs.push({ headerName: "", suppressHeaderMenuButton: true, resizable: true, field: '', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });

      break;

    case 3:
      columnDefs.push({ headerName: 'Hub Id', suppressHeaderMenuButton: true, resizable: true, field: 'hubId', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      columnDefs.push({ headerName: 'Hub Name', suppressHeaderMenuButton: true, resizable: true, field: 'hubName', sortable: true, filter: false, editable: false, width: COLUMN_LARGE, type: 'text' });
      columnDefs.push({ headerName: "Year", suppressHeaderMenuButton: true, resizable: true, field: "year", sortable: false, filter: 'agNumberColumnFilter', filterParams: { newRowsAction: 'keep' }, editable: false, width: COLUMN_SMALL, type: 'numeric' });
      columnDefs.push({ headerName: "Fields", suppressHeaderMenuButton: true, resizable: true, field: "fields", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, type: 'numeric' });
      columnDefs.push({ headerName: "Liquid (" + valueTypes.UnitString(valueTypes.mboed) + ")", suppressHeaderMenuButton: true, resizable: true, field: "liquidMbod", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Gas (" + valueTypes.UnitString(valueTypes.mmscfd) + ")", suppressHeaderMenuButton: true, resizable: true, field: "gasMmscfd", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Capex (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "capexUSDMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "OPEX Charge(" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "opexChargeUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Hub and Field Specific OPEX Receipts (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "totalOpexReceiptsUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Total Tarriff Receipts (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "totalTariffUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "ETS Charge (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "etsChargeUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Carbon Charge (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "carbonChargeUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Total Emissions Charge (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "totalEmissionsChargeUSDMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Abex (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "abexUSDMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Revenue (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "revenueUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Pre Tax Cashflow (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "preTaxCashFlowUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Cumulative Pre Tax Cashflow (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "cumulativePreTaxCashflowUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM_MEDIUM, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Corporate Tax (" + usdMM + ")", field: "corporateTaxUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Special Tax (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "specialTaxUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_SMALL, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Undiscounted Post Tax Cashflow (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "undiscountedPostTaxCashflowUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM_MEDIUM, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Cumulative Undiscounted Post Tax Cashflow (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "cumulativeUndiscountedPostTaxCashflowUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_LARGE, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Discounted Post Tax Cashflow (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "discountedPostTaxCashflowUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM_MEDIUM, cellRendererFramework: DoubleFormatter, type: 'numeric' });
      columnDefs.push({ headerName: "Cumulative Discounted Post Tax Cashflow (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: "cumulativeDiscountedPostTaxCashflowUsdMM", sortable: true, filter: false, editable: false, width: COLUMN_LARGE, cellRendererFramework: DoubleFormatter, type: 'numeric' });

      break;

    case 4:
      columnDefs.push({ headerName: 'Hub Id', suppressHeaderMenuButton: true, resizable: true, field: 'hubId', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      columnDefs.push({ headerName: 'Hub Name', suppressHeaderMenuButton: true, resizable: true, field: 'hubName', sortable: true, filter: false, editable: false, width: COLUMN_LARGE, type: 'text' });
      columnDefs.push({ headerName: 'Year', suppressHeaderMenuButton: true, resizable: true, field: 'year', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      columnDefs.push({ headerName: "Production (mboed)", suppressHeaderMenuButton: true, resizable: true, field: 'production', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Total " + valueTypes.UnitString(valueTypes.CO2) + " Emissions (t" + valueTypes.UnitString(valueTypes.CO2) + ")", suppressHeaderMenuButton: true, resizable: true, field: 'cO2TotalTonnes', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Total " + valueTypes.UnitString(valueTypes.CH4) + " Emissions (t" + valueTypes.UnitString(valueTypes.CH4) + ")", suppressHeaderMenuButton: true, resizable: true, field: 'cH4TotalTonnes', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: valueTypes.UnitString(valueTypes.CO2) + " Emissions Intensity (kg" + valueTypes.UnitString(valueTypes.CO2) + "/boe)", suppressHeaderMenuButton: true, resizable: true, field: 'cO2EmissionsIntensity', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: valueTypes.UnitString(valueTypes.CH4) + " Emissions Intensity (kg" + valueTypes.UnitString(valueTypes.CH4) + "/boe)", suppressHeaderMenuButton: true, resizable: true, field: 'cH4EmissionsIntensity', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Fuel Gas Consumption (" + valueTypes.UnitString(valueTypes.SM3) + ")", suppressHeaderMenuButton: true, resizable: true, field: 'fuelGasConsumptionSM3', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Diesel Consumption (L)", suppressHeaderMenuButton: true, resizable: true, field: 'dieselConsumptionLitres', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Flaring (" + valueTypes.UnitString(valueTypes.SM3) + ")", suppressHeaderMenuButton: true, resizable: true, field: 'flaringSM3', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Venting Total (" + valueTypes.UnitString(valueTypes.SM3) + ")", suppressHeaderMenuButton: true, resizable: true, field: 'ventingTotalSM3', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Venting " + valueTypes.UnitString(valueTypes.CO2) + " (" + valueTypes.UnitString(valueTypes.SM3) + ")", suppressHeaderMenuButton: true, resizable: true, field: 'ventingCO2SM3', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "ETS Allowance (t)", suppressHeaderMenuButton: true, resizable: true, field: 'cO2EtsAllowanceTonnes', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });

      break;

    case 5:
      columnDefs.push({ headerName: 'Resource Name', resizable: true, field: 'resourceName', sortable: true, filter: false, editable: false, width: COLUMN_LARGE, type: 'text' });
      columnDefs.push({ headerName: 'Year', resizable: true, field: 'year', sortable: true, filter: false, editable: false, width: COLUMN_MEDIUM, type: 'numeric' });
      columnDefs.push({ headerName: 'Liquid Price (' + valueTypes.UnitString(valueTypes.usdBOE) + ')', suppressHeaderMenuButton: true, resizable: true, field: 'liquidPriceUsdBOE', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'Gas Price (' + valueTypes.UnitString(valueTypes.usdMCF) + ')', suppressHeaderMenuButton: true, resizable: true, field: 'gasPriceUsdMCF', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Liquid (" + valueTypes.UnitString(valueTypes.mboed) + ")", suppressHeaderMenuButton: true, resizable: true, field: 'liquidProductionMbod', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Gas (" + valueTypes.UnitString(valueTypes.mmscfd) + ")", suppressHeaderMenuButton: true, resizable: true, field: 'gasProductionMmscfd', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Capex (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'costForLossesOrDepreciationUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "OPEX (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'opexUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Total Tariff (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'totalTariffUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "ETS Charge (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'etsChargeUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Carbon Charge (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'carbonChargeUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Total Emissions Charge (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'totalEmissionsChargeUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Abex (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'abexUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Revenue (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'totalRevenueUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Pre Tax Cashflow (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'preTaxCashflowUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });

      columnDefs.push({ headerName: "Operating Cashflow (EBITDA) (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'ebitda', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });

      columnDefs.push({ headerName: "Cumulative Operating Cashflow (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'cumulativeEBITDA', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Corporate Tax (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'corporationTaxUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Special Tax (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'specialTaxUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Energy Profit Levy (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'ukLevyUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: "Post Tax Undiscounted Cashflow (" + usdMM + ")", suppressHeaderMenuButton: true, resizable: true, field: 'postTaxCashflowUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });

      columnDefs.push({ headerName: 'CT Closing Balance (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ctClosingBalanceUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'CT Opening Balance (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ctOpeningBalanceUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'CT Cumulative Cost For DDA (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ctCumulativeCostForDDAUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'CT Depreciation (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ctDepreciationUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'CT Losses Or Depreciation Added (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ctLossesOrDepreciationAddedUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'CT Losses Used Or Income Sheltered (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ctLossesUsedOrIncomeShelteredUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'CT Tax Base (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ctTaxBaseUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });

      columnDefs.push({ headerName: 'SCT Closing Balance (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'sctClosingBalanceUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'SCT Opening Balance (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'sctOpeningBalanceUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'SCT Cumulative Cost For DDA (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'sctCumulativeCostForDDAUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'SCT Depreciation (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'sctDepreciationUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'SCT Losses Or Depreciation Added (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'sctLossesOrDepreciationAddedUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'SCT Losses Used Or Income Sheltered (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'sctLossesUsedOrIncomeShelteredUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'SCT Income Sheltered (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'sctIncomeShelteredUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'Investments To Uplift (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'investmentsToUpliftUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'Uplift (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'upliftUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'Cumulative Uplift After Usage (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'cumulativeUpliftAfterUsageUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'Investment Allowance Available (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'investmentAllowanceAvailableUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'Investment Allowance Used (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'investmentAllowanceUsedUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'SCT Tax Base (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'sctTaxBaseUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'Abex Relief (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'abexReliefUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'RFES Uplift (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'rfesUpliftUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });

      columnDefs.push({ headerName: 'UK Levy Closing Balance (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ukLevyClosingBalanceUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'UK Levy Opening Balance (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ukLevyOpeningBalanceUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'UK Levy Tax Base (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ukLevyTaxBaseUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'UK Levy Losses Used Or Income Sheltered (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ukLevyLossesUsedOrIncomeShelteredUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'UK Levy Allowance Generated (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ukLevyAllowanceGeneratedUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'UK Levy Allowance Available (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ukLevyAllowanceAvailableUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'UK Levy Allowance Used (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ukLevyAllowanceUsedUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });
      columnDefs.push({ headerName: 'UK Levy Allowance Carry Forward (' + usdMM + ')', suppressHeaderMenuButton: true, resizable: true, field: 'ukLevyAllowanceCarryForwardUsdMM', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });

      //columnDefs.push({ headerName: '', suppressHeaderMenuButton: true, resizable: true, field: '', sortable: true, editable: false, width: COLUMN_MEDIUM, type: 'numeric', cellRendererFramework: DoubleFormatter });



      break;

    default:
    // code block
  }

  const columnTypes = {
    numeric: {},
    text: {},
    percent: {},
    none: {},
    dropdown: {},
    image: {}
  };

  return (
    <div className="aggrid-component ag-theme-balham" >
      <AgGridReact
        columnDefs={columnDefs}
        rowData={data}
        columnTypes={columnTypes}
        rowHeight={25}
        headerHeight={65}
      />
    </div>
  );
};

FiscalViewGridComponent.propTypes = {
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
};

export default FiscalViewGridComponent;
