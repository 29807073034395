import React from 'react';

const HomeComponent = (props) => {
  return (
    <div id="loading-div" style={{ cursor: 'default' }}>
      <img src="/images/wge-logo.svg" alt="Loading..." />
      <br />
      <br />
      <br />
      <h1>Welcome to {document.title}</h1>
      <br />
      {props.children}
    </div>
  );
};

export default HomeComponent;