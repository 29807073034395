import React from 'react';
import PropTypes from 'prop-types';
import './hub-upside.scss';

import { apiGet, apiPost, callApiDefaultOnFail } from '../../common/helpers/api-helpers';
import { LoadingGlobal, MessageBoxGlobal, CountriesGlobal } from '../../common/globals/globals';
import { Button } from 'reactstrap'
import TabPanelComponent, { Tab } from '../../shared/components/tab-panel/tab-panel-component';
import HubUpsideGridComponent from './hub-upside-grid-component';
import { LookupResult } from '../../common/models/lookup-result';
import { HubUpsidePostbackInfo } from './models/hub-upside-postback-info';
import { HubUpsideExcelExport } from './hub-upside-excel-export';

const IDS = {
  HUB_COP_YEAR: 0,
  NON_HUB_CAPTURE_RADIUS: 10,
  HUB_CAPTURE_RADIUS: 20,
  MIN_RESOURCE_SIZE: 30,
  PHASE_SUITABILITY: 40,
  MIN_MAPPING_VINTAGE: 60,
  LICENSED: 70
};
Object.freeze(IDS);
class HubUpsideView extends React.Component {
  constructor(props) {
    super(props);

    var postbackInfo = new HubUpsidePostbackInfo();

    this.state = {
      upsideData: null,
      hasStratSplitRun: false,
      lookUps: null,
      postbackInfo,
      countrySelectAll: false,
      areaSelectAll: false,
      hubSelectAll: false,
      pipelineSelectAll: false,
      variableId: 0,
      variableName: ''
    };
  };

  componentDidUpdate() {
    this.loadLookups();
  }

  get apiPrefix() { return 'api/app/hub-upside/' };

  runHubUpside() {
    const onSuccess = (result) => this.setState({ upsideData: result });
    let onFail = (e) => this.props.idGlobal.clear(() => callApiDefaultOnFail(e, this.props.messageBoxGlobal));

    var { postbackInfo } = this.state;
    apiPost(this.apiPrefix + 'run-upside', JSON.stringify(postbackInfo), this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, onFail);
  }

  loadLookups() {
    if (this.state.lookUps === null) {
      const onSuccess = (result) => {
        var lookUps = new LookupResult(result);
        var { postbackInfo } = this.state;
        for (var i = 0; i < lookUps.Countries.length; i++) {
          postbackInfo.countryIds.push(Number(lookUps.Countries[i].key));
        }
        postbackInfo.variableId = lookUps.Variable[0].key;
        // JB - removed as can be passed back to controller as null & likely will eant to select a few at a time rather than many apart from the odd one
        //for (var j = 0; j < lookUps.Area.length; j++) {
        //  postbackInfo.areaIds.push(Number(lookUps.Area[j].key));
        //}
        //for (var j = 0; j < lookUps.Hub.length; j++) {
        //  postbackInfo.hubIds.push(Number(lookUps.Hub[j].key));
        //}
        this.setState({
          lookUps: lookUps, variableId: lookUps.Variable[0].key, variableName: lookUps.Variable[0].value, postbackInfo: postbackInfo, countrySelectAll: true, areaSelectAll: false, hubSelectAll: false, pipelineSelectAll: false
        }); //make sure select all matches the above logic for selects
      }
      let onFail = (e) => this.props.idGlobal.clear(() => callApiDefaultOnFail(e, this.props.messageBoxGlobal));
      apiGet(this.apiPrefix + 'lookups', this.props.loadingGlobal.show, this.props.loadingGlobal.hide, this.props.messageBoxGlobal, onSuccess, onFail);
    }
  }

  onSelected = (e, type) => {
    var { postbackInfo, countrySelectAll, areaSelectAll, hubSelectAll, pipelineSelectAll } = this.state;
    var { countryIds, areaIds, hubIds, pipelineIds } = this.state.postbackInfo;
    var { Countries, Hub, Area, Pipeline } = this.state.lookUps;
    var selectAll = false;

    var ids = null;
    var entities = null;

    switch (type) {
      case 'Country':
        ids = countryIds;
        entities = Countries;
        break;
      case 'Area':
        ids = areaIds;
        entities = Area;
        break;
      case 'Hub':
        ids = hubIds;
        entities = Hub;
        break;
      case 'Pipeline':
        ids = pipelineIds;
        entities = Pipeline;
        break;
    }

    if (Number(e.currentTarget.id) === -99) {
      if (e.target.checked) {
        selectAll = true;
        ids = [];
        for (var j = 0; j < entities.length; j++) {
          ids.push(entities[j].key);
        }
      }
      else {
        ids = [];
        selectAll = false;
      }
    }
    else {
      if (e.target.checked) {
        ids.push(Number(e.currentTarget.id));
      }
      else {
        var index = ids.indexOf(Number(e.currentTarget.id));
        if (index !== -1) {
          ids.splice(index, 1);
        }
      }
    }

    if (ids.length === entities.length) selectAll = true;
    else selectAll = false;

    switch (type) {
      case 'Country':
        postbackInfo.countryIds = ids;
        countrySelectAll = selectAll;
        break;
      case 'Area':
        postbackInfo.areaIds = ids;
        areaSelectAll = selectAll;
        break;
      case 'Hub':
        postbackInfo.hubIds = ids;
        hubSelectAll = selectAll;
        break;
      case 'Pipeline':
        postbackInfo.pipelineIds = ids;
        pipelineSelectAll = selectAll;
        break;
    }

    this.setState({ postbackInfo: postbackInfo, countrySelectAll: countrySelectAll, areaSelectAll: areaSelectAll, hubSelectAll: hubSelectAll, pipelineSelectAll: pipelineSelectAll });
  }

  onEdit = (e) => {
    var id = Number(e.currentTarget.id);
    var value = Number(e.currentTarget.value);
    var checked = e.currentTarget.checked;
    var i = this.state.postbackInfo;

    switch (id) {
      case IDS.HUB_COP_YEAR: i.hubCopYear = value; break;
      case IDS.NON_HUB_CAPTURE_RADIUS: i.nonHubCaptureRadius = value; break;
      case IDS.HUB_CAPTURE_RADIUS: i.hubCaptureRadius = value; break;
      case IDS.MIN_RESOURCE_SIZE: i.minResourceSize = value; break;
      case IDS.PHASE_SUITABILITY: i.phaseSuitability = checked; break;
      case IDS.MIN_MAPPING_VINTAGE: i.minMappingVintage = value; break;
      case IDS.LICENSED: i.licenced = checked; break;
      default: throw new Error('Id ' + id + ' is not supported in HubUpsideView.onEdit');
    }

    this.setState({ postbackInfo: i })
  }

  handleFocus = (event) => event.target.select();

  excelExport() {
    const { upsideData } = this.state;
    let hasUpsideTables = upsideData !== null && upsideData !== undefined && upsideData.tables !== null;

    if (hasUpsideTables) {
      let excelExport = new HubUpsideExcelExport();
      excelExport.export(upsideData.tables, upsideData.workbookName);
    }
  }

  onChangeVariable(e) {
    var { postbackInfo, lookUps } = this.state;

    postbackInfo.variableId = lookUps.Variable[e.target.selectedIndex].key;
    this.setState({ postbackInfo: postbackInfo, variableId: lookUps.Variable[e.target.selectedIndex].key, variableName: e.target.value  })
  }

  render() {
    const { upsideData, lookUps, countrySelectAll, areaSelectAll, hubSelectAll, pipelineSelectAll, variableId, variableName } = this.state;

    const { countryIds, areaIds, hubIds, pipelineIds, hubCopYear, nonHubCaptureRadius, hubCaptureRadius, minResourceSize, phaseSuitability, minMappingVintage, licenced } = this.state.postbackInfo;

    let hasUpsideData = upsideData !== null && upsideData !== undefined;

    let hubSummary = hasUpsideData && upsideData.tables !== null && upsideData.tables.hubSummary !== null ? upsideData.tables.hubSummary : null;
    let hubSummarySettings = hubSummary !== null ? hubSummary.columnSettings : null;
    let hubSummaryCount = hubSummary !== null && hubSummary.data !== null ? hubSummary.data.length : 0;

    let fieldDetails = hasUpsideData && upsideData.tables !== null && upsideData.tables.fieldDetails !== null ? upsideData.tables.fieldDetails : null;
    let fieldDetailsSettings = fieldDetails !== null ? fieldDetails.columnSettings : null;
    let fieldDetailsCount = fieldDetails !== null && fieldDetails.data !== null ? fieldDetails.data.length : 0;

    let fieldCashflow = hasUpsideData && upsideData.tables !== null && upsideData.tables.fieldCashflow !== null ? upsideData.tables.fieldCashflow : null;
    let fieldCashflowSettings = fieldCashflow !== null ? fieldCashflow.columnSettings : null;
    let fieldCashflowCount = fieldCashflow !== null && fieldCashflow.data !== null ? fieldCashflow.data.length : 0;

    let fieldProduction = hasUpsideData && upsideData.tables !== null && upsideData.tables.fieldProduction !== null ? upsideData.tables.fieldProduction : null;
    let fieldProductionSettings = fieldProduction !== null ? fieldProduction.columnSettings : null;
    let fieldProductionCount = fieldProduction !== null && fieldProduction.data !== null ? fieldProduction.data.length : 0;

    let fieldParticipant = hasUpsideData && upsideData.tables !== null && upsideData.tables.fieldParticipant !== null ? upsideData.tables.fieldParticipant : null;
    let fieldParticipantSettings = fieldParticipant !== null ? fieldParticipant.columnSettings : null;
    let fieldParticipantCount = fieldParticipant !== null && fieldParticipant.data !== null ? fieldParticipant.data.length : 0;

    let tieInResults = hasUpsideData && upsideData.tables !== null && upsideData.tables.tieInResults !== null ? upsideData.tables.tieInResults : null;
    let tieInSettings = tieInResults !== null ? tieInResults.columnSettings : null;
    let tieInResultsCount = tieInResults !== null && tieInResults.data !== null ? tieInResults.data.length : 0;

    let discoveryResults = hasUpsideData && upsideData.tables !== null && upsideData.tables.discoveryResults !== null ? upsideData.tables.discoveryResults : null;
    let discoverySettings = discoveryResults !== null ? discoveryResults.columnSettings : null;
    let discoveryResultsCount = discoveryResults !== null && discoveryResults.data !== null ? discoveryResults.data.length : 0;

    let prospectResults = hasUpsideData && upsideData.tables !== null && upsideData.tables.prospectResults !== null ? upsideData.tables.prospectResults : null;
    let prospectSettings = prospectResults !== null ? prospectResults.columnSettings : null;
    let prospectResultsCount = prospectResults !== null && prospectResults.data !== null ? prospectResults.data.length : 0;

    let netDiscoveryResults = hasUpsideData && upsideData.tables !== null && upsideData.tables.netDiscoveryResults !== null ? upsideData.tables.netDiscoveryResults : null;
    let netDiscoverySettings = netDiscoveryResults !== null ? netDiscoveryResults.columnSettings : null;
    let netDiscoveryResultsCount = netDiscoveryResults !== null && netDiscoveryResults.data !== null ? netDiscoveryResults.data.length : 0;

    let netProspectResults = hasUpsideData && upsideData.tables !== null && upsideData.tables.netProspectResults !== null ? upsideData.tables.netProspectResults : null;
    let netProspectSettings = netProspectResults !== null ? netProspectResults.columnSettings : null;
    let netProspectResultsCount = netProspectResults !== null && netProspectResults.data !== null ? netProspectResults.data.length : 0;

    let ntdResults = hasUpsideData && upsideData.tables !== null && upsideData.tables.nearTermDevelopmentResults !== null ? upsideData.tables.nearTermDevelopmentResults : null;
    let ntdNTDSettings = ntdResults !== null ? ntdResults.columnSettings : null;
    let ntdResultsCount = ntdResults !== null && ntdResults.data !== null ? ntdResults.data.length : 0;

    var countrySelections = [];
    var areaSelections = [];
    var hubSelections = [];
    var pipelineSelections = [];
    var isSelected = false;
    var variableSelect = null;

    if (lookUps !== null) {
      if (lookUps.Countries !== undefined && lookUps.Countries !== null && lookUps.Countries.length > 0) {
        countrySelections.push(<div key={-99} style={{ 'height': '18px' }}><input id={-99} type="checkbox" className="category_check" checked={countrySelectAll} onChange={(e) => this.onSelected(e, 'Country')} /><span style={{ 'top': '-2px', 'position': 'relative', 'marginLeft': '5px' }}>Select All</span>  </div>);
        for (var j = 0; j < lookUps.Countries.length; j++) {
          isSelected = countryIds.includes(lookUps.Countries[j].key);
          countrySelections.push(<div key={j} style={{ 'height': '18px' }}><input id={lookUps.Countries[j].key} type="checkbox" className="category_check" checked={isSelected} onChange={(e) => this.onSelected(e, 'Country')} /><span style={{ 'top': '-2px', 'position': 'relative', 'marginLeft': '5px' }}>{lookUps.Countries[j].value} </span>  </div>);
        }
      }

      if (lookUps.Area !== undefined && lookUps.Area !== null && lookUps.Area.length > 0) {
        areaSelections.push(<div key={-99} style={{ 'height': '18px' }}><input id={-99} type="checkbox" className="category_check" checked={areaSelectAll} onChange={(e) => this.onSelected(e, 'Area')} /><span style={{ 'top': '-2px', 'position': 'relative', 'marginLeft': '5px' }}>Select All</span>  </div>);
        for (var j = 0; j < lookUps.Area.length; j++) {
          isSelected = areaIds.includes(lookUps.Area[j].key);
          areaSelections.push(<div key={j} style={{ 'height': '18px' }}><input id={lookUps.Area[j].key} type="checkbox" className="category_check" checked={isSelected} onChange={(e) => this.onSelected(e, 'Area')} /><span style={{ 'top': '-2px', 'position': 'relative', 'marginLeft': '5px' }}>{lookUps.Area[j].value} </span>  </div>);
        }
      }

      if (lookUps.Hub !== undefined && lookUps.Hub !== null && lookUps.Hub.length > 0) {
        hubSelections.push(<div key={-99} style={{ 'height': '18px' }}><input id={-99} type="checkbox" className="category_check" checked={hubSelectAll} onChange={(e) => this.onSelected(e, 'Hub')} /><span style={{ 'top': '-2px', 'position': 'relative', 'marginLeft': '5px' }}>Select All</span>  </div>);
        for (var j = 0; j < lookUps.Hub.length; j++) {
          isSelected = hubIds.includes(lookUps.Hub[j].key);
          hubSelections.push(<div key={j} style={{ 'height': '18px' }}><input id={lookUps.Hub[j].key} type="checkbox" className="category_check" checked={isSelected} onChange={(e) => this.onSelected(e, 'Hub')} /><span style={{ 'top': '-2px', 'position': 'relative', 'marginLeft': '5px' }}>{lookUps.Hub[j].value} </span>  </div>);
        }
      }

      if (lookUps.Pipeline !== undefined && lookUps.Pipeline !== null && lookUps.Pipeline.length > 0) {
        pipelineSelections.push(<div key={-99} style={{ 'height': '18px' }}><input id={-99} type="checkbox" className="category_check" checked={pipelineSelectAll} onChange={(e) => this.onSelected(e, 'Pipeline')} /><span style={{ 'top': '-2px', 'position': 'relative', 'marginLeft': '5px' }}>Select All</span>  </div>);
        for (var j = 0; j < lookUps.Pipeline.length; j++) {
          isSelected = pipelineIds.includes(lookUps.Pipeline[j].key);
          pipelineSelections.push(<div key={j} style={{ 'height': '18px' }}><input id={lookUps.Pipeline[j].key} type="checkbox" className="category_check" checked={isSelected} onChange={(e) => this.onSelected(e, 'Pipeline')} /><span style={{ 'top': '-2px', 'position': 'relative', 'marginLeft': '5px' }}>{lookUps.Pipeline[j].value} </span>  </div>);
        }
      }

      if (lookUps.Variable !== undefined && lookUps.Variable !== null && lookUps.Variable.length > 0) {
        variableSelect =
          <select value={variableName}
            onChange={(e) => this.onChangeVariable(e)}>
            {lookUps.Variable.map((x) => <option key={x.key} value={x.value}>{x.value}</option>)}
          </select>
      }

    }

    const tabs = [
      new Tab('Hub Details (' + hubSummaryCount.toString() + ')', hubSummary !== null ? <HubUpsideGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={hubSummary.data} colSettings={hubSummarySettings} /> : null),
      new Tab('Field Details (' + fieldDetailsCount.toString() + ')', fieldDetails !== null ? <HubUpsideGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={fieldDetails.data} colSettings={fieldDetailsSettings} /> : null),
      new Tab('Field Cashflows (' + fieldCashflowCount.toString() + ')', fieldCashflow !== null ? <HubUpsideGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={fieldCashflow.data} colSettings={fieldCashflowSettings} /> : null),
      new Tab('Field Production (' + fieldProductionCount.toString() + ')', fieldProduction !== null ? <HubUpsideGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={fieldProduction.data} colSettings={fieldProductionSettings} /> : null),
      new Tab('Field Participation (' + fieldParticipantCount.toString() + ')', fieldParticipant !== null ? <HubUpsideGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={fieldParticipant.data} colSettings={fieldParticipantSettings} /> : null),
      new Tab('Near Term Developments (' + ntdResultsCount.toString() + ')', ntdResults !== null ? <HubUpsideGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={ntdResults.data} colSettings={ntdNTDSettings} /> : null),
      new Tab('Hub Resource Potential Summary (' + tieInResultsCount.toString() + ')', tieInResults !== null ? <HubUpsideGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={tieInResults.data} colSettings={tieInSettings} /> : null),
      new Tab('Hubs - Gross Discoveries (' + discoveryResultsCount.toString() + ')', discoveryResults !== null ? <HubUpsideGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={discoveryResults.data} colSettings={discoverySettings} /> : null),
      new Tab('Prospects (' + prospectResultsCount.toString() + ')', prospectResults !== null ? <HubUpsideGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={prospectResults.data} colSettings={prospectSettings} /> : null),
      new Tab('Net Discoveries (' + netDiscoveryResultsCount.toString() + ')', netDiscoveryResults !== null ? <HubUpsideGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={netDiscoveryResults.data} colSettings={netDiscoverySettings} /> : null),
      new Tab('Net Prospects (' + netProspectResultsCount.toString() + ')', netProspectResults !== null ? <HubUpsideGridComponent messageBoxGlobal={this.props.messageBoxGlobal} data={netProspectResults.data} colSettings={netProspectSettings} /> : null),
    ];

    return (
      <div className="hub-upside-view">
        <div className="header-section">
          <h1 className="header">Hub Upside</h1>
        </div>
        <div className="selections-area">
          <div className="country-selections">
            <span className="label">Select Country(s)</span>
            <div>{countrySelections}</div>
          </div>
          <div className="area-selections">
            <span className="label">Select Area(s)</span>
            <div>{areaSelections}</div>
          </div>
          <div className="hub-selections">
            <span className="label">Select Hub(s)</span>
            <div>{hubSelections}</div>
          </div>
          <div className="pipeline-selections">
            <span className="label">Select Pipeline(s)</span>
            <div>{pipelineSelections}</div>
          </div>
          <div className="right-side">
            <div className="top-row-selections">
              <div className="hub-year-inputs">
                <span className="label">Hub COP Year</span>
                <input id={IDS.HUB_COP_YEAR} type="number" min="1970" value={hubCopYear} onChange={this.onEdit} onClick={this.handleFocus} ></input>
              </div>
              <div className="hub-radius-inputs">
                {/*<span className="label">Non Hub Capture Radius (KM)</span>*/}
                {/*<input id={IDS.NON_HUB_CAPTURE_RADIUS} type="number" min="0" value={nonHubCaptureRadius} onChange={this.onEdit} onClick={this.handleFocus} ></input>*/}
                <span className="label">Hub Capture Radius (KM)</span>
                <input id={IDS.HUB_CAPTURE_RADIUS} type="number" min="0" value={hubCaptureRadius} onChange={this.onEdit} onClick={this.handleFocus} ></input>
              </div>
              <div className="other-inputs">
                <span className="label">Minimum resource size (mmboe)</span>
                <input id={IDS.MIN_RESOURCE_SIZE} type="number" min="0" value={minResourceSize} onChange={this.onEdit} onClick={this.handleFocus} ></input>
                <span className="label">Minimum mapping vintage</span>
                <input id={IDS.MIN_MAPPING_VINTAGE} type="number" min="1970" value={minMappingVintage} onChange={this.onEdit} onClick={this.handleFocus} ></input>
              </div>
              <div className="other-checked-inputs">
                <span className="label">Phase suitability</span>
                <input id={IDS.PHASE_SUITABILITY} type="checkbox" checked={phaseSuitability} onChange={this.onEdit} ></input>
                <span className="licensed-label" >Licensed Only</span>
                <input className="licensed-input" id={IDS.LICENSED} type="checkbox" checked={licenced} onChange={this.onEdit} ></input>
              </div>
            </div>
            <div className="bottom-row-selections">
              <div className="valuations-filter">
                <h4>Variables:</h4>
                {variableSelect}
              </div>
            </div>
          </div>
        </div>
        <div className="run-button">
          <Button className="header-button" size="sm" onClick={() => this.runHubUpside()}>Run Hub Upside</Button>
        </div>
        <div className="run-button">
          <Button className="header-button" size="sm" onClick={() => this.excelExport()}>Export Tables</Button>
        </div>
        <div className="grid-area">
          <TabPanelComponent toolbarItems={tabs} selectedItemIndex={0} />
        </div>
      </div>
    );
  }

};

HubUpsideView.propTypes = {
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  countriesGlobal: PropTypes.instanceOf(CountriesGlobal).isRequired,
}

export default HubUpsideView;
