import React from 'react';
import PropTypes from 'prop-types';

import { HubAnnual, ResourceAnnual, ResourceProductionAttributes } from '../../../models/production-result';

import HubChart from '../../../../../common/components/chart/hub-chart';

const HubProductionChartComponent = (props) => {
  const {
    allResourceAnnuals,
    hubAnnuals,
    allResourceAttributes,
    hubMinYear,
    hubMaxYear
  } = props;

  return (
    <HubChart
      resourceData={allResourceAnnuals}
      hubAnnual={hubAnnuals}
      resourceAttributes={allResourceAttributes}
      isStacked="true"
      chartType="Line"
      hubMinYear={hubMinYear}
      hubMaxYear={hubMaxYear}
      xTitle="Year"
      yTitle="Mbod"
    />
  );
};

HubProductionChartComponent.propTypes = {
  allResourceAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(ResourceAnnual)).isRequired,
  hubAnnuals: PropTypes.arrayOf(PropTypes.instanceOf(HubAnnual)).isRequired,
  allResourceAttributes: PropTypes.arrayOf(PropTypes.instanceOf(ResourceProductionAttributes)).isRequired,
  hubMinYear: PropTypes.number.isRequired,
  hubMaxYear: PropTypes.number.isRequired
};

export default HubProductionChartComponent;