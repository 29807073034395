class HubUpsidePostbackInfo {
  countryIds = [];
  areaIds = [];
  hubIds = [];
  pipelineIds = [];
  hubCopYear = 2030;
  nonHubCaptureRadius = 50;
  hubCaptureRadius = 50;
  minResourceSize = 0;
  phaseSuitability = true;
  minMappingVintage = 0;
  licenced = false;
  variableId = 0;
}

export { HubUpsidePostbackInfo };
