import moment from 'moment';

export class ResourceSnapshotAnnual {
  snapshotDate = moment().format('YYYY-MM-DD 00:00:00.000');
  resourceSnapshotAnnualId = 0;
  resourceSnapshotId = 0;
  resourceId = 0;
  year = 0;
  liquidMbod = 0;
  gasMmscfd = 0;
  capexUSDMM = 0;
  opexActualUsdMM = 0;
  totalTariffUSDMM = 0;
  abexUSDMM = 0;
  preTaxCashFlowUsdMM = 0;
  cumulativeOperatingCashFlowUsdMM = 0;
  corporateTaxPercent = 0;
  specialTaxPercent = 0;
  postTaxDiscountedCashflowUsdMM = 0;
  cumulativeDiscountedCashflowUsdMM = 0;
  revenueUsdMM = 0;
  totalOpexUsdMM = 0;
  liquidPriceUSDBOE = 0;
  gasPriceUSDMCF = 0;
  corparateTaxUSDMM = 0;
  specialTaxUSDMM = 0;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}
