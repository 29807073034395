import L from 'leaflet';
import { default as GeometryUtil } from 'leaflet-geometryutil';
import { faDrawSquare } from '@fortawesome/pro-solid-svg-icons';

import { BaseButtonControl, BORDER_OPTIONS, MARKER_OPTIONS, TOOLTIP_OPTIONS } from '../common/base-button-control';

class BoxSelectButtonControl extends BaseButtonControl {
  constructor(searchButtonsControl) {
    super(searchButtonsControl);

    this._drawing = false;
  }

  get iconDef() { return faDrawSquare; }
  get title() { return 'Box Select'; }

  cancel() {
    this.clearLayerGroup();
    this._drawing = false;
    this.toggled = false;
  }

  onToggledChanged(toggled) {
    if (toggled) {
      this.addEvent('mousedown', this._mapMouseDown);
      this.addEvent('mousemove', this._mapMouseMove);
      this.addEvent('mouseup', this._mapMouseUp);
    } else {
      this.removeEvent('mousedown', this._mapMouseDown);
      this.removeEvent('mousemove', this._mapMouseMove);
      this.removeEvent('mouseup', this._mapMouseUp);
    }
  }

  _getTooltip(width, height) {
    const result =
      this.getDescriptionString('Width', this.getDistanceString(width)) +
      '<br />' +
      this.getDescriptionString('Height', this.getDistanceString(height))
    return result;
  }

  _mapMouseDown(e) {
    if (this.ignoreMouseEvents) {
      return;
    }

    this._drawing = true;

    const layerGroup = this.layerGroup;
    // Add start marker layer
    const startMarker = L.circleMarker(e.latlng, MARKER_OPTIONS);
    layerGroup.addLayer(startMarker);
    // Add end marker layer
    const endMarker = L.circleMarker(e.latlng, MARKER_OPTIONS);
    endMarker.bindTooltip(this._getTooltip(0, 0), TOOLTIP_OPTIONS);
    layerGroup.addLayer(endMarker);
    // Add border layer
    const border = L.rectangle([e.latlng, e.latlng], { ...BORDER_OPTIONS });
    layerGroup.addLayer(border);
  }

  _mapMouseMove(e) {
    if (this.ignoreMouseEvents || !this._drawing) {
      return;
    }

    const layers = this.layerGroup.getLayers();
    if (layers.length === 0) {
      return;
    }

    const startMarker = layers[layers.length - 3];
    const startLatLng = startMarker.getLatLng();
    const endLatLng = e.latlng;
    const top = Math.max(startLatLng.lng, endLatLng.lng);
    const bottom = Math.min(startLatLng.lng, endLatLng.lng);
    const left = Math.max(startLatLng.lat, endLatLng.lat);
    const right = Math.min(startLatLng.lat, endLatLng.lat);
    const nw = L.latLng(left, top);
    const ne = L.latLng(right, top);
    const sw = L.latLng(left, bottom);
    const se = L.latLng(right, bottom);
    const width = GeometryUtil.length([ne, se]);
    const height = GeometryUtil.length([ne, nw]);

    // Update border layer
    const border = layers[layers.length - 1];
    border.setLatLngs([ne, nw, sw, se]);
    // Update end marker layer
    const endMarker = layers[layers.length - 2];
    endMarker.setLatLng(e.latlng);
    endMarker.setTooltipContent(this._getTooltip(width, height));
  }

  _mapMouseUp(e) {
    if (this.ignoreMouseEvents) {
      return;
    }

    this._drawing = false;

    const layerGroup = this.layerGroup;
    const layers = layerGroup.getLayers();
    if (layers.length > 0) {
      const border = layers[layers.length - 1];
      const bounds = border.getBounds();
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();
      const width = GeometryUtil.length([L.latLng(southWest.lat, northEast.lng), southWest]);
      const height = GeometryUtil.length([northEast, L.latLng(southWest.lat, northEast.lng)]);

      if (width > 0 && height > 0) {
        for (var loop = 0; loop < layers.length - 1; loop++) {
          layerGroup.removeLayer(layers[loop]);
        }

        if (typeof this.onComplete === 'function') {
          this.onComplete(northEast, southWest);
        }
      } else {
        this.clearLayerGroup();
      }
    }

    //TODO: added until we revisit 181 - Retain Select Tool Function
    this.toggled = false;
  }
}

export { BoxSelectButtonControl };