import React from 'react';

class ReadOnlyGeneralFormatter extends React.Component {

  render() {    
    return (
      <div className="readOnlyColumn">{this.props.value}</div>);
  }
}

export default ReadOnlyGeneralFormatter;